/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { EditSubCluster } from '../components/subCluster/EditSubCluster';
import { SubClusterHome } from '../components/subCluster/SubClusterHome';
import { useSubClusterLoad } from '../hooks/subCluster';
import { InfectedPremisesPage } from '../pages/application/InfectedPremisesPage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISubClusterRouterParams {
    subClusterId?: string
}

interface ISubClusterRouterProps {
    subClusterId?: number;
    match: match<ISubClusterRouterParams>;
}

export const SubClusterRouter: React.FunctionComponent<ISubClusterRouterProps> = ({ subClusterId, match }) => {
    const subClusterIdTemp = match.params.subClusterId ? parseInt(match.params.subClusterId) : (subClusterId || -1);

    // Load in sub-cluster so child routes can use
    const subClusterIdLoaded = React.useMemo(() => subClusterIdTemp, [subClusterIdTemp])
    const [, response] = useSubClusterLoad(subClusterIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/edit`} component={EditSubCluster} />
            <Route path={`${match.path}/premise`} component={InfectedPremisesPage} />
            <Route path={`${match.path}`} component={SubClusterHome} />
        </Switch>
}