/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { Checkbox, ListItemText, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import subNationalModule from '../../store/reducers/subNationals/subNational';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISubNationalEditFormProps {
    editSubNational?: SpreadDtos.SubNational;
    country?: SpreadDtos.Country;
    history: History;
    loadingSubNational?: boolean;
    url: string;
}

interface ISubNationalFormSchema extends Partial<SpreadDtos.SubNational> {
    name: string;
}

const subNationalSchema = Yup.object<ISubNationalFormSchema>().shape({
    countryId: Yup.number().label('Country')
        .required(),
    name: Yup.string().label('Name')
        .required(),
});

const validation = Yup.object().shape({
    object: subNationalSchema.notRequired()
})


const SubNationalEditForm: React.FunctionComponent<ISubNationalEditFormProps> = ({
    editSubNational,
    country,
    history,
    loadingSubNational,
    url,
}) => {
    const countryId = editSubNational ? editSubNational.countryId : country && country.id;
    const editMode = editSubNational !== undefined;

    console.log('editSubNational', editSubNational);
    console.log('country', country);

    const wrappedObject = editMode ? {
        object: editSubNational
    } : undefined;

    return <EditForm
        actions={subNationalModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingSubNational}
        selectors={subNationalModule.selectors}
        successRouteCallback={(d) => d ? `/admin/country/${countryId}` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <TextField
                label={'Country'}
                disabled={true}
                fullWidth
                name={"object.country"}
                value={country ? country.name : ""}
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default SubNationalEditForm;