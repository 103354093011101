/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving the authenticated person.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';

import { Epic, ofType, combineEpics } from 'redux-observable';

import { of } from 'rxjs';

import { mergeMap, catchError } from 'rxjs/operators';

import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest } from '../../../helpers/createRequest';
import { RequestState } from '../../../types/RequestState';
import { IRequestState } from '../../../types/IRequestState';
import { AuthState } from '../../../types/AuthState';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialAuthenticatedPersonState {
    data?: SpreadDtos.AuthenticatedPerson;
    groupInvites: {
        data?: SpreadDtos.PersonGroupMemberInvite[];
        loadState: IRequestState;
    }
    session?: SpreadDtos.AuthenticateResponse;
    authState: AuthState;
    loadState: IRequestState;
    loginState: IRequestState;
}

export interface IAuthenticatedPersonState {
    authenticatedPerson: IInitialAuthenticatedPersonState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@authenticated_person';

export const authenticatedPersonTypes = {
    AUTH: `${typeNamespace}/AUTH`,
    AUTH_SUCCESS: `${typeNamespace}/AUTH_SUCCESS`,
    AUTH_FAILURE: `${typeNamespace}/AUTH_FAILURE`,
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    LOAD_GROUP_INVITES: `${typeNamespace}/LOAD_GROUP_INVITES`,
    LOAD_GROUP_INVITES_SUCCESS: `${typeNamespace}/LOAD_GROUP_INVITES_SUCCESS`,
    LOAD_GROUP_INVITES_FAILURE: `${typeNamespace}/LOAD_GROUP_INVITES_FAILURE`,
    LOGIN: `${typeNamespace}/LOGIN`,
    LOGIN_SUCCESS: `${typeNamespace}/LOGIN_SUCCESS`,
    LOGIN_FAILURE: `${typeNamespace}/LOGIN_FAILURE`,
    LOGOUT: `${typeNamespace}/LOGOUT`,
    LOGOUT_CLEAR: `${typeNamespace}/LOGOUT_CLEAR`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAuthenticatedPersonState = {
    data: undefined,
    session: undefined,
    authState: AuthState.Unknown,
    groupInvites: {
        data: undefined,
        loadState: {
            state: RequestState.None
        }
    },
    loadState: {
        state: RequestState.None
    },
    loginState: {
        state: RequestState.None
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const authenticatedPersonReducer = createReducer<IInitialAuthenticatedPersonState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const authenticatedPersonActions = {
    /**
     * This action begins the request to check the persons session with the system
     */
    auth: createAction(authenticatedPersonTypes.AUTH, () => ({})),
    /**
     * This action fires after a successful check of person auth request
     */
    authSuccess: createAction(authenticatedPersonTypes.AUTH_SUCCESS,
        (session: SpreadDtos.AuthenticateResponse) => ({ session })
    ),
    /**
     * This action stores the error in the authenticated person auth state after a 
     * failed auth check request
     */
    authFailure: createAction(authenticatedPersonTypes.AUTH_FAILURE,
        (responseStatus: SpreadDtos.ResponseStatus) => ({ responseStatus })
    ),
    /**
     * This action begins the request to retrieve the currently authenticated person
     */
    load: createAction(authenticatedPersonTypes.LOAD, () => ({})),
    /**
     * This action stores the person in the authenticated person state after a 
     * successful load request
     */
    loadSuccess: createAction(authenticatedPersonTypes.LOAD_SUCCESS,
        (person: SpreadDtos.AuthenticatedPerson) => ({ person })
    ),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed load request
     */
    loadFailure: createAction(authenticatedPersonTypes.LOAD_FAILURE,
        (response: SpreadDtos.GetAuthenticatedPersonResponse) => ({ response })
    ),

    /**
     * This action begins the request to retrieve group invites that belong to the
     * currently authenticated person
     */
    loadGroupInvites: createAction(authenticatedPersonTypes.LOAD_GROUP_INVITES, () => ({})),
    /**
     * This action stores the person's group invites after a successful load request
     */
    loadGroupInvitesSuccess: createAction(authenticatedPersonTypes.LOAD_GROUP_INVITES_SUCCESS,
        (invites: SpreadDtos.PersonGroupMemberInvite[]) => ({ invites })
    ),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed load request
     */
    loadGroupInvitesFailure: createAction(authenticatedPersonTypes.LOAD_GROUP_INVITES_FAILURE,
        (response: SpreadDtos.GetAuthenticatedPersonGroupInvitesResponse) => ({ response })
    ),
    /**
     * This action begins the request to login to the system
     */
    login: createAction(authenticatedPersonTypes.LOGIN,
        (email: string, password: string, rememberMe: string) => ({
            email,
            password,
            rememberMe
        })
    ),
    /**
     * This action fires after a successful login request
     */
    loginSuccess: createAction(authenticatedPersonTypes.LOGIN_SUCCESS,
        (session: SpreadDtos.AuthenticateResponse) => ({
            session
        })
    ),
    /**
     * This action stores the error in the authenticated person state after a 
     * failed login request
     */
    loginFailure: createAction(authenticatedPersonTypes.LOGIN_FAILURE,
        (response: SpreadDtos.AuthenticateResponse) => ({
            response
        })
    ),
    /**
     * This action sends a request to logout of the system
     */
    logout: createAction(authenticatedPersonTypes.LOGOUT, () => ({})),
    /**
     * This action sends a request to logout of the system
     */
    logoutClear: createAction(authenticatedPersonTypes.LOGOUT_CLEAR, () => ({})),
    /**
     * This action clears the entire authenticated person state.
     */
    clear: createAction(authenticatedPersonTypes.CLEAR, () => ({})),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Auth Reducers
authenticatedPersonReducer.on(authenticatedPersonActions.auth, (state) => (
    update(
        state,
        {
            session: {
                $set: undefined
            },
            authState: {
                $set: AuthState.Pending
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.authSuccess, (state, payload) => (
    update(
        state,
        {
            session: {
                $set: payload.session
            },
            authState: {
                $set: AuthState.Authenticated
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.authFailure, (state) => (
    update(
        state,
        {
            session: {
                $set: undefined
            },
            authState: {
                $set: AuthState.NoAuth
            }
        }
    )
));

// Load Reducers
authenticatedPersonReducer.on(authenticatedPersonActions.load, (state) => (
    update(
        state,
        {
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.person
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Load Group Invites Reducers
authenticatedPersonReducer.on(authenticatedPersonActions.loadGroupInvites, (state) => (
    update(
        state,
        {
            groupInvites: {
                loadState: {
                    $set: {
                        state: RequestState.Pending
                    }
                }
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loadGroupInvitesSuccess, (state, payload) => (
    update(
        state,
        {
            groupInvites: {
                data: {
                    $set: payload.invites
                },
                loadState: {
                    $set: {
                        state: RequestState.Success
                    }
                }
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loadGroupInvitesFailure, (state, payload) => (
    update(
        state,
        {
            groupInvites: {
                data: {
                    $set: undefined
                },
                loadState: {
                    $set: {
                        state: RequestState.Failure,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
));

// Login Reducers
authenticatedPersonReducer.on(authenticatedPersonActions.login, (state) => (
    update(
        state,
        {
            loginState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loginSuccess, (state, payload) => (
    update(
        state,
        {
            session: {
                $set: payload.session
            },
            loginState: {
                $set: {
                    state: RequestState.Success
                }
            },
            authState: {
                $set: AuthState.Authenticated
            }
        }
    )
));

authenticatedPersonReducer.on(authenticatedPersonActions.loginFailure, (state, payload) => (
    update(
        state,
        {
            session: {
                $set: undefined
            },
            loginState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// LogoutClear Reducer
authenticatedPersonReducer.on(authenticatedPersonActions.logoutClear, () => {

    const clearedState = { ...initialState };

    return update(
        clearedState,
        {
            authState: {
                $set: AuthState.NoAuth
            }
        }
    )
});

// Clear Reducer
authenticatedPersonReducer.on(authenticatedPersonActions.clear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const authenticatedPersonsApi = {
    auth: createGetRequest(
        SpreadDtos.Authenticate,
        () => ({})
    ),
    load: createGetRequest(
        SpreadDtos.GetAuthenticatedPerson,
        () => ({})
    ),
    loadGroupInvites: createGetRequest(
        SpreadDtos.GetAuthenticatedPersonGroupInvites,
        () => ({})
    ),
    login: createPostRequest(
        SpreadDtos.Authenticate,
        (email: string, password: string, rememberMe?: boolean) => ({
            provider: 'credentials',
            userName: email,
            password: password,
            rememberMe: rememberMe
        })
    ),
    logout: createPostRequest(
        SpreadDtos.Authenticate,
        () => ({
            provider: 'logout',
        })
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const authEpic: Epic<ReturnType<typeof authenticatedPersonActions.authSuccess>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(authenticatedPersonActions.auth.getType()),
    mergeMap(action =>
        authenticatedPersonsApi.auth()
            .pipe(
                mergeMap(response =>
                    of(
                        authenticatedPersonActions.authSuccess(response),
                        authenticatedPersonActions.load()
                    )
                ),
                catchError(e => of(authenticatedPersonActions.authFailure(e)))
            )
    ));

export const loadEpic: Epic<ReturnType<typeof authenticatedPersonActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(authenticatedPersonActions.load.getType()),
    mergeMap(action =>
        authenticatedPersonsApi.load()
            .pipe(
                mergeMap(response =>
                    of(
                        authenticatedPersonActions.loadSuccess(response.person)
                    )
                ),
                catchError(e => of(authenticatedPersonActions.loadFailure(e)))
            )
    ));

export const loadGroupInvitesEpic: Epic<ReturnType<typeof authenticatedPersonActions.loadGroupInvites>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(authenticatedPersonActions.loadGroupInvites.getType()),
    mergeMap(action =>
        authenticatedPersonsApi.loadGroupInvites()
            .pipe(
                mergeMap(response =>
                    of(
                        authenticatedPersonActions.loadGroupInvitesSuccess(response.invites)
                    )
                ),
                catchError(e => of(authenticatedPersonActions.loadGroupInvitesFailure(e)))
            )
    ));

export const loginEpic: Epic<ReturnType<typeof authenticatedPersonActions.login>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(authenticatedPersonActions.login.getType()),
    mergeMap(action =>
        authenticatedPersonsApi.login(action.payload.email, action.payload.password, action.payload.rememberMe !== undefined)
            .pipe(
                mergeMap(response =>
                    of(
                        authenticatedPersonActions.loginSuccess(response),
                        authenticatedPersonActions.load()
                    )
                ),
                catchError(e => of(authenticatedPersonActions.loginFailure(e)))
            )
    ));

export const logoutEpic: Epic<ReturnType<typeof authenticatedPersonActions.logout>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(authenticatedPersonActions.logout.getType()),
    mergeMap(action =>
        authenticatedPersonsApi.logout()
            .pipe(
                mergeMap(response =>
                    of(
                        authenticatedPersonActions.logoutClear()
                    )
                )
            )
    ));

export const authenticatedPersonEpics = combineEpics(authEpic, loadEpic, loadGroupInvitesEpic, loginEpic, logoutEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default authenticatedPersonReducer;