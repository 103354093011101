/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import ShareIcon from '@material-ui/icons/Share';
import { toDate } from '@servicestack/client';
import { isSameDay, isSameHour } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { AnalysisResultTimeScale, Epidemic, LatLngBounds, WindDispersionResultType } from '../../dtos/Spread.dtos';
import { networkEdges } from '../../helpers/tempData';
import { useClusterLoad } from '../../hooks/cluster';
import { useAnalysisWindDispersionResultsLoad } from '../../hooks/useAnalysisWindDispersion';
import { useSelector } from '../../hooks/useTypedSelector';
import { infectedPremiseSearchHooks } from '../../store/reducers/infectedPremises/infectedPremiseSearch';
import { RequestState } from '../../types/RequestState';
import { DownloadIndicator } from '../map/controls/DownloadIndicator';
import EpidemicTimeControl from '../map/controls/EpidemicTimeControl';
import { MapAnalysisList } from '../map/controls/MapAnalysisList';
import { MapClusterList } from '../map/controls/MapClusterList';
import { MapNetworkList } from '../map/controls/MapNetworkList';
import ResponsiveMapControl from '../map/controls/responsiveMapControl/ResponsiveMapControl';
import SmartMapControl from '../map/controls/smartMapControl/SmartMapControl';
import SmartMapTab from '../map/controls/smartMapControl/SmartMapTab';
import { SnapMapBoundsWatcher } from '../map/controls/SnapMapBoundsWatcher';
import LeafletMap from '../map/LeafletMap';
import { GenomicNetworkDisplay } from '../map/visualisations/genomic/GenomicNetworkDisplay';
import { WindDispersionHeatmap } from '../map/visualisations/wind/WindDispersionHeatmap';
import { EpidemicResultsContext } from './EpidemicResultsContext';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const TabsHeightPx = 72;
const TimerHeightPx = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(1),
            }
        },
        timerWrapper: {
            height: TimerHeightPx,
            padding: theme.spacing(0, 3)
        },
        legendLine: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 8
        },
        ipCircleSusceptible: {
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: '#99e818',
            marginRight: 16
        },
        ipCircle: {
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: '#ffa818',
            marginRight: 16
        },
        ipCircleInfected: {
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: '#ff302f',
            marginRight: 16
        },
        ipCircleDisposed: {
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: '#bfbfbf',
            marginRight: 16
        },
        paper: {
            margin: theme.spacing(2, 1),
            padding: theme.spacing(1, 2),
            width: '160px'
        },
        legendColours: {
            background: 'linear-gradient(to bottom, hsl(0, 100%, 50%) 0%, hsl(50, 100%, 50%) 25%, hsl(100, 100%, 50%) 50%, hsl(150, 100%, 50%) 75%, hsl(200, 100%, 50%) 100%)',
            margin: '2px',
            padding: '100px 20px',
        },
        legendLabels: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        },
        legendContainer: {
            display: 'flex',
            flexDirection: 'column-reverse',
            flexWrap: 'wrap-reverse',
            alignContent: 'flex-start',
            maxHeight: 'calc(100vh - 200px)'
        },
        legendItem: {
            background: 'white',
            padding: '5px',
            borderRadius: '4px',
            margin: '5px'
        },
        legendSubItem: {
            paddingLeft: '30px'
        }
    }),
);

interface IEpidemicMapProps {
    analysisId?: number;
    networkId?: number;
    clusterId?: number;
    clusterZoom?: boolean;
    infectedPremiseId?: number;

    epidemic?: Epidemic;
    history: History;
    match: match<any>;
    mapHeight: number;
    isMapHidden: boolean;
}

const tileMap = {
    attribution: "&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors",
    url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
};

export const EpidemicMap: React.FunctionComponent<IEpidemicMapProps> = ({
    epidemic,
    history,
    match,
    analysisId,
    networkId,
    clusterId,
    clusterZoom,
    infectedPremiseId,
    mapHeight,
    isMapHidden
}) => {
    const classes = useStyles();

    const epidemicResultContext = useContext(EpidemicResultsContext);

    const [analysisMode, setAnalysisMode] = useState<WindDispersionResultType>(WindDispersionResultType.DailyTotalAggregate);

    const [showProbabilities, setShowProbabilities] = useState(false);

    const handleModeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => setAnalysisMode(value as WindDispersionResultType),
        [setAnalysisMode],
    );

    const [showLabelMode, setLabelMode] = useState("farmId");

    const handleLabelModeChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => setLabelMode(value as string),
        [setLabelMode],
    );

    const [analysisResults, analysisGlobalMax, analysisMinimumCutOff, analysisResultGrid, analysisStartDate, analysisEndDate, analysisTimeScale, analysisLatLngBounds, analysisResultsRequestState] = useAnalysisWindDispersionResultsLoad(analysisMode, analysisId);

    const specOps = useMemo(() => ({ epidemicId: epidemic ? epidemic.id : -1, clusterId: clusterId }), [clusterId, epidemic]);
    const pageOps = useMemo(() => ({ take: 10000, skip: 0 }), []);
    const [data, , ,] = infectedPremiseSearchHooks.useSearch(specOps, pageOps);

    const dataHasPoints = data ? data.meta['ResultHasPointData'].toLowerCase() === 'true' : false;
    const dataHasPolygons = data ? data.meta['ResultHasPolygonData'].toLowerCase() === 'true' : false;

    const [filteredCluster, filteredClusterResponse] = useClusterLoad(clusterId);

    const defaultCentre: [number, number] = useMemo(() => (epidemic ? [epidemic.centreLat, epidemic.centreLng] : [50.73, -4.01]), [epidemic]);

    const currentTimeStepResults = useMemo(() => {
        return epidemicResultContext.currentTime && analysisResults ? analysisResults.find(f => {

            const myDate = toDate(f.timeStep);

            const sameDay = analysisTimeScale === AnalysisResultTimeScale.Day ? isSameDay(myDate, epidemicResultContext.currentTime) : isSameHour(myDate, epidemicResultContext.currentTime);

            return sameDay

        }) : undefined;

    }, [epidemicResultContext.currentTime, analysisResults, analysisTimeScale]);

    const [showPolygons, setShowPolygons] = useState(false);
    const [showPoints, setShowPoints] = useState(true);
    const [showLabels, setShowLabels] = useState(false);
    const [useCutoff, setUseCutoff] = useState(true);

    var defaultBounds: LatLngBounds | undefined = undefined;
    var noSpatialData: boolean = false;

    if (clusterId && clusterZoom) {
        defaultBounds = filteredCluster && filteredCluster.latLngBounds;
    } else if (epidemic) {
        if (epidemic.latLngBounds) {
            defaultBounds = epidemic && epidemic.latLngBounds;
        } else {
            noSpatialData = true;
        }
    }

    const results = useSelector(state => state.analysisTapm.loadResultsState);

    const unitsLabel = useMemo(
        () => analysisMode === WindDispersionResultType.Heatmap ? <>TCID<sub>50</sub>/1 h m<sup>3</sup></> : <>TCID<sub>50</sub>/24 h m<sup>3</sup></>
        , [analysisMode === WindDispersionResultType.Heatmap]);

    return <>{noSpatialData
        ? <Typography>No spatial data</Typography>
        : epidemic && defaultBounds ? <LeafletMap
            defaultBounds={defaultBounds}
            defaultCentre={defaultCentre}
            height={mapHeight}
            infectedPremises={data && data.results}
            infectedPremisesStatus={currentTimeStepResults && currentTimeStepResults.analysisJobInfectedPremiseStatuses}
            infectedPremiseIdStartOpen={infectedPremiseId}
            tileMap={tileMap}
            timeStep={epidemicResultContext.currentTime}

            showPolygons={showPolygons}
            showPoints={showPoints}
            showLabels={showLabels}
            showLabelMode={showLabelMode}
            isMapHidden={isMapHidden}>
            <SnapMapBoundsWatcher bounds={analysisLatLngBounds} />
            <SmartMapControl position='topleft' >
                <SmartMapTab featureIcon={<BubbleChartIcon />} tabKey={0} >
                    <MapClusterList
                        epidemicId={epidemic.id}
                        history={history}
                        url={match.path} />
                </SmartMapTab>
                <SmartMapTab
                    featureIcon={<LeakAddIcon />}
                    tabKey={1} >
                    <MapAnalysisList
                        epidemicId={epidemic.id}
                        subClusterId={clusterId}
                        history={history}
                        url={match.path} />
                </SmartMapTab>
                <SmartMapTab featureIcon={<ShareIcon />} tabKey={2} >
                    <MapNetworkList history={history} url={match.path} />
                </SmartMapTab>
            </SmartMapControl>
            <ResponsiveMapControl position='bottomright'>
                <div className={classes.legendContainer}>
                    <div className={classes.legendItem}>
                        <Typography component="p" variant="subtitle2">Premises</Typography>
                        <div className={classes.legendLine}>
                            <div className={classes.ipCircleSusceptible} />
                            <Typography component="p" variant="body1">At risk</Typography>
                        </div>
                        <div className={classes.legendLine}>
                            <div className={classes.ipCircle} />
                            <Typography component="p" variant="body1">At high risk</Typography>
                        </div>
                        <div className={classes.legendLine}>
                            <div className={classes.ipCircleInfected} />
                            <Typography component="p" variant="body1">Infected</Typography>
                        </div>
                        <div className={classes.legendLine}>
                            <div className={classes.ipCircleDisposed} />
                            <Typography component="p" variant="body1">Disposed</Typography>
                        </div>
                    </div>
                    <div className={classes.legendItem}>
                        <Typography component="p" variant="subtitle2">Layers</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showPolygons}
                                    disabled={!dataHasPolygons}
                                    onChange={() => setShowPolygons(!showPolygons)}
                                    value="showPolygons"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />}
                            label="Parcels" />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showPoints}
                                    disabled={!dataHasPoints}
                                    onChange={() => setShowPoints(!showPoints)}
                                    value="showPoints"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />}
                            label="Points" />
                        <br />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showLabels}
                                    disabled={!dataHasPoints}
                                    onChange={() => setShowLabels(!showLabels)}
                                    value="showLabels"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />}
                            label="Labels" />
                        <br />
                        <FormControl component="fieldset" className={classes.legendSubItem}>
                            <RadioGroup aria-label="labels mode" name="LabelsMode" value={showLabelMode} onChange={handleLabelModeChange}>
                                <FormControlLabel value="farmId" control={<Radio />} label="Farm ID" disabled={!showLabels} />
                                <FormControlLabel value="ipId" control={<Radio />} label="IP ID" disabled={!showLabels} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    {
                        analysisId ? <>
                            <div className={classes.legendItem}>
                                <Typography component="p" variant="subtitle2">Dispersion Mode</Typography>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="analysis results mode" name="AnalysisResultMode" value={analysisMode} onChange={handleModeChange}>
                                        <FormControlLabel value={WindDispersionResultType.Heatmap} control={<Radio />} label="Hourly Aggregate" />
                                        <FormControlLabel value={WindDispersionResultType.DailyTotalAggregate} control={<Radio />} label="Daily Aggregate" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className={classes.legendItem}>
                                <Typography component="p" variant="subtitle2">{unitsLabel}</Typography>
                                <div className={classes.legendLine}>
                                    <div className={classes.legendColours} />
                                    <div className={classes.legendLabels}>
                                        <Typography>{analysisGlobalMax ? analysisGlobalMax : <Skeleton width={30} />}</Typography>
                                        <Typography>{analysisGlobalMax ? analysisGlobalMax / 2 : <Skeleton width={30} />}</Typography>
                                        <Typography>0</Typography>
                                    </div>
                                </div>
                                <FormControlLabel
                                    style={{
                                        marginRight: '0px'
                                    }}
                                    control={
                                        <Checkbox
                                            checked={useCutoff}
                                            disabled={!dataHasPoints}
                                            onChange={() => setUseCutoff(!useCutoff)}
                                            value="useCutoff"
                                            inputProps={{
                                                'aria-label': 'primary checkbox',
                                            }}
                                        />}
                                    label="Hide Low Values" />
                            </div>
                            {currentTimeStepResults && <WindDispersionHeatmap
                                max={analysisGlobalMax}
                                useCutoff={useCutoff}
                                minimumCutOff={analysisMinimumCutOff}
                                windResults={currentTimeStepResults.resultGridCellAnalysisJobTapmResultValues}
                                resultGrid={analysisResultGrid}
                                unitsLabel={unitsLabel} />
                            }
                        </> : null
                    }
                </div>
            </ResponsiveMapControl>
            <DownloadIndicator loading={results.state === RequestState.Pending} />
            {
                networkId && <GenomicNetworkDisplay
                    networkEdges={networkEdges}
                    showProbabilities={showProbabilities} />
            }
        </LeafletMap>
            : <Skeleton height={mapHeight} />
    }
        <Box className={classes.timerWrapper}>
            <EpidemicTimeControl
                analysisTimeScale={analysisTimeScale}
                analysisStartDate={analysisStartDate}
                analysisEndDate={analysisEndDate}
                epidemic={epidemic}
            />
        </Box>
    </>
}