/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import TissueModule from '../../store/reducers/tissues/tissue';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ITissueEditFormProps {
    editTissue?: SpreadDtos.Tissue;
    history: History;
    loadingTissue?: boolean;
    url: string;
}

interface ITissueFormSchema extends Partial<SpreadDtos.Tissue> {
    name: string;
}

const TissueSchema = Yup.object<ITissueFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: TissueSchema.notRequired()
})


const TissueEditForm: React.FunctionComponent<ITissueEditFormProps> = ({
    editTissue,
    history,
    loadingTissue,
    url,
}) => {
    const editMode = editTissue !== undefined;

    const wrappedObject = editMode ? {
        object: editTissue
    } : undefined;

    return <EditForm
        actions={TissueModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingTissue}
        selectors={TissueModule.selectors}
        successRouteCallback={(d) => d ? `/admin/tissue` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default TissueEditForm;