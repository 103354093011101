/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import BannerImage from '../../assets/images/home/banner.jpg';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backing: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'cover'
        },
        textSmall: {
        },
        textLarge: {
        },
        text: {
            padding: '2rem 0 2rem 0',
            fontWeight: 'bold',
            fontSize: '1.75rem',
            lineHeight: '2.5rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '2rem',
                lineHeight: '2.75rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '3rem',
                lineHeight: '3.5rem'
            },
        },
        textWrapper: {
            position: 'relative',
            height: '50%',
        },
        textBacking: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 29, 90, 0.85)'
        },
        textContainer: {
            position: 'relative',
            color: 'white',
            textAlign: 'center'
        },
        wrapper: {
            position: 'relative',
            height: '400px'
        }
    }),
);

interface IContentHeroProps {
    textLineOne: string;
    textLineTwo?: string;
}

export const ContentHero: React.FunctionComponent<IContentHeroProps> = (props) => {
    const classes = useStyles(props);
    const matchSmall = useMediaQuery(theme => (theme as Theme).breakpoints.down('sm'));
    const matchLarge = useMediaQuery(theme => (theme as Theme).breakpoints.up('lg'));

    const textClass = classNames(classes.text, {
        [classes.textSmall]: matchSmall,
        [classes.textLarge]: matchLarge,
    })

    return <div className={classes.wrapper}>
        <div className={classes.backing}></div>
        <Box className={classes.textWrapper}>
            <div className={classes.textBacking}></div>
            <Container fixed className={classes.textContainer}>
                <Typography className={classes.text} variant={'h3'}>{props.textLineOne}</Typography>
                {props.textLineTwo && <Typography className={classes.text} variant={'h3'}>{props.textLineTwo}</Typography>}
            </Container>
        </Box>
    </div>
}
