/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { match, Redirect } from 'react-router';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from "../../dtos/Spread.dtos";
import { routeBackToPath } from '../../helpers/routeHelpers';
import { useSelector } from '../../hooks/useTypedSelector';
import { passwordResetActions, passwordResetSelectors } from '../../store/reducers/person/passwordReset';
import { RequestFormState } from '../../types/RequestState';
import AsyncForm from '../forms/AsyncForm';
import LabelledInputWrapper from '../forms/FinalFormControls/LabelledInputWrapper';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IResetPasswordFormSchema extends Partial<SpreadDtos.RequestPasswordReset> { }

const validation = Yup.object<IResetPasswordFormSchema>().shape({
    password: Yup.string().label('Password').required(),
    confirmPassword: Yup.string().label('Password confirmation').required()
        .test(
            'matching',
            'Password fields must match',
            function (item) {
                return this.parent.confirmPassword == this.parent.password
            }
        ),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomBox: {},
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IPasswordResetProps {
    history: History;
    match: match;
}

const PasswordReset: React.FunctionComponent<IPasswordResetProps> = ({ history, match }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const resetState = useSelector(s => passwordResetSelectors.resetState(s)).state;

    const params = parseQueryString(history.location.search);
    const token = params.token;

    const [showPassword, setShowPassword] = React.useState(false);

    var successRedirectComponent = null;

    if (resetState === RequestFormState.SubmitSuccess) {
        successRedirectComponent = <Redirect push to={routeBackToPath(match.url, 1, 'resetPasswordConfirm')} />
    }

    return <>
        <Typography variant="h4" component="h4">Password Reset</Typography>
        {successRedirectComponent}
        <AsyncForm
            start={passwordResetActions.reset}
            resolve={passwordResetActions.resetResponse}
            reject={passwordResetActions.resetResponse}
            initialValues={{
                token: token,
            }}
            formSubscription={{ pristine: true, submitting: true }}
            validate={validation}
        >
            {({ pristine, submitting }) =>
                <Grid container alignItems="flex-start" spacing={2} >
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="password"
                            component={LabelledInputWrapper}
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            fullWidth
                            name="confirmPassword"
                            component={LabelledInputWrapper}
                            type={showPassword ? 'text' : 'password'}
                            label="Re-type Password"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.bottomBox} display="flex" alignItems="center" justifyContent="flex-end">
                            <Button
                                className={classes.buttonSubmit}
                                color="primary"
                                disabled={pristine || submitting}
                                type="submit"
                                variant="contained"
                            >
                                Reset
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            }
        </AsyncForm>
    </>;
}

export default PasswordReset;