/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse } from 'query-string';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { sequenceViewsHooks } from '../../store/reducers/sequences/sequenceViewSearch';
import { RequestState } from '../../types/RequestState';
import ButtonLink from '../common/ButtonLink';
import { SequenceViewFilter } from './SequenceViewFilter';
import { SequenceViewList } from './SequenceViewList';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {
            marginTop: theme.spacing(2)
        },
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        buttonSwitch: {
            margin: theme.spacing(1),
        },
    }),
);

interface IViewSequenceViewsParams { }

interface IViewSequenceViewsProps {
    history: History;
    match: match<IViewSequenceViewsParams>;
}

export const ViewSequenceViews: React.FunctionComponent<IViewSequenceViewsProps> = ({
    history,
    match
}) => {
    const classes = useStyles();

    const params = parse(history.location.search);
    const link = !!params.link;

    const epidemic = useSelector(state => state.epidemic);
    const origin = params.origin ? String(params.origin) : undefined;
    const sequencingStatus = params.sequencingStatus ? String(params.sequencingStatus) : undefined;
    const country = params.country ? String(params.country) : undefined;
    const year = !isNaN(parseInt(String(params.year))) ? parseInt(String(params.year)) : undefined;
    const searchText = params.searchText ? String(params.searchText) : undefined;

    const specOps = React.useMemo(() => ({ 
        epidemicId: link ? undefined : epidemic && epidemic.data ? epidemic.data.id : -1, 
        notLinkedWithEpidemicId: link && epidemic && epidemic.data ? epidemic.data.id : undefined, 
        searchText: searchText, 
        origin: origin, 
        sequencingStatus: sequencingStatus, 
        country: country,
         year: year 
        }), [link, epidemic, searchText, origin, sequencingStatus, country, year]);
        
    const pagOps = React.useMemo(() => ({ orderBy: "-collectionDate", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = sequenceViewsHooks.useSearch(specOps, pagOps);

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">{link ? 'Candidate Sequences' : 'Linked Sequences'}</Typography>
        <ButtonLink
            className={classes.buttonSwitch}
            color="primary"
            size="small"
            to={link ? `${match.url}` : epidemic.data ? `${match.url}?link=1&country=${epidemic.data.country}&year=${epidemic.data.year}` : `${match.url}?link=1`}
            variant="contained"
        >View {link ? 'Linked Sequences' : 'Candidate Sequences'}</ButtonLink>
        <SequenceViewFilter
            filterSet={link ? "link" : "view"}
            history={history}
            params={params}
            url={match.url}
            sequencesLoading={requestState.state == RequestState.Pending}
        />

        {data && requestState.state === RequestState.Success && data.results && data.results.length > 0 ?
            <SequenceViewList
                data={data}
                history={history}
                url={match.url}
                link={link}
                paginateOptions={paginateOptions}
            /> :
            requestState.state === RequestState.Pending ?
                <Skeleton /> :
                <Typography variant="h6" component="h6">No Sequence</Typography>
        }
        {/*<Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <ButtonLink
                aria-label="Upload Genomic Data"
                color="primary"
                variant="contained"
                to={`${url}/upload`}
            >
            <AddBoxIcon className={classes.buttonSubmitIcon} />
            Upload new data
            </ButtonLink>
        </Box>*/}
    </Paper>
}