/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import countryModule from '../../store/reducers/countries/country';
import subNationalModule from '../../store/reducers/subNationals/subNational';
import { RequestFormState } from '../../types/RequestState';
import { SubNational } from '../../dtos/Spread.dtos';
import SubNationalEditForm from './SubNationalEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSubNationalParams {
    subNationalId: string;
    countryId?: string;
}

interface IEditSubNationalProps {
    subNationalId?: number;
    countryId?: number;
    history: History;
    match: match<IEditSubNationalParams>;
}

export const EditSubNational: React.FunctionComponent<IEditSubNationalProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles({});

    const countryState = useSelector(state => state.country);
    const countryId = props.countryId || ((props.match.params && props.match.params.countryId)? parseInt(props.match.params.countryId) : 0);
    useEffect(() => {
        if (countryModule.actions.load) {
            dispatch(countryModule.actions.load(countryId))
        }
    }, [dispatch, countryId]);

    const countryIsLoading = countryState.states.loadState.state === RequestFormState.Pending && !countryState.data;
    
    const subNationalState = useSelector(state => state.subNational);
    const subNationalId = props.subNationalId || ((props.match.params && props.match.params.subNationalId)? parseInt(props.match.params.subNationalId) : 0);
    useEffect(() => {
        if (subNationalModule.actions.load) {
            dispatch(subNationalModule.actions.load(subNationalId))
        }
    }, [dispatch, subNationalId]);

    return countryIsLoading ?
        <CircularProgress /> : <Paper className={classes.root}>
        <SubNationalEditForm
            editSubNational={subNationalState.data}
            country={countryState.data}
            history={props.history}
            loadingSubNational={subNationalState.states.loadState.state === RequestFormState.Pending && !subNationalState.data}
            url={props.match.url}
        />
    </Paper>
}