/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import RegisterForm from '../../components/person/RegisterForm';

export interface IRegisterPageProps {
  classes?: any;
  history: History;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 2)
    },
  }),
);

export const RegisterPage: React.FunctionComponent<IRegisterPageProps> = (props) => {
  const classes = useStyles();

  const params = parseQueryString(props.history.location.search);
  var email = undefined;

  try {
    if (params.email) {
      email = atob(params.email as string);
    }
  } catch (error) { };

  const token = params.token && params.token as string;

  return (<Container maxWidth="sm">
    <Paper className={classes.root}>
      {
        token && email ? <RegisterForm
          email={email}
          token={token}
        />
          : <div>Invalid signup link</div>
      }
    </Paper>
  </Container>
  );
}
