/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Button, FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import * as React from 'react';
import { ReactNode, useMemo } from 'react';
import { Field, FormSpy } from 'react-final-form';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { InfectedPremise } from '../../../dtos/Spread.dtos';
import { infectedPremiseSearchHooks } from '../../../store/reducers/infectedPremises/infectedPremiseSearch';
import { RequestState } from '../../../types/RequestState';
import SkeletonCheckboxOptions from '../SkeletonCheckboxOptions/SkeletonCheckboxOptions';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IInfectedPremiseCheckboxSelectProps {
    clusterId?: number;
    epidemicId?: number;
    filter?: (ip: InfectedPremise, index?: number, array?: InfectedPremise[]) => boolean
    subClusterId?: number;
    name: string;
    ipCount?: number;
    noResultsMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        formSkeleton: {
            height: 42,
            marginLeft: -11,
        },
        formBox: {
            width: '100%'
        }
    }),
);

const InfectedPremiseCheckboxSelect: React.FunctionComponent<IInfectedPremiseCheckboxSelectProps> = ({
    clusterId,
    epidemicId,
    filter,
    subClusterId,
    name,
    ipCount,
    noResultsMessage
}) => {
    const classes = useStyles();

    const ipPageOps = useMemo(() => (
        { take: 1000, skip: 0 }
    ), []);

    const ipSpecOps = useMemo(() => (
        {
            epidemicId: epidemicId,
            clusterId: clusterId,
            subClusterId: subClusterId
        }), [clusterId, subClusterId]);

    const [infectedPremiseData, , , infectedPremiseRequestState] = infectedPremiseSearchHooks.useSearch(ipSpecOps, ipPageOps);

    const infectedPremiseResults = infectedPremiseData && infectedPremiseData.results;

    const infectedPremisesFiltered = infectedPremiseResults ?
        filter ?
            infectedPremiseResults.filter(filter).filter(ip => ip.infectedPremiseId && ip.infectedPremiseId.length > 0) :
            infectedPremiseResults.filter(ip => ip.infectedPremiseId && ip.infectedPremiseId.length > 0) :
        undefined;

    const infectedPremises = useMemo(() => infectedPremisesFiltered, [infectedPremisesFiltered]);

    const renderSkeletonOptions = infectedPremiseRequestState.state === RequestState.Pending;

    return <Box className={classes.formBox}>
        <FormLabel>Included Infected Premises</FormLabel>
        <Box className={classes.formGroup} display="flex" flexDirection={"column"}>
            {
                renderSkeletonOptions
                    ? <SkeletonCheckboxOptions
                        className={classes.formSkeleton}
                        count={ipCount || 1}
                    />
                    : infectedPremises && infectedPremises.length > 0
                        ? <>
                            <FormSpy>
                                {formSpyProps =>
                                    <Box display="flex" flexDirection={"row"} justifyContent={"space-around"} paddingBottom={"8px"}>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            formSpyProps.form.mutators.changeValue(name, infectedPremises.map(ip => ip.id))
                                        }}>
                                            Check All
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            formSpyProps.form.mutators.changeValue(name, [])
                                        }}>
                                            Uncheck All
                                        </Button>
                                    </Box>
                                }
                            </FormSpy>
                            <InfectedPremiseCheckboxes
                                infectedPremiseResults={infectedPremises}
                                name={name}
                            />
                        </>
                        : <div>
                            <Typography>{noResultsMessage ? noResultsMessage : 'No eligible Infected Premises found - try a different cluster.'}</Typography>
                        </div>
            }
        </Box>
    </Box>;
}

export default InfectedPremiseCheckboxSelect;

interface IInfectedPremiseCheckboxes {
    infectedPremiseResults: InfectedPremise[];
    name: string;
}

const InfectedPremiseCheckboxes: React.FC<IInfectedPremiseCheckboxes> = React.memo(props => {
    return <>
        {
            props.infectedPremiseResults.map((ip, i) => {

                const hasValidAnalysisDates = ip.infectedPremiseDateSet && ip.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate && ip.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate;
                const analysisDateString = hasValidAnalysisDates ?
                    `(${format(toDate(ip.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(ip.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)})` :
                    '(Insufficient date data)'

                return <ConnectedCheckbox
                    actualValue={ip.id}
                    // Check by default
                    defaultValue={ip.id}
                    label={<>IP ID: <b>{ip.infectedPremiseId}</b> Farm ID: <b>{ip.farmId}</b> Infection Period: <b>{analysisDateString}</b></>}
                    name={`${props.name}.${i}`}
                    type="checkbox"
                    key={`${props.name}-${ip.id}`}
                />
            })
        }
    </>
})

interface IConnectedCheckbox {
    actualValue: any;
    defaultValue: any;
    name: string;
    type: string;
    label: ReactNode;
    placeholder?: string;
}

const ConnectedCheckbox: React.FunctionComponent<IConnectedCheckbox> = ({ placeholder, label, name, type, actualValue, defaultValue }) => {

    return <Field
        defaultValue={defaultValue}
        name={name}
        type={type}
    >
        {({ input, meta }) => {
            function onInputChange(newVal: any) {
                input.onChange(newVal.target.checked ? actualValue : null);
                input.onBlur(newVal);
            }

            return <FormControlLabel
                control={<Checkbox
                    edge="start"
                    checked={input.checked}
                    tabIndex={-1}
                    disableRipple
                    onChange={onInputChange}
                />}
                label={label}
            />
        }}
    </Field>
}