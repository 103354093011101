/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AreaLivestockCount } from '../dtos/Spread.dtos';
import { areaLivestockCountActions } from '../store/reducers/areaLivestockCounts/areaLivestockCount';
import { IFormRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useAreaLivestockCountLoad(AreaLivestockCountId: number): [AreaLivestockCount | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.areaLivestockCount.data);
    const requestState = useSelector(state => state.areaLivestockCount.states.loadState);

    useEffect(() => {
        if (AreaLivestockCountId > 0) {
            dispatch(areaLivestockCountActions.load(AreaLivestockCountId))
        }

        return function cleanup() {
            dispatch(areaLivestockCountActions.clear())
        }
    }, [dispatch, AreaLivestockCountId]);

    return [
        data,
        requestState
    ];
}
