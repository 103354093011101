/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box, CircularProgress, Grid, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import { match, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { useSelector } from '../../hooks/useTypedSelector';
import excretionModule from '../../store/reducers/excretion/excretion';
import { RequestFormState } from '../../types/RequestState';
import CommonForm from '../forms/CommonForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import NumericFieldWrapper from '../forms/FinalFormControls/NumericFieldWrapper';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { changeValue, clear } from '../forms/mutators';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formItem: {
            margin: theme.spacing(2, 3)
        },
        formTitle: {
            marginLeft: theme.spacing(3),
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        loadingLabel: {
            marginLeft: theme.spacing(1)
        }
    }),
);

interface ICreateExcretionFormProps {
    epidemicId?: number;
    className?: string;
    history: History;
    match: match<any>;
}

export interface IExcretionJobFormSchema {
    epidemicID: number;
    name?: string;
    model: string;
    duration: number;
    i2lInterval: number;
    i2rInterval: number;
    r2cInterval: number;
    ignoreCulling: boolean;
}

const validation = Yup.object<IExcretionJobFormSchema>({
    epidemicID: Yup.number().label('Epidemic ID').required(),
    name: Yup.string(),
    model: Yup.string().label('Model').required(),
    duration: Yup.number().label('Estimated max days of excretion from farms').min(0).max(365).required(),
    i2lInterval: Yup.number().label('Mean infection-to-lesion interval').min(0).max(30).required(),
    i2rInterval: Yup.number().label('Mean infection-to-reporting interval').min(0).max(30).required(),
    r2cInterval: Yup.number().label('Mean reporting-to-culling interval').min(0).max(30).required(),
    ignoreCulling: Yup.boolean().label('Ignore culling').required()
});

export const ExcretionCreateJobForm: React.FunctionComponent<ICreateExcretionFormProps> = ({ history, match }) => {
    const classes = useStyles();

    const createState = useSelector(excretionModule.selectors.createState);
    const data = useSelector(excretionModule.selectors.data);

    const submitSuccess = createState.state === RequestFormState.SubmitSuccess;

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        const toRoute: string = data ? `${routeBackToPath(match.url)}` : '/404';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    const startAction = excretionModule.actions.createJob;
    const resolveAction = excretionModule.actions.createJobFormResponse;
    const rejectAction = excretionModule.actions.createJobFormResponse;

    const epidemic = useSelector(state => state.epidemic.data);

    const initialValues: IExcretionJobFormSchema = React.useMemo(() => ({
        epidemicID: epidemic?.id!,
        model: '',
        duration: 30,
        i2lInterval: 2,
        i2rInterval: 5,
        r2cInterval: 2,
        ignoreCulling: false
    }), [epidemic]);

    return <>
        <Typography variant="h4" component="h4">Undertake Excretion Run</Typography>
        {successRedirectComponent}
        <CommonForm
            history={history}
            labelSave={'Queue'}
            mutators={{
                clear: clear,
                changeValue: changeValue
            }}
            submitSuccess={submitSuccess}
            start={startAction}
            resolve={resolveAction}
            reject={rejectAction}
            validate={validation}
            initialValues={initialValues}>
            {() => <>
                <Grid item xs={12}>
                    <Box className={classes.formTitle}>
                        <Typography variant="h6">Job Details</Typography>
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="name"
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            component={TextFieldWrapper}
                            type="text"
                            label="Optional Name"
                            placeholder={"Use this to help find this job later"}
                        />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="model"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label="Model">
                            <MenuItem value={SpreadDtos.Model.M1}>Model 1</MenuItem>
                            <MenuItem value={SpreadDtos.Model.M2}>Model 2</MenuItem>
                            <MenuItem value={SpreadDtos.Model.M3A}>Model 3A</MenuItem>
                            <MenuItem value={SpreadDtos.Model.M3B}>Model 3B</MenuItem>
                            <MenuItem value={SpreadDtos.Model.M4}>Model 4</MenuItem>
                        </Field>
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="duration"
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            component={NumericFieldWrapper}
                            type="number"
                            label="Estimated max days of excretion from farms"
                        />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="i2lInterval"
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            component={NumericFieldWrapper}
                            type="number"
                            label="Mean infection-to-lesion interval"
                        />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="i2rInterval"
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            component={NumericFieldWrapper}
                            type="number"
                            label="Mean infection-to-reporting interval"
                            defaultValue={"5"}
                        />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="r2cInterval"
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            component={NumericFieldWrapper}
                            type="number"
                            label="Mean reporting-to-culling interval"
                            numberFormat={{
                                decimalScale: 0
                            }}
                        />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field
                            fullWidth
                            name="ignoreCulling"
                            component={CheckboxWrapper}
                            type="checkbox"
                            label="Ignore culling"
                        />
                    </Box>
                </Grid>
            </>}
        </CommonForm>
        {
            createState.state === RequestFormState.Pending && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Typography className={classes.loadingLabel} component="h6" variant="h6">Dispatching job</Typography>
            </Box>
        }
    </>
}