/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';
import { match } from 'react-router';
import ButtonLink from '../common/ButtonLink';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { HasPermission } from '../common/HasPermission';
import { HoverTooltip } from '../tooltip/HoverTooltip';
import { AnalysisList } from './AnalysisList';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        controlBox: {
            margin: theme.spacing(2, 1),
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            },
        },
        filterOption: {
            margin: theme.spacing(2, 1),
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
        },
        controlItem: {
            marginLeft: theme.spacing(2),
        },
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAnalysesParams { }

interface IViewAnalysesProps {
    className?: string;
    epidemicId: number;
    match: match<IViewAnalysesParams>;
}

export const ViewAnalyses: React.FunctionComponent<IViewAnalysesProps> = ({ className, epidemicId, match }) => {
    const url = match.url + (!match.url.endsWith('/analysis') ? '/analysis' : '');
    const classes = useStyles();

    const [search, setSearch] = React.useState('');

    const handleSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value as any),
        [setSearch],
    );

    return <Paper className={classNames(className, classes.root)}>
        <Typography variant="h4" component="h4">Wind Dispersion Analysis <HoverTooltip name={'WindDispersionAnalysis'} /></Typography>
        <Box className={classes.controlBox} display="flex" flexDirection="row" justifyContent="space-between" >
            <FormControl className={classes.filterOption}>
                <InputLabel>Search analysis name</InputLabel>

                <Input
                    value={search}
                    onChange={handleSearchChange}
                    placeholder={''}
                    startAdornment={<SearchIcon />}
                />
            </FormControl>
            <HasPermission permission="CreateAnalysisJob" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Queue Analysis Job"
                    className={classes.controlItem}
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Queue New Analysis Job
                </ButtonLink>
            </HasPermission>
        </Box>
        <AnalysisList
            className={classes.list}
            epidemicId={epidemicId}
            url={url}
            nameFilter={search}
        />
    </Paper>
}