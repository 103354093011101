/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an specimen
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateSpecimen, CreateSpecimenResponse, GetSpecimen, GetSpecimenResponse, Specimen, UpdateSpecimen, UpdateSpecimenResponse, DeleteSpecimen, DeleteSpecimenResponse } from '../../../dtos/Spread.dtos';
import { ActionType, createCrudModule, IBaseCrudActions, IBaseCrudApi, IBaseCrudHooks, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';

import createAction from '../../../helpers/createAction';
import { createGetRequest } from '../../../helpers/createRequest';
import { useSelector } from '../../../hooks/useTypedSelector';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISpecimenActions extends IBaseCrudActions<Specimen, CreateSpecimenResponse, undefined, UpdateSpecimenResponse, DeleteSpecimenResponse, undefined> {
    loadWithEpidemic: ActionType<(id: number, epidemicId: number) => { id: number; epidemicId: number; }>;
    loadWithEpidemicFormResponse: ActionType<(response: GetSpecimenResponse, state: RequestFormState) => { response: GetSpecimenResponse; state: RequestFormState; }>;
}

export interface ISpecimenState {
    specimen: IBaseCrudState<Specimen>;
}

interface ISpecimenApi extends IBaseCrudApi {
    loadWithEpidemic: ReturnType<typeof createGetRequest>;
}

interface ISpecimenHooks extends IBaseCrudHooks<Specimen> {
    useLoadWithEpidemic: (id: number, epidemicId: number) => [Specimen | undefined, IFormRequestState]
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const specimenModule = createCrudModule<
    Specimen,
    CreateSpecimenResponse,
    undefined,
    UpdateSpecimenResponse,
    DeleteSpecimenResponse,
    undefined,
    IBaseCrudState<Specimen>,
    ISpecimenActions,
    ISpecimenApi,
    IBaseCrudSelectors<Specimen, IBaseCrudState<Specimen>>,
    ISpecimenHooks
    >(
        'specimen',
        CreateSpecimen,
        undefined,
        UpdateSpecimen,
        DeleteSpecimen,
        undefined,
    );

// Create Job action

specimenModule.actions.loadWithEpidemic = createAction(`@@specimen/LOAD_WITH_EPIDEMIC`,
    (id: number, epidemicId: number) => ({ id, epidemicId })
);

specimenModule.actions.loadWithEpidemicFormResponse = createAction(`@@specimen/LOAD_WITH_EPIDEMIC_FORM_RESPONSE`,
    (response: GetSpecimenResponse, state: RequestFormState) => ({ response, state })
);

const loadWithEpidemicFormReducer = (state: IBaseCrudState<Specimen>) => update(
    state,
    {
        states: {
            loadState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const loadWithEpidemicFormResponseReducer = (state: IBaseCrudState<Specimen>, payload: any, data: Specimen) => update(
    state,
    {
        data: {
            $set: data
        },
        states: {
            loadState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

specimenModule.api.loadWithEpidemic = createGetRequest(
    GetSpecimen,
    (id: number, epidemicId: number) => ({
        id,
        epidemicId,
    })
);

const loadWithEpidemicEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(specimenModule.actions.loadWithEpidemic.getType()),
        mergeMap((action: Action<GetSpecimen, {}>) =>
            specimenModule.api.loadWithEpidemic(action.payload.id, action.payload.epidemicId)
                .pipe(
                    mergeMap(response => of(specimenModule.actions.loadWithEpidemicFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(specimenModule.actions.loadWithEpidemicFormResponse(e, RequestFormState.ServerError)))
                )
        )
    )

const useLoadWithEpidemic = (id: number, epidemicId: number): [Specimen | undefined, IFormRequestState] => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.specimen.data);
    const requestState = useSelector(state => state.specimen.states.loadState);

    useEffect(() => {
        if (id && id > 0 && epidemicId && epidemicId > 0 && specimenModule.actions.load) {
            dispatch(specimenModule.actions.loadWithEpidemic(id, epidemicId));
        }

        return function cleanup() {
            if (specimenModule.actions.clearFormState) {
                dispatch(specimenModule.actions.clearFormState());
            }
        }
    }, [dispatch, id]);

    return [
        data,
        requestState
    ];
};

specimenModule.hooks.useLoadWithEpidemic = useLoadWithEpidemic;

/* Add additional reducers and epics to module */

specimenModule.reducer.on(specimenModule.actions.loadWithEpidemic, (state: IBaseCrudState<Specimen>) => (
    loadWithEpidemicFormReducer(state)
));

specimenModule.reducer.on(specimenModule.actions.loadWithEpidemicFormResponse, (state: IBaseCrudState<Specimen>, payload: { response: GetSpecimenResponse, state: RequestFormState }) => (
    loadWithEpidemicFormResponseReducer(state, payload, payload.response.specimen)
));

specimenModule.epics = combineEpics(specimenModule.epics, loadWithEpidemicEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default specimenModule;