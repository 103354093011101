/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateField } from '../../components/field/CreateField';
import { ViewFields } from '../../components/field/ViewFields';
import { FieldRouter } from '../../routers/FieldRouter';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IFieldsPageProps {
    match: match;
}

export const FieldsPage: React.FunctionComponent<IFieldsPageProps> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/create`} component={CreateField} />
        <Route exact path={`${match.path}`} component={ViewFields} />
        <Route path={`${match.path}/:fieldId`} component={FieldRouter} />
    </Switch>;
}
