/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { sequenceSearchActions, sequencesHooks } from '../../../store/reducers/sequences/sequenceSearch';
import { RequestState } from '../../../types/RequestState';
import { SortableTable } from '../../table/SortableTable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns = [
    { id: 'spreadSequenceId', numeric: false, disablePadding: false, label: 'SPREAD Sequence ID' },
    { id: 'specimenSpecimenId', numeric: false, disablePadding: false, label: 'Specimen ID' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Date of Sequencing' },
];

interface ISequenceListProps {
    className?: string;
    showTitle?: boolean;
    specimenId?: number;
    url: string;
}

export const SequenceList: React.FunctionComponent<ISequenceListProps> = ({ className, showTitle, specimenId, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ specimenId }), [specimenId]);
    const pagOps = useMemo(() => ({ orderBy: "spreadSequenceId", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = sequencesHooks.useSearch(specOps, pagOps);

    return data && data.results && data.results.length > 0 && requestState.state !== RequestState.Pending ?
        <>
            {
                showTitle ?
                    <Typography variant="h4" component="h4">Sequences</Typography> :
                    null
            }
            <SortableTable
                className={classNames(className, classes.table)}
                headerColumns={headerColumns}
                paginateOptions={paginateOptions}
                rowCount={data ? data.total : 0}
                updatePaginateOptions={sequenceSearchActions.updatePaginateOptions}
            >
                {
                    data.results.map((item =>
                        <TableRow
                            className={classes.clickableRow}
                            key={item.id}
                        >
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`${url}/${item.id}`}>
                                    {item.spreadSequenceId}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.specimenSpecimenId || 'No Data'}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.date ? format(toDate(item.date), DateTimeFormat) : 'Not provided'}
                            </TableCell>
                        </TableRow>
                    ))
                }
            </SortableTable>
        </> :
        requestState.state === RequestState.Pending ?
            <Skeleton /> :
            <Typography variant="h6" component="h6">No Sequence</Typography>
}