
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngBoundsLiteral } from 'leaflet';

 
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { LatLngBounds } from '../../../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISnapMapBoundsWatcherProps {
    bounds?: LatLngBounds;
}

export const SnapMapBoundsWatcher: React.FunctionComponent<ISnapMapBoundsWatcherProps> = ({ bounds }) => {

    const map = useMap();

    useEffect(() => {
        if(bounds && map){
            const latLngLiteral: LatLngBoundsLiteral | undefined = [
                [bounds.latitudeCornerA, bounds.longitudeCornerA],
                [bounds.latitudeCornerB, bounds.longitudeCornerB]
            ];

            map.fitBounds(latLngLiteral);
        }
        
    }, [bounds])

    return null;
}