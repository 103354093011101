/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple enrichmentMethod.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialEnrichmentMethodSearchState = ISearchState<SpreadDtos.EnrichmentMethod>;

export interface IEnrichmentMethodSearchState {
    enrichmentMethodSearch: IInitialEnrichmentMethodSearchState;
}

export const initialState: IEnrichmentMethodSearchState = {
    enrichmentMethodSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const enrichmentMethodsModule = createAutoQueryModule(
    'enrichmentMethodSearch',
    initialSearchState,
    '@@enrichmentMethodSearch',
    SpreadDtos.QueryEnrichmentMethods,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const enrichmentMethodSearchActions = enrichmentMethodsModule.actions;
export const enrichmentMethodSearchEpics = enrichmentMethodsModule.epics;
export const enrichmentMethodSearchApi = enrichmentMethodsModule.api;
export const enrichmentMethodSearchSelectors = enrichmentMethodsModule.selectors;
export const enrichmentMethodSearchHooks = enrichmentMethodsModule.hooks;
export default enrichmentMethodsModule.reducer;