/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import countryModule from '../../store/reducers/countries/country';
import { RequestFormState } from '../../types/RequestState';
import { Disease } from '../../dtos/Spread.dtos';
import DiseaseEditForm from './DiseaseEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateDiseaseParams {
}

interface ICreateDiseaseProps {
    history: History;
    match: match<ICreateDiseaseParams>;
}

export const CreateDisease: React.FunctionComponent<ICreateDiseaseProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    return <Paper className={classes.root}>
            <DiseaseEditForm
                editDisease={new Disease({
                })}
                history={props.history}
                url={props.match.url}
            />
        </Paper>
}