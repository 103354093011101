/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { routeBackToPath } from '../../helpers/routeHelpers';
import GroupEpidemicEditForm from './GroupEpidemicEditForm';
import { EpidemicDO, PersonGroupDO } from '../../dtos/Spread.dtos';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateGroupEpidemicParams {
    epidemicId?: string;
    groupId?: string;
}

interface ICreateGroupEpidemicProps {
    epidemicId?: number;
    groupId?: number;
    history: History;
    match: match<ICreateGroupEpidemicParams>;
}

export const CreateGroupEpidemic: React.FunctionComponent<ICreateGroupEpidemicProps> = (props) => {
    const classes = useStyles();

    const params = parseQueryString(props.history.location.search);

    const epidemicId = props.epidemicId || (props.match.params.epidemicId ? parseInt(props.match.params.epidemicId) : (params ? Number(params.epidemicId) : undefined));
    const groupId = props.groupId || (props.match.params.groupId ? parseInt(props.match.params.groupId) : (params ? Number(params.groupId) : undefined));

    const url = props.match.url;

    const epidemicData = useSelector(state => state.epidemic).data;
    const groupData = useSelector(state => state.group).data;

    return <Paper className={classes.root}>
        <GroupEpidemicEditForm
            epidemicId={epidemicId}
            epidemicData={epidemicData}
            groupId={groupId}
            groupData={groupData}
            history={props.history}
            successRouteCallback={
                epidemicId ?
                    () => `${routeBackToPath(url, 2)}` :
                    groupId ?
                        () => `${routeBackToPath(url)}` :
                        undefined
            }
            url={props.match.url}
        />
    </Paper>
}
