/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for making and processing password reset requests
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { IStoreState } from '../../store';
import { authenticatedPersonActions } from './authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialPasswordResetState {
    data?: string;
    requestState: IFormRequestState;
    resetState: IFormRequestState;
}

export interface IPasswordResetState {
    passwordReset: IInitialPasswordResetState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@passwordReset';

export const PasswordResetTypes = {
    REQUEST: `${typeNamespace}/REQUEST`,
    REQUEST_FORM_RESPONSE: `${typeNamespace}/REQUEST_FORM_RESPONSE`,
    RESET: `${typeNamespace}/RESET`,
    RESET_FORM_RESPONSE: `${typeNamespace}/RESET_FORM_RESPONSE`,
    CLEAR: `${typeNamespace}/CLEAR`,
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialPasswordResetState = {
    data: undefined,
    requestState: {
        state: RequestFormState.None
    },
    resetState: {
        state: RequestFormState.None
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const passwordResetReducer = createReducer<IInitialPasswordResetState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const passwordResetActions = {
    /**
     * Begins the request to reset a password.
     */
    request: createAction(PasswordResetTypes.REQUEST,
        (email: string) => ({ email })
    ),
    /**
     * Stores the email/status after a request
     */
    requestResponse: createAction(PasswordResetTypes.REQUEST_FORM_RESPONSE,
        (response: SpreadDtos.RequestPasswordResetResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the process of resetting a password.
     */
    reset: createAction(PasswordResetTypes.RESET,
        (token: string, password: string) => ({ token, password })
    ),
    /**
     * Stores the status after a request
     */
    resetResponse: createAction(PasswordResetTypes.RESET_FORM_RESPONSE,
        (response: SpreadDtos.RequestPasswordResetResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the password reset state.
    */
    clear: createAction(PasswordResetTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Request Reducers
passwordResetReducer.on(passwordResetActions.request, (state: IInitialPasswordResetState) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            requestState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

passwordResetReducer.on(passwordResetActions.requestResponse, (state: IInitialPasswordResetState, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.email,
            },
            requestState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Reset Reducers
passwordResetReducer.on(passwordResetActions.reset, (state: IInitialPasswordResetState) => (
    update(
        state,
        {
            resetState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

passwordResetReducer.on(passwordResetActions.resetResponse, (state: IInitialPasswordResetState, payload) => (
    update(
        state,
        {
            resetState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
passwordResetReducer.on(passwordResetActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
passwordResetReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const passwordResetsApi = {
    request: createPostRequest(
        SpreadDtos.RequestPasswordReset,
        (email: string) => ({
            email: email,
        })
    ),
    reset: createPutRequest(
        SpreadDtos.ResetPassword,
        (token: string, password: string) => ({
            token: token,
            password: password,
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const requestEpic: Epic<ReturnType<typeof passwordResetActions.request>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(passwordResetActions.request.getType()),
    mergeMap(action =>
        passwordResetsApi.request(action.payload.email)
            .pipe(
                mergeMap(response => of(passwordResetActions.requestResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(passwordResetActions.requestResponse(e, RequestFormState.ServerError)))
            )
    ));

export const resetEpic: Epic<ReturnType<typeof passwordResetActions.reset>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(passwordResetActions.reset.getType()),
    mergeMap(action =>
        passwordResetsApi.reset(action.payload.token, action.payload.password)
            .pipe(
                mergeMap(response => of(passwordResetActions.resetResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(passwordResetActions.resetResponse(e, RequestFormState.ServerError)))
            )
    ));

export const passwordResetEpics = combineEpics(requestEpic, resetEpic);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const passwordResetSelectors = {
    data: (state: IStoreState) => state.passwordReset.data,
    requestState: (state: IStoreState) => state.passwordReset.requestState,
    resetState: (state: IStoreState) => state.passwordReset.resetState,
};

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default passwordResetReducer;