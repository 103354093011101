/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { areaDateTypeSearchHooks } from '../../store/reducers/areaDateTypes/areaDateTypeSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import { fieldSearchActions, fieldSearchHooks } from '../../store/reducers/fields/fieldSearch';
import { toDateFmt } from '@servicestack/client';
import { AnalysisDate } from '../areaDate/AnalysisDate';
import { useSelector } from '../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        fieldRow: {
        }
    }),
);

interface IFieldListProps {
    className?: string;
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    parcelId?: number;
    url: string;
}

export const FieldList: React.FunctionComponent<IFieldListProps> = ({ className, clusterId, epidemicId, history, infectedPremiseId, parcelId, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ clusterId: clusterId, epidemicId: epidemicId, infectedPremiseId: infectedPremiseId, parcelId: parcelId }), [clusterId, epidemicId, infectedPremiseId, parcelId]);
    const [data, paginateOptions, , requestState] = fieldSearchHooks.useSearch(specOps);

    const epidemic = useSelector(state => state.epidemic);

    const startDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isStartOfInfectiousPeriodDate);
    const endDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isEndOfInfectiousPeriodDate);

    const headerColumns = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'fieldDateSet.earliestStartOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: startDateType ? startDateType.name : 'Start of Infectious Period Date' },
        { id: 'fieldDateSet.latestEndOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: endDateType ? endDateType.name : 'End of Infectious Period Date' },
    ];

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={fieldSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.fieldRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/field/${item.id}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <AnalysisDate dateSet={item.fieldDateSet} dateType='startinfectious' />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <AnalysisDate dateSet={item.fieldDateSet} dateType='endinfectious' />
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}