/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple pathogens.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialPathogenSearchState = ISearchState<SpreadDtos.Pathogen>;

export interface IPathogenSearchState {
    pathogenSearch: IInitialPathogenSearchState;
}

export const initialState: IPathogenSearchState = {
    pathogenSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const pathogenSearchModule = createAutoQueryModule(
    'pathogenSearch',
    initialSearchState,
    '@@pathogenSearch',
    SpreadDtos.QueryPathogens,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const pathogenSearchActions = pathogenSearchModule.actions;
export const pathogenSearchEpics = pathogenSearchModule.epics;
export const pathogenSearchApi = pathogenSearchModule.api;
export const pathogenSearchSelectors = pathogenSearchModule.selectors;
export const pathogenSearchHooks = pathogenSearchModule.hooks;
export default pathogenSearchModule.reducer;