/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Parcel } from '../../dtos/Spread.dtos';
import ParcelEditForm from './ParcelEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateParcelParams {
    clusterId?: string;
    epidemicId?: string;
    infectedPremiseId?: string;
    parcelId?: string;
}

interface ICreateParcelProps {
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    match: match<ICreateParcelParams>;
}

export const CreateParcel: React.FunctionComponent<ICreateParcelProps> = (props) => {
    const classes = useStyles();

    const epidemicId = props.epidemicId || (props.match.params && props.match.params.epidemicId ? parseInt(props.match.params.epidemicId) : undefined);
    const clusterId = props.clusterId || (props.match.params && props.match.params.clusterId ? parseInt(props.match.params.clusterId) : undefined);
    const infectedPremiseId = props.infectedPremiseId || (props.match.params && props.match.params.infectedPremiseId ? parseInt(props.match.params.infectedPremiseId) : undefined);

    return <Paper className={classes.root}>
        <ParcelEditForm
            editParcel={new Parcel({
                //clusterId,
                //epidemicId,
                infectedPremiseId,
            })}
            history={props.history}
            url={props.match.url}
        />
    </Paper>
}