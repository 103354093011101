/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useAreaLivestockCountLoad } from '../../hooks/areaLivestockCount';
import { RequestFormState } from '../../types/RequestState';
import AreaLivestockCountEditForm from './AreaLivestockCountEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditAreaLivestockCountParams {
    areaLivestockCountId: string;
    clusterId: string;
    epidemicId: string;
}

interface IEditAreaLivestockCountProps {
    areaLivestockCountId?: number;
    history: History;
    match: match<IEditAreaLivestockCountParams>;
}

export const EditAreaLivestockCount: React.FunctionComponent<IEditAreaLivestockCountProps> = ({
    history,
    match,
    areaLivestockCountId,
}) => {
    const classes = useStyles();

    areaLivestockCountId = areaLivestockCountId || (match.params ? parseInt(match.params.areaLivestockCountId) : undefined);
    areaLivestockCountId = React.useMemo(() => areaLivestockCountId, [areaLivestockCountId]);

    if (!areaLivestockCountId) {
        throw ("Invalid ID specified");
    }

    const [AreaLivestockCountData, AreaLivestockCountState] = useAreaLivestockCountLoad(areaLivestockCountId);

    return <Paper className={classes.root}>
        <AreaLivestockCountEditForm
            editAreaLivestockCount={AreaLivestockCountData}
            history={history}
            loadingAreaLivestockCount={AreaLivestockCountState.state === RequestFormState.Pending && !AreaLivestockCountData}
            url={match.url}
        />
    </Paper>
}