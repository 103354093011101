/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../dtos/Spread.dtos';
import { useSelector } from '../../hooks/useTypedSelector';
import { importEpidemicActions } from '../../store/reducers/bulkUpload/importEpidemic';
import { RequestFormState } from '../../types/RequestState';
import AsyncForm from '../forms/AsyncForm';
import FileWrapper from '../forms/FinalFormControls/FileWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import NavigationBlockerDialog from '../routes/NavigationBlockerDialog';
import ImportFieldMapping from './ImportFieldMapping';



/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IImportEpidemicFormProps {
    epidemicId?: number;
    history: History;
    url: string;
    successRouteCallback?: (data?: SpreadDtos.ImportEpidemicResponse) => string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1)
        },
    }),
);

export interface IImportEpidemicFormSchema {
    dateTimeFormat: string;
    epidemicId: number;
    files: SpreadDtos.File[];
    fieldMapping: any;
}

const importEpidemicSchema = Yup.object<IImportEpidemicFormSchema>().shape({
    epidemicId: Yup.number().label('Epidemic').required(),
    files: Yup.array().of(Yup.string()).label('File').required(),
    dateTimeFormat: Yup.string().label('Date Format').required(),
    fieldMapping: Yup.object().notRequired(),
});

const validation = Yup.object().shape({
    importEpidemic: importEpidemicSchema.notRequired()
})

const ImportEpidemicForm: React.FunctionComponent<IImportEpidemicFormProps> = ({
    epidemicId,
    history,
    url,
    successRouteCallback
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const importState = useSelector(state => state.importEpidemic);

    useEffect(() => {
        return function cleanup() {
            dispatch(importEpidemicActions.clear());
        }
    }, [dispatch, importEpidemicActions])

    var successRedirectComponent: any = null;

    if (importState.importState.state === RequestFormState.SubmitSuccess) {
        const toRoute: string = successRouteCallback ? successRouteCallback(importState.importResponse) : '/';

        successRedirectComponent = <Redirect push={true} to={toRoute} />
    }

    return <AsyncForm
        start={importEpidemicActions.import}
        resolve={importEpidemicActions.importFormResponse}
        reject={importEpidemicActions.importFormResponse}
        mutators={{ clear: clear }}
        formSubscription={{ dirtySinceLastSubmit: true, pristine: true, submitting: true }}
        validate={validation}
    >{({ dirtySinceLastSubmit, pristine, submitting }) => <>
        {successRedirectComponent}
        <Grid item lg={6} xs={12}>
            <Field
                accept="text/csv"
                name="importEpidemic.files"
                label="Import File"
            >
                {
                    props => (
                        <FileWrapper
                            buttonText={'Select Import File'}
                            className={classes.formControl}
                            label={'Import File'}
                            maxFiles={1}
                            {...props}
                        />
                    )
                }
            </Field>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Field
                required
                className={classes.formControl}
                name="importEpidemic.dateTimeFormat"
                component={TextFieldWrapper}
                label="Date Format"
                defaultValue={'d/MM/yyyy'}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <Field
                required
                disabled
                component={'input'}
                name="importEpidemic.epidemicId"
                defaultValue={epidemicId}
                type={'hidden'}
            />
        </Grid>
        <Grid item lg={4} xs={12}>
            <Field name="importEpidemic.files">
                {
                    fileFieldProps => <ImportFieldMapping
                        className={classes.formControl}
                        epidemicId={epidemicId}
                        importType={SpreadDtos.ImportType.Epidemic}
                        importIpKey={undefined}
                        name={"importEpidemic.fieldMapping"}
                        temporaryFile={fileFieldProps.input.value && fileFieldProps.input.value[0]}
                    />
                }
            </Field>
        </Grid>
        <Grid item lg={6} xs={12}>
            <FormSpy subscription={{ submitError: true }}>
                {
                    formErrorProps => (
                        formErrorProps.submitError ? <Typography className={classes.formControl} color={'error'} variant="body1" component="p">{formErrorProps.submitError}</Typography> : null
                    )
                }
            </FormSpy>
            <Button
                variant="contained"
                size="small"
                className={classes.buttonSubmit}
                type="submit"
                color="primary"
                disabled={pristine || submitting}
            >
                Submit
            </Button>
        </Grid>
        <NavigationBlockerDialog block={importState.importState.state !== RequestFormState.SubmitSuccess && (!pristine || dirtySinceLastSubmit)} />

    </>}
    </AsyncForm>
};

export default ImportEpidemicForm;