/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an material
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateMaterial,
    CreateMaterialResponse,
    Material,
    DeleteMaterial,
    DeleteMaterialResponse,
    GetMaterial,
    GetMaterialResponse,
    UpdateMaterial,
    UpdateMaterialResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IMaterialState {
    material: IBaseCrudState<Material>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const materialModule = createCrudModule<Material, CreateMaterialResponse, GetMaterialResponse, UpdateMaterialResponse, DeleteMaterialResponse, undefined>(
    'material',
    CreateMaterial,
    GetMaterial,
    UpdateMaterial,
    DeleteMaterial,
    undefined,
)


export default materialModule;