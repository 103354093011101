/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { authenticatedPersonActions } from '../../store/reducers/person/authenticatedPerson';
import { AuthState } from '../../types/AuthState';
import { useSelector } from '../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function AuthBaseline() {
    const personState = useSelector(state => state.authenticatedPerson);
    const dispatch = useDispatch();

    const onLoadSession = useCallback(() => {
        dispatch(authenticatedPersonActions.auth())
    }, [dispatch])

    if (personState.authState === AuthState.Unknown) {
        onLoadSession();
    }

    return null;
}