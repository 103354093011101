/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains common types, actions and reducers for form integrated CRUD
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { Action, ComplexActionCreator, createReducer, Reducer } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IDelete, IGet, IPost, IPut, IReturn } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { useSelector } from '../../../hooks/useTypedSelector';
import { ReplaceReturnType } from '../../../types/HelperTypes';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------------
 */

export interface IBaseCrudStates {
    createState: IFormRequestState;
    loadState: IFormRequestState;
    updateState: IFormRequestState;
    deleteState: IFormRequestState;
    archiveState: IFormRequestState;
}

export interface IBaseCrudState<DataObjectType = any> {
    data?: DataObjectType;
    form?: DataObjectType;
    states: IBaseCrudStates;
}

// Create Initial State
export const baseCrudInitialState = <ObjectType>(): IBaseCrudState<ObjectType> => {
    return {
        data: undefined,
        form: undefined,
        states: {
            createState: {
                state: RequestFormState.None
            },
            loadState: {
                state: RequestFormState.None
            },
            updateState: {
                state: RequestFormState.None
            },
            deleteState: {
                state: RequestFormState.None
            },
            archiveState: {
                state: RequestFormState.None
            },
        },
    };
}

export interface IBaseCrudTypes {
    CREATE: string;
    CREATE_FORM_RESPONSE: string;
    LOAD: string;
    LOAD_FORM_RESPONSE: string;
    UPDATE: string;
    UPDATE_FORM_RESPONSE: string;
    DELETE: string;
    DELETE_FORM_RESPONSE: string;
    ARCHIVE: string;
    ARCHIVE_FORM_RESPONSE: string;
    CLEAR: string;
    CLEAR_FORM: string;
}

export type ActionType<T extends ((...args: any) => any)> = ReplaceReturnType<T, {
    type: string;
    payload: any;
    meta: {};
}> & ComplexActionCreator<ReturnType<T>, {}>;

export interface IBaseCrudActions<
    ObjectType = any,
    CreateResponseType = any,
    LoadResponseType = any,
    UpdateResponseType = any,
    DeleteResponseType = any,
    ArchiveResponseType = any,
    > {
    create?: ActionType<(object: ObjectType) => { object: ObjectType }>;
    createFormResponse?: ActionType<(response: CreateResponseType, state: RequestFormState) => { response: CreateResponseType, state: RequestFormState }>;
    load?: ActionType<(id: number) => { id: number }>;
    loadFormResponse?: ActionType<(response: LoadResponseType, state: RequestFormState) => { response: LoadResponseType, state: RequestFormState }>;
    update?: ActionType<(object: ObjectType) => { object: ObjectType }>;
    updateFormResponse?: ActionType<(response: UpdateResponseType, state: RequestFormState) => { response: UpdateResponseType, state: RequestFormState }>;
    delete?: ActionType<(id: number, ...params: any[]) => { id: number }>;
    deleteFormResponse?: ActionType<(response: DeleteResponseType, state: RequestFormState) => { response: DeleteResponseType, state: RequestFormState }>;
    archive?: ActionType<(id: number) => { id: number }>;
    archiveFormResponse?: ActionType<(response: ArchiveResponseType, state: RequestFormState) => { response: ArchiveResponseType, state: RequestFormState }>;
    clear: ActionType<() => {}>;
    clearFormState: ActionType<() => {}>;
}

export interface IBaseCrudApi {
    create?: ReturnType<typeof createPostRequest>;
    load?: ReturnType<typeof createGetRequest>;
    update?: ReturnType<typeof createPutRequest>;
    delete?: ReturnType<typeof createDeleteRequest>;
    archive?: ReturnType<typeof createPutRequest>;
}

export interface IBaseCrudSelectors<ObjectType, StateType extends IBaseCrudState<ObjectType>> {
    data: (state: any) => StateType["data"];
    form: (state: any) => StateType["form"];
    createState: (state: any) => StateType["states"]["createState"];
    loadState: (state: any) => StateType["states"]["loadState"];
    updateState: (state: any) => StateType["states"]["updateState"];
    deleteState: (state: any) => StateType["states"]["deleteState"];
    archiveState: (state: any) => StateType["states"]["archiveState"];
}

export interface IBaseCrudHooks<ObjectType = any> {
    useLoad: (id: number) => [ObjectType | undefined, IFormRequestState];
}


/*
 * ---------------------------------------------------------------------------------
 * Module Creator
 * ---------------------------------------------------------------------------------
 */

export function createCrudModule<
    ObjectType,
    CreateResponseType,
    LoadResponseType,
    UpdateResponseType,
    DeleteResponseType,
    ArchiveResponseType,
    StateType extends IBaseCrudState<ObjectType> = IBaseCrudState<ObjectType>,
    ActionsType extends IBaseCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType> = IBaseCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType>,
    ApiType extends IBaseCrudApi = IBaseCrudApi,
    SelectorsType extends IBaseCrudSelectors<ObjectType, StateType> = IBaseCrudSelectors<ObjectType, StateType>,
    HooksType extends IBaseCrudHooks<ObjectType> = IBaseCrudHooks<ObjectType>,
    >
    (
        stateKey: string,
        createRequest?: new () => IReturn<CreateResponseType> & IPost,
        loadRequest?: new () => IReturn<LoadResponseType> & IGet & { id: number },
        updateRequest?: new () => IReturn<UpdateResponseType> & IPut,
        deleteRequest?: new () => IReturn<DeleteResponseType> & IDelete & { id: number },
        archiveRequest?: new () => IReturn<ArchiveResponseType> & IPut & { id: number },
        initialState: StateType = baseCrudInitialState<ObjectType>() as StateType,
) {
    return createCrudModuleWithNamedRequestObject<
        ObjectType,
        CreateResponseType,
        LoadResponseType,
        UpdateResponseType,
        DeleteResponseType,
        ArchiveResponseType,
        StateType,
        ActionsType,
        ApiType,
        SelectorsType,
        HooksType
    >(stateKey, stateKey, createRequest, loadRequest, updateRequest, deleteRequest, archiveRequest, initialState);
}

export function createCrudModuleWithNamedRequestObject<
    ObjectType,
    CreateResponseType,
    LoadResponseType,
    UpdateResponseType,
    DeleteResponseType,
    ArchiveResponseType,
    StateType extends IBaseCrudState<ObjectType> = IBaseCrudState<ObjectType>,
    ActionsType extends IBaseCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType> = IBaseCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType>,
    ApiType extends IBaseCrudApi = IBaseCrudApi,
    SelectorsType extends IBaseCrudSelectors<ObjectType, StateType> = IBaseCrudSelectors<ObjectType, StateType>,
    HooksType extends IBaseCrudHooks<ObjectType> = IBaseCrudHooks<ObjectType>,
    >
    (
        stateKey: string,
        requestObjectName: string,
        createRequest?: new () => IReturn<CreateResponseType> & IPost,
        loadRequest?: new () => IReturn<LoadResponseType> & IGet & { id: number },
        updateRequest?: new () => IReturn<UpdateResponseType> & IPut,
        deleteRequest?: new () => IReturn<DeleteResponseType> & IDelete & { id: number },
        archiveRequest?: new () => IReturn<ArchiveResponseType> & IPut & { id: number },
        initialState: StateType = baseCrudInitialState<ObjectType>() as StateType,
) {
    // Create Action Types
    const actionTypes = createCrudActionTypes(stateKey);

    // Create Reducer Module
    const reducer = createReducer<StateType>({}, initialState);

    // Create Actions
    const actions = createCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType>(actionTypes) as ActionsType;

    // Apply Reducers
    applyCrudReducers(reducer, actions, requestObjectName, initialState);

    // LogoutClear Reducer
    reducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

    // Create APIs
    const api = createCrudApis(requestObjectName, createRequest, loadRequest, updateRequest, deleteRequest, archiveRequest) as ApiType;

    // Create Epics
    const epics = createCrudEpics(actions, api);

    // Create Selectords
    const selectors = createCrudSelectors(stateKey) as SelectorsType;

    // Create Hooks
    const hooks = createCrudHooks<ObjectType>(stateKey, actions) as HooksType;

    return {
        actionTypes,
        reducer,
        actions,
        api,
        epics,
        selectors,
        hooks,
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Dummy Functions
 * ---------------------------------------------------------------------------------
 */

export const dummyObservable = () => {
    return new Observable<any>();
}

export const dummyAction = createAction('DUMMY', dummyObservable);

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

export function createCrudActionTypes(namespace: string): IBaseCrudTypes {
    const actionTypes = {
        CREATE: `${namespace}/CREATE`,
        CREATE_FORM_RESPONSE: `${namespace}/CREATE_FORM_RESPONSE`,
        LOAD: `${namespace}/LOAD`,
        LOAD_FORM_RESPONSE: `${namespace}/LOAD_FORM_RESPONSE`,
        UPDATE: `${namespace}/UPDATE`,
        UPDATE_FORM_RESPONSE: `${namespace}/UPDATE_FORM_RESPONSE`,
        DELETE: `${namespace}/DELETE`,
        DELETE_FORM_RESPONSE: `${namespace}/DELETE_FORM_RESPONSE`,
        ARCHIVE: `${namespace}/ARCHIVE`,
        ARCHIVE_FORM_RESPONSE: `${namespace}/ARCHIVE_FORM_RESPONSE`,
        CLEAR: `${namespace}/CLEAR`,
        CLEAR_FORM: `${namespace}/CLEAR_FORM_REQUEST_STATE`,
    };

    return actionTypes as IBaseCrudTypes;
}

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export function createCrudActions<
    ObjectType = any,
    CreateResponseType = any,
    LoadResponseType = any,
    UpdateResponseType = any,
    DeleteResponseType = any,
    ArchiveResponseType = any,
    >(
        actionTypes: IBaseCrudTypes,
): IBaseCrudActions<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType> {
    return {
        create: createAction(actionTypes.CREATE,
            (object: ObjectType) => ({ object })
        ),
        createFormResponse: createAction(actionTypes.CREATE_FORM_RESPONSE,
            (response: CreateResponseType, state: RequestFormState) => ({ response, state })
        ),
        load: createAction(actionTypes.LOAD,
            (id: number) => ({ id })
        ),
        loadFormResponse: createAction(actionTypes.LOAD_FORM_RESPONSE,
            (response: LoadResponseType, state: RequestFormState) => ({ response, state })
        ),
        update: createAction(actionTypes.UPDATE,
            (object: ObjectType) => ({ object })
        ),
        updateFormResponse: createAction(actionTypes.UPDATE_FORM_RESPONSE,
            (response: UpdateResponseType, state: RequestFormState) => ({ response, state })
        ),
        delete: createAction(actionTypes.DELETE,
            (id: number) => ({ id })
        ),
        deleteFormResponse: createAction(actionTypes.DELETE_FORM_RESPONSE,
            (response: DeleteResponseType, state: RequestFormState) => ({ response, state })
        ),
        archive: createAction(actionTypes.ARCHIVE,
            (id: number) => ({ id })
        ),
        archiveFormResponse: createAction(actionTypes.ARCHIVE_FORM_RESPONSE,
            (response: ArchiveResponseType, state: RequestFormState) => ({ response, state })
        ),
        clear: createAction(actionTypes.CLEAR,
            () => ({})
        ),
        clearFormState: createAction(actionTypes.CLEAR_FORM,
            () => ({})
        ),
    };
}


/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

export function applyCrudReducers<StateType extends IBaseCrudState>(myReducer: Reducer<any, AnyAction>, myActions: IBaseCrudActions, payloadStringPart: string, initialState: StateType) {
    if (myActions.create && myActions.createFormResponse) {
        // The start of a request to create an object
        myReducer.on(myActions.create, (state: StateType) => (
            createObjectReducer(state)
        ));

        // The response from the server from a form submission
        myReducer.on(myActions.createFormResponse, (state: StateType, payload) => (
            createFormResponseReducer(state, payload, payload.response[payloadStringPart])
        ));
    }

    if (myActions.load && myActions.loadFormResponse) {
        // The start of a request to load an object
        myReducer.on(myActions.load, (state: StateType) => (
            loadReducer(state)
        ));

        // The response from the server from a form submission
        myReducer.on(myActions.loadFormResponse, (state: StateType, payload) => (
            loadFormResponseReducer(state, payload, payload.response[payloadStringPart])
        ));
    }

    if (myActions.update && myActions.updateFormResponse) {
        // The start of a request to update an object
        myReducer.on(myActions.update, (state: StateType) => (
            updateReducer(state)
        ));

        // The response from the server from a form submission
        myReducer.on(myActions.updateFormResponse, (state: StateType, payload) => (
            updateFormResponseReducer(state, payload, payload.response[payloadStringPart])
        ));
    }

    if (myActions.delete && myActions.deleteFormResponse) {
        // The start of a request to delete an object
        myReducer.on(myActions.delete, (state: StateType) => (
            deleteReducer(state)
        ));

        // The response from the server from a form submission
        myReducer.on(myActions.deleteFormResponse, (state: StateType, payload) => (
            deleteFormResponseReducer(state, payload)
        ));
    }

    if (myActions.archive && myActions.archiveFormResponse) {
        // The start of a request to archive an object
        myReducer.on(myActions.archive, (state: StateType) => (
            archiveReducer(state)
        ));

        // The response from the server from a form submission
        myReducer.on(myActions.archiveFormResponse, (state: StateType, payload) => (
            archiveFormResponseReducer(state, payload)
        ));
    }

    // Clear everything
    myReducer.on(myActions.clear, (state: StateType) => (
        clearReducer(state, initialState)
    ));

    // Clear the form request states
    myReducer.on(myActions.clearFormState, (state: StateType) => (
        clearFormReducer(state, initialState)
    ));
};

const createObjectReducer = (state: IBaseCrudState) => update(
    state,
    {
        states: {
            createState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const createFormResponseReducer = (state: IBaseCrudState, payload: any, data: any) => {
    return update(
        state,
        {
            data: {
                $set: data
            },
            form: {
                $set: data
            },
            states: {
                createState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
}

const loadReducer = (state: IBaseCrudState) => update(
    state,
    {
        states: {
            loadState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const loadFormResponseReducer = (state: IBaseCrudState, payload: any, data: any) => {
    return update(
        state,
        {
            data: {
                $set: data
            },
            form: {
                $set: data
            },
            states: {
                loadState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
}

const updateReducer = (state: IBaseCrudState) => update(
    state,
    {
        states: {
            updateState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const updateFormResponseReducer = (state: IBaseCrudState, payload: any, data: any) => {
    // Only replace the data in the state if it was a success,
    // otherwise it was probably a failed submission and 
    // we don't want that bad data
    const newState = payload.state === RequestFormState.SubmitSuccess ? update(
        state,
        {
            data: {
                $set: data
            },
            form: {
                $set: data
            },
        }
    ) : state;

    return update(
        newState,
        {
            states: {
                updateState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
}

const deleteReducer = (state: IBaseCrudState) => update(
    state,
    {
        states: {
            deleteState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const deleteFormResponseReducer = (state: IBaseCrudState, payload: any) => {
    return update(
        state,
        {
            states: {
                deleteState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
}

const archiveReducer = (state: IBaseCrudState) => update(
    state,
    {
        states: {
            archiveState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const archiveFormResponseReducer = (state: IBaseCrudState, payload: any) => {
    return update(
        state,
        {
            states: {
                archiveState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
}

// Clear Reducer
const clearReducer = <ObjectType, StateType extends IBaseCrudState<ObjectType>>(state: StateType, initialState: StateType) => update(
    state,
    {
        $set: initialState,
    }
);

// Clear Form State Reducer
const clearFormReducer = <ObjectType, StateType extends IBaseCrudState<ObjectType>>(state: IBaseCrudState<ObjectType>, initialState: StateType) => {
    const data = state.data;

    const newState = update(
        state,
        {
            $set: initialState,
        }
    );

    return update(
        newState,
        {
            data: {
                $set: data
            },
            form: {
                $set: data
            },
        }
    )
};

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export function createCrudApis<ObjectType, CreateResponseType, LoadResponseType, UpdateResponseType, DeleteResponseType, ArchiveResponseType>(
    objectName: string,
    createRequest?: new () => IReturn<CreateResponseType> & IPost,
    loadRequest?: new () => IReturn<LoadResponseType> & IGet & { id: number },
    updateRequest?: new () => IReturn<UpdateResponseType> & IPut,
    deleteRequest?: new () => IReturn<DeleteResponseType> & IDelete & { id: number },
    archiveRequest?: new () => IReturn<ArchiveResponseType> & IPut & { id: number },
): IBaseCrudApi {
    return {
        create: createRequest ? createPostRequest(
            createRequest,
            (object: ObjectType) => ({
                [objectName]: object
            })
        ) : undefined,
        load: loadRequest ? createGetRequest(
            loadRequest,
            (id: number) => ({
                id
            })
        ) : undefined,
        update: updateRequest ? createPutRequest(
            updateRequest,
            (object) => ({
                [objectName]: object
            })
        ) : undefined,
        delete: deleteRequest ? createDeleteRequest(
            deleteRequest,
            (id: number) => ({
                id
            })
        ) : undefined,
        archive: archiveRequest ? createPutRequest(
            archiveRequest,
            (id: number) => ({
                id
            })
        ) : undefined,
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export function createCrudEpics(
    actions: IBaseCrudActions,
    api: IBaseCrudApi,
    additionalCreateParams?: string[],
    additionalLoadParams?: string[],
    additionalUpdateParams?: string[],
    additionalDeleteParams?: string[],
    additionalArchiveParams?: string[],
) {
    // This function is really hacky because TypeScript doesn't like to nest evaluated types and the actions are sometimes undefined

    let createEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> | undefined = undefined;
    if (actions.create && actions.createFormResponse && api.create) {
        createEpic = (action$, state$: StateObservable<any>) => {
            if (actions.create) {
                return action$.pipe(
                    ofType(actions.create.getType()),
                    mergeMap(action => {
                        if (api.create) {
                            const params = additionalCreateParams ? additionalCreateParams.map(p => action.payload[p]) : [];
                            return api.create(action.payload.object, ...params)
                                .pipe(
                                    mergeMap(response => {
                                        if (actions.createFormResponse) {
                                            return of(actions.createFormResponse(response, RequestFormState.SubmitSuccess));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    }),
                                    catchError(e => {
                                        if (actions.createFormResponse) {
                                            return of(actions.createFormResponse(e, RequestFormState.ServerError));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    })
                                );
                        }
                        // #region dummy code
                        // This should never be reached
                        return dummyObservable()
                            .pipe(
                                mergeMap(() => of(dummyObservable())),
                                catchError(() => of(dummyObservable()))
                            )
                        //#endregion
                    })
                )
            }
            // #region dummy code
            // This should never be reached
            return action$.pipe(
                ofType(dummyAction.getType()),
                mergeMap(() =>
                    dummyObservable()
                        .pipe(
                            mergeMap(() => of(dummyObservable())),
                            catchError(() => of(dummyObservable()))
                        )
                ))
            //#endregion
        };
    }

    let loadEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> | undefined = undefined;
    if (actions.load && actions.loadFormResponse && api.load) {
        loadEpic = (action$, state$: StateObservable<any>) => {
            if (actions.load) {
                return action$.pipe(
                    ofType(actions.load.getType()),
                    mergeMap(action => {
                        if (api.load) {
                            const params = additionalLoadParams ? additionalLoadParams.map(p => action.payload[p]) : [];
                            return api.load(action.payload.id, ...params)
                                .pipe(
                                    mergeMap(response => {
                                        if (actions.loadFormResponse) {
                                            return of(actions.loadFormResponse(response, RequestFormState.SubmitSuccess));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    }),
                                    catchError(e => {
                                        if (actions.loadFormResponse) {
                                            return of(actions.loadFormResponse(e, RequestFormState.ServerError));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    })
                                );
                        }
                        // #region dummy code
                        // This should never be reached
                        return dummyObservable()
                            .pipe(
                                mergeMap(() => of(dummyObservable())),
                                catchError(() => of(dummyObservable()))
                            )
                        //#endregion
                    })
                )
            }
            // #region dummy code
            // This should never be reached
            return action$.pipe(
                ofType(dummyAction.getType()),
                mergeMap(() =>
                    dummyObservable()
                        .pipe(
                            mergeMap(() => of(dummyObservable())),
                            catchError(() => of(dummyObservable()))
                        )
                ))
            //#endregion
        };
    }

    let updateEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> | undefined = undefined;
    if (actions.update && actions.updateFormResponse && api.update) {
        updateEpic = (action$, state$: StateObservable<any>) => {
            if (actions.update) {
                return action$.pipe(
                    ofType(actions.update.getType()),
                    mergeMap(action => {
                        if (api.update) {
                            const params = additionalUpdateParams ? additionalUpdateParams.map(p => action.payload[p]) : [];
                            return api.update(action.payload.object, ...params)
                                .pipe(
                                    mergeMap(response => {
                                        if (actions.updateFormResponse) {
                                            return of(actions.updateFormResponse(response, RequestFormState.SubmitSuccess))
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    }),
                                    catchError(e => {
                                        if (actions.updateFormResponse) {
                                            return of(actions.updateFormResponse(e, RequestFormState.ServerError))
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    })
                                )
                        }
                        // #region dummy code
                        // This should never be reached
                        return dummyObservable()
                            .pipe(
                                mergeMap(() => of(dummyObservable())),
                                catchError(() => of(dummyObservable()))
                            )
                        //#endregion
                    })
                );
            }
            // #region dummy code
            // This should never be reached
            return action$.pipe(
                ofType(dummyAction.getType()),
                mergeMap(() =>
                    dummyObservable()
                        .pipe(
                            mergeMap(() => of(dummyObservable())),
                            catchError(() => of(dummyObservable()))
                        )
                ))
            //#endregion
        };
    }

    let deleteEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> | undefined = undefined;
    if (actions.delete && actions.deleteFormResponse && api.delete) {
        deleteEpic = (action$, state$: StateObservable<any>) => {
            if (actions.delete) {
                return action$.pipe(
                    ofType(actions.delete.getType()),
                    mergeMap(action => {
                        if (api.delete) {
                            const params = additionalDeleteParams ? additionalDeleteParams.map(p => action.payload[p]) : [];
                            return api.delete(action.payload.id, ...params)
                                .pipe(
                                    mergeMap(response => {
                                        if (actions.deleteFormResponse) {
                                            return of(actions.deleteFormResponse(response, RequestFormState.SubmitSuccess));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    }),
                                    catchError(e => {
                                        if (actions.deleteFormResponse) {
                                            return of(actions.deleteFormResponse(e, RequestFormState.ServerError));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    })
                                );
                        }
                        // #region dummy code
                        // This should never be reached
                        return dummyObservable()
                            .pipe(
                                mergeMap(() => of(dummyObservable())),
                                catchError(() => of(dummyObservable()))
                            )
                        //#endregion
                    })
                );
            }
            // #region dummy code
            // This should never be reached
            return action$.pipe(
                ofType(dummyAction.getType()),
                mergeMap(() =>
                    dummyObservable()
                        .pipe(
                            mergeMap(() => of(dummyObservable())),
                            catchError(() => of(dummyObservable()))
                        )
                ))
            //#endregion
        };
    }

    let archiveEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> | undefined = undefined;
    if (actions.archive && actions.archiveFormResponse && api.archive) {
        archiveEpic = (action$, state$: StateObservable<any>) => {
            if (actions.archive) {
                return action$.pipe(
                    ofType(actions.archive.getType()),
                    mergeMap(action => {
                        if (api.archive) {
                            const params = additionalArchiveParams ? additionalArchiveParams.map(p => action.payload[p]) : [];
                            return api.archive(action.payload.id, ...params)
                                .pipe(
                                    mergeMap(response => {
                                        if (actions.archiveFormResponse) {
                                            return of(actions.archiveFormResponse(response, RequestFormState.SubmitSuccess));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    }),
                                    catchError(e => {
                                        if (actions.archiveFormResponse) {
                                            return of(actions.archiveFormResponse(e, RequestFormState.ServerError));
                                        }
                                        // #region dummy code
                                        // This should never be reached
                                        return of(dummyObservable());
                                        //#endregion
                                    })
                                );
                        }
                        // #region dummy code
                        // This should never be reached
                        return dummyObservable()
                            .pipe(
                                mergeMap(() => of(dummyObservable())),
                                catchError(() => of(dummyObservable()))
                            )
                        //#endregion
                    })
                )
            }
            // #region dummy code
            // This should never be reached
            return action$.pipe(
                ofType(dummyAction.getType()),
                mergeMap(() =>
                    dummyObservable()
                        .pipe(
                            mergeMap(() => of(dummyObservable())),
                            catchError(() => of(dummyObservable()))
                        )
                ))
            //#endregion
        };
    }

    let definedEpics: Epic<Action<any>, any>[] = [];
    if (createEpic)
        definedEpics.push(createEpic);
    if (loadEpic)
        definedEpics.push(loadEpic);
    if (updateEpic)
        definedEpics.push(updateEpic);
    if (deleteEpic)
        definedEpics.push(deleteEpic);
    if (archiveEpic)
        definedEpics.push(archiveEpic);

    return combineEpics(...definedEpics)
}

/*
 * ---------------------------------------------------------------------------------
 * Selectors & Hooks
 * ---------------------------------------------------------------------------------
 */
export const createCrudSelectors = <ObjectType, StateType extends IBaseCrudState<ObjectType>>(reducerPropertyName: string): IBaseCrudSelectors<ObjectType, StateType> => {
    return {
        data: (state: any) => state[reducerPropertyName].data,
        form: (state: any) => state[reducerPropertyName].form,
        createState: (state: any) => state[reducerPropertyName].states.createState,
        loadState: (state: any) => state[reducerPropertyName].states.loadState,
        updateState: (state: any) => state[reducerPropertyName].states.updateState,
        deleteState: (state: any) => state[reducerPropertyName].states.deleteState,
        archiveState: (state: any) => state[reducerPropertyName].states.archiveState,
    };
}

export function createCrudHooks<ObjectType>(reducerPropertyName: string, actions: IBaseCrudActions<ObjectType>) {
    return {
        useLoad: (id: number): [ObjectType | undefined, IFormRequestState] => {
            const dispatch = useDispatch();
            const data = useSelector<ObjectType>(state => state[reducerPropertyName].data);
            const requestState = useSelector(state => state[reducerPropertyName].states.loadState);

            useEffect(() => {
                if (id && id > 0 && actions.load) {
                    dispatch(actions.load(id));
                }

                return function cleanup() {
                    if (actions.clear) {
                        dispatch(actions.clear());
                    }
                }
            }, [dispatch, id]);

            return [
                data,
                requestState
            ];
        }
    }
}
