/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { isNumber } from 'util';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { useSelector } from '../../hooks/useTypedSelector';
import { diseaseClassificationSearchHooks } from '../../store/reducers/diseaseClassifications/diseaseClassificationSearch';
import { countriesHooks } from '../../store/reducers/countries/countrySearch';
import diseaseModule from '../../store/reducers/diseases/disease';
import { diseaseSearchHooks } from '../../store/reducers/diseases/diseaseSearch';
import { diseaseSubClassificationSearchHooks } from '../../store/reducers/diseaseSubClassifications/diseaseSubClassificationSearch';
import { subNationalSearchHooks } from '../../store/reducers/subNationals/subNationalSearch';
import { RequestFormState, RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import MultiSelectWrapper from '../forms/FinalFormControls/MultiSelectWrapper';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import epidemicModule from '../../store/reducers/epidemics/epidemic';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IEpidemicEditFormProps {
    editEpidemic?: SpreadDtos.Epidemic;
    history: History;
    loadingEpidemic?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IEpidemicFormSchema extends Partial<SpreadDtos.Epidemic> {
    name: string;
    countryId: number;
    subNationalId: number;
    year: number;
    diseaseId: number;
    diseaseClassificationId: number;
}

const epidemicSchema = Yup.object<IEpidemicFormSchema>({
    name: Yup.string().label('Epidemic name')
        .required(),
    countryId: Yup.number().label('Country')
        .required(),
    year: Yup.number().typeError('Year must be a number').label('Year')
        .required().positive(),
    diseaseId: Yup.number().label('Disease')
        .required(),
    diseaseClassificationId: Yup.number().label('Classification'),
    diseaseSubClassificationIds: Yup.mixed().notRequired(),
    subNationalId: Yup.mixed().notRequired(),
});

const validation = Yup.object().shape({
    object: epidemicSchema.notRequired(),
})

const EpidemicEditForm: React.FunctionComponent<IEpidemicEditFormProps> = ({
    editEpidemic,
    history,
    loadingEpidemic,
    url,
}) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const [countryId, setCountryId] = React.useState(undefined as number | undefined);
    const [subNationalId, setSubNationalId] = React.useState(undefined as number | undefined);
    const [diseaseId, setDiseaseId] = React.useState(undefined as number | undefined);
    const [diseaseClassificationId, setDiseaseClassificationId] = React.useState(undefined as number | undefined);

    if (!countryId && editEpidemic && editEpidemic.countryId) {
        setCountryId(editEpidemic.countryId);
    }

    const updateCountryId = (countryId: number | undefined) => {
        setCountryId(countryId);
    }

    if (!subNationalId && editEpidemic && editEpidemic.subNationalId) {
        setSubNationalId(editEpidemic.subNationalId);
    }

    const updateSubNationalId = (subNationalId: number | undefined) => {
        setSubNationalId(subNationalId);
    }

    if (!diseaseId && editEpidemic && editEpidemic.diseaseId) {
        setDiseaseId(editEpidemic.diseaseId);
    }

    const updateDiseaseId = (diseaseId: number | undefined) => {
        setDiseaseId(diseaseId);
    }

    const updateDiseaseClassificationId = (diseaseClassificationId: number | undefined) => {
        setDiseaseClassificationId(diseaseClassificationId);
    }

    const dcSpecOps = React.useMemo(() => ({ diseaseId: diseaseId }), [diseaseId]);
    const dcPagOps = React.useMemo(() => ({ skip: 0, take: 100, orderBy: "Value" }), []);

    const dscSpecOps = React.useMemo(() => ({ diseaseClassificationId: diseaseClassificationId }), [diseaseClassificationId]);
    const dscPagOps = React.useMemo(() => ({ skip: 0, take: 100, orderBy: "Value" }), []);

    const [countryData, , , countryRequestState] = countriesHooks.useSearch();

    const snSpecOps = useMemo(() => ({ countryId: countryId }), [countryId]);
    const snPagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 100 }), []);
    const [subNationalData, , , subNationalRequestState] = subNationalSearchHooks.useSearch(snSpecOps, snPagOps);
    const [diseasesData, , , diseasesRequestState] = diseaseSearchHooks.useSearch();
    const [diseaseClassificationsData, , ,] = diseaseClassificationSearchHooks.useSearch(dcSpecOps, dcPagOps);
    const [diseaseSubClassificationsData, , ,] = diseaseSubClassificationSearchHooks.useSearch(dscSpecOps, dscPagOps);

    const diseaseState = useSelector(state => state.disease);
    useEffect(() => {
        if (diseaseModule.actions.load && diseaseId && diseaseId > 0) {
            dispatch(diseaseModule.actions.load(diseaseId))
        }
    }, [dispatch, diseaseId]);
    const diseaseIsLoading = diseaseState.states.loadState.state === RequestFormState.Pending && !diseaseState.data;

    const countryOptions = countryData && countryData.results && countryData.results.length > 0 ?
        countryData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];

    const subNationalOptions = subNationalData && subNationalData.results && subNationalData.results.length > 0 ?
        subNationalData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];

    const diseaseOptions = diseasesData && diseasesData.results && diseasesData.results.length > 0 ?
        diseasesData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];

    const diseaseClassificationOptions = diseaseClassificationsData && diseaseClassificationsData.results && diseaseClassificationsData.results.length > 0 ?
        diseaseClassificationsData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.value}
            </MenuItem>
        )) :
        [];

    const diseaseSubClassificationsDataHidden = React.useMemo(() => { return (diseaseSubClassificationsData === undefined || (diseaseSubClassificationsData !== undefined && diseaseSubClassificationsData.total === 0)) ? true : false }, [diseaseClassificationId, diseaseSubClassificationsData]);

    const editMode = editEpidemic !== undefined;

    const wrappedObject = editMode ? {
        object: editEpidemic,
    } : undefined;

    return <EditForm
        actions={epidemicModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingEpidemic}
        selectors={epidemicModule.selectors}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        url={url}
        validate={validation}
    >{(values) => (
        <>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.name"
                    component={TextFieldWrapper}
                    type="text"
                    label="Epidemic Name"
                />
            </Grid>

            <Grid item xs={12}>
                {
                    countryRequestState.state !== RequestState.Pending ?
                        <Field
                            fullWidth
                            required
                            disabled={false}
                            name="object.countryId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label="Country"
                        >
                            {countryOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.countryIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Country"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <Grid item xs={12}>
                {
                    (subNationalRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            required
                            disabled={false}
                            name="object.subNationalId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label="Sub National"
                        >
                            {subNationalOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.subNationalIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Sub National"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.subNationalId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== subNationalId) {
                            updateSubNationalId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.year"
                    component={TextFieldWrapper}
                    type="text"
                    label="Year"
                />
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.diseaseId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== diseaseId) {
                            updateDiseaseId(value);
                            props.form.mutators.clear('object.diseaseSubClassificationIds');
                            props.form.mutators.clear('object.diseaseClassificationId');
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <FormSpy>
                {props => <Field
                    name="object.countryId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== countryId) {
                            updateCountryId(value);
                            props.form.mutators.clear('object.subNationalId');
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item xs={12}>
                {
                    diseasesRequestState.state !== RequestState.Pending ?
                        <Field
                            fullWidth
                            required
                            disabled={diseaseIsLoading}
                            name="object.diseaseId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label="Disease"
                        >
                            <MenuItem value={0}></MenuItem>
                            {diseaseOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.diseaseIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Disease"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={'Pathogen'}
                    disabled={true}
                    fullWidth
                    name={"object.pathogen"}
                    value={diseaseState.data ? diseaseState.data.pathogen.name : ""}
                />
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.diseaseClassificationId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (value !== diseaseClassificationId) {
                            updateDiseaseClassificationId(value);
                            props.form.mutators.clear('object.diseaseSubClassificationIds');
                        }
                        return null;
                    }}
                />}
            </FormSpy>

            {
                <FormSpy subscription={{ values: true }}>
                    {
                        spyProps =>
                            <Grid item xs={12}>
                                <Field
                                    required
                                    name="object.diseaseClassificationId"
                                    component={SelectWrapper}
                                    disabled={spyProps.values.object === undefined || spyProps.values.object.diseaseId === undefined}
                                    formControlProps={{
                                        className: classes.formControl,
                                        fullWidth: true
                                    }}
                                    label={diseaseClassificationsData && diseaseClassificationsData.results.length > 0 ? diseaseClassificationsData.results[0].name : "Classification"}
                                >
                                    <MenuItem value={undefined}></MenuItem>
                                    {diseaseClassificationOptions}
                                </Field>
                            </Grid>
                    }
                </FormSpy>
            }
            <Grid item xs={12} hidden={diseaseSubClassificationsDataHidden}>
                <Field name="object.diseaseClassificationId">
                    {
                        classificationProps => {
                            const disableField = classificationProps.input.value === undefined;

                            return <Field
                                name="object.diseaseSubClassificationIds"
                                component={MultiSelectWrapper}
                                disabled={disableField}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true
                                }}
                                label={'Genotypes/Topotypes'}
                                renderValue={diseaseSubClassificationsData && ((selected: number[]) => diseaseSubClassificationsData.results.filter(s => selected.indexOf(s.id) > -1).map(s => s.value).join(', '))}
                                options={diseaseSubClassificationsData ? diseaseSubClassificationsData.results.map(d => { return { label: `${d.name} - ${d.value}`, value: d.id } }) : []}
                            />
                        }
                    }
                </Field>
            </Grid>
        </>
    )}
    </EditForm>
};

export default EpidemicEditForm;