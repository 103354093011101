/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Field } from '../dtos/Spread.dtos';
import { fieldActions } from '../store/reducers/fields/field';
import { IFormRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useFieldLoad(fieldId: number): [Field | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.field.data);
    const requestState = useSelector(state => state.field.states.loadState);

    useEffect(() => {
        if(fieldActions.load){
            dispatch(fieldActions.load(fieldId))
        }

        return function cleanup() {
            dispatch(fieldActions.clear())
        }
    }, [dispatch, fieldId]);

    return [
        data,
        requestState
    ];
}
