/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Redirect, Route } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ContentLayout } from '../../components/content/ContentLayout';
import { ContentSection } from '../../components/content/ContentSection';
import LoginForm from '../../components/person/LoginForm';
import { useSelector } from '../../hooks/useTypedSelector';
import { AuthState } from '../../types/AuthState';
import PasswordReset from '../../components/person/PasswordReset';
import PasswordResetConfirm from '../../components/person/PasswordResetConfirm';
import PasswordResetRequest from '../../components/person/PasswordResetRequest';
import PasswordResetRequestConfirm from '../../components/person/PasswordResetRequestConfirm';

export interface ITestPageProps {
    classes?: any;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2),
            //backgroundColor: theme.palette.secondary.light
        },
    }),
);

export default function LoginPage() {
    const classes = useStyles();

    const sessionState = useSelector(state => state.authenticatedPerson.authState);

    const authRedirects = sessionState === AuthState.Authenticated && <>
        <Redirect from="/login" to="/task" />
    </>;

    return (<ContentLayout>

        <ContentSection
            overrideMaxWidth={'sm'}
        >
            <Paper className={classes.root}>
                {authRedirects}
                <Route exact path={'/login/resetPasswordRequestConfirm'} component={PasswordResetRequestConfirm} />
                <Route exact path={'/login/resetPasswordRequest'} component={PasswordResetRequest} />
                <Route exact path={'/login/resetPasswordConfirm'} component={PasswordResetConfirm} />
                <Route exact path={'/login/resetPassword'} component={PasswordReset} />
                <Route exact path={'/login'} component={LoginForm} />
            </Paper>
        </ContentSection>

    </ContentLayout>
    );
}
