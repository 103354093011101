/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple area date types.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';


/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialAreaDateTypeSearchState = ISearchState<SpreadDtos.QueryAreaDateType>;

export interface IAreaDateTypeSearchState {
    areaDateTypeSearch: IInitialAreaDateTypeSearchState;
}

export const initialState: IAreaDateTypeSearchState = {
    areaDateTypeSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const areaDateTypeSearchModule = createAutoQueryModule(
    'areaDateTypeSearch',
    initialSearchState,
    '@@areaDateTypeSearch',
    SpreadDtos.QueryAreaDateTypes,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        epidemicId: options.specialOptions.epidemicId,
        isStartOfInfectiousPeriodDate: options.specialOptions.isStartOfInfectiousPeriodDate,
        isEndOfInfectiousPeriodDate: options.specialOptions.isEndOfInfectiousPeriodDate,
    })
);


/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const areaDateTypeSearchActions = areaDateTypeSearchModule.actions;
export const areaDateTypeSearchEpics = areaDateTypeSearchModule.epics;
export const areaDateTypeSearchApi = areaDateTypeSearchModule.api;
export const areaDateTypeSearchSelectors = areaDateTypeSearchModule.selectors;
export const areaDateTypeSearchHooks = areaDateTypeSearchModule.hooks;
export default areaDateTypeSearchModule.reducer;