/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for executing an epidemic hierarchy import
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IImportHierarchyFormSchema } from '../../../components/bulkUpload/ImportHierarchyForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialImportHierarchyState {
    importState: IFormRequestState;
    importResponse?: SpreadDtos.ImportHierarchyResponse;
}

export interface IImportHierarchyState {
    importHierarchy: IInitialImportHierarchyState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@importHierarchy';

export const importHierarchyTypes = {
    IMPORT: `${typeNamespace}/IMPORT`,
    IMPORT_FORM_RESPONSE: `${typeNamespace}/IMPORT_FORM_RESPONSE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialImportHierarchyState = {
    importResponse: undefined,
    importState: {
        state: RequestFormState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const importHierarchyReducer = createReducer<IInitialImportHierarchyState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const importHierarchyActions = {
    /**
     * Begins the request to execute a new hierarchy import.
     */
    import: createAction(importHierarchyTypes.IMPORT,
        (importHierarchy: IImportHierarchyFormSchema) => ({ importHierarchy })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    importFormResponse: createAction(importHierarchyTypes.IMPORT_FORM_RESPONSE,
        (response: SpreadDtos.ImportHierarchyResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the importHierarchy state.
    */
    clear: createAction(importHierarchyTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Create new Reducers
importHierarchyReducer.on(importHierarchyActions.import, (state) => (
    update(
        state,
        {
            importState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

importHierarchyReducer.on(importHierarchyActions.importFormResponse, (state, payload) => (
    update(
        state,
        {
            importResponse: {
                $set: payload.response
            },
            importState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
importHierarchyReducer.on(importHierarchyActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
importHierarchyReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const importHierarchysApi = {
    import: createPostRequest(
        SpreadDtos.ImportHierarchy,
        (importHierarchy: IImportHierarchyFormSchema) => ({
            epidemicId: importHierarchy.epidemicId,
            temporaryFileToken: importHierarchy.files[0].tempFileGuid,
            mappedHeadersDictionary: importHierarchy.fieldMapping
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const importEpic: Epic<ReturnType<typeof importHierarchyActions.import>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(importHierarchyActions.import.getType()),
    mergeMap(action => {
        return importHierarchysApi.import(action.payload.importHierarchy).pipe(
            mergeMap(response =>
                of(
                    importHierarchyActions.importFormResponse(response, RequestFormState.SubmitSuccess)
                )
            ),
            catchError(e => of(importHierarchyActions.importFormResponse(e, RequestFormState.ServerError)))
        )
    })
);

export const importHierarchyEpics = combineEpics(importEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default importHierarchyReducer;