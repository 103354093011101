/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import React, { useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { SmartMapContext, ISmartMapPosition } from './SmartMapContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ReactLeafletControl from '../reactLeafletControl/ReactLeafletControl';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISmartMapControl {
    position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlRoot: {
            margin: '80px 0 0 -5px !important',
            minHeight: '40px'
        }
    })
);

const SmartMapControl: React.FunctionComponent<ISmartMapControl> = (props) => {
    const { children, position } = {...props};
    const classes = useStyles(props);

    const sidePosition: ISmartMapPosition = props.position === 'topleft' || props.position === 'bottomleft' ? 'left' : 'right';

    const [openTabKey, setOpen] = useState<number | undefined>(undefined);

    const handleToggleOpen = (tabKey: number) => {
        if(tabKey === openTabKey){
            setOpen(undefined);
        } else {
            setOpen(tabKey);
        }
    }

    return <ReactLeafletControl className={classes.controlRoot} position={position}>
        <SmartMapContext.Provider value={{
            openTabKey: openTabKey,
            position: sidePosition, 
            toggleOpen: handleToggleOpen
            }}>
        {children}
        </SmartMapContext.Provider>
    </ReactLeafletControl>;
}

export default SmartMapControl;