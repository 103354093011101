/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import diseaseModule from '../../store/reducers/diseases/disease';
import diseaseClassificationModule from '../../store/reducers/diseaseClassifications/diseaseClassification';
import { RequestFormState } from '../../types/RequestState';
import { DiseaseClassification } from '../../dtos/Spread.dtos';
import DiseaseClassificationEditForm from './DiseaseClassificationEditForm';
import { ViewDiseaseSubClassifications } from '../diseaseSubClassification/ViewDiseaseSubClassifications';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditDiseaseClassificationParams {
    diseaseClassificationId: string;
    diseaseId?: string;
}

interface IEditDiseaseClassificationProps {
    diseaseClassificationId?: number;
    diseaseId?: number;
    history: History;
    match: match<IEditDiseaseClassificationParams>;
}

export const EditDiseaseClassification: React.FunctionComponent<IEditDiseaseClassificationProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles({});

    const diseaseState = useSelector(state => state.disease);
    const diseaseId = props.diseaseId || ((props.match.params && props.match.params.diseaseId) ? parseInt(props.match.params.diseaseId) : 0);
    useEffect(() => {
        if (diseaseModule.actions.load) {
            dispatch(diseaseModule.actions.load(diseaseId))
        }
    }, [dispatch, diseaseId]);

    const diseaseIsLoading = diseaseState.states.loadState.state === RequestFormState.Pending && !diseaseState.data;

    const diseaseClassificationState = useSelector(state => state.diseaseClassification);
    const diseaseClassificationId = props.diseaseClassificationId || ((props.match.params && props.match.params.diseaseClassificationId) ? parseInt(props.match.params.diseaseClassificationId) : 0);
    useEffect(() => {
        if (diseaseClassificationModule.actions.load) {
            dispatch(diseaseClassificationModule.actions.load(diseaseClassificationId))
        }
    }, [dispatch, diseaseClassificationId]);

    return diseaseIsLoading ?
        <CircularProgress /> :
        <>
            <Paper className={classes.root}>
                <DiseaseClassificationEditForm
                    editDiseaseClassification={diseaseClassificationState.data}
                    disease={diseaseState.data}
                    history={props.history}
                    loadingDiseaseClassification={diseaseClassificationState.states.loadState.state === RequestFormState.Pending && !diseaseClassificationState.data}
                    url={props.match.url}
                />
            </Paper>
            <ViewDiseaseSubClassifications
                editMode
                diseaseClassificationId={diseaseClassificationId}
                history={props.history}
                match={props.match}
            />
        </>
}