/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography/Typography';
import PublicIcon from '@material-ui/icons/Public';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useMemo } from 'react';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { toDate } from '@servicestack/client';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../constants/Dates';
import { clusterSearchActions, clustersHooks } from '../../store/reducers/clusters/clusterSearch';
import { RequestState } from '../../types/RequestState';
import IconButtonLink from '../common/IconLink';
import { SortableTable } from '../table/SortableTable';
import { Box } from '@material-ui/core';
import { AnalysisDate } from '../areaDate/AnalysisDate';
import { useSelector } from '../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

interface IClusterListProps {
    className?: string;
    epidemicId?: number;
    history: History;
    showTitle?: boolean;
    url: string;
}

export const ClusterList: React.FunctionComponent<IClusterListProps> = ({ className, epidemicId, history, showTitle, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId]);
    const pagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = clustersHooks.useSearch(specOps, pagOps);

    const epidemic = useSelector(state => state.epidemic);

    const startDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isStartOfInfectiousPeriodDate);
    const endDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isEndOfInfectiousPeriodDate);

    const headerColumns = [
        { id: '', numeric: false, disablePadding: true, label: '' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'infectedPremiseCount', numeric: false, disablePadding: false, label: 'No. of IPs' },
        { id: 'clusterDateSet.earliestStartOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: startDateType ? startDateType.name : 'Start of Infectious Period Date' },
        { id: 'clusterDateSet.latestEndOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: endDateType ? endDateType.name : 'End of Infectious Period Date' },
    ];

    return <>
        {
            showTitle && data && data.results && data.results.length > 0 && requestState.state !== RequestState.Pending ?
                <Typography variant="h4" component="h4">Clusters</Typography> :
                null
        }
        {data && data.results && data.results.length > 0 ?
            <SortableTable
                className={classNames(className, classes.table)}
                headerColumns={headerColumns}
                paginateOptions={paginateOptions}
                rowCount={data ? data.total : 0}
                updatePaginateOptions={clusterSearchActions.updatePaginateOptions}
            >
                {
                    data.results.map((item =>
                        <TableRow
                            className={classes.clickableRow}
                            key={item.id}
                        >
                            <TableCell scope="row">
                                {
                                    item.infectedPremiseCount > 0
                                        ? <>
                                            <Box display="flex" justifyContent="space-around">
                                                <IconButtonLink edge="start" color="inherit" aria-label="View Map" to={`/epidemic/${item.epidemicId}/results/map?clusterId=${item.id}&zoomToCluster=1`}>
                                                    <PublicIcon />
                                                </IconButtonLink>
                                                <IconButtonLink edge="start" color="inherit" aria-label="View Graph" to={`/epidemic/${item.epidemicId}/results/graph?clusterId=${item.id}&zoomToCluster=1`}>
                                                    <BarChartIcon />
                                                </IconButtonLink>
                                            </Box>
                                        </>
                                        : null
                                }
                            </TableCell>
                            <TableCell scope="row">
                                <Link component={RouterLink} to={`${url}/${item.id}`}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <TableCell scope="row">
                                {item.infectedPremiseCount}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <AnalysisDate dateSet={item.clusterDateSet} dateType='startinfectious' />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <AnalysisDate dateSet={item.clusterDateSet} dateType='endinfectious' />
                            </TableCell>
                        </TableRow>
                    ))
                }
            </SortableTable> :
            requestState.state === RequestState.Pending ?
                <Skeleton /> :
                <Typography variant="h6" component="h6">No Cluster</Typography>
        }
    </>
}