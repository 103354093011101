/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for creating a genomic network job
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import download from 'downloadjs';
import update from 'immutability-helper';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ApiJobGenomicNetwork, CreateJobGenomicNetwork, CreateJobGenomicNetworkResponse, DeleteJobGenomicNetwork, DeleteJobGenomicNetworkResponse, GetAllGenomicNetworkFiles, GetGenomicNetworkDirectTransmissionsImage, GetGenomicNetworkIndirectTransmissionsImage } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { ActionType, baseCrudInitialState, createCrudModuleWithNamedRequestObject, IBaseCrudActions, IBaseCrudApi, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IGenomicNetworkSubState extends IBaseCrudState<ApiJobGenomicNetwork> {
    file?: Blob;
    extraStates: {
        loadFileState: IFormRequestState;
    }
}

export interface IGenomicNetworkState {
    genomicNetwork: IGenomicNetworkSubState;
}

interface IGenomicNetworkActions extends IBaseCrudActions<ApiJobGenomicNetwork> {
    createJob: ActionType<(infectedPremiseIds: number[], allInfectedPremisesOnEpidemicId: number, startDateTypeId?: number) => { infectedPremiseIds: number[], allInfectedPremisesOnEpidemicId: number, startDateTypeId?: number }>;
    createJobFormResponse: ActionType<(response: CreateJobGenomicNetworkResponse, state: RequestFormState) => { response: CreateJobGenomicNetworkResponse, state: RequestFormState }>;
    getAllFiles: ActionType<(genomicNetworkJobId: number) => { genomicNetworkJobId: number }>;
    getAllFilesFormResponse: ActionType<(response: Blob, state: RequestFormState) => { response: Blob, state: RequestFormState }>;
    getDirectTransmissionsImage: ActionType<(genomicNetworkJobId: number) => { genomicNetworkJobId: number }>;
    getDirectTransmissionsImageFormResponse: ActionType<(response: Blob, state: RequestFormState) => { response: Blob, state: RequestFormState }>;
    getIndirectTransmissionsImage: ActionType<(genomicNetworkJobId: number) => { genomicNetworkJobId: number }>;
    getIndirectTransmissionsImageFormResponse: ActionType<(response: Blob, state: RequestFormState) => { response: Blob, state: RequestFormState }>;
}

interface IGenomicNetworkApi extends IBaseCrudApi {
    createJob: ReturnType<typeof createPostRequest>;
    getAllGenomicNetworkFiles: ReturnType<typeof createGetRequest>;
    getDirectTransmissionsImage: ReturnType<typeof createGetRequest>;
    getIndirectTransmissionsImage: ReturnType<typeof createGetRequest>;
}

interface IGenomicNetworkSelectors extends IBaseCrudSelectors<ApiJobGenomicNetwork, IGenomicNetworkSubState> {
    file: (state: any) => IGenomicNetworkSubState["file"];
    loadFileState: (state: any) => IGenomicNetworkSubState["extraStates"]["loadFileState"];
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const initialGenomicNetworkState: IGenomicNetworkSubState = {
    file: undefined,
    extraStates: {
        loadFileState: {
            state: RequestFormState.None
        }
    },
    ...baseCrudInitialState<ApiJobGenomicNetwork>()
};

const genomicNetworkModule = createCrudModuleWithNamedRequestObject<ApiJobGenomicNetwork, undefined, undefined, undefined, DeleteJobGenomicNetworkResponse, undefined, IGenomicNetworkSubState, IGenomicNetworkActions, IGenomicNetworkApi, IGenomicNetworkSelectors>(
    'genomicNetwork',
    'retrievalJob',
    undefined,
    undefined,
    undefined,
    DeleteJobGenomicNetwork,
    undefined,
    initialGenomicNetworkState,
);

// Create Job action

genomicNetworkModule.actions.createJob = createAction(`@@genomicNetwork/CREATE_JOB`,
    (infectedPremiseIds: number[], allInfectedPremisesOnEpidemicId: number, startDateTypeId?: number) => ({ infectedPremiseIds, allInfectedPremisesOnEpidemicId, startDateTypeId })
);

genomicNetworkModule.actions.createJobFormResponse = createAction(`@@genomicNetwork/CREATE_JOB_FORM_RESPONSE`,
    (response: CreateJobGenomicNetworkResponse, state: RequestFormState) => ({ response, state })
);

const createJobReducer = (state: IGenomicNetworkSubState) => update(
    state,
    {
        states: {
            createState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const createJobFormResponseReducer = (state: IGenomicNetworkSubState, payload: any, data: ApiJobGenomicNetwork) => update(
    state,
    {
        data: {
            $set: data
        },
        states: {
            createState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.createJob, (state: IGenomicNetworkSubState) => (
    createJobReducer(state)
));

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.createJobFormResponse, (state: IGenomicNetworkSubState, payload) => (
    createJobFormResponseReducer(state, payload, payload.response.genomicNetworkJob)
));

genomicNetworkModule.api.createJob = createPostRequest(
    CreateJobGenomicNetwork,
    (infectedPremiseIds: number[], allInfectedPremisesOnEpidemicId: number, startDateTypeId?: number) => ({
        infectedPremiseIds,
        allInfectedPremisesOnEpidemicId,
        startDateTypeId
    })
);

const createJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(genomicNetworkModule.actions.createJob.getType()),
        mergeMap(action => {
            return genomicNetworkModule.api.createJob(action.payload.infectedPremiseIds, action.payload.allInfectedPremisesOnEpidemicId, action.payload.startDateTypeId)
                .pipe(
                    mergeMap(response => of(genomicNetworkModule.actions.createJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(genomicNetworkModule.actions.createJobFormResponse(e, RequestFormState.ServerError)))
                );
        })
    )

// Get All Files action

genomicNetworkModule.actions.getAllFiles = createAction(`@@genomicNetwork/GET_ALL_FILES`,
    (genomicNetworkJobId: number) => ({ genomicNetworkJobId })
);

genomicNetworkModule.actions.getAllFilesFormResponse = createAction(`@@genomicNetwork/GET_ALL_FILES_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);

const getAllFilesReducer = (state: IGenomicNetworkSubState) => update(
    state,
    {
        extraStates: {
            loadFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const getAllFilesFormResponseReducer = (state: IGenomicNetworkSubState, payload: any, blob: Blob) => {
    download(blob, "genomicNetwork.tar.gz");
    return update(
        state,
        {
            file: {
                $set: blob
            },
            extraStates: {
                loadFileState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    );
};

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getAllFiles, (state: IGenomicNetworkSubState) => (
    getAllFilesReducer(state)
));

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getAllFilesFormResponse, (state: IGenomicNetworkSubState, payload) => (
    getAllFilesFormResponseReducer(state, payload, payload.response)
));

genomicNetworkModule.api.getAllGenomicNetworkFiles = createGetRequest(
    GetAllGenomicNetworkFiles,
    (genomicNetworkJobId: number) => ({
        genomicNetworkJobId
    })
);

const getAllFilesEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(genomicNetworkModule.actions.getAllFiles.getType()),
        mergeMap(action =>
            genomicNetworkModule.api.getAllGenomicNetworkFiles(action.payload.genomicNetworkJobId)
                .pipe(
                    mergeMap(response => of(genomicNetworkModule.actions.getAllFilesFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(genomicNetworkModule.actions.getAllFilesFormResponse(e, RequestFormState.ServerError)))
                )
        )
    )

// Get Direct Transmissions Image action

genomicNetworkModule.actions.getDirectTransmissionsImage = createAction(`@@genomicNetwork/GET_DIRECT_TRANSMISSIONS_IMAGE`,
    (genomicNetworkJobId: number) => ({ genomicNetworkJobId })
);

genomicNetworkModule.actions.getDirectTransmissionsImageFormResponse = createAction(`@@genomicNetwork/GET_DIRECT_TRANSMISSIONS_IMAGE_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);

const getDirectTransmissionsImageReducer = (state: IGenomicNetworkSubState) => update(
    state,
    {
        extraStates: {
            loadFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const getDirectTransmissionsImageFormResponseReducer = (state: IGenomicNetworkSubState, payload: any, blob: Blob) => update(
    state,
    {
        file: {
            $set: blob
        },
        extraStates: {
            loadFileState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getDirectTransmissionsImage, (state: IGenomicNetworkSubState) => (
    getDirectTransmissionsImageReducer(state)
));

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getDirectTransmissionsImageFormResponse, (state: IGenomicNetworkSubState, payload) => (
    getDirectTransmissionsImageFormResponseReducer(state, payload, payload.response)
));

genomicNetworkModule.api.getDirectTransmissionsImage = createGetRequest(
    GetGenomicNetworkDirectTransmissionsImage,
    (genomicNetworkJobId: number) => ({
        genomicNetworkJobId
    })
);

const getDirectTransmissionsImageEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(genomicNetworkModule.actions.getDirectTransmissionsImage.getType()),
        mergeMap(action =>
            genomicNetworkModule.api.getDirectTransmissionsImage(action.payload.genomicNetworkJobId)
                .pipe(
                    mergeMap(response => of(genomicNetworkModule.actions.getDirectTransmissionsImageFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(genomicNetworkModule.actions.getDirectTransmissionsImageFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

// Get Indirect Transmissions Image action

genomicNetworkModule.actions.getIndirectTransmissionsImage = createAction(`@@genomicNetwork/GET_INDIRECT_TRANSMISSIONS_IMAGE`,
    (genomicNetworkJobId: number) => ({ genomicNetworkJobId })
);

genomicNetworkModule.actions.getIndirectTransmissionsImageFormResponse = createAction(`@@genomicNetwork/GET_INDIRECT_TRANSMISSIONS_IMAGE_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);

const getIndirectTransmissionsImageReducer = (state: IGenomicNetworkSubState) => update(
    state,
    {
        extraStates: {
            loadFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const getIndirectTransmissionsImageFormResponseReducer = (state: IGenomicNetworkSubState, payload: any, blob: Blob) => update(
    state,
    {
        file: {
            $set: blob
        },
        extraStates: {
            loadFileState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getIndirectTransmissionsImage, (state: IGenomicNetworkSubState) => (
    getIndirectTransmissionsImageReducer(state)
));

genomicNetworkModule.reducer.on(genomicNetworkModule.actions.getIndirectTransmissionsImageFormResponse, (state: IGenomicNetworkSubState, payload) => (
    getIndirectTransmissionsImageFormResponseReducer(state, payload, payload.response)
));

genomicNetworkModule.api.getIndirectTransmissionsImage = createGetRequest(
    GetGenomicNetworkIndirectTransmissionsImage,
    (genomicNetworkJobId: number) => ({
        genomicNetworkJobId
    })
);

const getIndirectTransmissionsImageEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(genomicNetworkModule.actions.getIndirectTransmissionsImage.getType()),
        mergeMap(action =>
            genomicNetworkModule.api.getIndirectTransmissionsImage(action.payload.genomicNetworkJobId)
                .pipe(
                    mergeMap(response => of(genomicNetworkModule.actions.getIndirectTransmissionsImageFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(genomicNetworkModule.actions.getIndirectTransmissionsImageFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

/* Add additional epics to module */

genomicNetworkModule.epics = combineEpics(genomicNetworkModule.epics, createJobEpic, getAllFilesEpic, getDirectTransmissionsImageEpic, getIndirectTransmissionsImageEpic);

/* Additional Selectors */

genomicNetworkModule.selectors.file = (state: any) => state['genomicNetwork'].file;
genomicNetworkModule.selectors.loadFileState = (state: any) => state['genomicNetwork'].extraStates.loadFileState;

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default genomicNetworkModule;
