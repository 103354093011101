/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestState, RequestFormState } from '../../types/RequestState';
import { ViewGroupEpidemics } from '../groupEpidemic/ViewGroupEpidemics';
import { ViewGroup } from './ViewGroup';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IGroupHomeProps {
    history: History;
    loading: boolean;
    match: match<any>;
}

export const GroupHome: React.FunctionComponent<IGroupHomeProps> = ({ history, match }) => {
    const classes = useStyles();

    const group = useSelector(state => state.group.data);
    const groupState = useSelector(state => state.group.states.loadState);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewGroup
                group={group}
                history={history}
                loading={groupState.state === RequestFormState.Pending}
                match={match}
            />
        </Box>
    </Box>
}
