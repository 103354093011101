/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { Epidemic } from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { useSelector } from '../../hooks/useTypedSelector';
import epidemicModule from '../../store/reducers/epidemics/epidemic';
import { RequestFormState } from '../../types/RequestState';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonIcon: {
            marginRight: theme.spacing(1),
        },
        manageButton: {
            marginRight: theme.spacing(1),
        },
        editButton: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IEpidemicDetailsProps {
    className?: string;
    epidemic?: Epidemic;
    history: History;
    url: string;
}

export const EpidemicDetails: React.FunctionComponent<IEpidemicDetailsProps> = ({ className, epidemic, history, url }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    // Archive stuff

    const archiveRequestState = useSelector(s => s.epidemic.states.archiveState.state);
    if (archiveRequestState === RequestFormState.SubmitSuccess) {
        history.push(routeBackToPath(url, 2));
    }

    const [isArchiveConfirmOpen, setArchiveConfirmOpen] = React.useState(false);

    const handleArchiveClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setArchiveConfirmOpen(true);
        },
        [setArchiveConfirmOpen],
    );

    const handleArchiveConfirmClose = React.useCallback(
        () => {
            setArchiveConfirmOpen(false);
        },
        [setArchiveConfirmOpen],
    );

    const handleArchiveConfirm = React.useCallback(
        () => {
            if (epidemic && epidemicModule.actions.archive) {
                dispatch(epidemicModule.actions.archive(epidemic.id));
            }
            setArchiveConfirmOpen(false);
        },
        [epidemic, setArchiveConfirmOpen],
    );

    return <Paper className={classNames(className, classes.root)}>
        {
            !epidemic || archiveRequestState === RequestFormState.Pending ?
                <CircularProgress /> :
                <>
                    <Typography variant="h4" component="h4">{epidemic.name}</Typography>
                    <Typography variant="h5" component="h5">Country: {
                        epidemic.country ?
                            epidemic.country :
                            'Unspecified'
                    }</Typography>
                    <Typography variant="h5" component="h5">Sub-National: {
                        epidemic.subNational ?
                            epidemic.subNational :
                            'Unspecified'
                    }</Typography>
                    <Typography variant="h5" component="h5">Year: {
                        epidemic.year ?
                            epidemic.year :
                            'Unspecified'
                    }</Typography>
                    <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                        <HasPermission permission="UpdateEpidemic" objectId={epidemic.id}>
                            <ButtonLink
                                className={classes.manageButton}
                                to={`/epidemic/${epidemic.id}/manage`}
                                color="primary"
                                variant="contained"
                            >
                                <SupervisorAccountIcon className={classes.buttonIcon} />
                                Manage Access
                            </ButtonLink>
                        </HasPermission>
                        <HasPermission permission="BulkUpload" objectId={epidemic.id}>
                            <ButtonLink
                                aria-label="Bulk Data Upload"
                                className={classes.editButton}
                                color="primary"
                                variant="contained"
                                to={`${url}/import`}
                            >
                                <CloudUploadIcon className={classes.buttonIcon} />
                                Bulk Upload
                            </ButtonLink>
                        </HasPermission>
                        <HasPermission permission="UpdateEpidemic" objectId={epidemic.id}>
                            <ButtonLink
                                aria-label="Edit Epidemic"
                                className={classes.editButton}
                                color="primary"
                                variant="contained"
                                to={`/epidemic/${epidemic.id}/data/edit`}
                            >
                                <EditIcon className={classes.buttonIcon} />
                                Edit
                            </ButtonLink>
                        </HasPermission>
                        <HasPermission permission="ArchiveEpidemic" objectId={epidemic.id}>
                            <Button
                                aria-label="Archive Epidemic"
                                color="primary"
                                variant="contained"
                                onClick={handleArchiveClick}
                            >
                                <DeleteIcon className={classes.buttonIcon} />
                                Archive
                            </Button>
                        </HasPermission>
                    </Box>
                </>
        }
        <Dialog
            open={isArchiveConfirmOpen}
            onClose={handleArchiveConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Archive Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to archive {epidemic ? `${epidemic.name}` : '[ERROR]'}? <strong>Undoing this operation is not supported by the application and will require a system administrator to undo.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleArchiveConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleArchiveConfirm} color="secondary">
                    Archive
                </Button>
            </DialogActions>
        </Dialog>
    </Paper>
}
