/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MockSelect } from './MockSelect';
import { MockRadioButtons } from './MockRadioButtons';
import { MockText } from './MockText';
import { IMockFormState, MockFormContext } from './MockFormContext';
import FileWrapper from '../../forms/FinalFormControls/FileWrapper';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        }
    }),
);

interface IMockUploadFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

export const MockUploadForm: React.FunctionComponent<IMockUploadFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);

    const ipName = !mockForm.fields['spec_ip'] || mockForm.fields['spec_ip'] == '' || mockForm.fields['spec_ip'] == 'NO_SELECTION'
        ? 'NO-IP' : mockForm.fields['spec_ip'];

    const animalName = !mockForm.fields['spec_nlis'] || mockForm.fields['spec_nlis'] == ''
    ? 'NO-ANIMAL-ID' : mockForm.fields['spec_nlis'];

    const num = Math.round(Math.random() * 100)
    const defaultSequenceId = `${ipName}_${animalName}_${num}`;

    const ngsMode = mockForm.fields['seq_sequencingSystem'] == '1';

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seq_enrichmentMethod'}
                title={'Enrichment method'}
                items={[
                    {
                        key: '1',
                        label: 'PolyA enrichment'
                    },
                    {
                        key: '2',
                        label: 'CDNA subtraction'
                    },
                    {
                        key: '3',
                        label: 'PCR ("AMPLICON")'
                    },
                    {
                        key: '4',
                        label: 'Magnetic beads'
                    },
                    {
                        key: '5',
                        label: 'Affinity purification'
                    },
                    {
                        key: '6',
                        label: 'Other'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={12} xs={12}>
            <FileWrapper
                input={{
                    checked: false,
                    multiple: false,
                    name: mockForm.fields['up_file'],
                    onBlur: () => undefined,
                    onChange: (event: any) => mockForm.setField('up_file', event as any),
                    onFocus: () => undefined,
                    value: mockForm.fields['up_file']
                }}
                meta={{}}
            />
        </Grid>
        {
            ngsMode && <Grid item lg={12} xs={12}>
                <FileWrapper
                    input={{
                        checked: false,
                        multiple: false,
                        name: mockForm.fields['up_file2'],
                        onBlur: () => undefined,
                        onChange: (event: any) => mockForm.setField('up_file2', event as any),
                        onFocus: () => undefined,
                        value: mockForm.fields['up_file2']
                    }}
                    meta={{}}
                />
            </Grid>
        }
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                defaultValue={defaultSequenceId}
                fieldName={'up_sequenceId'}
                title='SPREAD Sequence ID'
            />
        </Grid>
        <Grid item xs={12} />
    </Grid>
}