/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateAreaDateType } from '../../components/areaDateType/CreateAreaDateType';
import { ViewAreaDateTypes } from '../../components/areaDateType/ViewAreaDateTypes';
import { AreaDateTypeRouter } from '../../routers/AreaDateTypeRouter';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IAreaDateTypesPageProps {
    classes?: string;
    match: match;
}

export const AreaDateTypesPage: React.FunctionComponent<IAreaDateTypesPageProps> = (props) => {

    return <Switch>
        <Route exact path={`${props.match.path}/create`} component={CreateAreaDateType} />
        <Route exact path={`${props.match.path}`} component={ViewAreaDateTypes} />
        <Route path={`${props.match.path}/:areaDateTypeId`} component={AreaDateTypeRouter} />
    </Switch>;
}
