/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import { Redirect } from 'react-router';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DatePickerDateTimeFormat, DateTimeFormat } from '../../../constants/Dates';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { formatDate, parseDate } from "../../../helpers/dateHelpers";
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import { analysisTapmActions } from '../../../store/reducers/analysis/analysisTapm';
import { clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import { subClustersSelectors } from '../../../store/reducers/subClusters/subClusterSearch';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import DatePickerWrapper from "../../forms/FinalFormControls/DatePickerWrapper";
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';
import InfectedPremiseCheckboxSelect from '../../forms/InfectedPremiseCheckboxSelect/InfectedPremiseCheckboxSelect';
import { changeValue, clear } from '../../forms/mutators';
import SubClusterSelect from '../../forms/SubClusterSelect/SubClusterSelect';
import AnalysisTapmDataSetSelect from './AnalysisTapmDataSetSelect';
import AnalysisTapmExcretionSelect from './AnalysisTapmExcretionSelect';
import { IAnalysisTapmFormSchema } from './types';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAnalysisTapmEditFormProps {
    epidemicId?: number;
    history: History;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formItem: {
            margin: theme.spacing(2, 3),
        },
        formTitle: {
            marginLeft: theme.spacing(3),
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        loadingLabel: {
            marginLeft: theme.spacing(1)
        }
    }),
);

const analysisJobSchema = Yup.object<IAnalysisTapmFormSchema>({
    name: Yup.string().label('Name'),
    infectedPremiseIds: Yup.array().compact().of(Yup.number()),
    clusterId: Yup.number().label('Cluster'),
    entireEpidemicId: Yup.mixed().label('EpidemicId'),
    subClusterId: Yup.number().label('Sub-Cluster'),
    emissionRate: Yup.number().label('Emission rate').required(),
    tapmExcretionId: Yup.number().label('Excretion run'),
    tapmDataSetId: Yup.number().label('Data set').required(),
    limitRunStartDate: Yup.string().label('Simulation start date').required(),
    limitRunEndDate: Yup.string().label('Simulation end date').required(),
    forecastLength: Yup.mixed().label('Forecast length').required(),
    nestingLevel: Yup.mixed().label('Nesting level').required(),
    virusSurvival: Yup.mixed().label('Virus Survival').required(),
    extentKilometres: Yup.mixed().label('Extent')
});

const validation = Yup.object().shape({
    analysis: analysisJobSchema.notRequired()
})

const AnalysisTapmEditForm: React.FunctionComponent<IAnalysisTapmEditFormProps> = ({ epidemicId, history, url }) => {
    const classes = useStyles();

    const clusterSpecOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId]);
    const clusterPaginateOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [clusterData, , , clusterRequestState] = clustersHooks.useSearch(clusterSpecOps, clusterPaginateOps);

    const clusterOptions = clusterData && clusterData.results && clusterData.results.length > 0 ?
        clusterData.results.map((c =>
            <MenuItem
                key={c.id}
                value={c.id}
            >
                {c.name}: {
                    c.clusterDateSet && c.clusterDateSet.earliestStartOfInfectiousPeriodDate && c.clusterDateSet.latestEndOfInfectiousPeriodDate
                        ? `${format(toDate(c.clusterDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.clusterDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)}`
                        : '(Insufficient date data)'
                }
            </MenuItem>
        )) :
        [];

    const subClustersData = useSelector(subClustersSelectors.data);

    const analysisCreateState = useSelector(s => s.analysisTapm.createState);
    const analysisCreateDataState = useSelector(s => s.analysisTapm.data);
    const submitSuccess = analysisCreateState.state === RequestFormState.SubmitSuccess;

    const epidemic = useSelector(state => state.epidemic.data);

    const defaultStartDate = epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate ? format(toDate(epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate), DatePickerDateTimeFormat) : undefined
    const defaultEndDate = epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate ? format(toDate(epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate), DatePickerDateTimeFormat) : undefined

    const [showCustomIpSelection, setShowCustomIpSelection] = useState(true);

    var successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        const toRoute: string = analysisCreateDataState ? `${routeBackToPath(url, 1, analysisCreateDataState.id)}` : '/404';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    return <>
        <Typography variant="h4" component="h4">Undertake Wind Dispersion Run</Typography>
        <CommonForm
            history={history}
            labelSave={'Queue'}
            mutators={{
                clear: clear,
                changeValue: changeValue
            }}
            submitSuccess={submitSuccess}
            start={analysisTapmActions.create}
            resolve={analysisTapmActions.createFormResponse}
            reject={analysisTapmActions.createFormResponse}
            validate={validation}
        >{(values) => (
            <Grid item>
                {successRedirectComponent}
                <Box className={classes.formTitle}>
                    <Typography variant="h6">Job Details</Typography>
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        fullWidth
                        name="analysis.name"
                        component={TextFieldWrapper}
                        type="text"
                        label="Optional Name"
                        placeholder={"Use this to help find this job later"}
                    />
                </Box>
                <Box className={classes.formTitle}>
                    <Typography variant="h6">Dispersion Emission Points</Typography>
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        name="analysis.entireEpidemicId"
                        component={SelectWrapper}
                        defaultValue={'custom'}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Infected Premises' To Emit From"
                    >
                        <MenuItem value={'custom'}>Custom selection</MenuItem>
                        <MenuItem value={epidemic && epidemic.id}>All in epidemic</MenuItem>
                    </Field>
                    <FormSpy>
                        {formSpyProps =>
                            <OnChange name={"analysis.entireEpidemicId"}>
                                {(value: any) => {
                                    setShowCustomIpSelection(value === 'custom');
                                    formSpyProps.form.mutators.clear('analysis.clusterId');
                                    formSpyProps.form.mutators.clear('analysis.subClusterId');
                                    formSpyProps.form.mutators.clear('analysis.infectedPremiseIds');
                                }}
                            </OnChange>}
                    </FormSpy>
                </Box>
                {
                    showCustomIpSelection
                        ? <>
                            <Box className={classes.formItem}>
                                <Field
                                    name="analysis.clusterId"
                                    component={SelectWrapper}
                                    formControlProps={{
                                        className: classes.formControl,
                                        fullWidth: true,
                                        required: false
                                    }}
                                    label="Cluster"
                                >
                                    <MenuItem value={-1}></MenuItem>
                                    <MenuItem value={undefined}>All</MenuItem>
                                    {clusterOptions}
                                </Field>
                            </Box>
                            <Box className={classes.formItem}>
                                <FormSpy>
                                    {formSpyProps =>
                                        <Field
                                            name="analysis.subClusterId"
                                        >
                                            {subClusterProps => (
                                                <Field name="analysis.clusterId" render={(clusterValue) => {

                                                    const clusterId = Number(clusterValue.input.value) || undefined;

                                                    if (!clusterId && subClusterProps.input.value) {
                                                        formSpyProps.form.mutators.clear('analysis.subClusterId');
                                                    }

                                                    return <SubClusterSelect
                                                        clusterId={clusterId}
                                                        formControlProps={{
                                                            className: classes.formControl,
                                                            fullWidth: true,
                                                            required: false
                                                        }}
                                                        disabled={clusterId == -1}
                                                        input={subClusterProps.input}
                                                        label="Sub-Cluster"
                                                        meta={subClusterProps.meta}
                                                    />
                                                }} />

                                            )}
                                        </Field>
                                    }
                                </FormSpy>
                            </Box>
                            <FormSpy>
                                {props => <>
                                    <Field name={"analysis.clusterId"} subscription={{}}>
                                        {(
                                            // No subscription. We only use Field to get to the change function
                                            { input: { onChange } }
                                        ) => (
                                                <OnChange name={"analysis.clusterId"}>
                                                    {(value: any) => {
                                                        props.form.mutators.clear('analysis.infectedPremiseIds');
                                                    }}
                                                </OnChange>
                                            )}
                                    </Field>
                                    <Field name={"analysis.subClusterId"} subscription={{}}>
                                        {(
                                            // No subscription. We only use Field to get to the change function
                                            { input: { onChange } }
                                        ) => (
                                                <OnChange name={"analysis.subClusterId"}>
                                                    {(value: any) => {
                                                        props.form.mutators.clear('analysis.infectedPremiseIds');
                                                    }}
                                                </OnChange>
                                            )}
                                    </Field>
                                </>}
                            </FormSpy>
                            <Box className={classes.formItem}>
                                <Field name="analysis.clusterId" render={(clusterValue) => {
                                    const clusterIpCount = clusterValue.input.value === '' || !clusterData || !clusterData.results ? 0 : GetIpCount(clusterValue.input.value, clusterData.results);

                                    return <Field name="analysis.subClusterId" render={(subClusterValue) => {
                                        const subClusterIpCount = subClusterValue.input.value === '' || !subClustersData || !subClustersData.results ? undefined : GetIpCount(subClusterValue.input.value, subClustersData.results);

                                        const ipCount = subClusterIpCount ? subClusterIpCount : clusterIpCount

                                        const clusterId = clusterValue.input.value === '' ? undefined : clusterValue.input.value;
                                        const subClusterId = subClusterValue.input.value === '' ? undefined : subClusterValue.input.value;

                                        return <InfectedPremiseCheckboxSelect
                                            clusterId={clusterId}
                                            epidemicId={epidemicId}
                                            subClusterId={subClusterId}
                                            name={"analysis.infectedPremiseIds"}
                                            ipCount={ipCount || 0}
                                        />
                                    }} />
                                }} />
                            </Box>
                        </> : null
                }
                <Box className={classes.formTitle}>
                    <Typography variant="h6">Model Parameters</Typography>
                </Box>
                <Box className={classes.formItem}>
                    <AnalysisTapmDataSetSelect
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Data Set"
                        name="analysis.tapmDataSetId"
                        filterDateStart={epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate ? epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate : undefined}
                        filterDateEnd={epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate ? epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate : undefined}
                    />
                </Box>

                <Box className={classes.formItem}>
                    <Field
                        fullWidth
                        name="analysis.limitRunStartDate"
                        component={DatePickerWrapper}
                        type="text"
                        label="Simulation start date"
                        defaultValue={defaultStartDate}
                        parse={parseDate}
                        format={formatDate}
                    />
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        fullWidth
                        name="analysis.limitRunEndDate"
                        component={DatePickerWrapper}
                        type="text"
                        label="Simulation end date"
                        defaultValue={defaultEndDate}
                        parse={parseDate}
                        format={formatDate}
                    />
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        fullWidth
                        required
                        name="analysis.emissionRate"
                        component={TextFieldWrapper}
                        type="number"
                        label="Global Emission Rate"
                        defaultValue={"100000"}
                    />
                </Box>
                <Box className={classes.formItem}>
                    <AnalysisTapmExcretionSelect
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Excretion Run"
                        name="analysis.tapmExcretionId"
                        epidemicId={epidemic && epidemic.id}
                    />
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        name="analysis.forecastLength"
                        component={SelectWrapper}
                        defaultValue={SpreadDtos.ILength.OneDay}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Forecast Length"
                    >
                        <MenuItem value={SpreadDtos.ILength.OneDay}>1 day</MenuItem>
                        <MenuItem value={SpreadDtos.ILength.TwoDays}>2 days</MenuItem>
                        <MenuItem value={SpreadDtos.ILength.ThreeDays}>3 days</MenuItem>
                        <MenuItem value={SpreadDtos.ILength.FourDays}>4 days</MenuItem>
                    </Field>
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        name="analysis.nestingLevel"
                        component={SelectWrapper}
                        defaultValue={SpreadDtos.InRun.Auto}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Nesting Level"
                    >
                        <MenuItem value={SpreadDtos.InRun.Auto}>Auto</MenuItem>
                        <MenuItem value={SpreadDtos.InRun.ThirtyKilometres}>30km</MenuItem>
                        <MenuItem value={SpreadDtos.InRun.TenKilometres}>10km</MenuItem>
                        <MenuItem value={SpreadDtos.InRun.ThreeKilometres}>3km</MenuItem>
                        <MenuItem value={SpreadDtos.InRun.OneKilometre}>1km</MenuItem>
                        <MenuItem value={SpreadDtos.InRun.ThreeHundredMetres}>300m</MenuItem>
                    </Field>
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        name="analysis.virusSurvival"
                        component={SelectWrapper}
                        defaultValue={SpreadDtos.VirusSurvival.Off}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Virus Survival"
                    >
                        <MenuItem value={SpreadDtos.VirusSurvival.Off}>Off</MenuItem>
                        <MenuItem value={SpreadDtos.VirusSurvival.SerotypeOAvg}>serotype_o_avg</MenuItem>
                        <MenuItem value={SpreadDtos.VirusSurvival.Lausanne}>lausanne</MenuItem>
                        <MenuItem value={SpreadDtos.VirusSurvival.UKG2001}>ukg_32_2001</MenuItem>
                        <MenuItem value={SpreadDtos.VirusSurvival.UKG2007}>ukg_7_2007</MenuItem>
                    </Field>
                </Box>
                <Box className={classes.formItem}>
                    <Field
                        name="analysis.extentKilometres"
                        component={SelectWrapper}
                        defaultValue={-1}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Extent"
                    >
                        <MenuItem value={-1}>Any</MenuItem>
                        <MenuItem value={3}>3 Kilometres</MenuItem>
                        <MenuItem value={10}>10 Kilometres</MenuItem>
                    </Field>
                </Box>
            </Grid>
        )}
        </CommonForm>
        {
            analysisCreateState.state === RequestFormState.Pending && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Typography className={classes.loadingLabel} component="h6" variant="h6">Dispatching job</Typography>
            </Box>
        }
    </>
};

type BaseCluster = {
    id: number;
}
type EitherCluster = BaseCluster & Partial<SpreadDtos.QueryCluster> & Partial<SpreadDtos.QuerySubCluster>;

const GetIpCount = (clusterId?: number, clusters?: EitherCluster[]) => {

    if (clusters && clusterId) {
        const item = clusters ? clusters.find(c => c.id === clusterId) : undefined;

        if (item) {
            return item.infectedPremiseCount;
        }
    }

    return 0;
}

export default AnalysisTapmEditForm;