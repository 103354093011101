/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/styles';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import { CircleMarker, GeoJSON, Popup, Tooltip } from "react-leaflet";
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../../constants/Dates';
import { AnalysisInfectedPremiseStatus, DateSet, InfectedPremise } from '../../../dtos/Spread.dtos';
import ButtonLink from '../../common/ButtonLink';


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface IInfectedPremiseMarker {
    infectedPremise: InfectedPremise;
    analysisStatus?: AnalysisInfectedPremiseStatus;
    timeStep?: Date;
    startOpen?: boolean;

    showPolygons: boolean;
    showPoints: boolean;
    showLabels: boolean;
    showLabelMode: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        markerPopup: {
            '& p': {
                margin: 0
            }
        }
    })
);

function onEachFeature(feature: any, layer: any, name: string) {
    if (layer && name) {
        layer.bindPopup(name);
    }
}

const InfectedPremiseMarker: React.FunctionComponent<IInfectedPremiseMarker> = ({ analysisStatus, infectedPremise, timeStep, startOpen, showPolygons, showPoints, showLabels, showLabelMode }) => {
    const classes = useStyles();

    const [, statusColour, statusLabel] = CalculateStatus(infectedPremise.infectedPremiseDateSet, timeStep, analysisStatus);
    const center = useMemo<[number, number]>(() => [infectedPremise.centreLat, infectedPremise.centreLng], [infectedPremise]);
    const centerValid = center && center[0] != undefined && center[1] != undefined;

    //const parsedPolygon: undefined | number[][] = infectedPremise.polygon && JSON.parse(infectedPremise.polygon.replace('{"type":"Polygon","coordinates":', '').replace('}', ''));

    //const reversedPolygon = parsedPolygon && parsedPolygon.flatMap((polygonPoints) => polygonPoints.map((point) =>  [point[1],point[0]]));

    const handleOnEachFeature = (feature: any, layer: any) => {
        return onEachFeature(feature, layer, infectedPremise.infectedPremiseId)
    }

    const openDefaultPopup = (marker: any) => {
        if (marker && startOpen) {
            window.setTimeout(() => {
                marker.openPopup()
            })
        }
    }

    return <>
        {
            showPolygons && infectedPremise.polygon ? <GeoJSON
                data={JSON.parse(infectedPremise.polygon) as any}
                key={statusColour}
                style={{
                    color: statusColour
                }}
                onEachFeature={handleOnEachFeature}
            /> : null
        }
        {
            showLabels && centerValid && showLabelMode == 'farmId' &&
            <CircleMarker
                center={center}
                radius={1}
                weight={1}
                opacity={0.01}
            >
                <Tooltip pane="popupPane" permanent={true}>
                    <Typography variant="body2" component="p">Farm ID: {infectedPremise.farmId}</Typography>
                </Tooltip>
            </CircleMarker>
        }
        {
            showLabels && centerValid && showLabelMode == 'ipId' && infectedPremise.infectedPremiseId &&
            <CircleMarker
                center={center}
                radius={1}
                weight={1}
                opacity={0.01}
            >
                <Tooltip pane="popupPane" permanent={true}>
                    <Typography variant="body2" component="p">IP ID: {infectedPremise.infectedPremiseId}</Typography>
                </Tooltip>
            </CircleMarker>
        }
        {
            showPoints && centerValid && <CircleMarker
                center={center}
                radius={5}
                weight={1}
                opacity={1}
                fillOpacity={1}
                ref={openDefaultPopup}
                pathOptions={{ color: '#000', fillColor: statusColour }}
            //onMouseOver={(e: any) => e.target.openPopup()}
            >
                <Popup pane="popupPane">
                    <div className={classes.markerPopup}>
                        <Typography variant="h6" component="h6">Infected Premise</Typography>
                        <Typography variant="body2" component="p">Status: {statusLabel}</Typography>
                        <Typography variant="body2" component="p">Infected Premise ID: {infectedPremise.infectedPremiseId}</Typography>
                        <Typography variant="body2" component="p">Farm ID: {infectedPremise.farmId}</Typography>
                        <hr />
                        <Typography variant="body2" component="p">Presumed infection date: {infectedPremise.infectedPremiseDateSet && infectedPremise.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate ? format(toDate(infectedPremise.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat) : 'Unknown'}</Typography>
                        <Typography variant="body2" component="p">Disposal date: {infectedPremise.infectedPremiseDateSet && infectedPremise.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate ? format(toDate(infectedPremise.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat) : 'Unknown'}</Typography>
                        <hr />
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            <ButtonLink
                                variant="outlined"
                                color="primary"
                                to={`/epidemic/${infectedPremise.epidemicId}/data/cluster/${infectedPremise.clusterId}/subCluster/${infectedPremise.subClusterId}/premise/${infectedPremise.id}`}>
                                View Data
                            </ButtonLink>
                        </Box>
                    </div>
                </Popup>
                <Tooltip pane="popupPane">
                    <Typography variant="body2" component="p">IP ID: {infectedPremise.infectedPremiseId}</Typography>
                    <Typography variant="body2" component="p">Farm ID: {infectedPremise.farmId}</Typography>
                </Tooltip>
            </CircleMarker>
        }
    </>;
}

export default InfectedPremiseMarker;

enum LocalInfectedPremiseStatus {
    Susceptible = 'Susceptible',
    AtRisk = 'AtRisk',
    Infected = 'Infected',
    PostInfected = 'PostInfected'
}

const CalculateStatus = (dateSet: DateSet, timeStep?: Date, analysisStatus?: AnalysisInfectedPremiseStatus) => {

    const startDate = dateSet && toDate(dateSet.earliestStartOfInfectiousPeriodDate);
    const endDate = dateSet && toDate(dateSet.latestEndOfInfectiousPeriodDate);

    var status: LocalInfectedPremiseStatus = LocalInfectedPremiseStatus.Susceptible

    if (timeStep !== undefined && startDate && endDate) {
        status = startDate <= timeStep && endDate >= timeStep ? LocalInfectedPremiseStatus.Infected
            : timeStep >= endDate ? LocalInfectedPremiseStatus.PostInfected
                : analysisStatus === AnalysisInfectedPremiseStatus.AtRisk ? LocalInfectedPremiseStatus.AtRisk
                    : LocalInfectedPremiseStatus.Susceptible;
    } else if (analysisStatus === AnalysisInfectedPremiseStatus.AtRisk) {
        status = LocalInfectedPremiseStatus.AtRisk;
    }

    const defaultColour: string = status === LocalInfectedPremiseStatus.Susceptible ? "#99e818"
        : status === LocalInfectedPremiseStatus.AtRisk ? "#ffa818"
            : status === LocalInfectedPremiseStatus.Infected ? "#ff302f"
                : status === LocalInfectedPremiseStatus.PostInfected ? "#bfbfbf"
                    : '#000000';

    const defaultLabel: string = status === LocalInfectedPremiseStatus.Susceptible ? "At risk"
        : status === LocalInfectedPremiseStatus.AtRisk ? "At high risk"
            : status === LocalInfectedPremiseStatus.Infected ? "Infected"
                : status === LocalInfectedPremiseStatus.PostInfected ? "Disposed"
                    : 'Unknown';

    return [
        status,
        defaultColour,
        defaultLabel
    ];
}