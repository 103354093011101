/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AnalysisJobTapm, SpreadWorkflowJobStatus } from '../dtos/Spread.dtos';
import { IRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';
import { analysisTapmActions } from '../store/reducers/analysis/analysisTapm';
import useInterval from './useInterval';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useAnalysisTapmLoad(
    analysisJobId: number, 
    refreshIntervalSeconds?: number
): [
    AnalysisJobTapm | undefined, 
    IRequestState
] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.analysisTapm.data);
    const requestState = useSelector(state => state.analysisTapm.loadState);

    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const refreshInterval = refreshIntervalSeconds ? refreshIntervalSeconds * 1000 : null;

    useInterval(() => {
        if(data && (data.status === SpreadWorkflowJobStatus.Initing || data.status === SpreadWorkflowJobStatus.Running)) {
            setRefreshTrigger(!refreshTrigger);
        }
    }, refreshInterval);

    useEffect(() => {
        // although naughty, use the trigger variable to surpress hooks warning
        if(refreshTrigger){
            // That's cool
        }

        dispatch(analysisTapmActions.load(analysisJobId))

        return function cleanup() {
            dispatch(analysisTapmActions.clear())
        }
    }, [dispatch, analysisJobId, refreshTrigger]);

    return [
        data,
        requestState
    ];
}

export function useRefreshTapmDataSets(): [
    () => void,
    IRequestState
] {
    const dispatch = useDispatch();
    const requestState = useSelector(state => state.analysisTapm.refreshTapmDataSetsState);

    const reprocessFn = () => {
        dispatch(analysisTapmActions.refreshTapmDatasets())
    };

    return [
        reprocessFn,
        requestState
    ];
}
