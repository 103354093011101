/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading a group.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActions, createCrudActionTypes, createCrudEpics, createCrudSelectors, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialGroupEpidemicState {
    data?: SpreadDtos.PersonGroupEpidemic;
    states: {
        createState: IFormRequestState;
        loadState: IFormRequestState;
        updateState: IFormRequestState;
        deleteState: IFormRequestState;
        archiveState: IFormRequestState;
    }
}

export interface IGroupEpidemicState {
    groupEpidemic: IInitialGroupEpidemicState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IGroupEpidemicActionTypes {
    CLEAR: string;
}

const groupEpidemicTypes: IBaseCrudTypes & IGroupEpidemicActionTypes = {
    ...createCrudActionTypes('groupEpidemic'),
    CLEAR: '@@groupEpidemic/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialGroupEpidemicState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const groupEpidemicReducer = createReducer<IInitialGroupEpidemicState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const groupEpidemicActions = {
    ...createCrudActions<SpreadDtos.PersonGroupEpidemic, SpreadDtos.CreateGroupEpidemicResponse, SpreadDtos.GetGroupEpidemicResponse, SpreadDtos.UpdateGroupEpidemicResponse, undefined, undefined>(groupEpidemicTypes),
    //clear: createAction(groupEpidemicTypes.CLEAR,
    //    () => ({})
    //),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */


// Create/Update Reducers
applyCrudReducers(groupEpidemicReducer, groupEpidemicActions, 'groupEpidemic', initialState);

// Clear Reducer
groupEpidemicReducer.on(groupEpidemicActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
groupEpidemicReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const groupEpidemicsApi = {
    create: createPostRequest(
        SpreadDtos.CreateGroupEpidemic,
        (groupEpidemic: SpreadDtos.PersonGroupEpidemic) => ({
            groupEpidemic: groupEpidemic
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetGroupEpidemic,
        (groupEpidemicId: number) => ({
            id: groupEpidemicId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateGroupEpidemic,
        (groupEpidemic: SpreadDtos.PersonGroupEpidemic) => ({
            groupEpidemic: groupEpidemic
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(groupEpidemicActions, groupEpidemicsApi, ['groupEpidemic']);

export const groupEpidemicEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const groupEpidemicSelectors = createCrudSelectors('groupEpidemic');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default groupEpidemicReducer;