/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { useSelector } from '../../hooks/useTypedSelector';
import livestockModule from '../../store/reducers/livestock/livestock';
import { RequestFormState } from '../../types/RequestState';
import LivestockEditForm from './LivestockEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditLivestockParams {
    livestockId: string;
    epidemicId: string;
}

interface IEditLivestockProps {
    livestockId?: number;
    history: History;
    match: match<IEditLivestockParams>;
}

export const EditLivestock: React.FunctionComponent<IEditLivestockProps> = (props) => {
    const livestockState = useSelector(state => state.livestock);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const livestockId = props.livestockId || (props.match.params ? parseInt(props.match.params.livestockId) : undefined);

    const epidemicId = props.match.params ? parseInt(props.match.params.epidemicId) : undefined

    useEffect(() => {
        if (livestockModule.actions.load) {
            dispatch(livestockModule.actions.load(livestockId))
        }
    }, [dispatch, livestockId]);

    return <Paper className={classes.root}>
        <LivestockEditForm
            editLivestock={livestockState.data}
            epidemicId={epidemicId}
            history={props.history}
            loadingLivestock={livestockState.states.loadState.state === RequestFormState.Pending && !livestockState.data}
            url={props.match.url}
        />
    </Paper>
}
