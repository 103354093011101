import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { DatePickerDateTimeFormat } from '../../../constants/Dates';

const DatePickerWrapper: React.FunctionComponent<FieldRenderProps<any, any>> = ({ input: { name, onChange, value, ...restInput }, meta, ...rest }) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    const handleChange = (value: Date | null) => {
        if (value instanceof Date) {
            try {
                onChange(format(value, DatePickerDateTimeFormat));
            }
            catch { }
        }
    };

    let actualValue;

    // If it's already a date, cool
    if (value instanceof Date) {
        actualValue = value;
    }
    else {
        // If it isn't try to make it a date with ServiceStack
        actualValue = toDate(value);
        if (actualValue && isNaN(actualValue.getTime())) {
            // Otherwise, assume it's in the right format, and do some tricky stuff to get it to parse right
            let parts = value.split("-");
            actualValue = new Date(parseInt(parts[2], 10),
                parseInt(parts[1], 10) - 1,
                parseInt(parts[0], 10));
            if (actualValue && isNaN(actualValue.getTime())) {
                actualValue = undefined;
            }
        }
    }

    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
            disableToolbar
            {...rest}
            variant="inline"
            format={DatePickerDateTimeFormat}
            margin="normal"
            error={showError}
            helperText={showError ? meta.error || meta.submitError : undefined}
            name={name}
            onChange={handleChange}
            value={actualValue}
        />
    </MuiPickersUtilsProvider>;
};

export default DatePickerWrapper;