/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import HelpIcon from '@material-ui/icons/Help';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { useImportPreParseLoad } from '../../hooks/useImportPreParse';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import { RequestState } from '../../types/RequestState';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IImportFieldMappingProps {
    className?: string;
    epidemicId?: number;
    importIpKey?: number;
    importType: SpreadDtos.ImportType;
    name: string;
    temporaryFile: SpreadDtos.File;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1)
        },
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        helpIcon: {
            fontSize: '1.25rem',
            marginLeft: theme.spacing(0.5)
        }
    }),
);

const ImportFieldMapping: React.FunctionComponent<IImportFieldMappingProps> = ({
    className,
    epidemicId,
    importIpKey,
    importType,
    name,
    temporaryFile
}) => {
    const classes = useStyles();

    const [preParseData, preParseRequest] = useImportPreParseLoad(importType, epidemicId, temporaryFile)

    const foundHeaderMenuItems = [<MenuItem value={undefined}>{'UNMAPPED FIELD'}</MenuItem>];

    if (preParseData) {
        preParseData.foundHeaders.map((foundHeader) => {
            foundHeaderMenuItems.push(<MenuItem value={foundHeader}>{foundHeader}</MenuItem>)
        });
    }

    const formSpy = useForm();

    useEffect(() => {
        if (preParseRequest.state === RequestState.None) {
            formSpy.mutators.clear(name);
        }
    }, [preParseRequest, preParseData])

    const infectedPremiseKeyName = 'infectedpremisekey';

    const expectedHeaderQuestions = preParseData && preParseData.expectedHeaders.map((expectedHeader) => {
        var expectedHeaderLabel = expectedHeader.label;
        var defaultValue = expectedHeader.suggestedFoundHeaderValue;

        if(expectedHeader.value === infectedPremiseKeyName) {
            expectedHeaderLabel = importIpKey ? SpreadDtos.ImportIpKey[importIpKey].toLowerCase() : expectedHeaderLabel;
            defaultValue = expectedHeaderLabel;

        }

        return <TableRow key={expectedHeader.value} >
            <TableCell>
                {expectedHeaderLabel}
                {
                    /*keyHeader && <Tooltip title={`This field will be used to find the premise in the SPREAD database`}>
                        <StarIcon />
                    </Tooltip>*/
                }
            </TableCell>
            <TableCell align="right">
                <Field
                    name={`${name}.${expectedHeader.value}`}
                    component={SelectWrapper}
                    defaultValue={defaultValue}
                    formControlProps={{
                        className: classes.formControl,
                        fullWidth: true
                    }}
                >
                    {foundHeaderMenuItems}
                </Field>
            </TableCell>
        </TableRow>
    })

    return <FormControl fullWidth className={className}>
        <FormLabel>Field Mapping</FormLabel>
        {
            preParseData ? <FormGroup className={classes.formGroup}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography component={'span'} variant={'subtitle1'}>Expected Field</Typography>
                                <Tooltip title={`This is the SPREAD database field to match the mapped fields against`}>
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            </TableCell>
                            <TableCell align="right">
                                <Typography component={'span'} variant={'subtitle1'}>Mapped Field</Typography>
                                <Tooltip title={`This is the header name from the file you uploaded`}>
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expectedHeaderQuestions}
                    </TableBody>
                </Table>
            </FormGroup>
                : preParseRequest.state === RequestState.Pending
                    ? <CircularProgress />
                    : <Typography variant={'body2'}>Upload an import file to start mapping fields.</Typography>
        }
    </FormControl >;
};

export default ImportFieldMapping;