/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { History } from 'history';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Epidemic } from '../../dtos/Spread.dtos';
//import { countryDescription } from '../../helpers/enumDescription';
import { useSelector } from '../../hooks/useTypedSelector';
import { RequestState } from '../../types/RequestState';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { useImportLogLoad } from '../../hooks/useImportLog';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';
import { DateTimeFormat, DateTimeHourFormat } from '../../constants/Dates';
import Skeleton from 'react-loading-skeleton';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonIcon: {
            marginRight: theme.spacing(1),
        },
        manageButton: {
            marginRight: theme.spacing(1),
        },
        editButton: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IImportLogDetailsProps {
    className?: string;
    importLogId: number;
    history: History;
    url: string;
}

function createData(attribute: string, value?: string) {
    return { attribute, value };
}

export const ImportLogDetails: React.FunctionComponent<IImportLogDetailsProps> = ({ className, history, importLogId, url }) => {
    const classes = useStyles();

    const [importLog, importLogRequest] = useImportLogLoad(importLogId);

    const rows = [
        createData('Import Type', importLog && importLog.importType.toString()),
        createData('Import Start', importLog && format(toDate(importLog.startDate), DateTimeHourFormat)),
        createData('Import Finish', importLog && format(toDate(importLog.endDate), DateTimeHourFormat)),
        createData('Imported By', importLog && importLog.createdByPersonId.toString())
    ];

    return <Paper className={classNames(className, classes.root)}>
        <Typography variant={'h6'}>Data Import Log</Typography>
        <Table aria-label="simple table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Attribute</TableCell>
                    <TableCell align="right">Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map(row => (
                    <TableRow key={row.attribute}>
                        <TableCell component="th" scope="row">
                            {row.attribute}
                        </TableCell>
                        <TableCell align="right">{row.value ? row.value : <Skeleton />}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Paper>
}
