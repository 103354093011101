/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import speciesModule from '../../store/reducers/species/species';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISpeciesEditFormProps {
    editSpecies?: SpreadDtos.Species;
    history: History;
    loadingSpecies?: boolean;
    url: string;
}

interface ISpeciesFormSchema extends Partial<SpreadDtos.Species> {
    name: string;
}

const speciesSchema = Yup.object<ISpeciesFormSchema>({
    name: Yup.string().label('Name').required(),
    supportsExcretion: Yup.bool().label('Supports excretion')
});

const validation = Yup.object().shape({
    object: speciesSchema.notRequired(),
})

const SpeciesEditForm: React.FunctionComponent<ISpeciesEditFormProps> = ({
    editSpecies,
    history,
    loadingSpecies,
    url,
}) => {
    const editMode = editSpecies !== undefined;

    const wrappedObject = editMode ? {
        object: editSpecies,
    } : undefined;

    return <EditForm
        actions={speciesModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingSpecies}
        selectors={speciesModule.selectors}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, undefined)}` : '/404'}
        url={url}
        validate={validation}
    >{() => (
        <>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.name"
                    component={TextFieldWrapper}
                    type="text"
                    label="Species Name"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.supportsExcretion"
                    component={CheckboxWrapper}
                    type="checkbox"
                    label="Supports Excretion"
                />
            </Grid>
        </>
    )}
    </EditForm>
};

export default SpeciesEditForm;