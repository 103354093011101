/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { forwardRef } from 'react';
import { Link as RouterLink,  LinkProps } from 'react-router-dom';

import { IconButtonTypeMap } from '@material-ui/core/IconButton';
import IconButton from '@material-ui/core/IconButton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

/**
 * This helper wraps a Material-Ui <Button /> component to work with a <Link /> from 
 * React-Router-Dom
 * 
 * @returns The button wrapped with a React-Router-Dom Link
 */
const IconButtonLink: React.FunctionComponent<IconButtonTypeMap<LinkProps, 'button'>['props']> = (props) => {

    const { children, to, replace, innerRef, ...rest } = props;

    const renderLink = forwardRef((itemProps: any, ref: any) => (
        // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
        <RouterLink to={to} {...itemProps} innerRef={ref} />
    ));

    return (
        <IconButton component={renderLink} {...rest}>
            {children}
        </IconButton>
    );
}

export default IconButtonLink;