/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple sub-clusters.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSpecimenSearchState = ISearchState<SpreadDtos.QuerySpecimen>;

export interface ISpecimenSearchState {
    specimenSearch: IInitialSpecimenSearchState;
}

export const initialState: ISpecimenSearchState = {
    specimenSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const specimensModule = createAutoQueryModule(
    'specimenSearch',
    initialSearchState,
    '@@specimenSearch',
    SpreadDtos.QuerySpecimens,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const specimenSearchActions = specimensModule.actions;
export const specimenSearchEpics = specimensModule.epics;
export const specimensApi = specimensModule.api;
export const specimensSelectors = specimensModule.selectors;
export const specimensHooks = specimensModule.hooks;
export default specimensModule.reducer;