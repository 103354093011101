/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple diseases.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialDiseaseSearchState = ISearchState<SpreadDtos.QueryDisease>;

export interface IDiseaseSearchState {
    diseaseSearch: IInitialDiseaseSearchState;
}

export const initialState: IDiseaseSearchState = {
    diseaseSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const diseaseSearchModule = createAutoQueryModule(
    'diseaseSearch',
    initialSearchState,
    '@@diseaseSearch',
    SpreadDtos.QueryDiseases,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const diseaseSearchActions = diseaseSearchModule.actions;
export const diseaseSearchEpics = diseaseSearchModule.epics;
export const diseaseSearchApi = diseaseSearchModule.api;
export const diseaseSearchSelectors = diseaseSearchModule.selectors;
export const diseaseSearchHooks = diseaseSearchModule.hooks;
export default diseaseSearchModule.reducer;