/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateEpidemic } from '../../components/epidemic/CreateEpidemic';
import { ViewEpidemics } from '../../components/epidemic/ViewEpidemics';

import { EpidemicRouter } from '../../routers/EpidemicRouter';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IEpidemicsPageProps {
    classes?: string;
    match: match;
}

export const EpidemicsPage: React.FunctionComponent<IEpidemicsPageProps> = (props) => {

    return <Switch>
        <Route exact path={`${props.match.path}/create`} component={CreateEpidemic} />
        <Route exact path={`${props.match.path}`} component={ViewEpidemics} />
        <Route path={`${props.match.path}/:epidemicId`} component={EpidemicRouter} />
    </Switch>;
}
