/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { FormControlProps } from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toDate } from '@servicestack/client';
import { differenceInDays, format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { infectedPremiseSearchHooks } from '../../../store/reducers/infectedPremises/infectedPremiseSearch';
import { RequestState } from '../../../types/RequestState';
import SelectWrapper from '../FinalFormControls/SelectWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IInfectedPremiseSelectProps extends FieldRenderProps<any, any> {
    epidemicId?: number;
    clusterId?: number;
    subClusterId?: number;
    includeNoIPOption?: boolean;
    label?: string;
    formControlProps?: FormControlProps;
    labelAnalysisDates?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        formSkeleton: {
            height: 42,
            marginLeft: -11,
        }
    }),
);

const InfectedPremiseSelect: React.FunctionComponent<IInfectedPremiseSelectProps> = ({
    epidemicId,
    clusterId,
    subClusterId,
    input,
    meta,
    includeNoIPOption,
    label,
    formControlProps,
    labelAnalysisDates,
    disabled,
    ...rest
}) => {
    const classes = useStyles();

    const infectedPremiseSpecOps = useMemo(() => (
        DetermineSpecOps(epidemicId, clusterId, subClusterId)
    ),
        [epidemicId, clusterId, subClusterId]
    );

    const infectedPremisePaginateOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [infectedPremiseData, , , infectedPremiseRequestState] = infectedPremiseSearchHooks.useSearch(infectedPremiseSpecOps, infectedPremisePaginateOps);

    const infectedPremiseOptions = infectedPremiseData && infectedPremiseData.results && infectedPremiseData.results.length > 0 ?
        infectedPremiseData.results.map((c => {

            if (!c.infectedPremiseId || c.infectedPremiseId.trim() == '') {
                return null;
            }

            const dayDifference = c.infectedPremiseDateSet && c.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate && c.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate
                ? differenceInDays(toDate(c.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate), toDate(c.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate)) : null;

            const dayDifferenceMessage = dayDifference && dayDifference + ` day${dayDifference !== 1 && 's'}`;

            const analysisDateLabel = c.infectedPremiseDateSet && c.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate && c.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate
                ? `${format(toDate(c.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)} ${dayDifferenceMessage && dayDifferenceMessage}`
                : '(Insufficient date data)';

            return <MenuItem
                key={c.id}
                value={c.id}
            >
                {c.infectedPremiseId}
                {
                    labelAnalysisDates && analysisDateLabel
                }
            </MenuItem>
        }
        )) :
        [];

    return infectedPremiseRequestState.state == RequestState.Pending ?
        <Skeleton /> :
        <SelectWrapper
            disabled={disabled}
            formControlProps={formControlProps}
            input={input}
            label={label}
            meta={meta}
        >
            {
                includeNoIPOption ?
                    <MenuItem value={undefined}>No IP</MenuItem> :
                    undefined
            }
            {infectedPremiseOptions}
        </SelectWrapper>;
}

const DetermineSpecOps = (epidemicId?: number, clusterId?: number, subClusterId?: number) => {
    if (epidemicId && clusterId && clusterId != -1) {
        return {
            epidemicId: epidemicId,
            clusterId: clusterId,
            orderBy: 'EpidemicId'
        }
    }
    if (epidemicId) {
        return {
            epidemicId: epidemicId,
            clusterId: undefined,
            orderBy: 'EpidemicId'
        }
    }
    else if (clusterId) {
        return {
            clusterId: clusterId,
            orderBy: 'InfectedPremiseId'
        }
    } else if (subClusterId) {
        return {
            subClusterId: subClusterId,
            orderBy: 'InfectedPremiseId'
        }
    }

    return {};
}

export default InfectedPremiseSelect;