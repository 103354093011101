/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { ParcelList } from './ParcelList';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            marginTop: theme.spacing(2),
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewParcelsParams {
    clusterId?: string;
    epidemicId?: string;
    infectedPremiseId?: string;
}

interface IViewParcelsProps {
    className?: string;
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    match: match<IViewParcelsParams>;
}

export const ViewParcels: React.FunctionComponent<IViewParcelsProps> = ({ clusterId, epidemicId, infectedPremiseId, history, match }) => {
    const classes = useStyles();

    const localEpidemicId = epidemicId || (match.params && match.params.epidemicId ? parseInt(match.params.epidemicId) : undefined);
    const localClusterId = clusterId || (match.params && match.params.clusterId ? parseInt(match.params.clusterId) : undefined);
    const localInfectedPremisesId = infectedPremiseId || (match.params && match.params.infectedPremiseId ? parseInt(match.params.infectedPremiseId) : undefined);

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Parcels</Typography>
        <ParcelList
            className={classes.list}
            clusterId={localClusterId}
            epidemicId={localEpidemicId}
            infectedPremiseId={localInfectedPremisesId}
            url={match.url}
        />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateArea" objectId={localEpidemicId}>
                <ButtonLink
                    aria-label="Create Area"
                    color="primary"
                    variant="contained"
                    to={`${match.url}/parcel/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}