/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../../hooks/useTypedSelector';
import { RequestFormState } from '../../../types/RequestState';
import SequenceEditForm from './SequenceEditForm';
import { ViewGenbank } from './ViewGenbank';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSequenceParams {
    epidemicId: string;
    sequenceId: string;
}

interface IEditSequenceProps {
    epidemicId?: number;
    history: History;
    match: match<IEditSequenceParams>;
    uploadType?: "fastQ" | "fastA";
}

export const EditSequence: React.FunctionComponent<IEditSequenceProps> = ({
    epidemicId: epidemicIdProp,
    history,
    match,
    uploadType: uploadTypeProp,
}) => {
    const classes = useStyles();

    const sequenceState = useSelector(state => state.sequence);

    const params = parseQueryString(history.location.search);

    const epidemicId = epidemicIdProp || (match.params.epidemicId ? parseInt(match.params.epidemicId) : undefined);

    const uploadType = uploadTypeProp || (params.uploadType == "fastQ" || params.uploadType == "fastA" ? params.uploadType : undefined);

    let sequence = sequenceState.data;

    if (sequence && epidemicId)
        sequence.epidemicId = epidemicId;

    return <>
        <Paper className={classes.root}>
            <SequenceEditForm
                editSequence={sequence}
                history={history}
                uploadType={uploadType}
                loadingSequence={sequenceState.states.loadState.state === RequestFormState.Pending && !sequenceState.data}
                url={match.url}
            />
        </Paper>
        <ViewGenbank
            sequenceId={sequence && sequence.id}
        />
    </>
}