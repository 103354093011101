/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import MSAViewer from '@ngt/react-msa-viewer';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MSASequence } from '../../../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IMSAViewerProps {
    sequences: MSASequence[];

    barAttributes?: object;
    barFillColor?: string;
    barMethod?: 'information-content' | 'conservation';
    barStyle?: object;
    colorScheme?: 'buried_index' | 'clustal' | 'clustal2' | 'cinema' | 'helix_propensity' | 'hydro' | 'lesk' | 'mae' | 'nucleotide' | 'purine_pyrimidine' | 'strand_propensity' | 'taylor' | 'turn_propensity' | 'zappo';
    height?: number;
    labelAttributes?: object;
    labelComponent?: object | Function;
    labelStyle?: object;
    layout?: 'basic' | 'default' | 'inverse' | 'full' | 'compact' | 'funky';
    markerAttributes?: object;
    markerComponent?: object | Function;
    markerStartIndex?: number;
    markerSteps?: number;
    markerStyle?: object;
    msaStore?: object;
    onResidueClick?: Function;
    onResidueDoubleClick?: Function;
    onResidueMouseEnter?: Function;
    onResidueMouseLeave?: Function;
    position?: {
        x: number;
        y: number;
    };
    sequenceBorder?: boolean;
    sequenceBorderColor?: string;
    sequenceBorderWidth?: number;
    sequenceOverflow?: 'hidden' | 'auto' | 'scroll';
    sequenceOverflowX?: 'hidden' | 'auto' | 'scroll' | 'initial';
    sequenceOverflowY?: 'hidden' | 'auto' | 'scroll' | 'initial';
    sequenceScrollBarPositionX?: 'top' | 'bottom';
    sequenceScrollBarPositionY?: 'left' | 'right';
    sequenceTextColor?: string;
    sequenceTextFont?: string;
    tileHeight?: number;
    tileWidth?: number;
    width?: number;
}

export const SpreadMSAViewer: React.FunctionComponent<IMSAViewerProps> = ({ sequences, ...rest }) => {
    return <>
        <MSAViewer
            sequences={sequences.map(s => {

                const truncatedLabel = s.label ? s.label.split(' ')[0] : 'UNKNOWN';

                return {
                    name: truncatedLabel,
                    sequence: s.sequence
                };
            })}
            {...rest}
        />
    </>;
}