/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { GenomicUpload } from '../../components/genomic/GenomicDataUpload';
import { ViewSequenceViews } from '../../components/genomic/ViewSequenceViews';
import { MSAsPage } from './MSAsPage';
import { SpecimensPage } from './SpecimensPage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IBioinformaticsPageProps {
    classes?: string;
    match: match;
}

export const BioinformaticsPage: React.FunctionComponent<IBioinformaticsPageProps> = (props) => {

    return <Switch>
        <Route path={`${props.match.path}/specimen`} component={SpecimensPage} />
        <Route path={`${props.match.path}/msa`} component={MSAsPage} />
        <Route path={`${props.match.path}/upload`} component={GenomicUpload} />
        <Route path={`${props.match.path}`} component={ViewSequenceViews} />
    </Switch>;
}