/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an libraryStrategy
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateLibraryStrategy,
    CreateLibraryStrategyResponse,
    LibraryStrategy,
    DeleteLibraryStrategy,
    DeleteLibraryStrategyResponse,
    GetLibraryStrategy,
    GetLibraryStrategyResponse,
    UpdateLibraryStrategy,
    UpdateLibraryStrategyResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ILibraryStrategyState {
    libraryStrategy: IBaseCrudState<LibraryStrategy>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const libraryStrategyModule = createCrudModule<LibraryStrategy, CreateLibraryStrategyResponse, GetLibraryStrategyResponse, UpdateLibraryStrategyResponse, DeleteLibraryStrategyResponse, undefined>(
    'libraryStrategy',
    CreateLibraryStrategy,
    GetLibraryStrategy,
    UpdateLibraryStrategy,
    DeleteLibraryStrategy,
    undefined,
)


export default libraryStrategyModule;