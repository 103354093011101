/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple personRoles.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialPersonRoleSearchState = ISearchState<SpreadDtos.QueryPersonRole>;

export interface IPersonRoleSearchState {
    personRoleSearch: IInitialPersonRoleSearchState;
}

export const initialState: IPersonRoleSearchState = {
    personRoleSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const personRolesModule = createAutoQueryModule(
    'personRoleSearch',
    initialSearchState,
    '@@personRoleSearch',
    SpreadDtos.QueryPersonRoles,
    {
        //CLEAR: 'CLEAR'
    },
    (type) => ({
        //clear: createAction(type.CLEAR, () => ({}))
    }),
    (reducer, actions) => {
        //reducer.on(actions.clear, () => ({ ...initialSearchState }));
    },
    false,
    (options) => ({
        epidemicId: options.specialOptions.epidemicId,
        personGroupId: options.specialOptions.personGroupId,
        personId: options.specialOptions.personId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const personRoleSearchActions = personRolesModule.actions;
export const personRoleSearchEpics = personRolesModule.epics;
export const personRolesApi = personRolesModule.api;
export const personRolesSelectors = personRolesModule.selectors;
export const personRolesHooks = personRolesModule.hooks;
export default personRolesModule.reducer;