/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import speciesModule from '../../store/reducers/species/species';
import { RequestFormState } from '../../types/RequestState';
import SpeciesEditForm from './SpeciesEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSpeciesParams {
    speciesId: string;
}

interface IEditSpeciesProps {
    speciesId?: number;
    history: History;
    match: match<IEditSpeciesParams>;
}

export const EditSpecies: React.FunctionComponent<IEditSpeciesProps> = (props) => {
    const speciesState = useSelector(state => state.species);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const speciesId = props.speciesId || (props.match.params ? parseInt(props.match.params.speciesId) : undefined);

    useEffect(() => {
        if (speciesModule.actions.load) {
            dispatch(speciesModule.actions.load(speciesId))
        }
    }, [dispatch, speciesId]);

    return <Paper className={classes.root}>
        <SpeciesEditForm
            editSpecies={speciesState.data}
            history={props.history}
            loadingSpecies={speciesState.states.loadState.state === RequestFormState.Pending && !speciesState.data}
            url={props.match.url}
        />
    </Paper>
}
