import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

interface IInputProps {
	label: string
  }

const LabelledInputWrapper: React.FunctionComponent<FieldRenderProps<any,any> & Partial<IInputProps>> = ({input: {name, onChange, value, ...restInput}, label, meta, ...rest}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

	return (
		<FormControl fullWidth>
			<InputLabel error={showError} htmlFor={name}>{label}</InputLabel>
			<Input
				{...rest}
				name={name}
				error={showError}
				inputProps={restInput}
				onChange={onChange}
				value={value}
			/>

			{showError &&
				<FormHelperText error={showError}>
					{meta.error || meta.submitError}
				</FormHelperText>
			}
		</FormControl>
	);
};

export default LabelledInputWrapper;