/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple sub nationals.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';


/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSubNationalSearchState = ISearchState<SpreadDtos.QuerySubNational>;

export interface ISubNationalSearchState {
    subNationalSearch: IInitialSubNationalSearchState;
}

export const initialState: ISubNationalSearchState = {
    subNationalSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const subNationalSearchModule = createAutoQueryModule(
    'subNationalSearch',
    initialSearchState,
    '@@subNationalSearch',
    SpreadDtos.QuerySubNationals,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        countryId: options.specialOptions.countryId
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const subNationalSearchActions = subNationalSearchModule.actions;
export const subNationalSearchEpics = subNationalSearchModule.epics;
export const subNationalSearchApi = subNationalSearchModule.api;
export const subNationalSearchSelectors = subNationalSearchModule.selectors;
export const subNationalSearchHooks = subNationalSearchModule.hooks;
export default subNationalSearchModule.reducer;