/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import PlatformModule from '../../store/reducers/platforms/platform';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IPlatformEditFormProps {
    editPlatform?: SpreadDtos.Platform;
    history: History;
    loadingPlatform?: boolean;
    url: string;
}

interface IPlatformFormSchema extends Partial<SpreadDtos.Platform> {
    name: string;
}

const PlatformSchema = Yup.object<IPlatformFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: PlatformSchema.notRequired()
})


const PlatformEditForm: React.FunctionComponent<IPlatformEditFormProps> = ({
    editPlatform,
    history,
    loadingPlatform,
    url,
}) => {
    const editMode = editPlatform !== undefined;

    const wrappedObject = editMode ? {
        object: editPlatform
    } : undefined;

    return <EditForm
        actions={PlatformModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingPlatform}
        selectors={PlatformModule.selectors}
        successRouteCallback={(d) => d ? `/admin/platform` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default PlatformEditForm;