/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { EditSpecimen } from '../components/genomic/specimen/EditSpecimen';
import { SequencesPage } from '../pages/application/SequencesPage';
import specimenModule from '../store/reducers/specimens/specimen';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISpecimenRouterParams {
    epidemicId?: string;
    specimenId?: string;
}

interface ISpecimenRouterProps {
    epidemicId?: number;
    specimenId?: number;
    match: match<ISpecimenRouterParams>;
}

export const SpecimenRouter: React.FunctionComponent<ISpecimenRouterProps> = ({ specimenId: specimenIdProp, epidemicId: epidemicIdProp, match }) => {
    const specimenId = specimenIdProp || (match.params.specimenId ? parseInt(match.params.specimenId) : -1);
    const epidemicId = epidemicIdProp || (match.params.epidemicId ? parseInt(match.params.epidemicId) : -1);

    // Load in specimen so child routes can use
    const specimenIdMemo = React.useMemo(() => specimenId, [specimenId]);
    const epidemicIdMemo = React.useMemo(() => epidemicId, [epidemicId]);
    const [, response] = specimenModule.hooks.useLoadWithEpidemic(specimenIdMemo, epidemicIdMemo);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}`} component={EditSpecimen} />
            <Route path={`${match.path}/sequence`} component={SequencesPage} />
        </Switch>
}