/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ClusterHome } from '../components/cluster/ClusterHome';
import { EditCluster } from '../components/cluster/EditCluster';
import { useClusterLoad } from '../hooks/cluster';
import { SubClustersPage } from '../pages/application/SubClustersPage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IClusterRouterParams {
    clusterId?: string
}

interface IClusterRouterProps {
    clusterId?: number;
    match: match<IClusterRouterParams>;
}

export const ClusterRouter: React.FunctionComponent<IClusterRouterProps> = ({ clusterId, match }) => {
    const clusterIdTemp = match.params.clusterId ? parseInt(match.params.clusterId) : (clusterId || -1);

    // Load in cluster so child routes can use
    const clusterIdLoaded = React.useMemo(() => clusterIdTemp, [clusterIdTemp])
    const [, response] = useClusterLoad(clusterIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/edit`} component={EditCluster} />
            <Route path={`${match.path}/subCluster`} component={SubClustersPage} />
            <Route path={`${match.path}`} component={ClusterHome} />
        </Switch>
}