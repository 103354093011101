/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MockSelect } from './MockSelect';
import { MockRadioButtons } from './MockRadioButtons';
import { MockText } from './MockText';
import { IMockFormState, MockFormContext } from './MockFormContext';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        }
    }),
);

interface IMockAssembleFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

export const MockAssembleForm: React.FunctionComponent<IMockAssembleFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);

    const assemblyMethodValue = mockForm.fields['assem_assemblymethod'];

    const subForm1 = <>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_isolatereference'}
                title={'Reference sequence'}
                items={[
                    {
                        key: '1',
                        label: 'Sequence 1'
                    },
                    {
                        key: '2',
                        label: 'Sequence 2'
                    },
                    {
                        key: '3',
                        label: 'Sequence 3'
                    },
                    {
                        key: '4',
                        label: 'Sequence 4'
                    }
                ]}
            />
        </Grid>
    </>

    const subForm2 = <>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_genotypeserotype'}
                title={'Serotype (if known)'}
                items={[
                    {
                        key: '1',
                        label: 'A'
                    },
                    {
                        key: '2',
                        label: 'O'
                    },
                    {
                        key: '3',
                        label: 'C'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_genotypegenotype'}
                title={'Genotype'}
                items={[
                    {
                        key: '1',
                        label: 'Genotype 1'
                    },
                    {
                        key: '2',
                        label: 'Genotype 2'
                    },
                    {
                        key: '3',
                        label: 'Genotype 3'
                    }
                ]}
            />
        </Grid>
    </>

    const subForm3 = <>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_vp1serotype'}
                title={'Serotype (if known)'}
                items={[
                    {
                        key: '1',
                        label: 'A'
                    },
                    {
                        key: '2',
                        label: 'O'
                    },
                    {
                        key: '3',
                        label: 'C'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_vp1genotype'}
                title={'Genotype'}
                items={[
                    {
                        key: '1',
                        label: 'Genotype 1'
                    },
                    {
                        key: '2',
                        label: 'Genotype 2'
                    },
                    {
                        key: '3',
                        label: 'Genotype 3'
                    }
                ]}
            />
        </Grid>
    </>

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_isolate'}
                title={'Isolate for sequence'}
                items={[
                    {
                        key: '1',
                        label: 'Isolate A'
                    },
                    {
                        key: '2',
                        label: 'Isolate B'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'assem_assemblysequence'}
                title={'Sequence to be assembled'}
                items={[
                    {
                        key: '1',
                        label: 'Sequence 1'
                    },
                    {
                        key: '2',
                        label: 'Sequence 2'
                    },
                    {
                        key: '3',
                        label: 'Sequence 3'
                    },
                    {
                        key: '4',
                        label: 'Sequence 4'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockRadioButtons
                fieldName={'assem_assemblymethod'}
                className={classes.formControl}
                title={'Assembly method'}
                items={[
                    {
                        key: '1',
                        label: 'Reference Isolate'
                    },
                    {
                        key: '2',
                        label: 'Reference Genotype'
                    },
                    {
                        key: '3',
                        label: 'VP1 - Denovo + Reference Genotype'
                    },
                    {
                        key: '4',
                        label: 'Denovo'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        {
            assemblyMethodValue == '1'
                ? subForm1
                : assemblyMethodValue == '2'
                    ? subForm2
                    : assemblyMethodValue == '3'
                        ? subForm3
                        : assemblyMethodValue == '4'
                            ? null
                            : null
        }
        <Grid item xs={12} />
    </Grid>
}