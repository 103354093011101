/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for retrieving tooltips.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';

import { Epic, ofType, combineEpics } from 'redux-observable';

import { of } from 'rxjs';

import { mergeMap, catchError } from 'rxjs/operators';

import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest } from '../../../helpers/createRequest';
import { RequestState } from '../../../types/RequestState';
import { IRequestState } from '../../../types/IRequestState';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialTooltipsState {
    data?: SpreadDtos.Tooltip[];
    loadState: IRequestState;
}

export interface ITooltipsState {
    tooltips: IInitialTooltipsState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@tooltips';

export const tooltipsTypes = {
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialTooltipsState = {
    data: undefined,
    loadState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const tooltipsReducer = createReducer<IInitialTooltipsState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const tooltipsActions = {
    load: createAction(tooltipsTypes.LOAD, () => ({})),
    loadSuccess: createAction(tooltipsTypes.LOAD_SUCCESS,
        (tooltips: { [index: string]: SpreadDtos.Tooltip; }) => ({ tooltips })
    ),
    loadFailure: createAction(tooltipsTypes.LOAD_FAILURE,
        (response: SpreadDtos.GetTooltipsResponse) => ({ response })
    ),
    /**
     * This action clears the entire tooltips state.
     */
    clear: createAction(tooltipsTypes.CLEAR, () => ({})),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Load Reducers
tooltipsReducer.on(tooltipsActions.load, (state) => (
    update(
        state,
        {
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

tooltipsReducer.on(tooltipsActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.tooltips
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

tooltipsReducer.on(tooltipsActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
tooltipsReducer.on(tooltipsActions.clear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const tooltipsApi = {
    load: createGetRequest(
        SpreadDtos.GetTooltips,
        () => ({})
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const loadEpic: Epic<ReturnType<typeof tooltipsActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(tooltipsActions.load.getType()),
    mergeMap(action =>
        tooltipsApi.load()
            .pipe(
                mergeMap(response =>
                    of(
                        tooltipsActions.loadSuccess(response.tooltips)
                    )
                ),
                catchError(e => of(tooltipsActions.loadFailure(e)))
            )
    ));

export const tooltipsEpics = combineEpics(loadEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default tooltipsReducer;