/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple groups.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type IInitialGroupEpidemicSearchState = ISearchState<SpreadDtos.QueryPersonGroupEpidemic>;

export interface IGroupEpidemicSearchState {
    groupEpidemicSearch: IInitialGroupEpidemicSearchState;
}

export const initialState: IGroupEpidemicSearchState = {
    groupEpidemicSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const groupEpidemicsModule = createAutoQueryModule(
    'groupEpidemicSearch',
    initialSearchState,
    '@@groupEpidemicSearch',
    SpreadDtos.QueryGroupEpidemics,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        epidemicId: options.specialOptions.epidemicId,
        personGroupId: options.specialOptions.personGroupId
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const groupEpidemicSearchActions = groupEpidemicsModule.actions;
export const groupEpidemicSearchEpics = groupEpidemicsModule.epics;
export const groupEpidemicsApi = groupEpidemicsModule.api;
export const groupEpidemicsSelectors = groupEpidemicsModule.selectors;
export const groupEpidemicsHooks = groupEpidemicsModule.hooks;
export default groupEpidemicsModule.reducer;
