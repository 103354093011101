/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import mockupSequenceDifferences from '../../../assets/images/mockup_differences.png';
import { MockSelect } from './MockSelect';
import { MockFormContext } from './MockFormContext';
import { MockRadioButtons } from './MockRadioButtons';
import { MockMultiLineText } from './MockText';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        assemblyButtons: {
            margin: theme.spacing(1)
        },
        imageWrapper: {
            textAlign: 'center'
        },
        image: {
            width: '50%',
        }
    }),
);

interface IMockSequenceAnnotationFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

function createData(attribute: string, value: string) {
    return { attribute, value };
}

const mappingStatsRows = [
    createData('Length', '9812'),
    createData('Open Reading Frame', '9812'),
    createData('Serotype', '0'),
    createData('Genotype', '32'),
    createData('Topotype', 'O/Ind-200d'),
    createData('Base Counts', 'A:1931 T:1611 C:2133 G:1949 N:0 Other:3'),
];

export const MockSequenceAnnotationForm: React.FunctionComponent<IMockSequenceAnnotationFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);
    const dataSet = mockForm.fields['MockDataSetYear'];

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqano_genomeId'}
                title={'Genome ID'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: 'AA001100'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqano_epidemic'}
                title={'Epidemic'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: dataSet === 1982 ? '1982/83 - GDR / Denmark' : 'UK_FMD_2007'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqano_cluster'}
                title={'Cluster'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: dataSet === 1982 ? 'Eastern Funen - DNK' : 'Surrey'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={2} xs={12}>
            <MockRadioButtons
                className={classes.formControl}
                fieldName={'seqano_assessment'}
                title={'Quality Assessment'}
                items={[
                    {
                        key: '1',
                        label: 'Pass'
                    },
                    {
                        key: '2',
                        label: 'Fail'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={10} xs={12}>
            <MockMultiLineText
                className={classes.formControl}
                fieldName={'seqano_comments'}
                title='Comment'
                multiline
            />
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h5'}>Report</Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mappingStatsRows.map(row => (
                        <TableRow key={row.attribute}>
                            <TableCell component="th" scope="row">
                                {row.attribute}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        <Grid item lg={10} xs={12}>
            <MockMultiLineText
                className={classes.formControl}
                fieldName={'seqano_assemblycomments'}
                title='Assembly Comments'
                multiline
            />
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Differences Between Sequence and Reference Sequence</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
            <div className={classes.imageWrapper}>
                <img
                    className={classes.image}
                    src={mockupSequenceDifferences}
                    alt="Sequence Reference Differences"
                />
            </div>
        </Grid>
        <Grid item xs={12} />
    </Grid>
}