/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ViewMSA } from '../components/genomic/MSA/ViewMSA';
import msaModule from '../store/reducers/msa/msa';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IMSARouterParams {
    epidemicId?: string;
    msaId?: string;
}

interface IMSARouterProps {
    msaId?: number;
    match: match<IMSARouterParams>;
}

export const MSARouter: React.FunctionComponent<IMSARouterProps> = ({ msaId: msaIdProp, match }) => {
    const msaId = msaIdProp || (match.params.msaId ? parseInt(match.params.msaId) : -1);

    // Load in msa so child routes can use
    const msaIdMemo = React.useMemo(() => msaId, [msaId]);
    const [, response] = msaModule.hooks.useLoad(msaIdMemo);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
            <Redirect to="/404" /> :
            <Switch>
                <Route exact path={`${match.path}/review`} component={ViewMSA} />
            </Switch>
}