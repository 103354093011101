/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { usePersonLoad } from '../../hooks/person';
import { RequestState } from '../../types/RequestState';
import { ViewPersonRoles } from '../roles/ViewPersonRoles';
import { ViewPerson } from './ViewPerson';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IPersonHomeParams {
    personId: string;
    epidemicId: string;
    subPersonId: string;
}

interface IPersonHomeProps {
    personId?: number;
    history: History;
    loading: boolean;
    match: match<IPersonHomeParams>;
}

export const PersonHome: React.FunctionComponent<IPersonHomeProps> = ({ personId, history, match }) => {
    const classes = useStyles();

    const personIdTemp = personId || (match.params ? parseInt(match.params.personId) : undefined);
    const personIdLoaded = React.useMemo(() => personIdTemp, [personIdTemp]);

    if (!personIdLoaded) {
        throw Error("Invalid ID specified");
    }

    const [personData, personState] = usePersonLoad(personIdLoaded);

    if (personData === undefined && personState.state === RequestState.Success) {
        history.push('/404');
    }

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewPerson
                person={personData}
                loading={personState.state === RequestState.Pending}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewPersonRoles
                personId={personIdLoaded}
                match={match}
            />
        </Box>
    </Box>
}
