/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { GroupMemberList } from './GroupMemberList';
import { DateTimeFormat } from '../../constants/Dates';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        }
    }),
);

interface IViewGroupParams {
}

interface IViewGroupProps {
    group?: SpreadDtos.PersonGroup;
    history: History;
    loading: boolean;
    match: match<IViewGroupParams>;
}

export const ViewGroup: React.FunctionComponent<IViewGroupProps> = ({ group, history, loading, match }) => {
    const classes = useStyles();

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">{!loading ? group && group.displayName : <Skeleton />}</Typography>
        <Typography variant="h5" component="h5">{!loading ? group && `Created: ${format(toDate(group.createdDate), DateTimeFormat)}` : <Skeleton />}</Typography>

        <Typography variant="h6" component="h6">Members</Typography>
        <GroupMemberList
            history={history}
            items={group && group.groupMembers ? group.groupMembers : []}
            groupId={group && group.id}
        />
        {!loading && group ?
            <Box display="flex" justifyContent="flex-end">
                <HasPermission permission="UpdateGroup" objectId={group.id}>
                    <ButtonLink
                        aria-label="Edit Group"
                        color="primary"
                        variant="contained"
                        to={`${match.url}/edit`}
                    >
                        <EditIcon className={classes.buttonSubmitIcon} />
                        Edit
                    </ButtonLink>
                </HasPermission>
            </Box> :
            undefined
        }
    </Paper>
}