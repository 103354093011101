/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ModalProps } from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import ButtonExternalLink from './ButtonExternalLink';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    }),
);

interface IImageDialogProps extends Partial<ModalProps> {
    open: boolean;
    src: string;
    title?: string;
}

const ImageDialog: React.FunctionComponent<IImageDialogProps> = ({ src, title, open, onClose, ...rest }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return <Dialog
        open={open}
        fullScreen={fullScreen}
        {...rest}
    >
        <DialogTitle>{title || "View Image"}</DialogTitle>
        <DialogContent>
            <img
                src={src}
                className={classes.img}
            />
        </DialogContent>
        <DialogActions>
            <Box width="100%" display="flex" justifyContent="space-between">
                <ButtonExternalLink href={src} target="_blank" color="primary">
                    Open in new tab
                </ButtonExternalLink>
                <Button onClick={() => onClose ? onClose({}, "backdropClick") : undefined} color="primary">
                    Close
                </Button>
            </Box>
        </DialogActions>
    </Dialog>;
}

export default ImageDialog;