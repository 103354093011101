/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match } from 'react-router';
import Skeleton from 'react-loading-skeleton';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { GroupInviteList } from '../../components/profile/GroupInviteList';

export interface IPersonProfilePageProps {
  classes?: any;
  match: match;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export const PersonProfilePage: React.FunctionComponent<IPersonProfilePageProps> = (props) => {
  const classes = useStyles();

  return (<Container maxWidth="lg">
    <Grid container alignItems="flex-start" spacing={2} >
      <Grid item xs={12} lg={6}>
        <Paper className={classes.root}>
        <Typography variant="h5" component="h5">Pending group invites</Typography>
          <GroupInviteList />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.root}>
        <Typography variant="h5" component="h5">Your system roles</Typography>
          <RolesAndPermReadout />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Paper className={classes.root}>
        <Typography variant="h5" component="h5">Your groups</Typography>
          <MemberGroupsReadOut />
        </Paper>
      </Grid>
    </Grid>
  </Container>
  );
}



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 2)
    }
  }),
);

const RolesAndPermReadout: React.FunctionComponent<any> = (props) => {
  const userState = useSelector(state => state.authenticatedPerson.data);

  var groupPermissions: any[] = [];

  if (userState) {
    for (const group in userState.groupPermissions) {
      groupPermissions.push(userState.groupPermissions[group].map((p, i) => <li key={i}>{p} on Group {group}</li>));
    }
  }

  var epidemicPermissions: any[] = [];

  if (userState) {
    for (const epidemic in userState.epidemicPermissions) {
      epidemicPermissions.push(userState.epidemicPermissions[epidemic].map((p, i) => <li key={i}>{p} on Epidemic {epidemic}</li>));
    }
  }

  return userState
    ? <ul>
        {
          userState.permissions ? userState.permissions.map((r, i) => <li key={i}>{r} on All</li>) : null
        }
        {
          groupPermissions
        }
        {
          epidemicPermissions
        }
      </ul>
    : <Skeleton />;

}


const MemberGroupsReadOut: React.FunctionComponent<any> = (props) => {
  const userState = useSelector(state => state.authenticatedPerson.data);

  return userState
    ? <ul>
        {
          userState.memberGroups ? userState.memberGroups.map((g, i) => <li key={g.id}>{g.displayName}</li>) : null
        }
      </ul>
    : <Skeleton />;

}