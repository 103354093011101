/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple epidemics.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialEpidemicSearchState = ISearchState<SpreadDtos.QueryEpidemic>;

export interface IEpidemicSearchState {
    epidemicSearch: IInitialEpidemicSearchState;
}

export const initialState: IEpidemicSearchState = {
    epidemicSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const epidemicsModule = createAutoQueryModule(
    'epidemicSearch',
    initialSearchState,
    '@@epidemicSearch',
    SpreadDtos.QueryEpidemics,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        includeArchived: options.specialOptions.includeArchived,
        canAssignRoleId: options.specialOptions.canAssignRoleId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const epidemicSearchActions = epidemicsModule.actions;
export const epidemicSearchEpics = epidemicsModule.epics;
export const epidemicsApi = epidemicsModule.api;
export const epidemicsSelectors = epidemicsModule.selectors;
export const epidemicsHooks = epidemicsModule.hooks;
export default epidemicsModule.reducer;