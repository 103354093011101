/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { EditCountry } from '../../components/country/EditCountry';
import { CountryHome } from '../../components/country/CountryHome';
import { ViewCountries } from '../../components/country/ViewCountries';
import { CreateCountry } from '../../components/country/CreateCountry';
import { CreateSubNational } from '../../components/subNational/CreateSubNational';
import { EditSubNational } from '../../components/subNational/EditSubNational';

export interface ICountriesPageProps {
    classes?: any;
    match: match;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export const CountriesPage: React.FunctionComponent<ICountriesPageProps> = (props) => {
    return (<Switch>
        <Route exact path={`${props.match.path}/create`} component={CreateCountry} />
        <Route exact path={`${props.match.path}/:countryId`} component={EditCountry} />
        <Route exact path={`${props.match.path}/:countryId/subNational/create`} component={CreateSubNational} />
        <Route exact path={`${props.match.path}/:countryId/subNational/:subNationalId/edit`} component={EditSubNational} />
        <Route path={`${props.match.path}`} component={ViewCountries} />
    </Switch>
    );
}