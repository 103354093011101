/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useState } from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ReactLeafletControl from '../reactLeafletControl/ReactLeafletControl';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IResponsiveMapControl {
    position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        togglePaper: {
            width: '40px',
            height: '40px'
        },
        toggleButton: {
            //padding: theme.spacing(1, 1),
            position: 'absolute',
            top: 0,
            right: 0
        },
        legendInner: {
            padding: theme.spacing(0, 2, 1, 2),
        },
        legendPaper: {
            //margin: theme.spacing(2, 1),
            //padding: theme.spacing(1, 2),
        },
        legendTitle: {
            paddingTop: theme.spacing(1)
        }
    })
);

const ResponsiveMapControl: React.FunctionComponent<IResponsiveMapControl> = ({
    children,
    position

}) => {
    const classes = useStyles();
    const theme = useTheme();
    const bigScreen = useMediaQuery(theme.breakpoints.up('md'));

    const positionTop = position === 'topleft' || position === 'topright';

    const [open, setOpen] = useState(false);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(!open);
    }

    return <ReactLeafletControl position={position}>
        {
            !open && <ResponsiveMapToggleButton
                className={classes.toggleButton}
                handleOpen={handleOpen}
                invertIconDirection={positionTop}
                open={open}
                wrap={true}
                wrapClassName={classes.togglePaper}
            />
        }
        {
            open || bigScreen ? <div className={classes.legendPaper} >
                <Box className={classes.legendInner}>
                    <ResponsiveMapToggleButton
                        className={classes.toggleButton}
                        handleOpen={handleOpen}
                        invertIconDirection={positionTop}
                        open={open}
                    />
                    {children}
                </Box>
            </div>
                : null
        }
    </ReactLeafletControl>;
}

interface IResponsiveMapToggleButton {
    className?: string;
    handleOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    invertIconDirection: boolean;
    open: boolean;
    wrap?: boolean;
    wrapClassName?: string;
}

const ResponsiveMapToggleButton: React.FunctionComponent<IResponsiveMapToggleButton> = ({
    className,
    handleOpen,
    invertIconDirection,
    open,
    wrap,
    wrapClassName
}) => {
    const buttonElement = <IconButton className={className} onClick={handleOpen}>
        {
            (!open && invertIconDirection) || (!invertIconDirection && open) ? <ExpandMoreIcon />
                : <ExpandLessIcon />
        }
    </IconButton>;

    return <Hidden mdUp implementation="css">
        {wrap ? <Paper className={wrapClassName}>
            {buttonElement}
        </Paper>
            : buttonElement
        }

    </Hidden>;
};

export default ResponsiveMapControl;