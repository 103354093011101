/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateCluster } from '../../components/cluster/CreateCluster';
import { ViewClusters } from '../../components/cluster/ViewClusters';
import { ClusterRouter } from '../../routers/ClusterRouter';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IClustersPageProps {
    match: match;
}

export const ClustersPage: React.FunctionComponent<IClustersPageProps> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/create`} component={CreateCluster} />
        <Route exact path={`${match.path}`} component={ViewClusters} />
        <Route path={`${match.path}/:clusterId`} component={ClusterRouter} />
    </Switch>;
}
