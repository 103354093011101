/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving a areaLivestockCount
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActionTypes, createCrudSelectors, createCrudEpics, IBaseCrudState } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialAreaLivestockCountState extends IBaseCrudState {
    data?: SpreadDtos.AreaLivestockCount;
}

export interface IAreaLivestockCountState {
    areaLivestockCount: IInitialAreaLivestockCountState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@areaLivestockCount';

export const areaLivestockCountTypes = {
    ...createCrudActionTypes(typeNamespace),
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAreaLivestockCountState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const areaLivestockCountReducer = createReducer<IInitialAreaLivestockCountState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const areaLivestockCountActions = {
    /**
     * Begins the request to create a new areaLivestockCount.
     * 
     * This cannot update an existing areaLivestockCount. Use update for that.
     */
    create: createAction(areaLivestockCountTypes.CREATE,
        (areaLivestockCount: SpreadDtos.AreaLivestockCount) => ({ object: areaLivestockCount })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    createFormResponse: createAction(areaLivestockCountTypes.CREATE_FORM_RESPONSE,
        (response: SpreadDtos.CreateAreaLivestockCountResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given areaLivestockCount.
     */
    load: createAction(areaLivestockCountTypes.LOAD,
        (areaLivestockCountId: number) => ({ id: areaLivestockCountId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    loadFormResponse: createAction(areaLivestockCountTypes.LOAD_FORM_RESPONSE,
        (response: SpreadDtos.GetAreaLivestockCountResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to update a areaLivestockCount.
     * 
     * This cannot create a new areaLivestockCount. Use save for that.
     */
    update: createAction(areaLivestockCountTypes.UPDATE,
        (areaLivestockCount: SpreadDtos.AreaLivestockCount) => ({ object: areaLivestockCount })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    updateFormResponse: createAction(areaLivestockCountTypes.UPDATE_FORM_RESPONSE,
        (response: SpreadDtos.UpdateAreaLivestockCountResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the form request state.
    */
    clearFormState: createAction(areaLivestockCountTypes.CLEAR_FORM, () => ({})),
    /**
    * Clears the areaLivestockCount state.
    */
    clear: createAction(areaLivestockCountTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(areaLivestockCountReducer, areaLivestockCountActions, 'areaLivestockCount', initialState);

// Clear Reducer
areaLivestockCountReducer.on(areaLivestockCountActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
areaLivestockCountReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const areaLivestockCountsApi = {
    create: createPostRequest(
        SpreadDtos.CreateAreaLivestockCount,
        (areaLivestockCount: SpreadDtos.AreaLivestockCount) => ({
            areaLivestockCount: areaLivestockCount
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetAreaLivestockCount,
        (areaLivestockCountId: number) => ({
            id: areaLivestockCountId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateAreaLivestockCount,
        (areaLivestockCount: SpreadDtos.AreaLivestockCount) => ({
            areaLivestockCount: areaLivestockCount
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(areaLivestockCountActions, areaLivestockCountsApi);

export const areaLivestockCountEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const areaLivestockCountSelectors = createCrudSelectors('areaLivestockCount');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default areaLivestockCountReducer;