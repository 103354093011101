/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { History } from 'history';
import * as React from 'react';
import { useState } from 'react';

import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

import Box from '@material-ui/core/Box';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DrawerWidthPx, HeaderHeightPx } from '../../constants/Layout';
import { Header } from './Header';
import { Nav } from './Nav';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface ILayoutProps {
    showNav: boolean;
    title?: string;
    history: History;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'block',
            width: '100%',
            // remove gutters to make map size calculations easier
            paddingLeft: 0,
            paddingRight: 0
        },
        root: {
            display: 'flex',
        },
        header: {
            backgroundColor: theme.palette.primary.main, //'#18202c',
            marginLeft: (props: ILayoutProps): number => props.showNav ? DrawerWidthPx : 0,
            [theme.breakpoints.up('md')]: {
                width: (props: ILayoutProps): string => props.showNav ? `calc(100% - ${DrawerWidthPx}px)` : '100%',
            },
            height: HeaderHeightPx
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        toolbar: theme.mixins.toolbar,

    })
);

export const Layout: React.FunctionComponent<ILayoutProps> = (props) => {

    const classes = useStyles(props);

    const [drawerOpen, drawerOpenUpdate] = useState(false);

    function toggleDrawerOpen() {
        drawerOpenUpdate(!drawerOpen);
    }

    return (<div className={classes.root}>
        <Header classNames={classes.header} onMenuClick={toggleDrawerOpen} history={props.history} />

        {
            props.showNav
                ? <Nav
                    drawerOpen={drawerOpen}
                    title={props.title}
                    toggleDrawerOpen={toggleDrawerOpen}
                />
                : null}

        <Box className={classes.container}>
            <div className={classes.toolbar} />
            {props.children}
        </Box>
    </div>);
}
