/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { infectedPremiseActions, infectedPremiseSelectors } from '../../store/reducers/infectedPremises/infectedPremise';
import ClusterSelect from '../forms/ClusterSelect/ClusterSelect';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import SubClusterSelect from '../forms/SubClusterSelect/SubClusterSelect';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
*/

interface IInfectedPremiseEditFormProps
{
    editInfectedPremise?: SpreadDtos.InfectedPremise;
    history: History;
    loadingInfectedPremise?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IInfectedPremiseFormSchema extends Partial<SpreadDtos.InfectedPremise>
{
}

const infectedPremiseSchema = Yup.object<IInfectedPremiseFormSchema>().shape({
    farmId: Yup.string().label('Farm ID')
        .notRequired(),
    infectedPremiseId: Yup.string().label('Infected Premise ID')
        .notRequired(),
});

const validation = Yup.object().shape({
    object: infectedPremiseSchema.notRequired()
})

const InfectedPremiseEditForm: React.FunctionComponent<IInfectedPremiseEditFormProps> = ({
    editInfectedPremise,
    history,
    loadingInfectedPremise,
    url,
}) =>
{
    const classes = useStyles();
    const editMode = editInfectedPremise && editInfectedPremise.id;
    const epidemicId = editInfectedPremise ? editInfectedPremise.epidemicId : -1;

    const wrappedObject = {
        object: editInfectedPremise
    };

    return <EditForm
        actions={infectedPremiseActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingInfectedPremise}
        mutators={{ clear: clear }}
        url={url}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        validate={validation}
        selectors={infectedPremiseSelectors}
    >{() => <>
        <FormSpy>
            {props => <>
                <OnChange name={"object.clusterId"}>
                    {(value: any) =>
                    {
                        props.form.mutators.clear('object.subClusterId');
                    }}
                </OnChange>
            </>}
        </FormSpy>
        <Grid item xs={12}>
            <Field name="object.clusterId" render={
                clusterProps => (
                    <ClusterSelect
                        displayMode="basic"
                        epidemicId={epidemicId}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        input={clusterProps.input}
                        label="Cluster"
                        meta={clusterProps.meta}
                        unselectLabel={'None'}
                    />
                )
            } />
        </Grid>
        <Grid item xs={12}>
            <Field name="object.clusterId" render={
                (clusterProps) => (
                    <Field name="object.subClusterId" render={
                        subClusterProps => (
                            <SubClusterSelect
                                clusterId={Number(clusterProps.input.value) || undefined}
                                epidemicId={epidemicId}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                    required: false
                                }}
                                disabled={Number(clusterProps.input.value) == -1}
                                input={subClusterProps.input}
                                label="Sub-Cluster"
                                meta={subClusterProps.meta}
                                unselectLabel={'None'}
                            />
                        )
                    } />
                )
            } />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.infectedPremiseId"
                component={TextFieldWrapper}
                type="text"
                label="Infected Premise ID"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.farmId"
                component={TextFieldWrapper}
                type="text"
                label="Farm ID"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.centreLat"
                component={TextFieldWrapper}
                type="number"
                label="Latitude"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.centreLng"
                component={TextFieldWrapper}
                type="number"
                label="Longitude"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.area"
                component={TextFieldWrapper}
                type="number"
                label="Area"
            />
        </Grid>
    </>}
    </EditForm>
};

export default InfectedPremiseEditForm;