import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

import Select from '@material-ui/core/Select';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

interface SelectWrapperProps extends FieldRenderProps<any, any> {
    label?: string;
    formControlProps?: FormControlProps;
    disabled?: boolean;
}

const SelectWrapper: React.FunctionComponent<SelectWrapperProps> = ({
    input: { name, value, onChange, ...restInput },
    disabled,
    meta,
    label,
    formControlProps,
    ...rest
}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    return (
        <FormControl {...formControlProps} error={showError}>
            { label && <InputLabel htmlFor={name}>{label}</InputLabel> }

            <Select
                {...rest}
                disabled={disabled}
                name={name}
                onChange={onChange}
                inputProps={restInput}
                value={value}
            />

            {showError &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    );
};

export default SelectWrapper;