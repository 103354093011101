/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../constants/Dates';
import { SpreadWorkflowJobStatus } from '../../dtos/Spread.dtos';
import { spreadWorkflowJobStatusDescription } from '../../helpers/enumDescription';
import { useSelector } from '../../hooks/useTypedSelector';
import excretionModule from '../../store/reducers/excretion/excretion';
import excretionSearchModule from '../../store/reducers/excretion/excretionSearch';
import { RequestState } from '../../types/RequestState';
import ImageDialog from '../common/ImageDialog';
import { SortableTable } from '../table/SortableTable';
import { ITableHeadSortableColumn } from '../table/TableHeadSortable';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        row: {
        },
        buttonDeleteIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IExcretionJobListProps {
    className?: string;
    url: string;
}


export const ExcretionJobList: React.FunctionComponent<IExcretionJobListProps> = ({ className, url }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const epidemic = useSelector(state => state.epidemic.data);
    const person = useSelector(state => state.authenticatedPerson.data);
    const deleteRequestState = useSelector(state => state.excretion.states.deleteState);
    const cancelRequestState = useSelector(state => state.excretion.extraStates.cancelState);

    const headerColumns: ITableHeadSortableColumn[] = [
        { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'jobId', numeric: false, disablePadding: false, label: 'Job Key' },
        { id: 'failureMessage', numeric: false, disablePadding: false, label: 'Failure Message' },
        { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Requested At' },
        { id: 'image', numeric: false, disablePadding: false, label: 'Image' },
        { id: 'download', numeric: false, disablePadding: false, label: 'Download' }
    ];

    const epidemicId = epidemic && epidemic.id;

    const pageOps = useMemo(() => ({ skip: 0, take: 10, orderBy: undefined, orderByDesc: 'Id' }), []);
    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId, deleteRequestState.state, cancelRequestState.state]);
    const [data, paginateOptions, , requestState] = excretionSearchModule.hooks.useSearch(specOps, pageOps);

    // Delete stuff
    const hasDeletePerm = person ? person.permissions.find(p => p === 'DeleteExcretionJob') !== undefined : false;

    if (hasDeletePerm) {
        headerColumns.push({ id: 'delete', numeric: false, disablePadding: true, disableSorting: true, label: '' });
    }

    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteConfirmOpen(true);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            if (excretionModule.actions.deleteJob) {
                dispatch(excretionModule.actions.deleteJob(idToDelete));
            }
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [idToDelete, setIdToDelete, setDeleteConfirmOpen],
    );

    // Image stuff

    const [isImageOpen, setImageOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(undefined as number | undefined);

    const handleImageClick = React.useCallback(
        (id: number) => {
            setSelectedId(id);
            setImageOpen(true);
        },
        [setImageOpen, setSelectedId],
    );

    const handleImageClose = React.useCallback(
        () => {
            setImageOpen(false);
            setSelectedId(undefined);
        },
        [setImageOpen, setSelectedId],
    );

    // Download stuff

    const handleDownloadClick = (apiJobId: number) => {
        dispatch(excretionModule.actions.loadResultFile(apiJobId));
    }

    // Cancel stuff

    const handleCancelClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            dispatch(excretionModule.actions.cancelJob(parseInt(event.currentTarget.value)));
        },
        [],
    );

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={excretionSearchModule.actions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0 ?
                    data.results.map((item =>
                        <TableRow className={classes.row} key={item.id}>
                            <TableCell component="th" scope="row">
                                {item.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {spreadWorkflowJobStatusDescription(item.status)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.apiJobId}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.failureMessage}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {format(toDate(item.requestedDate), DateTimeFormat)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.resultParsed ?
                                        <IconButton edge="start" color="inherit" aria-label="View Map" onClick={() => handleImageClick(item.id)}>
                                            <ImageIcon />
                                        </IconButton> :
                                        null
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.status == SpreadWorkflowJobStatus.ResultsDownloaded ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessed ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessing ?
                                        <IconButton aria-label="Download" color="primary" onClick={() => handleDownloadClick(item.apiJobId)} >
                                            <DownloadIcon />
                                        </IconButton> :
                                        ""
                                }
                            </TableCell>
                            {
                                <TableCell component="th" scope="row">
                                    {
                                        item.status == SpreadWorkflowJobStatus.Created ||
                                            item.status == SpreadWorkflowJobStatus.Initing ||
                                            item.status == SpreadWorkflowJobStatus.Inited ||
                                            item.status == SpreadWorkflowJobStatus.Running ?
                                            <Button
                                                aria-label="Cancel Job"
                                                color="primary"
                                                value={item.apiJobId}
                                                onClick={handleCancelClick}
                                            >
                                                <CancelIcon className={classes.buttonDeleteIcon} />
                                            </Button> :
                                            hasDeletePerm &&
                                                (item.status == SpreadWorkflowJobStatus.Cancelled ||
                                                    item.status == SpreadWorkflowJobStatus.Failed ||
                                                    item.status == SpreadWorkflowJobStatus.CreateError ||
                                                    item.status == SpreadWorkflowJobStatus.InitError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsDownloadError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsProcessed) ?
                                                <Button
                                                    aria-label="Delete Job"
                                                    color="primary"
                                                    value={item.apiJobId}
                                                    onClick={handleDeleteClick}
                                                >
                                                    <DeleteIcon className={classes.buttonDeleteIcon} />
                                                </Button> :
                                                undefined
                                    }
                                </TableCell>
                            }
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending ?
                                <Skeleton /> :
                                'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <ImageDialog
            open={isImageOpen}
            src={selectedId ? `/file/excretion/${selectedId}/image` : ""}
            title={`Excretion ${selectedId}`}
            onClose={handleImageClose}
        />
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this job? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}