/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import EnrichmentMethodEditForm from './EnrichmentMethodEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateEnrichmentMethodParams { }

interface ICreateEnrichmentMethodProps {
    history: History;
    match: match<ICreateEnrichmentMethodParams>;
}

export const CreateEnrichmentMethod: React.FunctionComponent<ICreateEnrichmentMethodProps> = (props) => {
    const classes = useStyles(props);

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Create Enrichment Method</Typography>
        <EnrichmentMethodEditForm
            history={props.history}
            url={props.match.url}
        />
    </Paper>
}