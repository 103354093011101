/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an platform
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreatePlatform,
    CreatePlatformResponse,
    Platform,
    DeletePlatform,
    DeletePlatformResponse,
    GetPlatform,
    GetPlatformResponse,
    UpdatePlatform,
    UpdatePlatformResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPlatformState {
    platform: IBaseCrudState<Platform>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const platformModule = createCrudModule<Platform, CreatePlatformResponse, GetPlatformResponse, UpdatePlatformResponse, DeletePlatformResponse, undefined>(
    'platform',
    CreatePlatform,
    GetPlatform,
    UpdatePlatform,
    DeletePlatform,
    undefined,
)


export default platformModule;