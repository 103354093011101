/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox, ListItemText, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import { isNumber } from 'util';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import { RequestState } from '../../types/RequestState';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import diseaseSubClassificationModule from '../../store/reducers/diseaseSubClassifications/diseaseSubClassification';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import { subClassificationSearchHooks } from '../../store/reducers/subClassifications/subClassificationSearch';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IDiseaseSubClassificationEditFormProps {
    editDiseaseSubClassification?: SpreadDtos.DiseaseSubClassification;
    disease?: SpreadDtos.Disease;
    diseaseClassification?: SpreadDtos.DiseaseClassification;
    history: History;
    loadingDiseaseSubClassification?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IDiseaseSubClassificationFormSchema extends Partial<SpreadDtos.DiseaseSubClassification> {
    value: string;
    subClassificationId: number;
}

const diseaseSubClassificationSchema = Yup.object<IDiseaseSubClassificationFormSchema>().shape({
    diseaseClassificationId: Yup.number().label('Disease Classification').required(),
    value: Yup.string().label('Value').required(),
    subClassificationId: Yup.number().label('Sub Classification').required(),
});

const validation = Yup.object().shape({
    object: diseaseSubClassificationSchema.notRequired()
})


const DiseaseSubClassificationEditForm: React.FunctionComponent<IDiseaseSubClassificationEditFormProps> = ({
    editDiseaseSubClassification,
    disease,
    diseaseClassification,
    history,
    loadingDiseaseSubClassification,
    url,
}) => {
    const diseaseClassificationId = editDiseaseSubClassification ? editDiseaseSubClassification.diseaseClassificationId : diseaseClassification && diseaseClassification.id;
    const diseaseId = diseaseClassification && diseaseClassification.diseaseId;
    const editMode = editDiseaseSubClassification !== undefined;

    const wrappedObject = editMode ? {
        object: editDiseaseSubClassification
    } : undefined;
    const classes = useStyles({});

    // setup sub classifications settings
    const [subClassificationId, setSubClassificationId] = React.useState(undefined as number | undefined);

    if (!subClassificationId && editDiseaseSubClassification && editDiseaseSubClassification.subClassificationId) {
        setSubClassificationId(editDiseaseSubClassification.subClassificationId);
    }

    const updateSubClassificationId = (subClassificationId: number | undefined) => {
        setSubClassificationId(subClassificationId);
    }

    const [subClassificationData, , , subClassificationRequestState] = subClassificationSearchHooks.useSearch();

    const subClassificationOptions = subClassificationData && subClassificationData.results && subClassificationData.results.length > 0 ?
        subClassificationData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];
    // ~ Sub classifications

    return <EditForm
        actions={diseaseSubClassificationModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingDiseaseSubClassification}
        selectors={diseaseSubClassificationModule.selectors}
        successRouteCallback={(d) => d ? `/admin/disease/${diseaseId}/diseaseclassification/${diseaseClassificationId}/edit` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <TextField
                label={'Disease'}
                disabled={true}
                fullWidth
                name={"object.disease.name"}
                value={disease ? disease.name : ""}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={'Classification'}
                disabled={true}
                fullWidth
                name={"object.diseaseClassification.classification.name"}
                value={diseaseClassification ? diseaseClassification.classification.name : ""}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                label={'Classification Value'}
                disabled={true}
                fullWidth
                name={"object.diseaseClassification.value"}
                value={diseaseClassification ? diseaseClassification.value : ""}
            />
        </Grid>
        <Grid item xs={12}>
            {
                (subClassificationRequestState.state !== RequestState.Pending) ?
                    <Field
                        fullWidth
                        required
                        disabled={false}
                        name="object.subClassificationId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Sub Classification"
                    >
                        {subClassificationOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.subClassificationIdLoading"
                        component={TextFieldWrapper}
                        type="text"
                        label="Sub Classification"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <FormSpy>
            {props => <Field
                name="object.subClassificationId"
                subscription={{ value: true }}
                render={field => {
                    const value = field.input.value;
                    if (isNumber(value) && value > 0 && value !== subClassificationId) {
                        updateSubClassificationId(value);
                    }
                    return null;
                }}
            />}
        </FormSpy>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.value"
                component={TextFieldWrapper}
                type="text"
                label="Value"
            />
        </Grid>
    </>}
    </EditForm>
};


export default DiseaseSubClassificationEditForm;