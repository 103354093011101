/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an Sub classification
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateSubClassification,
    CreateSubClassificationResponse,
    SubClassification,
    DeleteSubClassification,
    DeleteSubClassificationResponse,
    GetSubClassification,
    GetSubClassificationResponse,
    UpdateSubClassification,
    UpdateSubClassificationResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ISubClassificationState {
    subClassification: IBaseCrudState<SubClassification>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const subClassificationModule = createCrudModule<SubClassification, CreateSubClassificationResponse, GetSubClassificationResponse, UpdateSubClassificationResponse, DeleteSubClassificationResponse, undefined>(
    'subClassification',
    CreateSubClassification,
    GetSubClassification,
    UpdateSubClassification,
    DeleteSubClassification,
    undefined,
)


export default subClassificationModule;