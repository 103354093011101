/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { History } from 'history';
import * as React from 'react';
import { useCallback } from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { HasPermission } from '../common/HasPermission';
import { AreaDateList } from './AreaDateList';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAreaDatesParams {
    areaId?: string;
    clusterId?: string;
    epidemicId?: string;
    infectedPremiseId?: string;
}

interface IViewAreaDatesProps {
    areaId?: number;
    className?: string;
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    match: match<IViewAreaDatesParams>;
}

export const ViewAreaDates: React.FunctionComponent<IViewAreaDatesProps> = (props) => {
    const classes = useStyles();

    const url = props.match.url + (!props.match.url.endsWith('/date') ? '/date' : '');
    const clusterId = props.clusterId || (props.match.params && props.match.params.clusterId ? parseInt(props.match.params.clusterId) : undefined);
    const epidemicId = props.epidemicId || (props.match.params && props.match.params.epidemicId ? parseInt(props.match.params.epidemicId) : undefined);
    const infectedPremiseId = props.infectedPremiseId || (props.match.params && props.match.params.infectedPremiseId ? parseInt(props.match.params.infectedPremiseId) : undefined);
    const areaId = props.areaId || (props.match.params && props.match.params.areaId ? parseInt(props.match.params.areaId) : undefined);

    const handleCreate = useCallback(
        () => props.history.push(`${url}/create`),
        [props.history, url],
    );

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Dates</Typography>
        <AreaDateList
            areaId={areaId}
            className={classes.list}
            clusterId={clusterId}
            epidemicId={epidemicId}
            infectedPremiseId={infectedPremiseId}
            history={props.history}
            url={url}
        />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateAreaDate" objectId={epidemicId}>
                <Button
                    aria-label="Create Date"
                    color="primary"
                    variant="contained"
                    onClick={handleCreate}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </Button>
            </HasPermission>
        </Box>
    </Paper>
}