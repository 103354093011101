/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { fieldActions, fieldSelectors } from '../../store/reducers/fields/field';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IFieldEditFormProps
{
    editField?: SpreadDtos.Field;
    history: History;
    loadingField?: boolean;
    url: string;

    // For create
    clusterId?: number;
    epidemicId?: number;
    infectedPremiseId?: number;
    parentParcelId?: number;
    subClusterId?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IFieldFormSchema extends Partial<SpreadDtos.Field>
{
}

const fieldSchema = Yup.object<IFieldFormSchema>().shape({
    name: Yup.string().label('Field name')
        .required(),
    parentParcelId: Yup.number().label('Parcel')
        .required(),
});

const validation = Yup.object().shape({
    object: fieldSchema.notRequired()
})

const FieldEditForm: React.FunctionComponent<IFieldEditFormProps> = ({
    editField,
    history,
    loadingField,
    url,
}) =>
{
    const editMode = editField !== undefined && editField.id !== undefined;

    const wrappedObject = {
        object: editField
    };

    return <EditForm
        actions={fieldActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingField}
        url={url}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        validate={validation}
        selectors={fieldSelectors}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Field Name"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.centreLat"
                component={TextFieldWrapper}
                type="number"
                label="Latitude"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.centreLng"
                component={TextFieldWrapper}
                type="number"
                label="Longitude"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.area"
                component={TextFieldWrapper}
                type="number"
                label="Area"
            />
        </Grid>
    </>}
    </EditForm>
};

export default FieldEditForm;