/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../dtos/Spread.dtos';

export interface INetworkEdgeThatWillEventuallyBeADTO {
    infectedPremiseFrom: SpreadDtos.InfectedPremise;
    infectedPremiseTo: SpreadDtos.InfectedPremise;
    probability: number;
}

const cluster: SpreadDtos.QueryCluster = {
    id: 2,
    epidemicId: 1,
    name: "Surrey",
    unassigned: false,
    infectedPremiseCount: 26,
    clusterDateSet: {
        earliestStartOfInfectiousPeriodDate: '',
        earliestPresumedInfectionDate: '',
        latestEndOfInfectiousPeriodDate: '',
        isEarliestStartOfInfectiousPeriodDateCalculated: false,
        isEarliestPresumedInfectionDateCalculated: false,
        isLatestEndOfInfectiousPeriodDateCalculated: false
    }
};

const subCluster: SpreadDtos.SubCluster = {
    id: 2,
    clusterId: 2,
    name: "[Unassigned]",
    unassigned: true,
    cluster: cluster,
};

export const surreyIPs: SpreadDtos.InfectedPremise[] = [
    {
        id: 3,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP1b",
        farmId: "1b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.24288,
        centreLng: -0.67542,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 4,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP1c",
        farmId: "1c",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.17485,
        centreLng: -0.68998244,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 5,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP2a",
        farmId: "2a",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.26498,
        centreLng: -0.64174,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 6,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP2b",
        farmId: "2b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.25763,
        centreLng: -0.64202,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 7,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP2c",
        farmId: "2c",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.25526,
        centreLng: -0.614466716,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 9,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP3b",
        farmId: "3b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.41344,
        centreLng: -0.55105,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 10,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP3c",
        farmId: "3c",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.42202,
        centreLng: -0.51339,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 11,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP4b",
        farmId: "4b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.42088,
        centreLng: -0.55268,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 12,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP5",
        farmId: "5",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.39574,
        centreLng: -0.55614,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 13,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP6b",
        farmId: "6b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.44306,
        centreLng: -0.5587,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 14,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP7",
        farmId: "7",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.42115,
        centreLng: -0.5837,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
    {
        id: 23,
        subClusterId: 2,
        areaId: 3,
        infectedPremiseId: "IP8b",
        farmId: "8b",
        clusterId: 2,
        epidemicId: 1,
        subCluster: subCluster,
        polygon: "",
        centreLat: 51.45123,
        centreLng: -0.557600645,
        area: 100000,
        parcels: [],
        infectedPremiseDateSet: {
            earliestStartOfInfectiousPeriodDate: '',
            earliestPresumedInfectionDate: '',
            latestEndOfInfectiousPeriodDate: '',
            isEarliestStartOfInfectiousPeriodDateCalculated: false,
            isEarliestPresumedInfectionDateCalculated: false,
            isLatestEndOfInfectiousPeriodDateCalculated: false
        },
        unassigned: false,
        hasSequence: true,
        hasConsensusSequence: false
    },
];

const ip1b = surreyIPs[0];
const ip1c = surreyIPs[1];
const ip2a = surreyIPs[2];
const ip2b = surreyIPs[3];
const ip2c = surreyIPs[4];
const ip3b = surreyIPs[5];
const ip3c = surreyIPs[6];
const ip4b = surreyIPs[7];
const ip5 = surreyIPs[8];
const ip6b = surreyIPs[9];
const ip7 = surreyIPs[10];
const ip8 = surreyIPs[11];

export const networkEdges: INetworkEdgeThatWillEventuallyBeADTO[] = [
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip2c,
        probability: 0.596089758,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip3b,
        probability: 0.037991557,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip4b,
        probability: 0.035769829,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip3c,
        probability: 0.025994224,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip6b,
        probability: 0.017551655,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip7,
        probability: 0.025327705,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip8,
        probability: 0.017107309,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip5,
        probability: 0.143301489,
    },
    {
        infectedPremiseFrom: ip1b,
        infectedPremiseTo: ip2b,
        probability: 0.471450789,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip1b,
        probability: 0.103976894,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip3b,
        probability: 0.016218618,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip4b,
        probability: 0.012663852,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip3c,
        probability: 0.010664297,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip6b,
        probability: 0.007331704,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip7,
        probability: 0.012886025,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip8,
        probability: 0.010219951,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip5,
        probability: 0.029104643,
    },
    {
        infectedPremiseFrom: ip2c,
        infectedPremiseTo: ip2b,
        probability: 0.087758276,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip1b,
        probability: 0.011997334,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip2c,
        probability: 0.005554321,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip4b,
        probability: 0.183070429,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip3c,
        probability: 0.13641413,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip6b,
        probability: 0.48011553,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip7,
        probability: 0.310153299,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip8,
        probability: 0.09508998,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip5,
        probability: 0.022883804,
    },
    {
        infectedPremiseFrom: ip3b,
        infectedPremiseTo: ip2b,
        probability: 0.007331704,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip1b,
        probability: 0.00311042,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip2c,
        probability: 0.000222173,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip3b,
        probability: 0.214841146,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip3c,
        probability: 0.419906687,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip6b,
        probability: 0.058209287,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip7,
        probability: 0.036214175,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip8,
        probability: 0.007331704,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip5,
        probability: 0.034658965,
    },
    {
        infectedPremiseFrom: ip4b,
        infectedPremiseTo: ip2b,
        probability: 0.001333037,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip1b,
        probability: 0.000444346,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip2c,
        probability: 0.001333037,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip3b,
        probability: 0.094201289,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip4b,
        probability: 0.19951122,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip6b,
        probability: 0.047989336,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip7,
        probability: 0.021550766,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip8,
        probability: 0.007109531,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip5,
        probability: 0.028660298,
    },
    {
        infectedPremiseFrom: ip3c,
        infectedPremiseTo: ip2b,
        probability: 0.000666519,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip1b,
        probability: 0.001333037,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip2c,
        probability: 0.001110864,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip3b,
        probability: 0.052877138,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip4b,
        probability: 0.006887358,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip3c,
        probability: 0.007331704,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip7,
        probability: 0.203732504,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip8,
        probability: 0.098644746,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip5,
        probability: 0.003554766,
    },
    {
        infectedPremiseFrom: ip6b,
        infectedPremiseTo: ip2b,
        probability: 0.000444346,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip3b,
        probability: 0.021328594,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip4b,
        probability: 0.00155521,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip3c,
        probability: 0.002221729,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip6b,
        probability: 0.094201289,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip8,
        probability: 0.525660964,
    },
    {
        infectedPremiseFrom: ip7,
        infectedPremiseTo: ip5,
        probability: 0.000888691,
    },
    {
        infectedPremiseFrom: ip8,
        infectedPremiseTo: ip6b,
        probability: 0.00777605,
    },
    {
        infectedPremiseFrom: ip8,
        infectedPremiseTo: ip7,
        probability: 0.141301933,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip1b,
        probability: 0.014441235,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip2c,
        probability: 0.008220395,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip3b,
        probability: 0.230171073,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip4b,
        probability: 0.233948012,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip3c,
        probability: 0.161741835,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip6b,
        probability: 0.06309709,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip7,
        probability: 0.040879804,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip8,
        probability: 0.019106865,
    },
    {
        infectedPremiseFrom: ip5,
        infectedPremiseTo: ip2b,
        probability: 0.037547212,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip1b,
        probability: 0.192179516,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip2c,
        probability: 0.135969784,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip3b,
        probability: 0.021106421,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip4b,
        probability: 0.016885137,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip3c,
        probability: 0.01399689,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip6b,
        probability: 0.010219951,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip7,
        probability: 0.0155521,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip8,
        probability: 0.012219507,
    },
    {
        infectedPremiseFrom: ip2b,
        infectedPremiseTo: ip5,
        probability: 0.22683848,
    },

];