/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an cluster
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActionTypes, createCrudSelectors, createCrudEpics, IBaseCrudState } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialClusterState extends IBaseCrudState {
    data?: SpreadDtos.Cluster;
}

export interface IClusterState {
    cluster: IInitialClusterState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@cluster';

export const clusterTypes = {
    ...createCrudActionTypes(typeNamespace),
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialClusterState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const clusterReducer = createReducer<IInitialClusterState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const clusterActions = {
    /**
     * Begins the request to create a new cluster.
     * 
     * This cannot update an existing cluster. Use update for that.
     */
    create: createAction(clusterTypes.CREATE,
        (cluster: SpreadDtos.Cluster) => ({ object: cluster })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    createFormResponse: createAction(clusterTypes.CREATE_FORM_RESPONSE,
        (response: SpreadDtos.CreateClusterResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given cluster.
     */
    load: createAction(clusterTypes.LOAD,
        (clusterId: number) => ({ id: clusterId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    loadFormResponse: createAction(clusterTypes.LOAD_FORM_RESPONSE,
        (response: SpreadDtos.GetClusterResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to update an cluster.
     * 
     * This cannot create a new cluster. Use save for that.
     */
    update: createAction(clusterTypes.UPDATE,
        (cluster: SpreadDtos.Cluster) => ({ object: cluster })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    updateFormResponse: createAction(clusterTypes.UPDATE_FORM_RESPONSE,
        (response: SpreadDtos.UpdateClusterResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the form request state.
    */
    clearFormState: createAction(clusterTypes.CLEAR_FORM, () => ({})),
    /**
    * Clears the cluster state.
    */
    clear: createAction(clusterTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(clusterReducer, clusterActions, 'cluster', initialState);

// Clear Reducer
clusterReducer.on(clusterActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
clusterReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const clustersApi = {
    create: createPostRequest(
        SpreadDtos.CreateCluster,
        (cluster: SpreadDtos.Cluster) => ({
            cluster: cluster
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetCluster,
        (clusterId: number) => ({
            id: clusterId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateCluster,
        (cluster: SpreadDtos.Cluster) => ({
            cluster: cluster
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(clusterActions, clustersApi);

export const clusterEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const clusterSelectors = createCrudSelectors('cluster');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default clusterReducer;