/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { match } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { authenticatedPersonActions } from '../../store/reducers/person/authenticatedPerson';
import ButtonLink from '../common/ButtonLink';
import AsyncForm from '../forms/AsyncForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import LabelledInputWrapper from '../forms/FinalFormControls/LabelledInputWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ILoginFormProps {
    match: match;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        progress: {
            margin: theme.spacing(0, 1, 0, 0)
        },
    }),
);

const validation = Yup.object().shape({
    email: Yup.string().email('must be a valid email').required(),
    password: Yup.string().required()
});

const LoginForm: React.FunctionComponent<ILoginFormProps> = ({ match }) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = React.useState(false);

    const url = match.url == "/" ? "" : match.url;

    return <AsyncForm
        initialValues={undefined}
        validate={validation}
        start={authenticatedPersonActions.login}
        resolve={authenticatedPersonActions.loginFailure}
        reject={authenticatedPersonActions.loginFailure}
        formSubscription={{ submitting: true, pristine: true, submitError: true, dirtySinceLastSubmit: true, hasValidationErrors: true, }}
    >{({ dirtySinceLastSubmit, hasValidationErrors, pristine, submitError, submitting, }) => (
        <Grid container alignItems="flex-start" spacing={2} >
            <Grid item xs={12} lg={6}>
                <Field
                    fullWidth
                    name="email"
                    component={TextFieldWrapper}
                    type="text"
                    label="Email"
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Field
                    fullWidth
                    name="password"
                    component={LabelledInputWrapper}
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="Toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justify="space-between">
                    <ButtonLink
                        color="inherit"
                        to={`${url.endsWith('login') ? url : url + '/login'}/resetPasswordRequest`}
                    >
                        Forgot password?
                    </ButtonLink>
                    <Grid item direction="row" justify="flex-end">
                        
                        {
                            submitting ? <CircularProgress className={classes.progress} />
                                : <>
                                <FormControlLabel
                                    control={<Field
                                        name="rememberMe"
                                        component={CheckboxWrapper}
                                        type="checkbox"
                                        value="rememberMe"
                                    />}
                                    label="Remember me"
                                />
                                <Button
                                    className={classes.buttonSubmit}
                                    color="primary"
                                    disabled={pristine || hasValidationErrors || submitting || (submitError !== undefined && !dirtySinceLastSubmit)}
                                    type="submit"
                                    variant="contained"
                                >
                                    Login
                                </Button>
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {submitError
                && <Grid item xs={12}>
                    <Typography color={'error'} variant="body1" component="p">{submitError}</Typography>
                </Grid>
            }
        </Grid >
    )}
    </AsyncForm>
};

export default LoginForm;