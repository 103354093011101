/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading a group.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';

import { catchError, mergeMap } from 'rxjs/operators';
import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestState } from '../../../types/RequestState';
import { IRequestState } from '../../../types/IRequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialGroupInviteState {
    data?: SpreadDtos.PersonGroupMemberInvite[];
    acceptInviteState: IRequestState;
    createMemberInviteState: IRequestState;
    deleteInviteState: IRequestState;
    loadInvitesState: IRequestState;
}

export interface IGroupInviteState {
    groupInvite: IInitialGroupInviteState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@groupInvite';

export const GroupInviteTypes = {
    ACCEPT_INVITE: `${typeNamespace}/ACCEPT_INVITE`,
    ACCEPT_INVITE_SUCCESS: `${typeNamespace}/ACCEPT_INVITE_SUCCESS`,
    ACCEPT_INVITE_FAILURE: `${typeNamespace}/ACCEPT_INVITE_FAILURE`,
    CREATE_MEMBER_INVITE: `${typeNamespace}/CREATE_MEMBER_INVITE`,
    CREATE_MEMBER_INVITE_SUCCESS: `${typeNamespace}/CREATE_MEMBER_INVITE_SUCCESS`,
    CREATE_MEMBER_INVITE_FAILURE: `${typeNamespace}/CREATE_MEMBER_INVITE_FAILURE`,
    DELETE_INVITE: `${typeNamespace}/DELETE_INVITE`,
    DELETE_INVITE_SUCCESS: `${typeNamespace}/DELETE_INVITE_SUCCESS`,
    DELETE_INVITE_FAILURE: `${typeNamespace}/DELETE_INVITE_FAILURE`,
    LOAD_INVITES: `${typeNamespace}/LOAD`,
    LOAD_INVITES_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_INVITES_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    CLEAR_CREATE_INVITE: `${typeNamespace}/CLEAR_CREATE_INVITE`,
    CLEAR: `${typeNamespace}/CLEAR`,
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialGroupInviteState = {
    data: undefined,
    acceptInviteState: {
        state: RequestState.None
    },
    createMemberInviteState: {
        state: RequestState.None
    },
    deleteInviteState: {
        state: RequestState.None
    },
    loadInvitesState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const groupInviteReducer = createReducer<IInitialGroupInviteState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const groupInviteActions = {
    /**
     * This action begins the request to accept a group member invite
     */
    acceptInvite: createAction(
        GroupInviteTypes.ACCEPT_INVITE,
        (inviteId: number) => ({ inviteId })
    ),
    /**
     * This action stores the group in the group person state after a 
     * successful invite acceptance
     */
    acceptInviteSuccess: createAction(
        GroupInviteTypes.ACCEPT_INVITE_SUCCESS,
        (response: SpreadDtos.AcceptGroupMemberInviteResponse) => ({
            response
        })
    ),
    /**
     * This action stores the error after a failed invite
     * acceptance request
     */
    acceptInviteFailure: createAction(
        GroupInviteTypes.ACCEPT_INVITE_FAILURE,
        (response: SpreadDtos.AcceptGroupMemberInviteResponse) => ({
            response
        })
    ),
    /**
     * Begins the request to create a a new invite to a person
     * for a particular group.
     */
    createMemberInvite: createAction(
        GroupInviteTypes.CREATE_MEMBER_INVITE,
        (groupId: number, email: string) => ({ groupId, email })
    ),
    /**
     * Stores the response from a successful create member
     * invite request
     */
    createMemberInviteSuccess: createAction(
        GroupInviteTypes.CREATE_MEMBER_INVITE_SUCCESS,
        (response: SpreadDtos.CreateGroupResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed create member invite
     */
    createMemberInviteFailure: createAction(
        GroupInviteTypes.CREATE_MEMBER_INVITE_FAILURE,
        (response: SpreadDtos.CreateGroupResponse) => ({
            response
        })
    ),
    /**
     * This action begins the request to delete a group member invite
     */
    deleteInvite: createAction(
        GroupInviteTypes.DELETE_INVITE,
        (inviteId: number) => ({ inviteId })
    ),
    /**
     * This action stores the group in the group person state after a 
     * successful invite deletion
     */
    deleteInviteSuccess: createAction(
        GroupInviteTypes.DELETE_INVITE_SUCCESS,
        (response: SpreadDtos.DeleteGroupMemberInviteResponse) => ({
            response
        })
    ),
    /**
     * This action stores the error after a failed invite
     * delete request
     */
    deleteInviteFailure: createAction(
        GroupInviteTypes.DELETE_INVITE_FAILURE,
        (response: SpreadDtos.DeleteGroupMemberInviteResponse) => ({
            response
        })
    ),
    /**
     * This action begins the request to load a given group's invites.
     */
    loadInvites: createAction(
        GroupInviteTypes.LOAD_INVITES,
        (groupId: number) => ({ groupId })
    ),
    /**
     * This action stores the group's invites in the state after a 
     * successful load request
     */
    loadInvitesSuccess: createAction(
        GroupInviteTypes.LOAD_INVITES_SUCCESS,
        (response: SpreadDtos.GetGroupMemberInviteResponse) => ({ response })
    ),
    /**
     * This action stores the error in the group invite state after a 
     * failed load request
     */
    loadInvitesFailure: createAction(
        GroupInviteTypes.LOAD_INVITES_FAILURE,
        (response: SpreadDtos.GetGroupMemberInviteResponse) => ({
            response
        })
    ),
    /**
    * This action clears the invite group state.
    */
    clearCreateInvite: createAction(GroupInviteTypes.CLEAR_CREATE_INVITE, () => ({})),
    /**
    * This action clears the group state.
    */
    clear: createAction(GroupInviteTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Accept invite Reducers
groupInviteReducer.on(groupInviteActions.acceptInvite, (state) => (
    update(
        state,
        {
            acceptInviteState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.acceptInviteSuccess, (state, payload) => (
    update(
        state,
        {
            acceptInviteState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.acceptInviteFailure, (state, payload) => (
    update(
        state,
        {
            acceptInviteState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Create member invite Reducers
groupInviteReducer.on(groupInviteActions.createMemberInvite, (state) => (
    update(
        state,
        {
            createMemberInviteState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.createMemberInviteSuccess, (state, payload) => (
    update(
        state,
        {
            createMemberInviteState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.createMemberInviteFailure, (state, payload) => (
    update(
        state,
        {
            createMemberInviteState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Delete invite Reducers
groupInviteReducer.on(groupInviteActions.deleteInvite, (state) => (
    update(
        state,
        {
            deleteInviteState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.deleteInviteSuccess, (state, payload) => (
    update(
        state,
        {
            deleteInviteState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.deleteInviteFailure, (state, payload) => (
    update(
        state,
        {
            deleteInviteState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Load Reducers
groupInviteReducer.on(groupInviteActions.loadInvites, (state) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadInvitesState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.loadInvitesSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.memberInvites
            },
            loadInvitesState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

groupInviteReducer.on(groupInviteActions.loadInvitesFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadInvitesState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Create Invite Reducer
groupInviteReducer.on(groupInviteActions.clearCreateInvite, (state, payload: any) => (
    update(
        state,
        {
            createMemberInviteState: {
                $set: {
                    state: RequestState.None
                }
            }
        }
    )
));

// Clear Reducer
groupInviteReducer.on(groupInviteActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
groupInviteReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const groupInviteApi = {
    acceptInvite: createPutRequest(
        SpreadDtos.AcceptGroupMemberInvite,
        (inviteId: number) => ({
            inviteId: inviteId
        })
    ),
    createMemberInvite: createPostRequest(
        SpreadDtos.CreateGroupMemberInvite,
        (groupId: number, email: string) => ({
            groupId: groupId,
            personEmail: email
        })
    ),
    deleteInvite: createDeleteRequest(
        SpreadDtos.DeleteGroupMemberInvite,
        (inviteId: number) => ({
            inviteId: inviteId
        })
    ),
    loadMemberInvites: createGetRequest(
        SpreadDtos.GetGroupMemberInvite,
        (groupId: number) => ({
            groupId: groupId
        })
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const acceptInviteEpic: Epic<ReturnType<typeof groupInviteActions.acceptInvite>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(groupInviteActions.acceptInvite.getType()),
    mergeMap(action =>
        groupInviteApi.acceptInvite(action.payload.inviteId)
            .pipe(
                mergeMap(response =>
                    of(
                        groupInviteActions.acceptInviteSuccess(response),
                        // refresh profile so it's got this newest group
                        authenticatedPersonActions.load()
                    )
                ),
                catchError(e => of(groupInviteActions.acceptInviteFailure(e)))
            )
    ));

export const createMemberInviteEpic: Epic<ReturnType<typeof groupInviteActions.createMemberInvite>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(groupInviteActions.createMemberInvite.getType()),
    mergeMap(action =>
        groupInviteApi.createMemberInvite(action.payload.groupId, action.payload.email)
            .pipe(
                mergeMap(response =>
                    of(
                        groupInviteActions.createMemberInviteSuccess(response)
                    )
                ),
                catchError(e => of(groupInviteActions.createMemberInviteFailure(e)))
            )
    ));

export const deleteInviteEpic: Epic<ReturnType<typeof groupInviteActions.deleteInvite>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(groupInviteActions.deleteInvite.getType()),
    mergeMap(action =>
        groupInviteApi.deleteInvite(action.payload.inviteId)
            .pipe(
                mergeMap(response =>
                    of(
                        groupInviteActions.deleteInviteSuccess(response),
                        groupInviteActions.loadInvites(response.deletedInvitePersonGroupId)
                    )
                ),
                catchError(e => of(groupInviteActions.deleteInviteFailure(e)))
            )
    ));

export const loadInvitesEpic: Epic<ReturnType<typeof groupInviteActions.loadInvites>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(groupInviteActions.loadInvites.getType()),
    mergeMap(action =>
        groupInviteApi.loadMemberInvites(action.payload.groupId)
            .pipe(
                mergeMap(response =>
                    of(
                        groupInviteActions.loadInvitesSuccess(response)
                    )
                ),
                catchError(e => of(groupInviteActions.loadInvitesFailure(e)))
            )
    ));

export const groupInviteEpics = combineEpics(acceptInviteEpic, createMemberInviteEpic, deleteInviteEpic, loadInvitesEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default groupInviteReducer;