/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { setIn, FORM_ERROR } from 'final-form';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../dtos/Spread.dtos';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

/**
 * This method maps a response redux action into a form that Final-Form expects.
 * 
 * Assumes that the response contains ServiceStack FluentValidation errors which
 * will be returned in structure that Final-Form can understand.
 * 
 * Final-Form [FORM_ERROR] will be populated by the top level error in the Service
 * Stack response status.
 * 
 * @param responseStatus The API response action with validation errors to map.
 * @returns An error object in the following structure: 
 *  { 
 *      [FORM_ERROR]: 'topLevelErrorString', 
 *      fieldName: 'errorString', 
 *      fieldName2: 'errorString2' 
 *  }
 */
export function mapFormPayload(responseStatus: SpreadDtos.ResponseStatus) {
    const errors = mapErrorsFromResponse(responseStatus);

    return errors;
}

const CustomErrorKey = 'FINAL_FORM/form-error';

function mapErrorsFromResponse(responseStatus: SpreadDtos.ResponseStatus) {
    if (!responseStatus || !responseStatus.errorCode)
        return null;

    // This will mostly just be hit by 
    // • 500 errors as they do not populate errors
    // • Regular exceptions in service code
    if(!responseStatus.errors || responseStatus.errors.length === 0 || responseStatus.stackTrace) {
        return {
            [FORM_ERROR]: responseStatus.message ? responseStatus.message : 'Unhandled Error'
        }
    }

    const fieldErrors = responseStatus.errors.filter(e => e.errorCode !== CustomErrorKey);
    const customError = responseStatus.errors.find(e => e.errorCode === CustomErrorKey);

    // Map ServiceStack FluentValidation errors back into a form Final-Form expects.
    // The fieldname casing must match exactly
    const errorObj = fieldErrors.reduce(
        (accum, currentError) => setIn(accum,
            currentError.fieldName,
            currentError.message),
        {});

    var formErrorMessage: string | undefined = undefined;

    if(customError){
        formErrorMessage = customError.message;
    }
    // To stop repeating error messages needlessly, show the main response status
    // message only if there are no field level errors
    else if(fieldErrors.length === 0) {
        formErrorMessage = responseStatus.message;
    }

    const finalErrorObj = {
        ...errorObj,
        [FORM_ERROR]: formErrorMessage && formErrorMessage
    }

    return finalErrorObj;
}
