/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { routeBackToPath } from '../../helpers/routeHelpers';
import ButtonLink from '../common/ButtonLink';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IPasswordResetConfirmProps {
    history: History;
    match: match;
}

const PasswordResetConfirm: React.FunctionComponent<IPasswordResetConfirmProps> = ({ history, match }) => {
    return <>
        <Typography variant="h4" component="h4">Password Reset</Typography>
        <Typography variant="h5" component="h5">Your password has successfully been reset. Please log in with your new password.</Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-center">
            <ButtonLink
                color="primary"
                to={routeBackToPath(match.url)}
            >
                Login
            </ButtonLink>
        </Box>
    </>;
}

export default PasswordResetConfirm;