/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useCallback } from 'react';
import MakeAsyncFunction from 'react-redux-promise-listener';
import { Form, FormProps, FormRenderProps } from 'react-final-form';
import { Action } from 'redux-act';
import { Config, FormSubscription, setIn } from 'final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import createAction from '../../helpers/createAction';
import { mapFormPayload } from '../../helpers/mapFormPayload';
import { promiseListener } from '../../store/store';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAsyncFormProps<ValidatorType = Yup.Schema<any>, FormType = Yup.InferType<ValidatorType>> {
    children: (props: FormRenderProps<FormType>) => React.ReactNode;

    /**
     * The initial form data to load into the fields.
     */
    initialValues?: FormType;

    mutators?: Config<any>['mutators'];
    /**
     * The actions that are dispatched when submitting the form.
     */
    // the type of action to dispatch when this function is called
    start: ReturnType<typeof createAction>;
    // the type of action that will resolve the promise
    resolve: ReturnType<typeof createAction>;
    // the type of action that will reject the promise
    reject: ReturnType<typeof createAction>;

    /**
     * Configuration for when the underlying final-form should rerender.
     */
    formSubscription?: FormProps<FormType>['subscription'];

    validate?: ValidatorType; //Yup.Schema<FormType>;
    validateContext?: object;
    validateFn?: IFormValidationCreator<FormType>;
}

interface IFormValidationCreator<FormType> {
    (values: FormType): Yup.Schema<FormType>
}

const formValidatorCreator = async <ValueType, FormType>(
    validationCreator: IFormValidationCreator<FormType>,
    validateValues: FormType,
    validateContext?: object,
) => {
    const validator = validationCreator(validateValues);

    return await formValidator(validator, validateValues, validateContext);
};

export const DEFAULT_FORM_SUBSCRIPTION: FormSubscription = {
};

const formValidator = async <FormType extends {}>(
    validator: Yup.Schema<FormType>,
    validateValues: FormType,
    validateContext?: object,
) => {
    try {
        await validator.validate(validateValues, { abortEarly: false, context: validateContext })

        return;
    }
    catch (err) {
        const typedError: Yup.ValidationError = err;

        const errors = typedError.inner.reduce((formError, innerError) => {
            if (!formError[innerError.path]) {
                return setIn(formError, innerError.path, innerError.message);
            }

            return {};
        }, {});

        return errors;
    }
}

function AsyncForm<ValidatorType, FormType>({ children, formSubscription, initialValues, mutators, validate, validateContext, validateFn, start, resolve, reject }: IAsyncFormProps<ValidatorType, FormType>) {
    const validator = useCallback(async (value) => {
        if (validateFn) {
            return await formValidatorCreator(validateFn, value, validateContext);
        }

        if (validate) {
            const foo = validate as unknown as Yup.Schema<any>;

            return await formValidator(foo, value, validateContext);
        }

        return undefined;
    }, [validate, validateContext, validateFn])

    return <MakeAsyncFunction
        listener={promiseListener}
        start={start.getType()}     // the type of action to dispatch when this function is called
        resolve={resolve.getType()} // the type of action that will resolve the promise
        reject={reject.getType()}   // the type of action that will reject the promise
        getPayload={(action: Action<any>) => {
            return mapFormPayload(action.payload.response.responseStatus)
        }}
        setPayload={(action: Action<any>, payload: any) => {

            //payload = { epidemic: payload };

            return (
                {
                    ...action,
                    payload
                }
            );
        }}
    /*getError={(action: Action<any>) => {
        const foo = mapFormPayload(action.payload.response.responseStatus)

        return foo;
    }}*/
    >{asyncFunc => (<Form
        initialValues={initialValues}
        onSubmit={asyncFunc}
        subscription={formSubscription}
        validate={validator}
        mutators={mutators}
        render={(formState) => (
            <form onSubmit={formState.handleSubmit}>
                {children(formState)}
            </form>
        )}
    />)}
    </MakeAsyncFunction>
};

export default AsyncForm;