/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import * as React from 'react';
import NavigationPrompt from "react-router-navigation-prompt";

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface INavigationBlockerDialogProps {
    block: boolean;
    labelDescription?: string;
    labelCancel?: string;
    labelConfirm?: string;
}

const NavigationBlockerDialog: React.FunctionComponent<INavigationBlockerDialogProps> = ({
    block,
    labelDescription,
    labelCancel,
    labelConfirm
}) => {
    return <NavigationPrompt
        renderIfNotActive={true}
        when={(currentLoc, nextLoc) => ((!nextLoc
            || !nextLoc.pathname.startsWith(currentLoc.pathname))
            && block)
        }
    >
        {({ isActive, onCancel, onConfirm }) => {
            return <Dialog
                open={isActive}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {labelDescription ? labelDescription : 'You have unsaved changes. Are you sure you want to discard them?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>
                        {labelCancel ? labelCancel : 'No'}

                    </Button>
                    <Button onClick={onConfirm} color="primary" autoFocus>
                        {labelConfirm ? labelConfirm : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        }}
    </NavigationPrompt>
};

export default NavigationBlockerDialog;