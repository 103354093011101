/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from './useTypedSelector';
import { groupInviteActions } from '../store/reducers/groups/groupInvite';
import { IRequestState } from '../types/IRequestState';
import { PersonGroupMemberInvite } from '../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function usePersonGroupInvitesLoad(groupId?: number): [PersonGroupMemberInvite[] | undefined, IRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.groupInvite.data);
    const requestState = useSelector(state => state.groupInvite.loadInvitesState);

    useEffect(() => {
        if(groupId){
            dispatch(groupInviteActions.loadInvites(groupId))
        }

        return function cleanup() {
            dispatch(groupInviteActions.clear())
        }
    }, [dispatch, groupId]);

    return [
        data,
        requestState
    ];
}

export function useAcceptPersonGroupInvite(): [ (inviteId: number) => void, IRequestState ] {
    const dispatch = useDispatch();
    const requestState = useSelector(state => state.groupInvite.acceptInviteState);

    const acceptDispatch = useCallback((inviteId: number) => {
        dispatch(
            groupInviteActions.acceptInvite(inviteId)
        )
    }, [dispatch]);

    return [
        acceptDispatch,
        requestState
    ];
}

export function useDeletePersonGroupInvite(): [(inviteId: number) => void, IRequestState] {
    const dispatch = useDispatch();
    const requestState = useSelector(state => state.groupInvite.deleteInviteState);

    const deleteDispatch = useCallback((inviteId: number) => {
        dispatch(
            groupInviteActions.deleteInvite(inviteId)
        )
    }, [dispatch]);

    return [
        deleteDispatch,
        requestState
    ];
}

