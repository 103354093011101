/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for executing an epidemic spatial import
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IImportDatesFormSchema } from '../../../components/bulkUpload/ImportDatesForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialImportDatesState {
    importState: IFormRequestState;
    importResponse?: SpreadDtos.ImportDatesResponse;
}

export interface IImportDatesState {
    importDates: IInitialImportDatesState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@importDates';

export const ImportDatesTypes = {
    IMPORT: `${typeNamespace}/IMPORT`,
    IMPORT_FORM_RESPONSE: `${typeNamespace}/IMPORT_FORM_RESPONSE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialImportDatesState = {
    importResponse: undefined,
    importState: {
        state: RequestFormState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const importDatesReducer = createReducer<IInitialImportDatesState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const importDatesActions = {
    /**
     * Begins the request to execute a new livestock import.
     */
    import: createAction(ImportDatesTypes.IMPORT,
        (importDates: IImportDatesFormSchema) => ({ importDates })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    importFormResponse: createAction(ImportDatesTypes.IMPORT_FORM_RESPONSE,
        (response: SpreadDtos.ImportDatesResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the importDates state.
    */
    clear: createAction(ImportDatesTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Create new Reducers
importDatesReducer.on(importDatesActions.import, (state) => (
    update(
        state,
        {
            importState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

importDatesReducer.on(importDatesActions.importFormResponse, (state, payload) => (
    update(
        state,
        {
            importResponse: {
                $set: payload.response
            },
            importState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
importDatesReducer.on(importDatesActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
importDatesReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const importDatessApi = {
    import: createPostRequest(
        SpreadDtos.ImportDates,
        (importDates: IImportDatesFormSchema) => ({
            dateTimeFormat: importDates.dateTimeFormat,
            epidemicId: importDates.epidemicId,
            temporaryFileToken: importDates.files[0].tempFileGuid,
            mappedHeadersDictionary: importDates.fieldMapping,
            importIpKey: SpreadDtos.ImportIpKey.FarmId
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const importEpic: Epic<ReturnType<typeof importDatesActions.import>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(importDatesActions.import.getType()),
    mergeMap(action => {
        return importDatessApi.import(action.payload.importDates).pipe(
            mergeMap(response =>
                of(
                    importDatesActions.importFormResponse(response, RequestFormState.SubmitSuccess)
                )
            ),
            catchError(e => of(importDatesActions.importFormResponse(e, RequestFormState.ServerError)))
        )
    })
);

export const importDatesEpics = combineEpics(importEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default importDatesReducer;