/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import LivestockEditForm from './LivestockEditForm';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateLivestockParams { }

interface ICreateLivestockProps {
    history: History;
    match: match<ICreateLivestockParams>;
}

export const CreateLivestock: React.FunctionComponent<ICreateLivestockProps> = (props) => {
    const classes = useStyles(props);

    const epidemic = useSelector(state => state.epidemic.data);
    
    return <Paper className={classes.root}>
        <LivestockEditForm
            epidemicId={epidemic && epidemic.id}
            history={props.history}
            url={props.match.url}
        />
    </Paper>
}