/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple sub-clusters.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSubClusterSearchState = ISearchState<SpreadDtos.QuerySubCluster>;

export interface ISubClusterSearchState {
    subClusterSearch: IInitialSubClusterSearchState;
}

export const initialState: ISubClusterSearchState = {
    subClusterSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const subClustersModule = createAutoQueryModule(
    'subClusterSearch',
    initialSearchState,
    '@@subClusterSearch',
    SpreadDtos.QuerySubClusters,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        clusterId: options.specialOptions.clusterId,
        epidemicId: options.specialOptions.epidemicId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const subClusterSearchActions = subClustersModule.actions;
export const subClusterSearchEpics = subClustersModule.epics;
export const subClustersApi = subClustersModule.api;
export const subClustersSelectors = subClustersModule.selectors;
export const subClustersHooks = subClustersModule.hooks;
export default subClustersModule.reducer;