/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { areaDateActions } from '../../store/reducers/areaDates/areaDate';
import { RequestFormState } from '../../types/RequestState';
import AreaDateEditForm from './AreaDateEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditAreaDateParams {
    areaDateId: string;
    clusterId?: string;
    epidemicId?: string;
    fieldId?: string;
    infectedPremiseId?: string;
    parcelId?: string;
}

interface IEditAreaDateProps {
    areaDateId?: number;
    history: History;
    match: match<IEditAreaDateParams>;
}

export const EditAreaDate: React.FunctionComponent<IEditAreaDateProps> = (props) => {
    const areaDateState = useSelector(state => state.areaDate);
    const dispatch = useDispatch();
    const classes = useStyles();

    const areaDateId = props.areaDateId || (props.match.params ? parseInt(props.match.params.areaDateId) : undefined);

    useEffect(() => {
        dispatch(areaDateActions.load(areaDateId))

        return function cleanup() {
            dispatch(areaDateActions.clear())
        }
    }, [areaDateId, dispatch]);

    return <Paper className={classes.root}>
        <AreaDateEditForm
            editAreaDate={areaDateState.data}
            history={props.history}
            loadingAreaDate={areaDateState.states.loadState.state === RequestFormState.Pending && !areaDateState.data}
            url={props.match.url}
        />
    </Paper>
}