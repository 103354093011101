/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import IconButton from '@material-ui/core/IconButton/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DownloadIcon from '@material-ui/icons/GetApp';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../constants/Dates';
import { QueryResponse, QuerySequenceView } from '../../dtos/Spread.dtos';
import { IPaginateQueryOptions } from '../../store/reducers/common/autoQuery';
import sequenceModule from '../../store/reducers/sequences/sequence';
import { sequenceViewSearchActions } from '../../store/reducers/sequences/sequenceViewSearch';
import { SortableTable } from '../table/SortableTable';
import { ITableHeadSortableColumn } from '../table/TableHeadSortable';
import IconButtonLink from '../common/IconLink';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
    }),
);

interface ISequenceViewListProps {
    className?: string;
    data: QueryResponse<QuerySequenceView>;
    history: History;
    link?: boolean;
    paginateOptions: IPaginateQueryOptions;
    url: string;
}

export const SequenceViewList: React.FunctionComponent<ISequenceViewListProps> = ({ className, data, history, link, paginateOptions, url }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const linkedHeaderColumns: ITableHeadSortableColumn[] = [
        { id: 'clusterName', numeric: false, disablePadding: false, label: 'Cluster' },
        { id: 'infectedPremiseInfectedPremiseId', numeric: false, disablePadding: false, label: 'IP' }
    ];

    const commonHeaderColumns: ITableHeadSortableColumn[] = [
        { id: 'laboratory', numeric: false, disablePadding: false, label: 'Sequencing Lab' },
        { id: 'isolateName', numeric: false, disablePadding: false, label: 'Isolate Name' },
        { id: 'origin', numeric: false, disablePadding: false, label: 'Origin' },
        { id: 'spreadSequenceId', numeric: false, disablePadding: false, label: 'Sequence ID' },
        { id: 'collectionDate', numeric: false, disablePadding: false, label: 'Collection Date' },
        { id: 'sequencingStatus', numeric: false, disablePadding: false, label: 'Sequencing Status' },
        { id: 'assemblyQAStatus', numeric: false, disablePadding: false, label: 'NGS QA' },
        { id: 'annotationQAStatus', numeric: false, disablePadding: false, label: 'Consensus QA' },
        { id: 'downloadGBK', numeric: false, disablePadding: false, disableSorting: true, label: 'GBK' },
    ];

    const headerColumns: ITableHeadSortableColumn[] = link ? commonHeaderColumns : linkedHeaderColumns.concat(commonHeaderColumns);

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={sequenceViewSearchActions.updatePaginateOptions}
        >
            {
                data.results.map(((item, i) =>
                    <TableRow
                        key={`sequenceViewRow-${i}`}
                    >
                        {!link && <>
                            <TableCell component="th" scope="row">
                                {item.clusterName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.infectedPremiseInfectedPremiseId}
                            </TableCell>
                        </>
                        }
                        <TableCell component="th" scope="row">
                            {item.laboratory}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/specimen/${item.specimenId}`}>
                                {item.isolateName || '(not provided)'}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.origin}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.sequencingStatus == "Assembled" ?
                                <Link component={RouterLink} to={`${url}/specimen/${item.specimenId}/sequence/${item.sequenceId}/consensusSequence/${item.consensusSequenceId}/gbk`}>
                                    {item.spreadSequenceId || '(not provided)'}
                                </Link> :
                                item.spreadSequenceId || '(not provided)'
                            }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.collectionDate ?
                                `${format(toDate(item.collectionDate), DateTimeFormat)}${(item.isCollectionDateEstimated ? '*' : '')}` :
                                'Not provided'}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.sequencingStatus}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.sequencingStatus == "Assembled" ?
                                <Link component={RouterLink} to={`${url}/specimen/${item.specimenId}/sequence/${item.sequenceId}/consensusSequence/${item.consensusSequenceId}/qa`}>
                                    {item.assemblyQAStatus}
                                </Link> :
                                ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.sequencingStatus == "Assembled" ?
                                <Link component={RouterLink} to={`${url}/specimen/${item.specimenId}/sequence/${item.sequenceId}/consensusSequence/${item.consensusSequenceId}/annotationqa`}>
                                    {item.annotationQAStatus}
                                </Link> :
                                ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.consensusSequenceId ?
                                <IconButtonLink edge="start" color="primary" aria-label="Download GBK" to={`/file/sequence/gbk/${item.consensusSequenceId}`} download target="_blank">
                                    <DownloadIcon />
                                </IconButtonLink> :
                                undefined}
                        </TableCell>
                    </TableRow>
                ))
            }
        </SortableTable>
    </>
}