/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';

import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import PublicIcon from '@material-ui/icons/Public';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestState } from '../../../types/RequestState';
import { SortableTable } from '../../table/SortableTable';
import { DateTimeFormat } from '../../../constants/Dates';
import IconButtonLink from '../../common/IconLink';
import analysisTapmDataSetModule from '../../../store/reducers/analysis/analysisTapmDataSetSearch';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        analysisRow: {
        }
    }),
);

const headerColumns = [
    { id: 'id', numeric: false, disablePadding: false, label: 'SPREAD Database Id' },
    { id: 'dataSetId', numeric: false, disablePadding: false, label: 'DataSetId' },
    { id: 'dataSetName', numeric: false, disablePadding: false, label: 'DataSetName' },
    { id: 'minDate', numeric: false, disablePadding: false, label: 'Min Date' },
    { id: 'maxDate', numeric: false, disablePadding: false, label: 'Max Date' },
    { id: 'createdDate', numeric: false, disablePadding: false, label: 'Synced Into SPREAD Date' },
];

interface ITapmDataSetListProps {
    className?: string;
    url: string;
}

export const TapmDataSetList: React.FunctionComponent<ITapmDataSetListProps> = ({ className, url }) => {
    const classes = useStyles();

    const ipPageOps = useMemo(() => ({ take: 1000, skip: 0, orderBy: 'dataSetId', orderByDes: undefined }), []);
    const ipSpecOps = useMemo(() => ({}), []);
    const [data, paginateOptions, ,requestState] = analysisTapmDataSetModule.hooks.useSearch(ipSpecOps, ipPageOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={analysisTapmDataSetModule.actions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.analysisRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            {item.id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.dataSetId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.dataSetName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.minDate), DateTimeFormat)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.maxDate), DateTimeFormat)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.createdDate), DateTimeFormat)}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}