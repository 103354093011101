/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple material.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialMaterialSearchState = ISearchState<SpreadDtos.Material>;

export interface IMaterialSearchState {
    materialSearch: IInitialMaterialSearchState;
}

export const initialState: IMaterialSearchState = {
    materialSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const materialsModule = createAutoQueryModule(
    'materialSearch',
    initialSearchState,
    '@@materialSearch',
    SpreadDtos.QueryMaterials,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const materialSearchActions = materialsModule.actions;
export const materialSearchEpics = materialsModule.epics;
export const materialSearchApi = materialsModule.api;
export const materialSearchSelectors = materialsModule.selectors;
export const materialSearchHooks = materialsModule.hooks;
export default materialsModule.reducer;