
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
 
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ResponsiveMapControl from './responsiveMapControl/ResponsiveMapControl';
import { useSelector } from '../../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useLoaderStyles = makeStyles((theme: Theme) =>
createStyles({
    root: {
        paddingTop: theme.spacing(1.5),
    },
    text: {
        paddingTop: theme.spacing(1)
    }
}),
);

interface IDownloadIndicatorProps {
    loading: boolean;
}

export const DownloadIndicator: React.FunctionComponent<IDownloadIndicatorProps> = ({ loading }) => {
const classes = useLoaderStyles();

return loading ? <ResponsiveMapControl position='bottomleft'>
        <Box className={classes.root} alignItems="center" display="flex" flexDirection="column" component="div">
            <CircularProgress />
            <Typography className={classes.text} component="span">Downloading analysis results</Typography>
        </Box>
    </ResponsiveMapControl> : null;
}