/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple parcels.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';
import { Parcel, QueryParcels } from '../../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialParcelSearchState = ISearchState<Parcel>;

export interface IParcelSearchState {
    parcelSearch: IInitialParcelSearchState;
}

export const initialState: IParcelSearchState = {
    parcelSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const parcelSearchModule = createAutoQueryModule(
    'parcelSearch',
    initialSearchState,
    '@@parcelSearch', 
    QueryParcels, 
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) =>({
        clusterId: options.specialOptions.clusterId,
        epidemicId: options.specialOptions.epidemicId,
        infectedPremiseId: options.specialOptions.infectedPremiseId
    })
);


/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const parcelSearchActions = parcelSearchModule.actions;
export const parcelSearchEpics = parcelSearchModule.epics;
export const parcelSearchApi = parcelSearchModule.api;
export const parcelSearchSelectors = parcelSearchModule.selectors;
export const parcelSearchHooks = parcelSearchModule.hooks;
export default parcelSearchModule.reducer;