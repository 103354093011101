/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from './useTypedSelector';
import { groupInviteActions } from '../store/reducers/groups/groupInvite';
import { IRequestState } from '../types/IRequestState';
import { PersonGroupMemberInvite, ImportLog } from '../dtos/Spread.dtos';
import { importLogActions } from '../store/reducers/bulkUpload/importLog';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useImportLogLoad(importLogId?: number): [ImportLog | undefined, IRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.importLog.data);
    const requestState = useSelector(state => state.importLog.loadState);

    useEffect(() => {
        if(importLogId){
            dispatch(importLogActions.load(importLogId))
        }

        return function cleanup() {
            dispatch(importLogActions.clear())
        }
    }, [dispatch, importLogId]);

    return [
        data,
        requestState
    ];
}