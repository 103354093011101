/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { History } from 'history';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { useSelector } from '../../hooks/useTypedSelector';
import { areaDateTypeActions } from '../../store/reducers/areaDateTypes/areaDateType';
import { RequestFormState } from '../../types/RequestState';
import { HasPermission } from '../common/HasPermission';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAreaDateTypeParams {
    areaDateTypeId: string;
    epidemicId: string;
}

interface IViewAreaDateTypeProps {
    areaDateTypeId?: number;
    history: History;
    match: match<IViewAreaDateTypeParams>;
}

export const ViewAreaDateType: React.FunctionComponent<IViewAreaDateTypeProps> = (props) => {
    const classes = useStyles();
    const areaDateTypeState = useSelector(state => state.areaDateType);
    const dispatch = useDispatch();

    const areaDateTypeId = props.areaDateTypeId || (props.match.params ? parseInt(props.match.params.areaDateTypeId) : undefined);
    const epidemicId = props.match.params ? parseInt(props.match.params.epidemicId) : undefined;

    useEffect(() => {
        dispatch(areaDateTypeActions.load(areaDateTypeId))

        return function cleanup() {
            dispatch(areaDateTypeActions.clear());
        }
    }, [areaDateTypeId, dispatch]);

    const handleEdit = useCallback(
        () => props.history.push(`${props.match.url}/edit`),
        [props.history, props.match.url],
    );

    return <>
        <Paper className={classes.root}>
            {
                !areaDateTypeState.data || areaDateTypeState.states.loadState.state === RequestFormState.Pending
                    ? <CircularProgress />
                    : <>
                        <Typography variant="h4" component="h4">{areaDateTypeState.data.name}</Typography>
                        <Typography variant="h5" component="h5">{areaDateTypeState.data.createdDate}</Typography>
                        {
                            areaDateTypeState.data.isStartOfInfectiousPeriodDate ?
                                <Typography variant="h5" component="h5">Start of Infectious Period Date</Typography> :
                                undefined
                        }
                        {
                            areaDateTypeState.data.isEndOfInfectiousPeriodDate ?
                                <Typography variant="h5" component="h5">End of Infectious Period Date</Typography> :
                                undefined
                        }
                        <Typography variant="h5" component="h5">Offset Days: {
                            areaDateTypeState.data.isStartOfInfectiousPeriodDate ?
                                'N/A' :
                                areaDateTypeState.data.offsetDays
                        }</Typography>
                        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                            <HasPermission permission="UpdateAreaDateType" objectId={epidemicId}>
                                <Button
                                    aria-label="Edit Date"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleEdit}
                                >
                                    <EditIcon className={classes.buttonSubmitIcon} />
                                    Edit
                                </Button>
                            </HasPermission>
                        </Box>
                    </>
            }
        </Paper>
    </>
}