/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import { ButtonTypeMap } from '@material-ui/core/Button';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

/**
 * This helper wraps a Material-Ui <Button /> component to work with a <a />
 * 
 * @returns The button wrapped with an <a> tag
 */
const ButtonExternalLink: React.FunctionComponent<ButtonTypeMap<JSX.IntrinsicElements['a'], 'button'>['props']> = (props) => {

    const { children, href, ...rest } = props;

    const renderLink = forwardRef((itemProps: any, ref: any) => (
        <a href={href} target="_blank" rel="noopener" {...itemProps}/>
    ));

    return (
        <Button component={renderLink} {...rest}>
            {children}
        </Button>
    );
}

export default ButtonExternalLink