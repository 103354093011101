/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import PublicIcon from '@material-ui/icons/Public';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../constants/Dates';
import { SpreadWorkflowJobStatus } from '../../dtos/Spread.dtos';
import { spreadWorkflowJobStatusDescription } from '../../helpers/enumDescription';
import { useSelector } from '../../hooks/useTypedSelector';
import analysissModule from '../../store/reducers/analysis/analysisSearch';
import { analysisTapmActions } from '../../store/reducers/analysis/analysisTapm';
import { RequestState } from '../../types/RequestState';
import IconButtonLink from '../common/IconLink';
import { SortableTable } from '../table/SortableTable';
import { ITableHeadSortableColumn } from '../table/TableHeadSortable';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        analysisRow: {
        },
        buttonDeleteIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IAnalysisListProps {
    className?: string;
    clusterId?: number;
    epidemicId: number;
    url: string;
    nameFilter?: string;
}

export const AnalysisList: React.FunctionComponent<IAnalysisListProps> = ({
    className,
    clusterId,
    epidemicId,
    url,
    nameFilter
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const deleteRequestState = useSelector(state => state.analysisTapm.deleteState);
    const cancelRequestState = useSelector(state => state.analysisTapm.extraStates.cancelState);
    const person = useSelector(state => state.authenticatedPerson.data);

    const headerColumns: ITableHeadSortableColumn[] = [
        { id: '', numeric: false, disablePadding: true, label: '' },
        { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'limitRunStartDate', numeric: false, disablePadding: false, label: 'Date From' },
        { id: 'limitRunEndDate', numeric: false, disablePadding: false, label: 'Date To' },
        { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Requested At' },
        { id: 'download', numeric: false, disablePadding: true, disableSorting: true, label: '' },
    ];

    const pageOps = useMemo(() => ({ skip: 0, take: 10, orderBy: undefined, orderByDesc: 'Id' }), []);
    const specOps = useMemo(() => ({
        epidemicId: epidemicId,
        clusterId: clusterId,
        nameContains: nameFilter
    }), [clusterId, epidemicId, nameFilter, deleteRequestState.state, cancelRequestState.state]);

    const [data, paginateOptions, , requestState] = analysissModule.hooks.useSearch(specOps, pageOps);

    // Delete stuff

    const hasDeletePerm = person ? person.permissions.find(p => p === 'DeleteAnalysisJob') !== undefined : false;

    if (hasDeletePerm) {
        headerColumns.push({ id: 'delete', numeric: false, disablePadding: true, disableSorting: true, label: '' });
    }

    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteConfirmOpen(true);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            dispatch(analysisTapmActions.delete(idToDelete));
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [idToDelete, setIdToDelete, setDeleteConfirmOpen],
    );

    // Download stuff

    const handleDownloadClick = (apiJobId: number) => {
        dispatch(analysisTapmActions.loadTapmResultFile(apiJobId));
    }

    // Cancel stuff

    const handleCancelClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            dispatch(analysisTapmActions.cancel(parseInt(event.currentTarget.value)));
        },
        [],
    );

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={analysissModule.actions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item =>
                        <TableRow
                            className={classes.analysisRow}
                            key={item.id}>
                            <TableCell component="th" scope="row">
                                {
                                    item.resultParsed ?
                                        <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${item.epidemicId}/results/map?analysisId=${item.id}`}>
                                            <PublicIcon />
                                        </IconButtonLink> :
                                        null
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`${url}/${item.id}`}>
                                    {item.id}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {spreadWorkflowJobStatusDescription(item.status)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.limitRunStartDate && format(toDate(item.limitRunStartDate), DateTimeFormat)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.limitRunEndDate && format(toDate(item.limitRunEndDate), DateTimeFormat)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {format(toDate(item.requestedDate), DateTimeFormat)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.status == SpreadWorkflowJobStatus.ResultsDownloaded ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessed ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessing ?
                                        <IconButton
                                            aria-label="Download"
                                            color="primary"

                                            onClick={() => handleDownloadClick(item.id)}
                                        >
                                            <DownloadIcon />
                                        </IconButton> :
                                        ""
                                }
                            </TableCell>
                            {
                                <TableCell component="th" scope="row">
                                    {
                                        item.status == SpreadWorkflowJobStatus.Created ||
                                            item.status == SpreadWorkflowJobStatus.Initing ||
                                            item.status == SpreadWorkflowJobStatus.Inited ||
                                            item.status == SpreadWorkflowJobStatus.Running ?
                                            <Button
                                                aria-label="Cancel Job"
                                                color="primary"
                                                value={item.id}

                                                onClick={handleCancelClick}
                                            >
                                                <CancelIcon className={classes.buttonDeleteIcon} />
                                            </Button> :
                                            hasDeletePerm &&
                                                (item.status == SpreadWorkflowJobStatus.Cancelled ||
                                                    item.status == SpreadWorkflowJobStatus.Failed ||
                                                    item.status == SpreadWorkflowJobStatus.CreateError ||
                                                    item.status == SpreadWorkflowJobStatus.InitError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsDownloadError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                                    item.status == SpreadWorkflowJobStatus.ResultsProcessed) ?
                                                <Button
                                                    aria-label="Delete Job"
                                                    color="primary"
                                                    value={item.id}

                                                    onClick={handleDeleteClick}
                                                >
                                                    <DeleteIcon className={classes.buttonDeleteIcon} />
                                                </Button> :
                                                undefined
                                    }
                                </TableCell>
                            }
                        </TableRow>
                    ))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this job? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}