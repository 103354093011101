/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import logo from '../../assets/images/spread-logo.png';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoContainer: {
            height: '75%'
        },
        logoLink: {
            height: '100%'
        },
        logo: {
            height: '100%'
        },
        titleLink: {
            "&:hover": {
                textDecoration: 'none'
            }
        },
        title: {
            color: (props: IContentLogoProps): string => props.color === 'primary' ? theme.palette.primary.main : 'white',
            marginBottom: '8px',
            marginLeft: '-40px',
            fontWeight: 'bold'
        }
    }),
);

interface IContentLogoProps {
    color: 'white' | 'primary'
}

export const ContentLogo: React.FunctionComponent<IContentLogoProps> = (props) => {
    const classes = useStyles(props);


    return <Box className={classes.logoContainer} display="flex" flexDirection="row" alignItems="flex-end" >
        <RouterLink className={classes.logoLink} to={``}>
            <img className={classes.logo} src={logo} alt="Spread Logo" />
        </RouterLink>
        <Link className={classes.titleLink} component={RouterLink} color={'inherit'} to={``} variant="h5">
            <Typography className={classes.title} variant="h4" component="h1">
                SPREAD
        </Typography>
        </Link>
    </Box>
}