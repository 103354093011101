/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as classNames from 'classnames';
import { format } from 'date-fns';
import { History } from 'history';
import { parse as parseQueryString, stringify } from 'query-string';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { toDate } from '@servicestack/client';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import analysissModule from '../../../store/reducers/analysis/analysisSearch';
import { RequestState } from '../../../types/RequestState';
import { SortableTable } from '../../table/SortableTable';
import IconButtonLink from '../../common/IconLink';

import { ITableHeadSortableColumn } from '../../table/TableHeadSortable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns: ITableHeadSortableColumn[] = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Analysis Id' },
    { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Requested' },
    { id: '', numeric: false, disablePadding: false, label: '', disableSorting: true }
];

interface IMapAnalysisListProps {
    className?: string;
    epidemicId?: number;
    subClusterId?: number;
    history: History;
    url: string;
}

const defaultTake = 5;

export const MapAnalysisList: React.FunctionComponent<IMapAnalysisListProps> = ({ className, epidemicId, subClusterId, history, url }) => {
    const classes = useStyles();

    const pageOps = React.useMemo(() => ({ skip: 0, take: defaultTake, orderBy: undefined, orderByDesc: 'Id' }), []);
    const specOps = React.useMemo(() => ({ epidemicId: epidemicId, subClusterId: subClusterId }), [epidemicId, subClusterId])

    const [data, paginateOptions, , requestState] = analysissModule.hooks.useSearch(specOps, pageOps);

    const params = parseQueryString(history.location.search);
    const paramsWithoutAnalysisId = { ...params };
    if (paramsWithoutAnalysisId) {
        paramsWithoutAnalysisId.analysisId = undefined;
    }

    const existingMapParams = stringify(paramsWithoutAnalysisId);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        rowsPerPageOptions={[defaultTake, 8]}
        updatePaginateOptions={analysissModule.actions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id}
                    >
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`/epidemic/${item.epidemicId}/results/map?analysisId=${item.id}&${existingMapParams}`}>
                                {item.id}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.requestedDate), DateTimeFormat)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${item.epidemicId}/analysis/${item.id}`}>
                                <EditIcon />
                            </IconButtonLink>
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}