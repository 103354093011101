/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { ExcretionCreateJobForm } from './ExcretionCreateJobForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateExcretionJobParams {
    epidemicId: string;
}

interface ICreateExcretionJobProps {
    epidemicId?: number;
    history: History;
    match: match<ICreateExcretionJobParams>;
}

export const CreateExcretionJob: React.FunctionComponent<ICreateExcretionJobProps> = ({ history, match, }) => {
    const classes = useStyles();

    const epidemic = useSelector(state => state.epidemic.data);

    return <Paper className={classes.root}>
        <ExcretionCreateJobForm
            match={match}
            history={history}
        />
    </Paper>
}