/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { ClusterList } from './ClusterList';




/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewClustersParams {
    epidemicId: string;
}

interface IViewClustersProps {
    className?: string;
    epidemicId?: number;
    history: History;
    match: match<IViewClustersParams>;
}

export const ViewClusters: React.FunctionComponent<IViewClustersProps> = (props) => {
    const classes = useStyles();

    const url = props.match.url + (!props.match.url.endsWith('/cluster') ? '/cluster' : '');
    const epidemicId = props.epidemicId || (props.match.params ? parseInt(props.match.params.epidemicId) : undefined);

    return <Paper className={classNames(props.className, classes.root)}>
        <ClusterList
            showTitle
            className={classes.list}
            epidemicId={epidemicId}
            history={props.history}
            url={url}
        />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateCluster" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Create Cluster"
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}