/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { useSelector } from '../../hooks/useTypedSelector';
import { areaDateTypeActions } from '../../store/reducers/areaDateTypes/areaDateType';
import { areaDateTypeSearchActions, areaDateTypeSearchHooks } from '../../store/reducers/areaDateTypes/areaDateTypeSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IAreaDateTypeListProps {
    className?: string;
    editMode?: boolean;
    epidemicId?: number;
    history: History;
    url: string;
}

export const AreaDateTypeList: React.FunctionComponent<IAreaDateTypeListProps> = ({ className, editMode, epidemicId, history, url }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const deleteRequestState = useSelector(state => state.areaDateType.states.deleteState);

    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId, deleteRequestState]);
    const pagOps = useMemo(() => ({ orderBy: "offsetDays", skip: 0, take: 100 }), []);
    const [data, paginateOptions, , requestState] = areaDateTypeSearchHooks.useSearch(specOps, pagOps);

    const startDateType = data && data.results.find(t => t.isStartOfInfectiousPeriodDate);

    const headerColumns = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Date Event' },
        { id: '-isPresumedInfectionDate,-isStartOfInfectiousPeriodDate,-isEndOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: 'Event Type' },
        { id: 'offsetDays', numeric: false, disablePadding: false, label: `Default Offset From ${startDateType ? startDateType.name : 'Start of Infectious Period'}` },
        { id: 'actualOffsetDays', numeric: false, disablePadding: false, label: `Average Actual Offset From ${startDateType ? startDateType.name : 'Start of Infectious Period'}` },
        { id: '', numeric: false, disablePadding: false, label: 'Delete', disableSorting: true },
    ];

    const startDate = data && data.results ? data.results.find(d => d.isStartOfInfectiousPeriodDate) : undefined;
    const startOffset = startDate ? startDate.offsetDays : undefined;

    // Delete stuff

    const [isDeleteOptionsOpen, setDeleteOptionsOpen] = React.useState(false);
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);
    const [deleteType, setDeleteType] = React.useState(false);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteOptionsOpen(true);
        },
        [setDeleteOptionsOpen, setIdToDelete],
    );

    const handleDeleteOptionAndClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setDeleteType(true);
            setDeleteConfirmOpen(true);
        },
        [setDeleteOptionsOpen, setIdToDelete],
    );

    const handleDeleteOptionOnlyClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setDeleteType(false);
            setDeleteConfirmOpen(true);
        },
        [setDeleteOptionsOpen, setIdToDelete],
    );

    const handleDeleteOptionsClose = React.useCallback(
        () => {
            setIdToDelete(undefined);
            setDeleteOptionsOpen(false);
        },
        [setDeleteOptionsOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setDeleteType(false);
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen, setDeleteType],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            dispatch(areaDateTypeActions.delete(idToDelete, deleteType));
            setDeleteType(false);
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
            setDeleteOptionsOpen(false);
        },
        [idToDelete, deleteType],
    );

    const dateTypeToDelete = idToDelete && data && data.results ? data.results.find(t => t.id == idToDelete) : undefined;

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={areaDateTypeSearchActions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item => {
                        let offset = startOffset ? item.offsetDays - startOffset : item.offsetDays;
                        let actualOffset = startOffset ? item.actualOffsetDays - startOffset : item.actualOffsetDays;
                        return <TableRow
                            className={classes.clickableRow}
                            key={item.id}>
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`${url}/${item.id}/edit`}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.isPresumedInfectionDate ? 'Presumed Infection Date' : item.isStartOfInfectiousPeriodDate ? "Start of Infectious Period" : item.isEndOfInfectiousPeriodDate ? "End of Infectious Period" : ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.isStartOfInfectiousPeriodDate ? "N/A" :
                                        offset < -1 ? `${offset * -1} days before` :
                                            offset > 1 ? `${offset} days after` :
                                                offset == -1 ? `${offset * -1} day before` :
                                                    offset == 1 ? `${offset} day after` :
                                                        'On the same day'
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.isStartOfInfectiousPeriodDate || (actualOffset === undefined) ? "N/A" :
                                        actualOffset < -1 ? `${actualOffset * -1} days before` :
                                            actualOffset > 1 ? `${actualOffset} days after` :
                                                actualOffset == -1 ? `${actualOffset * -1} day before` :
                                                    actualOffset == 1 ? `${actualOffset} day after` :
                                                        'On the same day'
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    !item.isStartOfInfectiousPeriodDate && !item.isEndOfInfectiousPeriodDate && !item.isPresumedInfectionDate ?
                                        <Button
                                            aria-label="Delete Date"
                                            color="primary"
                                            value={item.id}

                                            onClick={handleDeleteClick}
                                        >
                                            <DeleteIcon className={classes.buttonSubmitIcon} />
                                        </Button> :
                                        undefined
                                }
                            </TableCell>
                        </TableRow>
                    }))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <Dialog
            open={isDeleteOptionsOpen}
            onClose={handleDeleteOptionsClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Options</DialogTitle>
            <DialogContent>
                Would you like to delete the type along with the dates associated with it, or only the associated dates?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteOptionsClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteOptionAndClick} color="secondary">
                    Dates AND Type
                </Button>
                <Button onClick={handleDeleteOptionOnlyClick} color="secondary">
                    ONLY Dates
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete {deleteType ? `"${dateTypeToDelete ? dateTypeToDelete.name : '[ERROR]'}" and all associated dates` : `all dates associated with "${dateTypeToDelete ? dateTypeToDelete.name : '[ERROR]'}"`}? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}