/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { lighten, Theme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import AllOutIcon from '@material-ui/icons/AllOut';
import BuildIcon from '@material-ui/icons/Build';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import PeopleIcon from '@material-ui/icons/People';
import PublicIcon from '@material-ui/icons/Public';
import BarChartIcon from '@material-ui/icons/BarChart';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from '@material-ui/icons/Share';
import TabIcon from '@material-ui/icons/Tab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import logo from '../../assets/images/spread-logo.png';
import { DrawerWidthPx, HeaderHeightPx } from '../../constants/Layout';
import { useSelector } from '../../hooks/useTypedSelector';
import { ListItemLink, ListItemNavLink } from '../common/ListItemLink';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: DrawerWidthPx,
                flexShrink: 0,
            }
        },
        drawerButton: {
            border: '1px solid'
        },
        drawerItem: {
            "&:hover": {
                background: lighten(theme.palette.primary.dark, 0.15)
            },
            paddingLeft: theme.spacing(3)
        },
        drawerItemActive: {
            color: lighten(theme.palette.primary.main, 0.4)
        },
        drawerItemNoLink: {
            padding: theme.spacing(0)
        },
        drawerIconLink: {
            width: 'auto',
            //padding: theme.spacing(2),
            "&:hover": {
                background: lighten(theme.palette.primary.dark, 0.15)
            },
        },
        drawerCollapseSubListIcon: {
            border: '1px solid',
            borderRadius: '100%'
        },
        drawerSubItem: {
            "&:hover": {
                background: lighten(theme.palette.primary.dark, 0.15)
            },
            paddingLeft: theme.spacing(3),
            paddingTop: 0,
            paddingBottom: 0
        },
        drawerSubItemActive: {
            color: lighten(theme.palette.primary.main, 0.4)
        },
        drawerItemButton: {

        },
        drawerList: {
            paddingTop: 0,
        },
        drawerSubList: {
            paddingBottom: theme.spacing(0),
            paddingTop: 0,
            paddingLeft: theme.spacing(4)
        },
        drawerPaper: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            //backgroundColor: theme.palette.primary.main,
            width: DrawerWidthPx,
        },
        drawerLoading: {
            marginBottom: theme.spacing(2)
        }

    })
);

interface INavItem {
    displayRoute?: string;
    label: string;
    icon?: React.ReactElement;
    to: string;
}

interface INavProps {
    drawerOpen: boolean;
    title?: string;
    toggleDrawerOpen: any;
}

export const Nav: React.FunctionComponent<INavProps> = (props) => {
    const classes = useStyles(props);
    const adminPermissions: string[] = [
        'CreateCountry', 'UpdateCountry', 'DeleteCountry',
        'CreateLivestock', 'UpdateLivestock', 'DeleteLivestock',
    ];

    const epidemic = useSelector(state => state.epidemic.data);
    const group = useSelector(state => state.group.data);
    const authenticatedPerson = useSelector(state => state.authenticatedPerson.data);

    let admin = false;

    if (authenticatedPerson && authenticatedPerson.permissions) {
        authenticatedPerson.permissions.forEach(p => {
            if (adminPermissions.indexOf(p) !== -1) {
                admin = true;
            }
        });
    }

    const items = {
        task: {
            label: 'Tasks',
            icon: <DashboardIcon />,
            to: '/task'
        },
        epidemic: {
            label: 'Epidemics',
            icon: <TabIcon />,
            to: '/epidemic'
        },
        people: {
            label: 'People',
            icon: <PeopleIcon />,
            to: '/person'
        },
        group: {
            label: 'Groups',
            icon: <GroupWorkIcon />,
            to: '/group'
        },
        admin: admin ? {
            label: 'Admin',
            icon: <SettingsIcon />,
            to: '/admin'
        } : undefined,
    };

    const taskDrawer = (epidemicMatch?: string) => (
        <ListItem className={classes.drawerItemNoLink}>
            <ListItemLink className={classes.drawerIconLink} to={`/task`}>
                <ChevronLeftIcon className={classes.drawerCollapseSubListIcon} />
            </ListItemLink>

            <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerIconLink} to={`/task/select`}>
                <ListItemText primary={'Select Epidemic'} />
            </ListItemNavLink>
        </ListItem>
    );

    const epidemicDrawer = (epidemicMatch?: string) => {
        const epidemicCreateRoute = epidemicMatch === 'create';

        return <>
            <ListItem className={classes.drawerItemNoLink}>
                <ListItemLink className={classes.drawerIconLink} to={`/epidemic`}>
                    <ChevronLeftIcon className={classes.drawerCollapseSubListIcon} />
                </ListItemLink>

                <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerIconLink} exact to={`/epidemic/${epidemicMatch}`}>
                    <ListItemText primary={epidemic ? epidemic.name : epidemicCreateRoute ? 'New Epidemic' : 'Epidemic'} />
                </ListItemNavLink>
            </ListItem>
            {
                epidemicCreateRoute
                    ? undefined
                    : epidemic ? <List className={classes.drawerSubList}>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}`}>
                            <ListItemIcon><DashboardIcon /></ListItemIcon>
                            <ListItemText primary={'Tasks'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}/data`}>
                            <ListItemIcon><TabIcon /></ListItemIcon>
                            <ListItemText primary={'Data'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}/results/map`}>
                            <ListItemIcon><PublicIcon /></ListItemIcon>
                            <ListItemText primary={'Map'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}/results/graph`}>
                            <ListItemIcon><BarChartIcon /></ListItemIcon>
                            <ListItemText primary={'Graph'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} to={`/epidemic/${epidemic.id}/excretion`}>
                            {<ListItemIcon><AllOutIcon /></ListItemIcon>}
                            <ListItemText primary={'Excretion'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} to={`/epidemic/${epidemic.id}/analysis`}>
                            {<ListItemIcon><LeakAddIcon /></ListItemIcon>}
                            <ListItemText primary={'Wind Dispersion'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} to={`/epidemic/${epidemic.id}/bioinformatics`}>
                            {<ListItemIcon><LineStyleIcon /></ListItemIcon>}
                            <ListItemText primary={'Bioinformatics'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} to={`/epidemic/${epidemic.id}/genomicNetwork`}>
                            {<ListItemIcon><ShareIcon /></ListItemIcon>}
                            <ListItemText primary={'Genomic Network'} />
                        </ListItemNavLink>
                        <Divider />
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}/manage`}>
                            {<ListItemIcon><BuildIcon /></ListItemIcon>}
                            <ListItemText primary={'Manage'} />
                        </ListItemNavLink>
                        <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/epidemic/${epidemic.id}/data/edit`}>
                            {<ListItemIcon><BuildIcon /></ListItemIcon>}
                            <ListItemText primary={'Edit'} />
                        </ListItemNavLink>
                    </List>
                        : <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <CircularProgress className={classes.drawerLoading} />
                        </Box>
            }
        </>;
    };

    const groupDrawer = (groupMatch?: string) => (
        <>
            <ListItem className={classes.drawerItemNoLink}>
                <ListItemLink className={classes.drawerIconLink} to={`/group`}>
                    <ChevronLeftIcon className={classes.drawerCollapseSubListIcon} />
                </ListItemLink>

                <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerIconLink} exact to={`/group/${groupMatch}`}>
                    <ListItemText primary={group ? group.displayName : groupMatch === 'create' ? 'New Group' : 'Group'} />
                </ListItemNavLink>
            </ListItem>
            {
                group && <List className={classes.drawerSubList}>
                    <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/group/${group.id}/groupEpidemic`}>
                        {<ListItemIcon><TabIcon /></ListItemIcon>}
                        <ListItemText primary={'Epidemics'} />
                    </ListItemNavLink>
                    <Divider />
                    <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/group/${group.id}/invite`}>
                        {<ListItemIcon><SendIcon /></ListItemIcon>}
                        <ListItemText primary={'Invites'} />
                    </ListItemNavLink>
                    <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/group/${group.id}/role`}>
                        {<ListItemIcon><PeopleIcon /></ListItemIcon>}
                        <ListItemText primary={'Roles'} />
                    </ListItemNavLink>
                    <ListItemNavLink activeClassName={classes.drawerSubItemActive} className={classes.drawerSubItem} exact to={`/group/${group.id}/edit`}>
                        {<ListItemIcon><BuildIcon /></ListItemIcon>}
                        <ListItemText primary={'Edit'} />
                    </ListItemNavLink>
                </List>
            }
        </>
    );

    const drawer = (
        <div>
            <Divider />
            <List className={classes.drawerList}>

                <Switch>
                    <Route path='/task/select'>{
                        routeProps => routeProps.match && taskDrawer(routeProps.match.params.epidemicId)
                    }
                    </Route>
                    <Route>
                        <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={items.task.to}>
                            {items.task.icon && <ListItemIcon>{items.task.icon}</ListItemIcon>}
                            <ListItemText primary={items.task.label} />
                        </ListItemNavLink>
                    </Route>
                </Switch>

                <Divider />

                <Switch>
                    <Route path='/epidemic/:epidemicId'>{
                        routeProps => routeProps.match && epidemicDrawer(routeProps.match.params.epidemicId)
                    }
                    </Route>
                    <Route>
                        <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={items.epidemic.to}>
                            {items.epidemic.icon && <ListItemIcon>{items.epidemic.icon}</ListItemIcon>}
                            <ListItemText primary={items.epidemic.label} />
                        </ListItemNavLink>
                    </Route>
                </Switch>

                <Divider />

                <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={items.people.to}>
                    {items.people.icon && <ListItemIcon>{items.people.icon}</ListItemIcon>}
                    <ListItemText primary={items.people.label} />
                </ListItemNavLink>

                <Divider />

                <Switch>
                    <Route path='/group/:groupId'>{
                        routeProps => routeProps.match && groupDrawer(routeProps.match.params.groupId)
                    }
                    </Route>
                    <Route>
                        <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={items.group.to}>
                            {items.group.icon && <ListItemIcon>{items.group.icon}</ListItemIcon>}
                            <ListItemText primary={items.group.label} />
                        </ListItemNavLink>
                    </Route>
                </Switch>

                <Divider />

                {
                    items.admin ?
                        <>
                            <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={items.admin.to}>
                                {items.admin.icon && <ListItemIcon>{items.admin.icon}</ListItemIcon>}
                                <ListItemText primary={items.admin.label} />
                            </ListItemNavLink>

                            <Divider />
                        </> :
                        undefined
                }
            </List>
        </div>);

    return <nav className={classes.drawer} aria-label="SPREAD Navigation Menu">
        <Hidden mdUp implementation="css">
            <SwipeableDrawer
                variant="temporary"
                anchor={'left'}
                open={props.drawerOpen}
                onClose={props.toggleDrawerOpen}
                onOpen={props.toggleDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <DrawerHeader title={props.title}>
                    <IconButton className={classes.drawerButton} color={'inherit'} onClick={props.toggleDrawerOpen}>
                        {<ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                {drawer}
            </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
            <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
                <DrawerHeader title={props.title} />
                {drawer}
            </Drawer>
        </Hidden>
    </nav>;

}

interface IDrawerHeaderProps {
    title?: string;
}

const DrawerHeader: React.FunctionComponent<IDrawerHeaderProps> = (props) => {
    const classes = useDrawerHeaderStyles(props);

    return <div className={classes.drawerHeader}>
        <RouterLink className={classes.drawerHeaderImageLink} to={``}>
            <img className={classes.drawerHeaderImage} src={logo} alt="Spread Logo" />
        </RouterLink>
        {props.title && <Link className={classes.drawerHeaderTitleLink} component={RouterLink} color={'inherit'} to={``} variant="h5">
            <Typography className={classes.drawerHeaderTitle} variant="h5" component="h1">
                {props.title}
            </Typography>
        </Link>
        }
        <div className={classes.drawerRightHand}>
            {props.children}
        </div>
    </div>

}

const useDrawerHeaderStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: HeaderHeightPx,
            padding: theme.spacing(1, 1.5),
        },
        drawerHeaderImageLink: {
            height: '100%'
        },
        drawerHeaderImage: {
            height: '100%'
        },
        drawerRightHand: {
            marginLeft: 'auto'
        },
        drawerHeaderTitleLink: {
            "&:hover": {
                textDecoration: 'none'
            },
            left: 60,
            position: 'absolute',
            top: 17,
        },
        drawerHeaderTitle: {
            marginLeft: theme.spacing(2),
            fontWeight: 'bold'
        }
    })
);
