/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useReducer } from 'react';
import { History } from 'history';
import * as React from 'react';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import update from 'immutability-helper';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ButtonLink from '../common/ButtonLink';
import { ImportLogDetails } from './ImportLogDetails';
import { ImportLogList } from './ImportLogList';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        formPaper: {
            padding: theme.spacing(3, 2)
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        paper: {
            padding: theme.spacing(3, 2)
        }
    }),
);

interface IImportLogHomeProps {
    className?: string;
    history: History;
    match: match<any>;
}


export const ImportLogHome: React.FunctionComponent<IImportLogHomeProps> = ({ className, history, match }) => {
    const classes = useStyles();

    return <Box className={classes.root} component="div">
        <Switch>
            <Route exact path={`${match.path}`}>

                <Box className={classes.layoutItem}>
                    <Paper className={classes.paper}>
                        <ImportLogList
                            epidemicId={match.params.epidemicId}
                            url={match.url}
                        />
                    </Paper>
                </Box>
            </Route>
            <Route exact path={`${match.path}/:importLogId`}>
                {
                    routeProps =>
                        <Box className={classes.layoutItem}>
                            <ImportLogDetails
                                history={history}
                                importLogId={routeProps.match && routeProps.match.params.importLogId}
                                url={match.url}
                            />
                        </Box>
                }
            </Route>
            <Redirect to={`${match.path}`} />
        </Switch>
    </Box>
}