/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AreaLivestockCount } from '../../dtos/Spread.dtos';
import { useSelector } from '../../hooks/useTypedSelector';
import { infectedPremiseActions } from '../../store/reducers/infectedPremises/infectedPremise';
import AreaLivestockCountEditForm from './AreaLivestockCountEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateAreaLivestockCountParams {
    clusterId: string;
    epidemicId: string;
    fieldId?: string;
    infectedPremiseId: string;
    livestockId: string;
    parcelId?: string;
    subClusterId: string;
}

interface ICreateAreaLivestockCountProps {
    history: History;
    match: match<ICreateAreaLivestockCountParams>;
}

export const CreateAreaLivestockCount: React.FunctionComponent<ICreateAreaLivestockCountProps> = ({
    history,
    match,
}) => {
    const classes = useStyles();
    const infectedPremiseState = useSelector(state => state.infectedPremise);
    const dispatch = useDispatch();

    const params = parseQueryString(history.location.search);

    const areaId = match.params.fieldId ?
        parseInt(match.params.fieldId) :
        match.params.parcelId ?
            parseInt(match.params.parcelId) :
            infectedPremiseState && infectedPremiseState.data ?
                infectedPremiseState.data.areaId :
                undefined;

    const livestockId = match.params.livestockId ? parseInt(match.params.livestockId) : (params ? Number(params.livestockId) : undefined);
    const infectedPremiseId = match.params.infectedPremiseId ? parseInt(match.params.infectedPremiseId) : undefined;

    React.useEffect(() => {
        if (infectedPremiseActions.load) {
            dispatch(infectedPremiseActions.load(infectedPremiseId));
        }

        return function cleanup() {
            dispatch(infectedPremiseActions.clear());
        };
    }, [dispatch, infectedPremiseId]);

    return <Paper className={classes.root}>
        <AreaLivestockCountEditForm
            editAreaLivestockCount={new AreaLivestockCount({
                areaId,
                livestockId,
            })}
            history={history}
            url={match.url}
        />
    </Paper>
}