/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { ViewAreaDateTypes } from '../areaDateType/ViewAreaDateTypes';
import { ViewClusters } from '../cluster/ViewClusters';
import { ViewInfectedPremises } from '../infectedPremise/ViewInfectedPremises';
import { EpidemicDetails } from './EpidemicDetails';
import { ViewLivestocks } from '../livestock/ViewLivestocks';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IViewEpidemicParams {
    clusterId: string;
    epidemicId: string;
    subClusterId: string;
}

interface IEpidemicHomeProps {
    history: History;
    match: match<IViewEpidemicParams>;
}

export const EpidemicHome: React.FunctionComponent<IEpidemicHomeProps> = ({ history, match }) => {
    const classes = useStyles();

    const epidemic = useSelector(state => state.epidemic.data);

    const clusterId = epidemic && epidemic.unassignedClusterId;
    const subClusterId = epidemic && epidemic.unassignedSubClusterId;

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <EpidemicDetails
                epidemic={epidemic}
                history={history}
                url={match.url}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewClusters
                epidemicId={epidemic && epidemic.id}
                history={history}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewInfectedPremises
                showSearch
                history={history}
                match={match}
                epidemicId={epidemic && epidemic.id}
                subClusterId={subClusterId || -1}
                url={`${match.url}/cluster/${clusterId || -1}/subCluster/${subClusterId || -1}`}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaDateTypes
                epidemicId={epidemic && epidemic.id}
                history={history}
                match={match}
            />
        </Box>
        <Box>
            <ViewLivestocks
                epidemicId={epidemic && epidemic.id}
                history={history}
                match={match}
            />
        </Box>
    </Box>
}
