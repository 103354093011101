
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import instrumentModule from '../../store/reducers/instruments/instrument';
import { RequestFormState } from '../../types/RequestState';
import InstrumentEditForm from './InstrumentEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditInstrumentParams {
    instrumentId: string;
}

interface IEditInstrumentProps {
    instrumentId?: number;
    history: History;
    match: match<IEditInstrumentParams>;
}

export const EditInstrument: React.FunctionComponent<IEditInstrumentProps> = (props) => {
    const instrumentState = useSelector(state => state.instrument);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const instrumentId = props.instrumentId || (props.match.params ? parseInt(props.match.params.instrumentId) : undefined);

    useEffect(() => {
        if (instrumentModule.actions.load) {
            dispatch(instrumentModule.actions.load(instrumentId))
        }
    }, [dispatch, instrumentId]);

    return <>
        <Paper className={classes.root}>
            <Typography variant="h4" component="h4">Edit Instrument</Typography>
            <InstrumentEditForm
                editInstrument={instrumentState.data}
                history={props.history}
                loadingInstrument={instrumentState.states.loadState.state === RequestFormState.Pending && !instrumentState.data}
                url={props.match.url}
            />
        </Paper>
    </>
}
