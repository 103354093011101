/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { useCallback } from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { useReprocessTapmResults } from '../../../hooks/useReprocessTapmResults';
import { HasPermission } from '../../common/HasPermission';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomButtons: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewGenomicNetworkJobParams {

}

interface IViewGenomicNetworkJobProps {
    analysisJob?: SpreadDtos.ApiJobGenomicNetwork;
    loading: boolean;
    match: match<IViewGenomicNetworkJobParams>;
}

export const ViewGenomicNetworkJob: React.FunctionComponent<IViewGenomicNetworkJobProps> = ({ analysisJob, match }) => {
    const classes = useStyles();

    //const objectUrl = submitSuccess && image ? URL.createObjectURL(image) : undefined;

    const [reprocessFn, reprocessState] = useReprocessTapmResults(analysisJob && analysisJob.id);

    const handleReprocess = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => reprocessFn(),
        [reprocessFn],
    );

    return <Paper className={classes.root}>
        {/*
        <div>
            <Typography variant="h6" component="span">Status: </Typography><Typography variant="body1" component="span">{analysisJob ? analysisJob.status : <Skeleton width={200} />}</Typography>
        </div>
        <div>
            <Typography variant="h6" component="span">Last Checked: </Typography><Typography variant="body1" component="span">{analysisJob ? analysisJob.statusLastChecked ? format(toDate(analysisJob.statusLastChecked), DateTimeHourFormat) : 'Never' : <Skeleton width={200} />}</Typography>
        </div>
        <div>
            <Typography variant="h6" component="span">Progress: </Typography><Typography variant="body1" component="span">{analysisJob ? `${analysisJob.jobPercentage}%` : <Skeleton width={200} />}</Typography>
        </div>
        */}
        {
            //analysisJob && <LinearProgress value={analysisJob.jobPercentage} variant="determinate" />
        }

        <Box display="flex" justifyContent="flex-end">
            {/*analysisJob && analysisJob.status === AnalysisJobStatus.Completed ?
                <ButtonLink
                    className={classes.bottomButtons}
                    color="primary"
                    variant="contained"
                    to={`/epidemic/${analysisJob.epidemicId}/results/map?analysisId=${analysisJob.id}`}
                >
                    View Analysis
                </ButtonLink>
                : null
            */}
            <HasPermission permission="CreateGenomicNetworkJob" objectId={analysisJob?.epidemicId}>
                <Button
                    aria-label="Reprocess Genomic Network Job Results"
                    className={classes.bottomButtons}
                    color="primary"
                    variant="contained"
                    onClick={handleReprocess}
                >
                    Reprocess Results
                </Button>
            </HasPermission>
        </Box>
    </Paper>
}