/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { areaLivestockCountSearchActions, areaLivestockCountsHooks } from '../../store/reducers/areaLivestockCounts/areaLivestockCountSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns = [
    { id: 'livestockName', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'livestockLocationName', numeric: false, disablePadding: false, label: 'Location' },
    { id: 'count', numeric: false, disablePadding: false, label: 'Count' },
    { id: 'infectedCount', numeric: false, disablePadding: false, label: 'Infected Count' },
    { id: 'isIndexSpecies', numeric: false, disablePadding: false, label: 'Index Species' }
];

interface IAreaLivestockCountListProps
{
    areaId?: number;
    className?: string;
    history: History;
    url: string;
}

export const AreaLivestockCountList: React.FunctionComponent<IAreaLivestockCountListProps> = ({ className, areaId, history, url }) =>
{
    const classes = useStyles();

    const specOps = useMemo(() => ({ areaId: areaId }), [areaId]);
    const pagOps = useMemo(() => ({ orderBy: "livestockId", skip: 0, take: 200 }), []);

    const [data, paginateOptions, , requestState] = areaLivestockCountsHooks.useSearch(specOps, pagOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={areaLivestockCountSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map(((item, i) =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id ? `id-${item.id}` : `index-${i}`}
                    >
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={item.id ? `${url}/${item.id}` : `${url}/create?livestockId=${item.livestockId}`}>
                                {item.speciesName}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.livestockLocation ? item.livestockLocation.name : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.infectedCount}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.isIndexSpecies ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}