/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { SubClusterList } from './SubClusterList';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewSubClustersParams {
    clusterId: string;
    epidemicId: string;
}

interface IViewSubClustersProps {
    clusterId?: number;
    history: History;
    match: match<IViewSubClustersParams>;
}

export const ViewSubClusters: React.FunctionComponent<IViewSubClustersProps> = ({
    clusterId,
    history,
    match,
}) => {
    const classes = useStyles();

    const url = match.url + (!match.url.endsWith('/subCluster') ? '/subCluster' : '');
    clusterId = clusterId || (match.params ? parseInt(match.params.clusterId) : undefined);
    const epidemicId = (match.params ? parseInt(match.params.epidemicId) : undefined);

    return <Paper className={classes.root}>
        <SubClusterList
            showTitle
            className={classes.list}
            clusterId={clusterId}
            history={history}
            url={url}
        />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateCluster" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Create Cluster"
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}