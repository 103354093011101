/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an area date type
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActionTypes, createCrudSelectors, createCrudEpics, IBaseCrudState } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialAreaDateTypeState extends IBaseCrudState {
    data?: SpreadDtos.AreaDateType;
}

export interface IAreaDateTypeState {
    areaDateType: IInitialAreaDateTypeState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@areaDateType';

export const areaDateTypeTypes = {
    ...createCrudActionTypes(typeNamespace),
    CLEAR: `${typeNamespace}/CLEAR`,
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAreaDateTypeState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const areaDateTypeReducer = createReducer<IInitialAreaDateTypeState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const areaDateTypeActions = {
    /**
     * Begins the request to create a new area date type.
     * 
     * This cannot update an existing area date type. Use update for that.
     */
    create: createAction(areaDateTypeTypes.CREATE,
        (areaDateType: SpreadDtos.AreaDateType) => ({ object: areaDateType })
    ),
    /**
     * Stores the area date type after a successful create request
     */
    createFormResponse: createAction(areaDateTypeTypes.CREATE_FORM_RESPONSE,
        (response: SpreadDtos.CreateAreaDateTypeResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to delete a given area date type.
     */
    delete: createAction(areaDateTypeTypes.DELETE,
        (areaDateTypeId: number, deleteType: boolean) => ({ id: areaDateTypeId, deleteType })
    ),
    /**
     * Stores the response after a delete request
     */
    deleteFormResponse: createAction(areaDateTypeTypes.DELETE_FORM_RESPONSE,
        (response: SpreadDtos.DeleteAreaDateTypeResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given area date type.
     */
    load: createAction(areaDateTypeTypes.LOAD,
        (areaDateTypeId: number) => ({ id: areaDateTypeId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    loadFormResponse: createAction(areaDateTypeTypes.LOAD_FORM_RESPONSE,
        (response: SpreadDtos.GetAreaDateTypeResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to update an area date type.
     * 
     * This cannot create a new area date type. Use save for that.
     */
    update: createAction(areaDateTypeTypes.UPDATE,
        (areaDateType: SpreadDtos.AreaDateType) => ({ object: areaDateType })
    ),
    /**
     *
     */
    updateFormResponse: createAction(areaDateTypeTypes.UPDATE_FORM_RESPONSE,
        (response: SpreadDtos.UpdateAreaDateTypeResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the form request state.
    */
    clearFormState: createAction(areaDateTypeTypes.CLEAR_FORM, () => ({})),
    /**
    * Clears the areaDateType state.
    */
    clear: createAction(areaDateTypeTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(areaDateTypeReducer, areaDateTypeActions, 'areaDateType', initialState);

// Clear Reducer
areaDateTypeReducer.on(areaDateTypeActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
areaDateTypeReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const areaDateTypesApi = {
    create: createPostRequest(
        SpreadDtos.CreateAreaDateType,
        (areaDateType: SpreadDtos.AreaDateType) => ({
            areaDateType: areaDateType
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetAreaDateType,
        (areaDateTypeId: number) => ({
            id: areaDateTypeId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateAreaDateType,
        (areaDateType: SpreadDtos.AreaDateType) => ({
            areaDateType: areaDateType
        })
    ),
    delete: createDeleteRequest(
        SpreadDtos.DeleteAreaDateType,
        (areaDateTypeId: number, deleteType: boolean) => ({
            areaDateTypeId: areaDateTypeId,
            deleteType: deleteType,
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(areaDateTypeActions, areaDateTypesApi, undefined, undefined, undefined, ['deleteType']);

export const areaDateTypeEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const areaDateTypeSelectors = createCrudSelectors('areaDateType');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default areaDateTypeReducer;