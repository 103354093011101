/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple tissue.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialTissueSearchState = ISearchState<SpreadDtos.Tissue>;

export interface ITissueSearchState {
    tissueSearch: IInitialTissueSearchState;
}

export const initialState: ITissueSearchState = {
    tissueSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const tissuesModule = createAutoQueryModule(
    'tissueSearch',
    initialSearchState,
    '@@tissueSearch',
    SpreadDtos.QueryTissues,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const tissueSearchActions = tissuesModule.actions;
export const tissueSearchEpics = tissuesModule.epics;
export const tissueSearchApi = tissuesModule.api;
export const tissueSearchSelectors = tissuesModule.selectors;
export const tissueSearchHooks = tissuesModule.hooks;
export default tissuesModule.reducer;