/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AssembleSequenceForm } from '../components/genomic/sequence/AssembleSequenceForm';
import { EditSequence } from '../components/genomic/sequence/EditSequence';
import { ConsensusSequencesPage } from '../pages/application/ConsensusSequencesPage';
import sequenceModule from '../store/reducers/sequences/sequence';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISequenceRouterParams {
    sequenceId?: string;
    specimenId?: string;
}

interface ISequenceRouterProps {
    sequenceId?: number;
    match: match<ISequenceRouterParams>;
}

export const SequenceRouter: React.FunctionComponent<ISequenceRouterProps> = ({ sequenceId: sequenceIdProp, match }) => {
    const sequenceId = sequenceIdProp || (match.params.sequenceId ? parseInt(match.params.sequenceId) : -1);

    // Load in sequence so child routes can use
    const sequenceIdMemo = React.useMemo(() => sequenceId, [sequenceId])
    const [, response] = sequenceModule.hooks.useLoad(sequenceIdMemo);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route path={`${match.path}/consensusSequence`} component={ConsensusSequencesPage} />
            <Route exact path={`${match.path}/assemble`} component={AssembleSequenceForm} />
            <Route exact path={`${match.path}`} component={EditSequence} />
        </Switch>
}