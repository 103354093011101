/* Options:
Date: 2022-06-22 13:54:52
Version: 5.70
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5005

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IGet
{
}

export interface IPost
{
}

export interface IPut
{
}

export interface IDelete
{
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class QueryAreaDateType
{
    public id: number;
    public epidemicId: number;
    public name: string;
    public offsetDays: number;
    public isPresumedInfectionDate: boolean;
    public isStartOfInfectiousPeriodDate: boolean;
    public isEndOfInfectiousPeriodDate: boolean;
    public actualOffsetDays: number;

    public constructor(init?: Partial<QueryAreaDateType>) { (Object as any).assign(this, init); }
}

export class QueryArea
{
    public id: number;
    public name: string;
    public parentAreaId: number;
    public startOfInfectiousPeriodDate: string;
    public startOfInfectiousPeriodDateFormatted: string;
    public endOfInfectiousPeriodDate: string;
    public endOfInfectiousPeriodDateFormatted: string;

    public constructor(init?: Partial<QueryArea>) { (Object as any).assign(this, init); }
}

export class DateSet
{
    public earliestPresumedInfectionDate: string;
    public isEarliestPresumedInfectionDateCalculated: boolean;
    public earliestStartOfInfectiousPeriodDate: string;
    public isEarliestStartOfInfectiousPeriodDateCalculated: boolean;
    public latestEndOfInfectiousPeriodDate: string;
    public isLatestEndOfInfectiousPeriodDateCalculated: boolean;

    public constructor(init?: Partial<DateSet>) { (Object as any).assign(this, init); }
}

export class LatLngBounds
{
    public latitudeCornerA: number;
    public longitudeCornerA: number;
    public latitudeCornerB: number;
    public longitudeCornerB: number;

    public constructor(init?: Partial<LatLngBounds>) { (Object as any).assign(this, init); }
}

export class QueryEpidemic
{
    public id: number;
    public name: string;
    public countryName: string;
    public subNationalName: string;
    public year: number;
    public infectedPremiseCount: number;
    public diseaseName: string;

    public constructor(init?: Partial<QueryEpidemic>) { (Object as any).assign(this, init); }
}

export class AreaDateType
{
    public id: number;
    public epidemicId: number;
    public name: string;
    public offsetDays: number;
    public eventTypeName: string;
    public isPresumedInfectionDate: boolean;
    public isStartOfInfectiousPeriodDate: boolean;
    public isEndOfInfectiousPeriodDate: boolean;
    public createdDate: string;
    public epidemic: QueryEpidemic;

    public constructor(init?: Partial<AreaDateType>) { (Object as any).assign(this, init); }
}

export class Epidemic
{
    public id: number;
    public name: string;
    public countryId: number;
    public country: string;
    public subNationalId: number;
    public subNational: string;
    public visibility: number;
    public year: number;
    public diseaseClassificationId: number;
    public diseaseId: number;
    public epidemicDateSet: DateSet;
    public archived: boolean;
    public centreLat: number;
    public centreLng: number;
    public latLngBounds: LatLngBounds;
    public unassignedClusterId: number;
    public unassignedSubClusterId: number;
    public diseaseSubClassificationIds: number[];
    public dateTypes: AreaDateType[];
    public token: string;

    public constructor(init?: Partial<Epidemic>) { (Object as any).assign(this, init); }
}

export class Cluster
{
    public id: number;
    public epidemicId: number;
    public name: string;
    public unassigned: boolean;
    public epidemic: QueryEpidemic;
    public unassignedSubClusterId: number;
    public centreLat: number;
    public centreLng: number;
    public latLngBounds: LatLngBounds;

    public constructor(init?: Partial<Cluster>) { (Object as any).assign(this, init); }
}

export class Field
{
    public id: number;
    public level: number;
    public name: string;
    public parentParcelId: number;
    public infectedPremiseId: number;
    public epidemicId: number;
    public clusterId: number;
    public subClusterId: number;
    public centreLat: number;
    public centreLng: number;
    public area: number;
    public fieldDateSet: DateSet;

    public constructor(init?: Partial<Field>) { (Object as any).assign(this, init); }
}

export class Parcel
{
    public id: number;
    public level: number;
    public name: string;
    public infectedPremiseId: number;
    public epidemicId: number;
    public clusterId: number;
    public centreLat: number;
    public centreLng: number;
    public area: number;
    public parcelDateSet: DateSet;
    public epidemic: Epidemic;
    public cluster: Cluster;
    public infectedPremise: InfectedPremise;
    public fields: Field[];

    public constructor(init?: Partial<Parcel>) { (Object as any).assign(this, init); }
}

export class QueryCluster
{
    public id: number;
    public epidemicId: number;
    public name: string;
    public infectedPremiseCount: number;
    public unassigned: boolean;
    public clusterDateSet: DateSet;

    public constructor(init?: Partial<QueryCluster>) { (Object as any).assign(this, init); }
}

export class SubCluster
{
    public id: number;
    public clusterId: number;
    public name: string;
    public unassigned: boolean;
    public cluster: QueryCluster;

    public constructor(init?: Partial<SubCluster>) { (Object as any).assign(this, init); }
}

export class InfectedPremise
{
    public id: number;
    public subClusterId: number;
    public clusterId: number;
    public epidemicId: number;
    public infectedPremiseId: string;
    public farmId: string;
    public polygon: string;
    public area: number;
    public areaId: number;
    public unassigned: boolean;
    public centreLat: number;
    public centreLng: number;
    public hasSequence: boolean;
    public hasConsensusSequence: boolean;
    public parcels: Parcel[];
    public subCluster: SubCluster;
    public infectedPremiseDateSet: DateSet;

    public constructor(init?: Partial<InfectedPremise>) { (Object as any).assign(this, init); }
}

export class AreaDate
{
    public id: number;
    public areaId: number;
    public areaDateTypeId: number;
    public date: string;
    public name: string;
    public isPresumedInfectionDate: boolean;
    public isStartOfInfectiousPeriodDate: boolean;
    public isEndOfInfectiousPeriodDate: boolean;
    public infectedPremiseId: number;
    public clusterId: number;
    public epidemicId: number;
    public createdDate: string;
    public areaDateType: QueryAreaDateType;
    public area: QueryArea;
    public infectedPremise: InfectedPremise;
    public cluster: QueryCluster;
    public epidemic: QueryEpidemic;

    public constructor(init?: Partial<AreaDate>) { (Object as any).assign(this, init); }
}

export class QueryBase
{
    // @DataMember(Order=1)
    public skip: number;

    // @DataMember(Order=2)
    public take: number;

    // @DataMember(Order=3)
    public orderBy: string;

    // @DataMember(Order=4)
    public orderByDesc: string;

    // @DataMember(Order=5)
    public include: string;

    // @DataMember(Order=6)
    public fields: string;

    // @DataMember(Order=7)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<QueryBase>) { (Object as any).assign(this, init); }
}

export class QueryDb_2<From, Into> extends QueryBase
{

    public constructor(init?: Partial<QueryDb_2<From, Into>>) { super(init); (Object as any).assign(this, init); }
}

export class PathogenDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<PathogenDO>) { (Object as any).assign(this, init); }
}

export class DiseaseDO
{
    public id: number;
    public name: string;
    // @References(Type=typeof(PathogenDO))
    public pathogenId: number;

    public createdDate: string;
    public pathogen: PathogenDO;
    public classifications: DiseaseClassificationDO[];

    public constructor(init?: Partial<DiseaseDO>) { (Object as any).assign(this, init); }
}

export class ClassificationDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<ClassificationDO>) { (Object as any).assign(this, init); }
}

export class SubClassificationDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<SubClassificationDO>) { (Object as any).assign(this, init); }
}

export class DiseaseSubClassificationDO
{
    public id: number;
    // @References(Type=typeof(DiseaseClassificationDO))
    public diseaseClassificationId: number;

    // @References(Type=typeof(SubClassificationDO))
    public subClassificationId: number;

    public value: string;
    public createdDate: string;
    public disease: DiseaseClassificationDO;
    public subClassification: SubClassificationDO;

    public constructor(init?: Partial<DiseaseSubClassificationDO>) { (Object as any).assign(this, init); }
}

export class DiseaseClassificationDO
{
    public id: number;
    // @References(Type=typeof(DiseaseDO))
    public diseaseId: number;

    // @References(Type=typeof(ClassificationDO))
    public classificationId: number;

    public value: string;
    public createdDate: string;
    public disease: DiseaseDO;
    public classification: ClassificationDO;
    public subClassifications: DiseaseSubClassificationDO[];

    public constructor(init?: Partial<DiseaseClassificationDO>) { (Object as any).assign(this, init); }
}

export class CountryDO
{
    public id: number;
    public name: string;
    public sortOrder: number;
    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    public createdDate: string;

    public constructor(init?: Partial<CountryDO>) { (Object as any).assign(this, init); }
}

export class SubNationalDO
{
    public id: number;
    // @References(Type=typeof(CountryDO))
    public countryId: number;

    public name: string;
    public country: CountryDO;
    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    public createdDate: string;

    public constructor(init?: Partial<SubNationalDO>) { (Object as any).assign(this, init); }
}

export class EpidemicDO implements IArchivable
{
    public id: number;
    public name: string;
    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    public visibility: number;
    public year: number;
    // @References(Type=typeof(CountryDO))
    public countryId: number;

    // @References(Type=typeof(SubNationalDO))
    public subNationalId: number;

    // @References(Type=typeof(DiseaseClassificationDO))
    public diseaseClassificationId: number;

    public createdDate: string;
    public modifiedDate: string;
    public archived: boolean;
    public diseaseClassification: DiseaseClassificationDO;
    public country: CountryDO;
    public subNational: SubNationalDO;

    public constructor(init?: Partial<EpidemicDO>) { (Object as any).assign(this, init); }
}

export class ClusterDO implements IBulkSave
{
    public id: number;
    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public name: string;
    public unassigned: boolean;
    public createdDate: string;
    public bulkSaveGuid: string;
    public epidemic: EpidemicDO;

    public constructor(init?: Partial<ClusterDO>) { (Object as any).assign(this, init); }
}

export class SubClusterDO implements IBulkSave
{
    public id: number;
    // @References(Type=typeof(ClusterDO))
    public clusterId: number;

    public name: string;
    public unassigned: boolean;
    public createdDate: string;
    public bulkSaveGuid: string;
    public cluster: ClusterDO;

    public constructor(init?: Partial<SubClusterDO>) { (Object as any).assign(this, init); }
}

export class InfectedPremiseDO implements IBulkSave
{
    public id: number;
    public bulkSaveGuid: string;
    // @References(Type=typeof(SubClusterDO))
    public subClusterId: number;

    public infectedPremiseId: string;
    public farmId: string;
    public createdDate: string;
    public unassigned: boolean;
    // @References(Type=typeof(AreaDO))
    public areaId: number;

    public area: AreaDO;
    public subCluster: SubClusterDO;

    public constructor(init?: Partial<InfectedPremiseDO>) { (Object as any).assign(this, init); }
}

export class AreaDO implements IBulkSave
{
    public id: number;
    public name: string;
    public area: number;
    // @References(Type=typeof(AreaDO))
    public parentAreaId: number;

    public createdDate: string;
    public bulkSaveGuid: string;
    // @Ignore()
    public childAreas: AreaDO[];

    public parentArea: AreaDO;
    public infectedPremise: InfectedPremiseDO;

    public constructor(init?: Partial<AreaDO>) { (Object as any).assign(this, init); }
}

export class AreaView
{
    public id: number;
    public level: number;
    public name: string;
    // @References(Type=typeof(AreaDO))
    public parentAreaId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    // @References(Type=typeof(ClusterDO))
    public clusterId: number;

    // @References(Type=typeof(SubClusterDO))
    public subClusterId: number;

    // @References(Type=typeof(InfectedPremiseDO))
    public infectedPremiseId: number;

    // @References(Type=typeof(AreaDO))
    public rootAreaId: number;

    // @References(Type=typeof(AreaDO))
    public parcelId: number;

    // @References(Type=typeof(AreaDO))
    public fieldId: number;

    public parentArea: AreaDO;
    public epidemic: EpidemicDO;
    public cluster: ClusterDO;
    public infectedPremise: InfectedPremiseDO;
    public rootArea: AreaDO;
    public parcel: AreaDO;
    public field: AreaDO;

    public constructor(init?: Partial<AreaView>) { (Object as any).assign(this, init); }
}

export class AreaDateTypeDO
{
    public id: number;
    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public name: string;
    public offsetDays: number;
    public isPresumedInfectionDate: boolean;
    public isStartOfInfectiousPeriodDate: boolean;
    public isEndOfInfectiousPeriodDate: boolean;
    public createdDate: string;
    public epidemic: EpidemicDO;

    public constructor(init?: Partial<AreaDateTypeDO>) { (Object as any).assign(this, init); }
}

export class AreaDateDO
{
    public id: number;
    // @References(Type=typeof(AreaView))
    public areaId: number;

    // @References(Type=typeof(AreaDateTypeDO))
    public areaDateTypeId: number;

    public date: string;
    public createdDate: string;
    public calculatedRecord: boolean;
    public area: AreaView;
    public areaDateType: AreaDateTypeDO;

    public constructor(init?: Partial<AreaDateDO>) { (Object as any).assign(this, init); }
}

export class QueryAreaDate
{
    public id: number;
    // @Ignore()
    public name: string;

    public date: string;
    public areaDateTypeName: string;
    public calculatedRecord: boolean;
    public createdDate: string;

    public constructor(init?: Partial<QueryAreaDate>) { (Object as any).assign(this, init); }
}

export class LivestockLocation
{
    public id: number;
    public name: string;
    public defaultLivestockLocation: boolean;

    public constructor(init?: Partial<LivestockLocation>) { (Object as any).assign(this, init); }
}

export class AreaLivestockCount
{
    public id: number;
    public areaId: number;
    public speciesName: string;
    public livestockId: number;
    public livestockLocationId: number;
    public count: number;
    public infectedCount: number;
    public isIndexSpecies: boolean;
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<AreaLivestockCount>) { (Object as any).assign(this, init); }
}

export class SpeciesDO
{
    public id: number;
    public name: string;
    public supportsExcretion: boolean;
    public createdDate: string;

    public constructor(init?: Partial<SpeciesDO>) { (Object as any).assign(this, init); }
}

export class LivestockBetaDO
{
    public id: number;
    public createdDate: string;
    // @References(Type=typeof(LivestockDO))
    public sourceLivestockId: number;

    // @References(Type=typeof(LivestockDO))
    public targetLivestockId: number;

    public value: number;
    public sourceLivestock: LivestockDO;
    public targetLivestock: LivestockDO;

    public constructor(init?: Partial<LivestockBetaDO>) { (Object as any).assign(this, init); }
}

export class LivestockLog10Tcid50DO
{
    public id: number;
    public createdDate: string;
    // @References(Type=typeof(LivestockDO))
    public livestockId: number;

    public day: number;
    public value: number;

    public constructor(init?: Partial<LivestockLog10Tcid50DO>) { (Object as any).assign(this, init); }
}

export class LivestockDO
{
    public id: number;
    public epidemicId: number;
    // @References(Type=typeof(SpeciesDO))
    public speciesId: number;

    public createdDate: string;
    public transmissionParameter: string;
    public latentPeriod: number;
    public infectiousPeriod1: number;
    public infectiousPeriod2: number;
    public relativeExcretionInL1: number;
    public relativeExcretionInL2: number;
    public windDispersionThreshold: number;
    public species: SpeciesDO;
    public beta: LivestockBetaDO[];
    public log10Tcid50: LivestockLog10Tcid50DO[];

    public constructor(init?: Partial<LivestockDO>) { (Object as any).assign(this, init); }
}

export class LivestockLocationDO
{
    public id: number;
    public name: string;
    public defaultLivestockLocation: boolean;
    public createdDate: string;

    public constructor(init?: Partial<LivestockLocationDO>) { (Object as any).assign(this, init); }
}

export class AreaLivestockCountView
{
    public id: number;
    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    // @References(Type=typeof(AreaDO))
    public areaId: number;

    // @References(Type=typeof(LivestockDO))
    public livestockId: number;

    // @References(Type=typeof(LivestockLocationDO))
    public livestockLocationId: number;

    public speciesName: string;
    public count: number;
    public infectedCount: number;
    public isIndexSpecies: boolean;
    public epidemic: EpidemicDO;
    public area: AreaDO;
    public livestock: LivestockDO;
    public livestockLocation: LivestockLocationDO;

    public constructor(init?: Partial<AreaLivestockCountView>) { (Object as any).assign(this, init); }
}

export enum AreaLevel
{
    InfectedPremise = 'InfectedPremise',
    Parcel = 'Parcel',
    Field = 'Field',
}

export class Area
{
    public id: number;
    public level: number;
    public name: string;
    public parentAreaId: number;
    public epidemicId: number;
    public clusterId: number;
    public infectedPremiseId: number;
    public rootAreaId: number;
    public parcelId: number;
    public fieldId: number;
    public spatialData: string;
    public epidemic: QueryEpidemic;
    public cluster: QueryCluster;
    public infectedPremise: InfectedPremise;
    public parentArea: QueryArea;
    public rootArea: QueryArea;
    public parcel: QueryArea;
    public field: QueryArea;
    public levelEnum: AreaLevel;

    public constructor(init?: Partial<Area>) { (Object as any).assign(this, init); }
}

export enum ImportType
{
    Hierarchy = 1,
    Spatial = 2,
    Dates = 3,
    Livestock = 4,
    Epidemic = 5,
}

export enum FileExtensionType
{
    csv = 1,
    geojson = 2,
    fastq = 3,
    fasta = 4,
    zip = 5,
    targz = 6,
    json = 7,
    gbk = 8,
    png = 9,
    txt = 10,
    msa = 11,
    gz = 12,
}

export class BulkUploadHeaderColumn
{
    public value: string;
    public label: string;
    public suggestedFoundHeaderValue: string;

    public constructor(init?: Partial<BulkUploadHeaderColumn>) { (Object as any).assign(this, init); }
}

export enum ImportIpKey
{
    FarmId = 1,
    InfectedPremiseId = 2,
}

export class Classification
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Classification>) { (Object as any).assign(this, init); }
}

export class Country
{
    public id: number;
    public name: string;
    public sortOrder: number;
    public createdDate: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class QueryCountry
{
    public id: number;
    public name: string;
    public sortOrder: number;
    public createdDate: string;

    public constructor(init?: Partial<QueryCountry>) { (Object as any).assign(this, init); }
}

export class SubClassification
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<SubClassification>) { (Object as any).assign(this, init); }
}

export class DiseaseSubClassification
{
    public id: number;
    public diseaseClassificationId: number;
    public subClassificationId: number;
    public value: string;
    public subClassification: SubClassification;

    public constructor(init?: Partial<DiseaseSubClassification>) { (Object as any).assign(this, init); }
}

export class DiseaseClassification
{
    public id: number;
    public diseaseId: number;
    public classificationId: number;
    public value: string;
    public createdDate: string;
    public classification: Classification;
    public subClassifications: DiseaseSubClassification[];

    public constructor(init?: Partial<DiseaseClassification>) { (Object as any).assign(this, init); }
}

export class QueryDiseaseClassification
{
    public id: number;
    public diseaseId: number;
    public classificationId: number;
    public value: string;
    public name: string;
    public diseaseName: string;
    public classificationName: string;
    public createdDate: string;

    public constructor(init?: Partial<QueryDiseaseClassification>) { (Object as any).assign(this, init); }
}

export class Pathogen
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Pathogen>) { (Object as any).assign(this, init); }
}

export class Disease
{
    public id: number;
    public name: string;
    public pathogenId: number;
    public createdDate: string;
    public pathogen: Pathogen;
    public classifications: DiseaseClassification[];

    public constructor(init?: Partial<Disease>) { (Object as any).assign(this, init); }
}

export class QueryDisease
{
    public id: number;
    public name: string;
    public pathogenName: string;
    public createdDate: string;

    public constructor(init?: Partial<QueryDisease>) { (Object as any).assign(this, init); }
}

export class QueryDiseaseSubClassification
{
    public id: number;
    public diseaseClassificationId: number;
    public subClassificationId: number;
    public diseaseClassificationDiseaseName: string;
    public diseaseClassificationClassificationName: string;
    public diseaseClassificationValue: string;
    public subClassificationName: string;
    public value: string;
    public name: string;

    public constructor(init?: Partial<QueryDiseaseSubClassification>) { (Object as any).assign(this, init); }
}

export class EnrichmentMethod
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<EnrichmentMethod>) { (Object as any).assign(this, init); }
}

export class EnrichmentMethodDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<EnrichmentMethodDO>) { (Object as any).assign(this, init); }
}

export class PersonGroupEpidemicDO
{
    public id: number;
    // @References(Type=typeof(PersonGroupDO))
    public personGroupId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public createdDate: string;

    public constructor(init?: Partial<PersonGroupEpidemicDO>) { (Object as any).assign(this, init); }
}

export class QueryPersonGroupEpidemic
{
    public id: number;
    public createdDate: string;
    public personGroupId: number;
    public personGroupDisplayName: string;
    public epidemicId: number;
    public epidemicName: string;

    public constructor(init?: Partial<QueryPersonGroupEpidemic>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionLivestockBeta
{
    public name: string;
    public value: number;

    public constructor(init?: Partial<ApiJobExcretionLivestockBeta>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionLivestockLog10Tcid50
{
    public day: number;
    public value: number;

    public constructor(init?: Partial<ApiJobExcretionLivestockLog10Tcid50>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionSpecies
{
    public name: string;
    public beta: ApiJobExcretionLivestockBeta[];
    public lp: number;
    public iP1: number;
    public iP2: number;
    public omega1: number;
    public omega2: number;
    public log10TCID50: ApiJobExcretionLivestockLog10Tcid50[];

    public constructor(init?: Partial<ApiJobExcretionSpecies>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionInfectedPremiseSpecies
{
    public speciesName: string;
    public numberSusceptible: number;
    public numberInfected: number;

    public constructor(init?: Partial<ApiJobExcretionInfectedPremiseSpecies>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionArea
{
    public areaId: number;
    public farmId: string;
    public parcelId: string;
    public fieldId: string;
    public fieldBarnId: string;
    public fieldAreaMetresSquared: number;
    public isIndexField: boolean;
    public presumedInfectionDate: string;
    public startOfSheddingDate: string;
    public endOfSheddingDate: string;
    public oldestLesionAge: number;
    public indexSpecies: string;
    public species: ApiJobExcretionInfectedPremiseSpecies[];

    public constructor(init?: Partial<ApiJobExcretionArea>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionEdge
{
    public farmId1: string;
    public parcelId1: string;
    public fieldId1: string;
    public farmId2: string;
    public parcelId2: string;
    public fieldId2: string;

    public constructor(init?: Partial<ApiJobExcretionEdge>) { (Object as any).assign(this, init); }
}

export enum Model
{
    M1 = 1,
    M2 = 2,
    M3A = 3,
    M3B = 4,
    M4 = 5,
}

export enum SpreadWorkflowJobStatus
{
    Creating = 'Creating',
    Created = 'Created',
    CreateError = 'CreateError',
    Initing = 'Initing',
    Inited = 'Inited',
    InitError = 'InitError',
    Running = 'Running',
    Completed = 'Completed',
    ResultsDownloading = 'ResultsDownloading',
    ResultsDownloadError = 'ResultsDownloadError',
    ResultsDownloaded = 'ResultsDownloaded',
    ResultsProcessing = 'ResultsProcessing',
    ResultsProcessed = 'ResultsProcessed',
    ResultsProcessError = 'ResultsProcessError',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
}

export enum SpreadWorkflowExternalJobStatus
{
    Pending = 'Pending',
    Running = 'Running',
    Completed = 'Completed',
    Failed = 'Failed',
    Expired = 'Expired',
    Cancelled = 'Cancelled',
}

export class ApiJobExcretion
{
    public id: number;
    public apiJobId: number;
    public epidemicId: number;
    public requestedByPersonId: number;
    public name: string;
    public model: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public resultParsed: string;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;

    public constructor(init?: Partial<ApiJobExcretion>) { (Object as any).assign(this, init); }
}

export class ApiJobStatusDO
{
    public id: number;
    // @References(Type=typeof(ApiJobDO))
    public apiJobId: number;

    public status: SpreadWorkflowExternalJobStatus;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public estimatedStartTime: string;
    public jobPercentage: string;
    public downloadUrl: string;
    public fileSize: string;
    public cancelledDate: string;
    public jobCreatedDate: string;
    public statusPingedDate: string;

    public constructor(init?: Partial<ApiJobStatusDO>) { (Object as any).assign(this, init); }
}

export class ApiJobDO
{
    public id: number;
    public workflowHandlerType: string;
    public workflowName: string;
    public externalJobId: string;
    public status: SpreadWorkflowJobStatus;
    public failureMessage: string;
    public mostRecentException: string;
    public resultFileRecordId: number;
    public resultParsed: string;
    public requestedByPersonId: number;
    public requestedDate: string;
    public apiJobStatuss: ApiJobStatusDO[];

    public constructor(init?: Partial<ApiJobDO>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionDO
{
    public id: number;
    // @References(Type=typeof(ApiJobDO))
    public apiJobId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public name: string;
    public model: Model;
    public duration: number;
    public meanInfectionToLesionInterval: number;
    public meanInfectionToReportingInterval: number;
    public meanReportingToCullingInterval: number;
    public ignoreCulling: boolean;
    public imageFieldId: number;
    public apiJob: ApiJobDO;
    public epidemic: EpidemicDO;

    public constructor(init?: Partial<ApiJobExcretionDO>) { (Object as any).assign(this, init); }
}

export class File
{
    public name: string;
    public fileExtensionType: FileExtensionType;
    public originalName: string;
    public tempFileGuid: string;
    public exists: boolean;
    public isTemporary: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class ApiJobGenomicNetwork
{
    public id: number;
    public apiJobId: number;
    public epidemicId: number;
    public requestedByPersonId: number;
    public name: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public jobId: string;
    public resultParsed: string;
    public msaId: number;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;
    public infectedPremises: InfectedPremise[];

    public constructor(init?: Partial<ApiJobGenomicNetwork>) { (Object as any).assign(this, init); }
}

export class ApiJobGenomicNetworkSequenceParam
{
    public host: string;
    public infected_premise: string;
    public sampling_day: number;
    public start_day: number;
    public end_day: number;

    public constructor(init?: Partial<ApiJobGenomicNetworkSequenceParam>) { (Object as any).assign(this, init); }
}

export class FileRecordDO
{
    public id: number;
    public uploadedName: string;
    public folder: string;
    public createdDate: string;
    public createdByPersonId: number;
    public extension: FileExtensionType;
    // @Ignore()
    public fileNameWithExtension: string;

    // @Ignore()
    public fileName: string;

    public constructor(init?: Partial<FileRecordDO>) { (Object as any).assign(this, init); }
}

export class MSASequenceDO
{
    public id: number;
    // @References(Type=typeof(LivestockDO))
    public msaId: number;

    public label: string;
    public sequence: string;
    public createdDate: string;
    public msa: MSADO;

    public constructor(init?: Partial<MSASequenceDO>) { (Object as any).assign(this, init); }
}

export class MSADO
{
    public id: number;
    // @References(Type=typeof(FileRecordDO))
    public fileId: number;

    public createdDate: string;
    public file: FileRecordDO;
    public sequences: MSASequenceDO[];

    public constructor(init?: Partial<MSADO>) { (Object as any).assign(this, init); }
}

export class ApiJobGenomicNetworkDO
{
    public id: number;
    // @References(Type=typeof(ApiJobDO))
    public apiJobId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    // @References(Type=typeof(FileRecordDO))
    public directTransmissionsImageFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public indirectTransmissionsImageFileId: number;

    // @References(Type=typeof(MSADO))
    public msaId: number;

    public apiJob: ApiJobDO;
    public epidemic: EpidemicDO;
    public directTransmissionsImageFile: FileRecordDO;
    public indirectTransmissionsImageFile: FileRecordDO;
    public msa: MSADO;

    public constructor(init?: Partial<ApiJobGenomicNetworkDO>) { (Object as any).assign(this, init); }
}

export class Person
{
    public id: number;
    public email: string;
    public displayName: string;

    public constructor(init?: Partial<Person>) { (Object as any).assign(this, init); }
}

export class PersonGroup
{
    public id: number;
    public displayName: string;
    public createdDate: string;
    public groupMembers: Person[];

    public constructor(init?: Partial<PersonGroup>) { (Object as any).assign(this, init); }
}

export class PersonGroupEpidemic
{
    public id: number;
    public personGroupId: number;
    public epidemicId: number;
    public personGroup: PersonGroup;
    public epidemic: Epidemic;

    public constructor(init?: Partial<PersonGroupEpidemic>) { (Object as any).assign(this, init); }
}

export class PersonGroupMemberInvite
{
    public id: number;
    public email: string;
    public groupName: string;
    public createdDate: string;

    public constructor(init?: Partial<PersonGroupMemberInvite>) { (Object as any).assign(this, init); }
}

export class UserAuth
{
    public id: number;
    public userName: string;
    public email: string;
    public primaryEmail: string;
    public phoneNumber: string;
    public firstName: string;
    public lastName: string;
    public displayName: string;
    public company: string;
    public birthDate: string;
    public birthDateRaw: string;
    public address: string;
    public address2: string;
    public city: string;
    public state: string;
    public country: string;
    public culture: string;
    public fullName: string;
    public gender: string;
    public language: string;
    public mailAddress: string;
    public nickname: string;
    public postalCode: string;
    public timeZone: string;
    public salt: string;
    public passwordHash: string;
    public digestHa1Hash: string;
    public roles: string[];
    public permissions: string[];
    public createdDate: string;
    public modifiedDate: string;
    public invalidLoginAttempts: number;
    public lastLoginAttempt: string;
    public lockedDate: string;
    public recoveryToken: string;
    public refId: number;
    public refIdStr: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserAuth>) { (Object as any).assign(this, init); }
}

// @Flags()
export enum ObjectType
{
    NoCheck = 0,
    PersonGroup = 1,
    Epidemic = 2,
}

export class PermissionDO
{
    public id: number;
    public code: string;
    // @References(Type=typeof(ObjectTypeDO))
    public objectTypeId: ObjectType;

    public description: string;
    public createdDate: string;

    public constructor(init?: Partial<PermissionDO>) { (Object as any).assign(this, init); }
}

export class RoleDO
{
    public id: number;
    // @References(Type=typeof(ObjectTypeDO))
    public assignRoleObjectTypeId: ObjectType;

    public code: string;
    public displayName: string;
    public createdDate: string;
    // @Ignore()
    public permissions: PermissionDO[];

    public constructor(init?: Partial<RoleDO>) { (Object as any).assign(this, init); }
}

export class PersonRoleDO
{
    public id: number;
    // @References(Type=typeof(PersonDO))
    public personId: number;

    // @References(Type=typeof(RoleDO))
    public roleId: number;

    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    public allPersonGroups: boolean;
    public allEpidemics: boolean;
    // @References(Type=typeof(PersonGroupDO))
    public personGroupId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public createdDate: string;
    public modifiedDate: string;
    public role: RoleDO;
    public refId: number;
    public refIdStr: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<PersonRoleDO>) { (Object as any).assign(this, init); }
}

export class PersonDO extends UserAuth
{
    // @Ignore()
    public personRoles: PersonRoleDO[];

    public emailVerified: boolean;
    public emailVerifiedDate: string;
    public resetPasswordToken: string;
    public resetPasswordRequestedDate: string;

    public constructor(init?: Partial<PersonDO>) { super(init); (Object as any).assign(this, init); }
}

export class PersonGroupDO
{
    public id: number;
    public displayName: string;
    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    public createdDate: string;
    // @Ignore()
    public groupMembers: PersonDO[];

    public constructor(init?: Partial<PersonGroupDO>) { (Object as any).assign(this, init); }
}

export class QueryPersonGroup
{
    public id: number;
    public displayName: string;
    public createdDate: string;

    public constructor(init?: Partial<QueryPersonGroup>) { (Object as any).assign(this, init); }
}

export class ImportLog
{
    public id: number;
    public importType: ImportType;
    public createdByPersonId: number;
    public epidemicId: number;
    public startDate: string;
    public endDate: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ImportLog>) { (Object as any).assign(this, init); }
}

export class ImportLogDO
{
    public id: number;
    public importType: ImportType;
    // @References(Type=typeof(PersonDO))
    public createdByPersonId: number;

    // @References(Type=typeof(EpidemicDO))
    public epidemicId: number;

    public startDate: string;
    public endDate: string;
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ImportLogDO>) { (Object as any).assign(this, init); }
}

export class Instrument
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Instrument>) { (Object as any).assign(this, init); }
}

export class InstrumentDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<InstrumentDO>) { (Object as any).assign(this, init); }
}

export class LibraryStrategy
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<LibraryStrategy>) { (Object as any).assign(this, init); }
}

export class LibraryStrategyDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<LibraryStrategyDO>) { (Object as any).assign(this, init); }
}

export class LivestockBeta
{
    public id: number;
    public createdDate: string;
    public sourceLivestockId: number;
    public targetLivestockId: number;
    public value: number;

    public constructor(init?: Partial<LivestockBeta>) { (Object as any).assign(this, init); }
}

export class LivestockLog10Tcid50
{
    public id: number;
    public createdDate: string;
    public livestockId: number;
    public day: number;
    public value: number;

    public constructor(init?: Partial<LivestockLog10Tcid50>) { (Object as any).assign(this, init); }
}

export class Livestock
{
    public id: number;
    public epidemicId: number;
    public speciesName: string;
    public speciesId: number;
    public createdDate: string;
    public transmissionParameter: string;
    public latentPeriod: number;
    public infectiousPeriod1: number;
    public infectiousPeriod2: number;
    public relativeExcretionInL1: string;
    public relativeExcretionInL2: string;
    public windDispersionThreshold: string;
    public beta: LivestockBeta[];
    public log10Tcid50: LivestockLog10Tcid50[];

    public constructor(init?: Partial<Livestock>) { (Object as any).assign(this, init); }
}

export class LivestockLog10Tcid50DefaultDO
{
    public id: number;
    public createdDate: string;
    // @References(Type=typeof(LivestockDefaultDO))
    public livestockId: number;

    public day: number;
    public value: number;

    public constructor(init?: Partial<LivestockLog10Tcid50DefaultDO>) { (Object as any).assign(this, init); }
}

export class LivestockDefaultDO
{
    public id: number;
    // @References(Type=typeof(SpeciesDO))
    public speciesId: number;

    public createdDate: string;
    public transmissionParameter: string;
    public latentPeriod: number;
    public infectiousPeriod1: number;
    public infectiousPeriod2: number;
    public relativeExcretionInL1: number;
    public relativeExcretionInL2: number;
    public windDispersionThreshold: number;
    public species: SpeciesDO;
    public beta: LivestockBetaDefaultDO[];
    public log10Tcid50: LivestockLog10Tcid50DefaultDO[];

    public constructor(init?: Partial<LivestockDefaultDO>) { (Object as any).assign(this, init); }
}

export class LivestockBetaDefaultDO
{
    public id: number;
    public createdDate: string;
    // @References(Type=typeof(LivestockDefaultDO))
    public sourceLivestockId: number;

    // @References(Type=typeof(LivestockDefaultDO))
    public targetLivestockId: number;

    public value: number;
    public sourceLivestock: LivestockDefaultDO;
    public targetLivestock: LivestockDefaultDO;

    public constructor(init?: Partial<LivestockBetaDefaultDO>) { (Object as any).assign(this, init); }
}

export class Material
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Material>) { (Object as any).assign(this, init); }
}

export class MaterialDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<MaterialDO>) { (Object as any).assign(this, init); }
}

export class MaxSpecies
{
    /**
    * cattle, sheep, pigs and goats are the four species we are currently testing.
    */
    // @ApiMember(Description="cattle, sheep, pigs and goats are the four species we are currently testing.", IsRequired=true)
    public speciesName: string;

    /**
    * The number of susceptible species within the paddock, parcel or farm.
    */
    // @ApiMember(Description="The number of susceptible species within the paddock, parcel or farm.", IsRequired=true)
    public numberSusceptible: number;

    /**
    * Estimate of the number of infected animals within the paddock, parcel or farm.
    */
    // @ApiMember(Description="Estimate of the number of infected animals within the paddock, parcel or farm.", IsRequired=true)
    public numberInfected: number;

    /**
    * Is this the first species in which the disease was identified?
    */
    // @ApiMember(Description="Is this the first species in which the disease was identified?")
    public isIndexSpecies: boolean;

    public constructor(init?: Partial<MaxSpecies>) { (Object as any).assign(this, init); }
}

export class MaxDate
{
    /**
    * The date naming should be consistent across the epidemic. These can be specific to the epidemic, but should be consistent within the epidemic.  If the farm is an "InfectedPremise" then at least 1 date should be present.  Properties at risk will not have any dates allocated, unless an inspection has been undertaken and recorded.
    */
    // @ApiMember(Description="The date naming should be consistent across the epidemic. These can be specific to the epidemic, but should be consistent within the epidemic.  If the farm is an \"InfectedPremise\" then at least 1 date should be present.  Properties at risk will not have any dates allocated, unless an inspection has been undertaken and recorded.", IsRequired=true)
    public dateAttribute: string;

    /**
    * yyyy/mm/dd
    */
    // @ApiMember(Description="yyyy/mm/dd", IsRequired=true)
    public date: string;

    public constructor(init?: Partial<MaxDate>) { (Object as any).assign(this, init); }
}

export class MaxCoordinate
{
    /**
    * The horizontal part of the coordinate.
    */
    // @ApiMember(Description="The horizontal part of the coordinate.", IsRequired=true)
    public x: number;

    /**
    * The vertical part of the coordinate.
    */
    // @ApiMember(Description="The vertical part of the coordinate.", IsRequired=true)
    public y: number;

    public constructor(init?: Partial<MaxCoordinate>) { (Object as any).assign(this, init); }
}

export class MaxPoint
{
    /**
    * The coordinate of the point.
    */
    // @ApiMember(Description="The coordinate of the point.", IsRequired=true)
    public coordinate: MaxCoordinate;

    public constructor(init?: Partial<MaxPoint>) { (Object as any).assign(this, init); }
}

export class MaxPolygon
{
    /**
    * The list of coordinates that make up the polygon
    */
    // @ApiMember(Description="The list of coordinates that make up the polygon", IsRequired=true)
    public coordinates: MaxCoordinate[];

    public constructor(init?: Partial<MaxPolygon>) { (Object as any).assign(this, init); }
}

export class MaxSpatial
{
    /**
    * A single point denoting the position.
    */
    // @ApiMember(Description="A single point denoting the position.")
    public point: MaxPoint;

    /**
    * One or more polygons describing the location.
    */
    // @ApiMember(Description="One or more polygons describing the location.")
    public polygons: MaxPolygon[];

    public constructor(init?: Partial<MaxSpatial>) { (Object as any).assign(this, init); }
}

export class MaxArea
{
    public species: MaxSpecies[];
    public dates: MaxDate[];
    public spatial: MaxSpatial;
    public area: number;

    public constructor(init?: Partial<MaxArea>) { (Object as any).assign(this, init); }
}

export class MaxField extends MaxArea
{
    /**
    * This value could be either numeric or containing characters. Most likely the paddock will be picked up during an outbreak and may not currently be stored within the jurisdictions database.
    */
    // @ApiMember(Description="This value could be either numeric or containing characters. Most likely the paddock will be picked up during an outbreak and may not currently be stored within the jurisdictions database.", IsRequired=true)
    public fieldId: string;

    /**
    * Is the paddock a true paddock or a shed/barn?
    */
    // @ApiMember(Description="Is the paddock a true paddock or a shed/barn?")
    public fieldBarnId: string;

    /**
    * What is the area of the paddock? (measured in sqm)
    */
    // @ApiMember(Description="What is the area of the paddock? (measured in sqm)")
    public fieldAreaMetersSquared: number;

    /**
    * Is this the first field where the disease was identified?
    */
    // @ApiMember(Description="Is this the first field where the disease was identified?")
    public isIndexField: boolean;

    public constructor(init?: Partial<MaxField>) { super(init); (Object as any).assign(this, init); }
}

export class MaxParcel extends MaxArea
{
    /**
    * The "parcelId" should be a unique value.  This maybe the parcel identifier (PFI) by Vicmap Property which would also relate to the parcel polygon.  Other jurisdictions will have a similar property identifier.
    */
    // @ApiMember(Description="The \"parcelId\" should be a unique value.  This maybe the parcel identifier (PFI) by Vicmap Property which would also relate to the parcel polygon.  Other jurisdictions will have a similar property identifier.", IsRequired=true)
    public parcelId: string;

    public fields: MaxField[];

    public constructor(init?: Partial<MaxParcel>) { super(init); (Object as any).assign(this, init); }
}

export class MaxFarm extends MaxArea
{
    /**
    * The property identifier of the farm.  In most cases this will be the PIC.
    */
    // @ApiMember(Description="The property identifier of the farm.  In most cases this will be the PIC.", IsRequired=true)
    public farmId: string;

    /**
    * A farm will be allocated an identifier during the epidemic. This may be the sequential number of when the farm was deemed a positive case.
    */
    // @ApiMember(Description="A farm will be allocated an identifier during the epidemic. This may be the sequential number of when the farm was deemed a positive case.")
    public infectedPremise: string;

    public parcels: MaxParcel[];

    public constructor(init?: Partial<MaxFarm>) { super(init); (Object as any).assign(this, init); }
}

export class MaxSubCluster
{
    /**
    * The overall cluster maybe divided into sub-clusters to reduce the number of farms within a cluster
    */
    // @ApiMember(Description="The overall cluster maybe divided into sub-clusters to reduce the number of farms within a cluster", IsRequired=true)
    public subClusterName: string;

    public farms: MaxFarm[];

    public constructor(init?: Partial<MaxSubCluster>) { (Object as any).assign(this, init); }
}

export class MaxCluster
{
    /**
    * Clusters will be divided by the agency and may be based on location, municipality, or region, etc.
    */
    // @ApiMember(Description="Clusters will be divided by the agency and may be based on location, municipality, or region, etc.", IsRequired=true)
    public clusterName: string;

    public subClusters: MaxSubCluster[];
    public farms: MaxFarm[];

    public constructor(init?: Partial<MaxCluster>) { (Object as any).assign(this, init); }
}

export class MSASequence
{
    public id: number;
    public msaId: number;
    public label: string;
    public sequence: string;
    public createdDate: string;

    public constructor(init?: Partial<MSASequence>) { (Object as any).assign(this, init); }
}

export class MSA
{
    public id: number;
    public sequenceCount: number;
    public createdDate: string;
    public hasGenomicNetwork: boolean;
    public sequences: MSASequence[];

    public constructor(init?: Partial<MSA>) { (Object as any).assign(this, init); }
}

export class QueryMSA
{
    public id: number;
    public epidemicId: number;
    public status: SpreadWorkflowJobStatus;
    public externalJobId: string;
    public failureMessage: string;
    public requestedDate: string;
    public apiJobGenomicNetworkId: number;

    public constructor(init?: Partial<QueryMSA>) { (Object as any).assign(this, init); }
}

export class ApiJobMSA
{
    public id: number;
    public apiJobId: number;
    public epidemicId: number;
    public requestedByPersonId: number;
    public name: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public jobId: string;
    public resultParsed: string;
    public msaId: number;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;
    public infectedPremises: InfectedPremise[];

    public constructor(init?: Partial<ApiJobMSA>) { (Object as any).assign(this, init); }
}

export class ApiJobMSASequenceParam
{
    public host: string;
    public infected_premise: string;

    public constructor(init?: Partial<ApiJobMSASequenceParam>) { (Object as any).assign(this, init); }
}

export class AuthenticatedPerson
{
    public id: number;
    public email: string;
    public displayName: string;
    public permissions: string[];
    public epidemicPermissions: { [index: number]: string[]; };
    public groupPermissions: { [index: number]: string[]; };
    public memberGroups: PersonGroup[];
    public notificationCount: number;

    public constructor(init?: Partial<AuthenticatedPerson>) { (Object as any).assign(this, init); }
}

export class QueryPerson
{
    public id: number;
    public displayName: string;
    public createdDate: string;

    public constructor(init?: Partial<QueryPerson>) { (Object as any).assign(this, init); }
}

export class Platform
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Platform>) { (Object as any).assign(this, init); }
}

export class PlatformDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<PlatformDO>) { (Object as any).assign(this, init); }
}

export class Permission
{
    public id: number;
    public code: string;
    public objectType: ObjectType;
    public description: string;
    public createdDate: string;

    public constructor(init?: Partial<Permission>) { (Object as any).assign(this, init); }
}

export class Role
{
    public id: number;
    public assignRoleObjectTypeId: number;
    public code: string;
    public displayName: string;
    public createdDate: string;
    public permissions: Permission[];

    public constructor(init?: Partial<Role>) { (Object as any).assign(this, init); }
}

export class QueryRole
{
    public id: number;
    public assignRoleObjectTypeId: ObjectType;
    public code: string;
    public displayName: string;
    public createdDate: string;

    public constructor(init?: Partial<QueryRole>) { (Object as any).assign(this, init); }
}

export class QueryPersonRole
{
    public id: number;
    public createdByPersonId: number;
    public allPersonGroups: boolean;
    public allEpidemics: boolean;
    public personGroupId: number;
    public epidemicId: number;
    public roleId: number;
    public roleDisplayName: string;
    public personId: number;
    public personDisplayName: string;
    public createdDate: string;
    public modifiedDate: string;

    public constructor(init?: Partial<QueryPersonRole>) { (Object as any).assign(this, init); }
}

export class QuerySpecimen
{
    public id: number;
    public collectionDate: string;
    public specimenId: string;
    public isolateName: string;
    public livestockName: string;

    public constructor(init?: Partial<QuerySpecimen>) { (Object as any).assign(this, init); }
}

export class Sequence
{
    public id: number;
    public epidemicId: number;
    public specimenId: number;
    public enrichmentMethodId: number;
    public laboratory: string;
    public date: string;
    public technician: string;
    public isolationDate: string;
    public systemId: number;
    public platformId: number;
    public libraryStrategyId: number;
    public pairedEnd: boolean;
    public instrumentId: number;
    public file1Id: number;
    public file2Id: number;
    public spreadSequenceId: string;
    public comment: string;
    public originId: number;
    public originName: string;
    public consensusSequenceId: number;
    public specimen: QuerySpecimen;
    public fastQTempFile1: File[];
    public fastQTempFile2: File[];
    public fastATempFile: File[];

    public constructor(init?: Partial<Sequence>) { (Object as any).assign(this, init); }
}

export class ConsensusSequence
{
    public id: number;
    public sequenceId: number;
    public assemblyQA: boolean;
    public assemblyComment: string;
    public annotationQA: boolean;
    public annotationComment: string;
    public createdDate: string;
    public gbkFileId: number;
    public fastQForwardReadsImageFileId: number;
    public fastQReverseReadsImageFileId: number;
    public coverageImageFileId: number;
    public sequence: Sequence;

    public constructor(init?: Partial<ConsensusSequence>) { (Object as any).assign(this, init); }
}

export class TissueDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<TissueDO>) { (Object as any).assign(this, init); }
}

export class SpecimenInfectedPremiseDO
{
    public id: number;
    // @References(Type=typeof(SpecimenDO))
    public specimenId: number;

    // @References(Type=typeof(InfectedPremiseDO))
    public infectedPremiseId: number;

    public specimen: SpecimenDO;
    public infectedPremise: InfectedPremiseDO;

    public constructor(init?: Partial<SpecimenInfectedPremiseDO>) { (Object as any).assign(this, init); }
}

export class SequenceOriginDO
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<SequenceOriginDO>) { (Object as any).assign(this, init); }
}

export class ConsensusSequenceDO
{
    public id: number;
    // @References(Type=typeof(SequenceDO))
    public sequenceId: number;

    public assemblyQA: boolean;
    public assemblyComment: string;
    public annotationQA: boolean;
    public annotationComment: string;
    public createdDate: string;
    // @References(Type=typeof(FileRecordDO))
    public gbkFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public fastQForwardReadsImageFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public fastQReverseReadsImageFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public coverageImageFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public assemblyJsonFileId: number;

    // @References(Type=typeof(FileRecordDO))
    public annotationJsonFileId: number;

    public sequence: SequenceDO;
    public gbkFile: FileRecordDO;
    public fastQForwardReadsImageFile: FileRecordDO;
    public fastQReverseReadsImageFile: FileRecordDO;
    public coverageImageFile: FileRecordDO;

    public constructor(init?: Partial<ConsensusSequenceDO>) { (Object as any).assign(this, init); }
}

export class SequenceDO
{
    public id: number;
    // @References(Type=typeof(SpecimenDO))
    public specimenId: number;

    // @References(Type=typeof(EnrichmentMethodDO))
    public enrichmentMethodId: number;

    public laboratory: string;
    public date: string;
    public technician: string;
    public systemId: number;
    // @References(Type=typeof(PlatformDO))
    public platformId: number;

    // @References(Type=typeof(LibraryStrategyDO))
    public libraryStrategyId: number;

    public pairedEnd: boolean;
    // @References(Type=typeof(InstrumentDO))
    public instrumentId: number;

    // @References(Type=typeof(FileRecordDO))
    public file1Id: number;

    // @References(Type=typeof(FileRecordDO))
    public file2Id: number;

    public spreadSequenceId: string;
    public comment: string;
    public createdDate: string;
    // @References(Type=typeof(SequenceOriginDO))
    public originId: number;

    public modifiedDate: string;
    public specimen: SpecimenDO;
    public enrichmentMethod: EnrichmentMethodDO;
    public platform: PlatformDO;
    public libraryStrategy: LibraryStrategyDO;
    public instrument: InstrumentDO;
    public file1: FileRecordDO;
    public file2: FileRecordDO;
    public origin: SequenceOriginDO;
    public consensusSequences: ConsensusSequenceDO[];

    public constructor(init?: Partial<SequenceDO>) { (Object as any).assign(this, init); }
}

export class SpecimenDO
{
    public id: number;
    // @References(Type=typeof(SpeciesDO))
    public speciesId: number;

    public collectionDate: string;
    public collectionDateRaw: string;
    public specimenId: string;
    // @References(Type=typeof(TissueDO))
    public tissueId: number;

    public nlisrfid: string;
    public isolateName: string;
    public isolationDate: string;
    // @References(Type=typeof(MaterialDO))
    public materialId: number;

    // @References(Type=typeof(CountryDO))
    public countryId: number;

    public createdDate: string;
    public species: SpeciesDO;
    public tissue: TissueDO;
    public material: MaterialDO;
    public country: CountryDO;
    public infectedPremises: SpecimenInfectedPremiseDO[];
    public sequences: SequenceDO[];

    public constructor(init?: Partial<SpecimenDO>) { (Object as any).assign(this, init); }
}

export class QuerySequence
{
    public id: number;
    public specimenId: number;
    public date: string;
    public spreadSequenceId: string;
    public originName: string;
    public hasConsensusSequence: boolean;
    public specimenSpecimenId: string;

    public constructor(init?: Partial<QuerySequence>) { (Object as any).assign(this, init); }
}

export class FastAFastQMetadata
{
    public accession: string;
    public collection_date: string;
    public host: string;
    public isolate: string;
    public country: string;
    public organism: string;
    public exp_center_name: string;
    public exp_instrument_model: string;
    public specimen_id: string;
    public infected_premise: string;
    public host_subject_id: string;
    public isolation_source: string;
    public isolation_date: string;
    public note: string;
    public tissue_type: string;
    public sequencing_date: string;
    public library_strategy: string;
    public library_strategy_end: string;
    public seq_technician: string;
    public platform: string;
    public seq_enrichment: string;

    public constructor(init?: Partial<FastAFastQMetadata>) { (Object as any).assign(this, init); }
}

export class SequenceView
{
    public sequenceId: number;
    public consensusSequenceId: number;
    public originId: number;
    public origin: string;
    public laboratory: string;
    public specimenId: number;
    public spreadSpecimenId: string;
    public isolateName: string;
    public collectionDate: string;
    public collectionDateRaw: string;
    public limsId: string;
    public country: string;
    public spreadSequenceId: string;
    public sequencingDate: string;
    public assemblyJobId: number;
    public sequencingStatus: string;
    public assemblyQAStatus: string;
    public annotationQAStatus: string;
    public modifiedDate: string;

    public constructor(init?: Partial<SequenceView>) { (Object as any).assign(this, init); }
}

export class QuerySequenceView
{
    public sequenceId: number;
    public consensusSequenceId: number;
    public epidemicId: number;
    public clusterId: number;
    public clusterName: string;
    public infectedPremiseId: number;
    public infectedPremiseInfectedPremiseId: string;
    public originId: number;
    public origin: string;
    public laboratory: string;
    public specimenId: number;
    public isolateName: string;
    public collectionDate: string;
    public collectionDateRaw: string;
    public isCollectionDateEstimated: boolean;
    public limsId: string;
    public spreadSequenceId: string;
    public sequencingDate: string;
    public sequencingStatus: string;
    public assemblyQAStatus: string;
    public annotationQAStatus: string;

    public constructor(init?: Partial<QuerySequenceView>) { (Object as any).assign(this, init); }
}

export class ApiJobSequenceRetrieval
{
    public id: number;
    public requestedByPersonId: number;
    public name: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public jobId: string;
    public resultParsed: string;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;
    public timestamp: string;

    public constructor(init?: Partial<ApiJobSequenceRetrieval>) { (Object as any).assign(this, init); }
}

export enum AssemblyMethod
{
    ReferenceIsolate = 1,
    ReferenceGenotype = 2,
    VP1 = 3,
    Denovo = 4,
}

export class ApiJobAssembly
{
    public id: number;
    public apiJobId: number;
    public requestedByPersonId: number;
    public name: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public jobId: string;
    public resultParsed: string;
    public sequenceId: number;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;
    public infectedPremises: InfectedPremise[];

    public constructor(init?: Partial<ApiJobAssembly>) { (Object as any).assign(this, init); }
}

export class Species
{
    public id: number;
    public name: string;
    public supportsExcretion: boolean;
    public createdDate: string;

    public constructor(init?: Partial<Species>) { (Object as any).assign(this, init); }
}

export class Specimen
{
    public id: number;
    public speciesId: number;
    public collectionDate: string;
    public collectionDateRaw: string;
    public specimenId: string;
    public tissueId: number;
    public nlisrfid: string;
    public isolateName: string;
    public isolationDate: string;
    public materialId: number;
    public species: Species;
    public sequences: QuerySequence[];
    public epidemicId: number;
    public clusterId: number;
    public infectedPremiseId: number;

    public constructor(init?: Partial<Specimen>) { (Object as any).assign(this, init); }
}

export class SpreadWorkflowJob
{
    public id: number;
    public workflowName: string;
    public jobId: string;
    public status: SpreadWorkflowJobStatus;
    public failureMessage: string;
    public resultParsed: string;
    public requestedByPersonId: number;
    public requestedDate: string;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public name: string;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;

    public constructor(init?: Partial<SpreadWorkflowJob>) { (Object as any).assign(this, init); }
}

export class QuerySubClassification
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<QuerySubClassification>) { (Object as any).assign(this, init); }
}

export class QuerySubCluster
{
    public id: number;
    public clusterId: number;
    public name: string;
    public unassigned: boolean;
    public infectedPremiseCount: number;
    public subClusterDateSet: DateSet;

    public constructor(init?: Partial<QuerySubCluster>) { (Object as any).assign(this, init); }
}

export class SubNational
{
    public id: number;
    public countryId: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<SubNational>) { (Object as any).assign(this, init); }
}

export class QuerySubNational
{
    public id: number;
    public countryId: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<QuerySubNational>) { (Object as any).assign(this, init); }
}

export class ApiJobExcretionResultDO
{
    public id: number;
    public apiJobExcretionId: number;
    public areaId: number;
    public date: string;
    public value: number;
    public livestockId: number;

    public constructor(init?: Partial<ApiJobExcretionResultDO>) { (Object as any).assign(this, init); }
}

// @Flags()
export enum ILength
{
    OneDay = '1',
    TwoDays = '2',
    ThreeDays = '3',
    FourDays = '4',
}

// @Flags()
export enum InRun
{
    ThirtyKilometres = '1',
    TenKilometres = '2',
    ThreeKilometres = '3',
    OneKilometre = '4',
    ThreeHundredMetres = '5',
    Auto = '6',
}

export enum VirusSurvival
{
    Off = 'off',
    SerotypeOAvg = 'serotype_o_avg',
    Lausanne = 'lausanne',
    UKG2001 = 'ukg_32_2001',
    UKG2007 = 'ukg_7_2007',
}

export class AnalysisJobTapm
{
    public id: number;
    public epidemicId: number;
    public subClusterId: number;
    public requestedByPersonId: number;
    public name: string;
    public failureMessage: string;
    public status: SpreadWorkflowJobStatus;
    public apiStatus: SpreadWorkflowExternalJobStatus;
    public requestedDate: string;
    public jobId: string;
    public resultParsed: string;
    public startRunTime: string;
    public estimatedRunTimeSeconds: number;
    public jobPercentage: number;
    public fileSize: string;
    public cancelledDate: string;
    public statusLastChecked: string;
    public infectedPremises: InfectedPremise[];
    public excretionValues: ApiJobExcretionResultDO[];
    public limitRunStartDate: string;
    public limitRunEndDate: string;
    public globalEmissionRate: number;
    public excretionRunId: number;
    public iLength: ILength;
    public inRun: InRun;
    public virusSurvival: VirusSurvival;

    public constructor(init?: Partial<AnalysisJobTapm>) { (Object as any).assign(this, init); }
}

export enum WindDispersionResultType
{
    ContinuousAggregate = 'ContinuousAggregate',
    DailyTotalAggregate = 'DailyTotalAggregate',
    Heatmap = 'Heatmap',
    TotalAggregate = 'TotalAggregate',
}

export class ResultGridCell
{
    public key: number;
    public lat: string;
    public lng: string;
    public latSize: number;
    public lngSize: number;

    public constructor(init?: Partial<ResultGridCell>) { (Object as any).assign(this, init); }
}

export class AnalysisJobWindDispersionResultValue
{
    public value: number;

    public constructor(init?: Partial<AnalysisJobWindDispersionResultValue>) { (Object as any).assign(this, init); }
}

export enum AnalysisInfectedPremiseStatus
{
    AtRisk = 'AtRisk',
}

export class AnalysisJobInfectedPremiseStatus
{
    public infectedPremiseId: number;
    public infectedPremiseStatus: AnalysisInfectedPremiseStatus;

    public constructor(init?: Partial<AnalysisJobInfectedPremiseStatus>) { (Object as any).assign(this, init); }
}

export class AnalysisJobWindDispersionResult
{
    public timeStep: string;
    public resultGridCellAnalysisJobTapmResultValues: { [index: number]: AnalysisJobWindDispersionResultValue; };
    public analysisJobInfectedPremiseStatuses: AnalysisJobInfectedPremiseStatus[];

    public constructor(init?: Partial<AnalysisJobWindDispersionResult>) { (Object as any).assign(this, init); }
}

export enum AnalysisResultTimeScale
{
    SingleFrame = 'SingleFrame',
    Hour = 'Hour',
    Day = 'Day',
}

export class TapmDataSetDO
{
    public id: number;
    public dataSetId: string;
    public dataSetName: string;
    public minLongitude: number;
    public minLatitude: number;
    public maxLongitude: number;
    public maxLatitude: number;
    public temporalResolution: number;
    public temporalUnits: string;
    public minDate: string;
    public maxDate: string;
    public createdDate: string;

    public constructor(init?: Partial<TapmDataSetDO>) { (Object as any).assign(this, init); }
}

export class AnalysisTapmDataSet
{
    public id: number;
    public dataSetId: string;
    public dataSetName: string;
    public minLongitude: number;
    public minLatitude: number;
    public maxLongitude: number;
    public maxLatitude: number;
    public temporalResolution: number;
    public temporalUnits: string;
    public minDate: string;
    public maxDate: string;
    public createdDate: string;

    public constructor(init?: Partial<AnalysisTapmDataSet>) { (Object as any).assign(this, init); }
}

export enum TemplateType
{
    PersonInvite = 1,
    ResetPasswordRequest = 2,
}

export class QueryDb_1<T> extends QueryBase
{

    public constructor(init?: Partial<QueryDb_1<T>>) { super(init); (Object as any).assign(this, init); }
}

export class PartialDO
{
    public id: number;
    public name: string;
    public description: string;
    public alwaysInclude: boolean;
    public body: string;

    public constructor(init?: Partial<PartialDO>) { (Object as any).assign(this, init); }
}

export class LayoutDO
{
    public id: number;
    public name: string;
    public body: string;

    public constructor(init?: Partial<LayoutDO>) { (Object as any).assign(this, init); }
}

export class TemplateDO
{
    public id: number;
    // @References(Type=typeof(LayoutDO))
    public layoutId: number;

    // @References(Type=typeof(ConfigurationDO))
    public configurationId: number;

    public name: string;
    public description: string;
    public body: string;
    public ignoreEmailOptOut: boolean;
    public partials: PartialDO[];
    public layout: LayoutDO;

    public constructor(init?: Partial<TemplateDO>) { (Object as any).assign(this, init); }
}

export class Tissue
{
    public id: number;
    public name: string;
    public createdDate: string;

    public constructor(init?: Partial<Tissue>) { (Object as any).assign(this, init); }
}

export class Tooltip
{
    public key: string;
    public url: string;
    public urlTitle: string;

    public constructor(init?: Partial<Tooltip>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IBulkSave
{
    id?: number;
    bulkSaveGuid: string;
}

export interface IArchivable
{
    archived: boolean;
}

export class GetAreaDateResponse
{
    public responseStatus: ResponseStatus;
    public areaDate: AreaDate;

    public constructor(init?: Partial<GetAreaDateResponse>) { (Object as any).assign(this, init); }
}

export class CreateAreaDateResponse
{
    public responseStatus: ResponseStatus;
    public areaDate: AreaDate;

    public constructor(init?: Partial<CreateAreaDateResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAreaDateResponse
{
    public responseStatus: ResponseStatus;
    public areaDate: AreaDate;

    public constructor(init?: Partial<UpdateAreaDateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class QueryResponse<T>
{
    // @DataMember(Order=1)
    public offset: number;

    // @DataMember(Order=2)
    public total: number;

    // @DataMember(Order=3)
    public results: T[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    // @DataMember(Order=5)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueryResponse<T>>) { (Object as any).assign(this, init); }
}

export class GetAreaDateTypeResponse
{
    public responseStatus: ResponseStatus;
    public areaDateType: AreaDateType;

    public constructor(init?: Partial<GetAreaDateTypeResponse>) { (Object as any).assign(this, init); }
}

export class CreateAreaDateTypeResponse
{
    public responseStatus: ResponseStatus;
    public areaDateType: AreaDateType;

    public constructor(init?: Partial<CreateAreaDateTypeResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAreaDateTypeResponse
{
    public responseStatus: ResponseStatus;
    public areaDateType: AreaDateType;

    public constructor(init?: Partial<UpdateAreaDateTypeResponse>) { (Object as any).assign(this, init); }
}

export class DeleteAreaDateTypeResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteAreaDateTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetAreaLivestockCountResponse
{
    public responseStatus: ResponseStatus;
    public areaLivestockCount: AreaLivestockCount;

    public constructor(init?: Partial<GetAreaLivestockCountResponse>) { (Object as any).assign(this, init); }
}

export class CreateAreaLivestockCountResponse
{
    public responseStatus: ResponseStatus;
    public areaLivestockCount: AreaLivestockCount;

    public constructor(init?: Partial<CreateAreaLivestockCountResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAreaLivestockCountResponse
{
    public responseStatus: ResponseStatus;
    public areaLivestockCount: AreaLivestockCount;

    public constructor(init?: Partial<UpdateAreaLivestockCountResponse>) { (Object as any).assign(this, init); }
}

export class GetAreaResponse
{
    public responseStatus: ResponseStatus;
    public area: Area;

    public constructor(init?: Partial<GetAreaResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAreaResponse
{
    public responseStatus: ResponseStatus;
    public area: Area;

    public constructor(init?: Partial<UpdateAreaResponse>) { (Object as any).assign(this, init); }
}

export class PreparseImportFileResponse
{
    public responseStatus: ResponseStatus;
    public foundHeaders: string[];
    public expectedHeaders: BulkUploadHeaderColumn[];

    public constructor(init?: Partial<PreparseImportFileResponse>) { (Object as any).assign(this, init); }
}

export class ImportDatesResponse
{
    public responseStatus: ResponseStatus;
    public importLogId: number;

    public constructor(init?: Partial<ImportDatesResponse>) { (Object as any).assign(this, init); }
}

export class ImportSpatialResponse
{
    public responseStatus: ResponseStatus;
    public importLogId: number;

    public constructor(init?: Partial<ImportSpatialResponse>) { (Object as any).assign(this, init); }
}

export class ImportLivestockResponse
{
    public responseStatus: ResponseStatus;
    public importLogId: number;

    public constructor(init?: Partial<ImportLivestockResponse>) { (Object as any).assign(this, init); }
}

export class ImportEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public importLogId: number;

    public constructor(init?: Partial<ImportEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class ImportHierarchyResponse
{
    public responseStatus: ResponseStatus;
    public importLogId: number;

    public constructor(init?: Partial<ImportHierarchyResponse>) { (Object as any).assign(this, init); }
}

export class GetClassificationResponse
{
    public responseStatus: ResponseStatus;
    public classification: Classification;

    public constructor(init?: Partial<GetClassificationResponse>) { (Object as any).assign(this, init); }
}

export class CreateClassificationResponse
{
    public responseStatus: ResponseStatus;
    public classification: Classification;

    public constructor(init?: Partial<CreateClassificationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateClassificationResponse
{
    public responseStatus: ResponseStatus;
    public classification: Classification;

    public constructor(init?: Partial<UpdateClassificationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteClassificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteClassificationResponse>) { (Object as any).assign(this, init); }
}

export class GetClusterResponse
{
    public responseStatus: ResponseStatus;
    public cluster: Cluster;

    public constructor(init?: Partial<GetClusterResponse>) { (Object as any).assign(this, init); }
}

export class CreateClusterResponse
{
    public responseStatus: ResponseStatus;
    public cluster: Cluster;

    public constructor(init?: Partial<CreateClusterResponse>) { (Object as any).assign(this, init); }
}

export class UpdateClusterResponse
{
    public responseStatus: ResponseStatus;
    public cluster: Cluster;

    public constructor(init?: Partial<UpdateClusterResponse>) { (Object as any).assign(this, init); }
}

export class GetCountryResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;

    public constructor(init?: Partial<GetCountryResponse>) { (Object as any).assign(this, init); }
}

export class CreateCountryResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;

    public constructor(init?: Partial<CreateCountryResponse>) { (Object as any).assign(this, init); }
}

export class UpdateCountryResponse
{
    public responseStatus: ResponseStatus;
    public country: Country;

    public constructor(init?: Partial<UpdateCountryResponse>) { (Object as any).assign(this, init); }
}

export class GetDiseaseClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseClassification: DiseaseClassification;

    public constructor(init?: Partial<GetDiseaseClassificationResponse>) { (Object as any).assign(this, init); }
}

export class CreateDiseaseClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseClassification: DiseaseClassification;

    public constructor(init?: Partial<CreateDiseaseClassificationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateDiseaseClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseClassification: DiseaseClassification;

    public constructor(init?: Partial<UpdateDiseaseClassificationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDiseaseClassificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDiseaseClassificationResponse>) { (Object as any).assign(this, init); }
}

export class GetDiseaseResponse
{
    public responseStatus: ResponseStatus;
    public disease: Disease;

    public constructor(init?: Partial<GetDiseaseResponse>) { (Object as any).assign(this, init); }
}

export class CreateDiseaseResponse
{
    public responseStatus: ResponseStatus;
    public disease: Disease;

    public constructor(init?: Partial<CreateDiseaseResponse>) { (Object as any).assign(this, init); }
}

export class UpdateDiseaseResponse
{
    public responseStatus: ResponseStatus;
    public disease: Disease;

    public constructor(init?: Partial<UpdateDiseaseResponse>) { (Object as any).assign(this, init); }
}

export class GetDiseaseSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseSubClassification: DiseaseSubClassification;

    public constructor(init?: Partial<GetDiseaseSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class CreateDiseaseSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseSubClassification: DiseaseSubClassification;

    public constructor(init?: Partial<CreateDiseaseSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateDiseaseSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public diseaseSubClassification: DiseaseSubClassification;

    public constructor(init?: Partial<UpdateDiseaseSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteDiseaseSubClassificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteDiseaseSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class GetEnrichmentMethodResponse
{
    public responseStatus: ResponseStatus;
    public enrichmentMethod: EnrichmentMethod;

    public constructor(init?: Partial<GetEnrichmentMethodResponse>) { (Object as any).assign(this, init); }
}

export class CreateEnrichmentMethodResponse
{
    public responseStatus: ResponseStatus;
    public enrichmentMethod: EnrichmentMethod;

    public constructor(init?: Partial<CreateEnrichmentMethodResponse>) { (Object as any).assign(this, init); }
}

export class UpdateEnrichmentMethodResponse
{
    public responseStatus: ResponseStatus;
    public enrichmentMethod: EnrichmentMethod;

    public constructor(init?: Partial<UpdateEnrichmentMethodResponse>) { (Object as any).assign(this, init); }
}

export class DeleteEnrichmentMethodResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteEnrichmentMethodResponse>) { (Object as any).assign(this, init); }
}

export class GetEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public epidemic: Epidemic;

    public constructor(init?: Partial<GetEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class CreateEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public epidemic: Epidemic;

    public constructor(init?: Partial<CreateEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class UpdateEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public epidemic: Epidemic;

    public constructor(init?: Partial<UpdateEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class ArchiveEpidemicResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ArchiveEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class GetExcretionInputParamsResponse
{
    public responseStatus: ResponseStatus;
    public species: ApiJobExcretionSpecies[];
    public infectedPremises: ApiJobExcretionArea[];
    public edges: ApiJobExcretionEdge[];
    public duration: number;
    public i2lInterval: number;
    public i2rInterval: number;
    public r2cInterval: number;
    public ka: number;
    public ke: number;
    public indexPadBarn: string;
    public ignoreCulling: string;
    public testing: string;
    public model: Model;

    public constructor(init?: Partial<GetExcretionInputParamsResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobExcretionResponse
{
    public excretionJob: ApiJobExcretion;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateJobExcretionResponse>) { (Object as any).assign(this, init); }
}

export class DeleteJobExcretionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteJobExcretionResponse>) { (Object as any).assign(this, init); }
}

export class CancelJobExcretionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CancelJobExcretionResponse>) { (Object as any).assign(this, init); }
}

export class GetFieldResponse
{
    public responseStatus: ResponseStatus;
    public field: Field;

    public constructor(init?: Partial<GetFieldResponse>) { (Object as any).assign(this, init); }
}

export class CreateFieldResponse
{
    public responseStatus: ResponseStatus;
    public field: Field;

    public constructor(init?: Partial<CreateFieldResponse>) { (Object as any).assign(this, init); }
}

export class UpdateFieldResponse
{
    public responseStatus: ResponseStatus;
    public field: Field;

    public constructor(init?: Partial<UpdateFieldResponse>) { (Object as any).assign(this, init); }
}

export class PostTemporaryFilesResponse
{
    public files: File[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostTemporaryFilesResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobGenomicNetworkResponse
{
    public genomicNetworkJob: ApiJobGenomicNetwork;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateJobGenomicNetworkResponse>) { (Object as any).assign(this, init); }
}

export class DeleteJobGenomicNetworkResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteJobGenomicNetworkResponse>) { (Object as any).assign(this, init); }
}

export class GetGenomicNetworkSequenceParamsResponse
{
    public sequences: ApiJobGenomicNetworkSequenceParam[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetGenomicNetworkSequenceParamsResponse>) { (Object as any).assign(this, init); }
}

export class CreateGenomicSyncJobResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateGenomicSyncJobResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public groupEpidemic: PersonGroupEpidemic;

    public constructor(init?: Partial<GetGroupEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class CreateGroupEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public groupEpidemic: PersonGroupEpidemic;

    public constructor(init?: Partial<CreateGroupEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class UpdateGroupEpidemicResponse
{
    public responseStatus: ResponseStatus;
    public groupEpidemic: PersonGroupEpidemic;

    public constructor(init?: Partial<UpdateGroupEpidemicResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupResponse
{
    public responseStatus: ResponseStatus;
    public group: PersonGroup;

    public constructor(init?: Partial<GetGroupResponse>) { (Object as any).assign(this, init); }
}

export class CreateGroupResponse
{
    public responseStatus: ResponseStatus;
    public group: PersonGroup;

    public constructor(init?: Partial<CreateGroupResponse>) { (Object as any).assign(this, init); }
}

export class UpdateGroupResponse
{
    public responseStatus: ResponseStatus;
    public group: PersonGroup;

    public constructor(init?: Partial<UpdateGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupMemberInviteResponse
{
    public responseStatus: ResponseStatus;
    public memberInvites: PersonGroupMemberInvite[];

    public constructor(init?: Partial<GetGroupMemberInviteResponse>) { (Object as any).assign(this, init); }
}

export class AcceptGroupMemberInviteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AcceptGroupMemberInviteResponse>) { (Object as any).assign(this, init); }
}

export class DeleteGroupMemberInviteResponse
{
    public responseStatus: ResponseStatus;
    public deletedInvitePersonGroupId: number;

    public constructor(init?: Partial<DeleteGroupMemberInviteResponse>) { (Object as any).assign(this, init); }
}

export class CreateGroupMemberInviteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateGroupMemberInviteResponse>) { (Object as any).assign(this, init); }
}

export class DeleteGroupMemberResponse
{
    public responseStatus: ResponseStatus;
    public group: PersonGroup;

    public constructor(init?: Partial<DeleteGroupMemberResponse>) { (Object as any).assign(this, init); }
}

export class GetImportLogResponse
{
    public responseStatus: ResponseStatus;
    public importLog: ImportLog;

    public constructor(init?: Partial<GetImportLogResponse>) { (Object as any).assign(this, init); }
}

export class GetInfectedPremiseResponse
{
    public responseStatus: ResponseStatus;
    public infectedPremise: InfectedPremise;

    public constructor(init?: Partial<GetInfectedPremiseResponse>) { (Object as any).assign(this, init); }
}

export class CreateInfectedPremiseResponse
{
    public responseStatus: ResponseStatus;
    public infectedPremise: InfectedPremise;

    public constructor(init?: Partial<CreateInfectedPremiseResponse>) { (Object as any).assign(this, init); }
}

export class UpdateInfectedPremiseResponse
{
    public responseStatus: ResponseStatus;
    public infectedPremise: InfectedPremise;

    public constructor(init?: Partial<UpdateInfectedPremiseResponse>) { (Object as any).assign(this, init); }
}

export class GetInstrumentResponse
{
    public responseStatus: ResponseStatus;
    public instrument: Instrument;

    public constructor(init?: Partial<GetInstrumentResponse>) { (Object as any).assign(this, init); }
}

export class CreateInstrumentResponse
{
    public responseStatus: ResponseStatus;
    public instrument: Instrument;

    public constructor(init?: Partial<CreateInstrumentResponse>) { (Object as any).assign(this, init); }
}

export class UpdateInstrumentResponse
{
    public responseStatus: ResponseStatus;
    public instrument: Instrument;

    public constructor(init?: Partial<UpdateInstrumentResponse>) { (Object as any).assign(this, init); }
}

export class DeleteInstrumentResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteInstrumentResponse>) { (Object as any).assign(this, init); }
}

export class GetLibraryStrategyResponse
{
    public responseStatus: ResponseStatus;
    public libraryStrategy: LibraryStrategy;

    public constructor(init?: Partial<GetLibraryStrategyResponse>) { (Object as any).assign(this, init); }
}

export class CreateLibraryStrategyResponse
{
    public responseStatus: ResponseStatus;
    public libraryStrategy: LibraryStrategy;

    public constructor(init?: Partial<CreateLibraryStrategyResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLibraryStrategyResponse
{
    public responseStatus: ResponseStatus;
    public libraryStrategy: LibraryStrategy;

    public constructor(init?: Partial<UpdateLibraryStrategyResponse>) { (Object as any).assign(this, init); }
}

export class DeleteLibraryStrategyResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteLibraryStrategyResponse>) { (Object as any).assign(this, init); }
}

export class GetLivestockLocationResponse
{
    public responseStatus: ResponseStatus;
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<GetLivestockLocationResponse>) { (Object as any).assign(this, init); }
}

export class CreateLivestockLocationResponse
{
    public responseStatus: ResponseStatus;
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<CreateLivestockLocationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLivestockLocationResponse
{
    public responseStatus: ResponseStatus;
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<UpdateLivestockLocationResponse>) { (Object as any).assign(this, init); }
}

export class GetLivestockResponse
{
    public responseStatus: ResponseStatus;
    public livestock: Livestock;

    public constructor(init?: Partial<GetLivestockResponse>) { (Object as any).assign(this, init); }
}

export class CreateLivestockResponse
{
    public responseStatus: ResponseStatus;
    public livestock: Livestock;

    public constructor(init?: Partial<CreateLivestockResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLivestockResponse
{
    public responseStatus: ResponseStatus;
    public livestock: Livestock;

    public constructor(init?: Partial<UpdateLivestockResponse>) { (Object as any).assign(this, init); }
}

export class DeleteLivestockResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteLivestockResponse>) { (Object as any).assign(this, init); }
}

export class GetLivestockDefaultResponse
{
    public responseStatus: ResponseStatus;
    public livestockDefault: Livestock;

    public constructor(init?: Partial<GetLivestockDefaultResponse>) { (Object as any).assign(this, init); }
}

export class CreateLivestockDefaultResponse
{
    public responseStatus: ResponseStatus;
    public livestockDefault: Livestock;

    public constructor(init?: Partial<CreateLivestockDefaultResponse>) { (Object as any).assign(this, init); }
}

export class UpdateLivestockDefaultResponse
{
    public responseStatus: ResponseStatus;
    public livestockDefault: Livestock;

    public constructor(init?: Partial<UpdateLivestockDefaultResponse>) { (Object as any).assign(this, init); }
}

export class DeleteLivestockDefaultResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteLivestockDefaultResponse>) { (Object as any).assign(this, init); }
}

export class GetMaterialResponse
{
    public responseStatus: ResponseStatus;
    public material: Material;

    public constructor(init?: Partial<GetMaterialResponse>) { (Object as any).assign(this, init); }
}

export class CreateMaterialResponse
{
    public responseStatus: ResponseStatus;
    public material: Material;

    public constructor(init?: Partial<CreateMaterialResponse>) { (Object as any).assign(this, init); }
}

export class UpdateMaterialResponse
{
    public responseStatus: ResponseStatus;
    public material: Material;

    public constructor(init?: Partial<UpdateMaterialResponse>) { (Object as any).assign(this, init); }
}

export class DeleteMaterialResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteMaterialResponse>) { (Object as any).assign(this, init); }
}

export class MaxUploadResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MaxUploadResponse>) { (Object as any).assign(this, init); }
}

export class GetMSAResponse
{
    public responseStatus: ResponseStatus;
    public msa: MSA;

    public constructor(init?: Partial<GetMSAResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobMSAResponse
{
    public msaJob: ApiJobMSA;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateJobMSAResponse>) { (Object as any).assign(this, init); }
}

export class DeleteJobMSAResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteJobMSAResponse>) { (Object as any).assign(this, init); }
}

export class GetMSAJobSequenceParamsResponse
{
    public sequences: ApiJobMSASequenceParam[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetMSAJobSequenceParamsResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobGenomicNetworkMSAResponse
{
    public genomicNetworkJob: ApiJobGenomicNetwork;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateJobGenomicNetworkMSAResponse>) { (Object as any).assign(this, init); }
}

export class GetParcelResponse
{
    public responseStatus: ResponseStatus;
    public parcel: Parcel;

    public constructor(init?: Partial<GetParcelResponse>) { (Object as any).assign(this, init); }
}

export class CreateParcelResponse
{
    public responseStatus: ResponseStatus;
    public parcel: Parcel;

    public constructor(init?: Partial<CreateParcelResponse>) { (Object as any).assign(this, init); }
}

export class UpdateParcelResponse
{
    public responseStatus: ResponseStatus;
    public parcel: Parcel;

    public constructor(init?: Partial<UpdateParcelResponse>) { (Object as any).assign(this, init); }
}

export class RequestPasswordResetResponse
{
    public email: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RequestPasswordResetResponse>) { (Object as any).assign(this, init); }
}

export class ResetPasswordResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ResetPasswordResponse>) { (Object as any).assign(this, init); }
}

export class GetPathogenResponse
{
    public responseStatus: ResponseStatus;
    public pathogen: Pathogen;

    public constructor(init?: Partial<GetPathogenResponse>) { (Object as any).assign(this, init); }
}

export class CreatePathogenResponse
{
    public responseStatus: ResponseStatus;
    public pathogen: Pathogen;

    public constructor(init?: Partial<CreatePathogenResponse>) { (Object as any).assign(this, init); }
}

export class UpdatePathogenResponse
{
    public responseStatus: ResponseStatus;
    public pathogen: Pathogen;

    public constructor(init?: Partial<UpdatePathogenResponse>) { (Object as any).assign(this, init); }
}

export class DeletePathogenResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeletePathogenResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthenticatedPersonResponse
{
    public responseStatus: ResponseStatus;
    public person: AuthenticatedPerson;

    public constructor(init?: Partial<GetAuthenticatedPersonResponse>) { (Object as any).assign(this, init); }
}

export class GetAuthenticatedPersonGroupInvitesResponse
{
    public responseStatus: ResponseStatus;
    public invites: PersonGroupMemberInvite[];

    public constructor(init?: Partial<GetAuthenticatedPersonGroupInvitesResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;

    public constructor(init?: Partial<GetPersonResponse>) { (Object as any).assign(this, init); }
}

export class UpdatePersonResponse
{
    public responseStatus: ResponseStatus;
    public person: Person;

    public constructor(init?: Partial<UpdatePersonResponse>) { (Object as any).assign(this, init); }
}

export class GetPlatformResponse
{
    public responseStatus: ResponseStatus;
    public platform: Platform;

    public constructor(init?: Partial<GetPlatformResponse>) { (Object as any).assign(this, init); }
}

export class CreatePlatformResponse
{
    public responseStatus: ResponseStatus;
    public platform: Platform;

    public constructor(init?: Partial<CreatePlatformResponse>) { (Object as any).assign(this, init); }
}

export class UpdatePlatformResponse
{
    public responseStatus: ResponseStatus;
    public platform: Platform;

    public constructor(init?: Partial<UpdatePlatformResponse>) { (Object as any).assign(this, init); }
}

export class DeletePlatformResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeletePlatformResponse>) { (Object as any).assign(this, init); }
}

export class GetRoleResponse
{
    public responseStatus: ResponseStatus;
    public role: Role;

    public constructor(init?: Partial<GetRoleResponse>) { (Object as any).assign(this, init); }
}

export class AssignRoleResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRoleResponse>) { (Object as any).assign(this, init); }
}

export class UnassignRoleResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnassignRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetSequenceResponse
{
    public responseStatus: ResponseStatus;
    public sequence: Sequence;

    public constructor(init?: Partial<GetSequenceResponse>) { (Object as any).assign(this, init); }
}

export class GetConsensusSequenceResponse
{
    public responseStatus: ResponseStatus;
    public consensusSequence: ConsensusSequence;

    public constructor(init?: Partial<GetConsensusSequenceResponse>) { (Object as any).assign(this, init); }
}

export class CreateSequenceResponse
{
    public responseStatus: ResponseStatus;
    public sequence: Sequence;

    public constructor(init?: Partial<CreateSequenceResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSequenceResponse
{
    public responseStatus: ResponseStatus;
    public sequence: Sequence;

    public constructor(init?: Partial<UpdateSequenceResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAssemblyQAResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateAssemblyQAResponse>) { (Object as any).assign(this, init); }
}

export class UpdateAnnotationQAResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateAnnotationQAResponse>) { (Object as any).assign(this, init); }
}

export class GetFastAFastQMetadataResponse
{
    public metadata: FastAFastQMetadata;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFastAFastQMetadataResponse>) { (Object as any).assign(this, init); }
}

export class GetAnnotationDataResponse
{
    public annotationData: Object;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAnnotationDataResponse>) { (Object as any).assign(this, init); }
}

export class GetAssemblyDataResponse
{
    public assemblyData: Object;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAssemblyDataResponse>) { (Object as any).assign(this, init); }
}

export class GetJobSequenceRetrievalResponse
{
    public retrievalJob: ApiJobSequenceRetrieval;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetJobSequenceRetrievalResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobSequenceRetrievalResponse
{
    public retrievalJob: ApiJobSequenceRetrieval;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateJobSequenceRetrievalResponse>) { (Object as any).assign(this, init); }
}

export class CreateJobAssemblyResponse
{
    public responseStatus: ResponseStatus;
    public assemblyJob: ApiJobAssembly;

    public constructor(init?: Partial<CreateJobAssemblyResponse>) { (Object as any).assign(this, init); }
}

export class GetSpeciesResponse
{
    public responseStatus: ResponseStatus;
    public species: Species;

    public constructor(init?: Partial<GetSpeciesResponse>) { (Object as any).assign(this, init); }
}

export class CreateSpeciesResponse
{
    public responseStatus: ResponseStatus;
    public species: Species;

    public constructor(init?: Partial<CreateSpeciesResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSpeciesResponse
{
    public responseStatus: ResponseStatus;
    public species: Species;

    public constructor(init?: Partial<UpdateSpeciesResponse>) { (Object as any).assign(this, init); }
}

export class GetSpecimenResponse
{
    public responseStatus: ResponseStatus;
    public specimen: Specimen;

    public constructor(init?: Partial<GetSpecimenResponse>) { (Object as any).assign(this, init); }
}

export class CreateSpecimenResponse
{
    public responseStatus: ResponseStatus;
    public specimen: Specimen;

    public constructor(init?: Partial<CreateSpecimenResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSpecimenResponse
{
    public responseStatus: ResponseStatus;
    public specimen: Specimen;

    public constructor(init?: Partial<UpdateSpecimenResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSpecimenResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteSpecimenResponse>) { (Object as any).assign(this, init); }
}

export class GetSpreadWorkflowJobResponse
{
    public job: SpreadWorkflowJob;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSpreadWorkflowJobResponse>) { (Object as any).assign(this, init); }
}

export class GetSpreadWorkflowJobsResponse
{
    public jobs: SpreadWorkflowJob[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSpreadWorkflowJobsResponse>) { (Object as any).assign(this, init); }
}

export class DispatchSpreadWorkflowJobResponse
{
    public job: SpreadWorkflowJob;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DispatchSpreadWorkflowJobResponse>) { (Object as any).assign(this, init); }
}

export class RefreshSpreadWorkflowJobStatusResponse
{
    public job: SpreadWorkflowJob;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RefreshSpreadWorkflowJobStatusResponse>) { (Object as any).assign(this, init); }
}

export class DispatchSpreadWorkflowJobResultDownloadResponse
{
    public job: SpreadWorkflowJob;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DispatchSpreadWorkflowJobResultDownloadResponse>) { (Object as any).assign(this, init); }
}

export class ProcessSpreadWorkflowJobResultResponse
{
    public job: SpreadWorkflowJob;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcessSpreadWorkflowJobResultResponse>) { (Object as any).assign(this, init); }
}

export class GetSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public subClassification: SubClassification;

    public constructor(init?: Partial<GetSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class CreateSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public subClassification: SubClassification;

    public constructor(init?: Partial<CreateSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSubClassificationResponse
{
    public responseStatus: ResponseStatus;
    public subClassification: SubClassification;

    public constructor(init?: Partial<UpdateSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSubClassificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteSubClassificationResponse>) { (Object as any).assign(this, init); }
}

export class GetSubClusterResponse
{
    public responseStatus: ResponseStatus;
    public subCluster: SubCluster;

    public constructor(init?: Partial<GetSubClusterResponse>) { (Object as any).assign(this, init); }
}

export class CreateSubClusterResponse
{
    public responseStatus: ResponseStatus;
    public subCluster: SubCluster;

    public constructor(init?: Partial<CreateSubClusterResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSubClusterResponse
{
    public responseStatus: ResponseStatus;
    public subCluster: SubCluster;

    public constructor(init?: Partial<UpdateSubClusterResponse>) { (Object as any).assign(this, init); }
}

export class GetSubNationalResponse
{
    public responseStatus: ResponseStatus;
    public subNational: SubNational;

    public constructor(init?: Partial<GetSubNationalResponse>) { (Object as any).assign(this, init); }
}

export class CreateSubNationalResponse
{
    public responseStatus: ResponseStatus;
    public subNational: SubNational;

    public constructor(init?: Partial<CreateSubNationalResponse>) { (Object as any).assign(this, init); }
}

export class UpdateSubNationalResponse
{
    public responseStatus: ResponseStatus;
    public subNational: SubNational;

    public constructor(init?: Partial<UpdateSubNationalResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSubNationalResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteSubNationalResponse>) { (Object as any).assign(this, init); }
}

export class GetAnalysisJobTapmResponse
{
    public analysisJob: AnalysisJobTapm;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAnalysisJobTapmResponse>) { (Object as any).assign(this, init); }
}

export class DeleteAnalysisJobTapmResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteAnalysisJobTapmResponse>) { (Object as any).assign(this, init); }
}

export class ProcessAnalysisJobTapmResultResponse
{
    public analysisJob: AnalysisJobTapm;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcessAnalysisJobTapmResultResponse>) { (Object as any).assign(this, init); }
}

export class GetAnalysisJobTapmResultResponse
{
    public analysisTapmId: number;
    public analysisStartDate: string;
    public analysisEndDate: string;
    public resultGrid: ResultGridCell[];
    public results: AnalysisJobWindDispersionResult[];
    public analysisResultType: WindDispersionResultType;
    public analysisResultTimeScale: AnalysisResultTimeScale;
    public resultGlobalMax: number;
    public minimumCutOff: number;
    public latLngBounds: LatLngBounds;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAnalysisJobTapmResultResponse>) { (Object as any).assign(this, init); }
}

export class CreateAnalysisJobTapmResponse
{
    public analysisJob: AnalysisJobTapm;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CreateAnalysisJobTapmResponse>) { (Object as any).assign(this, init); }
}

export class CancelAnalysisJobTapmResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CancelAnalysisJobTapmResponse>) { (Object as any).assign(this, init); }
}

export class RefreshTapmDatasetsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RefreshTapmDatasetsResponse>) { (Object as any).assign(this, init); }
}

export class GetTemplateResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTemplateResponse>) { (Object as any).assign(this, init); }
}

export class MailRenderedTemplateResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MailRenderedTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetTissueResponse
{
    public responseStatus: ResponseStatus;
    public tissue: Tissue;

    public constructor(init?: Partial<GetTissueResponse>) { (Object as any).assign(this, init); }
}

export class CreateTissueResponse
{
    public responseStatus: ResponseStatus;
    public tissue: Tissue;

    public constructor(init?: Partial<CreateTissueResponse>) { (Object as any).assign(this, init); }
}

export class UpdateTissueResponse
{
    public responseStatus: ResponseStatus;
    public tissue: Tissue;

    public constructor(init?: Partial<UpdateTissueResponse>) { (Object as any).assign(this, init); }
}

export class DeleteTissueResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteTissueResponse>) { (Object as any).assign(this, init); }
}

export class GetTooltipsResponse
{
    public responseStatus: ResponseStatus;
    public tooltips: { [index: string]: Tooltip; };

    public constructor(init?: Partial<GetTooltipsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=8)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/areaDate/{id}", "GET")
export class GetAreaDate implements IReturn<GetAreaDateResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetAreaDate>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAreaDateResponse(); }
    public getTypeName() { return 'GetAreaDate'; }
}

// @Route("/api/areaDate", "POST")
export class CreateAreaDate implements IReturn<CreateAreaDateResponse>, IPost
{
    public areaDate: AreaDate;

    public constructor(init?: Partial<CreateAreaDate>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAreaDateResponse(); }
    public getTypeName() { return 'CreateAreaDate'; }
}

// @Route("/api/areaDate/{id}", "PUT")
export class UpdateAreaDate implements IReturn<UpdateAreaDateResponse>, IPut
{
    public areaDate: AreaDate;
    public noObject: string;

    public constructor(init?: Partial<UpdateAreaDate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAreaDateResponse(); }
    public getTypeName() { return 'UpdateAreaDate'; }
}

// @Route("/api/query/areaDate", "GET")
export class QueryAreaDates extends QueryDb_2<AreaDateDO, QueryAreaDate> implements IReturn<QueryResponse<QueryAreaDate>>
{
    public epidemicId: number;
    public clusterId: number;
    public infectedPremiseId: number;
    public areaId: number;

    public constructor(init?: Partial<QueryAreaDates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryAreaDate>(); }
    public getTypeName() { return 'QueryAreaDates'; }
}

// @Route("/api/areaDateType/{id}", "GET")
export class GetAreaDateType implements IReturn<GetAreaDateTypeResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetAreaDateType>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAreaDateTypeResponse(); }
    public getTypeName() { return 'GetAreaDateType'; }
}

// @Route("/api/areaDateType", "POST")
export class CreateAreaDateType implements IReturn<CreateAreaDateTypeResponse>, IPost
{
    public areaDateType: AreaDateType;

    public constructor(init?: Partial<CreateAreaDateType>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAreaDateTypeResponse(); }
    public getTypeName() { return 'CreateAreaDateType'; }
}

// @Route("/api/areaDateType/{id}", "PUT")
export class UpdateAreaDateType implements IReturn<UpdateAreaDateTypeResponse>, IPut
{
    public areaDateType: AreaDateType;
    public noObject: string;

    public constructor(init?: Partial<UpdateAreaDateType>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAreaDateTypeResponse(); }
    public getTypeName() { return 'UpdateAreaDateType'; }
}

// @Route("/api/areaDateType/{areaDateTypeId}", "DELETE")
export class DeleteAreaDateType implements IReturn<DeleteAreaDateTypeResponse>, IDelete
{
    public areaDateTypeId: number;
    public deleteType: boolean;

    public constructor(init?: Partial<DeleteAreaDateType>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAreaDateTypeResponse(); }
    public getTypeName() { return 'DeleteAreaDateType'; }
}

// @Route("/api/query/areaDateType", "GET")
export class QueryAreaDateTypes extends QueryDb_2<AreaDateTypeDO, QueryAreaDateType> implements IReturn<QueryResponse<QueryAreaDateType>>
{
    public epidemicId: number;
    public isStartOfInfectiousPeriodDate: boolean;
    public isEndOfInfectiousPeriodDate: boolean;

    public constructor(init?: Partial<QueryAreaDateTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryAreaDateType>(); }
    public getTypeName() { return 'QueryAreaDateTypes'; }
}

// @Route("/api/areaLivestockCount/{id}", "GET")
export class GetAreaLivestockCount implements IReturn<GetAreaLivestockCountResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetAreaLivestockCount>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAreaLivestockCountResponse(); }
    public getTypeName() { return 'GetAreaLivestockCount'; }
}

// @Route("/api/areaLivestockCount", "POST")
export class CreateAreaLivestockCount implements IReturn<CreateAreaLivestockCountResponse>, IPost
{
    public areaLivestockCount: AreaLivestockCount;

    public constructor(init?: Partial<CreateAreaLivestockCount>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAreaLivestockCountResponse(); }
    public getTypeName() { return 'CreateAreaLivestockCount'; }
}

// @Route("/api/areaLivestockCount/{id}", "PUT")
export class UpdateAreaLivestockCount implements IReturn<UpdateAreaLivestockCountResponse>, IPut
{
    public areaLivestockCount: AreaLivestockCount;

    public constructor(init?: Partial<UpdateAreaLivestockCount>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAreaLivestockCountResponse(); }
    public getTypeName() { return 'UpdateAreaLivestockCount'; }
}

// @Route("/api/query/areaLivestockCount", "GET")
export class QueryAreaLivestockCounts extends QueryDb_2<AreaLivestockCountView, AreaLivestockCount> implements IReturn<QueryResponse<AreaLivestockCount>>
{
    public areaId: number;

    public constructor(init?: Partial<QueryAreaLivestockCounts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<AreaLivestockCount>(); }
    public getTypeName() { return 'QueryAreaLivestockCounts'; }
}

// @Route("/api/area/{id}", "GET")
export class GetArea implements IReturn<GetAreaResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetArea>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAreaResponse(); }
    public getTypeName() { return 'GetArea'; }
}

// @Route("/api/area/{id}", "PUT")
export class UpdateArea implements IReturn<UpdateAreaResponse>, IPut
{
    public area: Area;

    public constructor(init?: Partial<UpdateArea>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAreaResponse(); }
    public getTypeName() { return 'UpdateArea'; }
}

// @Route("/api/query/area", "GET")
export class QueryAreas extends QueryDb_2<AreaDO, QueryArea> implements IReturn<QueryResponse<QueryArea>>
{

    public constructor(init?: Partial<QueryAreas>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryArea>(); }
    public getTypeName() { return 'QueryAreas'; }
}

// @Route("/api/import/epidemic/{epidemicId}/preparse/{importType}", "GET")
export class PreparseImportFile implements IReturn<PreparseImportFileResponse>, IGet
{
    public epidemicId: number;
    public importType: ImportType;
    public temporaryFileToken: string;
    public temporaryFileExtensionType: FileExtensionType;

    public constructor(init?: Partial<PreparseImportFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new PreparseImportFileResponse(); }
    public getTypeName() { return 'PreparseImportFile'; }
}

// @Route("/api/import/epidemic/{epidemicId}/date", "POST")
export class ImportDates implements IReturn<ImportDatesResponse>, IGet
{
    public epidemicId: number;
    public temporaryFileToken: string;
    public dateTimeFormat: string;
    public mappedHeadersDictionary: { [index: string]: string; };
    public importIpKey: ImportIpKey;

    public constructor(init?: Partial<ImportDates>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImportDatesResponse(); }
    public getTypeName() { return 'ImportDates'; }
}

// @Route("/api/import/epidemic/{epidemicId}/spatial", "POST")
export class ImportSpatial implements IReturn<ImportSpatialResponse>, IGet
{
    public epidemicId: number;
    public temporaryFileToken: string;
    public temporaryFileExtension: FileExtensionType;
    public mappedHeadersDictionary: { [index: string]: string; };
    public importIpKey: ImportIpKey;

    public constructor(init?: Partial<ImportSpatial>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImportSpatialResponse(); }
    public getTypeName() { return 'ImportSpatial'; }
}

// @Route("/api/import/epidemic/{epidemicId}/livestock", "POST")
export class ImportLivestock implements IReturn<ImportLivestockResponse>, IGet
{
    public epidemicId: number;
    public temporaryFileToken: string;
    public mappedHeadersDictionary: { [index: string]: string; };
    public importIpKey: ImportIpKey;

    public constructor(init?: Partial<ImportLivestock>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImportLivestockResponse(); }
    public getTypeName() { return 'ImportLivestock'; }
}

// @Route("/api/import/epidemic/{epidemicId}/epidemic", "POST")
export class ImportEpidemic implements IReturn<ImportEpidemicResponse>, IGet
{
    public epidemicId: number;
    public temporaryFileToken: string;
    public dateTimeFormat: string;
    public mappedHeadersDictionary: { [index: string]: string; };
    public importIpKey: ImportIpKey;

    public constructor(init?: Partial<ImportEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImportEpidemicResponse(); }
    public getTypeName() { return 'ImportEpidemic'; }
}

// @Route("/api/import/epidemic/{epidemicId}", "POST")
export class ImportHierarchy implements IReturn<ImportHierarchyResponse>, IGet
{
    public epidemicId: number;
    public temporaryFileToken: string;
    public mappedHeadersDictionary: { [index: string]: string; };
    public importIpKey: ImportIpKey;

    public constructor(init?: Partial<ImportHierarchy>) { (Object as any).assign(this, init); }
    public createResponse() { return new ImportHierarchyResponse(); }
    public getTypeName() { return 'ImportHierarchy'; }
}

// @Route("/api/classification/{id}", "GET")
export class GetClassification implements IReturn<GetClassificationResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClassificationResponse(); }
    public getTypeName() { return 'GetClassification'; }
}

// @Route("/api/classification", "POST")
export class CreateClassification implements IReturn<CreateClassificationResponse>, IPost
{
    public classification: Classification;

    public constructor(init?: Partial<CreateClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateClassificationResponse(); }
    public getTypeName() { return 'CreateClassification'; }
}

// @Route("/api/classification/{id}", "PUT")
export class UpdateClassification implements IReturn<UpdateClassificationResponse>, IPut
{
    public classification: Classification;

    public constructor(init?: Partial<UpdateClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateClassificationResponse(); }
    public getTypeName() { return 'UpdateClassification'; }
}

// @Route("/api/classification/{id}", "DELETE")
export class DeleteClassification implements IReturn<DeleteClassificationResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteClassificationResponse(); }
    public getTypeName() { return 'DeleteClassification'; }
}

// @Route("/api/query/classification", "GET")
export class QueryClassifications extends QueryDb_2<ClassificationDO, Classification> implements IReturn<QueryResponse<Classification>>
{

    public constructor(init?: Partial<QueryClassifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Classification>(); }
    public getTypeName() { return 'QueryClassifications'; }
}

// @Route("/api/cluster/{id}", "GET")
export class GetCluster implements IReturn<GetClusterResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetClusterResponse(); }
    public getTypeName() { return 'GetCluster'; }
}

// @Route("/api/cluster", "POST")
export class CreateCluster implements IReturn<CreateClusterResponse>, IPost
{
    public cluster: Cluster;

    public constructor(init?: Partial<CreateCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateClusterResponse(); }
    public getTypeName() { return 'CreateCluster'; }
}

// @Route("/api/cluster/{id}", "PUT")
export class UpdateCluster implements IReturn<UpdateClusterResponse>, IPut
{
    public cluster: Cluster;

    public constructor(init?: Partial<UpdateCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateClusterResponse(); }
    public getTypeName() { return 'UpdateCluster'; }
}

// @Route("/api/query/cluster", "GET")
export class QueryClusters extends QueryDb_2<ClusterDO, QueryCluster> implements IReturn<QueryResponse<QueryCluster>>
{
    public epidemicId: number;
    public nameContains: string;

    public constructor(init?: Partial<QueryClusters>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryCluster>(); }
    public getTypeName() { return 'QueryClusters'; }
}

// @Route("/api/country/{id}", "GET")
export class GetCountry implements IReturn<GetCountryResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCountryResponse(); }
    public getTypeName() { return 'GetCountry'; }
}

// @Route("/api/country", "POST")
export class CreateCountry implements IReturn<CreateCountryResponse>, IPost
{
    public country: Country;

    public constructor(init?: Partial<CreateCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateCountryResponse(); }
    public getTypeName() { return 'CreateCountry'; }
}

// @Route("/api/country/{id}", "PUT")
export class UpdateCountry implements IReturn<UpdateCountryResponse>, IPut
{
    public country: Country;

    public constructor(init?: Partial<UpdateCountry>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateCountryResponse(); }
    public getTypeName() { return 'UpdateCountry'; }
}

// @Route("/api/query/country", "GET")
export class QueryCountries extends QueryDb_2<CountryDO, QueryCountry> implements IReturn<QueryResponse<QueryCountry>>
{

    public constructor(init?: Partial<QueryCountries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryCountry>(); }
    public getTypeName() { return 'QueryCountries'; }
}

// @Route("/api/diseaseClassification/{id}", "GET")
export class GetDiseaseClassification implements IReturn<GetDiseaseClassificationResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetDiseaseClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDiseaseClassificationResponse(); }
    public getTypeName() { return 'GetDiseaseClassification'; }
}

// @Route("/api/diseaseClassification", "POST")
export class CreateDiseaseClassification implements IReturn<CreateDiseaseClassificationResponse>, IPost
{
    public diseaseClassification: DiseaseClassification;

    public constructor(init?: Partial<CreateDiseaseClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDiseaseClassificationResponse(); }
    public getTypeName() { return 'CreateDiseaseClassification'; }
}

// @Route("/api/diseaseClassification/{id}", "PUT")
export class UpdateDiseaseClassification implements IReturn<UpdateDiseaseClassificationResponse>, IPut
{
    public diseaseClassification: DiseaseClassification;

    public constructor(init?: Partial<UpdateDiseaseClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateDiseaseClassificationResponse(); }
    public getTypeName() { return 'UpdateDiseaseClassification'; }
}

// @Route("/api/diseaseClassification/{id}", "DELETE")
export class DeleteDiseaseClassification implements IReturn<DeleteDiseaseClassificationResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteDiseaseClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteDiseaseClassificationResponse(); }
    public getTypeName() { return 'DeleteDiseaseClassification'; }
}

// @Route("/api/query/diseaseClassification", "GET")
export class QueryDiseaseClassifications extends QueryDb_2<DiseaseClassificationDO, QueryDiseaseClassification> implements IReturn<QueryResponse<QueryDiseaseClassification>>
{
    public diseaseId: number;

    public constructor(init?: Partial<QueryDiseaseClassifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryDiseaseClassification>(); }
    public getTypeName() { return 'QueryDiseaseClassifications'; }
}

// @Route("/api/disease/{id}", "GET")
export class GetDisease implements IReturn<GetDiseaseResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetDisease>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDiseaseResponse(); }
    public getTypeName() { return 'GetDisease'; }
}

// @Route("/api/disease", "POST")
export class CreateDisease implements IReturn<CreateDiseaseResponse>, IPost
{
    public disease: Disease;

    public constructor(init?: Partial<CreateDisease>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDiseaseResponse(); }
    public getTypeName() { return 'CreateDisease'; }
}

// @Route("/api/disease/{id}", "PUT")
export class UpdateDisease implements IReturn<UpdateDiseaseResponse>, IPut
{
    public disease: Disease;

    public constructor(init?: Partial<UpdateDisease>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateDiseaseResponse(); }
    public getTypeName() { return 'UpdateDisease'; }
}

// @Route("/api/query/disease", "GET")
export class QueryDiseases extends QueryDb_2<DiseaseDO, QueryDisease> implements IReturn<QueryResponse<QueryDisease>>
{

    public constructor(init?: Partial<QueryDiseases>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryDisease>(); }
    public getTypeName() { return 'QueryDiseases'; }
}

// @Route("/api/diseaseSubClassification/{id}", "GET")
export class GetDiseaseSubClassification implements IReturn<GetDiseaseSubClassificationResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetDiseaseSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDiseaseSubClassificationResponse(); }
    public getTypeName() { return 'GetDiseaseSubClassification'; }
}

// @Route("/api/diseaseSubClassification", "POST")
export class CreateDiseaseSubClassification implements IReturn<CreateDiseaseSubClassificationResponse>, IPost
{
    public diseaseSubClassification: DiseaseSubClassification;

    public constructor(init?: Partial<CreateDiseaseSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateDiseaseSubClassificationResponse(); }
    public getTypeName() { return 'CreateDiseaseSubClassification'; }
}

// @Route("/api/diseaseSubClassification/{id}", "PUT")
export class UpdateDiseaseSubClassification implements IReturn<UpdateDiseaseSubClassificationResponse>, IPut
{
    public diseaseSubClassification: DiseaseSubClassification;

    public constructor(init?: Partial<UpdateDiseaseSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateDiseaseSubClassificationResponse(); }
    public getTypeName() { return 'UpdateDiseaseSubClassification'; }
}

// @Route("/api/diseaseSubClassification/{id}", "DELETE")
export class DeleteDiseaseSubClassification implements IReturn<DeleteDiseaseSubClassificationResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteDiseaseSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteDiseaseSubClassificationResponse(); }
    public getTypeName() { return 'DeleteDiseaseSubClassification'; }
}

// @Route("/api/query/diseaseSubClassification", "GET")
export class QueryDiseaseSubClassifications extends QueryDb_2<DiseaseSubClassificationDO, QueryDiseaseSubClassification> implements IReturn<QueryResponse<QueryDiseaseSubClassification>>
{
    public diseaseClassificationId: number;

    public constructor(init?: Partial<QueryDiseaseSubClassifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryDiseaseSubClassification>(); }
    public getTypeName() { return 'QueryDiseaseSubClassifications'; }
}

// @Route("/api/enrichmentMethod/{id}", "GET")
export class GetEnrichmentMethod implements IReturn<GetEnrichmentMethodResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetEnrichmentMethod>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEnrichmentMethodResponse(); }
    public getTypeName() { return 'GetEnrichmentMethod'; }
}

// @Route("/api/enrichmentMethod", "POST")
export class CreateEnrichmentMethod implements IReturn<CreateEnrichmentMethodResponse>, IPost
{
    public enrichmentMethod: EnrichmentMethod;

    public constructor(init?: Partial<CreateEnrichmentMethod>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateEnrichmentMethodResponse(); }
    public getTypeName() { return 'CreateEnrichmentMethod'; }
}

// @Route("/api/enrichmentMethod/{id}", "PUT")
export class UpdateEnrichmentMethod implements IReturn<UpdateEnrichmentMethodResponse>, IPut
{
    public enrichmentMethod: EnrichmentMethod;

    public constructor(init?: Partial<UpdateEnrichmentMethod>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEnrichmentMethodResponse(); }
    public getTypeName() { return 'UpdateEnrichmentMethod'; }
}

// @Route("/api/enrichmentMethod/{id}", "DELETE")
export class DeleteEnrichmentMethod implements IReturn<DeleteEnrichmentMethodResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteEnrichmentMethod>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteEnrichmentMethodResponse(); }
    public getTypeName() { return 'DeleteEnrichmentMethod'; }
}

// @Route("/api/query/enrichmentMethod", "GET")
export class QueryEnrichmentMethods extends QueryDb_2<EnrichmentMethodDO, EnrichmentMethod> implements IReturn<QueryResponse<EnrichmentMethod>>
{

    public constructor(init?: Partial<QueryEnrichmentMethods>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<EnrichmentMethod>(); }
    public getTypeName() { return 'QueryEnrichmentMethods'; }
}

// @Route("/api/epidemic/{id}", "GET")
export class GetEpidemic implements IReturn<GetEpidemicResponse>, IGet
{
    public id: number;
    public includeArchived: boolean;

    public constructor(init?: Partial<GetEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEpidemicResponse(); }
    public getTypeName() { return 'GetEpidemic'; }
}

// @Route("/api/epidemic", "POST")
export class CreateEpidemic implements IReturn<CreateEpidemicResponse>, IPost
{
    public epidemic: Epidemic;

    public constructor(init?: Partial<CreateEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateEpidemicResponse(); }
    public getTypeName() { return 'CreateEpidemic'; }
}

// @Route("/api/epidemic/{id}", "PUT")
export class UpdateEpidemic implements IReturn<UpdateEpidemicResponse>, IPut
{
    public epidemic: Epidemic;

    public constructor(init?: Partial<UpdateEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateEpidemicResponse(); }
    public getTypeName() { return 'UpdateEpidemic'; }
}

// @Route("/api/epidemic/{id}/archive", "PUT")
export class ArchiveEpidemic implements IReturn<ArchiveEpidemicResponse>, IPut
{
    public id: number;

    public constructor(init?: Partial<ArchiveEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new ArchiveEpidemicResponse(); }
    public getTypeName() { return 'ArchiveEpidemic'; }
}

// @Route("/api/query/epidemic", "GET")
export class QueryEpidemics extends QueryDb_2<EpidemicDO, QueryEpidemic> implements IReturn<QueryResponse<QueryEpidemic>>
{
    public includeArchived: boolean;
    public canAssignRoleId: number;

    public constructor(init?: Partial<QueryEpidemics>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryEpidemic>(); }
    public getTypeName() { return 'QueryEpidemics'; }
}

// @Route("/api/query/epidemic/group", "GET")
export class QueryPersonGroupEpidemics extends QueryDb_2<PersonGroupEpidemicDO, QueryPersonGroupEpidemic> implements IReturn<QueryResponse<QueryPersonGroupEpidemic>>
{
    public epidemicId: number;
    public includeArchived: boolean;

    public constructor(init?: Partial<QueryPersonGroupEpidemics>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryPersonGroupEpidemic>(); }
    public getTypeName() { return 'QueryPersonGroupEpidemics'; }
}

// @Route("/api/excretion/{ApiJobId}/input", "GET")
export class GetExcretionInputParams implements IReturn<GetExcretionInputParamsResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<GetExcretionInputParams>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetExcretionInputParamsResponse(); }
    public getTypeName() { return 'GetExcretionInputParams'; }
}

// @Route("/api/excretion", "POST")
export class CreateJobExcretion implements IReturn<CreateJobExcretionResponse>, IPost
{
    public epidemicId: number;
    public name: string;
    public model: Model;
    public duration: number;
    public i2lInterval: number;
    public i2rInterval: number;
    public r2cInterval: number;
    public ignoreCulling: boolean;

    public constructor(init?: Partial<CreateJobExcretion>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobExcretionResponse(); }
    public getTypeName() { return 'CreateJobExcretion'; }
}

// @Route("/api/excretion/{ApiJobId}", "DELETE")
export class DeleteJobExcretion implements IReturn<DeleteJobExcretionResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<DeleteJobExcretion>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteJobExcretionResponse(); }
    public getTypeName() { return 'DeleteJobExcretion'; }
}

// @Route("/api/excretion/{ApiJobId}/cancel", "PUT")
export class CancelJobExcretion implements IReturn<CancelJobExcretionResponse>, IPost
{
    public apiJobId: number;

    public constructor(init?: Partial<CancelJobExcretion>) { (Object as any).assign(this, init); }
    public createResponse() { return new CancelJobExcretionResponse(); }
    public getTypeName() { return 'CancelJobExcretion'; }
}

// @Route("/api/query/excretion", "GET")
export class QueryJobExcretion extends QueryDb_2<ApiJobExcretionDO, ApiJobExcretion> implements IReturn<QueryResponse<ApiJobExcretion>>
{
    public epidemicId: number;
    public status: string;

    public constructor(init?: Partial<QueryJobExcretion>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<ApiJobExcretion>(); }
    public getTypeName() { return 'QueryJobExcretion'; }
}

// @Route("/file/excretion/{ApiJobId}/result", "GET")
export class GetExcretionJobResultFile implements IReturn<Blob>
{
    public apiJobId: number;

    public constructor(init?: Partial<GetExcretionJobResultFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetExcretionJobResultFile'; }
}

// @Route("/file/excretion/{ApiJobId}/image", "GET")
export class GetExcretionImage implements IReturn<Blob>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<GetExcretionImage>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetExcretionImage'; }
}

// @Route("/api/field/{id}", "GET")
export class GetField implements IReturn<GetFieldResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetField>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFieldResponse(); }
    public getTypeName() { return 'GetField'; }
}

// @Route("/api/field", "POST")
export class CreateField implements IReturn<CreateFieldResponse>, IPost
{
    public field: Field;

    public constructor(init?: Partial<CreateField>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateFieldResponse(); }
    public getTypeName() { return 'CreateField'; }
}

// @Route("/api/field/{id}", "PUT")
export class UpdateField implements IReturn<UpdateFieldResponse>, IPut
{
    public field: Field;

    public constructor(init?: Partial<UpdateField>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateFieldResponse(); }
    public getTypeName() { return 'UpdateField'; }
}

// @Route("/api/query/field", "GET")
export class QueryFields extends QueryDb_2<AreaDO, Field> implements IReturn<QueryResponse<Field>>
{
    public clusterId: number;
    public epidemicId: number;
    public infectedPremiseId: number;
    public parcelId: number;
    public subClusterId: number;

    public constructor(init?: Partial<QueryFields>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Field>(); }
    public getTypeName() { return 'QueryFields'; }
}

// @Route("/file/temporary", "POST")
export class PostTemporaryFiles implements IReturn<PostTemporaryFilesResponse>
{

    public constructor(init?: Partial<PostTemporaryFiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostTemporaryFilesResponse(); }
    public getTypeName() { return 'PostTemporaryFiles'; }
}

// @Route("/file/{id}", "GET")
export class GetFile implements IReturn<Blob>
{
    public id: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/api/genomic/network", "POST")
export class CreateJobGenomicNetwork implements IReturn<CreateJobGenomicNetworkResponse>, IPost
{
    public allInfectedPremisesOnEpidemicId: number;
    public infectedPremiseIds: number[];
    public startDateTypeId: number;

    public constructor(init?: Partial<CreateJobGenomicNetwork>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobGenomicNetworkResponse(); }
    public getTypeName() { return 'CreateJobGenomicNetwork'; }
}

// @Route("/api/genomic/network/{GenomicNetworkJobId}", "DELETE")
export class DeleteJobGenomicNetwork implements IReturn<DeleteJobGenomicNetworkResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<DeleteJobGenomicNetwork>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteJobGenomicNetworkResponse(); }
    public getTypeName() { return 'DeleteJobGenomicNetwork'; }
}

// @Route("/file/genomic/network/{GenomicNetworkJobId}/image/direct", "GET")
export class GetGenomicNetworkDirectTransmissionsImage implements IReturn<Blob>, IGet
{
    public genomicNetworkJobId: number;

    public constructor(init?: Partial<GetGenomicNetworkDirectTransmissionsImage>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetGenomicNetworkDirectTransmissionsImage'; }
}

// @Route("/file/genomic/network/{GenomicNetworkJobId}/image/indirect", "GET")
export class GetGenomicNetworkIndirectTransmissionsImage implements IReturn<Blob>, IGet
{
    public genomicNetworkJobId: number;

    public constructor(init?: Partial<GetGenomicNetworkIndirectTransmissionsImage>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetGenomicNetworkIndirectTransmissionsImage'; }
}

// @Route("/file/genomic/network/{GenomicNetworkJobId}/all", "GET")
export class GetAllGenomicNetworkFiles implements IReturn<Blob>, IGet
{
    public genomicNetworkJobId: number;

    public constructor(init?: Partial<GetAllGenomicNetworkFiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetAllGenomicNetworkFiles'; }
}

// @Route("/api/genomic/network/{GenomicNetworkJobId}/sequence", "GET")
export class GetGenomicNetworkSequenceParams implements IReturn<GetGenomicNetworkSequenceParamsResponse>, IGet
{
    public genomicNetworkJobId: number;

    public constructor(init?: Partial<GetGenomicNetworkSequenceParams>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGenomicNetworkSequenceParamsResponse(); }
    public getTypeName() { return 'GetGenomicNetworkSequenceParams'; }
}

// @Route("/api/query/analysis", "GET")
export class QueryJobGenomicNetwork extends QueryDb_2<ApiJobGenomicNetworkDO, ApiJobGenomicNetwork> implements IReturn<QueryResponse<ApiJobGenomicNetwork>>
{
    public epidemicId: number;

    public constructor(init?: Partial<QueryJobGenomicNetwork>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<ApiJobGenomicNetwork>(); }
    public getTypeName() { return 'QueryJobGenomicNetwork'; }
}

// @Route("/api/genomic", "POST")
export class CreateGenomicSyncJob implements IReturn<CreateGenomicSyncJobResponse>, IPost
{

    public constructor(init?: Partial<CreateGenomicSyncJob>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGenomicSyncJobResponse(); }
    public getTypeName() { return 'CreateGenomicSyncJob'; }
}

// @Route("/api/groupEpidemic/{id}", "GET")
export class GetGroupEpidemic implements IReturn<GetGroupEpidemicResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetGroupEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupEpidemicResponse(); }
    public getTypeName() { return 'GetGroupEpidemic'; }
}

// @Route("/api/groupEpidemic", "POST")
export class CreateGroupEpidemic implements IReturn<CreateGroupEpidemicResponse>, IPost
{
    public groupEpidemic: PersonGroupEpidemic;

    public constructor(init?: Partial<CreateGroupEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGroupEpidemicResponse(); }
    public getTypeName() { return 'CreateGroupEpidemic'; }
}

// @Route("/api/groupEpidemic/{id}", "PUT")
export class UpdateGroupEpidemic implements IReturn<UpdateGroupEpidemicResponse>, IPut
{
    public groupEpidemic: PersonGroupEpidemic;

    public constructor(init?: Partial<UpdateGroupEpidemic>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateGroupEpidemicResponse(); }
    public getTypeName() { return 'UpdateGroupEpidemic'; }
}

// @Route("/api/query/groupEpidemic/{personGroupId}", "GET")
export class QueryGroupEpidemics extends QueryDb_2<PersonGroupEpidemicDO, QueryPersonGroupEpidemic> implements IReturn<QueryResponse<QueryPersonGroupEpidemic>>
{
    public personGroupId: number;
    public epidemicId: number;

    public constructor(init?: Partial<QueryGroupEpidemics>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryPersonGroupEpidemic>(); }
    public getTypeName() { return 'QueryGroupEpidemics'; }
}

// @Route("/api/group/{id}", "GET")
export class GetGroup implements IReturn<GetGroupResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupResponse(); }
    public getTypeName() { return 'GetGroup'; }
}

// @Route("/api/group", "POST")
export class CreateGroup implements IReturn<CreateGroupResponse>, IPost
{
    public group: PersonGroup;

    public constructor(init?: Partial<CreateGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGroupResponse(); }
    public getTypeName() { return 'CreateGroup'; }
}

// @Route("/api/group/{id}", "PUT")
export class UpdateGroup implements IReturn<UpdateGroupResponse>, IPut
{
    public group: PersonGroup;

    public constructor(init?: Partial<UpdateGroup>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateGroupResponse(); }
    public getTypeName() { return 'UpdateGroup'; }
}

// @Route("/api/group/{groupid}/invite", "GET")
export class GetGroupMemberInvite implements IReturn<GetGroupMemberInviteResponse>, IGet
{
    public groupId: number;

    public constructor(init?: Partial<GetGroupMemberInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupMemberInviteResponse(); }
    public getTypeName() { return 'GetGroupMemberInvite'; }
}

// @Route("/api/group/invite/{inviteId}/accept", "PUT")
export class AcceptGroupMemberInvite implements IReturn<AcceptGroupMemberInviteResponse>, IGet
{
    public inviteId: number;

    public constructor(init?: Partial<AcceptGroupMemberInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new AcceptGroupMemberInviteResponse(); }
    public getTypeName() { return 'AcceptGroupMemberInvite'; }
}

// @Route("/api/group/invite/{inviteId}", "DELETE")
export class DeleteGroupMemberInvite implements IReturn<DeleteGroupMemberInviteResponse>, IGet
{
    public inviteId: number;

    public constructor(init?: Partial<DeleteGroupMemberInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteGroupMemberInviteResponse(); }
    public getTypeName() { return 'DeleteGroupMemberInvite'; }
}

// @Route("/api/group/{groupId}/invite", "POST")
export class CreateGroupMemberInvite implements IReturn<CreateGroupMemberInviteResponse>, IPost
{
    public groupId: number;
    public personId: number;
    public personEmail: string;

    public constructor(init?: Partial<CreateGroupMemberInvite>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateGroupMemberInviteResponse(); }
    public getTypeName() { return 'CreateGroupMemberInvite'; }
}

// @Route("/api/group/{groupId}/person/{personId}", "DELETE")
export class DeleteGroupMember implements IReturn<DeleteGroupMemberResponse>, IGet
{
    public groupId: number;
    public personId: number;

    public constructor(init?: Partial<DeleteGroupMember>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteGroupMemberResponse(); }
    public getTypeName() { return 'DeleteGroupMember'; }
}

// @Route("/api/query/group", "GET")
export class QueryGroups extends QueryDb_2<PersonGroupDO, QueryPersonGroup> implements IReturn<QueryResponse<QueryPersonGroup>>
{
    public canAssignRoleId: number;

    public constructor(init?: Partial<QueryGroups>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryPersonGroup>(); }
    public getTypeName() { return 'QueryGroups'; }
}

// @Route("/api/import/log/{importLogId}", "GET")
export class GetImportLog implements IReturn<GetImportLogResponse>, IGet
{
    public importLogId: number;

    public constructor(init?: Partial<GetImportLog>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetImportLogResponse(); }
    public getTypeName() { return 'GetImportLog'; }
}

// @Route("/api/query/import/log", "GET")
export class QueryImportLog extends QueryDb_2<ImportLogDO, ImportLog> implements IReturn<QueryResponse<ImportLog>>
{
    public epidemicId: number;

    public constructor(init?: Partial<QueryImportLog>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<ImportLog>(); }
    public getTypeName() { return 'QueryImportLog'; }
}

// @Route("/api/infectedPremise/{id}", "GET")
export class GetInfectedPremise implements IReturn<GetInfectedPremiseResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetInfectedPremise>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInfectedPremiseResponse(); }
    public getTypeName() { return 'GetInfectedPremise'; }
}

// @Route("/api/infectedPremise", "POST")
export class CreateInfectedPremise implements IReturn<CreateInfectedPremiseResponse>, IPost
{
    public infectedPremise: InfectedPremise;

    public constructor(init?: Partial<CreateInfectedPremise>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInfectedPremiseResponse(); }
    public getTypeName() { return 'CreateInfectedPremise'; }
}

// @Route("/api/infectedPremise/{id}", "PUT")
export class UpdateInfectedPremise implements IReturn<UpdateInfectedPremiseResponse>, IPut
{
    public infectedPremise: InfectedPremise;

    public constructor(init?: Partial<UpdateInfectedPremise>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateInfectedPremiseResponse(); }
    public getTypeName() { return 'UpdateInfectedPremise'; }
}

// @Route("/api/query/infectedPremise", "GET")
export class QueryInfectedPremises extends QueryDb_2<InfectedPremiseDO, InfectedPremise> implements IReturn<QueryResponse<InfectedPremise>>
{
    public subClusterId: number;
    public clusterId: number;
    public epidemicId: number;
    public searchText: string;

    public constructor(init?: Partial<QueryInfectedPremises>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<InfectedPremise>(); }
    public getTypeName() { return 'QueryInfectedPremises'; }
}

// @Route("/api/instrument/{id}", "GET")
export class GetInstrument implements IReturn<GetInstrumentResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetInstrument>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetInstrumentResponse(); }
    public getTypeName() { return 'GetInstrument'; }
}

// @Route("/api/instrument", "POST")
export class CreateInstrument implements IReturn<CreateInstrumentResponse>, IPost
{
    public instrument: Instrument;

    public constructor(init?: Partial<CreateInstrument>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateInstrumentResponse(); }
    public getTypeName() { return 'CreateInstrument'; }
}

// @Route("/api/instrument/{id}", "PUT")
export class UpdateInstrument implements IReturn<UpdateInstrumentResponse>, IPut
{
    public instrument: Instrument;

    public constructor(init?: Partial<UpdateInstrument>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateInstrumentResponse(); }
    public getTypeName() { return 'UpdateInstrument'; }
}

// @Route("/api/instrument/{id}", "DELETE")
export class DeleteInstrument implements IReturn<DeleteInstrumentResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteInstrument>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteInstrumentResponse(); }
    public getTypeName() { return 'DeleteInstrument'; }
}

// @Route("/api/query/instrument", "GET")
export class QueryInstruments extends QueryDb_2<InstrumentDO, Instrument> implements IReturn<QueryResponse<Instrument>>
{

    public constructor(init?: Partial<QueryInstruments>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Instrument>(); }
    public getTypeName() { return 'QueryInstruments'; }
}

// @Route("/api/libraryStrategy/{id}", "GET")
export class GetLibraryStrategy implements IReturn<GetLibraryStrategyResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetLibraryStrategy>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLibraryStrategyResponse(); }
    public getTypeName() { return 'GetLibraryStrategy'; }
}

// @Route("/api/libraryStrategy", "POST")
export class CreateLibraryStrategy implements IReturn<CreateLibraryStrategyResponse>, IPost
{
    public libraryStrategy: LibraryStrategy;

    public constructor(init?: Partial<CreateLibraryStrategy>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLibraryStrategyResponse(); }
    public getTypeName() { return 'CreateLibraryStrategy'; }
}

// @Route("/api/libraryStrategy/{id}", "PUT")
export class UpdateLibraryStrategy implements IReturn<UpdateLibraryStrategyResponse>, IPut
{
    public libraryStrategy: LibraryStrategy;

    public constructor(init?: Partial<UpdateLibraryStrategy>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateLibraryStrategyResponse(); }
    public getTypeName() { return 'UpdateLibraryStrategy'; }
}

// @Route("/api/libraryStrategy/{id}", "DELETE")
export class DeleteLibraryStrategy implements IReturn<DeleteLibraryStrategyResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteLibraryStrategy>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteLibraryStrategyResponse(); }
    public getTypeName() { return 'DeleteLibraryStrategy'; }
}

// @Route("/api/query/libraryStrategy", "GET")
export class QueryLibraryStrategys extends QueryDb_2<LibraryStrategyDO, LibraryStrategy> implements IReturn<QueryResponse<LibraryStrategy>>
{

    public constructor(init?: Partial<QueryLibraryStrategys>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<LibraryStrategy>(); }
    public getTypeName() { return 'QueryLibraryStrategys'; }
}

// @Route("/api/livestockLocation/{id}", "GET")
export class GetLivestockLocation implements IReturn<GetLivestockLocationResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetLivestockLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLivestockLocationResponse(); }
    public getTypeName() { return 'GetLivestockLocation'; }
}

// @Route("/api/livestockLocation", "POST")
export class CreateLivestockLocation implements IReturn<CreateLivestockLocationResponse>, IPost
{
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<CreateLivestockLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLivestockLocationResponse(); }
    public getTypeName() { return 'CreateLivestockLocation'; }
}

// @Route("/api/livestockLocation/{id}", "PUT")
export class UpdateLivestockLocation implements IReturn<UpdateLivestockLocationResponse>, IPut
{
    public livestockLocation: LivestockLocation;

    public constructor(init?: Partial<UpdateLivestockLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateLivestockLocationResponse(); }
    public getTypeName() { return 'UpdateLivestockLocation'; }
}

// @Route("/api/query/livestockLocation", "GET")
export class QueryLivestockLocations extends QueryDb_2<LivestockLocationDO, LivestockLocation> implements IReturn<QueryResponse<LivestockLocation>>
{

    public constructor(init?: Partial<QueryLivestockLocations>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<LivestockLocation>(); }
    public getTypeName() { return 'QueryLivestockLocations'; }
}

// @Route("/api/livestock/{id}", "GET")
export class GetLivestock implements IReturn<GetLivestockResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetLivestock>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLivestockResponse(); }
    public getTypeName() { return 'GetLivestock'; }
}

// @Route("/api/livestock", "POST")
export class CreateLivestock implements IReturn<CreateLivestockResponse>, IPost
{
    public livestock: Livestock;

    public constructor(init?: Partial<CreateLivestock>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLivestockResponse(); }
    public getTypeName() { return 'CreateLivestock'; }
}

// @Route("/api/livestock/{id}", "PUT")
export class UpdateLivestock implements IReturn<UpdateLivestockResponse>, IPut
{
    public livestock: Livestock;

    public constructor(init?: Partial<UpdateLivestock>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateLivestockResponse(); }
    public getTypeName() { return 'UpdateLivestock'; }
}

// @Route("/api/livestock/{id}", "DELETE")
export class DeleteLivestock implements IReturn<DeleteLivestockResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteLivestock>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteLivestockResponse(); }
    public getTypeName() { return 'DeleteLivestock'; }
}

// @Route("/api/livestock/default/{id}", "GET")
export class GetLivestockDefault implements IReturn<GetLivestockDefaultResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetLivestockDefault>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLivestockDefaultResponse(); }
    public getTypeName() { return 'GetLivestockDefault'; }
}

// @Route("/api/livestock/default", "POST")
export class CreateLivestockDefault implements IReturn<CreateLivestockDefaultResponse>, IPost
{
    public livestockDefault: Livestock;

    public constructor(init?: Partial<CreateLivestockDefault>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateLivestockDefaultResponse(); }
    public getTypeName() { return 'CreateLivestockDefault'; }
}

// @Route("/api/livestock/default/{id}", "PUT")
export class UpdateLivestockDefault implements IReturn<UpdateLivestockDefaultResponse>, IPut
{
    public livestockDefault: Livestock;

    public constructor(init?: Partial<UpdateLivestockDefault>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateLivestockDefaultResponse(); }
    public getTypeName() { return 'UpdateLivestockDefault'; }
}

// @Route("/api/livestock/{id}", "DELETE")
export class DeleteLivestockDefault implements IReturn<DeleteLivestockDefaultResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteLivestockDefault>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteLivestockDefaultResponse(); }
    public getTypeName() { return 'DeleteLivestockDefault'; }
}

// @Route("/api/query/livestock", "GET")
export class QueryLivestocks extends QueryDb_2<LivestockDO, Livestock> implements IReturn<QueryResponse<Livestock>>
{
    public epidemicId: number;

    public constructor(init?: Partial<QueryLivestocks>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Livestock>(); }
    public getTypeName() { return 'QueryLivestocks'; }
}

// @Route("/api/query/livestock/default", "GET")
export class QueryLivestockDefaults extends QueryDb_2<LivestockDefaultDO, Livestock> implements IReturn<QueryResponse<Livestock>>
{

    public constructor(init?: Partial<QueryLivestockDefaults>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Livestock>(); }
    public getTypeName() { return 'QueryLivestockDefaults'; }
}

// @Route("/api/material/{id}", "GET")
export class GetMaterial implements IReturn<GetMaterialResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetMaterial>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMaterialResponse(); }
    public getTypeName() { return 'GetMaterial'; }
}

// @Route("/api/material", "POST")
export class CreateMaterial implements IReturn<CreateMaterialResponse>, IPost
{
    public material: Material;

    public constructor(init?: Partial<CreateMaterial>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateMaterialResponse(); }
    public getTypeName() { return 'CreateMaterial'; }
}

// @Route("/api/material/{id}", "PUT")
export class UpdateMaterial implements IReturn<UpdateMaterialResponse>, IPut
{
    public material: Material;

    public constructor(init?: Partial<UpdateMaterial>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateMaterialResponse(); }
    public getTypeName() { return 'UpdateMaterial'; }
}

// @Route("/api/material/{id}", "DELETE")
export class DeleteMaterial implements IReturn<DeleteMaterialResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteMaterial>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteMaterialResponse(); }
    public getTypeName() { return 'DeleteMaterial'; }
}

// @Route("/api/query/material", "GET")
export class QueryMaterials extends QueryDb_2<MaterialDO, Material> implements IReturn<QueryResponse<Material>>
{

    public constructor(init?: Partial<QueryMaterials>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Material>(); }
    public getTypeName() { return 'QueryMaterials'; }
}

// @Route("/api/max", "POST")
export class MaxUploadInput implements IReturn<MaxUploadResponse>, IPost
{
    /**
    * Epidemic ID
    */
    // @ApiMember(Description="Epidemic ID", IsRequired=true)
    public epidemicId: number;

    /**
    * Coordinate reference system for point/polygon/multi-polyon
    */
    // @ApiMember(Description="Coordinate reference system for point/polygon/multi-polyon", IsRequired=true)
    public crs: string;

    /**
    * Within each epidemic there will be a minimum of 1 cluster. 
    */
    // @ApiMember(Description="Within each epidemic there will be a minimum of 1 cluster. ", IsRequired=true)
    public clusters: MaxCluster[];

    public constructor(init?: Partial<MaxUploadInput>) { (Object as any).assign(this, init); }
    public createResponse() { return new MaxUploadResponse(); }
    public getTypeName() { return 'MaxUploadInput'; }
}

// @Route("/api/genomic/msa/{id}", "GET")
export class GetMSA implements IReturn<GetMSAResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetMSA>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMSAResponse(); }
    public getTypeName() { return 'GetMSA'; }
}

// @Route("/api/query/genomic/msa", "GET")
export class QueryMSAs extends QueryDb_2<MSADO, QueryMSA> implements IReturn<QueryResponse<QueryMSA>>
{
    public epidemicId: number;

    public constructor(init?: Partial<QueryMSAs>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryMSA>(); }
    public getTypeName() { return 'QueryMSAs'; }
}

// @Route("/file/genomic/msa/{MSAId}", "GET")
export class GetMSAFile implements IReturn<Blob>, IGet
{
    public msaId: number;

    public constructor(init?: Partial<GetMSAFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetMSAFile'; }
}

// @Route("/api/genomic/msa/job", "POST")
export class CreateJobMSA implements IReturn<CreateJobMSAResponse>, IPost
{
    public infectedPremiseIds: number[];

    public constructor(init?: Partial<CreateJobMSA>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobMSAResponse(); }
    public getTypeName() { return 'CreateJobMSA'; }
}

// @Route("/api/genomic/msa/job/{MSAJobId}", "DELETE")
export class DeleteJobMSA implements IReturn<DeleteJobMSAResponse>, IGet
{
    public msaJobId: number;

    public constructor(init?: Partial<DeleteJobMSA>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteJobMSAResponse(); }
    public getTypeName() { return 'DeleteJobMSA'; }
}

// @Route("/api/genomic/msa/job/{MSAJobId}/sequence", "GET")
export class GetMSAJobSequenceParams implements IReturn<GetMSAJobSequenceParamsResponse>, IGet
{
    public msaJobId: number;

    public constructor(init?: Partial<GetMSAJobSequenceParams>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMSAJobSequenceParamsResponse(); }
    public getTypeName() { return 'GetMSAJobSequenceParams'; }
}

// @Route("/api/genomic/msa/{MSAId}/network", "POST")
export class CreateJobGenomicNetworkMSA implements IReturn<CreateJobGenomicNetworkMSAResponse>, IPost
{
    public epidemicId: number;
    public msaId: number;
    public startDateTypeId: number;

    public constructor(init?: Partial<CreateJobGenomicNetworkMSA>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobGenomicNetworkMSAResponse(); }
    public getTypeName() { return 'CreateJobGenomicNetworkMSA'; }
}

// @Route("/api/parcel/{id}", "GET")
export class GetParcel implements IReturn<GetParcelResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetParcel>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParcelResponse(); }
    public getTypeName() { return 'GetParcel'; }
}

// @Route("/api/parcel", "POST")
export class CreateParcel implements IReturn<CreateParcelResponse>, IPost
{
    public parcel: Parcel;

    public constructor(init?: Partial<CreateParcel>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateParcelResponse(); }
    public getTypeName() { return 'CreateParcel'; }
}

// @Route("/api/parcel/{id}", "PUT")
export class UpdateParcel implements IReturn<UpdateParcelResponse>, IPut
{
    public parcel: Parcel;

    public constructor(init?: Partial<UpdateParcel>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateParcelResponse(); }
    public getTypeName() { return 'UpdateParcel'; }
}

// @Route("/api/query/parcel", "GET")
export class QueryParcels extends QueryDb_2<AreaDO, Parcel> implements IReturn<QueryResponse<Parcel>>
{
    public clusterId: number;
    public epidemicId: number;
    public infectedPremiseId: number;
    public subClusterId: number;

    public constructor(init?: Partial<QueryParcels>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Parcel>(); }
    public getTypeName() { return 'QueryParcels'; }
}

// @Route("/api/passwordReset/request/{email}", "POST")
export class RequestPasswordReset implements IReturn<RequestPasswordResetResponse>, IPost
{
    public email: string;

    public constructor(init?: Partial<RequestPasswordReset>) { (Object as any).assign(this, init); }
    public createResponse() { return new RequestPasswordResetResponse(); }
    public getTypeName() { return 'RequestPasswordReset'; }
}

// @Route("/api/passwordReset/{token}", "PUT")
export class ResetPassword implements IReturn<ResetPasswordResponse>, IPut
{
    public token: string;
    public password: string;

    public constructor(init?: Partial<ResetPassword>) { (Object as any).assign(this, init); }
    public createResponse() { return new ResetPasswordResponse(); }
    public getTypeName() { return 'ResetPassword'; }
}

// @Route("/api/pathogen/{id}", "GET")
export class GetPathogen implements IReturn<GetPathogenResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetPathogen>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPathogenResponse(); }
    public getTypeName() { return 'GetPathogen'; }
}

// @Route("/api/pathogen", "POST")
export class CreatePathogen implements IReturn<CreatePathogenResponse>, IPost
{
    public pathogen: Pathogen;

    public constructor(init?: Partial<CreatePathogen>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePathogenResponse(); }
    public getTypeName() { return 'CreatePathogen'; }
}

// @Route("/api/pathogen/{id}", "PUT")
export class UpdatePathogen implements IReturn<UpdatePathogenResponse>, IPut
{
    public pathogen: Pathogen;

    public constructor(init?: Partial<UpdatePathogen>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatePathogenResponse(); }
    public getTypeName() { return 'UpdatePathogen'; }
}

// @Route("/api/pathogen/{id}", "DELETE")
export class DeletePathogen implements IReturn<DeletePathogenResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeletePathogen>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeletePathogenResponse(); }
    public getTypeName() { return 'DeletePathogen'; }
}

// @Route("/api/query/pathogen", "GET")
export class QueryPathogens extends QueryDb_2<PathogenDO, Pathogen> implements IReturn<QueryResponse<Pathogen>>
{

    public constructor(init?: Partial<QueryPathogens>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Pathogen>(); }
    public getTypeName() { return 'QueryPathogens'; }
}

// @Route("/api/person/authenticated", "GET")
export class GetAuthenticatedPerson implements IReturn<GetAuthenticatedPersonResponse>, IGet
{

    public constructor(init?: Partial<GetAuthenticatedPerson>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAuthenticatedPersonResponse(); }
    public getTypeName() { return 'GetAuthenticatedPerson'; }
}

// @Route("/api/person/authenticated/invite", "GET")
export class GetAuthenticatedPersonGroupInvites implements IReturn<GetAuthenticatedPersonGroupInvitesResponse>, IGet
{

    public constructor(init?: Partial<GetAuthenticatedPersonGroupInvites>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAuthenticatedPersonGroupInvitesResponse(); }
    public getTypeName() { return 'GetAuthenticatedPersonGroupInvites'; }
}

// @Route("/api/person/{id}", "GET")
export class GetPerson implements IReturn<GetPersonResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetPerson>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonResponse(); }
    public getTypeName() { return 'GetPerson'; }
}

// @Route("/api/person/{id}", "PUT")
export class UpdatePerson implements IReturn<UpdatePersonResponse>, IPut
{
    public person: Person;
    public noObject: string;

    public constructor(init?: Partial<UpdatePerson>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatePersonResponse(); }
    public getTypeName() { return 'UpdatePerson'; }
}

// @Route("/api/query/person", "GET")
export class QueryPersons extends QueryDb_2<PersonDO, QueryPerson> implements IReturn<QueryResponse<QueryPerson>>
{

    public constructor(init?: Partial<QueryPersons>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryPerson>(); }
    public getTypeName() { return 'QueryPersons'; }
}

// @Route("/api/platform/{id}", "GET")
export class GetPlatform implements IReturn<GetPlatformResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetPlatform>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPlatformResponse(); }
    public getTypeName() { return 'GetPlatform'; }
}

// @Route("/api/platform", "POST")
export class CreatePlatform implements IReturn<CreatePlatformResponse>, IPost
{
    public platform: Platform;

    public constructor(init?: Partial<CreatePlatform>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreatePlatformResponse(); }
    public getTypeName() { return 'CreatePlatform'; }
}

// @Route("/api/platform/{id}", "PUT")
export class UpdatePlatform implements IReturn<UpdatePlatformResponse>, IPut
{
    public platform: Platform;

    public constructor(init?: Partial<UpdatePlatform>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdatePlatformResponse(); }
    public getTypeName() { return 'UpdatePlatform'; }
}

// @Route("/api/platform/{id}", "DELETE")
export class DeletePlatform implements IReturn<DeletePlatformResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeletePlatform>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeletePlatformResponse(); }
    public getTypeName() { return 'DeletePlatform'; }
}

// @Route("/api/query/platform", "GET")
export class QueryPlatforms extends QueryDb_2<PlatformDO, Platform> implements IReturn<QueryResponse<Platform>>
{

    public constructor(init?: Partial<QueryPlatforms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Platform>(); }
    public getTypeName() { return 'QueryPlatforms'; }
}

// @Route("/api/role/{id}", "GET")
export class GetRole implements IReturn<GetRoleResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRoleResponse(); }
    public getTypeName() { return 'GetRole'; }
}

// @Route("/api/person/{personId}/role/{roleId}", "POST")
export class AssignRole implements IReturn<AssignRoleResponse>, IPost
{
    public personId: number;
    public roleId: number;
    public objectId: number;

    public constructor(init?: Partial<AssignRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRoleResponse(); }
    public getTypeName() { return 'AssignRole'; }
}

// @Route("/api/person/role/{personRoleId}", "DELETE")
export class UnassignRole implements IReturn<UnassignRoleResponse>, IDelete
{
    public personRoleId: number;

    public constructor(init?: Partial<UnassignRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnassignRoleResponse(); }
    public getTypeName() { return 'UnassignRole'; }
}

// @Route("/api/query/role", "GET")
export class QueryRoles extends QueryDb_2<RoleDO, QueryRole> implements IReturn<QueryResponse<QueryRole>>
{
    public canAssignOnly: boolean;
    public assignRoleObjectTypeId: ObjectType;

    public constructor(init?: Partial<QueryRoles>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryRole>(); }
    public getTypeName() { return 'QueryRoles'; }
}

// @Route("/api/query/personRole", "GET")
export class QueryPersonRoles extends QueryDb_2<PersonRoleDO, QueryPersonRole> implements IReturn<QueryResponse<QueryPersonRole>>
{
    public epidemicId: number;
    public personGroupId: number;
    public personId: number;
    public epidemicIdIn: number;
    public personGroupIdIn: number;

    public constructor(init?: Partial<QueryPersonRoles>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QueryPersonRole>(); }
    public getTypeName() { return 'QueryPersonRoles'; }
}

// @Route("/api/sequence/{id}", "GET")
export class GetSequence implements IReturn<GetSequenceResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSequence>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSequenceResponse(); }
    public getTypeName() { return 'GetSequence'; }
}

// @Route("/api/sequence/consensus/{id}", "GET")
export class GetConsensusSequence implements IReturn<GetConsensusSequenceResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetConsensusSequence>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetConsensusSequenceResponse(); }
    public getTypeName() { return 'GetConsensusSequence'; }
}

// @Route("/api/sequence", "POST")
export class CreateSequence implements IReturn<CreateSequenceResponse>, IPost
{
    public sequence: Sequence;

    public constructor(init?: Partial<CreateSequence>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSequenceResponse(); }
    public getTypeName() { return 'CreateSequence'; }
}

// @Route("/api/sequence", "PUT")
export class UpdateSequence implements IReturn<UpdateSequenceResponse>, IPut
{
    public sequence: Sequence;

    public constructor(init?: Partial<UpdateSequence>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSequenceResponse(); }
    public getTypeName() { return 'UpdateSequence'; }
}

// @Route("/api/sequence/consensus/{ConsensusSequenceId}/qa/assembly", "PUT")
export class UpdateAssemblyQA implements IReturn<UpdateAssemblyQAResponse>, IPut
{
    public consensusSequenceId: number;
    public passed: boolean;
    public comment: string;

    public constructor(init?: Partial<UpdateAssemblyQA>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAssemblyQAResponse(); }
    public getTypeName() { return 'UpdateAssemblyQA'; }
}

// @Route("/api/sequence/consensus/{ConsensusSequenceId}/qa/annotation", "PUT")
export class UpdateAnnotationQA implements IReturn<UpdateAnnotationQAResponse>, IPut
{
    public consensusSequenceId: number;
    public passed: boolean;
    public comment: string;

    public constructor(init?: Partial<UpdateAnnotationQA>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateAnnotationQAResponse(); }
    public getTypeName() { return 'UpdateAnnotationQA'; }
}

// @Route("/api/query/sequence", "GET")
export class QuerySequences extends QueryDb_2<SequenceDO, QuerySequence> implements IReturn<QueryResponse<QuerySequence>>
{
    public specimenId: number;

    public constructor(init?: Partial<QuerySequences>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySequence>(); }
    public getTypeName() { return 'QuerySequences'; }
}

// @Route("/file/sequence/gbk/{SequenceId}", "GET")
export class GetGBKFile implements IReturn<Blob>
{
    public sequenceId: number;

    public constructor(init?: Partial<GetGBKFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetGBKFile'; }
}

// @Route("/file/sequence/qualityf/{ConsensusSequenceId}", "GET")
export class GetQualityForwardImageFile implements IReturn<Blob>
{
    public consensusSequenceId: number;

    public constructor(init?: Partial<GetQualityForwardImageFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetQualityForwardImageFile'; }
}

// @Route("/file/sequence/qualityr/{ConsensusSequenceId}", "GET")
export class GetQualityReverseImageFile implements IReturn<Blob>
{
    public consensusSequenceId: number;

    public constructor(init?: Partial<GetQualityReverseImageFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetQualityReverseImageFile'; }
}

// @Route("/file/sequence/coverage/{ConsensusSequenceId}", "GET")
export class GetCoverageImageFile implements IReturn<Blob>
{
    public consensusSequenceId: number;

    public constructor(init?: Partial<GetCoverageImageFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetCoverageImageFile'; }
}

// @Route("/file/sequence/{SequenceId}/fastq1", "GET")
export class GetFastQFile1 implements IReturn<Blob>
{
    public sequenceId: number;

    public constructor(init?: Partial<GetFastQFile1>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFastQFile1'; }
}

// @Route("/file/sequence/{SequenceId}/fastq2", "GET")
export class GetFastQFile2 implements IReturn<Blob>
{
    public sequenceId: number;

    public constructor(init?: Partial<GetFastQFile2>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFastQFile2'; }
}

// @Route("/file/sequence/{SequenceId}/fasta", "GET")
export class GetFastAFile implements IReturn<Blob>
{
    public sequenceId: number;

    public constructor(init?: Partial<GetFastAFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFastAFile'; }
}

// @Route("/api/sequence/{SequenceId}/fastafastq/metadata/epidemic/{EpidemicId}", "GET")
export class GetFastAFastQMetadata implements IReturn<GetFastAFastQMetadataResponse>
{
    public sequenceId: number;
    public epidemicId: number;

    public constructor(init?: Partial<GetFastAFastQMetadata>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFastAFastQMetadataResponse(); }
    public getTypeName() { return 'GetFastAFastQMetadata'; }
}

// @Route("/api/sequence/consensus/{ConsensusSequenceId}/annotation", "GET")
export class GetAnnotationData implements IReturn<GetAnnotationDataResponse>
{
    public consensusSequenceId: number;

    public constructor(init?: Partial<GetAnnotationData>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAnnotationDataResponse(); }
    public getTypeName() { return 'GetAnnotationData'; }
}

// @Route("/api/sequence/consensus/{ConsensusSequenceId}/assembly", "GET")
export class GetAssemblyData implements IReturn<GetAssemblyDataResponse>
{
    public consensusSequenceId: number;

    public constructor(init?: Partial<GetAssemblyData>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAssemblyDataResponse(); }
    public getTypeName() { return 'GetAssemblyData'; }
}

// @Route("/api/query/sequence/view", "GET")
export class QuerySequenceViews extends QueryDb_2<SequenceView, QuerySequenceView> implements IReturn<QueryResponse<QuerySequenceView>>
{
    public notLinkedWithEpidemicId: number;
    public epidemicId: number;
    public year: number;
    public country: string;
    public origin: string;
    public sequencingStatus: string;
    public searchText: string;

    public constructor(init?: Partial<QuerySequenceViews>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySequenceView>(); }
    public getTypeName() { return 'QuerySequenceViews'; }
}

// @Route("/api/sequence/retrieval/{JobSequenceRetrievalId}", "GET")
export class GetJobSequenceRetrieval implements IReturn<GetJobSequenceRetrievalResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetJobSequenceRetrieval>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetJobSequenceRetrievalResponse(); }
    public getTypeName() { return 'GetJobSequenceRetrieval'; }
}

// @Route("/api/sequence/retrieval", "POST")
export class CreateJobSequenceRetrieval implements IReturn<CreateJobSequenceRetrievalResponse>, IPost
{
    public timestamp: string;

    public constructor(init?: Partial<CreateJobSequenceRetrieval>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobSequenceRetrievalResponse(); }
    public getTypeName() { return 'CreateJobSequenceRetrieval'; }
}

// @Route("/api/query/sequence/{SequenceId}/assemble", "POST")
export class CreateJobAssembly implements IReturn<CreateJobAssemblyResponse>, IPost
{
    public sequenceId: number;
    public method: AssemblyMethod;
    public genotype: string;

    public constructor(init?: Partial<CreateJobAssembly>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateJobAssemblyResponse(); }
    public getTypeName() { return 'CreateJobAssembly'; }
}

// @Route("/file/sequence/{AssemblyJobId}/all", "GET")
export class GetAllAssemblyJobFiles implements IReturn<Blob>, IGet
{
    public assemblyJobId: number;

    public constructor(init?: Partial<GetAllAssemblyJobFiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetAllAssemblyJobFiles'; }
}

// @Route("/api/species/{id}", "GET")
export class GetSpecies implements IReturn<GetSpeciesResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSpecies>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpeciesResponse(); }
    public getTypeName() { return 'GetSpecies'; }
}

// @Route("/api/species", "POST")
export class CreateSpecies implements IReturn<CreateSpeciesResponse>, IPost
{
    public species: Species;

    public constructor(init?: Partial<CreateSpecies>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSpeciesResponse(); }
    public getTypeName() { return 'CreateSpecies'; }
}

// @Route("/api/species/{id}", "PUT")
export class UpdateSpecies implements IReturn<UpdateSpeciesResponse>, IPut
{
    public species: Species;

    public constructor(init?: Partial<UpdateSpecies>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSpeciesResponse(); }
    public getTypeName() { return 'UpdateSpecies'; }
}

// @Route("/api/query/species", "GET")
export class QuerySpecies extends QueryDb_2<SpeciesDO, Species> implements IReturn<QueryResponse<Species>>
{

    public constructor(init?: Partial<QuerySpecies>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Species>(); }
    public getTypeName() { return 'QuerySpecies'; }
}

// @Route("/api/specimen/{id}", "GET")
export class GetSpecimen implements IReturn<GetSpecimenResponse>, IGet
{
    public id: number;
    public epidemicId: number;

    public constructor(init?: Partial<GetSpecimen>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpecimenResponse(); }
    public getTypeName() { return 'GetSpecimen'; }
}

// @Route("/api/specimen", "POST")
export class CreateSpecimen implements IReturn<CreateSpecimenResponse>, IPost
{
    public specimen: Specimen;

    public constructor(init?: Partial<CreateSpecimen>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSpecimenResponse(); }
    public getTypeName() { return 'CreateSpecimen'; }
}

// @Route("/api/specimen/{id}", "PUT")
export class UpdateSpecimen implements IReturn<UpdateSpecimenResponse>, IPut
{
    public specimen: Specimen;

    public constructor(init?: Partial<UpdateSpecimen>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSpecimenResponse(); }
    public getTypeName() { return 'UpdateSpecimen'; }
}

// @Route("/api/specimen/{id}", "DELETE")
export class DeleteSpecimen implements IReturn<DeleteSpecimenResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteSpecimen>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteSpecimenResponse(); }
    public getTypeName() { return 'DeleteSpecimen'; }
}

// @Route("/api/query/specimen", "GET")
export class QuerySpecimens extends QueryDb_2<SpecimenDO, QuerySpecimen> implements IReturn<QueryResponse<QuerySpecimen>>
{

    public constructor(init?: Partial<QuerySpecimens>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySpecimen>(); }
    public getTypeName() { return 'QuerySpecimens'; }
}

// @Route("/api/workflow/job/{ApiJobId}", "GET")
export class GetSpreadWorkflowJob implements IReturn<GetSpreadWorkflowJobResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<GetSpreadWorkflowJob>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpreadWorkflowJobResponse(); }
    public getTypeName() { return 'GetSpreadWorkflowJob'; }
}

// @Route("/api/workflow/job/", "GET")
export class GetSpreadWorkflowJobs implements IReturn<GetSpreadWorkflowJobsResponse>, IGet
{
    public jobStatusFilter: SpreadWorkflowJobStatus[];

    public constructor(init?: Partial<GetSpreadWorkflowJobs>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSpreadWorkflowJobsResponse(); }
    public getTypeName() { return 'GetSpreadWorkflowJobs'; }
}

// @Route("/api/workflow/job/{ApiJobId}/dispatch", "PUT")
export class DispatchSpreadWorkflowJob implements IReturn<DispatchSpreadWorkflowJobResponse>, IPut
{
    public apiJobId: number;

    public constructor(init?: Partial<DispatchSpreadWorkflowJob>) { (Object as any).assign(this, init); }
    public createResponse() { return new DispatchSpreadWorkflowJobResponse(); }
    public getTypeName() { return 'DispatchSpreadWorkflowJob'; }
}

// @Route("/api/workflow/job/{ApiJobId}/refresh", "GET")
export class RefreshSpreadWorkflowJobStatus implements IReturn<RefreshSpreadWorkflowJobStatusResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<RefreshSpreadWorkflowJobStatus>) { (Object as any).assign(this, init); }
    public createResponse() { return new RefreshSpreadWorkflowJobStatusResponse(); }
    public getTypeName() { return 'RefreshSpreadWorkflowJobStatus'; }
}

// @Route("/api/workflow/job/{ApiJobId}/download/dispatch", "PUT")
export class DispatchSpreadWorkflowJobResultDownload implements IReturn<DispatchSpreadWorkflowJobResultDownloadResponse>, IPut
{
    public apiJobId: number;

    public constructor(init?: Partial<DispatchSpreadWorkflowJobResultDownload>) { (Object as any).assign(this, init); }
    public createResponse() { return new DispatchSpreadWorkflowJobResultDownloadResponse(); }
    public getTypeName() { return 'DispatchSpreadWorkflowJobResultDownload'; }
}

// @Route("/api/workflow/job/{ApiJobId}/result/refresh", "PUT")
export class ProcessSpreadWorkflowJobResult implements IReturn<ProcessSpreadWorkflowJobResultResponse>, IPut
{
    public apiJobId: number;

    public constructor(init?: Partial<ProcessSpreadWorkflowJobResult>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessSpreadWorkflowJobResultResponse(); }
    public getTypeName() { return 'ProcessSpreadWorkflowJobResult'; }
}

// @Route("/api/query/workflow/job", "GET")
export class QuerySpreadWorkflowJobs extends QueryDb_2<ApiJobDO, SpreadWorkflowJob> implements IReturn<QueryResponse<SpreadWorkflowJob>>
{
    public onOrBeforeMinDate: string;
    public onOrAfterMaxDate: string;

    public constructor(init?: Partial<QuerySpreadWorkflowJobs>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<SpreadWorkflowJob>(); }
    public getTypeName() { return 'QuerySpreadWorkflowJobs'; }
}

// @Route("/api/subclassification/{id}", "GET")
export class GetSubClassification implements IReturn<GetSubClassificationResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSubClassificationResponse(); }
    public getTypeName() { return 'GetSubClassification'; }
}

// @Route("/api/subclassification", "POST")
export class CreateSubClassification implements IReturn<CreateSubClassificationResponse>, IPost
{
    public subClassification: SubClassification;

    public constructor(init?: Partial<CreateSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSubClassificationResponse(); }
    public getTypeName() { return 'CreateSubClassification'; }
}

// @Route("/api/subclassification/{id}", "PUT")
export class UpdateSubClassification implements IReturn<UpdateSubClassificationResponse>, IPut
{
    public subClassification: SubClassification;

    public constructor(init?: Partial<UpdateSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSubClassificationResponse(); }
    public getTypeName() { return 'UpdateSubClassification'; }
}

// @Route("/api/subclassification/{id}", "DELETE")
export class DeleteSubClassification implements IReturn<DeleteSubClassificationResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteSubClassification>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteSubClassificationResponse(); }
    public getTypeName() { return 'DeleteSubClassification'; }
}

// @Route("/api/query/subclassification", "GET")
export class QuerySubClassifications extends QueryDb_2<SubClassificationDO, QuerySubClassification> implements IReturn<QueryResponse<QuerySubClassification>>
{

    public constructor(init?: Partial<QuerySubClassifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySubClassification>(); }
    public getTypeName() { return 'QuerySubClassifications'; }
}

// @Route("/api/subCluster/{id}", "GET")
export class GetSubCluster implements IReturn<GetSubClusterResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSubCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSubClusterResponse(); }
    public getTypeName() { return 'GetSubCluster'; }
}

// @Route("/api/subCluster", "POST")
export class CreateSubCluster implements IReturn<CreateSubClusterResponse>, IPost
{
    public subCluster: SubCluster;

    public constructor(init?: Partial<CreateSubCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSubClusterResponse(); }
    public getTypeName() { return 'CreateSubCluster'; }
}

// @Route("/api/subCluster/{id}", "PUT")
export class UpdateSubCluster implements IReturn<UpdateSubClusterResponse>, IPut
{
    public subCluster: SubCluster;

    public constructor(init?: Partial<UpdateSubCluster>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSubClusterResponse(); }
    public getTypeName() { return 'UpdateSubCluster'; }
}

// @Route("/api/query/subCluster", "GET")
export class QuerySubClusters extends QueryDb_2<SubClusterDO, QuerySubCluster> implements IReturn<QueryResponse<QuerySubCluster>>
{
    public clusterId: number;
    public epidemicId: number;

    public constructor(init?: Partial<QuerySubClusters>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySubCluster>(); }
    public getTypeName() { return 'QuerySubClusters'; }
}

// @Route("/api/subNationalType/{id}", "GET")
export class GetSubNational implements IReturn<GetSubNationalResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetSubNational>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSubNationalResponse(); }
    public getTypeName() { return 'GetSubNational'; }
}

// @Route("/api/subNationalType", "POST")
export class CreateSubNational implements IReturn<CreateSubNationalResponse>, IPost
{
    public subNational: SubNational;

    public constructor(init?: Partial<CreateSubNational>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateSubNationalResponse(); }
    public getTypeName() { return 'CreateSubNational'; }
}

// @Route("/api/subNationalType/{id}", "PUT")
export class UpdateSubNational implements IReturn<UpdateSubNationalResponse>, IPut
{
    public subNational: SubNational;
    public noObject: string;

    public constructor(init?: Partial<UpdateSubNational>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateSubNationalResponse(); }
    public getTypeName() { return 'UpdateSubNational'; }
}

// @Route("/api/subNationalType/{id}", "DELETE")
export class DeleteSubNational implements IReturn<DeleteSubNationalResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteSubNational>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteSubNationalResponse(); }
    public getTypeName() { return 'DeleteSubNational'; }
}

// @Route("/api/query/subNationalType", "GET")
export class QuerySubNationals extends QueryDb_2<SubNationalDO, QuerySubNational> implements IReturn<QueryResponse<QuerySubNational>>
{
    public countryId: number;

    public constructor(init?: Partial<QuerySubNationals>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<QuerySubNational>(); }
    public getTypeName() { return 'QuerySubNationals'; }
}

// @Route("/api/analysis/tapm/{ApiJobId}", "GET")
export class GetAnalysisJobTapm implements IReturn<GetAnalysisJobTapmResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<GetAnalysisJobTapm>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAnalysisJobTapmResponse(); }
    public getTypeName() { return 'GetAnalysisJobTapm'; }
}

// @Route("/api/analysis/tapm/{ApiJobId}", "DELETE")
export class DeleteAnalysisJobTapm implements IReturn<DeleteAnalysisJobTapmResponse>, IGet
{
    public apiJobId: number;

    public constructor(init?: Partial<DeleteAnalysisJobTapm>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteAnalysisJobTapmResponse(); }
    public getTypeName() { return 'DeleteAnalysisJobTapm'; }
}

// @Route("/api/analysis/tapm/{ApiJobId}/result/refresh", "PUT")
export class ProcessAnalysisJobTapmResult implements IReturn<ProcessAnalysisJobTapmResultResponse>, IPut
{
    public apiJobId: number;

    public constructor(init?: Partial<ProcessAnalysisJobTapmResult>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProcessAnalysisJobTapmResultResponse(); }
    public getTypeName() { return 'ProcessAnalysisJobTapmResult'; }
}

// @Route("/api/analysis/tapm/{ApiJobId}/result/{ResultType}", "GET")
export class GetAnalysisJobTapmResult implements IReturn<GetAnalysisJobTapmResultResponse>, IGet
{
    public apiJobId: number;
    public resultType: WindDispersionResultType;

    public constructor(init?: Partial<GetAnalysisJobTapmResult>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAnalysisJobTapmResultResponse(); }
    public getTypeName() { return 'GetAnalysisJobTapmResult'; }
}

// @Route("/file/analysis/tapm/{ApiJobId}/result", "GET")
export class GetAnalysisJobTapmResultFile implements IReturn<Blob>
{
    public apiJobId: number;

    public constructor(init?: Partial<GetAnalysisJobTapmResultFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetAnalysisJobTapmResultFile'; }
}

// @Route("/api/analysis/tapm", "POST")
export class CreateAnalysisJobTapm implements IReturn<CreateAnalysisJobTapmResponse>, IPost
{
    public name: string;
    public tapmDataSetId: number;
    public infectedPremiseIds: number[];
    public entireEpidemicId: number;
    public emissionRate: number;
    public tapmExcretionId: number;
    public limitRunStartDate: string;
    public limitRunEndDate: string;
    public forecastLength: ILength;
    public nestingLevel: InRun;
    public virusSurvival: VirusSurvival;
    public extentKilometres: number;

    public constructor(init?: Partial<CreateAnalysisJobTapm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateAnalysisJobTapmResponse(); }
    public getTypeName() { return 'CreateAnalysisJobTapm'; }
}

// @Route("/api/analysis/tapm/{ApiJobId}/cancel", "PUT")
export class CancelAnalysisJobTapm implements IReturn<CancelAnalysisJobTapmResponse>, IPost
{
    public apiJobId: number;

    public constructor(init?: Partial<CancelAnalysisJobTapm>) { (Object as any).assign(this, init); }
    public createResponse() { return new CancelAnalysisJobTapmResponse(); }
    public getTypeName() { return 'CancelAnalysisJobTapm'; }
}

// @Route("/api/analysis/tapm/dataset/refresh", "GET")
export class RefreshTapmDatasets implements IReturn<RefreshTapmDatasetsResponse>, IGet
{

    public constructor(init?: Partial<RefreshTapmDatasets>) { (Object as any).assign(this, init); }
    public createResponse() { return new RefreshTapmDatasetsResponse(); }
    public getTypeName() { return 'RefreshTapmDatasets'; }
}

// @Route("/api/query/analysis", "GET")
export class QueryAnalysis extends QueryDb_2<ApiJobDO, AnalysisJobTapm> implements IReturn<QueryResponse<AnalysisJobTapm>>
{
    public subClusterId: number;
    public epidemicId: number;
    public nameContains: string;

    public constructor(init?: Partial<QueryAnalysis>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<AnalysisJobTapm>(); }
    public getTypeName() { return 'QueryAnalysis'; }
}

// @Route("/api/query/analysis/tapm/dataset", "GET")
export class QueryAnalysisTapmDataSet extends QueryDb_2<TapmDataSetDO, AnalysisTapmDataSet> implements IReturn<QueryResponse<AnalysisTapmDataSet>>
{
    public onOrBeforeMinDate: string;
    public onOrAfterMaxDate: string;

    public constructor(init?: Partial<QueryAnalysisTapmDataSet>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<AnalysisTapmDataSet>(); }
    public getTypeName() { return 'QueryAnalysisTapmDataSet'; }
}

// @Route("/api/template/{TemplateId}", "GET")
export class GetTemplate implements IReturn<GetTemplateResponse>, IPost
{
    public templateId: number;

    public constructor(init?: Partial<GetTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTemplateResponse(); }
    public getTypeName() { return 'GetTemplate'; }
}

// @Route("/api/template/{TemplateType}/render", "GET")
export class GetRenderedTemplate implements IReturn<Object>, IPost
{
    public templateType: TemplateType;

    public constructor(init?: Partial<GetRenderedTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new Object(); }
    public getTypeName() { return 'GetRenderedTemplate'; }
}

// @Route("/api/template/{TemplateType}/send", "POST")
export class MailRenderedTemplate implements IReturn<MailRenderedTemplateResponse>, IPost
{
    public templateType: TemplateType;
    public args: { [index: string]: Object; };

    public constructor(init?: Partial<MailRenderedTemplate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MailRenderedTemplateResponse(); }
    public getTypeName() { return 'MailRenderedTemplate'; }
}

// @Route("/api/query/template", "GET")
export class QueryTemplates extends QueryDb_1<TemplateDO> implements IReturn<QueryResponse<TemplateDO>>
{

    public constructor(init?: Partial<QueryTemplates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<TemplateDO>(); }
    public getTypeName() { return 'QueryTemplates'; }
}

// @Route("/api/tissue/{id}", "GET")
export class GetTissue implements IReturn<GetTissueResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetTissue>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTissueResponse(); }
    public getTypeName() { return 'GetTissue'; }
}

// @Route("/api/tissue", "POST")
export class CreateTissue implements IReturn<CreateTissueResponse>, IPost
{
    public tissue: Tissue;

    public constructor(init?: Partial<CreateTissue>) { (Object as any).assign(this, init); }
    public createResponse() { return new CreateTissueResponse(); }
    public getTypeName() { return 'CreateTissue'; }
}

// @Route("/api/tissue/{id}", "PUT")
export class UpdateTissue implements IReturn<UpdateTissueResponse>, IPut
{
    public tissue: Tissue;

    public constructor(init?: Partial<UpdateTissue>) { (Object as any).assign(this, init); }
    public createResponse() { return new UpdateTissueResponse(); }
    public getTypeName() { return 'UpdateTissue'; }
}

// @Route("/api/tissue/{id}", "DELETE")
export class DeleteTissue implements IReturn<DeleteTissueResponse>, IDelete
{
    public id: number;

    public constructor(init?: Partial<DeleteTissue>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteTissueResponse(); }
    public getTypeName() { return 'DeleteTissue'; }
}

// @Route("/api/query/tissue", "GET")
export class QueryTissues extends QueryDb_2<TissueDO, Tissue> implements IReturn<QueryResponse<Tissue>>
{

    public constructor(init?: Partial<QueryTissues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new QueryResponse<Tissue>(); }
    public getTypeName() { return 'QueryTissues'; }
}

// @Route("/api/tooltips", "GET")
export class GetTooltips implements IReturn<GetTooltipsResponse>, IGet
{

    public constructor(init?: Partial<GetTooltips>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTooltipsResponse(); }
    public getTypeName() { return 'GetTooltips'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin: boolean;

    // @DataMember(Order=9)
    public continue: string;

    // @DataMember(Order=10)
    public errorView: string;

    // @DataMember(Order=11)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Register>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegisterResponse(); }
    public getTypeName() { return 'Register'; }
}

