/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Link, LinkProps, NavLink, NavLinkProps } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

/**
 * This helper returns a Material-UI ListItem with a react-router-dom Link as the
 * component. 
 * 
 * @augments {ListItem} (Material-UI) and {Link} (react-router-dom)
 * @returns A ListItem whose component is a Link
 */
export function ListItemLink(props: LinkProps) {
    return <ListItem button component={Link} {...props} />;
}

/**
 * This helper returns a Material-UI ListItem with a react-router-dom NavLink as the
 * component. 
 * 
 * @augments {ListItem} (Material-UI) and {NavLink} (react-router-dom)
 * @returns A ListItem whose component is a NavLink
 */
export function ListItemNavLink(props: NavLinkProps) {
    const { to } = props;

    const renderLink = React.useMemo(
        () =>
          React.forwardRef((linkProps, ref) => (
            <NavLink to={to} {...linkProps} innerRef={ref as any} />
          )),
        [to],
      );

    return <ListItem button component={renderLink} {...props} />;
}

