/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useState } from 'react';
import { match, Route } from 'react-router';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';

import { Tab, Tabs, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import BarChartIcon from '@material-ui/icons/BarChart';
import PublicIcon from '@material-ui/icons/Public';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { HeaderHeightPx } from '../../constants/Layout';
import { useSelector } from '../../hooks/useTypedSelector';
import { infectedPremiseSearchSelectors } from '../../store/reducers/infectedPremises/infectedPremiseSearch';
import useWindowSize from '../../hooks/useWindowSize';
import { InfectedPremiseChart } from '../charts/TestChart';
import { EpidemicMap } from './EpidemicMap';
import { EpidemicResultsContext } from './EpidemicResultsContext';
import { differenceInCalendarDays } from 'date-fns';
import { queryString } from '@servicestack/client';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const TabsHeightPx = 72;
const TimerHeightPx = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chartPaper: {
            padding: theme.spacing(1, 2),
            marginLeft: '128px',
            marginRight: '128px'
        },
        ipTitle: {
            paddingBottom: theme.spacing(2),
        },
        tabs: {
            height: TabsHeightPx
        },
    }),
);

interface IViewEpidemicParams {
    analysisId?: string;
    networkId?: string;
    clusterId?: string;
    clusterZoom?: string;
    infectedPremiseId?: string;
}

interface IViewEpidemicProps {
    history: History;
    match: match<IViewEpidemicParams>;
}

const MapTabName = "map";
const GraphTabName = "graph";

export const ViewEpidemic: React.FunctionComponent<IViewEpidemicProps> = ({ history, match }) => {
    const classes = useStyles();

    const params = parseQueryString(history.location.search);

    const analysisId = params && params.analysisId && Number(params.analysisId) != NaN ? Number(params.analysisId) : undefined;
    const networkId = params && params.networkId && Number(params.networkId) != NaN ? Number(params.networkId) : undefined;
    const filteredClusterId = params && params.clusterId && Number(params.clusterId) != NaN ? Number(params.clusterId) : undefined;
    const filteredClusterZoom = params && params.zoomToCluster && params.zoomToCluster ? true : false;
    const filteredInfectedPremiseId = params && params.infectedPremiseId && Number(params.infectedPremiseId) != NaN ? Number(params.infectedPremiseId) : undefined;

    const epidemic = useSelector(state => state.epidemic.data);

    const ipData = useSelector(infectedPremiseSearchSelectors.data)

    const [tabValue, setTabValue] = React.useState(0);

    function handleTabChange(event: React.ChangeEvent<{}>, newValue: string)
    {
        history.push(match && match.url ? `${match.url}/${newValue}${history.location.search}` : "/404");
    }

    const windowSize = useWindowSize();

    const mapHeight = windowSize.height - HeaderHeightPx - TabsHeightPx - TimerHeightPx;

    const [currentTime, setCurrentTime] = useState(undefined);

    const [currentStartDate, setCurrentStartDate] = useState<Date | undefined>(undefined);
    const [currentEndDate, setCurrentEndDate] = useState<Date | undefined>(undefined);

    const setCurrentDates = (startDate: Date | undefined, endDate: Date | undefined) => {
        setCurrentStartDate(startDate);
        setCurrentEndDate(endDate);
    }

    return <EpidemicResultsContext.Provider value={{
        currentStartDate: currentStartDate,
        currentEndDate: currentEndDate,
        currentTime: currentTime,
        setCurrentTime: (v) => setCurrentTime(v),
        setCurrentDates: (s, e) => setCurrentDates(s, e)
    }}>
        <Route path={`${match.path}/:tabName`}>
            {
                routeProps =>
                {
                    const tabName = routeProps && routeProps.match && routeProps.match.params.tabName;
                    return <>
                        <Tabs className={classes.tabs}
                            value={tabName}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary"
                            aria-label="Map tabs">
                            <Tab
                                icon={<PublicIcon />}
                                label="MAP"
                                value="map"
                            />
                            <Tab
                                icon={<BarChartIcon />}
                                label="GRAPHS"
                                value={GraphTabName}
                            />
                        </Tabs>
                        <TabPanel value={tabName} index={MapTabName}>
                            <EpidemicMap
                                analysisId={analysisId}
                                networkId={networkId}
                                clusterId={filteredClusterId}
                                clusterZoom={filteredClusterZoom}
                                infectedPremiseId={filteredInfectedPremiseId}
                                epidemic={epidemic}
                                history={history}
                                match={match}
                                mapHeight={mapHeight}
                                isMapHidden={tabName !== MapTabName}
                            />
                        </TabPanel>
                        <TabPanel value={tabName} index={GraphTabName}>
                            <Box m={2}>
                                <Paper className={classes.chartPaper}>
                                    <Typography className={classes.ipTitle} variant="h5">Premise Breakdown</Typography>
                                    <InfectedPremiseChart
                                        data={ipData && ipData.results}
                                        simpleCount={true}
                                        dateInterval={
                                            currentEndDate &&
                                            currentStartDate &&
                                            differenceInCalendarDays(currentEndDate, currentStartDate) > 14 ? 7 : 1
                                        }
                                    />
                                </Paper>
                            </Box>
                        </TabPanel>
                    </>
                }
            }
            </Route>
    </EpidemicResultsContext.Provider>
}


interface ITabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {children}
        </Typography>
    );
}