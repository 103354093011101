/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple groups.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type IInitialGroupSearchState = ISearchState<SpreadDtos.QueryPersonGroup>;

export interface IGroupSearchState {
    groupSearch: IInitialGroupSearchState;
}

export const initialState: IGroupSearchState = {
    groupSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const groupsModule = createAutoQueryModule(
    'groupSearch',
    initialSearchState,
    '@@groupSearch',
    SpreadDtos.QueryGroups,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        canAssignRoleId: options.specialOptions.canAssignRoleId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const groupSearchActions = groupsModule.actions;
export const groupSearchEpics = groupsModule.epics;
export const groupsApi = groupsModule.api;
export const groupsSelectors = groupsModule.selectors;
export const groupsHooks = groupsModule.hooks;
export default groupsModule.reducer;
