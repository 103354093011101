/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { isNumber } from 'util';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { ObjectType } from '../../dtos/Spread.dtos';
import { usePersonLoad } from '../../hooks/person';
import { epidemicsHooks } from '../../store/reducers/epidemics/epidemicSearch';
import { groupsHooks } from '../../store/reducers/groups/groupSearch';
import { personRoleActions, personRoleSelectors } from '../../store/reducers/roles/personRole';
import { rolesHooks } from '../../store/reducers/roles/roleSearch';
import { RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IPersonRoleEditFormProps {
    epidemicId?: number;
    groupId?: number;
    personId?: number;
    history: History;
    loadingPersonRole?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

export interface IPersonRoleFormSchema {
    objectId?: number;
    personId?: number;
    roleId?: number;
}

const PersonRoleSchema = Yup.object<IPersonRoleFormSchema>().shape({
    objectId: Yup.number().when('$objectTypeId', (objectTypeId: number, schema: Yup.NumberSchema<number>) => (
        objectTypeId == ObjectType.Epidemic ? schema.label('Epidemic').required() :
            objectTypeId == ObjectType.PersonGroup ? schema.label('Group').required() :
                schema.label('This field')
    )),
    personId: Yup.number().label('Person').required(),
    roleId: Yup.number().label('Role').required(),
});

const validation = Yup.object().shape({
    object: PersonRoleSchema.notRequired()
})

const PersonRoleEditForm: React.FunctionComponent<IPersonRoleEditFormProps> = ({
    history,
    loadingPersonRole,
    personId,
    groupId,
    epidemicId,
    url,
}) => {
    const classes = useStyles();

    const [roleId, setRoleId] = React.useState(undefined as number | undefined);

    const lockedObjectId = groupId !== undefined ? groupId : epidemicId !== undefined ? epidemicId : undefined;
    const assignRoleObjectTypeId = groupId ? ObjectType.PersonGroup : epidemicId ? ObjectType.Epidemic : undefined;

    const specOps = React.useMemo(() => ({ canAssignOnly: true, assignRoleObjectTypeId: assignRoleObjectTypeId }), [assignRoleObjectTypeId]);
    const pagOps = React.useMemo(() => ({ orderBy: 'displayName', skip: 0, take: 200 }), []);

    const epSpecOps = React.useMemo(() => ({ canAssignRoleId: roleId }), [roleId]);
    const epPagOps = React.useMemo(() => ({ skip: 0, take: 100, orderBy: "Name" }), []);

    const grSpecOps = React.useMemo(() => ({ canAssignRoleId: roleId }), [roleId]);
    const grPagOps = React.useMemo(() => ({ skip: 0, take: 100, orderBy: "DisplayName" }), []);

    const [rolesData, , , rolesRequestState] = rolesHooks.useSearch(specOps, pagOps);
    const [epidemicData, , , epidemicRequestState] = epidemicsHooks.useSearch(epSpecOps, epPagOps);
    const [groupData, , , groupRequestState] = groupsHooks.useSearch(grSpecOps, grPagOps);

    const selectedRole = roleId ? rolesData && rolesData.results.find(r => r.id == roleId) : undefined;

    const roleOptions = rolesData && rolesData.results && rolesData.results.length > 0 ?
        rolesData.results.map((role =>
            <MenuItem
                key={role.id}
                value={role.id}
            >
                {role.displayName}
            </MenuItem>
        )) :
        [];

    const objectOptions = selectedRole ?
        (
            selectedRole.assignRoleObjectTypeId == ObjectType.Epidemic &&
            epidemicRequestState.state !== RequestState.Pending &&
            epidemicData && epidemicData.results && epidemicData.results.length > 0
        ) ?
            [<MenuItem
                key={'-1'}
                value={'-1'}
            >
                All Epidemics
            </MenuItem>].concat(epidemicData.results.map((e =>
                <MenuItem
                    key={e.id}
                    value={e.id}
                >
                    {e.name}
                </MenuItem>
            ))) :
            (
                selectedRole.assignRoleObjectTypeId == ObjectType.PersonGroup &&
                groupRequestState.state !== RequestState.Pending &&
                groupData && groupData.results && groupData.results.length > 0
            ) ?
                [<MenuItem
                    key={'-1'}
                    value={'-1'}
                >
                    All Groups
                </MenuItem>].concat(groupData.results.map((g =>
                    <MenuItem
                        key={g.id}
                        value={g.id}
                    >
                        {g.displayName}
                    </MenuItem>
                ))) :
                [] :
        undefined;

    const personIdLoaded = React.useMemo(() => personId, [personId]);

    const [personData, personState] = usePersonLoad(personIdLoaded || -1);
    const loadingPerson = personState.state == RequestState.Pending;

    const wrappedObject = undefined;

    return <EditForm
        actions={personRoleActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingPersonRole}
        url={url}
        successRouteCallback={() => {
            let url1 = url.substr(0, url.lastIndexOf('/'));
            return url1.substr(0, url1.lastIndexOf('/'));
        }}
        validate={validation}
        validateContext={{
            objectTypeId: selectedRole && selectedRole.assignRoleObjectTypeId
        }}
        selectors={personRoleSelectors}
    >{() => <>
        {
            //<Typography variant="h4" component="h4">{!loadingPerson ? personData && personData.displayName : <Skeleton />}</Typography>
        }
        <FormSpy>
            {props => <Field
                name="object.roleId"
                subscription={{ value: true }}
                render={field => {
                    const value = field.input.value;
                    if (isNumber(value) && value > 0 && value !== roleId) {
                        setRoleId(value);
                    }
                    return null;
                }}
            />}
        </FormSpy>
        <Grid item lg={6} xs={12}>
            {
                rolesRequestState.state !== RequestState.Pending &&
                <Field
                    required
                    name="object.roleId"
                    component={SelectWrapper}
                    formControlProps={{
                        className: classes.formControl,
                        fullWidth: true,
                        required: true
                    }}
                    label="Role"
                >
                    <MenuItem value={0}></MenuItem>
                    {roleOptions}
                </Field>
            }
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            {
                selectedRole && selectedRole.assignRoleObjectTypeId ?
                    epidemicRequestState.state == RequestState.Success ||
                        groupRequestState.state == RequestState.Success ?
                        <Field
                            required
                            name="object.objectId"
                            component={SelectWrapper}
                            defaultValue={lockedObjectId}
                            disabled={lockedObjectId !== undefined}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label={selectedRole.assignRoleObjectTypeId == ObjectType.Epidemic ? 'Epidemic' : 'Group'}
                        >
                            <MenuItem value={0}></MenuItem>
                            {objectOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.objectIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label={selectedRole.assignRoleObjectTypeId == ObjectType.Epidemic ? 'Epidemic' : 'Group'}
                            defaultValue="Loading..."
                        /> :
                    undefined
            }
        </Grid>
        <Grid item hidden lg={6} xs={12}>
            <Field
                disabled
                name="object.personId"
                component={TextFieldWrapper}
                formControlProps={{
                    className: classes.formControl,
                    fullWidth: true,
                    required: true
                }}
                label="Person"
                defaultValue={personId}
            />
        </Grid>
    </>}
    </EditForm>
};

export default PersonRoleEditForm;