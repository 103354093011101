/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Table, TableCell, TableRow } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../../constants/Dates';
import { ILength, InRun, VirusSurvival } from '../../../dtos/Spread.dtos';
import { getEnumKeyByEnumValue } from '../../../helpers/enumDescription';
import { useAnalysisTapmLoad } from '../../../hooks/analysisTapm';
import { RequestState } from '../../../types/RequestState';
import { ViewAnalysisTapm } from './ViewAnalysisTapm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        buttonView: {
            marginTop: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(3, 2)
        },
    }),
);

interface IViewEpidemicParams {
    analysisJobId: string;
    epidemicId: string;
}

interface IAnalysisTapmHomeProps {
    analysisJobId?: number;
    history: History;
    match: match<IViewEpidemicParams>;
}

export const AnalysisTapmHome: React.FunctionComponent<IAnalysisTapmHomeProps> = ({ analysisJobId, history, match }) => {
    const classes = useStyles();

    const analysisJobIdTemp = analysisJobId || (match.params ? parseInt(match.params.analysisJobId) : undefined);
    const analysisJobIdLoaded = React.useMemo(() => analysisJobIdTemp, [analysisJobIdTemp]);

    if (!analysisJobIdLoaded) {
        throw Error("Invalid ID specified");
    }

    const refreshIntervalSeconds = 60;

    const [analysisJobData, analysisJobState] = useAnalysisTapmLoad(analysisJobIdLoaded, refreshIntervalSeconds);

    return <Box className={classes.root} component="div">
        <ViewAnalysisTapm
            analysisJob={analysisJobData}
            className={classes.layoutItem}
            loading={analysisJobState.state === RequestState.Pending}
            match={match}
            refreshIntervalSeconds={refreshIntervalSeconds}
        />
        <Box className={classes.layoutItem}>
            <Paper className={classes.paper}>
                <Typography variant="h6" component="span">Model Parameters</Typography>
                {
                    <div>
                        <div>
                            <Typography variant="body1" component="span">Limit Start Date: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? analysisJobData.limitRunStartDate ? format(toDate(analysisJobData.limitRunStartDate), DateTimeFormat) : 'None' : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Limit End Date: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? analysisJobData.limitRunEndDate ? format(toDate(analysisJobData.limitRunEndDate), DateTimeFormat) : 'None' : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Global Emission Rate: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? analysisJobData.globalEmissionRate : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Excretion Run: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? analysisJobData.excretionRunId : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Forecast Length: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? `${getEnumKeyByEnumValue(ILength, analysisJobData.iLength)}` : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Nesting Level: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? `${getEnumKeyByEnumValue(InRun, analysisJobData.inRun)}` : <Skeleton width={200} />}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body1" component="span">Virus Survival: </Typography>
                            <Typography variant="body1" component="span">
                                {analysisJobData ? `${getEnumKeyByEnumValue(VirusSurvival, analysisJobData.virusSurvival)}` : <Skeleton width={200} />}
                            </Typography>
                        </div>
                    </div>
                }
            </Paper>
        </Box>
        <Box className={classes.layoutItem}>
            <Paper className={classes.paper}>
                {
                    analysisJobData && analysisJobData.infectedPremises ? <div>
                        <Typography variant="h6" component="span">Infected Premises</Typography>
                        <Table>
                            <TableRow>
                                <TableCell>Infected Premise ID</TableCell>
                                <TableCell>Excretion Start Date</TableCell>
                                <TableCell>Excretion End Date</TableCell>
                                <TableCell>Excretion Values</TableCell>
                            </TableRow>
                            {
                                analysisJobData.infectedPremises.map((ip) =>
                                (
                                    <TableRow>
                                        <TableCell>{ip.infectedPremiseId}</TableCell>
                                        <TableCell>{analysisJobData.excretionValues && analysisJobData.excretionValues.filter(ev => ip.areaId == ev.areaId)[0] && format(toDate(analysisJobData.excretionValues.filter(ev => ip.areaId == ev.areaId)[0].date), DateTimeFormat)}</TableCell>
                                        <TableCell>{analysisJobData.excretionValues && analysisJobData.excretionValues.filter(ev => ip.areaId == ev.areaId).slice(-1)[0] && format(toDate(analysisJobData.excretionValues.filter(ev => ip.areaId == ev.areaId).slice(-1)[0].date), DateTimeFormat)}</TableCell>
                                        <TableCell>{analysisJobData.excretionValues && analysisJobData.excretionValues.filter(ev => ip.areaId == ev.areaId).map(ev => (ev.value + ", "))}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </Table>
                    </div>
                        : null
                }
            </Paper>
        </Box>
        <Box className={classes.layoutItem}>
        </Box>
    </Box>
}
