/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSubClusterLoad } from '../../hooks/subCluster';
import { RequestFormState } from '../../types/RequestState';
import { ViewInfectedPremises } from '../infectedPremise/ViewInfectedPremises';
import { ViewSubCluster } from './ViewSubCluster';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface ISubClusterHomeParams {
    subClusterId: string;
}

interface ISubClusterHomeProps {
    subClusterId?: number;
    history: History;
    match: match<ISubClusterHomeParams>;
}

export const SubClusterHome: React.FunctionComponent<ISubClusterHomeProps> = ({ subClusterId, history, match }) => {
    const classes = useStyles();

    const subClusterIdTemp = subClusterId || (match.params ? parseInt(match.params.subClusterId) : undefined);
    const loadedSubClusterId = React.useMemo(() => subClusterIdTemp, [subClusterIdTemp]);

    if (!loadedSubClusterId) {
        throw Error("Invalid ID specified");
    }

    const [subClusterData, subClusterState] = useSubClusterLoad(loadedSubClusterId);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewSubCluster
                subCluster={subClusterData}
                loading={subClusterState.state === RequestFormState.Pending}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewInfectedPremises
                history={history}
                match={match}
                subClusterId={loadedSubClusterId}
            />
        </Box>
    </Box>
}
