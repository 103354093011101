import * as React from 'react';

interface IMockFormContext {
    fields: IMockFormState;
    //getField: (key: string) => any;
    setField: (key: string, value: any) => void;
}

export type IMockFormState = {
    MockDataSetYear?: number;

    spec_epidemic?: string;
    spec_cluster?: string;
    spec_ip?: string;
    spec_species?: string;
    spec_datecollection?: string;
    spec_specid?: string;
    spec_tissuespecimen?: string;
    spec_nlis?: string;
    spec_dateisolate?: string;
    spec_materialsent?: string;
    seq_enrichmentMethod?: string;
    seq_sequencingLaboratory?: string;
    seq_dateOfSequencing?: string;
    seq_sequencingTechnician?: string;
    seq_sequencingSystem?: string;
    seq_platform?: string;
    seq_libraryStrategy?: string;
    seq_pairing?: string;
    seq_instrument?: string;
    seq_comments?: string;
    up_file?: any;
    up_file2?: any;
    up_sequenceId?: string;
    assem_isolate?: string;
    assem_assemblysequence?: string;
    assem_assemblymethod?: string;
    assem_isolatereference?: string;
    assem_genotypeserotype?: string;
    assem_genotypegenotype?: string;
    assem_vp1serotype?: string;
    assem_vp1genotype?: string;
    align_epidemic?: string;
    align_cluster?: string;
    align_ips?: string[];
    align_assessment?: string;
    align_comments?: string;
    seqqual_genomeId?: string;
    seqqual_epidemic?: string;
    seqqual_cluster?: string;
    seqqual_assessment?: string;
    seqqual_comments?: string;

    seqano_genomeId?: string;
    seqano_cluster?: string;
    seqano_epidemic?: string;
    seqano_assemblycomments?: string;
    seqano_assessment?: string;
    seqano_comments?: string;
}

export const MockFormContext = React.createContext<IMockFormContext>({
    fields: {},
    //getField: (key: string) => {},
    setField: (key: string, value: any) => {}
});
