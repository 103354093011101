/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestFormState } from '../types/RequestState';
import { createCrudSelectors, IBaseCrudActions } from '../store/reducers/common/crud';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export default function useEditForm<DataType>(
    actions: IBaseCrudActions,
    selectors: ReturnType<typeof createCrudSelectors>,
    editMode: boolean
): [DataType, RequestFormState] {
    const dispatch = useDispatch();
    const requestState = useSelector(s => editMode ? selectors.updateState(s) : selectors.createState(s)).state;
    const data = useSelector(s => selectors.form(s));

    useEffect(() => {
        return function cleanup() {
            dispatch(actions.clearFormState());
        }
    }, [actions, dispatch])

    return [
        data,
        requestState
    ];
}