/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { SubNationalList } from './SubNationalList';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        timelineImageWrapper: {
            textAlign: 'center'
        },
        timelineImage: {
            width: '50%',
        }
    }),
);

interface IViewSubNationalsParams {
    countryId?: string;
}

interface IViewSubNationalsProps {
    className?: string;
    editMode?: boolean;
    countryId?: number;
    history: History;
    match: match<IViewSubNationalsParams>;
}

export const ViewSubNationals: React.FunctionComponent<IViewSubNationalsProps> = (props) => {
    const classes = useStyles({});

    let url = props.match.url;
    url = (url.endsWith('/edit') ? url.substr(0, url.lastIndexOf('/edit')) : url) + (!url.endsWith('/subNational') ? '/subNational' : '');
    const countryId = props.countryId || (props.match.params && props.match.params.countryId ? parseInt(props.match.params.countryId) : undefined);

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Sub Nationals</Typography>
        <SubNationalList
            className={classes.list}
            editMode={props.editMode}
            countryId={countryId}
            history={props.history}
            url={url}
        />
        {props.editMode ?
            <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                <HasPermission permission="CreateSubNational">
                    <ButtonLink
                        aria-label="Create Sub National"
                        color="primary"
                        variant="contained"
                        to={`${url}/create`}
                    >
                        <AddBoxIcon className={classes.buttonSubmitIcon} />
                        Create
                    </ButtonLink>
                </HasPermission>
            </Box> :
            undefined
        }
    </Paper>
}