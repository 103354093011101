/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple sub-clusters.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSequenceSearchState = ISearchState<SpreadDtos.QuerySequence>;

export interface ISequenceSearchState {
    sequenceSearch: IInitialSequenceSearchState;
}

export const initialState: ISequenceSearchState = {
    sequenceSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const sequencesModule = createAutoQueryModule(
    'sequenceSearch',
    initialSearchState,
    '@@sequenceSearch',
    SpreadDtos.QuerySequences,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        specimenId: options.specialOptions.specimenId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const sequenceSearchActions = sequencesModule.actions;
export const sequenceSearchEpics = sequencesModule.epics;
export const sequencesApi = sequencesModule.api;
export const sequencesSelectors = sequencesModule.selectors;
export const sequencesHooks = sequencesModule.hooks;
export default sequencesModule.reducer;