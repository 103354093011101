/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AreaDateType } from '../dtos/Spread.dtos';
import { areaDateTypeActions } from '../store/reducers/areaDateTypes/areaDateType';
import { IFormRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useAreaDateTypeLoad(areaDateTypeId: number): [AreaDateType | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.areaDateType.data);
    const requestState = useSelector(state => state.areaDateType.states.loadState);

    useEffect(() => {
        dispatch(areaDateTypeActions.load(areaDateTypeId))

        return function cleanup() {
            dispatch(areaDateTypeActions.clear())
        }
    }, [dispatch, areaDateTypeId]);

    return [
        data,
        requestState
    ];
}

export function useAreaDateTypeDelete(areaDateTypeId: number): [AreaDateType | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.areaDateType.data);
    const requestState = useSelector(state => state.areaDateType.states.deleteState) as IFormRequestState;

    useEffect(() => {
        if (areaDateTypeId)
            dispatch(areaDateTypeActions.delete(areaDateTypeId))

        return function cleanup() {
            dispatch(areaDateTypeActions.clear())
        }
    }, [dispatch, areaDateTypeId]);

    return [
        data,
        requestState
    ];
}
