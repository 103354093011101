/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple species.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSpeciesSearchState = ISearchState<SpreadDtos.Species>;

export interface ISpeciesSearchState {
    speciesSearch: IInitialSpeciesSearchState;
}

export const initialState: ISpeciesSearchState = {
    speciesSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const speciesModule = createAutoQueryModule(
    'speciesSearch',
    initialSearchState,
    '@@speciesSearch',
    SpreadDtos.QuerySpecies,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const speciesSearchActions = speciesModule.actions;
export const speciesSearchEpics = speciesModule.epics;
export const speciesSearchApi = speciesModule.api;
export const speciesSearchSelectors = speciesModule.selectors;
export const speciesSearchHooks = speciesModule.hooks;
export default speciesModule.reducer;