/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Button, CircularProgress, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../constants/Dates';
import { useSelector } from '../../hooks/useTypedSelector';
import { areaDateActions } from '../../store/reducers/areaDates/areaDate';
import { RequestFormState } from '../../types/RequestState';
import { HasPermission } from '../common/HasPermission';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAreaDateParams {
    areaId: string;
    areaDateId: string;
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
}

interface IViewAreaDateProps {
    areaDateId?: number;
    history: History;
    match: match<IViewAreaDateParams>;
}

export const ViewAreaDate: React.FunctionComponent<IViewAreaDateProps> = (props) => {
    const classes = useStyles();
    const areaDateState = useSelector(state => state.areaDate);
    const dispatch = useDispatch();

    const areaDateId = props.areaDateId || (props.match.params ? parseInt(props.match.params.areaDateId) : undefined);
    const epidemicId = props.match.params ? parseInt(props.match.params.epidemicId) : undefined;

    useEffect(() => {
        dispatch(areaDateActions.load(areaDateId))

        return function cleanup() {
            dispatch(areaDateActions.clear());
        }
    }, [areaDateId, dispatch]);

    const handleEdit = useCallback(
        () => props.history.push(`${props.match.url}/edit`),
        [props.history, props.match.url],
    );

    return <>
        <Paper className={classes.root}>
            {
                !areaDateState.data || areaDateState.states.loadState.state === RequestFormState.Pending
                    ? <CircularProgress />
                    : <>
                        <Typography variant="h4" component="h4">{areaDateState.data.name}</Typography>
                        <Typography variant="h5" component="h5">{format(toDate(areaDateState.data.createdDate), DateTimeFormat)}</Typography>
                        <Typography variant="h5" component="h5">Type: {
                            areaDateState.data.areaDateType ?
                                areaDateState.data.areaDateType.name :
                                'Unknown'
                        }</Typography>
                        <Typography variant="h5" component="h5">Date: {
                            areaDateState.data.date ?
                                format(toDate(areaDateState.data.date), DateTimeFormat) :
                                'Unknown'
                        }</Typography>
                        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                            <HasPermission permission="UpdateAreaDate" objectId={epidemicId}>
                                <Button
                                    aria-label="Edit Date"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleEdit}
                                >
                                    <EditIcon className={classes.buttonSubmitIcon} />
                                    Edit
                                </Button>
                            </HasPermission>
                        </Box>
                    </>
            }
        </Paper>
    </>
}