/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple infected premises.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialInfectedPremiseSearchState = ISearchState<SpreadDtos.InfectedPremise>;

export interface IInfectedPremiseSearchState {
    infectedPremiseSearch: IInitialInfectedPremiseSearchState;
}

export const initialState: IInfectedPremiseSearchState = {
    infectedPremiseSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const infectedPremiseSearchModule = createAutoQueryModule(
    'infectedPremiseSearch',
    initialSearchState,
    '@@infectedPremiseSearch',
    SpreadDtos.QueryInfectedPremises,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        ...options.specialOptions
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const infectedPremiseSearchActions = infectedPremiseSearchModule.actions;
export const infectedPremiseSearchEpics = infectedPremiseSearchModule.epics;
export const infectedPremiseSearchApi = infectedPremiseSearchModule.api;
export const infectedPremiseSearchSelectors = infectedPremiseSearchModule.selectors;
export const infectedPremiseSearchHooks = infectedPremiseSearchModule.hooks;
export default infectedPremiseSearchModule.reducer;