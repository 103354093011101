/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import classNames from 'classnames';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ButtonLink from '../../common/ButtonLink';
import { HasPermission } from '../../common/HasPermission';
import { GenomicNetworkJobList } from './GenomicNetworkJobList';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewGenomicNetworksParams { }

interface IViewGenomicNetworksProps {
    className?: string;
    epidemicId: number;
    match: match<IViewGenomicNetworksParams>;
}

export const ViewGenomicNetworkJobs: React.FunctionComponent<IViewGenomicNetworksProps> = ({ className, epidemicId, match }) => {
    const url = match.url + (!match.url.endsWith('/genomicNetwork') ? '/genomicNetwork' : '');
    const classes = useStyles();

    return <Paper className={classNames(className, classes.root)}>
        <Typography variant="h4" component="h4">Genomic Networks</Typography>
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateGenomicNetworkJob" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Queue Genomic Network Job"
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Queue Genomic Network Job
                </ButtonLink>
            </HasPermission>
        </Box>
        <GenomicNetworkJobList
            className={classes.list}
            url={url}
        />
    </Paper>
}