/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an livestockDefault
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    Livestock,
    CreateLivestockDefault,
    CreateLivestockDefaultResponse,
    GetLivestockDefault,
    GetLivestockDefaultResponse,
    UpdateLivestockDefault,
    UpdateLivestockDefaultResponse,
    DeleteLivestockDefault,
    DeleteLivestockDefaultResponse
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ILivestockDefaultState {
    livestockDefault: IBaseCrudState<Livestock>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const livestockDefaultModule = createCrudModule<Livestock, CreateLivestockDefaultResponse, GetLivestockDefaultResponse, UpdateLivestockDefaultResponse, DeleteLivestockDefaultResponse, undefined>(
    'livestockDefault',
    CreateLivestockDefault,
    GetLivestockDefault,
    UpdateLivestockDefault,
    DeleteLivestockDefault,
    undefined,
)


export default livestockDefaultModule;