/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { subClusterActions, subClusterSelectors } from '../../store/reducers/subClusters/subCluster';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISubClusterEditFormProps {
    clusterId?: number;
    editSubCluster?: SpreadDtos.SubCluster;
    history: History;
    loadingSubCluster?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface ISubClusterFormSchema extends Partial<SpreadDtos.SubCluster> { }

const subClusterSchema = Yup.object<ISubClusterFormSchema>().shape({
    name: Yup.string().label('Sub-cluster name')
        .required(),
    clusterId: Yup.number().label('Cluster')
        .required(),
});

const validation = Yup.object().shape({
    object: subClusterSchema.notRequired()
})

const SubClusterEditForm: React.FunctionComponent<ISubClusterEditFormProps> = ({
    editSubCluster,
    history,
    loadingSubCluster,
    url,
}) => {
    const editMode = editSubCluster !== undefined && editSubCluster.id !== undefined;

    const wrappedObject = {
        object: editSubCluster
    };

    return <EditForm
        actions={subClusterActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingSubCluster}
        url={url}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        validate={validation}
        selectors={subClusterSelectors}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Sub-Cluster Name"
            />
        </Grid>
    </>}
    </EditForm>
};

export default SubClusterEditForm;