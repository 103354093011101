/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isNumber } from 'util';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { formatDate, parseDate } from '../../../helpers/dateHelpers';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import { enrichmentMethodSearchHooks } from '../../../store/reducers/enrichmentMethods/enrichmentMethodSearch';
import { instrumentSearchHooks } from '../../../store/reducers/instruments/instrumentSearch';
import { libraryStrategySearchHooks } from '../../../store/reducers/libraryStrategys/libraryStrategySearch';
import { platformSearchHooks } from '../../../store/reducers/platforms/platformSearch';
import sequenceModule from '../../../store/reducers/sequences/sequence';
import { RequestFormState, RequestState } from '../../../types/RequestState';
import ButtonLink from '../../common/ButtonLink';
import EditForm from '../../forms/EditForm';
import DatePickerWrapper from '../../forms/FinalFormControls/DatePickerWrapper';
import FileWrapper from '../../forms/FinalFormControls/FileWrapper';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../../forms/mutators';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISequenceEditFormProps {
    editSequence?: SpreadDtos.Sequence;
    history: History;
    loadingSequence?: boolean;
    uploadType?: "fastQ" | "fastA";
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonAssemble: {
            margin: theme.spacing(1),
        },
        buttonAssembleIcon: {
            marginRight: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface ISequenceFormSchema extends Partial<SpreadDtos.Sequence> { }

const sequenceSchema = Yup.object<ISequenceFormSchema>().shape({
    specimenId: Yup.number().label('Specimen')
        .required(),
    spreadSequenceId: Yup.string().label('SPREAD Sequence ID')
        .required(),
    fastATempFile: Yup.mixed().label('FastA File')
        .notRequired(),
    fastQTempFile1: Yup.mixed().label('FastQ File')
        .notRequired(),
    fastQTempFile2: Yup.mixed().label('FastQ File')
        .notRequired(),
    enrichmentMethodId: Yup.number().label('Enrichment Method')
        .notRequired(),
    laboratory: Yup.string().label('Sequencing Laboratory')
        .notRequired(),
    date: Yup.string().label('Date of Sequencing')
        .notRequired(),
    technician: Yup.string().label('Sequencing Technician')
        .notRequired(),
    systemId: Yup.number().label('Sequencing System')
        .notRequired(),
    platformId: Yup.number().label('Platform')
        .notRequired(),
    libraryStrategyId: Yup.number().label('Library Strategy')
        .notRequired(),
    pairedEnd: Yup.mixed().label('This')
        .notRequired(),
    instrumentId: Yup.number().label('Instrument')
        .notRequired(),
    comment: Yup.string().label('Comment')
        .notRequired(),
});

const validation = Yup.object().shape({
    object: sequenceSchema.notRequired(),
})

const SequenceEditForm: React.FunctionComponent<ISequenceEditFormProps> = ({
    editSequence,
    history,
    loadingSequence,
    uploadType,
    url,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles({});

    const [systemId, setSystemId] = React.useState(undefined as number | undefined);
    const [file1Uploading, setFile1Uploading] = React.useState(false);
    const [file2Uploading, setFile2Uploading] = React.useState(false);

    const isGenbankRecord = React.useMemo(() => editSequence && editSequence.originName == "GenBank", [editSequence]);

    const wrappedObject = {
        object: editSequence,
    };

    // Setup the enrichmentMethod options
    const [enrichmentMethodId, setEnrichmentMethodId] = React.useState(undefined as number | undefined);
    if (!enrichmentMethodId && editSequence && editSequence.enrichmentMethodId) {
        setEnrichmentMethodId(editSequence.enrichmentMethodId);
    }
    const updateEnrichmentMethodId = (enrichmentMethodId: number | undefined) => {
        setEnrichmentMethodId(enrichmentMethodId);
    }
    const [enrichmentMethodData, , , enrichmentMethodRequestState] = enrichmentMethodSearchHooks.useSearch();
    const enrichmentMethodOptions = enrichmentMethodData && enrichmentMethodData.results && enrichmentMethodData.results.length > 0 ?
        enrichmentMethodData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup enrichmentMethod options

    // Setup the platform options
    const [platformId, setPlatformId] = React.useState(undefined as number | undefined);
    if (!platformId && editSequence && editSequence.platformId) {
        setPlatformId(editSequence.platformId);
    }
    const updatePlatformId = (platformId: number | undefined) => {
        setPlatformId(platformId);
    }
    const [platformData, , , platformRequestState] = platformSearchHooks.useSearch();
    const platformOptions = platformData && platformData.results && platformData.results.length > 0 ?
        platformData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup platform options

    // Setup the LibraryStrategy options
    const [libraryStrategyId, setLibraryStrategyId] = React.useState(undefined as number | undefined);
    if (!libraryStrategyId && editSequence && editSequence.libraryStrategyId) {
        setLibraryStrategyId(editSequence.libraryStrategyId);
    }
    const updateLibraryStrategyId = (libraryStrategyId: number | undefined) => {
        setLibraryStrategyId(libraryStrategyId);
    }
    const [libraryStrategyData, , , libraryStrategyRequestState] = libraryStrategySearchHooks.useSearch();
    const libraryStrategyOptions = libraryStrategyData && libraryStrategyData.results && libraryStrategyData.results.length > 0 ?
        libraryStrategyData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup libraryStrategy options

    // Setup the instrument options
    const [instrumentId, setInstrumentId] = React.useState(undefined as number | undefined);
    if (!instrumentId && editSequence && editSequence.instrumentId) {
        setInstrumentId(editSequence.instrumentId);
    }
    const updateInstrumentId = (instrumentId: number | undefined) => {
        setInstrumentId(instrumentId);
    }
    const [instrumentData, , , instrumentRequestState] = instrumentSearchHooks.useSearch();
    const instrumentOptions = instrumentData && instrumentData.results && instrumentData.results.length > 0 ?
        instrumentData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup instrument options

    // Assemble Sequence

    const assemblingState = useSelector((s) => sequenceModule.selectors.createAssemblyJobState(s));
    const [redirect, setRedirect] = React.useState(false);

    const assembleButton = editSequence && editSequence.id > 0 ?
        <ButtonLink
            key="sequence-assemblebutton"
            color="primary"
            variant="contained"
            size="small"
            className={classes.buttonAssemble}
            disabled={assemblingState.state == RequestFormState.Pending}
            to={`${url}/assemble`}
        >
            <LineStyleIcon className={classes.buttonAssembleIcon} />
            Re-Assemble
        </ButtonLink> :
        undefined;

    // QA Buttons

    const assemblyQAButton = editSequence && editSequence.id > 0 && editSequence.consensusSequenceId > 0 ?
        <ButtonLink
            key="sequence-assemblebutton"
            color="primary"
            variant="contained"
            size="small"
            className={classes.buttonAssemble}
            to={`${url}/consensusSequence/${editSequence.consensusSequenceId}/qa`}
        >
            Assembly QA
        </ButtonLink> :
        undefined;

    const annotationQAButton = editSequence && editSequence.id > 0 && editSequence.consensusSequenceId > 0 ?
        <ButtonLink
            key="sequence-assemblebutton"
            color="primary"
            variant="contained"
            size="small"
            className={classes.buttonAssemble}
            to={`${url}/consensusSequence/${editSequence.consensusSequenceId}/annotationqa`}
        >
            Annotation QA
        </ButtonLink> :
        undefined;

    if (assemblingState.state == RequestFormState.SubmitSuccess) {
        setRedirect(true);

        dispatch(sequenceModule.actions.clearFormState());
    }

    const redirectComponent: React.ReactNode | undefined = <Redirect push to={routeBackToPath(url, 5, "bioinformatics?link=1")} />;

    return <>
        {redirect ? redirectComponent : undefined}
        <EditForm
            actions={sequenceModule.actions}
            mutators={{ clear: clear }}
            edit={wrappedObject}
            extraButtons={[assemblyQAButton, annotationQAButton, assembleButton]}
            history={history}
            loading={!!loadingSequence}
            saveDisabled={file1Uploading || file2Uploading}
            url={url}
            successRouteCallback={(d) => d && d.id ? routeBackToPath(url, 4) : ''}
            validate={validation}
            selectors={sequenceModule.selectors}
        >{() => <>
            {
                uploadType == "fastQ" ?
                    <>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="object.fastQTempFile1"
                                label="FastQ File 1"
                            >
                                {
                                    props => (
                                        <FileWrapper
                                            accept=".fastq, .gz"
                                            buttonText={'Select FastQ File'}
                                            className={classes.formControl}
                                            label={'FastQ File'}
                                            maxFiles={1}
                                            onUploadStart={() => setFile1Uploading(true)}
                                            onUploadFinish={(success) => setFile1Uploading(false)}
                                            {...props}
                                        />
                                    )
                                }
                            </Field>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="object.fastQTempFile2"
                                label="FastQ File 2"
                            >
                                {
                                    props => (
                                        <FileWrapper
                                            accept=".fastq, .gz"
                                            buttonText={'Select FastQ File'}
                                            className={classes.formControl}
                                            label={'FastQ File'}
                                            maxFiles={1}
                                            onUploadStart={() => setFile2Uploading(true)}
                                            onUploadFinish={(success) => setFile2Uploading(false)}
                                            {...props}
                                        />
                                    )
                                }
                            </Field>
                        </Grid>
                        <Grid item xs={12} />
                    </> :
                    uploadType == "fastA" ?
                        <>
                            <Grid item lg={6} xs={12}>
                                <Field
                                    name="object.fastATempFile"
                                    label="FastA File"
                                >
                                    {
                                        props => (
                                            <FileWrapper
                                                accept=".fasta, .gz"
                                                buttonText={'Select FastA File'}
                                                className={classes.formControl}
                                                label={'FastA File'}
                                                maxFiles={1}
                                                onUploadStart={() => setFile1Uploading(true)}
                                                onUploadFinish={(success) => setFile1Uploading(false)}
                                                {...props}
                                            />
                                        )
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={12} />
                        </> :
                        undefined
            }
            <Grid item lg={6} xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.spreadSequenceId"
                    className={classes.formControl}
                    component={TextFieldWrapper}
                    inputProps={{
                        maxlength: 11,
                    }}
                    type="text"
                    label="SPREAD Sequence ID"
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item lg={6} xs={12}>
                {
                    (enrichmentMethodRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            name="object.enrichmentMethodId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Enrichment method"
                        >
                            {enrichmentMethodOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.enrichmentMethodIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Enrichment method"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.enrichmentMethodId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== enrichmentMethodId) {
                            updateEnrichmentMethodId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item lg={6} xs={12}>
                <Field
                    fullWidth
                    name="object.laboratory"
                    className={classes.formControl}
                    component={TextFieldWrapper}
                    type="text"
                    label="Sequencing Laboratory"
                />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Field
                    fullWidth
                    name="object.date"
                    component={DatePickerWrapper}
                    className={classes.formControl}
                    label="Date of Sequencing"
                    parse={parseDate}
                    format={formatDate}
                />
            </Grid>
            <Grid item lg={6} xs={12}>
                <Field
                    fullWidth
                    name="object.technician"
                    className={classes.formControl}
                    component={TextFieldWrapper}
                    type="text"
                    label="Sequencing Technician"
                />
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.systemId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== systemId) {
                            setSystemId(value);
                            props.form.mutators.clear('object.platformId');
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item lg={6} xs={12}>
                <Field
                    disabled
                    name="object.systemId"
                    component={SelectWrapper}
                    formControlProps={{
                        className: classes.formControl,
                        fullWidth: true,
                    }}
                    label="Sequencing System"
                >
                    <MenuItem
                        key={1}
                        value={1}
                    >
                        NGS
                </MenuItem>
                    <MenuItem
                        key={2}
                        value={2}
                    >
                        Capillary
                </MenuItem>
                </Field>
            </Grid>
            <Grid item lg={6} xs={12}>
                {
                    (platformRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            name="object.platformId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Platform"
                        >
                            {platformOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.platformIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Platform"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.platformId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== platformId) {
                            updatePlatformId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item lg={6} xs={12}>
                {
                    (libraryStrategyRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            name="object.libraryStrategyId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Library Strategy"
                        >
                            {libraryStrategyOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.libraryStrategyIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Library Strategy"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.libraryStrategyId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== libraryStrategyId) {
                            updateLibraryStrategyId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item lg={6} xs={12}>
                <Field
                    name="object.pairedEnd"
                    component={SelectWrapper}
                    disabled={systemId == 2}
                    formControlProps={{
                        className: classes.formControl,
                        fullWidth: true,
                    }}
                    label=" "
                >
                    <MenuItem value={undefined}></MenuItem>
                    <MenuItem
                        key={1}
                        value={1}
                    >
                        Paired End
                </MenuItem>
                    <MenuItem
                        key={2}
                        value={0}
                    >
                        Single End
                </MenuItem>
                </Field>
            </Grid>
            <Grid item lg={6} xs={12}>
                {
                    (instrumentRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            name="object.instrumentId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Instrument"
                        >
                            {instrumentOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.instrumentIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Instrument"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.instrumentId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== instrumentId) {
                            updateInstrumentId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
            <Grid item lg={6} xs={12}>
                <Field
                    fullWidth
                    name="object.comment"
                    className={classes.formControl}
                    component={TextFieldWrapper}
                    type="text"
                    label="Comment"
                />
            </Grid>
        </>}
        </EditForm>
    </>
};

export default SequenceEditForm;