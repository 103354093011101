/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an personRole
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IPersonRoleFormSchema } from '../../../components/roles/PersonRoleEditForm';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudSelectors, createCrudActions, createCrudActionTypes, createCrudEpics, IBaseCrudState, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialPersonRoleState extends IBaseCrudState {
    data?: any;
}

export interface IPersonRoleState {
    personRole: IInitialPersonRoleState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IPersonRoleActionTypes {
    CLEAR: string;
}

const personRoleTypes: IBaseCrudTypes & IPersonRoleActionTypes = {
    ...createCrudActionTypes('personRole'),
    CLEAR: '@@personRole/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialPersonRoleState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const personRoleReducer = createReducer<IInitialPersonRoleState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const personRoleActions = {
    ...createCrudActions<IPersonRoleFormSchema, SpreadDtos.AssignRoleResponse, undefined, undefined, undefined, undefined>(personRoleTypes),
    clear: createAction(personRoleTypes.CLEAR,
        () => ({})
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Create/Update Reducers
applyCrudReducers(personRoleReducer, personRoleActions, 'personRole', initialState);

// Clear Reducer
personRoleReducer.on(personRoleActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
personRoleReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const personRolesApi = {
    create: createPostRequest(
        SpreadDtos.AssignRole,
        (personRole: IPersonRoleFormSchema) => ({
            personId: personRole.personId,
            roleId: personRole.roleId,
            objectId: personRole.objectId,
        })
    ),
    load: undefined as any,
    update: undefined as any,
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(personRoleActions, personRolesApi);

export const personRoleEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const personRoleSelectors = createCrudSelectors('personRole');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default personRoleReducer;