/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as classNames from 'classnames';
import { History } from 'history';
import { parse as parseQueryString, stringify } from 'query-string';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import { FormControl, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { clusterSearchActions, clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import { RequestState } from '../../../types/RequestState';
import IconButtonLink from '../../common/IconLink';
import { SortableTable } from '../../table/SortableTable';
import ButtonLink from '../../common/ButtonLink';
import { ITableHeadSortableColumn } from '../../table/TableHeadSortable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomBox: {
            marginTop: theme.spacing(1),
        },
        overrideButtonStyle: {
            color: '#fff !important'
        },
        root: {
            width: '95%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns: ITableHeadSortableColumn[] = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Cluster Name' },
    { id: 'infectedPremiseCount', numeric: false, disablePadding: false, label: 'No. of IPs' },
    { id: '', numeric: false, disablePadding: false, label: '', disableSorting: true }
];

interface IMapClusterListProps {
    className?: string;
    epidemicId?: number;
    history: History;
    url: string;
}

const defaultTake = 5;

export const MapClusterList: React.FunctionComponent<IMapClusterListProps> = ({ className, epidemicId, history, url }) => {
    const classes = useStyles();

    const [search, setSearch] = React.useState(undefined);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value as any);
    };

    const pageOps = React.useMemo(() => ({ take: defaultTake, skip: 0 }), [])
    const specOps = React.useMemo(() => ({ epidemicId: epidemicId, nameContains: search }), [epidemicId, search])

    const [data, paginateOptions, , requestState] = clustersHooks.useSearch(specOps, pageOps);

    const params = parseQueryString(history.location.search);
    const paramsWithoutClusterParams = { ...params };
    if (paramsWithoutClusterParams) {
        paramsWithoutClusterParams.clusterId = undefined;
        paramsWithoutClusterParams.zoomToCluster = undefined;
    }

    const existingMapParams = stringify(paramsWithoutClusterParams);

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            rowsPerPageOptions={[defaultTake, 8]}
            updatePaginateOptions={clusterSearchActions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item =>
                        <TableRow
                            className={classes.clickableRow}
                            key={item.id}
                        >
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`?clusterId=${item.id}${existingMapParams && '&' + existingMapParams}`}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.infectedPremiseCount}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${item.epidemicId}/cluster/${item.id}`}>
                                    <EditIcon />
                                </IconButtonLink>
                            </TableCell>
                        </TableRow>
                    ))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <Box className={classes.bottomBox} display="flex" flexDirection="row" justifyContent="space-between">
            <FormControl>
                {
                    //<InputLabel htmlFor="component-simple">Search</InputLabel>
                }
                <Input
                    id="component-search"
                    value={search}
                    onChange={handleSearchChange}
                    placeholder={'Search...'}
                    startAdornment={<SearchIcon />}
                />
            </FormControl>
            <ButtonLink
                aria-label="View All Data"
                className={classes.overrideButtonStyle}
                color="primary"
                variant="contained"
                to={`?${existingMapParams}`}
            >
                <BubbleChartIcon />
                View All Clusters
            </ButtonLink>
        </Box>
    </>
}