/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

type Direction = 'asc' | 'desc' | undefined;

interface ITableHeadSortableProps {
    orderBy?: string;
    orderByDesc?: string;
    onRequestSort: any;
    columns: ITableHeadSortableColumn[];
}

export interface ITableHeadSortableColumn {
    id: string;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    disableSorting?: boolean;
}

export function TableHeadSortable(props: ITableHeadSortableProps) {
    const { orderBy, orderByDesc, onRequestSort } = props;

    const createSortHandler = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.columns.map(col => {

                    const activeSort: boolean = orderBy === col.id || orderByDesc === col.id;
                    const orderDirection: Direction = activeSort ? orderBy === col.id ? 'asc' : orderByDesc === col.id ? 'desc' : undefined : undefined;

                    return <TableCell
                        key={col.id}
                        align={col.numeric ? 'right' : 'left'}
                        padding={col.disablePadding ? 'none' : 'default'}
                        sortDirection={orderDirection}
                    >
                        {col.disableSorting ?
                            col.label :
                            <TableSortLabel
                                active={orderBy === col.id || orderByDesc === col.id}
                                direction={orderDirection}
                                onClick={createSortHandler(col.id)}
                            >
                                {col.label}
                            </TableSortLabel>
                        }
                    </TableCell>
                })}
            </TableRow>
        </TableHead>
    );
}
