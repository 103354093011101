/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { RequestFormState } from '../../types/RequestState';
import { ViewInfectedPremises } from '../infectedPremise/ViewInfectedPremises';
import { ViewSubClusters } from '../subCluster/ViewSubClusters';
import { ViewCluster } from './ViewCluster';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IClusterHomeParams {
    clusterId: string;
    epidemicId: string;
    subClusterId: string;
}

interface IClusterHomeProps {
    clusterId?: number;
    history: History;
    loading: boolean;
    match: match<IClusterHomeParams>;
}

export const ClusterHome: React.FunctionComponent<IClusterHomeProps> = ({ clusterId, history, match }) => {
    const classes = useStyles();

    const clusterIdTemp = clusterId || (match.params ? parseInt(match.params.clusterId) : undefined);
    const clusterIdLoaded = React.useMemo(() => clusterIdTemp, [clusterIdTemp]);

    if (!clusterIdLoaded) {
        throw Error("Invalid ID specified");
    }

    const clusterData = useSelector(state => state.cluster.data);
    const clusterState = useSelector(state => state.cluster.states.loadState);

    const subClusterId = clusterData && clusterData.unassignedSubClusterId;

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewCluster
                cluster={clusterData}
                loading={clusterState.state === RequestFormState.Pending}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewSubClusters
                clusterId={clusterIdLoaded}
                history={history}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            {
                clusterState.state !== RequestFormState.Pending && <ViewInfectedPremises
                subClusterId={subClusterId || -1}
                history={history}
                match={match}
                url={`${match.url}/subCluster/${subClusterId}`}/>
            }
        </Box>
    </Box>
}
