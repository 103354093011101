/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { InfectedPremiseList } from './InfectedPremiseList';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewInfectedPremisesProps {
    className?: string;
    epidemicId?: number;
    history: History;
    match: match<any>;
    showSearch?: boolean;
    subClusterId?: number;
    url?: string;
}

export const ViewInfectedPremises: React.FunctionComponent<IViewInfectedPremisesProps> = ({
    className,
    epidemicId,
    history,
    match,
    showSearch,
    subClusterId,
    url
}) => {
    const classes = useStyles();

    const [searchText, setSearchText] = React.useState(undefined as string | undefined);

    let parsedUrl = url || match.url;
    parsedUrl = parsedUrl + (!parsedUrl.endsWith('/premise') ? '/premise' : '');

    return <Paper className={classNames(className, classes.root)}>
        <Typography variant="h4" component="h4">Premises</Typography>
        {
            showSearch ?
                <TextField
                    label="Search All Premises"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setSearchText(event.target.value)
                    }}
                /> :
                undefined
        }
        {
            showSearch && searchText && searchText.length ?
                <InfectedPremiseList
                    className={classes.list}
                    epidemicId={epidemicId}
                    searchText={searchText}
                    url={parsedUrl}
                /> :
                <InfectedPremiseList
                    className={classes.list}
                    epidemicId={epidemicId}
                    subClusterId={subClusterId}
                    url={parsedUrl}
                />
        }
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateInfectedPremise" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Create Infected Premise"
                    color="primary"
                    variant="contained"
                    to={`${parsedUrl}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}