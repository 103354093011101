/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple classifications.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialClassificationSearchState = ISearchState<SpreadDtos.Classification>;

export interface IClassificationSearchState {
    classificationSearch: IInitialClassificationSearchState;
}

export const initialState: IClassificationSearchState = {
    classificationSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const classificationsModule = createAutoQueryModule(
    'classificationSearch',
    initialSearchState,
    '@@classificationSearch',
    SpreadDtos.QueryClassifications,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const classificationSearchActions = classificationsModule.actions;
export const classificationSearchEpics = classificationsModule.epics;
export const classificationSearchApi = classificationsModule.api;
export const classificationSearchSelectors = classificationsModule.selectors;
export const classificationSearchHooks = classificationsModule.hooks;
export default classificationsModule.reducer;