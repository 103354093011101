
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import enrichmentMethodModule from '../../store/reducers/enrichmentMethods/enrichmentMethod';
import { RequestFormState } from '../../types/RequestState';
import EnrichmentMethodEditForm from './EnrichmentMethodEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditEnrichmentMethodParams {
    enrichmentMethodId: string;
}

interface IEditEnrichmentMethodProps {
    enrichmentMethodId?: number;
    history: History;
    match: match<IEditEnrichmentMethodParams>;
}

export const EditEnrichmentMethod: React.FunctionComponent<IEditEnrichmentMethodProps> = (props) => {
    const enrichmentMethodState = useSelector(state => state.enrichmentMethod);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const enrichmentMethodId = props.enrichmentMethodId || (props.match.params ? parseInt(props.match.params.enrichmentMethodId) : undefined);

    useEffect(() => {
        if (enrichmentMethodModule.actions.load) {
            dispatch(enrichmentMethodModule.actions.load(enrichmentMethodId))
        }
    }, [dispatch, enrichmentMethodId]);

    return <>
        <Paper className={classes.root}>
            <Typography variant="h4" component="h4">Edit Enrichment Method</Typography>
            <EnrichmentMethodEditForm
                editEnrichmentMethod={enrichmentMethodState.data}
                history={props.history}
                loadingEnrichmentMethod={enrichmentMethodState.states.loadState.state === RequestFormState.Pending && !enrichmentMethodState.data}
                url={props.match.url}
            />
        </Paper>
    </>
}
