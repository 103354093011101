/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateMSAJob } from '../../components/genomic/MSA/CreateMSAJob';
import { ViewMSAs } from '../../components/genomic/MSA/ViewMSAs';
import { MSARouter } from '../../routers/MSARouter';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IMSAsPageProps {
    match: match;
}

export const MSAsPage: React.FunctionComponent<IMSAsPageProps> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/create`} component={CreateMSAJob} />
        <Route exact path={`${match.path}`} component={ViewMSAs} />
        <Route path={`${match.path}/:msaId`} component={MSARouter} />
    </Switch>;
}
