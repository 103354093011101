/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for executing an epidemic spatial import
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import createAction from '../../../helpers/createAction';
import { createGetRequest } from '../../../helpers/createRequest';
import { RequestState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';
import { ImportLog, GetImportLogResponse, GetImportLog } from '../../../dtos/Spread.dtos';
import { IRequestState } from '../../../types/IRequestState';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialImportLogState {
    data?: ImportLog;
    loadState: IRequestState
}

export interface IImportLogState {
    importLog: IInitialImportLogState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@importLog';

export const ImportLogTypes = {
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialImportLogState = {
    data: undefined,
    loadState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const importLogReducer = createReducer<IInitialImportLogState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const importLogActions = {

    /**
     * Begins the request to load a given log
     */
    load: createAction(ImportLogTypes.LOAD,
        (importLogId: number) => ({ importLogId })
    ),
    /**
     * Stores the log after a successful load request
     */
    loadSuccess: createAction(ImportLogTypes.LOAD_SUCCESS,
        (response: GetImportLogResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    loadFailure: createAction(ImportLogTypes.LOAD_FAILURE,
        (response: GetImportLogResponse) => ({
            response
        })
    ),
    /**
    * Clears the importLog state.
    */
    clear: createAction(ImportLogTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Load Reducers
importLogReducer.on(importLogActions.load, (state) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

importLogReducer.on(importLogActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.importLog
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

importLogReducer.on(importLogActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));


// Clear Reducer
importLogReducer.on(importLogActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
importLogReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const importLogApi = {
    load: createGetRequest(
        GetImportLog,
        (importLogId: number) => ({
            importLogId: importLogId
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const loadEpic: Epic<ReturnType<typeof importLogActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(importLogActions.load.getType()),
    mergeMap(action =>
        importLogApi.load(action.payload.importLogId)
            .pipe(
                mergeMap(response =>
                    of(
                        importLogActions.loadSuccess(response)
                    )
                ),
                catchError(e => of(importLogActions.loadFailure(e)))
            )
    ));

export const importLogEpics = combineEpics(loadEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default importLogReducer;