/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple classifications.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialSubClassificationSearchState = ISearchState<SpreadDtos.QuerySubClassification>;

export interface ISubClassificationSearchState {
    subClassificationSearch: IInitialSubClassificationSearchState;
}

export const initialState: ISubClassificationSearchState = {
    subClassificationSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const subClassificationSearchModule = createAutoQueryModule(
    'subClassificationSearch',
    initialSearchState,
    '@@subClassificationSearch',
    SpreadDtos.QuerySubClassifications,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const subClassificationSearchActions = subClassificationSearchModule.actions;
export const subClassificationSearchEpics = subClassificationSearchModule.epics;
export const subClassificationSearchApi = subClassificationSearchModule.api;
export const subClassificationSearchSelectors = subClassificationSearchModule.selectors;
export const subClassificationSearchHooks = subClassificationSearchModule.hooks;
export default subClassificationSearchModule.reducer;