/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { MutableState, Tools } from "final-form";


/*
 * ---------------------------------------------------------------------------------
 * Imports - Interal 
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation 
 * ---------------------------------------------------------------------------------
 */

/**
 * This method clears the form value for the given relevent state object.
 *
 * @param name The state string
 * @returns nothing.
 */
export const clear = ([name]: any, state: MutableState<object>, { changeValue }: Tools<object>) => {
    changeValue(state, name, () => undefined)
}

/**
 * This method changes the form value for the given relevent state object.
 *
 * @param name The state string
 * @returns nothing.
 */
export const changeValue = ([name, newValue]: any, state: MutableState<object>, { changeValue }: Tools<object>) => {
    changeValue(state, name, () => newValue)
}