/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

export interface IPage404Props {
  classes?: any;
}

export const Page404: React.FunctionComponent<IPage404Props> = ({classes}) => {

  return (<Box textAlign="center" m={1}>
    <Typography variant={'h2'}>
      404: Page not found
    </Typography>
  </Box>
  );
}

export default Page404;