/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Field } from '../../dtos/Spread.dtos';
import FieldEditForm from './FieldEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateFieldParams {
    clusterId?: string;
    epidemicId?: string;
    infectedPremiseId?: string;
    parcelId?: string;
}

interface ICreateFieldProps {
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    match: match<ICreateFieldParams>;
    parcelId?: number;
}

export const CreateField: React.FunctionComponent<ICreateFieldProps> = ({
    history,
    match,
    parcelId,
}) => {
    const classes = useStyles();

    parcelId = parcelId || (match.params && match.params.parcelId ? parseInt(match.params.parcelId) : undefined);

    return <Paper className={classes.root}>
        <FieldEditForm
            editField={new Field({
                parentParcelId: parcelId
            })}
            history={history}
            parentParcelId={parcelId}
            url={match.url}
        />
    </Paper>
}