import React, { Dispatch } from "react";

export type ISmartMapPosition = 'left' | 'right';

interface ISmartMapContext {
    openTabKey: number | undefined;
    position: ISmartMapPosition;
    toggleOpen: (tabKey: number) => void;
}

export const SmartMapContext = React.createContext<ISmartMapContext>({
    openTabKey: undefined,
    position: 'left',
    toggleOpen: (tabKey: number) => {}
  });