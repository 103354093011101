/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { DatePickerDateTimeFormat } from '../constants/Dates';

// These two functions are the same for now, but might diverge later

export function parseDate(value: string) {
    let actualValue: Date | undefined;

    actualValue = toDate(value);
    if (actualValue && isNaN(actualValue.getTime())) {
        actualValue = undefined;
    }

    return actualValue ? format(actualValue, DatePickerDateTimeFormat) : value;
}

export function formatDate(value: string) {
    let actualValue: Date | undefined;

    actualValue = toDate(value);
    if (actualValue && isNaN(actualValue.getTime())) {
        actualValue = undefined;
    }

    return actualValue ? format(actualValue, DatePickerDateTimeFormat) : value;
}