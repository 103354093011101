import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';


interface MultiSelectWrapperProps extends FieldRenderProps<any, any> {
    label?: string;
    formControlProps?: FormControlProps;
    options: { label: string; value: any; }[]
}

const MultiSelectWrapper: React.FunctionComponent<MultiSelectWrapperProps> = ({
    input: { name, value, onChange, ...restInput },
    meta,
    label,
    formControlProps,
    options,
    ...rest
}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    return (
        <FormControl {...formControlProps} error={showError}>
            <InputLabel htmlFor={name}>{label}</InputLabel>

            <Select
                multiple
                {...rest}
                name={name}
                onChange={
                    (blah) => {
                        return onChange(blah)
                    }
                }
                inputProps={restInput}
                value={Array.isArray(value) ? value.filter(v => v !== "") : []}
            >
                {
                    options ?
                        options.map((o =>
                            <MenuItem
                                key={`${name}-${o.value}`}
                                value={o.value}
                            >
                                <Checkbox checked={value.indexOf(o.value) > -1} />
                                <ListItemText primary={o.label} />
                            </MenuItem>
                        )) :
                        []
                }
            </Select>

            {showError &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    );
};

export default MultiSelectWrapper;