/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { JsonServiceClient } from '@servicestack/client';
import classNames from 'classnames';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { FILE_UPLOAD_MAX_SIZE_MB } from '../../../constants/Limits';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import Thumbnail from '../../common/Thumbnail';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        dropZone: {
            minHeight: 140,
            border: '1px solid black',
            borderRadius: '3px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        dropZoneActive: {
            background: '#e0e0e0'
        },
        wrapper: {
            minHeight: 140
        }
    }),
);

type IFileWrapperProps = FieldRenderProps<File, any> & {
    accept?: string;
    buttonText?: string;
    className?: string;
    label?: string;
    maxFiles?: number;

    onUploadStart?: () => void;
    onUploadFinish?: (success: boolean) => void;
}

const FileWrapper: React.FunctionComponent<IFileWrapperProps> = (
    {
        meta, input: { onChange, ...restInput },
        accept,
        buttonText,
        className,
        label,
        maxFiles,
        onUploadStart,
        onUploadFinish,
    }
) => {
    const classes = useStyles();
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    const [uploading, setUploading] = React.useState(false);
    const [dropZoneActive, setDropZoneActive] = React.useState(false);

    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
    const [uploadingFiles, setUploadingFiles] = React.useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = React.useState<SpreadDtos.File[]>([]);

    const [error, setError] = React.useState("");

    const checkFileSize = (file: File) => {
        var filesize = ((file.size / 1024) / 1024);
        if (filesize > FILE_UPLOAD_MAX_SIZE_MB) {
            setError("Files must be less than " + FILE_UPLOAD_MAX_SIZE_MB + " MB");
            return false;
        }
        return true;
    }

    const removeUploadedFile = (tempFileGuid: string) => {
        setUploadedFiles(uploadedFiles.filter((file, i) => file.tempFileGuid !== tempFileGuid));
    }

    const removeSelectedFile = (index: number) => {
        setSelectedFiles(selectedFiles.filter((file, i) => i !== index));
    }

    const uploadSuccess = (temporaryFiles: SpreadDtos.File[]) => {
        let newFiles = [...uploadedFiles];
        temporaryFiles.forEach((file, i) => { newFiles.push(file) });
        setUploading(false);
        setUploadedFiles(newFiles);
        setUploadingFiles([]);
        if (onUploadFinish) onUploadFinish(true);
    }

    const uploadError = (uploadingFiles: File[], errorMessage: string) => {
        // move the files back to the selected queue - append
        setUploading(false);
        setSelectedFiles(uploadingFiles);
        setUploadingFiles([]);
        setError(errorMessage);
        if (onUploadFinish) onUploadFinish(false);
    }

    const onDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
        setDropZoneActive(true);
    }

    const onDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
        setDropZoneActive(false);
    }

    const onDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    }

    const onDrop = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        let newFiles = [...selectedFiles];
        files.forEach((file, i) => {
            if (checkFileSize(file)) {
                newFiles.push(file as any);
            }
        });
        setDropZoneActive(false)
        setSelectedFiles(newFiles)
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(e.target.files as ArrayLike<File>);
        let newFiles = [...selectedFiles];
        files.forEach((file, i) => {
            if (checkFileSize(file)) {
                newFiles.push(file as any);
            }
        });
        setSelectedFiles(newFiles);
        e.target.value = '';
    }

    const submitUpload = (uploadingFiles: File[]) => {
        setUploadingFiles(uploadingFiles);

        var client = new JsonServiceClient();
        var parameters = new SpreadDtos.PostTemporaryFiles();
        const formData = new FormData();
        uploadingFiles.forEach((file, i) => {
            //formData.append('files[]', file, file.name);
            formData.append('files[]', file, file.name);
        });
        client.postBody(parameters, formData).then((response: any) => {
            uploadSuccess(response.files);
        }, (error: any) => {
            uploadError(uploadingFiles, error.responseStatus.message);
        });
    }

    // handle upload
    React.useEffect(() => {
        if (uploading || selectedFiles.length === 0) return;

        const uploadingFiles = [...selectedFiles];

        setUploading(true);
        setError("");
        setSelectedFiles([]);
        if (onUploadStart) onUploadStart();

        // Submit 
        submitUpload(uploadingFiles);
    }, [
            selectedFiles,
            uploading,
            setUploading,
            setError,
            setSelectedFiles,
            submitUpload
        ])

    // handle updating final-form state
    React.useEffect(() => {
        //onChange(uploadedFiles.map(f => f.serverPath));
        onChange(uploadedFiles);
    }, [uploadedFiles])

    const filesPreview = uploadedFiles.map((item, index) => {
        return <Box p={0} key={item.name}>
            <Thumbnail
                key={"file-" + item.name}
                previewPath={item.tempFileGuid}
                displayPath={item.tempFileGuid}
                isImage={false}
                text={item.originalName}
                onRemove={() => removeUploadedFile(item.tempFileGuid)}
            />
        </Box>;
    });

    const filesUploadingPreview = uploadingFiles.map((item, index) => {
        let display = URL.createObjectURL(item);
        return <Box p={0} key={"file-box-" + item.lastModified.toString() + "_" + index.toString()}>
            <Thumbnail
                key={"file-" + item.lastModified.toString() + "_" + index.toString()}
                previewPath={display}
                displayPath={display}
                isAbsolulePath={true}
                isImage={false}
                text={item.name}
                isLoading={true}
                style={{ borderColor: '#42c23b' }}
            />
        </Box>
    });

    const filesSelectedPreview = selectedFiles.map((item, index) => {
        let display = URL.createObjectURL(item);
        return <Box p={0} key={"file-box-" + item.lastModified.toString() + "_" + index.toString()}>
            <Thumbnail
                key={"file-" + item.lastModified.toString() + "_" + index.toString()}
                previewPath={display}
                displayPath={display}
                isAbsolulePath={true}
                isImage={false}
                text={item.name}
                onRemove={() => removeSelectedFile(index)}
                style={{ borderColor: '#f99a1d' }}
            />
        </Box>
    });

    const maxFilesReached = maxFiles ? selectedFiles.length + uploadingFiles.length + uploadedFiles.length >= maxFiles : false;

    return <div className={classNames(classes.wrapper, className)}>
        {label && <FormLabel>{label}</FormLabel>}
        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
            {filesPreview}
            {filesUploadingPreview}
            {filesSelectedPreview}
        </Box>
        <label
            //style={dragDropStyle}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            <input
                //{...rest}
                accept={accept}
                id={restInput.name}
                onChange={handleInputChange}
                style={{ display: 'none' }}
                type="file"
            />
            {
                !maxFilesReached && <div className={classNames(classes.dropZone, { [classes.dropZoneActive]: dropZoneActive })}>
                    <Typography>Drop files here or click to upload</Typography>
                    <Button variant="contained" color={'primary'} component="span" className={classes.buttonSubmit}>
                        {buttonText ? buttonText : 'Upload'}
                    </Button>
                </div>
            }
        </label>
        {
            showError && <FormHelperText error={showError}>
                {meta.error || meta.submitError}
            </FormHelperText>
        }
    </div>;
};

export default FileWrapper;