/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { clusterActions, clusterSelectors } from '../../store/reducers/clusters/cluster';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IClusterEditFormProps {
    editCluster?: SpreadDtos.Cluster;
    history: History;
    loadingCluster?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IClusterFormSchema extends Partial<SpreadDtos.Cluster> {
}

const clusterSchema = Yup.object<IClusterFormSchema>().shape({
    name: Yup.string().label('Cluster name')
        .required(),
    epidemicId: Yup.number().label('Epidemic')
        .required(),
});

const validation = Yup.object().shape({
    object: clusterSchema.notRequired()
});

const ClusterEditForm: React.FunctionComponent<IClusterEditFormProps> = ({
    editCluster,
    history,
    loadingCluster,
    url,
}) => {
    const editMode = editCluster !== undefined && editCluster.id !== undefined;

    const wrappedObject = {
        object: editCluster
    };

    return <EditForm
        actions={clusterActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingCluster}
        url={url}
        validate={validation}
        selectors={clusterSelectors}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Cluster Name"
            />
        </Grid>
    </>}
    </EditForm>
};

export default ClusterEditForm;