/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MockSelect } from './MockSelect';
import { MockRadioButtons } from './MockRadioButtons';
import { MockText, MockMultiLineText } from './MockText';
import { IMockFormState, MockFormContext } from './MockFormContext';
import ButtonLink from '../../common/ButtonLink';
import { useSelector } from '../../../hooks/useTypedSelector';
import { ViewGenbank } from '../sequence/ViewGenbank';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        assemblyButtons: {
            margin: theme.spacing(1)
        }
    }),
);

interface IMockViewNextGenFormProps {
    className?: string;
    history: History;
    match: match<any>;
    nextGenId: string;
}

function createData(attribute: string, value: string) {
    return { attribute, value };
}

export const MockViewNextGenForm: React.FunctionComponent<IMockViewNextGenFormProps> = ({ className, history, match, nextGenId }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);
    const dataSet = mockForm.fields['MockDataSetYear'];

    const specimenRows = [
        createData('Sequence ID', dataSet == 0 ? 'IP4_AnimalID_seq1' : dataSet == 1982 ? 'DQ077723' : 'EF552690'),
        createData('Epidemic', dataSet == 0 ? 'FMD_UK_2007' : dataSet == 1982 ? '1982/83 - GDR / Denmark' : '2001 - UK - FMDV - Serotype O	'),
        createData('Cluster', dataSet == 0 ? 'Surrey' : dataSet == 1982 ? 'Eastern Funen - DNK' : 'County Durham'),
        createData('IP', dataSet == 0 ? 'IP7' : dataSet == 1982 ? 'IP17' : '1404'),
        createData('Specimen ID', dataSet == 0 ? 'IP7-Cow-RFID-03/04/2007' : dataSet == 1982 ? 'O/Fjerritslev/DEN/17/82' : 'UKG/7039/2001'),
        createData('Host Species', dataSet == 0 ? 'Cattle' : dataSet == 1982 ? 'Unknown' : 'Cattle'),
        createData('NLIS RFID', dataSet == 0 ? '00111-1111-000009' : dataSet == 1982 ? 'Unknown' : 'Unknown'),
        createData('Date of Specimen Collection', dataSet == 0 ? '03/04/2007' : dataSet == 1982 ? 'Unknown' : '20/04/2001'),
        createData('Specimen Preparation Laboratory', dataSet == 0 ? 'Pirbright' : dataSet == 1982 ? 'Unknown' : 'Unknown'),
        createData('Specimen LIMS ID', dataSet == 0 ? 'Unknown' : dataSet == 1982 ? 'Unknown' : 'Unknown'),
        createData('Original Tissue Type', dataSet == 0 ? 'Tongue' : dataSet == 1982 ? 'Unknown' : 'Unknown'),
        createData('Date of Isolate', dataSet == 0 ? '06/04/2007' : dataSet == 1982 ? 'Unknown' : 'Unknown'),
        createData('Material sent for sequencing', dataSet == 0 ? 'clinical material' : dataSet == 1982 ? 'clinical material' : 'clinical material')
    ];

    const sequenceRows = [
        createData('Sequencing Laboratory', dataSet == 0 ? 'Pirbright' : 'Unknown'),
        createData('Date of Sequencing', dataSet == 0 ? '07/04/2007' : 'Unknown'),
        createData('Sequencing Technician', dataSet == 0 ? 'John Smith' : 'Unknown'),
        createData('Enrichment Method', dataSet == 0 ? 'PCR ("Amplicon")' : 'Unknown'),
        createData('Sequencing System', dataSet == 0 ? 'NGS' : 'Unknown'),
        createData('Library Strategy', dataSet == 0 ? 'Whole Genome Shotgun' : 'Unknown'),
        createData('Files', dataSet == 0 ? 'Paired End' : 'Unknown'),
        createData('Instrument', dataSet == 0 ? 'Illumina MiSeq' : 'Unknown')
    ];
    
    const assemblyRows = [
        createData('Assembly Quality Assessment',  dataSet == 0 ? 'Pass' : 'Unknown'),
        createData('Assembly Quality Comments',  dataSet == 0 ? 'Comments required by user' : 'Unknown'),
        createData('Assembly Method',  dataSet == 0 ? 'VP1 - Denovo + Reference Genotype' : 'Unknown'),
        createData('Reference Genotype',  dataSet == 0 ? 'Genotype 4' : 'Unknown')
    ];
    
    const annotationRows = [
        createData('Annotation Quality Comments', 'Comments required by user')
    ];
    
    
    return <Grid container alignItems="flex-start" spacing={2}>
        {

        /*
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Specimen
            </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {specimenRows.map(row => (
                        <TableRow key={row.attribute}>
                            <TableCell component="th" scope="row">
                                {row.attribute}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Sequencing Data
            </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sequenceRows.map(row => (
                        <TableRow key={row.attribute}>
                            <TableCell component="th" scope="row">
                                {row.attribute}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Assembly
            </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assemblyRows.map(row => (
                        <TableRow key={row.attribute}>
                            <TableCell component="th" scope="row">
                                {row.attribute}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
            */}
        <ViewGenbank />
        <Grid item xs={12}/>
        <Grid item lg={6} xs={12}>
            <Box display="flex" justifyContent="space-between">
                <ButtonLink
                    aria-label="Reassemble NextGen Sequence"
                    className={classes.assemblyButtons}
                    color="primary"
                    variant="contained"
                    to={`${match.url}/assemble`}
                >
                    Reassemble NextGen Sequence
                </ButtonLink>
                <ButtonLink
                    aria-label="View Assembly Quality Report"
                    className={classes.assemblyButtons}
                    color="primary"
                    variant="contained"
                    to={`${match.url}/${nextGenId}/quality`}
                >
                    View Assembly Quality Report
                </ButtonLink>
            </Box>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Annotation
            </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {annotationRows.map(row => (
                        <TableRow key={row.attribute}>
                            <TableCell component="th" scope="row">
                                {row.attribute}
                            </TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        <Grid item xs={12}/>
        <Grid item lg={6} xs={12}>
            <Box display="flex" justifyContent="space-between">
                <ButtonLink
                    aria-label="View Annotation QA Report"
                    className={classes.assemblyButtons}
                    color="primary"
                    variant="contained"
                    to={`${match.url}/${nextGenId}/annotation`}
                >
                    View Annotation QA Report
                </ButtonLink>
            </Box>
        </Grid>
        <Grid item xs={12}/>
    </Grid>
}