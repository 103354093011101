/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the enumeration for the current state of an api request
 * --------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * Enumerations
 * --------------------------------------------------------------------------------
 */

export enum RequestState {
    None = 0,
    Pending = 1,
    Success = 2,
    Failure = 3,
    Cancel = 4
}

export enum RequestFormState {
    None = 0,
    Pending = 1,
    SubmitSuccess = 2,
    FormErrors = 3,
    ServerError = 4,
    Cancel = 5
}