/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import MaterialModule from '../../store/reducers/materials/material';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IMaterialEditFormProps {
    editMaterial?: SpreadDtos.Material;
    history: History;
    loadingMaterial?: boolean;
    url: string;
}

interface IMaterialFormSchema extends Partial<SpreadDtos.Material> {
    name: string;
}

const MaterialSchema = Yup.object<IMaterialFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: MaterialSchema.notRequired()
})


const MaterialEditForm: React.FunctionComponent<IMaterialEditFormProps> = ({
    editMaterial,
    history,
    loadingMaterial,
    url,
}) => {
    const editMode = editMaterial !== undefined;

    const wrappedObject = editMode ? {
        object: editMaterial
    } : undefined;

    return <EditForm
        actions={MaterialModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingMaterial}
        selectors={MaterialModule.selectors}
        successRouteCallback={(d) => d ? `/admin/material` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default MaterialEditForm;