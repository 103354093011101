/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { History } from 'history';
import * as classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { livestockDefaultSearchActions, livestockDefaultsHooks } from '../../../store/reducers/livestockDefaults/livestockDefaultSearch';
import { RequestState, RequestFormState } from '../../../types/RequestState';
import { SortableTable } from '../../table/SortableTable';
import livestockDefaultModule from '../../../store/reducers/livestockDefaults/livestockDefault';
import { useSelector } from '../../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

const headerColumns = [
    { id: 'speciesName', numeric: false, disablePadding: false, label: 'Species Name' },
    { id: 'transmissionParameter', numeric: false, disablePadding: false, label: 'Transmission Parameter' },
    { id: 'latentPeriod', numeric: false, disablePadding: false, label: 'Latent Period' },
    { id: 'infectiousPeriod1', numeric: false, disablePadding: false, label: 'Infectious Period 1' },
    { id: 'infectiousPeriod2', numeric: false, disablePadding: false, label: 'Infectious Period 2' },
    { id: 'relativeExcretionInL1', numeric: false, disablePadding: false, label: 'Relative Excretion In L1' },
    { id: 'relativeExcretionInL2', numeric: false, disablePadding: false, label: 'Relative Excretion In L2' },
    { id: 'windDispersionThreshold', numeric: false, disablePadding: false, label: 'Wind Dispersion Threshold' },
    { id: '', numeric: false, disablePadding: false, label: 'Delete', disableSorting: true },
];

interface ILivestockDefaultListProps {
    className?: string;
    history: History;
    url: string;
}

export const LivestockDefaultList: React.FunctionComponent<ILivestockDefaultListProps> = (props) => {
    const classes = useStyles(props);
    const dispatch = useDispatch();

    const specOps = React.useMemo(() => ({  }), []);
    const pagOps = React.useMemo(() => ({ orderBy: "speciesName", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = livestockDefaultsHooks.useSearch(specOps, pagOps);

    // Delete
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteConfirmOpen(true);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            if(livestockDefaultModule.actions.delete){
                dispatch(livestockDefaultModule.actions.delete(idToDelete));
            }
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [idToDelete],
    );

    const livestockToDelete = idToDelete && data && data.results ? data.results.find(t => t.id == idToDelete) : undefined;

    const deleteState = useSelector(d => d.livestockDefault.states.deleteState.state);

    React.useEffect(() => {
        if(deleteState == RequestFormState.SubmitSuccess){
            dispatch(livestockDefaultSearchActions.search());
        }
    }, [deleteState])

    return <>
        <SortableTable
            className={classNames(classes.table, props.className)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={livestockDefaultSearchActions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item =>
                        <TableRow key={item.id} hover>
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`${props.url}/${item.id}`}>
                                    {item.speciesName}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.transmissionParameter}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.latentPeriod}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.infectiousPeriod1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.infectiousPeriod2}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.relativeExcretionInL1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.relativeExcretionInL2}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.windDispersionThreshold}
                            </TableCell>
                            <TableCell>
                                <Button
                                    aria-label="Delete Date"
                                    color="primary"
                                    value={item.id}
                                    onClick={handleDeleteClick}
                                >
                                    <DeleteIcon className={classes.buttonSubmitIcon} />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete the default livestock definition for '{livestockToDelete ? livestockToDelete.speciesName : 'unknown'}'? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}