/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateGroup } from '../../components/group/CreateGroup';
import { EditGroup } from '../../components/group/EditGroup';
import { GroupHome } from '../../components/group/GroupHome';
import { ViewGroups } from '../../components/group/ViewGroups';
import { CreateGroupEpidemic } from '../../components/groupEpidemic/CreateGroupEpidemic';
import { RequestState, RequestFormState } from '../../types/RequestState';
import { GroupManageRoleHome } from '../../components/group/GroupManageRoleHome';
import { GroupMemberInviteHome } from '../../components/group/GroupMemberInviteHome';
import { ViewGroupEpidemics } from '../../components/groupEpidemic/ViewGroupEpidemics';
import groupModule from '../../store/reducers/groups/group';

export interface IGroupsPageProps {
    classes?: any;
    match: match;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export const GroupsPage: React.FunctionComponent<IGroupsPageProps> = ({
    match
}) => {
    return (<Switch>
        <Route exact path={`${match.path}/create`} component={CreateGroup} />
        <Route exact path={`${match.path}`} component={ViewGroups} />
        <Route path={`${match.path}/:groupId`} component={GroupPage} />
    </Switch>
    );
}

const GroupPage: React.FunctionComponent<any> = ({
    history,
    match
}) => {

    const groupIdTemp = (match.params ? parseInt(match.params.groupId) : undefined);
    const groupIdLoaded = React.useMemo(() => groupIdTemp, [groupIdTemp]);

    if (!groupIdLoaded) {
        throw Error("Invalid ID specified");
    }

    const [groupData, groupState] = groupModule.hooks.useLoad(groupIdLoaded);

    if (groupData === undefined && groupState.state === RequestFormState.SubmitSuccess) {
        history.push('/404');
    }

    return <Switch>
        <Route exact path={`${match.path}/groupEpidemic/create`} component={CreateGroupEpidemic} />
        <Route exact path={`${match.path}/groupEpidemic`} component={ViewGroupEpidemics} />
        <Route exact path={`${match.path}/edit`} component={EditGroup} />
        <Route exact path={`${match.path}/invite`} component={GroupMemberInviteHome} />
        <Route exact path={`${match.path}`} component={GroupHome} />
        <Route path={`${match.path}/role`} component={GroupManageRoleHome} />
    </Switch>
}