/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { personRoleSearchActions, personRolesHooks } from '../../store/reducers/roles/personRoleSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import { DateTimeFormat } from '../../constants/Dates';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        }
    }),
);

interface IPersonRoleListProps {
    className?: string;
    epidemicId?: number;
    personGroupId?: number;
    personId?: number;
}

const headerColumns = [
    { id: 'PersonDisplayName', label: 'Person' },
    { id: 'RoleDisplayName', label: 'Role' },
    { id: 'createdDate', label: 'Created Date' }
]

export const PersonRoleList: React.FunctionComponent<IPersonRoleListProps> = ({ children, className, epidemicId, personGroupId, personId }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ epidemicId: epidemicId, personGroupId: personGroupId, personId: personId }), [epidemicId, personGroupId, personId])
    const pageOps = useMemo(() => ({ skip: 0, take: 10, orderBy: 'PersonDisplayName' }), []);

    const [data, paginateOptions, , requestState] = personRolesHooks.useSearch(specOps, pageOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={personRoleSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id}
                        hover
                        onClick={undefined}>
                        <TableCell component="th" scope="row">
                            {item.personDisplayName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.roleDisplayName}
                        </TableCell>
                        <TableCell align="right">
                            {format(toDate(item.createdDate), DateTimeFormat)}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}