/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as classNames from 'classnames';
import { History } from 'history';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { epidemicSearchActions, epidemicsHooks } from '../../store/reducers/epidemics/epidemicSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        epidemicRow: {
        }
    }),
);

const headerColumns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'countryName', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'subNational', numeric: false, disablePadding: false, label: 'Sub-National' },
    { id: 'year', numeric: false, disablePadding: false, label: 'Year' },
    { id: 'disease', numeric: false, disablePadding: false, label: 'Disease' },
    { id: 'infectedPremiseCount', numeric: false, disablePadding: false, label: 'No. of IPs' },
];

interface IEpidemicListProps {
    className?: string;
    history: History;
    url: string;
}

export const EpidemicList: React.FunctionComponent<IEpidemicListProps> = ({className, history, url}) => {
    const classes = useStyles({});

    const [data, paginateOptions, , requestState] = epidemicsHooks.useSearch();

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={epidemicSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.epidemicRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/${item.id}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.countryName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.subNationalName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.year}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.diseaseName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.infectedPremiseCount}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}