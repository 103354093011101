/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple livestockDefault.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialLivestockDefaultSearchState = ISearchState<SpreadDtos.Livestock>;

export interface ILivestockDefaultSearchState {
    livestockDefaultSearch: IInitialLivestockDefaultSearchState;
}

export const initialState: ILivestockDefaultSearchState = {
    livestockDefaultSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const livestockDefaultsModule = createAutoQueryModule(
    'livestockDefaultSearch',
    initialSearchState,
    '@@livestockDefaultSearch',
    SpreadDtos.QueryLivestockDefaults,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const livestockDefaultSearchActions = livestockDefaultsModule.actions;
export const livestockDefaultSearchEpics = livestockDefaultsModule.epics;
export const livestockDefaultsApi = livestockDefaultsModule.api;
export const livestockDefaultsSelectors = livestockDefaultsModule.selectors;
export const livestockDefaultsHooks = livestockDefaultsModule.hooks;
export default livestockDefaultsModule.reducer;