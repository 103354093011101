/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import { History } from 'history';
import { parse as parseQueryString, stringify } from 'query-string';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import analysissModule from '../../../store/reducers/analysis/analysisSearch';
import { IRequestState } from '../../../types/IRequestState';
import { RequestState } from '../../../types/RequestState';
import IconButtonLink from '../../common/IconLink';
import { SortableTable } from '../../table/SortableTable';
import { ITableHeadSortableColumn } from '../../table/TableHeadSortable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const hardCodedData: SpreadDtos.ApiJobGenomicNetwork[] = [
    {
        id: 1,
        apiJobId: 1,
        epidemicId: 1,
        infectedPremises: [],
        requestedByPersonId: 1,
        failureMessage: "",
        apiStatus: SpreadDtos.SpreadWorkflowExternalJobStatus.Completed,
        status: SpreadDtos.SpreadWorkflowJobStatus.ResultsDownloadError,
        requestedDate: "2019-12-18 8:00:00",
        jobId: "561321",
        resultParsed: "",
        startRunTime: "2019-12-18 10:00:00",
        estimatedRunTimeSeconds: 60,
        jobPercentage: 1,
        fileSize: "5MB",
        cancelledDate: "01/01/1900",
        statusLastChecked: "2019-12-18 10:01",
        name: "",
        msaId: 1,
    }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns: ITableHeadSortableColumn[] = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Network Id' },
    { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Requested' },
    { id: '', numeric: false, disablePadding: false, label: '', disableSorting: true }
];

interface IMapNetworkListProps {
    className?: string;
    //epidemicId?: number;
    //subClusterId?: number;
    history: History;
    url: string;
}

const defaultTake = 5;

export const MapNetworkList: React.FunctionComponent<IMapNetworkListProps> = ({ className, /*epidemicId, subClusterId,*/ history, url }) => {
    const classes = useStyles();

    const pageOps = React.useMemo(() => ({ skip: 0, take: defaultTake, orderBy: undefined, orderByDesc: 'Id' }), []);
    //const specOps = React.useMemo(() => ({ epidemicId: epidemicId, subClusterId: subClusterId }), [epidemicId, subClusterId]);

    //const [data, , , requestState] = analysissModule.hooks.useSearch(specOps, pageOps);

    const data = {
        results: hardCodedData,
        total: hardCodedData.length,
    };

    const requestState: IRequestState = {
        state: RequestState.Success,
    }

    const params = parseQueryString(history.location.search);
    const paramsWithoutAnalysisId = { ...params };
    if (paramsWithoutAnalysisId) {
        paramsWithoutAnalysisId.analysisId = undefined;
    }

    const existingMapParams = stringify(paramsWithoutAnalysisId);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={pageOps}
        rowCount={data ? data.total : 0}
        rowsPerPageOptions={[defaultTake, 8]}
        updatePaginateOptions={analysissModule.actions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id}
                    >
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`/epidemic/${item.epidemicId}/results/map?networkId=${item.id}&${existingMapParams}`}>
                                {item.id}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.requestedDate), DateTimeFormat)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${item.epidemicId}/analysis/${item.id}`}>
                                <EditIcon />
                            </IconButtonLink>
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}