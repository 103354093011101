/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { FormControlProps } from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toDate } from '@servicestack/client';
import { differenceInDays, format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../../constants/Dates';
import { clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import SelectWrapper from '../FinalFormControls/SelectWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

export type DisplayMode = 'basic' | 'analysis-dates';

interface IClusterSelectProps extends FieldRenderProps<any, any> {
    epidemicId?: number;
    unselectLabel?: string;
    label?: string;
    formControlProps?: FormControlProps;
    displayMode: DisplayMode
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        formSkeleton: {
            height: 42,
            marginLeft: -11,
        }
    }),
);

const ClusterSelect: React.FunctionComponent<IClusterSelectProps> = ({
    epidemicId,
    input,
    meta,
    label,
    unselectLabel,
    formControlProps,
    displayMode,
    ...rest
}) => {
    const classes = useStyles();

    // default to -1 so we get no results if no epidemic id is given
    const calculatedEpidemicId = epidemicId ? epidemicId : -1;

    const clusterSpecOps = useMemo(() => ({ epidemicId: calculatedEpidemicId }), [calculatedEpidemicId]);
    const clusterPaginateOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [clusterData, , , clusterRequestState] = clustersHooks.useSearch(clusterSpecOps, clusterPaginateOps);

    const clusterOptions = clusterData && clusterData.results && clusterData.results.length > 0 ?
        clusterData.results.map((c => {

            const dayDifference = c.clusterDateSet &&
                c.clusterDateSet.earliestStartOfInfectiousPeriodDate &&
                c.clusterDateSet.latestEndOfInfectiousPeriodDate
                ? differenceInDays(toDate(c.clusterDateSet.earliestStartOfInfectiousPeriodDate),
                    toDate(c.clusterDateSet.latestEndOfInfectiousPeriodDate))
                : null;

            const dayDifferenceMessage = dayDifference && dayDifference + ` day${dayDifference !== 1 && 's'}`;

            const dateLabel = c.clusterDateSet &&
                c.clusterDateSet.earliestStartOfInfectiousPeriodDate &&
                c.clusterDateSet.latestEndOfInfectiousPeriodDate
                ? `${format(toDate(c.clusterDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.clusterDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)} ${dayDifferenceMessage && dayDifferenceMessage}`
                : '(Insufficient date data)';

            return <MenuItem key={c.id} value={c.id} >
                {c.name} {displayMode === 'analysis-dates' && `: ${dateLabel}`}
            </MenuItem>
        }
        )) :
        [];

    return <SelectWrapper
        formControlProps={formControlProps}
        input={input}
        label={label}
        meta={meta}
    >
        <MenuItem value={undefined}>{unselectLabel ? unselectLabel : 'All'}</MenuItem>
        {clusterOptions}
    </SelectWrapper>;
}

export default ClusterSelect;