/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AdminTasks } from '../../components/admin/AdminTasks';
import { CountriesPage } from './CountryPage';
import { EditSpecies } from '../../components/species/EditSpecies';
import { CreateSpecies } from '../../components/species/CreateSpecies';
import { ViewSpecies } from '../../components/species/ViewSpecies';
import { EditLivestockDefault } from '../../components/livestock/defaults/EditLivestockDefault';
import { CreateLivestockDefault } from '../../components/livestock/defaults/CreateLivestockDefault';
import { ViewLivestockDefaults } from '../../components/livestock/defaults/ViewLivestockDefaults';
import { useSelector } from '../../hooks/useTypedSelector';
import { RefreshTapmDataSets } from '../../components/analysis/tapm/RefreshTapmDataSets';
import { EditPathogen } from '../../components/pathogen/EditPathogen';
import { CreatePathogen } from '../../components/pathogen/CreatePathogen';
import { ViewPathogens } from '../../components/pathogen/ViewPathogens';
import { EditClassification } from '../../components/classification/EditClassification';
import { CreateClassification } from '../../components/classification/CreateClassification';
import { ViewClassifications } from '../../components/classification/ViewClassifications';
import { EditSubClassification } from '../../components/subClassification/EditSubClassification';
import { CreateSubClassification } from '../../components/subClassification/CreateSubClassification';
import { ViewSubClassifications } from '../../components/subClassification/ViewSubClassifications';
import { EditDisease } from '../../components/disease/EditDisease';
import { CreateDisease } from '../../components/disease/CreateDisease';
import { ViewDiseases } from '../../components/disease/ViewDiseases';
import { CreateDiseaseClassification } from '../../components/diseaseClassification/CreateDiseaseClassification';
import { EditDiseaseClassification } from '../../components/diseaseClassification/EditDiseaseClassification';
import { CreateDiseaseSubClassification } from '../../components/diseaseSubClassification/CreateDiseaseSubClassification';
import { EditDiseaseSubClassification } from '../../components/diseaseSubClassification/EditDiseaseSubClassification';
import { ViewTissues } from '../../components/tissue/ViewTissues';
import { EditTissue } from '../../components/tissue/EditTissue';
import { CreateTissue } from '../../components/tissue/CreateTissue';
import { ViewMaterials } from '../../components/material/ViewMaterials';
import { EditMaterial } from '../../components/material/EditMaterial';
import { CreateMaterial } from '../../components/material/CreateMaterial';
import { ViewEnrichmentMethods } from '../../components/enrichmentMethod/ViewEnrichmentMethods';
import { EditEnrichmentMethod } from '../../components/enrichmentMethod/EditEnrichmentMethod';
import { CreateEnrichmentMethod } from '../../components/enrichmentMethod/CreateEnrichmentMethod';
import { ViewLibraryStrategys } from '../../components/libraryStrategy/ViewLibraryStrategys';
import { EditLibraryStrategy } from '../../components/libraryStrategy/EditLibraryStrategy';
import { CreateLibraryStrategy } from '../../components/libraryStrategy/CreateLibraryStrategy';
import { ViewInstruments } from '../../components/instrument/ViewInstruments';
import { EditInstrument } from '../../components/instrument/EditInstrument';
import { CreateInstrument } from '../../components/instrument/CreateInstrument';
import { ViewPlatforms } from '../../components/platform/ViewPlatforms';
import { EditPlatform } from '../../components/platform/EditPlatform';
import { CreatePlatform } from '../../components/platform/CreatePlatform';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IAdminPageProps {
    classes?: string;
    match: match;
}

export const AdminPage: React.FunctionComponent<IAdminPageProps> = (props) => {
    const person = useSelector(state => state.authenticatedPerson.data);
    const countryPermissions: string[] = ['CreateCountry', 'UpdateCountry', 'DeleteCountry'];
    const speciesPermissions: string[] = ['CreateSpecies', 'UpdateSpecies', 'DeleteSpecies'];
    const livestockDefaultPermissions: string[] = ['CreateLivestockDefault', 'UpdateLivestockDefault', 'DeleteLivestockDefault'];
    const pathogenPermissions: string[] = ['ViewPathogen', 'CreatePathogen', 'UpdatePathogen', 'DeletePathogen'];
    const classificationPermissions: string[] = ['ViewClassification', 'CreateClassification', 'UpdateClassification', 'DeleteClassification'];
    const subClassificationPermissions: string[] = ['ViewSubClassification', 'CreateSubClassification', 'UpdateSubClassification', 'DeleteSubClassification'];
    const diseasePermissions: string[] = ['ViewDisease', 'CreateDisease', 'UpdateDisease', 'DeleteDisease'];
    const diseaseClassificationPermissions: string[] = ['ViewDiseaseClassification', 'CreateDiseaseClassification', 'UpdateDiseaseClassification', 'DeleteDiseaseClassification'];
    const diseaseSubClassificationPermissions: string[] = ['ViewDiseaseSubClassification', 'CreateDiseaseSubClassification', 'UpdateDiseaseSubClassification', 'DeleteDiseaseSubClassification'];
    const tapmPermissions: string[] = ['RefreshTapmDataSets'];
    const tissuePermissions: string[] = ['ViewTissue', 'CreateTissue', 'UpdateTissue', 'DeleteTissue'];
    const materialPermissions: string[] = ['ViewMaterial', 'CreateMaterial', 'UpdateMaterial', 'DeleteMaterial'];
    const enrichmentMethodPermissions: string[] = ['ViewEnrichmentMethod', 'CreateEnrichmentMethod', 'UpdateEnrichmentMethod', 'DeleteEnrichmentMethod'];
    const libraryStrategyPermissions: string[] = ['ViewLibraryStrategy', 'CreateLibraryStrategy', 'UpdateLibraryStrategy', 'DeleteLibraryStrategy'];
    const instrumentPermissions: string[] = ['ViewInstrument', 'CreateInstrument', 'UpdateInstrument', 'DeleteInstrument'];
    const platformPermissions: string[] = ['ViewPlatform', 'CreatePlatform', 'UpdatePlatform', 'DeletePlatform'];
    

    let hasCountryPermission = false;
    let hasSpeciesPermission = false;
    let hasLivestockDefaultPermission = false;
    let hasTapmPermission = false;
    let hasPathogenPermission = false;
    let hasClassificationPermission = false;
    let hasSubClassificationPermission = false;
    let hasDiseaseClassificationPermission = false;
    let hasDiseaseSubClassificationPermission = false;
    let hasDiseasePermission = false;
    let hasTissuePermission = false;
    let hasMaterialPermission = false;
    let hasEnrichmentMethodPermission = false;
    let hasLibraryStrategyPermission = false;
    let hasInstrumentPermission = false;
    let hasPlatformPermission = false;

    if (person && person.permissions) {
        person.permissions.forEach(p => {
            if (countryPermissions.includes(p)) {
                hasCountryPermission = true;
            }
            if (speciesPermissions.includes(p)) {
                hasSpeciesPermission = true;
            }
            if (livestockDefaultPermissions.includes(p)) {
                hasLivestockDefaultPermission = true;
            }
            if (tapmPermissions.includes(p)) {
                hasTapmPermission = true;
            }
            if (pathogenPermissions.includes(p)) {
                hasPathogenPermission = true;
            }
            if (classificationPermissions.includes(p)) {
                hasClassificationPermission = true;
            }
            if (subClassificationPermissions.includes(p)) {
                hasSubClassificationPermission = true;
            }
            if (diseasePermissions.includes(p)) {
                hasDiseasePermission = true;
            }
            if (diseaseClassificationPermissions.includes(p)) {
                hasDiseaseClassificationPermission = true;
            }
            if (diseaseSubClassificationPermissions.includes(p)) {
                hasDiseaseSubClassificationPermission = true;
            }
            if (tissuePermissions.includes(p)) {
                hasTissuePermission = true;
            }
            if (materialPermissions.includes(p)) {
                hasMaterialPermission = true;
            }
            if (enrichmentMethodPermissions.includes(p)) {
                hasEnrichmentMethodPermission = true;
            }
            if (libraryStrategyPermissions.includes(p)) {
                hasLibraryStrategyPermission = true;
            }
            if (instrumentPermissions.includes(p)) {
                hasInstrumentPermission = true;
            }
            if (platformPermissions.includes(p)) {
                hasPlatformPermission = true;
            }
        });
    }

    return <Switch>
        {hasCountryPermission && <Route path={`${props.match.path}/country`} component={CountriesPage} />}
        {hasSpeciesPermission && <Route exact path={`${props.match.path}/species/create`} component={CreateSpecies} />}
        {hasSpeciesPermission && <Route exact path={`${props.match.path}/species/:speciesId`} component={EditSpecies} />}
        {hasSpeciesPermission && <Route path={`${props.match.path}/species`} component={ViewSpecies} />}
        {hasLivestockDefaultPermission && <Route exact path={`${props.match.path}/livestock/create`} component={CreateLivestockDefault} />}
        {hasLivestockDefaultPermission && <Route exact path={`${props.match.path}/livestock/:livestockDefaultId`} component={EditLivestockDefault} />}
        {hasLivestockDefaultPermission && <Route path={`${props.match.path}/livestock`} component={ViewLivestockDefaults} />}
        {hasPathogenPermission && <Route exact path={`${props.match.path}/pathogen/create`} component={CreatePathogen} />}
        {hasPathogenPermission && <Route exact path={`${props.match.path}/pathogen/:pathogenId`} component={EditPathogen} />}
        {hasPathogenPermission && <Route path={`${props.match.path}/pathogen`} component={ViewPathogens} />}
        {hasClassificationPermission && <Route exact path={`${props.match.path}/classification/create`} component={CreateClassification} />}
        {hasClassificationPermission && <Route exact path={`${props.match.path}/classification/:classificationId`} component={EditClassification} />}
        {hasClassificationPermission && <Route path={`${props.match.path}/classification`} component={ViewClassifications} />}
        {hasSubClassificationPermission && <Route exact path={`${props.match.path}/subclassification/create`} component={CreateSubClassification} />}
        {hasSubClassificationPermission && <Route exact path={`${props.match.path}/subclassification/:subclassificationId`} component={EditSubClassification} />}
        {hasSubClassificationPermission && <Route path={`${props.match.path}/subclassification`} component={ViewSubClassifications} />}
        {hasDiseaseClassificationPermission && <Route exact path={`${props.match.path}/disease/:diseaseId/diseaseClassification/create`} component={CreateDiseaseClassification} />}
        {hasDiseaseClassificationPermission && <Route exact path={`${props.match.path}/disease/:diseaseId/diseaseClassification/:diseaseClassificationId/edit`} component={EditDiseaseClassification} />}
        {hasDiseaseSubClassificationPermission && <Route exact path={`${props.match.path}/disease/:diseaseId/diseaseClassification/:diseaseClassificationId/diseaseSubClassification/create`} component={CreateDiseaseSubClassification} />}
        {hasDiseaseSubClassificationPermission && <Route exact path={`${props.match.path}/disease/:diseaseId/diseaseClassification/:diseaseClassificationId/diseaseSubClassification/:diseaseSubClassificationId/edit`} component={EditDiseaseSubClassification} />}
        {hasDiseasePermission && <Route exact path={`${props.match.path}/disease/create`} component={CreateDisease} />}
        {hasDiseasePermission && <Route exact path={`${props.match.path}/disease/:diseaseId`} component={EditDisease} />}
        {hasDiseasePermission && <Route path={`${props.match.path}/disease`} component={ViewDiseases} />}
        {hasTissuePermission && <Route exact path={`${props.match.path}/tissue/create`} component={CreateTissue} />}
        {hasTissuePermission && <Route exact path={`${props.match.path}/tissue/:tissueId`} component={EditTissue} />}
        {hasTissuePermission && <Route path={`${props.match.path}/tissue`} component={ViewTissues} />}
        {hasMaterialPermission && <Route exact path={`${props.match.path}/material/create`} component={CreateMaterial} />}
        {hasMaterialPermission && <Route exact path={`${props.match.path}/material/:materialId`} component={EditMaterial} />}
        {hasMaterialPermission && <Route path={`${props.match.path}/material`} component={ViewMaterials} />}
        {hasEnrichmentMethodPermission && <Route exact path={`${props.match.path}/enrichmentMethod/create`} component={CreateEnrichmentMethod} />}
        {hasEnrichmentMethodPermission && <Route exact path={`${props.match.path}/enrichmentMethod/:enrichmentMethodId`} component={EditEnrichmentMethod} />}
        {hasEnrichmentMethodPermission && <Route path={`${props.match.path}/enrichmentMethod`} component={ViewEnrichmentMethods} />}
        {hasLibraryStrategyPermission && <Route exact path={`${props.match.path}/libraryStrategy/create`} component={CreateLibraryStrategy} />}
        {hasLibraryStrategyPermission && <Route exact path={`${props.match.path}/libraryStrategy/:libraryStrategyId`} component={EditLibraryStrategy} />}
        {hasLibraryStrategyPermission && <Route path={`${props.match.path}/libraryStrategy`} component={ViewLibraryStrategys} />}
        {hasInstrumentPermission && <Route exact path={`${props.match.path}/instrument/create`} component={CreateInstrument} />}
        {hasInstrumentPermission && <Route exact path={`${props.match.path}/instrument/:instrumentId`} component={EditInstrument} />}
        {hasInstrumentPermission && <Route path={`${props.match.path}/instrument`} component={ViewInstruments} />}
        {hasPlatformPermission && <Route exact path={`${props.match.path}/platform/create`} component={CreatePlatform} />}
        {hasPlatformPermission && <Route exact path={`${props.match.path}/platform/:platformId`} component={EditPlatform} />}
        {hasPlatformPermission && <Route path={`${props.match.path}/platform`} component={ViewPlatforms} />}        
        {hasTapmPermission && <Route exact path={`${props.match.path}/workflow/job`} component={RefreshTapmDataSets} />}
        {hasTapmPermission && <Route exact path={`${props.match.path}/workflow/tapm/dataset`} component={RefreshTapmDataSets} />}
        {
            hasCountryPermission || hasLivestockDefaultPermission || hasTapmPermission
                ? <Route exact path={`${props.match.path}`} component={AdminTasks} />
                : undefined
        }
        {
            person && <Redirect to="/404" />
        }
    </Switch>
}
