/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from './useTypedSelector';
import { IRequestState } from '../types/IRequestState';
import { Tooltip } from '../dtos/Spread.dtos';
import { tooltipsActions } from '../store/reducers/tooltips/tooltips';
import { RequestState } from '../types/RequestState';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useTooltip(tooltipKey: string): [Tooltip | undefined, IRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.tooltips.data);
    const requestState = useSelector(state => state.tooltips.loadState);

    useEffect(() => {
        if(requestState.state === RequestState.None){
            dispatch(tooltipsActions.load())
        }
    }, [dispatch, requestState]);

    var tooltip = data && data[tooltipKey];

    return [
        tooltip,
        requestState
    ];
}