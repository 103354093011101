/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateAreaDate } from '../components/areaDate/CreateAreaDate';
import { EditAreaDate } from '../components/areaDate/EditAreaDate';
import { ViewAreaDate } from '../components/areaDate/ViewAreaDate';
import { CreateAreaLivestockCount } from '../components/areaLivestockCount/CreateAreaLivestockCount';
import { EditAreaLivestockCount } from '../components/areaLivestockCount/EditAreaLivestockCount';
import { EditParcel } from '../components/parcel/EditParcel';
import { ParcelHome } from '../components/parcel/ParcelHome';
import { useParcelLoad } from '../hooks/parcel';
import { FieldsPage } from '../pages/application/FieldsPage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IParcelRouterParams {
    parcelId?: string
}

interface IParcelRouterProps {
    parcelId?: number;
    match: match<IParcelRouterParams>;
}

export const ParcelRouter: React.FunctionComponent<IParcelRouterProps> = ({ parcelId, match }) => {
    const parcelIdTemp = match.params.parcelId ? parseInt(match.params.parcelId) : (parcelId || -1);

    // Load in parcel so child routes can use
    const parcelIdLoaded = React.useMemo(() => parcelIdTemp, [parcelIdTemp])
    const [, response] = useParcelLoad(parcelIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/date/create`} component={CreateAreaDate} />
            <Route exact path={`${match.path}/date/:areaDateId/edit`} component={EditAreaDate} />
            <Route exact path={`${match.path}/date/:areaDateId`} component={ViewAreaDate} />
            <Route exact path={`${match.path}/livestock/create`} component={CreateAreaLivestockCount} />
            <Route exact path={`${match.path}/livestock/:areaLivestockCountId`} component={EditAreaLivestockCount} />
            <Route exact path={`${match.path}/edit`} component={EditParcel} />
            <Route path={`${match.path}/field`} component={FieldsPage} />
            <Route path={`${match.path}`} component={ParcelHome} />
        </Switch>
}