/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file defines correctly typed requests (for our usage) to the ServiceStack API.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { JsonServiceClient } from '@servicestack/client';
import { from, Observable } from 'rxjs';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../dtos/Spread.dtos';
import { ReplaceReturnType } from '../types/HelperTypes';

/*
 * ---------------------------------------------------------------------------------
 * Types & Interfaces
 * ---------------------------------------------------------------------------------
 */

interface requestCreatorFunction<P> {
    (...args: any[]): Partial<P>
}

interface clientRequestFunction<T, R> {
    (client: JsonServiceClient, request: T): Observable<R>
}

export type DtoReturnType<T> = T extends SpreadDtos.IReturn<infer S> ? S : never;

/*
 * ---------------------------------------------------------------------------------
 * Base Implementation
 * ---------------------------------------------------------------------------------
 */

export type CreateRequestFn = <
    S, 
    T extends SpreadDtos.IReturn<S>, 
    FunctionType extends requestCreatorFunction<T>,
    U extends ReplaceReturnType<FunctionType, Observable<DtoReturnType<T>>>
>(
    type: { new(): T ;}, 
    requestMapping: FunctionType, 
    clientRequest: clientRequestFunction<T, S>
) => U

export type CreateVerbedRequestFn = <
    S, 
    T extends SpreadDtos.IReturn<S>, 
    FunctionType extends requestCreatorFunction<T>,
    U extends ReplaceReturnType<FunctionType, Observable<DtoReturnType<T>>>
>(
    type: { new(): T ;}, 
    requestMapping: FunctionType
) => U

/**
 * This method creates a request to the ServiceStack API. This was made because
 * ServiceStack typescript DTO class definitions didn't support optional params.
 * 
 * @augments {JsonServiceClient}
 * @param type The DTO Type
 * @param requestMapping The mapping of function parameters to DTO properties
 * @returns observable request.
 */
const createRequest: CreateRequestFn = (type, requestMapping, clientRequest) => {
    const fn: any = (...args: any[]) => {
        const client = new JsonServiceClient('');

        let request = new type();

        const values = requestMapping(...args)

        for (let key in values) {
            (request as any)[key] = values[key];
        }

        return clientRequest(client, request);
    };
    
    return fn;
};

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

/**
 * This method creates a post request to the ServiceStack API. This was made because
 * ServiceStack typescript DTO class definitions didn't support optional params.
 * 
 * @augments JsonServiceClient 
 * @param type The DTO Type
 * @param requestMapping The mapping of function parameters to DTO properties
 * @returns observable request.
 */
export const createPostRequest: CreateVerbedRequestFn = (type, requestMapping) => {
    return createRequest(type, requestMapping, (client, request) => from(client.post(request)) )
}

/**
 * This method creates a get request to the ServiceStack API. This was made because
 * ServiceStack typescript DTO class definitions didn't support optional params.
 * 
 * @augments {JsonServiceClient}
 * @param type The DTO Type
 * @param requestMapping The mapping of function parameters to DTO properties
 * @returns observable request.
 */
export const createGetRequest: CreateVerbedRequestFn = (type, requestMapping) => {
    return createRequest(type, requestMapping, (client, request) => { 
        return from(client.get(request))
    })
}

/**
 * This method creates a delete request to the ServiceStack API. This was made because
 * ServiceStack typescript DTO class definitions didn't support optional params.
 * 
 * @augments {JsonServiceClient}
 * @param type The DTO Type
 * @param requestMapping The mapping of function parameters to DTO properties
 * @returns observable request.
 */
export const createDeleteRequest: CreateVerbedRequestFn = (type, requestMapping) => {
    return createRequest(type, requestMapping, (client, request) => { 
        return from(client.delete(request))
    })
}

/**
 * This method creates a get request to the ServiceStack API. This was made because
 * ServiceStack typescript DTO class definitions didn't support optional params.
 * 
 * @augments {JsonServiceClient}
 * @param type The DTO Type
 * @param requestMapping The mapping of function parameters to DTO properties
 * @returns observable request.
 */
export const createPutRequest: CreateVerbedRequestFn = (type, requestMapping) => {
    return createRequest(type, requestMapping, (client, request) => { 
        return from(client.put(request))
    })
}