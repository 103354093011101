/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { History } from 'history';
import * as classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { diseaseSearchActions, diseaseSearchHooks } from '../../store/reducers/diseases/diseaseSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        }
    }),
);

const headerColumns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'pathogenName', numeric: false, disablePadding: false, label: 'Pathogen' },
    { id: 'createdDate', numeric: false, disablePadding: false, label: 'Created' },
];

interface IDiseaseListProps {
    className?: string;
    history: History;
    url: string;
}

export const DiseaseList: React.FunctionComponent<IDiseaseListProps> = (props) => {
    const classes = useStyles(props);

    const specOps = React.useMemo(() => ({  }), []);
    const pagOps = React.useMemo(() => ({ orderBy: "name", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = diseaseSearchHooks.useSearch(specOps, pagOps);

    return <>
        <SortableTable
            className={classNames(classes.table, props.className)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={diseaseSearchActions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item =>
                        <TableRow className={classes.clickableRow} key={item.id} hover onClick={() => props.history.push(props.url + `/${item.id}`)}>
                            <TableCell component="th" scope="row">
                                {item.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.pathogenName}
                            </TableCell>
                            <TableCell align="right">
                                {item.createdDate}
                            </TableCell>
                        </TableRow>
                    ))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
    </>
}