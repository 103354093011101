/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { SpreadWorkflowJobStatus } from '../../../dtos/Spread.dtos';
import { spreadWorkflowJobStatusDescription } from '../../../helpers/enumDescription';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import genomicNetworkModule from '../../../store/reducers/genomic/genomicNetwork';
import genomicNetworkSearchModule from '../../../store/reducers/genomic/genomicNetworkSearch';
import { RequestState } from '../../../types/RequestState';
import ImageDialog from '../../common/ImageDialog';
import { SortableTable } from '../../table/SortableTable';
import { ITableHeadSortableColumn } from '../../table/TableHeadSortable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        row: {
        },
        buttonDeleteIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IGenomicNetworkJobListProps {
    className?: string;
    url: string;
}

export const GenomicNetworkJobList: React.FunctionComponent<IGenomicNetworkJobListProps> = ({ className, url }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const epidemic = useSelector(state => state.epidemic.data);
    const person = useSelector(state => state.authenticatedPerson.data);
    const deleteRequestState = useSelector(state => state.genomicNetwork.states.deleteState);

    const headerColumns: ITableHeadSortableColumn[] = [
        { id: 'direct', numeric: false, disablePadding: false, label: 'Direct' },
        { id: 'indirect', numeric: false, disablePadding: false, label: 'Indirect' },
        { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
        { id: 'jobId', numeric: false, disablePadding: false, label: 'Job Key' },
        { id: 'failureMessage', numeric: false, disablePadding: false, label: 'Failure Message' },
        { id: 'requestedDate', numeric: false, disablePadding: false, label: 'Requested At' },
        { id: 'msaLink', numeric: false, disablePadding: false, disableSorting: true, label: 'Multi-Sequence Alignment' },
        { id: 'download', numeric: false, disablePadding: false, disableSorting: true, label: 'Download Files' },
    ];

    const epidemicId = epidemic && epidemic.id;

    const pageOps = useMemo(() => ({ skip: 0, take: 10, orderBy: undefined, orderByDesc: 'Id' }), []);
    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId, deleteRequestState.state]);
    const [data, paginateOptions, , requestState] = genomicNetworkSearchModule.hooks.useSearch(specOps, pageOps);

    // Image stuff

    const [isNetworkImageOpen, setNetworkImageOpen] = React.useState(false);
    const [selectedNetworkId, setSelectedNetworkId] = React.useState(undefined as number | undefined);
    const [selectedNetworkType, setSelectedNetworkType] = React.useState(undefined as 'direct' | 'indirect' | undefined);

    const handleImageClick = React.useCallback(
        (id: number, type: 'direct' | 'indirect') => {
            setSelectedNetworkId(id);
            setNetworkImageOpen(true);
            setSelectedNetworkType(type);
        },
        [setNetworkImageOpen, setSelectedNetworkId],
    );

    const handleImageClose = React.useCallback(
        () => {
            setNetworkImageOpen(false);
            setSelectedNetworkId(undefined);
            setSelectedNetworkType(undefined);
        },
        [setNetworkImageOpen, setSelectedNetworkId],
    );

    // Download stuff

    const handleDownloadClick = (genomicNetworkJobId: number) => {
        dispatch(genomicNetworkModule.actions.getAllFiles(genomicNetworkJobId));
    }

    // Delete stuff

    const hasDeletePerm = person ? person.permissions.find(p => p === 'DeleteGenomicNetworkJob') !== undefined : false;

    if (hasDeletePerm) {
        headerColumns.push({ id: 'delete', numeric: false, disablePadding: true, disableSorting: true, label: '' });
    }

    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteConfirmOpen(true);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            if (genomicNetworkModule.actions.delete) {
                dispatch(genomicNetworkModule.actions.delete(idToDelete));
            }
            setIdToDelete(undefined);
            setDeleteConfirmOpen(false);
        },
        [idToDelete, setIdToDelete, setDeleteConfirmOpen],
    );

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={genomicNetworkSearchModule.actions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0 ?
                    data.results.map((item =>
                        <TableRow
                            className={classes.row}
                            key={item.id}
                        >
                            <TableCell component="th" scope="row">
                                {
                                    item.resultParsed ?
                                        <IconButton edge="start" color="inherit" aria-label="View Map" onClick={() => handleImageClick(item.id, 'direct')}>
                                            <ImageIcon />
                                        </IconButton> :
                                        null
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.resultParsed ?
                                        <IconButton edge="start" color="inherit" aria-label="View Map" onClick={() => handleImageClick(item.id, 'indirect')}>
                                            <ImageIcon />
                                        </IconButton> :
                                        null
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {/*<Link component={RouterLink} to={`${url}/${item.id}`}>*/}
                                {item.id}
                                {/*</Link>*/}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {spreadWorkflowJobStatusDescription(item.status)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.jobId}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.failureMessage}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {format(toDate(item.requestedDate), DateTimeFormat)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.msaId ?
                                    <Link component={RouterLink} to={routeBackToPath(`${url}`, 1, `bioinformatics/msa/${item.msaId}/review`)}>
                                        View
                                    </Link> :
                                    ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {
                                    item.status == SpreadWorkflowJobStatus.ResultsDownloaded ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessed ||
                                        item.status == SpreadWorkflowJobStatus.ResultsProcessing ?
                                        <IconButton
                                            aria-label="Download"
                                            color="primary"

                                            onClick={() => handleDownloadClick(item.id)}
                                        >
                                            <DownloadIcon />
                                        </IconButton> :
                                        ""
                                }
                            </TableCell>
                            {
                                hasDeletePerm ?
                                    <TableCell component="th" scope="row">
                                        {
                                            // Prevent deleting in-progress jobs (for now)
                                            item.status == SpreadWorkflowJobStatus.Cancelled ||
                                                item.status == SpreadWorkflowJobStatus.Failed ||
                                                item.status == SpreadWorkflowJobStatus.CreateError ||
                                                item.status == SpreadWorkflowJobStatus.InitError ||
                                                item.status == SpreadWorkflowJobStatus.ResultsDownloadError ||
                                                item.status == SpreadWorkflowJobStatus.ResultsProcessError ||
                                                item.status == SpreadWorkflowJobStatus.ResultsProcessed ?
                                                <Button
                                                    aria-label="Delete Job"
                                                    color="primary"
                                                    value={item.id}

                                                    onClick={handleDeleteClick}
                                                >
                                                    <DeleteIcon className={classes.buttonDeleteIcon} />
                                                </Button> :
                                                undefined
                                        }
                                    </TableCell> :
                                    undefined
                            }
                        </TableRow>
                    )) :
                    <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending ?
                                <Skeleton /> :
                                'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <ImageDialog
            open={isNetworkImageOpen}
            src={selectedNetworkId ? `/file/genomic/network/${selectedNetworkId}/image/${selectedNetworkType}` : ""}
            title={`Genomic Network ${selectedNetworkId} - ${selectedNetworkType ? selectedNetworkType.charAt(0).toUpperCase() + selectedNetworkType.slice(1) : ""}`}
            onClose={handleImageClose}
        />
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this job? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}