/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as classNames from 'classnames';
import { useMemo } from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import epidemicPersonGroupAccessSearchModule from '../../store/reducers/epidemics/epidemicPersonGroupAccessSearch';
import { groupEpidemicsHooks } from '../../store/reducers/groupEpidemics/groupEpidemicSearch';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        }
    }),
);

interface IEpidemicPersonGroupAccessListProps {
    className?: string;
    epidemicId: number;
}

const headerColumns = [
    { id: 'personGroup.displayName', label: 'Group Name' },
    { id: 'createdDate', label: 'Created Date' },
]

export const EpidemicPersonGroupAccessList: React.FunctionComponent<IEpidemicPersonGroupAccessListProps> = ({ className, epidemicId }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [ epidemicId ])
    const pagOps = React.useMemo(() => ({ orderBy: "personGroupDisplayName", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = groupEpidemicsHooks.useSearch(specOps, pagOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={epidemicPersonGroupAccessSearchModule.actions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id}
                        hover
                        onClick={undefined}>
                        <TableCell component="th" scope="row">
                            {item.personGroupDisplayName}
                        </TableCell>
                        <TableCell align="right">
                            {item.createdDate}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}