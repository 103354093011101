/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an instrument
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateInstrument,
    CreateInstrumentResponse,
    Instrument,
    DeleteInstrument,
    DeleteInstrumentResponse,
    GetInstrument,
    GetInstrumentResponse,
    UpdateInstrument,
    UpdateInstrumentResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IInstrumentState {
    instrument: IBaseCrudState<Instrument>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const instrumentModule = createCrudModule<Instrument, CreateInstrumentResponse, GetInstrumentResponse, UpdateInstrumentResponse, DeleteInstrumentResponse, undefined>(
    'instrument',
    CreateInstrument,
    GetInstrument,
    UpdateInstrument,
    DeleteInstrument,
    undefined,
)


export default instrumentModule;