/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match, Route } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateEpidemicPersonRole } from '../roles/CreateEpidemicPersonRole';
import { ViewPersonRoles } from '../roles/ViewPersonRoles';
import { ViewEpidemicPersonGroupAccess } from './ViewEpidemicPersonGroupAccess';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IViewEpidemicParams {
    epidemicId: string;
}

interface IViewEpidemicProps {
    history: History;
    match: match<IViewEpidemicParams>;
}

export const ManageEpidemicAccess: React.FunctionComponent<IViewEpidemicProps> = ({
    history,
    match
}) => {
    const classes = useStyles();
    const epidemicId = match.params ? parseInt(match.params.epidemicId) : undefined;

    return <Box className={classes.root} component="div">
        <Route exact path={`${match.path}`}>
            <Box className={classes.layoutItem}>
                <ViewPersonRoles
                    epidemicId={epidemicId}
                    match={match}
                />
            </Box>
            <Box className={classes.layoutItem}>
                {
                    epidemicId && <ViewEpidemicPersonGroupAccess
                        epidemicId={epidemicId}
                        url={match.url}
                    />
                }
            </Box>
        </Route>
        <Route exact path={`${match.path}/role/create`}>
            <Box className={classes.layoutItem}>
                {
                    epidemicId && <CreateEpidemicPersonRole
                        epidemicId={epidemicId}
                        history={history}
                        match={match}
                    />
                }
            </Box>
        </Route>
    </Box >
}