/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { areaLivestockCountActions, areaLivestockCountSelectors } from '../../store/reducers/areaLivestockCounts/areaLivestockCount';
import livestockModule from '../../store/reducers/livestock/livestock';
import { livestockLocationsHooks } from '../../store/reducers/livestockLocations/livestockLocationSearch';
import { RequestFormState, RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAreaLivestockCountEditFormProps
{
    editAreaLivestockCount?: SpreadDtos.AreaLivestockCount;
    history: History;
    livestockId?: number;
    loadingAreaLivestockCount?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IAreaLivestockCountFormSchema extends Partial<SpreadDtos.AreaLivestockCount> { }

const areaLivestockCountSchema = Yup.object<IAreaLivestockCountFormSchema>().shape({
    areaId: Yup.number().label('Area')
        .required(),
    livestockId: Yup.number().label('Livestock')
        .required(),
    livestockLocationId: Yup.number().label('Livestock Location')
        .required(),
    count: Yup.number().label('Count')
        .required(),
    infectedCount: Yup.number().label('Infected Count')
        .notRequired(),
    isIndexSpecies: Yup.bool().label('Index Species')
        .notRequired()
});

const validation = Yup.object().shape({
    object: areaLivestockCountSchema.notRequired()
})

const AreaLivestockCountEditForm: React.FunctionComponent<IAreaLivestockCountEditFormProps> = ({
    editAreaLivestockCount,
    history,
    livestockId,
    loadingAreaLivestockCount,
    url,
}) =>
{
    const classes = useStyles({});

    const [livestockLocationData, , , livestockLocationRequestState] = livestockLocationsHooks.useSearch();

    const livestockLocationOptions = livestockLocationData && livestockLocationData.results && livestockLocationData.results.length > 0 ?
        livestockLocationData.results.map((l =>
            <MenuItem
                key={l.id}
                value={l.id}
            >
                {l.name}
            </MenuItem>
        )) :
        [];

    livestockId = editAreaLivestockCount && editAreaLivestockCount.livestockId ? editAreaLivestockCount.livestockId : livestockId;

    const livestockIdLoaded = React.useMemo(() => livestockId, [livestockId]);

    const [livestockData, livestockState] = livestockModule.hooks.useLoad(livestockIdLoaded || -1);
    const loadingLivestock = livestockState.state == RequestFormState.Pending;

    const wrappedObject = {
        object: editAreaLivestockCount
    };

    return <EditForm
        actions={areaLivestockCountActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingAreaLivestockCount}
        url={url}
        successRouteCallback={() => `${routeBackToPath(url, 2)}`}
        validate={validation}
        selectors={areaLivestockCountSelectors}
    >{() => <>
        <Typography variant="h4" component="h4">{!loadingLivestock ? livestockData && livestockData.speciesName : <Skeleton />}</Typography>
        <Grid item xs={12}>
            {
                livestockLocationRequestState.state !== RequestState.Pending ?
                    <Field
                        name="object.livestockLocationId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Location"
                    >
                        <MenuItem value={0}></MenuItem>
                        {livestockLocationOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.livestockLocationIdLoading"
                        component={TextFieldWrapper}
                        type="text"
                        label="Location"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.count"
                component={TextFieldWrapper}
                type="number"
                label="Count"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.infectedCount"
                component={TextFieldWrapper}
                type="number"
                label="Infected Count"
            />
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                name="object.isIndexSpecies"
                component={CheckboxWrapper}
                type="checkbox"
                label="Index Species"
            />
        </Grid>
    </>}
    </EditForm>
};

export default AreaLivestockCountEditForm;