/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { EditAreaDateType } from '../components/areaDateType/EditAreaDateType';
import { ViewAreaDateType } from '../components/areaDateType/ViewAreaDateType';
import { useAreaDateTypeLoad } from '../hooks/areaDateType';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IAreaDateTypeRouterParams {
    areaDateTypeId?: string
}

interface IAreaDateTypeRouterProps {
    areaDateTypeId?: number;
    match: match<IAreaDateTypeRouterParams>;
}

export const AreaDateTypeRouter: React.FunctionComponent<IAreaDateTypeRouterProps> = ({ areaDateTypeId, match }) => {
    const areaDateTypeIdTemp = match.params.areaDateTypeId ? parseInt(match.params.areaDateTypeId) : (areaDateTypeId || -1);

    // Load in area date type so child routes can use
    const areaDateTypeIdLoaded = React.useMemo(() => areaDateTypeIdTemp, [areaDateTypeIdTemp])
    const [, response] = useAreaDateTypeLoad(areaDateTypeIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/edit`} component={EditAreaDateType} />
            <Route path={`${match.path}`} component={ViewAreaDateType} />
        </Switch>
}