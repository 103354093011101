/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple clusters.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialClusterSearchState = ISearchState<SpreadDtos.QueryCluster>;

export interface IClusterSearchState {
    clusterSearch: IInitialClusterSearchState;
}

export const initialState: IClusterSearchState = {
    clusterSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const clustersModule = createAutoQueryModule(
    'clusterSearch',
    initialSearchState,
    '@@clusterSearch',
    SpreadDtos.QueryClusters,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        epidemicId: options.specialOptions.epidemicId,
        nameContains: options.specialOptions.nameContains
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const clusterSearchActions = clustersModule.actions;
export const clusterSearchEpics = clustersModule.epics;
export const clustersApi = clustersModule.api;
export const clustersSelectors = clustersModule.selectors;
export const clustersHooks = clustersModule.hooks;
export default clustersModule.reducer;