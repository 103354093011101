/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import * as classNames from 'classnames';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { groupEpidemicSearchActions, groupEpidemicsHooks } from '../../store/reducers/groupEpidemics/groupEpidemicSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns = [
    { id: 'epidemicName', numeric: false, disablePadding: false, label: 'Name' },
];

interface IGroupEpidemicListProps {
    groupId?: number;
    className?: string;
}

export const GroupEpidemicList: React.FunctionComponent<IGroupEpidemicListProps> = ({ className, groupId }) => {
    const classes = useStyles();

    const specOps = React.useMemo(() => ({ personGroupId: groupId }), [groupId]);
    const pagOps = React.useMemo(() => ({ orderBy: "epidemicName", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = groupEpidemicsHooks.useSearch(specOps, pagOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={groupEpidemicSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.clickableRow}
                        key={item.id}
                    >
                        <TableCell component="th" scope="row">
                            {item.epidemicName}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}