/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, match, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import consensusSequenceModule from '../../../store/reducers/sequences/consensusSequence';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import RadioWrapper from '../../forms/FinalFormControls/RadioWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../../forms/mutators';
import { Box } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        assemblyButtons: {
            margin: theme.spacing(1)
        },
        imageWrapper: {
            textAlign: 'center'
        },
        image: {
            width: '90%',
        },
        readsBox: {
            flexDirection: "column",
            [theme.breakpoints.up('lg')]: {
                flexDirection: "row"
            }
        },
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ISequenceQAFormParams {
    consensusSequenceId: string;
}

interface ISequenceQAFormProps {
    className?: string;
    history: History;
    match: match<ISequenceQAFormParams>;
}

export interface ISequenceAssemblyQAFormSchema {
    consensusSequenceId: number;
    passed?: boolean;
    comment?: string;
}

const validation = Yup.object<ISequenceAssemblyQAFormSchema>({
    consensusSequenceId: Yup.number().label('Consensus Sequence')
        .required(),
    passed: Yup.boolean().label('Selection')
        .notRequired(),
    comment: Yup.string().label('Comment')
        .notRequired(),
});

export const SequenceAssemblyQAForm: React.FunctionComponent<ISequenceQAFormProps> = ({ className, history, match }) => {
    const classes = useStyles();
    const consensusSequence = useSelector((s) => consensusSequenceModule.selectors.data(s));
    const updateState = useSelector((s) => consensusSequenceModule.selectors.assemblyQAState(s));

    const [assemblyData, assemblyDataState] = consensusSequenceModule.hooks.useLoadAssemblyData(consensusSequence ? consensusSequence.id : -1);

    const submitSuccess = updateState.state == RequestFormState.SubmitSuccess;

    const startAction = consensusSequenceModule.actions.assemblyQA;
    const resolveAction = consensusSequenceModule.actions.assemblyQAFormResponse;
    const rejectAction = consensusSequenceModule.actions.assemblyQAFormResponse;

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        successRedirectComponent = <Redirect push to={`${routeBackToPath(match.url, 8, "bioinformatics?link=1")}`} />;
    }

    return <Paper className={classes.root}>
        {successRedirectComponent}
        {consensusSequence ?
            <>
                <Typography variant={'h5'}>NGS Assembly QA Report{consensusSequence.sequence ? ` - ${consensusSequence.sequence.spreadSequenceId}` : ""}</Typography>
                <CommonForm
                    start={startAction}
                    resolve={resolveAction}
                    reject={rejectAction}
                    history={history}
                    initialValues={{
                        consensusSequenceId: consensusSequence.id,
                        passed: consensusSequence.assemblyQA,
                        comment: consensusSequence.assemblyComment,
                    }}
                    labelSave="Submit"
                    mutators={{ clear: clear }}
                    submitSuccess={submitSuccess}
                    validate={validation}
                >
                    {({ }) => <>
                        <Grid item xs={12} />
                        <Grid item lg={2} xs={12}>
                            <Field
                                name="passed"
                                component={RadioWrapper}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                parse={(value) => !value ? undefined : value === "true"}
                                items={[
                                    {
                                        value: true,
                                        label: 'Pass'
                                    },
                                    {
                                        value: false,
                                        label: 'Fail'
                                    }
                                ]}
                                label="Quality Assessment"
                            />
                        </Grid>
                        <Grid item lg={10} xs={12}>
                            <Field
                                multiline
                                name="comment"
                                component={TextFieldWrapper}
                                formControlProps={{
                                    multiline: true,
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label='Comment'
                            />
                        </Grid>
                    </>
                    }
                </CommonForm>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Report</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link component={RouterLink} to={routeBackToPath(match.url, 1, 'gbk')}>GBK File</Link>
                </Grid>
                {
                    consensusSequence.fastQForwardReadsImageFileId || consensusSequence.fastQReverseReadsImageFileId ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>Quality Control Fastqc Output</Typography>
                            </Grid>
                        </> :
                        undefined
                }
                <Box display="flex" className={classes.readsBox}>
                    {
                        consensusSequence.fastQForwardReadsImageFileId ?
                                <div className={classes.imageWrapper}>
                                    <Typography variant={'body1'}>Forward Reads</Typography>
                                    {
                                        consensusSequence.fastQForwardReadsImageFileId ?
                                            <img
                                                className={classes.image}
                                                src={`/file/sequence/qualityf/${consensusSequence.id}`}
                                                alt="FASTQ Forward Reads"
                                            /> :
                                            undefined
                                    }
                                </div>
                            : undefined
                    }
                    {
                        consensusSequence.fastQReverseReadsImageFileId ?
                            <div className={classes.imageWrapper}>
                                <Typography variant={'body1'}>Reverse Reads</Typography>
                                {
                                    consensusSequence.fastQReverseReadsImageFileId ?
                                        <img
                                            className={classes.image}
                                            src={`/file/sequence/qualityr/${consensusSequence.id}`}
                                            alt="FASTQ Reverse Reads"
                                        /> :
                                        undefined
                                }
                            </div>
                            : undefined
                    }
                </Box>
                {
                    assemblyDataState.state == RequestFormState.Pending ?
                        <Skeleton /> :
                        assemblyData ?
                            Object.keys(assemblyData).map(title => {
                                const values = assemblyData[title];
                                return <>
                                    <Grid item xs={12} key={`title-grid-${title}`}>
                                        <Typography variant={'h6'} key={`title-${title}`}>
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} xs={12} key={`table-grid-${title}`}>
                                        <Table aria-label="simple table" size="small" key={`table-${title}`}>
                                            <TableHead key={`table-head-${title}`}>
                                                <TableRow key={`table-head-row-${title}`}>
                                                    <TableCell key={`table-head-row-key-${title}`}>Attribute</TableCell>
                                                    <TableCell align="right" key={`table-head-row-value-${title}`}>Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody key={`table-body-${title}`}>
                                                {
                                                    Object.keys(values).map(key => (
                                                        <TableRow key={`table-body-row-${title}-${key}`}>
                                                            <TableCell key={`table-body-row-key-${title}-${key}`}>{key}</TableCell>
                                                            <TableCell align="right" key={`table-body-row-value-${title}-${key}`}>{typeof (values[key]) == "string" ? values[key] : JSON.stringify(values[key])}</TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </>;
                            }) :
                            null
                }
                {
                    consensusSequence.coverageImageFileId ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>Coverage and Variant Analysis</Typography>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <div className={classes.imageWrapper}>
                                    <img
                                        className={classes.image}
                                        src={`/file/sequence/coverage/${consensusSequence.id}`}
                                        alt="Coverage Analysis"
                                    />
                                </div>
                            </Grid>
                        </> :
                        undefined
                }
                {/*
                    consensusSequence.coverageImageFileId ?
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h6'}>CoverView</Typography>
                            </Grid>
                            <Grid item lg={12} xs={12}>
                                <div className={classes.imageWrapper}>
                                    <img
                                        className={classes.image}
                                        src={`/file/sequence/coverage/${consensusSequence.id}`}
                                        alt="Depth of coverage"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} />
                        </> :
                        undefined
                */}
            </> :
            <Skeleton />
        }
    </Paper>
}