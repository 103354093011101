/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import { match, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

/*
* ---------------------------------------------------------------------------------
* Imports - Internal
* ---------------------------------------------------------------------------------
*/

import { DateTimeFormat } from '../../../constants/Dates';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import { clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import msaModule from '../../../store/reducers/msa/msa';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import InfectedPremiseCheckboxSelect from '../../forms/InfectedPremiseCheckboxSelect/InfectedPremiseCheckboxSelect';
import { changeValue, clear } from '../../forms/mutators';
import SubClusterSelect from '../../forms/SubClusterSelect/SubClusterSelect';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        formItem: {
            margin: theme.spacing(2, 3),
        },
        hr: {
            width: '100%'
        },
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IMockAlignmentFormProps {
    epidemicId?: number;
    className?: string;
    history: History;
    match: match<any>;
}

export interface IMSAJobFormSchema {
    epidemicId?: number;
    clusterId?: number;
    subClusterId?: number;
    infectedPremiseIds: number[];
}

const validation = Yup.object<IMSAJobFormSchema>({
    epidemicId: Yup.number().label('Epidemic')
        .required(),
    clusterId: Yup.number().label('Cluster')
        .notRequired(),
    subClusterId: Yup.number().label('Sub-Cluster')
        .notRequired(),
    infectedPremiseIds: Yup.mixed().label('Infected Premises')
        .notRequired(),
});

export const MSACreateJobForm: React.FunctionComponent<IMockAlignmentFormProps> = ({ className, epidemicId, history, match }) => {
    const classes = useStyles();

    const createState = useSelector(msaModule.selectors.createJobState);
    const job = useSelector(msaModule.selectors.job);

    const submitSuccess = createState.state === RequestFormState.SubmitSuccess;

    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])
    const [clusterData, , ,] = clustersHooks.useSearch(specOps);

    const clusterOptions = clusterData && clusterData.results && clusterData.results.length > 0 ?
        clusterData.results.map((c =>
            <MenuItem
                key={c.id}
                value={c.id}
            >
                {c.name}: {
                    c.clusterDateSet && c.clusterDateSet.earliestStartOfInfectiousPeriodDate && c.clusterDateSet.latestEndOfInfectiousPeriodDate
                        ? `${format(toDate(c.clusterDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.clusterDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)}`
                        : '(Insufficient date data)'
                }
            </MenuItem>
        )) :
        [];

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        const toRoute: string = job ? `${routeBackToPath(match.url)}` : '/404';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    const startAction = msaModule.actions.createJob;
    const resolveAction = msaModule.actions.createJobFormResponse;
    const rejectAction = msaModule.actions.createJobFormResponse;

    return <>
        {successRedirectComponent}
        <CommonForm
            start={startAction}
            resolve={resolveAction}
            reject={rejectAction}
            history={history}
            mutators={{
                clear: clear,
                changeValue: changeValue
            }}
            submitSuccess={submitSuccess}
            initialValues={{
                epidemicId: epidemicId,
                clusterId: -1 as number | undefined,
                subClusterId: undefined as number | undefined,
                infectedPremiseIds: [] as number[],
            }}
            validate={validation}
            labelSave="Queue"
        >
            {() => <>
                <FormSpy>
                    {props => <>
                        <OnChange name={"clusterId"}>
                            {(value: any) => {
                                props.form.mutators.clear('subClusterId');
                                props.form.mutators.clear('infectedPremiseIds');
                            }}
                        </OnChange>
                        <OnChange name={"subClusterId"}>
                            {(value: any) => {
                                props.form.mutators.clear('infectedPremiseIds');
                            }}
                        </OnChange>
                    </>}
                </FormSpy>
                <Grid item xs={6}>
                    <Field
                        name="clusterId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: false
                        }}
                        label="Cluster"
                        format={(value) => value === -1 ? undefined : (value || 0)}
                        parse={(value) => value || undefined}
                    >
                        <MenuItem value={0}>All</MenuItem>
                        {clusterOptions}
                    </Field>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={6}>
                    <Field name="clusterId" render={
                        (clusterProps) => (
                            <Field name="subClusterId" render={
                                subClusterProps => (
                                    <SubClusterSelect
                                        clusterId={Number(clusterProps.input.value) || undefined}
                                        epidemicId={epidemicId}
                                        formControlProps={{
                                            className: classes.formControl,
                                            fullWidth: true,
                                            required: false
                                        }}
                                        disabled={Number(clusterProps.input.value) == -1}
                                        input={subClusterProps.input}
                                        label="Sub-Cluster"
                                        meta={subClusterProps.meta}
                                    />
                                )
                            } />
                        )
                    } />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <Field name="clusterId" render={
                        (clusterProps) => (
                            <Field name="subClusterId" render={
                                (subClusterProps) => (
                                    clusterProps.input.value !== -1 ?
                                    <InfectedPremiseCheckboxSelect
                                        clusterId={clusterProps.input.value === '' ? undefined : clusterProps.input.value}
                                        epidemicId={epidemicId}
                                        subClusterId={subClusterProps.input.value === '' ? undefined : subClusterProps.input.value}
                                        name={"infectedPremiseIds"}
                                        filter={(ip) => ip.hasConsensusSequence}
                                        noResultsMessage={'No eligible Infected Premises found - try a different cluster. To be eligible an Infected Premises must be attached to a consensus sequence.'}
                                    />
                                    : null
                                )
                            } />
                        )
                    } />
                </Grid>
            </>}
        </CommonForm>
    </>
}