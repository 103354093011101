/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an sequence
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ApiJobAssembly, AssemblyMethod, CreateJobAssembly, CreateJobAssemblyResponse, CreateSequence, CreateSequenceResponse, GetAllAssemblyJobFiles, GetGBKFile, GetSequence, GetSequenceResponse, Sequence, UpdateSequence, UpdateSequenceResponse } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest } from '../../../helpers/createRequest';
import { useSelector } from '../../../hooks/useTypedSelector';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { ActionType, baseCrudInitialState, createCrudModule, IBaseCrudActions, IBaseCrudApi, IBaseCrudHooks, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface LoadAssemblyJobFilesParams {
    assemblyJobId: number;
}

interface LoadGBKFileParams {
    sequenceId: number;
}

interface CreateAssemblyJobParams {
    sequenceId: number;
    assemblyMethod: AssemblyMethod;
    genotype: string;
}

interface ISequenceSubState extends IBaseCrudState<Sequence> {
    assemblyJob?: ApiJobAssembly;
    gbkFile?: Blob;
    extraStates: {
        createAssemblyJobState: IFormRequestState;
        loadAssemblyJobFilesState: IFormRequestState;
        loadGBKFileState: IFormRequestState;
    }
}

export interface ISequenceState {
    sequence: ISequenceSubState;
}

interface ISequenceActions extends IBaseCrudActions<Sequence> {
    createAssemblyJob: ActionType<(sequenceId: number, assemblyMethod: AssemblyMethod, genotype: string) => CreateAssemblyJobParams>;
    createAssemblyJobFormResponse: ActionType<(response: CreateJobAssemblyResponse, state: RequestFormState) => { response: CreateJobAssemblyResponse, state: RequestFormState }>;
    loadAssemblyJobFiles: ActionType<(sequenceId: number) => LoadAssemblyJobFilesParams>;
    loadAssemblyJobFilesFormResponse: ActionType<(response: Blob, state: RequestFormState) => { response: Blob, state: RequestFormState }>;
    loadGBKFile: ActionType<(sequenceId: number) => LoadGBKFileParams>;
    loadGBKFileFormResponse: ActionType<(response: Blob, state: RequestFormState) => { response: Blob, state: RequestFormState }>;
}

interface ISequenceApi extends IBaseCrudApi {
    createJobAssembly: ReturnType<typeof createPostRequest>;
    getAllAssemblyJobFiles: ReturnType<typeof createGetRequest>;
    getGBKFile: ReturnType<typeof createGetRequest>;
}

interface ISequenceSelectors extends IBaseCrudSelectors<Sequence, ISequenceSubState> {
    assemblyJob: (state: ISequenceState) => ISequenceSubState["assemblyJob"];
    gbkFile: (state: ISequenceState) => ISequenceSubState["gbkFile"];
    createAssemblyJobState: (state: ISequenceState) => ISequenceSubState["extraStates"]["createAssemblyJobState"];
    loadAssemblyJobFilesState: (state: ISequenceState) => ISequenceSubState["extraStates"]["loadAssemblyJobFilesState"];
    loadGBKFileState: (state: ISequenceState) => ISequenceSubState["extraStates"]["loadGBKFileState"];
}

interface ISequenceHooks extends IBaseCrudHooks<Sequence> {
    useLoadGBKFile: (sequenceId: number) => [Blob | undefined, IFormRequestState];
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const initialSequenceState: ISequenceSubState = {
    assemblyJob: undefined,
    gbkFile: undefined,
    extraStates: {
        createAssemblyJobState: {
            state: RequestFormState.None
        },
        loadAssemblyJobFilesState: {
            state: RequestFormState.None
        },
        loadGBKFileState: {
            state: RequestFormState.None
        },
    },
    ...baseCrudInitialState<Sequence>()
};

const sequenceModule = createCrudModule<Sequence, CreateSequenceResponse, GetSequenceResponse, UpdateSequenceResponse, undefined, undefined, ISequenceSubState, ISequenceActions, ISequenceApi, ISequenceSelectors, ISequenceHooks>(
    'sequence',
    CreateSequence,
    GetSequence,
    UpdateSequence,
    undefined,
    undefined,
    initialSequenceState,
);

// Create Assembly Job action

sequenceModule.actions.createAssemblyJob = createAction(`@@sequence/CREATE_ASSEMBLY_JOB`,
    (sequenceId: number, assemblyMethod: AssemblyMethod, genotype: string) => ({ sequenceId, assemblyMethod, genotype })
);

sequenceModule.actions.createAssemblyJobFormResponse = createAction(`@@sequence/CREATE_ASSEMBLY_JOB_FORM_RESPONSE`,
    (response: CreateJobAssemblyResponse, state: RequestFormState) => ({ response, state })
);

const createAssemblyJobReducer = (state: ISequenceSubState) => update(
    state,
    {
        extraStates: {
            createAssemblyJobState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const createAssemblyJobFormResponseReducer = (state: ISequenceSubState, payload: any, data: ApiJobAssembly) => update(
    state,
    {
        assemblyJob: {
            $set: data
        },
        extraStates: {
            createAssemblyJobState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

sequenceModule.reducer.on(sequenceModule.actions.createAssemblyJob, (state: ISequenceSubState) => (
    createAssemblyJobReducer(state)
));

sequenceModule.reducer.on(sequenceModule.actions.createAssemblyJobFormResponse, (state: ISequenceSubState, payload) => (
    createAssemblyJobFormResponseReducer(state, payload, payload.response.assemblyJob)
));

sequenceModule.api.createJobAssembly = createPostRequest(
    CreateJobAssembly,
    (sequenceId: number, assemblyMethod: AssemblyMethod, genotype: string) => ({
        sequenceId,
        method: assemblyMethod,
        genotype
    })
);

const createAssemblyJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(sequenceModule.actions.createAssemblyJob.getType()),
        mergeMap((action: Action<CreateAssemblyJobParams, {}>) => {
            return sequenceModule.api.createJobAssembly(action.payload.sequenceId, action.payload.assemblyMethod, action.payload.genotype)
                .pipe(
                    mergeMap(response => of(sequenceModule.actions.createAssemblyJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(sequenceModule.actions.createAssemblyJobFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

sequenceModule.selectors.assemblyJob = (state: ISequenceState) => state['sequence'].assemblyJob;
sequenceModule.selectors.createAssemblyJobState = (state: ISequenceState) => state['sequence'].extraStates.createAssemblyJobState;

// Load Assembly Job Files action

sequenceModule.actions.loadAssemblyJobFiles = createAction(`@@sequence/LOAD_ASSEMBLY_JOB_FILES`,
    (assemblyJobId: number) => ({ assemblyJobId })
);

sequenceModule.actions.loadAssemblyJobFilesFormResponse = createAction(`@@sequence/LOAD_ASSEMBLY_JOB_FILES_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);

const loadAssemblyJobFilesReducer = (state: ISequenceSubState) => update(
    state,
    {
        extraStates: {
            loadAssemblyJobFilesState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const loadAssemblyJobFilesResponseReducer = (state: ISequenceSubState, payload: any) => update(
    state,
    {
        extraStates: {
            loadAssemblyJobFilesState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

sequenceModule.reducer.on(sequenceModule.actions.loadAssemblyJobFiles, (state: ISequenceSubState) => (
    loadAssemblyJobFilesReducer(state)
));

sequenceModule.reducer.on(sequenceModule.actions.loadAssemblyJobFilesFormResponse, (state: ISequenceSubState, payload) => (
    loadAssemblyJobFilesResponseReducer(state, payload)
));

sequenceModule.api.getAllAssemblyJobFiles = createGetRequest(
    GetAllAssemblyJobFiles,
    (assemblyJobId: number) => ({
        assemblyJobId
    })
);

const loadAssemblyJobFilesEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(sequenceModule.actions.loadAssemblyJobFiles.getType()),
        mergeMap((action: Action<LoadAssemblyJobFilesParams, {}>) => {
            return sequenceModule.api.getGBKFile(action.payload.assemblyJobId)
                .pipe(
                    mergeMap(response => of(sequenceModule.actions.loadAssemblyJobFilesFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(sequenceModule.actions.loadAssemblyJobFilesFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

sequenceModule.selectors.gbkFile = (state: ISequenceState) => state['sequence'].gbkFile;
sequenceModule.selectors.loadAssemblyJobFilesState = (state: ISequenceState) => state['sequence'].extraStates.loadAssemblyJobFilesState;
sequenceModule.selectors.loadGBKFileState = (state: ISequenceState) => state['sequence'].extraStates.loadGBKFileState;

// Load GBK File action

sequenceModule.actions.loadGBKFile = createAction(`@@sequence/LOAD_GBK_FILE`,
    (sequenceId: number) => ({ sequenceId })
);

sequenceModule.actions.loadGBKFileFormResponse = createAction(`@@sequence/LOAD_GBK_FILE_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);

const loadGBKFileReducer = (state: ISequenceSubState) => update(
    state,
    {
        extraStates: {
            loadGBKFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const loadGBKFileFormResponseReducer = (state: ISequenceSubState, payload: any, blob: Blob) => {
    return update(
        state,
        {
            gbkFile: {
                $set: blob
            },
            extraStates: {
                loadGBKFileState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
};

sequenceModule.reducer.on(sequenceModule.actions.loadGBKFile, (state: ISequenceSubState) => (
    loadGBKFileReducer(state)
));

sequenceModule.reducer.on(sequenceModule.actions.loadGBKFileFormResponse, (state: ISequenceSubState, payload) => (
    loadGBKFileFormResponseReducer(state, payload, payload.response)
));

sequenceModule.api.getGBKFile = createGetRequest(
    GetGBKFile,
    (sequenceId: number) => ({
        sequenceId
    })
);

const loadGBKFileEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(sequenceModule.actions.loadGBKFile.getType()),
        mergeMap((action: Action<LoadGBKFileParams, {}>) => {
            return sequenceModule.api.getGBKFile(action.payload.sequenceId)
                .pipe(
                    mergeMap(response => of(sequenceModule.actions.loadGBKFileFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(sequenceModule.actions.loadGBKFileFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

sequenceModule.selectors.gbkFile = (state: ISequenceState) => state['sequence'].gbkFile;
sequenceModule.selectors.loadGBKFileState = (state: ISequenceState) => state['sequence'].extraStates.loadGBKFileState;

const useLoadGBKFile = (sequenceId: number): [Blob | undefined, IFormRequestState] => {
    const dispatch = useDispatch();
    const file = useSelector(state => state.sequence.gbkFile);
    const requestState = useSelector(state => state.sequence.extraStates.loadGBKFileState);

    useEffect(() => {
        if (sequenceId && sequenceId > 0) {
            dispatch(sequenceModule.actions.loadGBKFile(sequenceId));
        }

        return function cleanup() {
            if (sequenceModule.actions.clearFormState) {
                dispatch(sequenceModule.actions.clearFormState());
            }
        }
    }, [dispatch, sequenceId]);

    return [
        file,
        requestState
    ];
};

sequenceModule.hooks.useLoadGBKFile = useLoadGBKFile;

/* Add additional epics to module */

sequenceModule.epics = combineEpics(sequenceModule.epics, createAssemblyJobEpic, loadAssemblyJobFilesEpic, loadGBKFileEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default sequenceModule;