/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toDate } from '@servicestack/client';
import classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeHourFormat } from '../../../constants/Dates';
import { AnalysisJobTapm, SpreadWorkflowJobStatus } from '../../../dtos/Spread.dtos';
import { useReprocessTapmResults } from '../../../hooks/useReprocessTapmResults';
import ButtonLink from '../../common/ButtonLink';
import { HasPermission } from '../../common/HasPermission';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            marginTop: theme.spacing
        },
        bottomButtons: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAnalysisTapmParams {

}

interface IViewAnalysisTapmProps {
    analysisJob?: AnalysisJobTapm;
    className?: string;
    loading: boolean;
    match: match<IViewAnalysisTapmParams>;
    refreshIntervalSeconds?: number;
}

export const ViewAnalysisTapm: React.FunctionComponent<IViewAnalysisTapmProps> = ({ analysisJob, className, match, refreshIntervalSeconds }) => {
    const classes = useStyles();

    const [reprocessFn, reprocessState] = useReprocessTapmResults(analysisJob && analysisJob.id);

    const handleReprocess = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => reprocessFn(),
        [reprocessFn],
    );

    return <>
        <Paper className={classNames(className, classes.root)}>
            <div>
                <Typography variant="h6" component="span">Name: </Typography><Typography variant="body1" component="span">{analysisJob ? analysisJob.name ? analysisJob.name : 'None' : <Skeleton width={200} />}</Typography>
            </div>
            <div>
                <Typography variant="h6" component="span">Job Key: </Typography><Typography variant="body1" component="span">{analysisJob && analysisJob.jobId ? `${analysisJob.jobId}` : <Skeleton width={200} />}</Typography>
            </div>
            <div>
                <Typography variant="h6" component="span">Error Message: </Typography><Typography variant="body1" component="span">{analysisJob ? analysisJob.failureMessage ? `${analysisJob.failureMessage}` : 'None' : <Skeleton width={200} />}</Typography>
            </div>

            <Box display="flex" justifyContent="flex-end">
                {analysisJob && analysisJob.status === SpreadWorkflowJobStatus.Completed ?
                    <>
                        <ButtonLink
                            className={classes.bottomButtons}
                            color="primary"
                            variant="contained"
                            to={`/epidemic/${analysisJob.epidemicId}/results/map?analysisId=${analysisJob.id}`}
                        >
                            View Analysis
                        </ButtonLink>
                        <HasPermission permission="CreateAnalysisJob" objectId={analysisJob.epidemicId}>
                            <Button
                                aria-label="Reprocess Analysis Job Results"
                                className={classes.bottomButtons}
                                color="primary"
                                variant="contained"
                                onClick={handleReprocess}
                            >
                                Reprocess Results
                            </Button>
                        </HasPermission>
                    </>
                    : null
                }
            </Box>
        </Paper>
        <Paper className={classNames(className, classes.root)}>
            <div>
                <Typography variant="h6" component="span">Status: </Typography><Typography variant="body1" component="span">{analysisJob ? analysisJob.status : <Skeleton width={200} />}</Typography>
            </div>
            <div>
                <Typography variant="h6" component="span">Progress: </Typography>
                <Typography variant="body1" component="span">
                    {analysisJob ? `${analysisJob.jobPercentage}% ` : <Skeleton width={200} />}
                </Typography>
                <Typography variant="caption" component="span">
                    (Last Checked {analysisJob ? analysisJob.statusLastChecked ? format(toDate(analysisJob.statusLastChecked), DateTimeHourFormat) : 'Never' : <Skeleton width={200} />})
                </Typography>
                {
                    analysisJob && <LinearProgress value={analysisJob.jobPercentage} variant="determinate" />
                }

                {
                    refreshIntervalSeconds && analysisJob && (analysisJob.status == SpreadWorkflowJobStatus.Initing || analysisJob.status == SpreadWorkflowJobStatus.Running)
                        ? <Typography variant="body1" component="span">This page will refresh every {refreshIntervalSeconds} seconds</Typography>
                        : null
                }
            </div>
        </Paper>
    </>
}