/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateAreaDate } from '../components/areaDate/CreateAreaDate';
import { EditAreaDate } from '../components/areaDate/EditAreaDate';
import { ViewAreaDate } from '../components/areaDate/ViewAreaDate';
import { CreateAreaLivestockCount } from '../components/areaLivestockCount/CreateAreaLivestockCount';
import { EditAreaLivestockCount } from '../components/areaLivestockCount/EditAreaLivestockCount';
import { EditInfectedPremise } from '../components/infectedPremise/EditInfectedPremise';
import { InfectedPremiseHome } from '../components/infectedPremise/InfectedPremiseHome';
import { useInfectedPremiseLoad } from '../hooks/infectedPremise';
import { ParcelsPage } from '../pages/application/ParcelsPage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IInfectedPremiseRouterParams {
    infectedPremiseId?: string
}

interface IInfectedPremiseRouterProps {
    infectedPremiseId?: number;
    match: match<IInfectedPremiseRouterParams>;
}

export const InfectedPremiseRouter: React.FunctionComponent<IInfectedPremiseRouterProps> = ({ infectedPremiseId, match }) => {
    const infectedPremiseIdTemp = match.params.infectedPremiseId ? parseInt(match.params.infectedPremiseId) : (infectedPremiseId || -1);

    // Load in IP so child routes can use
    const infectedPremiseIdLoaded = React.useMemo(() => infectedPremiseIdTemp, [infectedPremiseIdTemp])
    const [, response] = useInfectedPremiseLoad(infectedPremiseIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
            <Redirect to="/404" /> :
            <Switch>
                <Route exact path={`${match.path}/date/create`} component={CreateAreaDate} />
                <Route exact path={`${match.path}/date/:areaDateId/edit`} component={EditAreaDate} />
                <Route exact path={`${match.path}/date/:areaDateId`} component={ViewAreaDate} />
                <Route exact path={`${match.path}/livestock/create`} component={CreateAreaLivestockCount} />
                <Route exact path={`${match.path}/livestock/:areaLivestockCountId`} component={EditAreaLivestockCount} />
                <Route exact path={`${match.path}/edit`} component={EditInfectedPremise} />
                <Route path={`${match.path}/parcel`} component={ParcelsPage} />
                <Route path={`${match.path}`} component={InfectedPremiseHome} />
            </Switch>
}