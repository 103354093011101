/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading a group.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, exhaustMap, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest } from '../../../helpers/createRequest';
import { IRequestState } from '../../../types/IRequestState';
import { RequestState } from '../../../types/RequestState';
import { IStoreState } from '../../store';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialGroupMemberState {
    deleteMemberState: IRequestState;
}

export interface IGroupMemberState {
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IGroupMemberActionTypes {
    CLEAR: string;
    DELETE_MEMBER: string;
    DELETE_MEMBER_SUCCESS: string;
    DELETE_MEMBER_FAILURE: string;
}

const groupMemberTypes: IGroupMemberActionTypes = {
    DELETE_MEMBER: '@@groupMember/DELETE_MEMBER',
    DELETE_MEMBER_SUCCESS: '@@groupMember/DELETE_MEMBER_SUCCESS',
    DELETE_MEMBER_FAILURE: '@@groupMember/DELETE_MEMBER_FAILURE',
    CLEAR: '@@groupMember/CLEAR'
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialGroupMemberState = {
    deleteMemberState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const groupMemberReducer = createReducer<IInitialGroupMemberState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const groupMemberActions = {
    clear: createAction(groupMemberTypes.CLEAR,
        () => ({})
    ),
    /**
     * This action begins the request to remove a person from a group.
     */
    deleteMember: createAction(
        groupMemberTypes.DELETE_MEMBER,
        (groupId: number, personId: number) => ({ groupId, personId })
    ),
    /**
     * This action stores the result of removing the person
     */
    deleteMemberSuccess: createAction(
        groupMemberTypes.DELETE_MEMBER_SUCCESS,
        (response: SpreadDtos.DeleteGroupMemberResponse) => ({ response })
    ),
    /**
     * This action stores the error after a failed remove request
     */
    deleteMemberFailure: createAction(
        groupMemberTypes.DELETE_MEMBER_FAILURE,
        (response: SpreadDtos.DeleteGroupMemberResponse) => ({ response })
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Delete Group Member Reducers
groupMemberReducer.on(groupMemberActions.deleteMember, (state, payload) => (
    update(
        state,
        {
            deleteMemberState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

groupMemberReducer.on(groupMemberActions.deleteMemberSuccess, (state, payload) => (
    update(
        state,
        {
            //data: {
            //    $set: payload.response.group
            //},
            deleteMemberState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

groupMemberReducer.on(groupMemberActions.deleteMemberFailure, (state, payload) => (
    update(
        state,
        {
            deleteMemberState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
groupMemberReducer.on(groupMemberActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
groupMemberReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const groupMemberApi = {
    deleteMember: createDeleteRequest(
        SpreadDtos.DeleteGroupMember,
        (groupId: number, personId: number) => ({
            groupId: groupId,
            personId: personId
        })
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const deleteMemberEpic: Epic<ReturnType<typeof groupMemberActions.deleteMember>, any, IStoreState, any> = (action$, state$) => action$.pipe(
    ofType(groupMemberActions.deleteMember.getType()),
    //filter(action => 
    //    state$.value.group.deleteMemberState.state !== RequestState.Pending),
    exhaustMap(action =>
        groupMemberApi.deleteMember(action.payload.groupId, action.payload.personId)
            .pipe(
                mergeMap(response =>
                    of(
                        groupMemberActions.deleteMemberSuccess(response)
                    )
                ),
                catchError(e => of(groupMemberActions.deleteMemberFailure(e)))
            )
    ));

export const groupMemberEpics = combineEpics(deleteMemberEpic);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default groupMemberReducer;