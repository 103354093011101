/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import PersonRoleEditForm from './PersonRoleEditForm';
import { PersonGroup } from '../../dtos/Spread.dtos';
import { personsHooks } from '../../store/reducers/person/personSearch';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        formControl: {
            marginBottom: theme.spacing(1),
            width: '100%'
        },
    }),
);

interface ICreateEpidemicPersonRoleProps {
    epidemicId: number;
    history: History;
    match: match<any>;
}

export const CreateEpidemicPersonRole: React.FunctionComponent<ICreateEpidemicPersonRoleProps> = ({
    epidemicId,
    history,
    match
}) => {
    const classes = useStyles();

    const [personId, setPersonId] = React.useState<number | undefined>(undefined);

    const handlePersonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPersonId(event.target.value as number);
    };

    const specOps = React.useMemo(() => ({}), []);
    const pagOps = React.useMemo(() => ({ orderBy: "displayName", skip: 0, take: 1000 }), []);

    const [personData, paginateOptions, , requestState] = personsHooks.useSearch(specOps, pagOps);

    const menuItems = personData && personData.results
        ? personData.results.map((person) => <MenuItem value={person.id}>{`${person.displayName}`}</MenuItem>)
        : null;

    return <Paper className={classes.root}>
        <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="person-assigned-role-label">Person</InputLabel>
                    <Select
                        value={personId}
                        onChange={handlePersonChange}
                        fullWidth={true}
                    >
                        {menuItems}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        {
            personId && <PersonRoleEditForm
                epidemicId={epidemicId}
                personId={personId}
                history={history}
                url={match.url}
            />
        }
    </Paper>
}