import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

interface NumericFieldWrapperProps extends FieldRenderProps<any, any> {
    label?: string;
    formControlProps?: FormControlProps;
    disabled?: boolean;
    inputProps?: {
        autocomplete?: string;
        autofocus?: boolean;
        dirname?: string;
        max?: number;
        maxlength?: number;
        min?: number;
        minlength?: number;
        pattern?: string;
    };
    numberFormat: Omit<NumberFormatProps, 'format'> & { format?: (val: number | string | undefined) => number | string | undefined };
}


interface IInternalNumberFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { value: number | undefined } }) => void;
}


const InternalNumberFormat: React.FunctionComponent<IInternalNumberFormatProps> = ({
    inputRef,
    onChange,
    ...rest
}) => {
    const onValueChange = React.useCallback((values: NumberFormatValues) => {
        onChange({
            target: {
                value: values.floatValue,
            },
        })
    }, [onChange]);

    return (
        <NumberFormat
            {...rest}
            getInputRef={inputRef}
            onValueChange={onValueChange}
        />
    );
}


const NumericFieldWrapper: React.FunctionComponent<NumericFieldWrapperProps> = ({
    input: { onChange, onBlur, onFocus, value, ...restInput },
    disabled,
    meta,
    label,
    formControlProps,
    inputProps,
    numberFormat,
    ...rest
}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    const onChangeCombined = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(event.target.value || event.target.value as any === 0 ? event.target.value : undefined as any);
    }, [onChange]);

    const allInputProps = { ...inputProps, onBlur, onFocus, value, ...numberFormat}

    return <FormControl {...formControlProps} error={showError}>
        <TextField
            {...rest}
            disabled={disabled}
            inputProps={allInputProps}
            label={label}
            InputProps={{
                inputComponent: InternalNumberFormat as any
            }}
            onChange={onChangeCombined}
        />

        {showError &&
            <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
        }
    </FormControl>;
};
//error={showError}
//helperText={showError ? meta.error || meta.submitError : undefined}

export default NumericFieldWrapper;