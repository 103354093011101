/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import { isNumber } from 'util';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { formatDate, parseDate } from '../../../helpers/dateHelpers';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { materialSearchHooks } from '../../../store/reducers/materials/materialSearch';
import { speciesSearchHooks } from '../../../store/reducers/species/speciesSearch';
import specimenModule from '../../../store/reducers/specimens/specimen';
import { tissueSearchHooks } from '../../../store/reducers/tissues/tissueSearch';
import { RequestState } from '../../../types/RequestState';
import ClusterSelect from '../../forms/ClusterSelect/ClusterSelect';
import EditForm from '../../forms/EditForm';
import DatePickerWrapper from '../../forms/FinalFormControls/DatePickerWrapper';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';
import InfectedPremiseSelect from '../../forms/InfectedPremiseSelect/InfectedPremiseSelect';
import { clear } from '../../forms/mutators';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISpecimenEditFormProps {
    editSpecimen?: SpreadDtos.Specimen;
    history: History;
    loadingSpecimen?: boolean;
    uploadType?: "fastQ" | "fastA";
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface ISpecimenFormSchema extends Partial<SpreadDtos.Specimen> { }

const specimenSchema = Yup.object<ISpecimenFormSchema>().shape({
    epidemicId: Yup.number().label('Epidemic')
        .required(),
    infectedPremiseId: Yup.number().label('Infected Premise')
        .notRequired(),
    speciesId: Yup.number().label('Species')
        .notRequired(),
    collectionDate: Yup.string().label('Collection Date')
        .required(),
    collectionDateRaw: Yup.string().label('GenBank Collection Date')
        .notRequired(),
    specimenId: Yup.string().label('Specimen ID')
        .notRequired(),
    tissueId: Yup.number().label('Tissue')
        .notRequired(),
    nlisrfid: Yup.string().label('NLISRFID')
        .notRequired(),
    isolateName: Yup.string().label('Isolate Name')
        .notRequired(),
    isolationDate: Yup.string().label('Isolation Date')
        .notRequired(),
    materialId: Yup.number().label('Material')
        .notRequired(),
});

const validation = Yup.object().shape({
    object: specimenSchema.notRequired()
})

const SpecimenEditForm: React.FunctionComponent<ISpecimenEditFormProps> = ({
    editSpecimen,
    history,
    loadingSpecimen,
    uploadType,
    url,
}) => {
    const classes = useStyles({});

    const isGenbankRecord = React.useMemo(() => editSpecimen && editSpecimen.sequences && editSpecimen.sequences.length > 0 && editSpecimen.sequences[0].originName == 'GenBank', [editSpecimen]);

    const calculatedEpidemicId = editSpecimen ? editSpecimen.epidemicId : -1;

    const [species, , , speciesRequestState] = speciesSearchHooks.useSearch();

    const speciesOptions = species && species.results ?
        species.results.map(s =>
            <MenuItem
                key={s.id}
                value={s.id}
            >
                {s.name}
            </MenuItem>
        ) :
        [];

    const wrappedObject = {
        object: editSpecimen
    };

    // Setup the tissue options
    const [tissueId, setTissueId] = React.useState(undefined as number | undefined);
    if (!tissueId && editSpecimen && editSpecimen.tissueId) {
        setTissueId(editSpecimen.tissueId);
    }
    const updateTissueId = (tissueId: number | undefined) => {
        setTissueId(tissueId);
    }
    const [tissueData, , , tissueRequestState] = tissueSearchHooks.useSearch();
    const tissueOptions = tissueData && tissueData.results && tissueData.results.length > 0 ?
        tissueData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup tissue options

    // Setup the material options
    const [materialId, setMaterialId] = React.useState(undefined as number | undefined);
    if (!materialId && editSpecimen && editSpecimen.materialId) {
        setMaterialId(editSpecimen.materialId);
    }
    const updateMaterialId = (materialId: number | undefined) => {
        setMaterialId(materialId);
    }
    const [materialData, , , materialRequestState] = materialSearchHooks.useSearch();
    const materialOptions = materialData && materialData.results && materialData.results.length > 0 ?
        materialData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) : [];
    // ~ Setup material options

    return <EditForm
        actions={specimenModule.actions}
        mutators={{ clear: clear }}
        edit={wrappedObject}
        history={history}
        loading={!!loadingSpecimen}
        url={url}
        successRouteCallback={(d) => editSpecimen && editSpecimen.id ? routeBackToPath(url, 2) : routeBackToPath(url, 1, `${d.id}/sequence/create?uploadType=${uploadType}`)}
        validate={validation}
        selectors={specimenModule.selectors}
    >{() => <>
        <FormSpy>
            {props => <OnChange name={"object.clusterId"}>
                {(value: any, previous: any) => {
                    if (previous !== '') {
                        props.form.mutators.clear('object.infectedPremiseId');
                    }
                }}
            </OnChange>}
        </FormSpy>
        <Grid item lg={6} xs={12}>
            {
                editSpecimen && <Field name="object.clusterId" render={(clusterProps) => {

                    return <ClusterSelect
                        epidemicId={calculatedEpidemicId}
                        displayMode={'basic'}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        input={clusterProps.input}
                        label="Cluster"
                        meta={clusterProps.meta}
                    />
                }} />
            }
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            {
                editSpecimen && <Field
                    name="object.clusterId"
                >
                    {
                        clusterProps => (<Field name="object.infectedPremiseId" render={(infectedPremiseProps) => {

                            const clusterId = clusterProps.input.value;

                            return <InfectedPremiseSelect
                                includeNoIPOption
                                //disabled={!clusterId}
                                epidemicId={calculatedEpidemicId}
                                clusterId={clusterId || -1}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                input={infectedPremiseProps.input}
                                label="Infected Premise"
                                meta={infectedPremiseProps.meta}
                            />
                        }} />)
                    }
                </Field>
            }
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            {
                speciesRequestState.state !== RequestState.Pending ?
                    <Field
                        name="object.speciesId"
                        component={SelectWrapper}
                        //disabled={isGenbankRecord}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        label="Host Species"
                    >
                        <MenuItem value={undefined}></MenuItem>
                        {speciesOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.speciesIdLoading"
                        component={TextFieldWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        type="text"
                        label="Host Species"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <Field
                fullWidth
                name="object.collectionDate"
                className={classes.formControl}
                component={DatePickerWrapper}
                label="Date of Specimen Collection"
                parse={parseDate}
                format={formatDate}
            />
        </Grid>
        <Grid hidden={!isGenbankRecord || !editSpecimen || !editSpecimen.collectionDateRaw} item lg={6} xs={12}>
            <Field
                disabled
                fullWidth
                name="object.collectionDateRaw"
                className={classes.formControl}
                component={TextFieldWrapper}
                formControlProps={{
                    className: classes.formControl,
                    fullWidth: true,
                }}
                type="text"
                label="GenBank Entry for Specimen Collection Date"
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <Field
                fullWidth
                name="object.specimenId"
                className={classes.formControl}
                component={TextFieldWrapper}
                //disabled={isGenbankRecord}
                formControlProps={{
                    className: classes.formControl,
                    fullWidth: true,
                }}
                type="text"
                label="Specimen ID"
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            {
                (tissueRequestState.state !== RequestState.Pending) ?
                    <Field
                        fullWidth
                        //disabled={isGenbankRecord}
                        name="object.tissueId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        label="Tissue Specimen"
                    >
                        {tissueOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.tissueIdLoading"
                        component={TextFieldWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        type="text"
                        label="Tissue Specimen"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <FormSpy>
            {props => <Field
                name="object.tissueId"
                subscription={{ value: true }}
                render={field => {
                    const value = field.input.value;
                    if (isNumber(value) && value > 0 && value !== tissueId) {
                        updateTissueId(value);
                    }
                    return null;
                }}
            />}
        </FormSpy>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <Field
                fullWidth
                name="object.nlisrfid"
                component={TextFieldWrapper}
                //disabled={isGenbankRecord}
                formControlProps={{
                    className: classes.formControl,
                    fullWidth: true,
                }}
                type="text"
                label="NLIS RFID (or animal id)"
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            {
                (materialRequestState.state !== RequestState.Pending) ?
                    <Field
                        fullWidth
                        //disabled={isGenbankRecord}
                        name="object.materialId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        label="Material Sent For Sequencing"
                    >
                        {materialOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.materialIdLoading"
                        component={TextFieldWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        type="text"
                        label="Material Sent For Sequencing"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <FormSpy>
            {props => <Field
                name="object.materialId"
                subscription={{ value: true }}
                render={field => {
                    const value = field.input.value;
                    if (isNumber(value) && value > 0 && value !== materialId) {
                        updateMaterialId(value);
                    }
                    return null;
                }}
            />}
        </FormSpy>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <Field
                fullWidth
                name="object.isolationDate"
                component={DatePickerWrapper}
                className={classes.formControl}
                //disabled={isGenbankRecord}
                label="Date of Isolation (if undertaken)"
                parse={parseDate}
                format={formatDate}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <Field
                fullWidth
                name="object.isolateName"
                className={classes.formControl}
                component={TextFieldWrapper}
                disabled={isGenbankRecord}
                formControlProps={{
                    className: classes.formControl,
                    fullWidth: true,
                }}
                type="text"
                label="Isolate Name"
            />
        </Grid>
    </>}
    </EditForm>
};

export default SpecimenEditForm;