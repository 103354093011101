/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an epidemic tapm
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import download from 'downloadjs';
import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IAnalysisTapmFormSchema } from '../../../components/analysis/tapm/types';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { IFormRequestState, IRequestState } from '../../../types/IRequestState';
import { RequestFormState, RequestState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export type WindDispersionAnalysisResults = {
    data: SpreadDtos.AnalysisJobWindDispersionResult[];
    startDate: string;
    endDate: string;
};

interface IInitialAnalysisTapmState {
    data?: SpreadDtos.AnalysisJobTapm;
    file?: Blob,
    results: {};
    createState: IFormRequestState;
    loadState: IRequestState;
    deleteState: IFormRequestState;
    loadResultsState: IRequestState;
    reprocessResultsState: IRequestState;
    refreshTapmDataSetsState: IRequestState;
    extraStates: {
        cancelState: IFormRequestState;
        loadFileState: IFormRequestState;
    },
}

export interface IAnalysisTapmState {
    analysisTapm: IInitialAnalysisTapmState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@analysisTapm';

export const AnalysisTapmTypes = {
    CREATE: `${typeNamespace}/CREATE`,
    CREATE_FORM_RESPONSE: `${typeNamespace}/CREATE_FORM_RESPONSE`,
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    DELETE: `${typeNamespace}/DELETE`,
    DELETE_FORM_RESPONSE: `${typeNamespace}/DELETE_FORM_RESPONSE`,
    CANCEL: `${typeNamespace}/CANCEL`,
    CANCEL_FORM_RESPONSE: `${typeNamespace}/CANCEL_FORM_RESPONSE`,
    LOAD_RESULTS: `${typeNamespace}/LOAD_RESULTS`,
    LOAD_RESULTS_SUCCESS: `${typeNamespace}/LOAD_RESULTS_SUCCESS`,
    LOAD_RESULTS_FAILURE: `${typeNamespace}/LOAD_RESULTS_FAILURE`,
    REPROCESS_RESULTS: `${typeNamespace}/REPROCESS_RESULTS`,
    REPROCESS_RESULTS_SUCCESS: `${typeNamespace}/REPROCESS_RESULTS_SUCCESS`,
    REPROCESS_RESULTS_FAILURE: `${typeNamespace}/REPROCESS_RESULTS_FAILURE`,
    REFRESH_TAPM_DATA_SETS: `${typeNamespace}/REFRESH_TAPM_DATA_SETS`,
    REFRESH_TAPM_DATA_SETS_SUCCESS: `${typeNamespace}/REFRESH_TAPM_DATA_SETS_SUCCESS`,
    REFRESH_TAPM_DATA_SETS_FAILURE: `${typeNamespace}/REFRESH_TAPM_DATA_SETS_FAILURE`,
    LOAD_TAPM_RESULT_FILE: `${typeNamespace}/LOAD_TAPM_RESULT_FILE`,
    LOAD_TAPM_RESULT_FILE_FORM_RESPONSE: `${typeNamespace}/LOAD_TAPM_RESULT_FILE_FORM_RESPONSE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAnalysisTapmState = {
    data: undefined,
    file: undefined,
    loadState: {
        state: RequestState.None
    },
    loadResultsState: {
        state: RequestState.None
    },
    createState: {
        state: RequestFormState.None
    },
    deleteState: {
        state: RequestFormState.None
    },
    reprocessResultsState: {
        state: RequestState.None
    },
    refreshTapmDataSetsState: {
        state: RequestState.None
    },
    extraStates: {
        cancelState: {
            state: RequestFormState.None
        },
        loadFileState: {
            state: RequestFormState.None
        },
    },
    results: {}
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const analysisTapmReducer = createReducer<IInitialAnalysisTapmState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const analysisTapmActions = {
    /**
     * Begins the request to create a new analysisTapm.
     * 
     * This cannot update an existing analysisTapm. Use update for that.
     */
    create: createAction(AnalysisTapmTypes.CREATE,
        (analysis: IAnalysisTapmFormSchema) => ({ analysis })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    createFormResponse: createAction(AnalysisTapmTypes.CREATE_FORM_RESPONSE,
        (response: SpreadDtos.CreateAnalysisJobTapmResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given apiJobId.
     */
    load: createAction(AnalysisTapmTypes.LOAD,
        (apiJobId: number) => ({ apiJobId })
    ),
    /**
     * Stores the analysisTapm after a successful load request
     */
    loadSuccess: createAction(AnalysisTapmTypes.LOAD_SUCCESS,
        (response: SpreadDtos.GetAnalysisJobTapmResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    loadFailure: createAction(AnalysisTapmTypes.LOAD_FAILURE,
        (response: SpreadDtos.GetAnalysisJobTapmResponse) => ({ response })
    ),
    /**
     * Begins the request to delete an alalysis job.
     */
    delete: createAction(AnalysisTapmTypes.DELETE,
        (analysisJobId: number) => ({ id: analysisJobId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    deleteFormResponse: createAction(AnalysisTapmTypes.DELETE_FORM_RESPONSE,
        (response: SpreadDtos.DeleteAnalysisJobTapmResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to cancel a tapm job.
     */
    cancel: createAction(AnalysisTapmTypes.CANCEL,
        (tapmJobId: number) => ({ tapmJobId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    cancelFormResponse: createAction(AnalysisTapmTypes.CANCEL_FORM_RESPONSE,
        (response: SpreadDtos.CancelAnalysisJobTapmResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given analysisTapm.
     */
    loadResults: createAction(AnalysisTapmTypes.LOAD_RESULTS,
        (analysisTapmId: number, analysisMode: SpreadDtos.WindDispersionResultType) => ({ analysisTapmId, analysisMode })
    ),
    /**
     * Stores the analysisTapm after a successful load request
     */
    loadResultsSuccess: createAction(AnalysisTapmTypes.LOAD_RESULTS_SUCCESS,
        (response: SpreadDtos.GetAnalysisJobTapmResultResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    loadResultsFailure: createAction(AnalysisTapmTypes.LOAD_RESULTS_FAILURE,
        (response: SpreadDtos.GetAnalysisJobTapmResultResponse) => ({ response })
    ),
    /**
     * Begins the request to load a given analysisTapm.
     */
    reprocessResults: createAction(AnalysisTapmTypes.REPROCESS_RESULTS,
        (analysisTapmId: number) => ({ analysisTapmId })
    ),
    /**
     * Stores the analysisTapm after a successful load request
     */
    reprocessResultsSuccess: createAction(AnalysisTapmTypes.REPROCESS_RESULTS_SUCCESS,
        (response: SpreadDtos.ProcessAnalysisJobTapmResultResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    reprocessResultsFailure: createAction(AnalysisTapmTypes.REPROCESS_RESULTS_FAILURE,
        (response: SpreadDtos.ProcessAnalysisJobTapmResultResponse) => ({ response })
    ),
    /**
     * Begins the request to load a given analysisTapm.
     */
    refreshTapmDatasets: createAction(AnalysisTapmTypes.REFRESH_TAPM_DATA_SETS,
        () => ({})
    ),
    /**
     * Stores the analysisTapm after a successful load request
     */
    refreshTapmDatasetsSuccess: createAction(AnalysisTapmTypes.REFRESH_TAPM_DATA_SETS_SUCCESS,
        (response: SpreadDtos.RefreshTapmDatasetsResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    refreshTapmDatasetsFailure: createAction(AnalysisTapmTypes.REFRESH_TAPM_DATA_SETS_FAILURE,
        (response: SpreadDtos.RefreshTapmDatasetsResponse) => ({ response })
    ),
    loadTapmResultFile: createAction(AnalysisTapmTypes.LOAD_TAPM_RESULT_FILE,
        (apiJobId: number) => ({ apiJobId })
    ),
    loadTapmResultFileFormResponse: createAction(AnalysisTapmTypes.LOAD_TAPM_RESULT_FILE_FORM_RESPONSE,
        (response: Blob, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the analysisTapm state.
    */
    clear: createAction(AnalysisTapmTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Create new Reducers
analysisTapmReducer.on(analysisTapmActions.create, (state) => (
    update(
        state,
        {
            createState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.createFormResponse, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.analysisJob
            },
            createState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Load Reducers
analysisTapmReducer.on(analysisTapmActions.load, (state) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.analysisJob
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Delete Reducers
analysisTapmReducer.on(analysisTapmActions.delete, (state) => (
    update(
        state,
        {
            deleteState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.deleteFormResponse, (state, payload) => (
    update(
        state,
        {
            deleteState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Cancel Reducers
analysisTapmReducer.on(analysisTapmActions.cancel, (state) => (
    update(
        state,
        {
            extraStates: {
                cancelState: {
                    $set: {
                        state: RequestFormState.Pending
                    }
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.cancelFormResponse, (state, payload) => (
    update(
        state,
        {
            extraStates: {
                cancelState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
));

// Load Results Reducers
analysisTapmReducer.on(analysisTapmActions.loadResults, (state, payload) => (
    update(
        state,
        {
            results: {
                [payload.analysisTapmId]: {
                    $set: setBitAroo(state.results[payload.analysisTapmId] && state.results[payload.analysisTapmId], payload)
                }
            },
            loadResultsState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

//Load result file reducers
analysisTapmReducer.on(analysisTapmActions.loadTapmResultFile, (state, payload) => update(
    state,
    {
        extraStates: {
            loadFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
));

analysisTapmReducer.on(analysisTapmActions.loadTapmResultFileFormResponse, (state, payload: any) => {
    download(payload.response, "TAPM result.zip");
    return update(
        state,
        {
            file: {
                $set: payload.response
            },
            extraStates: {
                loadFileState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    );
});

const setBitAroo = (statePart: any, payload: any) => {
    /*const newState = statePart && update(statePart,
        {
            [payload.analysisMode]: {$set: { }}
        }
    )

    return newState;*/

    return {
        ...statePart,
        [payload.analysisMode]: {}
    };
}

analysisTapmReducer.on(analysisTapmActions.loadResultsSuccess, (state, payload) => (
    update(
        state,
        {
            results: {
                [payload.response.analysisTapmId]: {
                    [payload.response.analysisResultType]: {
                        $set: payload.response
                    }
                }
            },
            loadResultsState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.loadResultsFailure, (state, payload) => (
    update(
        state,
        {
            loadResultsState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Reprocess Results Reducers
analysisTapmReducer.on(analysisTapmActions.reprocessResults, (state) => (
    update(
        state,
        {
            reprocessResultsState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.reprocessResultsSuccess, (state, payload) => (
    update(
        state,
        {
            reprocessResultsState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.reprocessResultsFailure, (state, payload) => (
    update(
        state,
        {
            reprocessResultsState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Refresh Tapm Datasets Reducers
analysisTapmReducer.on(analysisTapmActions.refreshTapmDatasets, (state) => (
    update(
        state,
        {
            refreshTapmDataSetsState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.refreshTapmDatasetsSuccess, (state, payload) => (
    update(
        state,
        {
            refreshTapmDataSetsState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

analysisTapmReducer.on(analysisTapmActions.refreshTapmDatasetsFailure, (state, payload) => (
    update(
        state,
        {
            refreshTapmDataSetsState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
analysisTapmReducer.on(analysisTapmActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
analysisTapmReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const analysisTapmsApi = {
    create: createPostRequest(
        SpreadDtos.CreateAnalysisJobTapm,
        (analysisJob: IAnalysisTapmFormSchema) => ({
            name: analysisJob.name,
            subClusterIds: [analysisJob.subClusterId],
            infectedPremiseIds: analysisJob.infectedPremiseIds && analysisJob.infectedPremiseIds.filter(ip => ip !== null),
            entireEpidemicId: analysisJob.entireEpidemicId,
            emissionRate: analysisJob.emissionRate,
            tapmExcretionId: analysisJob.tapmExcretionId,
            tapmDataSetId: analysisJob.tapmDataSetId,
            limitRunStartDate: analysisJob.limitRunStartDate,
            limitRunEndDate: analysisJob.limitRunEndDate,
            forecastLength: analysisJob.forecastLength,
            nestingLevel: analysisJob.nestingLevel,
            virusSurvival: analysisJob.virusSurvival,
            extentKilometres: analysisJob.extentKilometres !== -1 ? analysisJob.extentKilometres : undefined
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetAnalysisJobTapm,
        (apiJobId: number) => ({
            apiJobId: apiJobId
        })
    ),
    delete: createDeleteRequest(
        SpreadDtos.DeleteAnalysisJobTapm,
        (apiJobId: number) => ({
            apiJobId: apiJobId
        })
    ),
    cancel: createPutRequest(
        SpreadDtos.CancelAnalysisJobTapm,
        (apiJobId: number) => ({
            apiJobId,
        })
    ),
    loadResults: createGetRequest(
        SpreadDtos.GetAnalysisJobTapmResult,
        (apiJobId: number, resultMode: SpreadDtos.WindDispersionResultType) => ({
            resultType: resultMode,
            apiJobId: apiJobId
        })
    ),
    reprocessResults: createPutRequest(
        SpreadDtos.ProcessAnalysisJobTapmResult,
        (apiJobId: number) => ({
            apiJobId: apiJobId
        })
    ),
    refreshTapmDataSets: createGetRequest(
        SpreadDtos.RefreshTapmDatasets,
        () => ({})
    ),
    getAnalysisJobTapmResultFile: createGetRequest(
        SpreadDtos.GetAnalysisJobTapmResultFile,
        (apiJobId: number) => ({
            apiJobId: apiJobId
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const createEpic: Epic<ReturnType<typeof analysisTapmActions.create>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.create.getType()),
    mergeMap(action =>
        analysisTapmsApi.create(action.payload.analysis)
            .pipe(
                mergeMap(response =>
                    of(analysisTapmActions.createFormResponse(response, RequestFormState.SubmitSuccess))
                ),
                catchError(e => of(analysisTapmActions.createFormResponse(e, RequestFormState.ServerError)))
            )
    )
);

export const loadEpic: Epic<ReturnType<typeof analysisTapmActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.load.getType()),
    mergeMap(action =>
        analysisTapmsApi.load(action.payload.apiJobId)
            .pipe(
                mergeMap(response =>
                    of(
                        analysisTapmActions.loadSuccess(response)
                    )
                ),
                catchError(e => of(analysisTapmActions.loadFailure(e)))
            )
    )
);

export const deleteEpic: Epic<ReturnType<typeof analysisTapmActions.delete>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.delete.getType()),
    mergeMap(action => {
        return analysisTapmsApi.delete(action.payload.id)
            .pipe(
                mergeMap(response => of(analysisTapmActions.deleteFormResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(analysisTapmActions.deleteFormResponse(e, RequestFormState.ServerError)))
            )
    })
);

export const cancelEpic: Epic<ReturnType<typeof analysisTapmActions.cancel>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.cancel.getType()),
    mergeMap(action =>
        analysisTapmsApi.cancel(action.payload.tapmJobId)
            .pipe(
                mergeMap(response => of(analysisTapmActions.cancelFormResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(analysisTapmActions.cancelFormResponse(e, RequestFormState.ServerError)))
            )
    )
);

export const loadResultsEpic: Epic<ReturnType<typeof analysisTapmActions.loadResults>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.loadResults.getType()),
    mergeMap(action =>
        analysisTapmsApi.loadResults(action.payload.analysisTapmId, action.payload.analysisMode)
            .pipe(
                mergeMap(response =>
                    of(
                        analysisTapmActions.loadResultsSuccess(response)
                    )
                ),
                catchError(e => of(analysisTapmActions.loadResultsFailure(e)))
            )
    ));

export const reprocessResultsEpic: Epic<ReturnType<typeof analysisTapmActions.reprocessResults>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.reprocessResults.getType()),
    mergeMap(action =>
        analysisTapmsApi.reprocessResults(action.payload.analysisTapmId)
            .pipe(
                mergeMap(response =>
                    of(
                        analysisTapmActions.reprocessResultsSuccess(response)
                    )
                ),
                catchError(e => of(analysisTapmActions.reprocessResultsFailure(e)))
            )
    ));

export const refreshTapmDataSetsEpic: Epic<ReturnType<typeof analysisTapmActions.refreshTapmDatasets>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(analysisTapmActions.refreshTapmDatasets.getType()),
    mergeMap(action =>
        analysisTapmsApi.refreshTapmDataSets()
            .pipe(
                mergeMap(response => of(analysisTapmActions.refreshTapmDatasetsSuccess(response))),
                catchError(e => of(analysisTapmActions.refreshTapmDatasetsFailure(e)))
            )
    ));

export const getAnalysisJobTapmResultFileEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$) =>
    action$.pipe(
        ofType(analysisTapmActions.loadTapmResultFile.getType()),
        mergeMap(action =>
            analysisTapmsApi.getAnalysisJobTapmResultFile(action.payload.apiJobId)
                .pipe(
                    mergeMap(response => of(analysisTapmActions.loadTapmResultFileFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(analysisTapmActions.loadTapmResultFileFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

export const analysisTapmEpics = combineEpics(createEpic, loadEpic, deleteEpic, cancelEpic, loadResultsEpic, reprocessResultsEpic, refreshTapmDataSetsEpic, getAnalysisJobTapmResultFileEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default analysisTapmReducer;