/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import PublicIcon from '@material-ui/icons/Public';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { InfectedPremise } from '../../dtos/Spread.dtos';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import IconButtonLink from '../common/IconLink';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewInfectedPremiseParams {
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
    subClusterId: string;
}

interface IViewInfectedPremiseProps {
    infectedPremise?: InfectedPremise;
    loading: boolean;
    match: match<IViewInfectedPremiseParams>;
}

export const ViewInfectedPremise: React.FunctionComponent<IViewInfectedPremiseProps> = ({ infectedPremise, loading, match }) => {
    const classes = useStyles();

    const hideCluster = (infectedPremise === undefined || (infectedPremise !== undefined && infectedPremise.subCluster.cluster.unassigned))
    const hideSubCluster = (infectedPremise === undefined || (infectedPremise && infectedPremise.subCluster && infectedPremise.subCluster.unassigned));

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Farm ID: {!loading ? infectedPremise && infectedPremise.farmId : <Skeleton />}</Typography>
        <Typography variant="h4" component="h4">IP ID: {!loading ? infectedPremise && infectedPremise.infectedPremiseId : <Skeleton />}</Typography>
        <Typography variant="h6" component="h6" hidden={hideCluster}>Cluster: {!loading ? infectedPremise && infectedPremise.subCluster.cluster.name : <Skeleton />}</Typography>
        <Typography variant="h6" component="h6" hidden={hideSubCluster}>Sub-Cluster: {!loading ? infectedPremise && infectedPremise.subCluster.name : <Skeleton />}</Typography>
        <Typography variant="body1" component="p">{!loading ? infectedPremise?.centreLat && `Latitude : ${infectedPremise.centreLat}` : <Skeleton />}</Typography>
        <Typography variant="body1" component="p">{!loading ? infectedPremise?.centreLng && `Longitude: ${infectedPremise.centreLng}` : <Skeleton />}</Typography>
        <Typography variant="body1" component="p">{!loading ? infectedPremise?.area && <> Area: {infectedPremise.area} m<sup>2</sup></> : <Skeleton />} </Typography>
        <Typography variant="h6" component="h6">
            {!loading ? infectedPremise && infectedPremise.centreLat && infectedPremise.centreLng ? <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${infectedPremise.epidemicId}/results/map?infectedPremiseId=${infectedPremise.id}`}>
                <PublicIcon />
            </IconButtonLink> : null : <Skeleton />}
        </Typography>
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="UpdateInfectedPremise" objectId={infectedPremise?.epidemicId}>
                <ButtonLink
                    aria-label="Edit Infected Premise"
                    color="primary"
                    variant="contained"
                    to={`${match.url}/edit`}
                >
                    <EditIcon className={classes.buttonSubmitIcon} />
                    Edit
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>

}