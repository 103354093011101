/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateAreaDate } from '../components/areaDate/CreateAreaDate';
import { EditAreaDate } from '../components/areaDate/EditAreaDate';
import { ViewAreaDate } from '../components/areaDate/ViewAreaDate';
import { CreateAreaLivestockCount } from '../components/areaLivestockCount/CreateAreaLivestockCount';
import { EditAreaLivestockCount } from '../components/areaLivestockCount/EditAreaLivestockCount';
import { EditField } from '../components/field/EditField';
import { FieldHome } from '../components/field/FieldHome';
import { useFieldLoad } from '../hooks/field';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IFieldRouterParams {
    fieldId?: string
}

interface IFieldRouterProps {
    fieldId?: number;
    match: match<IFieldRouterParams>;
}

export const FieldRouter: React.FunctionComponent<IFieldRouterProps> = ({ fieldId, match }) => {
    const fieldIdTemp = match.params.fieldId ? parseInt(match.params.fieldId) : (fieldId || -1);

    // Load in field so child routes can use
    const fieldIdLoaded = React.useMemo(() => fieldIdTemp, [fieldIdTemp])
    const [, response] = useFieldLoad(fieldIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/date/create`} component={CreateAreaDate} />
            <Route exact path={`${match.path}/date/:areaDateId/edit`} component={EditAreaDate} />
            <Route exact path={`${match.path}/date/:areaDateId`} component={ViewAreaDate} />
            <Route exact path={`${match.path}/livestock/create`} component={CreateAreaLivestockCount} />
            <Route exact path={`${match.path}/livestock/:areaLivestockCountId`} component={EditAreaLivestockCount} />
            <Route exact path={`${match.path}/edit`} component={EditField} />
            <Route exact path={`${match.path}`} component={FieldHome} />
        </Switch>
}