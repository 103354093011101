/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Theme, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { createStyles, makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { useMemo } from 'react';
import { Polyline, Tooltip } from "react-leaflet";

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { InfectedPremise } from '../../../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface INetworkLine {
    infectedPremiseFrom: InfectedPremise;
    infectedPremiseTo: InfectedPremise;

    probability: number;

    hide?: boolean;
    keepTooltipOpen?: boolean;
    color?: string;
    weight?: number;
    opacity?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        probTooltip: {
            margin: 0,
        }
    })
);

const NetworkLine: React.FunctionComponent<INetworkLine> = ({ infectedPremiseFrom, infectedPremiseTo, probability, hide, keepTooltipOpen, color, weight, opacity }) => {
    const classes = useStyles();

    const centerFrom = useMemo<[number, number]>(() => [infectedPremiseFrom.centreLat, infectedPremiseFrom.centreLng], [infectedPremiseFrom,]);
    const centerTo = useMemo<[number, number]>(() => [infectedPremiseTo.centreLat, infectedPremiseTo.centreLng], [infectedPremiseTo]);

    const vertical = Math.abs(centerTo[0] - centerFrom[0]) > Math.abs(centerTo[1] - centerFrom[1]);
    const positive = vertical ? centerTo[0] - centerFrom[0] > 0 : centerTo[1] - centerFrom[1] > 0;

    return <>
        {
            !hide && <Polyline
                weight={weight || 2}
                opacity={opacity || 1}
                positions={[centerFrom, centerTo]}
            >
                <Tooltip
                    permanent={keepTooltipOpen}
                    direction={vertical ? 'left' : 'top'}
                >
                    <div className={classes.probTooltip}>
                        <Typography variant="h6" component="h6">
                            {!positive ?
                                vertical ?
                                    <ArrowDownwardIcon fontSize='small' /> :
                                    <ArrowBackIcon fontSize='small' /> :
                                ""}
                            {probability}
                            {positive ?
                                vertical ?
                                    <ArrowUpwardIcon fontSize='small' /> :
                                    <ArrowForwardIcon fontSize='small' /> :
                                ""}
                        </Typography>
                    </div>
                </Tooltip>
            </Polyline>
        }
    </>;
}

export default NetworkLine;