/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useInfectedPremiseLoad } from '../../hooks/infectedPremise';
import { useSelector } from '../../hooks/useTypedSelector';
import { infectedPremiseActions } from '../../store/reducers/infectedPremises/infectedPremise';
import { RequestFormState } from '../../types/RequestState';
import InfectedPremiseEditForm from './InfectedPremiseEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditInfectedPremiseParams {
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
}

interface IEditInfectedPremiseProps {
    infectedPremiseId?: number;
    history: History;
    match: match<IEditInfectedPremiseParams>;
}

export const EditInfectedPremise: React.FunctionComponent<IEditInfectedPremiseProps> = (props) => {
    const infectedPremiseState = useSelector(state => state.infectedPremise);
    const dispatch = useDispatch();
    const classes = useStyles();

    const infectedPremiseId = props.infectedPremiseId || (props.match.params ? parseInt(props.match.params.infectedPremiseId) : undefined);

    const [,] = useInfectedPremiseLoad(infectedPremiseId);

    return <Paper className={classes.root}>
        <InfectedPremiseEditForm
            editInfectedPremise={infectedPremiseState.data}
            history={props.history}
            loadingInfectedPremise={infectedPremiseState.states.loadState.state === RequestFormState.Pending && !infectedPremiseState.data}
            url={props.match.url}
        />
    </Paper>
}