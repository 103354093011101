/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Specimen } from '../../../dtos/Spread.dtos';
import { GenomicFormWrapper } from '../GenomicDataUpload';
import SpecimenEditForm from './SpecimenEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateSpecimenParams {
    epidemicId: string;
}

interface ICreateSpecimenProps {
    epidemicId?: number;
    history: History;
    match: match<ICreateSpecimenParams>;
    uploadType?: "fastQ" | "fastA";
}

export const CreateSpecimen: React.FunctionComponent<ICreateSpecimenProps> = ({
    epidemicId,
    history,
    match,
    uploadType: uploadTypeProp,
}) => {
    const classes = useStyles();

    const params = parseQueryString(history.location.search);

    epidemicId = epidemicId || (match.params ? parseInt(match.params.epidemicId) : undefined);

    const uploadType = uploadTypeProp || (params.uploadType == "fastQ" || params.uploadType == "fastA" ? params.uploadType : undefined);

    return <Paper className={classes.root}>
        <GenomicFormWrapper
            title={`Upload new ${uploadType ? `${uploadType.toUpperCase()} ` : ''}Genomic Data`}
            subtitle={`Step 1 - Isolate and specimen metadata`}
        >
            <SpecimenEditForm
                editSpecimen={new Specimen({
                    epidemicId
                })}
                uploadType={uploadType}
                history={history}
                url={match.url}
            />
        </GenomicFormWrapper>
    </Paper>
}