/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an subCluster
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActions, createCrudActionTypes, createCrudEpics, createCrudSelectors, IBaseCrudState, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialSubClusterState extends IBaseCrudState {
    data?: SpreadDtos.SubCluster;
}

export interface ISubClusterState {
    subCluster: IInitialSubClusterState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface ISubClusterActionTypes {
    CLEAR: string;
}

const subClusterTypes: IBaseCrudTypes & ISubClusterActionTypes = {
    ...createCrudActionTypes('subCluster'),
    CLEAR: '@@subCluster/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialSubClusterState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const subClusterReducer = createReducer<IInitialSubClusterState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const subClusterActions = {
    ...createCrudActions<SpreadDtos.SubCluster, SpreadDtos.CreateSubClusterResponse, SpreadDtos.GetSubClusterResponse, SpreadDtos.UpdateSubClusterResponse, undefined, undefined>(subClusterTypes),
    clear: createAction(subClusterTypes.CLEAR,
        () => ({})
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(subClusterReducer, subClusterActions, 'subCluster', initialState);

// Clear Reducer
subClusterReducer.on(subClusterActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
subClusterReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const subClustersApi = {
    create: createPostRequest(
        SpreadDtos.CreateSubCluster,
        (subCluster: SpreadDtos.SubCluster) => ({
            subCluster: subCluster
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetSubCluster,
        (subClusterId: number) => ({
            id: subClusterId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateSubCluster,
        (subCluster: SpreadDtos.SubCluster) => ({
            subCluster: subCluster
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(subClusterActions, subClustersApi);

export const subClusterEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const subClusterSelectors = createCrudSelectors('subCluster');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default subClusterReducer;