/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useParcelLoad } from '../../hooks/parcel';
import { RequestFormState } from '../../types/RequestState';
import { ViewAreaDates } from '../areaDate/ViewAreaDates';
import { ViewAreaLivestockCounts } from '../areaLivestockCount/ViewAreaLivestockCounts';
import { ViewFields } from '../field/ViewFields';
import { ViewParcel } from './ViewParcel';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IParcelHomeParams {
    areaId: string;
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
    parcelId: string;
    subClusterId: string;
}

interface IParcelHomeProps {
    parcelId?: number;
    history: History;
    match: match<IParcelHomeParams>;
}

export const ParcelHome: React.FunctionComponent<IParcelHomeProps> = ({ parcelId, history, match }) => {
    const classes = useStyles();

    let loadedParcelId = parcelId || (match.params ? parseInt(match.params.parcelId) : undefined);
    loadedParcelId = React.useMemo(() => loadedParcelId, [loadedParcelId]);

    if (!loadedParcelId) {
        throw Error("Invalid ID specified");
    }

    const [parcelData, parcelState] = useParcelLoad(loadedParcelId);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewParcel
                loading={parcelState.state === RequestFormState.Pending}
                match={match}
                parcel={parcelData}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewFields
                parcelId={loadedParcelId}
                match={match}
                history={history}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaLivestockCounts
                areaId={loadedParcelId}
                history={history}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaDates
                areaId={loadedParcelId}
                history={history}
                match={match}
            />
        </Box>
    </Box>
}
