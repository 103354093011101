/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple area dates.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';


/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialAreaDateSearchState = ISearchState<SpreadDtos.QueryAreaDate>;

export interface IAreaDateSearchState {
    areaDateSearch: IInitialAreaDateSearchState;
}

export const initialState: IAreaDateSearchState = {
    areaDateSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const areaDatesModule = createAutoQueryModule(
    'areaDateSearch',
    initialSearchState,
    '@@areaDateSearch',
    SpreadDtos.QueryAreaDates,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        areaId: options.specialOptions.areaId,
        clusterId: options.specialOptions.clusterId,
        epidemicId: options.specialOptions.epidemicId,
        infectedPremiseId: options.specialOptions.infectedPremiseId,
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const areaDateSearchActions = areaDatesModule.actions;
export const areaDateSearchEpics = areaDatesModule.epics;
export const areaDatesApi = areaDatesModule.api;
export const areaDatesSelectors = areaDatesModule.selectors;
export const areaDatesHooks = areaDatesModule.hooks;
export default areaDatesModule.reducer;
