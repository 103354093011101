/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an species
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateSpecies,
    CreateSpeciesResponse,
    GetSpecies,
    GetSpeciesResponse,
    Species,
    UpdateSpecies,
    UpdateSpeciesResponse
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ISpeciesState {
    species: IBaseCrudState<Species>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const speciesModule = createCrudModule<Species, CreateSpeciesResponse, GetSpeciesResponse, UpdateSpeciesResponse, undefined, undefined>(
    'species',
    CreateSpecies,
    GetSpecies,
    UpdateSpecies,
    undefined,
    undefined,
)


export default speciesModule;