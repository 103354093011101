/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MockSelect } from './MockSelect';
import { MockRadioButtons } from './MockRadioButtons';
import { MockText, MockMultiLineText } from './MockText';
import { IMockFormState, MockFormContext } from './MockFormContext';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        }
    }),
);

interface IMockSequenceFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

export const MockSequenceForm: React.FunctionComponent<IMockSequenceFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);

    const disablePlatform = mockForm.fields['seq_sequencingSystem'] === '2';

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seq_enrichmentMethod'}
                title={'Enrichment method'}
                items={[
                    {
                        key: '1',
                        label: 'PolyA enrichment'
                    },
                    {
                        key: '2',
                        label: 'CDNA subtraction'
                    },
                    {
                        key: '3',
                        label: 'PCR ("AMPLICON")'
                    },
                    {
                        key: '4',
                        label: 'Magnetic beads'
                    },
                    {
                        key: '5',
                        label: 'Affinity purification'
                    },
                    {
                        key: '6',
                        label: 'Other'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'seq_sequencingLaboratory'}
                title='Sequencing Laboratory'
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'seq_dateOfSequencing'}
                title='Date of Sequencing'
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'seq_sequencingTechnician'}
                title='Sequencing Technician'
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockRadioButtons
                fieldName={'seq_sequencingSystem'}
                className={classes.formControl}
                title={'Sequencing system'}
                items={[
                    {
                        key: '1',
                        label: 'NGS'
                    },
                    {
                        key: '2',
                        label: 'Capillary'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seq_platform'}
                disabled={disablePlatform}
                title={'Platform'}
                items={[
                    {
                        key: '1',
                        label: 'Illumina'
                    },
                    {
                        key: '2',
                        label: 'PacBio'
                    },
                    {
                        key: '3',
                        label: 'Oxford Nanopore'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seq_libraryStrategy'}
                disabled={disablePlatform}
                title={'Library Strategy'}
                items={[
                    {
                        key: '1',
                        label: 'Whole Genome Shotgun'
                    },
                    {
                        key: '2',
                        label: 'Partial Genome'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockRadioButtons
                className={classes.formControl}
                fieldName={'seq_pairing'}
                disabled={disablePlatform}
                title={''}
                items={[
                    {
                        key: '1',
                        label: 'Paired End'
                    },
                    {
                        key: '2',
                        label: 'Single End'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seq_instrument'}
                disabled={disablePlatform}
                title={'Instrument'}
                items={[
                    {
                        key: '1',
                        label: 'Illumina MiSeq'
                    },
                    {
                        key: '2',
                        label: 'Illumina Genome Analyzer IIx'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockMultiLineText
                className={classes.formControl}
                fieldName={'seq_comments'}
                title='Comment'
            />
        </Grid>
        <Grid item xs={12} />
    </Grid>
}