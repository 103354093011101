/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Popup, Rectangle } from "react-leaflet";
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { AnalysisJobWindDispersionResultValue, ResultGridCell } from '../../../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface IWindDispersionHeatmapProps {
    max: number;
    useCutoff: boolean;
    minimumCutOff: number;
    windResults: { [index: number]: AnalysisJobWindDispersionResultValue; }
    resultGrid?: ResultGridCell[];
    unitsLabel: any;
}

export const WindDispersionHeatmap: React.FunctionComponent<IWindDispersionHeatmapProps> = ({ max, useCutoff, minimumCutOff, windResults, resultGrid, unitsLabel }) => {

    function colorForValue(value: number) {
        var h = ((max - value) / max) * 200
        return "hsl(" + h + ", 100%, 50%)";
    }

    return <> {
        resultGrid && resultGrid.map(gridCell => {

            const centreLat = Number.parseFloat(gridCell.lat);
            const centreLng = Number.parseFloat(gridCell.lng);

            const cellValue = windResults[gridCell.key] ? windResults[gridCell.key].value : 0;

            var hide: boolean;

            if (useCutoff) {
                hide = cellValue <= minimumCutOff;
            }
            else {
                hide = cellValue == 0;
            }

            return !hide && <Rectangle
                bounds={[
                    [centreLat, centreLng],
                    [centreLat + gridCell.latSize, centreLng + gridCell.lngSize]
                ]}
                color={colorForValue(cellValue)}
                key={`gridcell-${gridCell.key}`}
                weight={.1}
                fillOpacity={0.25}
            >
                <Popup>
                    <div>
                        <Typography variant="h6" component="h6">Result Cell</Typography>
                        <Typography variant="body2" component="p">Latitude: {centreLat}</Typography>
                        <Typography variant="body2" component="p">Longitude: {centreLng}</Typography>
                        <Typography variant="body2" component="p">Value: {cellValue} {unitsLabel}</Typography>
                    </div>
                </Popup>
            </Rectangle>
        })
    }</>
}