/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';

import format from 'date-fns/format';

import { Box, Typography, Slider, IconButton, Tooltip, Select, FormControl, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat, DateTimeHourFormat } from '../../constants/Dates';
import useTimeSlider from '../../hooks/useTimeSlider';
import { AnalysisResultTimeScale } from '../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sliderButtonPlayer: {

        },
        sliderButtonBox: {
            marginTop: -theme.spacing(1)
        },
        sliderSpeedBox: {
            marginTop: -theme.spacing(1)
        },
        sliderLabelBox: {

        },
        sliderControlBox: {

        },
        slash: {
            paddingLeft: 4,
            paddingRight: 4
        }
    }),
);


interface ITimeSliderProps {
    endDate: Date;
    interval: 'day' | 'hour';
    startDate: Date;
    setIntervalCallBack: (offset: number) => void;  // change to context
}


const ValueLabelComponent: React.FunctionComponent<any> = ({ children, open, value, valueLabelFormat }) => {
    const popperRef = useRef(null);
    useEffect(() => {
        if (popperRef.current) {
            (popperRef.current as any).update();
        }
    });

    return (
        <Tooltip
            PopperProps={{
                popperRef,
            }}
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={valueLabelFormat ? valueLabelFormat(value) : value ? value : ''}
        >
            {children}
        </Tooltip>
    );
}

const TimeSlider: React.FunctionComponent<any> = (
    {
        className,
        endDate,
        interval,
        startDate,
        setSpeedCallBack,
        speed,
        currentInterval,
        currentTime,
        setOffsetValue,
        sliderMarks,
        togglePlayingFn,
        stopPlayingFn,
        skipToStartFn,
        skipToEndFn,
        sliderPlaying,
    }) => {
    const classes = useStyles();

    const handleSliderChange = useCallback((event: React.ChangeEvent<{}>, newValue: number | number[]) => {
        setOffsetValue(newValue as number);
    }, [setOffsetValue]);

    const getAriaValueText = useCallback((value: number) => `Time-${value}`, []);

    const getValueLabel = useCallback((value: number) => {
        if (!value)
            return '';

        const item = sliderMarks.find((mark: any) => mark.value === value);

        return item ? item.label : '';
    }, [sliderMarks]);

    const handleTimerStart = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (currentInterval === sliderMarks.length - 1) {
                setOffsetValue(0);
            }

            togglePlayingFn();
        }, [setOffsetValue, togglePlayingFn]
    );

    const handleTimerSkipStart = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            stopPlayingFn();
            skipToStartFn();
        }, [stopPlayingFn, skipToStartFn]
    );

    const handleTimerSkipEnd = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            stopPlayingFn();
            skipToEndFn();
        }, [stopPlayingFn, skipToEndFn]
    );

    const handleSpeedSelect = useCallback(
        (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            setSpeedCallBack(event.target.value as number);
        }, [setSpeedCallBack]
    );

    const hourly = interval === AnalysisResultTimeScale.Hour;

    const dateFormat = hourly ? DateTimeHourFormat : DateTimeFormat;

    return <div className={className}>
        <Slider
            defaultValue={0}
            getAriaValueText={getAriaValueText}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            step={1}
            marks
            min={0}
            max={sliderMarks.length - 1}
            value={currentInterval}
            ValueLabelComponent={ValueLabelComponent}
            valueLabelFormat={getValueLabel}
            onChange={handleSliderChange}
        />
        <Box className={classes.sliderControlBox} display="flex" justifyContent="space-between">
            <Box className={classes.sliderLabelBox} display="flex">
                <Typography>
                    {currentTime && format(currentTime, dateFormat)}
                </Typography>
                <Typography className={classes.slash}>{' / '}</Typography>
                <Typography>
                    {endDate && format(endDate, DateTimeFormat)}
                </Typography>
            </Box>
            <Box className={classes.sliderButtonBox}>
                <Tooltip title="Skip to start">
                    <IconButton className={classes.sliderButtonPlayer} aria-label="Skip to start of epidemic" onClick={handleTimerSkipStart}>
                        <SkipPreviousIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`${sliderPlaying ? 'Pause epidemic' : 'Play epidemic'}`}>
                    <IconButton className={classes.sliderButtonPlayer} aria-label="Play epidemic" onClick={handleTimerStart}>
                        {
                            sliderPlaying ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />
                        }
                    </IconButton>
                </Tooltip>
                <Tooltip title="Skip to end">
                    <IconButton className={classes.sliderButtonPlayer} aria-label="Skip to end of epidemic" onClick={handleTimerSkipEnd}>
                        <SkipNextIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box className={classes.sliderSpeedBox}>
                <FormControl>
                    <Select
                        onChange={handleSpeedSelect}
                        value={speed}
                        inputProps={{
                            name: 'speed',
                            id: 'epidemic-speed',
                        }}
                    >
                        <MenuItem value={1000}>{hourly ? '1 hour/sec' : '1 day/sec'}</MenuItem>
                        <MenuItem value={500}>{hourly ? '2 hours/sec' : '2 days/sec'}</MenuItem>
                        <MenuItem value={250}>{hourly ? '4 hours/sec' : '4 days/sec'}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    </div>
}


export default TimeSlider;