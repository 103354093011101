import { combineEpics } from 'redux-observable';
// Analysis Jobs
import analysissModule from './analysis/analysisSearch';
import analysisTapmReducer, { analysisTapmEpics } from './analysis/analysisTapm';
import analysisTapmDataSetModule from './analysis/analysisTapmDataSetSearch';
// Area Dates
import areaDateReducer, { areaDateEpics } from './areaDates/areaDate';
import areaDateSearchReducer, { areaDateSearchEpics } from './areaDates/areaDateSearch';
// Area Date Types
import areaDateTypeReducer, { areaDateTypeEpics } from './areaDateTypes/areaDateType';
import areaDateTypeSearchReducer, { areaDateTypeSearchEpics } from './areaDateTypes/areaDateTypeSearch';
// Area Livestock Counts
import areaLivestockCountReducer, { areaLivestockCountEpics } from './areaLivestockCounts/areaLivestockCount';
import areaLivestockCountSearchReducer, { areaLivestockCountSearchEpics } from './areaLivestockCounts/areaLivestockCountSearch';
// Areas
import areaSearchReducer, { areaSearchEpics } from './areas/areaSearch';
// Imports
import importDatesReducer, { importDatesEpics } from './bulkUpload/importDates';
import importEpidemicReducer, { importEpidemicEpics } from './bulkUpload/importEpidemic';
import importHierarchyReducer, { importHierarchyEpics } from './bulkUpload/importHierarchy';
import importLivestockReducer, { importLivestockEpics } from './bulkUpload/importLivestock';
import importLogReducer, { importLogEpics } from './bulkUpload/importLog';
import importLogSearchModule from './bulkUpload/importLogSearch';
import importPreParseReducer, { importPreParseEpics } from './bulkUpload/importPreParse';
import importSpatialReducer, { importSpatialEpics } from './bulkUpload/importSpatial';
// Classification
import classificationModule from './classifications/classification';
import classificationSearchReducer, { classificationSearchEpics } from './classifications/classificationSearch';
// Clusters
import clusterReducer, { clusterEpics } from './clusters/cluster';
import clusterSearchReducer, { clusterSearchEpics } from './clusters/clusterSearch';
// Countries
import countryModule from './countries/country';
import countrySearchReducer, { countrySearchEpics } from './countries/countrySearch';
// Disease Classifications
import diseaseClassificationModule from './diseaseClassifications/diseaseClassification';
import diseaseClassificationSearchReducer, { diseaseClassificationSearchEpics } from './diseaseClassifications/diseaseClassificationSearch';
// Diseases
import diseaseModule from './diseases/disease';
import diseaseSearchReducer, { diseaseSearchEpics } from './diseases/diseaseSearch';
// Disease Sub-classifications
import diseaseSubClassificationModule from './diseaseSubClassifications/diseaseSubClassification';
import diseaseSubClassificationSearchReducer, { diseaseSubClassificationSearchEpics } from './diseaseSubClassifications/diseaseSubClassificationSearch';
// EnrichmentMethod
import enrichmentMethodModule from './enrichmentMethods/enrichmentMethod';
import enrichmentMethodSearchReducer, { enrichmentMethodSearchEpics } from './enrichmentMethods/enrichmentMethodSearch';
// Epidemics
import epidemicModule from './epidemics/epidemic';
import epidemicPersonGroupAccessSearchModule from './epidemics/epidemicPersonGroupAccessSearch';
import epidemicSearchReducer, { epidemicSearchEpics } from './epidemics/epidemicSearch';
// Excretion
import excretionModule from './excretion/excretion';
import excretionSearchModule from './excretion/excretionSearch';
// Fields
import fieldReducer, { fieldEpics } from './fields/field';
import fieldSearchReducer, { fieldSearchEpics } from './fields/fieldSearch';
// Genomics
import genomicNetworkModule from './genomic/genomicNetwork';
import genomicNetworkSearchModule from './genomic/genomicNetworkSearch';
// Group Epidemics
import groupEpidemicReducer, { groupEpidemicEpics } from './groupEpidemics/groupEpidemic';
import groupEpidemicSearchReducer, { groupEpidemicSearchEpics } from './groupEpidemics/groupEpidemicSearch';
// Groups
import groupModule from './groups/group';
import groupInviteReducer, { groupInviteEpics } from './groups/groupInvite';
import groupMemberReducer, { groupMemberEpics } from './groups/groupMember';
import groupSearchReducer, { groupSearchEpics } from './groups/groupSearch';
// Infected Premises
import infectedPremiseReducer, { infectedPremiseEpics } from './infectedPremises/infectedPremise';
import infectedPremiseSearchReducer, { infectedPremiseSearchEpics } from './infectedPremises/infectedPremiseSearch';
// Instrument
import instrumentModule from './instruments/instrument';
import instrumentSearchReducer, { instrumentSearchEpics } from './instruments/instrumentSearch';
// LibraryStrategy
import libraryStrategyModule from './libraryStrategys/libraryStrategy';
import libraryStrategySearchReducer, { libraryStrategySearchEpics } from './libraryStrategys/libraryStrategySearch';
import livestockModule from './livestock/livestock';
import livestockSearchReducer, { livestockSearchEpics } from './livestock/livestockSearch';
// Livestock
import livestockDefaultModule from './livestockDefaults/livestockDefault';
import livestockDefaultSearchReducer, { livestockDefaultSearchEpics } from './livestockDefaults/livestockDefaultSearch';
import livestockLocationReducer, { livestockLocationEpics } from './livestockLocations/livestockLocation';
import livestockLocationSearchReducer, { livestockLocationSearchEpics } from './livestockLocations/livestockLocationSearch';
// Material
import materialModule from './materials/material';
import materialSearchReducer, { materialSearchEpics } from './materials/materialSearch';
// MSA
import msaModule from './msa/msa';
import msaSearchModule from './msa/msaSearch';
// Parcels
import parcelReducer, { parcelEpics } from './parcels/parcel';
import parcelSearchReducer, { parcelSearchEpics } from './parcels/parcelSearch';
// Pathogen
import pathogenModule from './pathogens/pathogen';
import pathogenSearchReducer, { pathogenSearchEpics } from './pathogens/pathogenSearch';
// Person
import authenticatedPersonReducer, { authenticatedPersonEpics } from './person/authenticatedPerson';
import passwordResetReducer, { passwordResetEpics } from './person/passwordReset';
import personReducer, { personEpics } from './person/person';
import personSearchReducer, { personSearchEpics } from './person/personSearch';
import registerPersonReducer, { registerPersonEpics } from './person/registerPerson';
// Platform
import platformModule from './platforms/platform';
import platformSearchReducer, { platformSearchEpics } from './platforms/platformSearch';
// Roles
import personRoleReducer, { personRoleEpics } from './roles/personRole';
import personRoleSearchReducer, { personRoleSearchEpics } from './roles/personRoleSearch';
import roleSearchReducer, { roleSearchEpics } from './roles/roleSearch';
// Sequences
import consensusSequenceModule from './sequences/consensusSequence';
import sequenceModule from './sequences/sequence';
import sequenceRetrievalModule from './sequences/sequenceRetrieval';
import sequenceSearchReducer, { sequenceSearchEpics } from './sequences/sequenceSearch';
import sequenceViewSearchReducer, { sequenceViewSearchEpics } from './sequences/sequenceViewSearch';
// Species
import speciesModule from './species/species';
import speciesSearchReducer, { speciesSearchEpics } from './species/speciesSearch';
// Specimens
import specimenModule from './specimens/specimen';
import specimenSearchReducer, { specimenSearchEpics } from './specimens/specimenSearch';
// Sub Classification
import subClassificationModule from './subClassifications/subClassification';
import subClassificationSearchReducer, { subClassificationSearchEpics } from './subClassifications/subClassificationSearch';
// Sub-clusters
import subClusterReducer, { subClusterEpics } from './subClusters/subCluster';
import subClusterSearchReducer, { subClusterSearchEpics } from './subClusters/subClusterSearch';
// Sub Nationals
import subNationalModule from './subNationals/subNational';
import subNationalSearchReducer, { subNationalSearchEpics } from './subNationals/subNationalSearch';
// System
import themeReducer from './theme/theme';
// Tissue
import tissueModule from './tissues/tissue';
import tissueSearchReducer, { tissueSearchEpics } from './tissues/tissueSearch';
// Tooltips
import tooltipsReducer, { tooltipsEpics } from './tooltips/tooltips';


export const combinedEpics = combineEpics(
    analysisTapmEpics,
    analysissModule.epics,
    analysisTapmDataSetModule.epics,
    authenticatedPersonEpics,
    areaSearchEpics,
    areaDateEpics,
    areaDateSearchEpics,
    areaDateTypeEpics,
    areaDateTypeSearchEpics,
    areaLivestockCountEpics,
    areaLivestockCountSearchEpics,
    clusterEpics,
    clusterSearchEpics,
    consensusSequenceModule.epics,
    diseaseModule.epics,
    diseaseSearchEpics,
    diseaseClassificationModule.epics,
    diseaseClassificationSearchEpics,
    diseaseSubClassificationModule.epics,
    diseaseSubClassificationSearchEpics,
    epidemicModule.epics,
    epidemicSearchEpics,
    epidemicPersonGroupAccessSearchModule.epics,
    excretionModule.epics,
    excretionSearchModule.epics,
    fieldEpics,
    fieldSearchEpics,
    genomicNetworkModule.epics,
    genomicNetworkSearchModule.epics,
    groupInviteEpics,
    groupModule.epics,
    groupMemberEpics,
    groupSearchEpics,
    groupEpidemicEpics,
    groupEpidemicSearchEpics,
    importDatesEpics,
    importEpidemicEpics,
    importHierarchyEpics,
    importLivestockEpics,
    importLogEpics,
    importLogSearchModule.epics,
    importPreParseEpics,
    importSpatialEpics,
    infectedPremiseEpics,
    infectedPremiseSearchEpics,
    livestockDefaultModule.epics,
    livestockDefaultSearchEpics,
    livestockModule.epics,
    livestockSearchEpics,
    livestockLocationEpics,
    livestockLocationSearchEpics,
    msaModule.epics,
    msaSearchModule.epics,
    parcelEpics,
    parcelSearchEpics,
    passwordResetEpics,
    personRoleEpics,
    personRoleSearchEpics,
    personEpics,
    personSearchEpics,
    registerPersonEpics,
    roleSearchEpics,
    sequenceModule.epics,
    sequenceRetrievalModule.epics,
    sequenceSearchEpics,
    sequenceViewSearchEpics,
    speciesModule.epics,
    speciesSearchEpics,
    specimenModule.epics,
    specimenSearchEpics,
    subClusterEpics,
    subClusterSearchEpics,
    countryModule.epics,
    countrySearchEpics,
    subNationalModule.epics,
    subNationalSearchEpics,
    pathogenModule.epics,
    pathogenSearchEpics,
    classificationModule.epics,
    classificationSearchEpics,
    subClassificationModule.epics,
    subClassificationSearchEpics,
    tissueModule.epics,
    tissueSearchEpics,
    materialModule.epics,
    materialSearchEpics,
    enrichmentMethodModule.epics,
    enrichmentMethodSearchEpics,
    libraryStrategyModule.epics,
    libraryStrategySearchEpics,
    instrumentModule.epics,
    instrumentSearchEpics,
    platformModule.epics,
    platformSearchEpics,
    tooltipsEpics,
);

export const combinedReducers = {
    analysisTapm: analysisTapmReducer,
    analysisSearch: analysissModule.reducer,
    analysisTapmDataSetSearch: analysisTapmDataSetModule.reducer,
    areaSearch: areaSearchReducer,
    areaDate: areaDateReducer,
    areaDateSearch: areaDateSearchReducer,
    areaDateType: areaDateTypeReducer,
    areaDateTypeSearch: areaDateTypeSearchReducer,
    areaLivestockCount: areaLivestockCountReducer,
    areaLivestockCountSearch: areaLivestockCountSearchReducer,
    authenticatedPerson: authenticatedPersonReducer,
    cluster: clusterReducer,
    clusterSearch: clusterSearchReducer,
    consensusSequence: consensusSequenceModule.reducer,
    disease: diseaseModule.reducer,
    diseaseSearch: diseaseSearchReducer,
    diseaseClassification: diseaseClassificationModule.reducer,
    diseaseClassificationSearch: diseaseClassificationSearchReducer,
    diseaseSubClassification: diseaseSubClassificationModule.reducer,
    diseaseSubClassificationSearch: diseaseSubClassificationSearchReducer,
    epidemic: epidemicModule.reducer,
    epidemicSearch: epidemicSearchReducer,
    epidemicPersonGroupAccessSearch: epidemicPersonGroupAccessSearchModule.reducer,
    excretion: excretionModule.reducer,
    excretionSearch: excretionSearchModule.reducer,
    field: fieldReducer,
    fieldSearch: fieldSearchReducer,
    genomicNetwork: genomicNetworkModule.reducer,
    genomicNetworkJobSearch: genomicNetworkSearchModule.reducer,
    group: groupModule.reducer,
    groupInvite: groupInviteReducer,
    groupMember: groupMemberReducer,
    groupSearch: groupSearchReducer,
    groupEpidemic: groupEpidemicReducer,
    groupEpidemicSearch: groupEpidemicSearchReducer,
    importDates: importDatesReducer,
    importEpidemic: importEpidemicReducer,
    importHierarchy: importHierarchyReducer,
    importLivestock: importLivestockReducer,
    importLog: importLogReducer,
    importLogSearch: importLogSearchModule.reducer,
    importPreParse: importPreParseReducer,
    importSpatial: importSpatialReducer,
    infectedPremise: infectedPremiseReducer,
    infectedPremiseSearch: infectedPremiseSearchReducer,
    livestock: livestockModule.reducer,
    livestockSearch: livestockSearchReducer,
    livestockDefault: livestockDefaultModule.reducer,
    livestockDefaultSearch: livestockDefaultSearchReducer,
    livestockLocation: livestockLocationReducer,
    livestockLocationSearch: livestockLocationSearchReducer,
    msa: msaModule.reducer,
    msaSearch: msaSearchModule.reducer,
    parcel: parcelReducer,
    parcelSearch: parcelSearchReducer,
    passwordReset: passwordResetReducer,
    person: personReducer,
    personSearch: personSearchReducer,
    registerPerson: registerPersonReducer,
    sequence: sequenceModule.reducer,
    sequenceRetrieval: sequenceRetrievalModule.reducer,
    sequenceSearch: sequenceSearchReducer,
    sequenceViewSearch: sequenceViewSearchReducer,
    species: speciesModule.reducer,
    speciesSearch: speciesSearchReducer,
    specimen: specimenModule.reducer,
    specimenSearch: specimenSearchReducer,
    subCluster: subClusterReducer,
    subClusterSearch: subClusterSearchReducer,
    personRole: personRoleReducer,
    personRoleSearch: personRoleSearchReducer,
    roleSearch: roleSearchReducer,
    country: countryModule.reducer,
    countrySearch: countrySearchReducer,
    subNational: subNationalModule.reducer,
    subNationalSearch: subNationalSearchReducer,
    pathogen: pathogenModule.reducer,
    pathogenSearch: pathogenSearchReducer,
    classification: classificationModule.reducer,
    classificationSearch: classificationSearchReducer,
    subClassification: subClassificationModule.reducer,
    subClassificationSearch: subClassificationSearchReducer,
    tissue: tissueModule.reducer,
    tissueSearch: tissueSearchReducer,
    material: materialModule.reducer,
    materialSearch: materialSearchReducer,
    enrichmentMethod: enrichmentMethodModule.reducer,
    enrichmentMethodSearch: enrichmentMethodSearchReducer,
    libraryStrategy: libraryStrategyModule.reducer,
    libraryStrategySearch: libraryStrategySearchReducer,
    instrument: instrumentModule.reducer,
    instrumentSearch: instrumentSearchReducer,
    platform: platformModule.reducer,
    platformSearch: platformSearchReducer,
    tooltips: tooltipsReducer,
    theme: themeReducer,
};