
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import HelpIcon from '@material-ui/icons/Help';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useTooltip } from '../../hooks/useTooltip';
import IconButtonExternalLink from '../common/IconExternalLink';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface ITooltipProps {
    name: string;
}

export const HoverTooltip: React.FunctionComponent<ITooltipProps> = (props) => {
    const [tooltip] = useTooltip(props.name);

    return tooltip ? <IconButtonExternalLink to={tooltip.url} title={tooltip.urlTitle}>
        <HelpIcon />
    </IconButtonExternalLink>
        : null;
}