/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history';

import Container from '@material-ui/core/Container';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backing: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 0
        },
        container: {
            position: 'relative'
        },
        wrapper: {
            position: 'relative'
        }
    }),
);

interface IContentSectionProps {
    backingClassName?: string;
    className?: string;
    wrapperClassName?: string;
    overrideMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; 
}

export const ContentSection: React.FunctionComponent<IContentSectionProps> = (props) => {
    const classes = useStyles(props);

    return <div className={classNames(props.wrapperClassName, classes.wrapper)}>
        {
            // Render background separately so we don't have to worry about things
            // like children inheriting opacity of backgrounds
        }
        <div className={classNames(props.backingClassName, classes.backing)}></div>
        <Container 
            maxWidth={props.overrideMaxWidth && props.overrideMaxWidth}
            fixed={props.overrideMaxWidth === undefined}
            className={classNames(props.className, classes.container)}
        >
            {props.children}
        </Container>
    </div>
}
