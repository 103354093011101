/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an infected premise
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActions, createCrudActionTypes, createCrudEpics, createCrudSelectors, IBaseCrudState, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialInfectedPremiseState extends IBaseCrudState {
    data?: SpreadDtos.InfectedPremise;
}

export interface IInfectedPremiseState {
    infectedPremise: IInitialInfectedPremiseState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IInfectedPremiseActionTypes {
    CLEAR: string;
}

const infectedPremiseTypes: IBaseCrudTypes & IInfectedPremiseActionTypes = {
    ...createCrudActionTypes('infectedPremise'),
    CLEAR: '@@infectedPremise/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialInfectedPremiseState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const infectedPremiseReducer = createReducer<IInitialInfectedPremiseState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const infectedPremiseActions = {
    ...createCrudActions<SpreadDtos.InfectedPremise, SpreadDtos.CreateInfectedPremiseResponse, SpreadDtos.GetInfectedPremiseResponse, SpreadDtos.UpdateInfectedPremiseResponse, undefined, undefined>(infectedPremiseTypes),
    clear: createAction(infectedPremiseTypes.CLEAR,
        () => ({})
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(infectedPremiseReducer, infectedPremiseActions, 'infectedPremise', initialState);

// Clear Reducer
infectedPremiseReducer.on(infectedPremiseActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
infectedPremiseReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const infectedPremisesApi = {
    create: createPostRequest(
        SpreadDtos.CreateInfectedPremise,
        (infectedPremise: SpreadDtos.InfectedPremise) => ({
            infectedPremise: infectedPremise
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetInfectedPremise,
        (infectedPremiseId: number) => ({
            id: infectedPremiseId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateInfectedPremise,
        (infectedPremise: SpreadDtos.InfectedPremise) => ({
            infectedPremise: infectedPremise
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(infectedPremiseActions, infectedPremisesApi);

export const infectedPremiseEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const infectedPremiseSelectors = createCrudSelectors('infectedPremise');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default infectedPremiseReducer;