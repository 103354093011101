/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box, Button, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { History } from 'history';
import * as React from 'react';
import { useCallback } from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { HasPermission } from '../common/HasPermission';
import { LivestockList } from './LivestockList';
import ButtonLink from '../common/ButtonLink';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewLivestocksParams { }

interface IViewLivestocksProps {
    className?: string;
    epidemicId?: number;
    editMode?: boolean;
    history: History;
    match: match<IViewLivestocksParams>;
}

export const ViewLivestocks: React.FunctionComponent<IViewLivestocksProps> = (props) => {
    let url = props.match.url;
    url = (url.endsWith('/edit') ? url.substr(0, url.lastIndexOf('/edit')) : url) + (!url.endsWith('/livestock') ? '/livestock' : '');

    const classes = useStyles({});

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Livestock</Typography>
        {
            props.epidemicId && <LivestockList className={classes.list} epidemicId={props.epidemicId} history={props.history} url={url} />
        }
        {
            props.editMode && <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                <HasPermission permission="CreateLivestock">
                    <ButtonLink
                        aria-label="Create Livestock"
                        color="primary"
                        variant="contained"
                        to={`${url}/create`}
                    >
                        <AddBoxIcon className={classes.buttonSubmitIcon} />
                        Create
                </ButtonLink>
                </HasPermission>
            </Box>
        }
    </Paper>
}