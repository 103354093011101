/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import LeakAddIcon from '@material-ui/icons/LeakAdd';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import partner_aha from '../../assets/images/home/partner_aha.jpg';
import partner_bom from '../../assets/images/home/partner_bom.png';
import partner_dawr from '../../assets/images/home/partner_dawr.png';
import partner_mla from '../../assets/images/home/partner_mla.png';
import partner_csiro from '../../assets/images/home/partner_csiro.jpg';
import { ContentFeatures } from '../../components/content/ContentFeatures';
import { ContentLayout } from '../../components/content/ContentLayout';
import { AuthState } from '../../types/AuthState';
import { ContentSection } from '../../components/content/ContentSection';
import { ContentHero } from '../../components/content/ContentHero';
import LoginForm from '../../components/person/LoginForm';
import ButtonLink from '../../components/common/ButtonLink';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IHomePageProps {
    classes?: any;
    match: match;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blurbBacking: {
            backgroundColor: '#64C2CA',
            background: 'linear-gradient(0, #64C2CA, #0963A1)'
        },
        blurbContainer: {
            paddingTop: '2rem',
            paddingBottom: '3rem',
        },
        blurbText: {
            color: 'white',
            fontSize: '1.25rem',
            lineHeight: 1.65,
            paddingTop: theme.spacing(2)
        },
        loginBacking: {
            background: '#0963A1'
        },
        loginBox: {
            padding: '1rem',
            background: 'white',
            color: 'black',
            border: '2px white solid',
            borderRadius: '6px',
        },
        loginContainer: {
            paddingTop: '3rem',
            paddingBottom: '1rem',
            width: '35%',
            [theme.breakpoints.down('lg')]: {
                width: '45%',
            },
            [theme.breakpoints.down('md')]: {
                width: '50%',
            },
            [theme.breakpoints.down('sm')]: {
                width: '80%',
            },
        },
        loginTitle: {
            paddingBottom: theme.spacing(1)
        },
        loginWelcomeBox: {
            overflowX: 'hidden'
        },
        loginWelcomeTitle: {
            padding: '0 0 1rem 0'
        },
        projectDetailsBacking: {
            background: 'white'
        },
        projectDetailsContainer: {
            paddingTop: '3rem',
            paddingBottom: '3rem',
        },
        projectDetailsTitle: {
        },
        projectDetailsText: {
            color: 'black',
            fontSize: '1.25rem',
            lineHeight: 1.65,
            paddingTop: theme.spacing(2),
        },
        partnersBacking: {
            background: 'white'
        },
        partnersContainer: {
            paddingBottom: '3rem',
            display: 'flex',
            flexDirection: 'row'
        },
        partnerImage: {
            height: '60px',
            [theme.breakpoints.down('md')]: {
                height: '45px',
            },
        },
        partnerRow: {
            width: '100%',
            paddingBottom: theme.spacing(3)
        },
        partnerRowWrapper: {
            width: '100%'
        }
    }),
);

export const HomePage: React.FunctionComponent<IHomePageProps> = ({
    match
}) => {
    const classes = useStyles();

    const userAuthenticatedState = useSelector(state => state.authenticatedPerson);

    return (<ContentLayout>
        <ContentHero
            textLineOne={'System for Preparedness and Response to Emergency Animal Disease'}
        />

        <ContentSection
            backingClassName={classes.loginBacking}
            className={classes.loginContainer}
        >
            <Box className={classes.loginBox} boxShadow={4}>
                {
                    userAuthenticatedState.authState === AuthState.Pending
                        ? <CircularProgress />
                        : userAuthenticatedState.authState !== AuthState.Authenticated
                            ? <>
                                <Typography className={classes.loginTitle} variant={'h5'}>
                                    Login
                                </Typography>
                                <LoginForm
                                    match={match}
                                />
                            </>
                            : <Box className={classes.loginWelcomeBox} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                                <Typography className={classes.loginWelcomeTitle} variant={'h4'}>
                                    Welcome
                                </Typography>
                                <Typography className={classes.loginWelcomeTitle} variant={'h5'}>
                                    {userAuthenticatedState.session && userAuthenticatedState.session.displayName}
                                </Typography>
                                <ButtonLink
                                    color="secondary"
                                    variant="contained"
                                    to={'/task'}>
                                    Go to application
                                </ButtonLink>
                            </Box>
                }
            </Box>
        </ContentSection>

        <ContentFeatures
            features={[
                {
                    description: '',
                    title: 'Visualise Epidemics',
                    icon: BubbleChartIcon
                },
                {
                    description: '',
                    title: 'Wind Dispersion Simulations',
                    icon: LeakAddIcon
                },
                {
                    description: '',
                    title: 'Bioinformatics Workflows',
                    icon: ShowChartIcon
                },
                {
                    description: '',
                    title: 'Network Analysis',
                    icon: LinearScaleIcon
                }
            ]}
        />

        <ContentSection
            backingClassName={classes.blurbBacking}
            className={classes.blurbContainer}
        >
            <Typography className={classes.blurbText} variant={'body1'}>
                SPREAD is an application designed to assist in the management of highly infectious animal diseases,
                such as foot-and-mouth disease (FMD). It specifically facilitates the identification of how the causative
                pathogens might be spreading from farm-to-farm ("F2F").
            </Typography>
            <Typography className={classes.blurbText} variant={'body1'}>
                Version 1 of the application focuses on determining if F2F spread of the virus might have occurred via
                wind-borne dispersion, and enables the use of whole genome sequence (WGS) data to confirm this path of
                transmission.
            </Typography>
        </ContentSection>

        {/*
    <ContentSection
      backingClassName={classes.loginBacking}
      className={classes.loginContainer}
    >

      <Box className={classes.loginBox} boxShadow={1}>
        {
          userAuthenticatedState.authState === AuthState.Pending
            ? <CircularProgress />
            : userAuthenticatedState.authState !== AuthState.Authenticated
              ? <>
                <Typography className={classes.loginTitle} variant={'h4'}>
                  Login
            </Typography>
                <LoginForm
                  match={match}
                />
              </>
              : <>
                <Typography className={classes.loginTitle} variant={'h4'}>
                  Welcome {userAuthenticatedState.session && userAuthenticatedState.session.displayName}
                </Typography>
                <ButtonLink
                  color="primary"
                  variant="contained"
                  to={'/task'}>
                  Go to application
              </ButtonLink>
              </>
        }
      </Box>
    </ContentSection>
*/}

        <ContentSection
            backingClassName={classes.projectDetailsBacking}
            className={classes.projectDetailsContainer}
        >
            <Typography className={classes.projectDetailsTitle} variant={'h4'}>
                Project Details
            </Typography>
            <Typography className={classes.projectDetailsText} variant={'body1'}>
                {'The SPREAD application is part of a wider project "Improved surveillance, '}
                {'preparedness and return to trade for emergency animal disease incursions using '}
                {'foot-and-mouth disease as model".'}
            </Typography>
            <Typography className={classes.projectDetailsText} variant={'body1'}>
                {'The project is supported by Meat and Livestock Australia, through funding from the '}
                {'Australian Government Department of Agriculture and Water Resources as part of its '}
                {'Rural R&D for Profit programme, and by producer levies from Australian FMD-susceptible '}
                {'livestock (cattle, sheep, goats and pigs) industries and Charles Sturt University (CSU), '}
                {'leveraging significant in-kind support from the research partners.'}
            </Typography>
            <Typography className={classes.projectDetailsText} variant={'body1'}>
                {' The research partners for this project are CSIRO, CSU through the Graham Centre for  '}
                {'Agricultural Innovation, the Bureau of Meteorology and the Australian Department of '}
                {'Agriculture and Water Resources, supported by Animal Health Australia (AHA).'}
            </Typography>
            <Typography className={classes.projectDetailsText} variant={'body1'}>
                {' The multidisciplinary team that is building SPREAD consists of experts in veterinary '}
                {'epidemiology, climatology, spatial analyses, virology, bioinformatics and software engineering. '}
                {'These skills are being drawn from CSIRO-Australian Animal Health Laboratory, CSIRO Oceans and '}
                {'Atmosphere, CSIRO Health & Biosecurity, CSIRO Information Management & Technology, the Australian '}
                {'Bureau of Meteorology and NewtonGreen.'}
            </Typography>
        </ContentSection>
        <ContentSection
            backingClassName={classes.partnersBacking}
            className={classes.partnersContainer}
        >
            <Box className={classes.partnerRowWrapper} display='flex' flexDirection="column">
                <Box className={classes.partnerRow} display='flex' justifyContent='space-around'>
                    <img className={classes.partnerImage} src={partner_dawr} alt="DAWR Logo" />
                    <img className={classes.partnerImage} src={partner_mla} alt="MLA Logo" />
                </Box>
                <Box className={classes.partnerRow} display='flex' justifyContent='space-around'>
                    <img className={classes.partnerImage} src={partner_csiro} alt="CSIRO Logo" />
                    <img className={classes.partnerImage} src={partner_bom} alt="BOM Logo" />
                </Box>
                <Box className={classes.partnerRow} display='flex' justifyContent='space-around'>
                    <img className={classes.partnerImage} src={partner_aha} alt="AHA Logo" />
                </Box>
            </Box>
        </ContentSection>
    </ContentLayout>
    );
}



