/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, match, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import mockupSequenceDifferences from '../../../assets/images/mockup_differences.png';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import consensusSequenceModule from '../../../store/reducers/sequences/consensusSequence';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import RadioWrapper from '../../forms/FinalFormControls/RadioWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../../forms/mutators';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        assemblyButtons: {
            margin: theme.spacing(1)
        },
        imageWrapper: {
            textAlign: 'center'
        },
        image: {
            width: '50%',
        },
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IMockSequenceAnnotationFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

export interface ISequenceAnnotationQAFormSchema {
    consensusSequenceId: number;
    passed?: boolean;
    comment?: string;
}

const validation = Yup.object<ISequenceAnnotationQAFormSchema>({
    consensusSequenceId: Yup.number().label('Consensus Sequence')
        .required(),
    passed: Yup.boolean().label('Selection')
        .notRequired(),
    comment: Yup.string().label('Comment')
        .notRequired(),
});

export const SequenceAnnotationQAForm: React.FunctionComponent<IMockSequenceAnnotationFormProps> = ({ className, history, match }) => {
    const classes = useStyles();
    const consensusSequence = useSelector((s) => consensusSequenceModule.selectors.data(s));
    const updateState = useSelector((s) => consensusSequenceModule.selectors.annotationQAState(s));

    const [annotationData, annotationDataState] = consensusSequenceModule.hooks.useLoadAnnotationData(consensusSequence ? consensusSequence.id : -1);

    const submitSuccess = updateState.state == RequestFormState.SubmitSuccess;

    const startAction = consensusSequenceModule.actions.annotationQA;
    const resolveAction = consensusSequenceModule.actions.annotationQAFormResponse;
    const rejectAction = consensusSequenceModule.actions.annotationQAFormResponse;

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        successRedirectComponent = <Redirect push to={`${routeBackToPath(match.url, 8, "bioinformatics?link=1")}`} />;
    }

    return <Paper className={classes.root}>
        {successRedirectComponent}
        {consensusSequence ?
            <>
                <Typography variant={'h5'}>Annotation QA of Consensus Sequence{consensusSequence.sequence ? ` - ${consensusSequence.sequence.spreadSequenceId}` : ""}</Typography>
                <CommonForm
                    start={startAction}
                    resolve={resolveAction}
                    reject={rejectAction}
                    history={history}
                    initialValues={{
                        consensusSequenceId: consensusSequence.id,
                        passed: consensusSequence.annotationQA,
                        comment: consensusSequence.annotationComment,
                    }}
                    labelSave="Submit"
                    mutators={{ clear: clear }}
                    submitSuccess={submitSuccess}
                    validate={validation}
                >
                    {({ }) => <>
                        <Grid item xs={12} />
                        <Grid item lg={2} xs={12}>
                            <Field
                                name="passed"
                                component={RadioWrapper}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                parse={(value) => !value ? undefined : value === "true"}
                                items={[
                                    {
                                        value: true,
                                        label: 'Pass'
                                    },
                                    {
                                        value: false,
                                        label: 'Fail'
                                    }
                                ]}
                                label="Quality Assessment"
                            />
                        </Grid>
                        <Grid item lg={10} xs={12}>
                            <Field
                                multiline
                                name="comment"
                                component={TextFieldWrapper}
                                formControlProps={{
                                    multiline: true,
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label='Comment'
                            />
                        </Grid>
                    </>
                    }
                </CommonForm>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h5'}>Report</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Link component={RouterLink} to={routeBackToPath(match.url, 1, 'gbk')}>GBK File</Link>
                </Grid>
                {
                    annotationDataState.state == RequestFormState.Pending ?
                        <Skeleton /> :
                        annotationData ?
                            <Grid item lg={6} xs={12}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Attribute</TableCell>
                                            <TableCell align="right">Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            Object.keys(annotationData).map(key => {
                                                const row = annotationData[key];
                                                return <TableRow key={key}>
                                                    <TableCell component="th" scope="row">
                                                        {key}
                                                    </TableCell>
                                                    <TableCell align="right">{row}</TableCell>
                                                </TableRow>;
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Grid> :
                            null
                }
                <Grid item lg={10} xs={12}>
                    <TextField
                        disabled
                        multiline
                        className={classes.formControl}
                        label='Assembly Comments'
                        value={consensusSequence.assemblyComment}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Differences Between Sequence and Reference Sequence</Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <div className={classes.imageWrapper}>
                        <img
                            className={classes.image}
                            src={mockupSequenceDifferences}
                            alt="Sequence Reference Differences"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} />
            </> :
            <Skeleton />
        }
    </Paper>
}