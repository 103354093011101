/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for creating an excretion job
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import download from 'downloadjs';
import update from 'immutability-helper';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { ApiJobExcretion, CancelJobExcretion, CancelJobExcretionResponse, CreateJobExcretion, CreateJobExcretionResponse, DeleteJobExcretion, DeleteJobExcretionResponse, GetExcretionJobResultFile, Model } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { ActionType, baseCrudInitialState, createCrudModule, IBaseCrudActions, IBaseCrudApi, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IExcretionSubState extends IBaseCrudState<ApiJobExcretion> {
    data?: ApiJobExcretion;
    file?: Blob,
    extraStates: {
        cancelState: IFormRequestState;
        loadFileState: IFormRequestState;
    },
}


export interface IExcretionState {
    excretion: IExcretionSubState;
}


interface IExcretionActions extends IBaseCrudActions<ApiJobExcretion> {
    createJob: ActionType<(epidemicID: number, name: string, model: string, duration: number, i2lInterval: number, i2rInterval: number, r2cInterval: number, ignoreCulling: boolean) =>
        { epidemicID: number, name: string, model: string, duration: number, i2lInterval: number, i2rInterval: number, r2cInterval: number, ignoreCulling: boolean }>;

    createJobFormResponse: ActionType<(response: CreateJobExcretionResponse, state: RequestFormState, payload: any) =>
        { response: CreateJobExcretionResponse, state: RequestFormState, payload: any }>;

    deleteJob: ActionType<(apiJobId: number) => { apiJobId: number }>;

    deleteJobFormResponse: ActionType<(response: DeleteJobExcretionResponse, state: RequestFormState) =>
        { response: DeleteJobExcretionResponse, state: RequestFormState }>;

    cancelJob: ActionType<(apiJobId: number) => { apiJobId: number }>;

    cancelJobFormResponse: ActionType<(response: CancelJobExcretionResponse, state: RequestFormState) =>
        { response: CancelJobExcretionResponse, state: RequestFormState }>;

    loadResultFile: ActionType<(apiJobId: number) => ({ apiJobId: number })>;

    loadResultFileFormResponse: ActionType<(response: Blob, state: RequestFormState) => ({ response: Blob, state: RequestFormState })>;
}


interface IExcretionApi extends IBaseCrudApi {
    createJob: ReturnType<typeof createPostRequest>;
    deleteJob: ReturnType<typeof createPostRequest>;
    cancelJob: ReturnType<typeof createPostRequest>;
    getResultFile: ReturnType<typeof createGetRequest>;
}


interface IExcretionSelectors extends IBaseCrudSelectors<ApiJobExcretion, IExcretionSubState> {
}


/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const initialExcretionState: IExcretionSubState = {
    ...baseCrudInitialState<ApiJobExcretion>(),
    data: undefined,
    file: undefined,
    extraStates: {
        cancelState: {
            state: RequestFormState.None
        },
        loadFileState: {
            state: RequestFormState.None
        },
    },
};

const excretionModule = createCrudModule<ApiJobExcretion, undefined, undefined, undefined, undefined, undefined, IExcretionSubState, IExcretionActions, IExcretionApi, IExcretionSelectors>(
    'excretion',
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    initialExcretionState,
);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

excretionModule.actions.createJob = createAction(`@@excretion/CREATE_JOB`,
    (epidemicID: number, name: string, model: string, duration: number, i2lInterval: number, i2rInterval: number, r2cInterval: number, ignoreCulling: boolean) =>
        ({ epidemicID, name, model, duration, i2lInterval, i2rInterval, r2cInterval, ignoreCulling })
);

excretionModule.actions.createJobFormResponse = createAction(`@@excretion/CREATE_JOB_FORM_RESPONSE`,
    (response: CreateJobExcretionResponse, state: RequestFormState, payload: any) => ({ response, state, payload })
);

excretionModule.actions.deleteJob = createAction(`@@excretion/DELETE_JOB`,
    (apiJobId: number) => ({ apiJobId })
);

excretionModule.actions.deleteJobFormResponse = createAction(`@@excretion/DELETE_JOB_FORM_RESPONSE`,
    (response: DeleteJobExcretionResponse, state: RequestFormState) => ({ response, state })
);

excretionModule.actions.cancelJob = createAction(`@@excretion/CANCEL`,
    (apiJobId: number) => ({ apiJobId })
);

excretionModule.actions.cancelJobFormResponse = createAction(`@@excretion/CANCEL_FORM_RESPONSE`,
    (response: CancelJobExcretionResponse, state: RequestFormState) => ({ response, state })
);

excretionModule.actions.loadResultFile = createAction(`@@excretion/LOAD_RESULT_FILE`,
    (apiJobId: number) => ({ apiJobId })
);

excretionModule.actions.loadResultFileFormResponse = createAction(`@@excretion/LOAD_RESULT_FILE_FORM_RESPONSE`,
    (response: Blob, state: RequestFormState) => ({ response, state })
);


/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

excretionModule.reducer.on(excretionModule.actions.createJob, (state: IExcretionSubState) => update(
    state,
    {
        states: {
            createState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
));

excretionModule.reducer.on(excretionModule.actions.createJobFormResponse, (state: IExcretionSubState, payload: any) => update(
    state,
    {
        data: {
            $set: payload.response.excretionJob
        },
        states: {
            createState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
));

// Delete Reducers
excretionModule.reducer.on(excretionModule.actions.deleteJob, (state) => (
    update(
        state,
        {
            states: {
                deleteState: {
                    $set: {
                        state: RequestFormState.Pending
                    }
                }
            }
        }
    )
));

excretionModule.reducer.on(excretionModule.actions.deleteJobFormResponse, (state, payload) => (
    update(
        state,
        {
            states: {
                deleteState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
));

// Cancel Reducers
excretionModule.reducer.on(excretionModule.actions.cancelJob, (state) => (
    update(
        state,
        {
            extraStates: {
                cancelState: {
                    $set: {
                        state: RequestFormState.Pending
                    }
                }
            }
        }
    )
));

excretionModule.reducer.on(excretionModule.actions.cancelJobFormResponse, (state, payload) => (
    update(
        state,
        {
            extraStates: {
                cancelState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    )
));

//Load result file reducers
excretionModule.reducer.on(excretionModule.actions.loadResultFile, (state, payload) => update(
    state,
    {
        extraStates: {
            loadFileState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
));

excretionModule.reducer.on(excretionModule.actions.loadResultFileFormResponse, (state, payload: any) => {
    download(payload.response, "Excretion result.tar.gz");
    return update(
        state,
        {
            file: {
                $set: payload.response
            },
            extraStates: {
                loadFileState: {
                    $set: {
                        state: payload.state,
                        responseStatus: payload.response.responseStatus
                    }
                }
            }
        }
    );
});


/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

excretionModule.api.createJob = createPostRequest(
    CreateJobExcretion,
    (epidemicID: number, name: string, model: Model, duration: number, i2lInterval: number, i2rInterval: number, r2cInterval: number, ignoreCulling: boolean) =>
        ({ epidemicID, name, model, duration, i2lInterval, i2rInterval, r2cInterval, ignoreCulling })
);

excretionModule.api.deleteJob = createDeleteRequest(
    DeleteJobExcretion,
    (apiJobId: number) => ({ apiJobId })
);

excretionModule.api.cancelJob = createPutRequest(
    CancelJobExcretion,
    (apiJobId: number) => ({ apiJobId })
);

excretionModule.api.getResultFile = createGetRequest(
    GetExcretionJobResultFile,
    (apiJobId: number) => ({ apiJobId: apiJobId })
);


/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const createJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(excretionModule.actions.createJob.getType()),
        mergeMap(action => {
            console.log(action);
            return excretionModule.api.createJob(action.payload.epidemicID,
                action.payload.name,
                action.payload.model,
                action.payload.duration,
                action.payload.i2lInterval,
                action.payload.i2rInterval,
                action.payload.r2cInterval,
                action.payload.ignoreCulling).
                pipe(
                    mergeMap(response => of(excretionModule.actions.createJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(excretionModule.actions.createJobFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

export const deleteJobEpic: Epic<ReturnType<typeof excretionModule.actions.deleteJob>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(excretionModule.actions.deleteJob.getType()),
    mergeMap(action => {
        return excretionModule.api.deleteJob(action.payload.apiJobId)
            .pipe(
                mergeMap(response => of(excretionModule.actions.deleteJobFormResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(excretionModule.actions.deleteJobFormResponse(e, RequestFormState.ServerError)))
            )
    })
);

export const cancelJobEpic: Epic<ReturnType<typeof excretionModule.actions.cancelJob>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(excretionModule.actions.cancelJob.getType()),
    mergeMap(action =>
        excretionModule.api.cancelJob(action.payload.apiJobId)
            .pipe(
                mergeMap(response => of(excretionModule.actions.cancelJobFormResponse(response, RequestFormState.SubmitSuccess))),
                catchError(e => of(excretionModule.actions.cancelJobFormResponse(e, RequestFormState.ServerError)))
            )
    )
);

export const getResultFileEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$) =>
    action$.pipe(
        ofType(excretionModule.actions.loadResultFile.getType()),
        mergeMap(action =>
            excretionModule.api.getResultFile(action.payload.apiJobId)
                .pipe(
                    mergeMap(response => of(excretionModule.actions.loadResultFileFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(excretionModule.actions.loadResultFileFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

/* Add additional epics to module */
excretionModule.epics = combineEpics(excretionModule.epics,
    createJobEpic,
    deleteJobEpic,
    cancelJobEpic,
    getResultFileEpic);


/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default excretionModule;
