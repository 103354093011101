/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an pathogen
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreatePathogen,
    CreatePathogenResponse,
    Pathogen,
    DeletePathogen,
    DeletePathogenResponse,
    GetPathogen,
    GetPathogenResponse,
    UpdatePathogen,
    UpdatePathogenResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPathogenState {
    pathogen: IBaseCrudState<Pathogen>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const pathogenModule = createCrudModule<Pathogen, CreatePathogenResponse, GetPathogenResponse, UpdatePathogenResponse, DeletePathogenResponse, undefined>(
    'pathogen',
    CreatePathogen,
    GetPathogen,
    UpdatePathogen,
    DeletePathogen,
    undefined,
)


export default pathogenModule;