/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import countryModule from '../../store/reducers/countries/country';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ICountryEditFormProps {
    editCountry?: SpreadDtos.Country;
    history: History;
    loadingCountry?: boolean;
    url: string;
}

interface ICountryFormSchema extends Partial<SpreadDtos.Country> {
    name: string;
}

const countrySchema = Yup.object<ICountryFormSchema>({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: countrySchema.notRequired(),
})

const CountryEditForm: React.FunctionComponent<ICountryEditFormProps> = ({
    editCountry,
    history,
    loadingCountry,
    url,
}) => {
    const editMode = editCountry !== undefined;

    const wrappedObject = editMode ? {
        object: editCountry,
    } : undefined;

    return <EditForm
        actions={countryModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingCountry}
        selectors={countryModule.selectors}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        url={url}
        validate={validation}
    >{() => (
        <>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.name"
                    component={TextFieldWrapper}
                    type="text"
                    label="Country Name"
                />
            </Grid>
        </>
    )}
    </EditForm>
};

export default CountryEditForm;