/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AnalysisHome } from '../components/analysis/AnalysisHome';
import { AnalysisTapmHome } from '../components/analysis/tapm/AnalysisTapmHome';
import { CreateAnalysisTapm } from '../components/analysis/tapm/CreateAnalysisTapm';
import { BulkUploadHome } from '../components/bulkUpload/BulkUploadHome';
import { ImportLogHome } from '../components/bulkUpload/ImportLogHome';
import { EditEpidemic } from '../components/epidemic/EditEpidemic';
import { EpidemicHome } from '../components/epidemic/EpidemicHome';
import { EpidemicModuleSelector } from '../components/epidemic/EpidemicModuleSelector';
import { ManageEpidemicAccess } from '../components/epidemic/ManageEpidemicAccess';
import { ViewEpidemic } from '../components/epidemic/ViewEpidemic';
import { CreateGenomicNetworkForm } from '../components/genomic/GenomicNetwork/CreateGenomicNetworkForm';
import { ViewGenomicNetworkJob } from '../components/genomic/GenomicNetwork/ViewGenomicNetworkJob';
import { ViewGenomicNetworkJobs } from '../components/genomic/GenomicNetwork/ViewGenomicNetworkJobs';
import { CreateGroupEpidemic } from '../components/groupEpidemic/CreateGroupEpidemic';
import { AreaDateTypesPage } from '../pages/application/AreaDateTypesPage';
import { BioinformaticsPage } from '../pages/application/BioinformaticsPage';
import { ClustersPage } from '../pages/application/ClustersPage';
import epidemicModule from '../store/reducers/epidemics/epidemic';
import { CreateLivestock } from '../components/livestock/CreateLivestock';
import { ViewLivestocks } from '../components/livestock/ViewLivestocks';
import { EditLivestock } from '../components/livestock/EditLivestock';
import { ViewExcretionJobs } from '../components/excretion/ViewExcretionJobs';
import { CreateExcretionJob } from '../components/excretion/CreateExcretionJob';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IEpidemicRouterParams {
    epidemicId?: string
}

interface IEpidemicRouterProps {
    epidemicId?: number;
    match: match<IEpidemicRouterParams>;
}

export const EpidemicRouter: React.FunctionComponent<IEpidemicRouterProps> = ({ epidemicId, match }) => {
    const epidemicIdTemp = match.params.epidemicId ? parseInt(match.params.epidemicId) : (epidemicId || -1);

    // Load in epidemic so child routes can use
    const epidemicIdLoaded = React.useMemo(() => epidemicIdTemp, [epidemicIdTemp])
    const [, response] = epidemicModule.hooks.useLoad(epidemicIdLoaded);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
            <Redirect to="/404" /> :
            <Switch>
                <Route exact path={`${match.path}/data/import`} component={BulkUploadHome} />
                <Route exact path={`${match.path}/data/edit`} component={EditEpidemic} />
                <Route exact path={`${match.path}/data`} component={EpidemicHome} />
                <Route exact path={`${match.path}/analysis`} component={AnalysisHome} />
                <Route exact path={`${match.path}/analysis/create`} component={CreateAnalysisTapm} />
                <Route exact path={`${match.path}/analysis/:analysisJobId`} component={AnalysisTapmHome} />
                <Route path={`${match.path}/results`} component={ViewEpidemic} />
                <Route exact path={`${match.path}/manage/groupEpidemic/create`} component={CreateGroupEpidemic} />
                <Route exact path={`${match.path}/genomicNetwork/create`} component={CreateGenomicNetworkForm} />
                <Route exact path={`${match.path}/genomicNetwork/:genomicNetworkJobId`} component={ViewGenomicNetworkJob} />
                <Route exact path={`${match.path}/genomicNetwork`} component={ViewGenomicNetworkJobs} />
                <Route exact path={`${match.path}/data/livestock/create`} component={CreateLivestock} />
                <Route exact path={`${match.path}/data/livestock/:livestockId`} component={EditLivestock} />
                <Route exact path={`${match.path}/data/livestock`} component={ViewLivestocks} />
                <Route exact path={`${match.path}/excretion/create`} component={CreateExcretionJob} />
                <Route exact path={`${match.path}/excretion`} component={ViewExcretionJobs} />
                <Route path={`${match.path}/data/areaDateType`} component={AreaDateTypesPage} />
                <Route path={`${match.path}/data/cluster`} component={ClustersPage} />
                <Route path={`${match.path}/data/import/log`} component={ImportLogHome} />
                <Route path={`${match.path}/manage`} component={ManageEpidemicAccess} />
                <Route path={`${match.path}/bioinformatics`} component={BioinformaticsPage} />
                <Route path={`${match.path}`} component={EpidemicModuleSelector} />
            </Switch>
}