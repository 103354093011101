/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an disease classifications
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateDiseaseSubClassification,
    CreateDiseaseSubClassificationResponse,
    DeleteDiseaseSubClassification,
    DeleteDiseaseSubClassificationResponse,
    DiseaseSubClassification,
    GetDiseaseSubClassification,
    GetDiseaseSubClassificationResponse,
    UpdateDiseaseSubClassification,
    UpdateDiseaseSubClassificationResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IDiseaseSubClassificationState {
    diseaseSubClassification: IBaseCrudState<DiseaseSubClassification>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const diseaseSubClassificationModule = createCrudModule<DiseaseSubClassification, CreateDiseaseSubClassificationResponse, GetDiseaseSubClassificationResponse, UpdateDiseaseSubClassificationResponse, DeleteDiseaseSubClassificationResponse, undefined>(
    'diseaseSubClassification',
    CreateDiseaseSubClassification,
    GetDiseaseSubClassification,
    UpdateDiseaseSubClassification,
    DeleteDiseaseSubClassification,
    undefined,
)


export default diseaseSubClassificationModule;