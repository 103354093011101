/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../constants/Dates';
import { areaDateTypeSearchHooks } from '../../store/reducers/areaDateTypes/areaDateTypeSearch';
import { infectedPremiseSearchActions, infectedPremiseSearchHooks } from '../../store/reducers/infectedPremises/infectedPremiseSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import IconButtonLink from '../common/IconLink';
import { AnalysisDate } from '../areaDate/AnalysisDate';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        ipRow: {
        }
    }),
);

interface IInfectedPremiseListProps {
    className?: string;
    epidemicId?: number;
    searchText?: string;
    subClusterId?: number;
    url: string;
}

export const InfectedPremiseList: React.FunctionComponent<IInfectedPremiseListProps> = ({ className, epidemicId, searchText, subClusterId, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ epidemicId: epidemicId, subClusterId: subClusterId, searchText: searchText }), [epidemicId, subClusterId, searchText]);
    const pagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 200}), []);
    const dateTypeSpecOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId]);
    const [data, paginateOptions, , requestState] = infectedPremiseSearchHooks.useSearch(specOps, pagOps);
    const [dateTypeData, , ,] = areaDateTypeSearchHooks.useSearch(dateTypeSpecOps);

    const epidemic = useSelector(state => state.epidemic);

    const startDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isStartOfInfectiousPeriodDate);
    const endDateType = epidemic.data && epidemic.data.dateTypes && epidemic.data.dateTypes.find(t => t.isEndOfInfectiousPeriodDate);

    const headerColumns = [
        { id: '', numeric: false, disablePadding: true, label: '' },
        { id: 'farmId', numeric: false, disablePadding: false, label: 'Farm ID' },
        { id: 'infectedPremiseId', numeric: false, disablePadding: false, label: 'Infected Premise ID' },
        { id: 'infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: startDateType ? startDateType.name : 'Start of Infectious Period Date' },
        { id: 'infectedPremiseDateSet.latestEndOfInfectiousPeriodDate', numeric: false, disablePadding: false, label: endDateType ? endDateType.name : 'End of Infectious Period Date' },
    ];

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={infectedPremiseSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.ipRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            {
                                item.centreLat && item.centreLng
                                    ? <IconButtonLink edge="start" color="inherit" aria-label="View Analysis" to={`/epidemic/${item.epidemicId}/results/map?infectedPremiseId=${item.id}`}>
                                        <PublicIcon />
                                    </IconButtonLink>
                                    : null
                            }
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/${item.id}`}>
                                {item.farmId}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.infectedPremiseId ? item.infectedPremiseId : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                                <AnalysisDate dateSet={item.infectedPremiseDateSet} dateType='startinfectious' />
                        </TableCell>
                        <TableCell component="th" scope="row">
                                <AnalysisDate dateSet={item.infectedPremiseDateSet} dateType='endinfectious' />
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}