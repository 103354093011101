/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving a top level epidemic
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ArchiveEpidemic, ArchiveEpidemicResponse, CreateEpidemic, CreateEpidemicResponse, Epidemic, GetEpidemic, GetEpidemicResponse, UpdateEpidemic, UpdateEpidemicResponse } from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IEpidemicState {
    epidemic: IBaseCrudState<Epidemic>;
}


/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const epidemicModule = createCrudModule<Epidemic, CreateEpidemicResponse, GetEpidemicResponse, UpdateEpidemicResponse, undefined, ArchiveEpidemicResponse>(
    'epidemic',
    CreateEpidemic,
    GetEpidemic,
    UpdateEpidemic,
    undefined,
    ArchiveEpidemic,
)


export default epidemicModule;