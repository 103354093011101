/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an parcel
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActions, createCrudActionTypes, createCrudEpics, createCrudSelectors, IBaseCrudState, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialParcelState extends IBaseCrudState {
    data?: SpreadDtos.Parcel;
}

export interface IParcelState {
    parcel: IInitialParcelState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IParcelActionTypes {
    CLEAR: string;
}

const parcelTypes: IBaseCrudTypes & IParcelActionTypes = {
    ...createCrudActionTypes('parcel'),
    CLEAR: '@@parcel/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialParcelState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const parcelReducer = createReducer<IInitialParcelState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const parcelActions = {
    ...createCrudActions<SpreadDtos.Parcel, SpreadDtos.CreateParcelResponse, SpreadDtos.GetParcelResponse, SpreadDtos.UpdateParcelResponse, undefined, undefined>(parcelTypes),
    clear: createAction(parcelTypes.CLEAR,
        () => ({})
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(parcelReducer, parcelActions, 'parcel', initialState);

// Clear Reducer
parcelReducer.on(parcelActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
parcelReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const parcelsApi = {
    create: createPostRequest(
        SpreadDtos.CreateParcel,
        (parcel: SpreadDtos.Parcel) => ({
            parcel: parcel
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetParcel,
        (parcelId: number) => ({
            id: parcelId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateParcel,
        (parcel: SpreadDtos.Parcel) => ({
            parcel: parcel
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(parcelActions, parcelsApi);

export const parcelEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const parcelSelectors = createCrudSelectors('parcel');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default parcelReducer;