/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import * as React from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import NetworkLine from '../NetworkLine';
import { INetworkEdgeThatWillEventuallyBeADTO, networkEdges } from '../../../../helpers/tempData';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface IGenomicNetworkGraphProps {
    networkEdges: INetworkEdgeThatWillEventuallyBeADTO[];
    showProbabilities?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2, 1),
            padding: theme.spacing(1, 2),
            width: '160px'
        },
        legendLine: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        legendColours: {
            backgroundImage: 'linear-gradient(to bottom, #84ACFF,#90E47C,#EAE667,#FF7686)',
            margin: '2px',
            padding: '100px 20px',
        },
        legendLabels: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        },
        legendTitle: {
            paddingTop: theme.spacing(1)
        }
    })
);

export const GenomicNetworkGraph: React.FunctionComponent<IGenomicNetworkGraphProps> = ({ networkEdges, showProbabilities }) => {
    return <>
        {
            networkEdges ?
                networkEdges.map((ne, i) =>
                    <NetworkLine
                        key={`networkline-${i}`}
                        keepTooltipOpen={showProbabilities}
                        infectedPremiseFrom={ne.infectedPremiseFrom}
                        infectedPremiseTo={ne.infectedPremiseTo}
                        probability={ne.probability}
                        weight={2}
                    />) :
                null
        }
    </>;
}