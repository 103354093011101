/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect, useMemo, useState } from "react";

import format from 'date-fns/format';
import { addHours, eachDayOfInterval } from 'date-fns';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat, DateTimeHourFormat } from "../constants/Dates";
import { AnalysisResultTimeScale } from "../dtos/Spread.dtos";
import useInterval from "./useInterval";

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISliderMark {
    value: number;
    label: string;
    dateValue: Date;
}

export default function useTimeSlider(
    interval: AnalysisResultTimeScale,
    speed: number,
    startDate?: Date,
    endDate?: Date,
): [
        number,
        Date | undefined,
        (offsetValue: number) => void,
        ISliderMark[],
        () => void,
        () => void,
        () => void,
        () => void,
        boolean
    ] {
    const [currentTime, setCurrentTime] = useState<Date | undefined>(undefined);
    const [dateOffsetValue, setDateOffsetValue] = useState(0);

    const [sliderPlaying, setSliderPlaying] = useState(false);
    const [skipToEnd, setSkipToEnd] = useState(false);
    const [skipToStart, setSkipToStart] = useState(false);

    useInterval(() => {
        if (sliderPlaying) {
            if (dateOffsetValue === sliderMarkLength) {
                setSliderPlaying(false);
            } else {
                setDateOffsetValue(dateOffsetValue + 1);
            }
        }

        if (skipToStart) {
            setDateOffsetValue(0);
            setSkipToStart(false);
        }

        if (skipToEnd) {
            setDateOffsetValue(sliderMarkLength);
            setSkipToEnd(false);
        }
    }, speed);

    const sliderMarks: ISliderMark[] = useMemo(() => startDate && endDate && interval ? computeSliderMarks(interval, startDate, endDate) : [], [interval, startDate, endDate]);
    const sliderMarkLength: number = useMemo(() => sliderMarks ? sliderMarks.length - 1 : 1, [sliderMarks]);

    useEffect(() => {
        if (sliderMarks[dateOffsetValue]) {
            setCurrentTime(sliderMarks[dateOffsetValue].dateValue)
        }
    }, [dateOffsetValue, sliderMarks]);


    const setOffsetValueFn = useMemo(() => (offsetValue: number) => {
        setDateOffsetValue(offsetValue);
    }, [setDateOffsetValue]);

    const togglePlayingFn = useMemo(() => () => {
        setSliderPlaying(!sliderPlaying);
    }, [setSliderPlaying, sliderPlaying]);

    const stopPlayingFn = useMemo(() => () => {
        setSliderPlaying(false);
    }, [setSliderPlaying]);

    const skipToEndFn = useMemo(() => () => {
        setSkipToEnd(true);
    }, [setSkipToEnd]);

    const skipToStartFn = useMemo(() => () => {
        setSkipToStart(true);
    }, [setSkipToEnd]);

    // Skip to start if startDate or endDate change as the view has changed (analysis loaded or something)
    useEffect(() => {
        setSkipToStart(true);
    }, [startDate, endDate])

    return [
        dateOffsetValue, // current interval
        currentTime, // current interval value (date)
        setOffsetValueFn, // set current interval
        sliderMarks,
        togglePlayingFn,
        stopPlayingFn,
        skipToStartFn,
        skipToEndFn,
        sliderPlaying
    ]
}

const computeSliderMarks = (interval: AnalysisResultTimeScale, startDate: Date, endDate: Date): ISliderMark[] => {
    const dayDates = eachDayOfInterval(
        {
            start: startDate,
            end: endDate,
        }
    );

    if (interval === AnalysisResultTimeScale.Day) {
        return dayDates.map((d, idx) => {
            return {
                value: idx,
                label: format(d, DateTimeFormat),
                dateValue: d
            }
        });
    } else if (AnalysisResultTimeScale.Hour) {
        const hourDates = dayDates.flatMap((d, idx) => {
            return [0,1,2,3,4,5,6,7,8,9, 10,11,12,13,14,15,16,17,18,19, 20,21,22,23].map((hourCount) => addHours(d, hourCount));
        });

        return hourDates.map((d, idx) => {
            return {
                value: idx,
                label: format(d, DateTimeHourFormat),
                dateValue: d
            }
        });
    } else {
        // single frame
        return [{
            value: 0,
            label: format(startDate, DateTimeFormat),
            dateValue: startDate
        }];
    }
}