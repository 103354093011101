import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { MockFormContext } from './MockFormContext';

interface IMockMenuItem {
    key: any;
    label: string;
}

interface IMockSelectProps {
    className?: string;
    defaultValue?: any;
    disabled?: boolean;
    fieldName: string;
    title: string;
    multiline?: boolean;
}


const useMockFormStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

export const MockText: React.FunctionComponent<IMockSelectProps> = ({ className, disabled, defaultValue, fieldName, multiline, title }) => {
    const classes = useMockFormStyles();

    const mockForm = React.useContext(MockFormContext);

    const handleKeyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        mockForm.setField(fieldName, event.target.value as any);
    };

    //const variant: "outlined" |  = multiline ? "outlined" : "standard";

    return <TextField
        className={className}
        defaultValue={defaultValue}
        label={title}
        onChange={handleKeyChange}
        value={mockForm.fields[fieldName]}
    />
}

export const MockMultiLineText: React.FunctionComponent<IMockSelectProps> = ({ className, disabled, defaultValue, fieldName, multiline, title }) => {
    const classes = useMockFormStyles();

    const mockForm = React.useContext(MockFormContext);

    const handleKeyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        mockForm.setField(fieldName, event.target.value as any);
    };

    return <TextField
        className={className}
        defaultValue={defaultValue}
        label={title}
        onChange={handleKeyChange}
        value={mockForm.fields[fieldName]}
        multiline={true}
        variant={"outlined"}
    />
}