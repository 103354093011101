/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

/**
 * This helper returns a Material-UI ListItem with a react-router-dom Link as the
 * component. 
 * 
 * @augments {ListItem} (Material-UI) and {Link} (react-router-dom)
 * @returns A ListItem whose component is a Link
 */
export function ListItemExternalLink(props: JSX.IntrinsicElements['a']) {

  const { href, ...rest } = props;

  const renderLink = forwardRef((itemProps: any, ref: any) => (
      <a href={href} target="_blank" rel="noopener" {...itemProps}/>
  ));

  return <ListItem button component={renderLink} {...rest} />;
}