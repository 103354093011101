/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';

import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import PublicIcon from '@material-ui/icons/Public';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import { DateTimeFormat, DateTimeHourFormat } from '../../constants/Dates';
import IconButtonLink from '../common/IconLink';
import importLogSearchModule from '../../store/reducers/bulkUpload/importLogSearch';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        importLogRow: {
        }
    }),
);

const headerColumns = [
    { id: 'id', numeric: false, disablePadding: false, label: 'Id' },
    { id: 'importType', numeric: false, disablePadding: false, label: 'Import Type' },
    { id: 'createdByPersonId', numeric: false, disablePadding: false, label: 'Imported By' },
    { id: 'startDate', numeric: false, disablePadding: false, label: 'Import Date' },
];

interface IImportLogListProps {
    className?: string;
    epidemicId: number;
    url: string;
}

export const ImportLogList: React.FunctionComponent<IImportLogListProps> = ({ className, epidemicId, url }) => {
    const classes = useStyles();

    const pageOps = useMemo(() => ({ skip: 0, take: 10, orderBy: undefined, orderByDesc: 'Id' }), []);
    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId]);
    const [data, paginateOptions, , requestState] = importLogSearchModule.hooks.useSearch(specOps, pageOps);

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={importLogSearchModule.actions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.importLogRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/${item.id}`}>
                                {item.id}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.importType}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.createdByPersonId}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {format(toDate(item.startDate), DateTimeHourFormat)}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}