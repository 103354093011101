/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { addDays, isSameDay, isSameHour } from 'date-fns';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { toDate } from '@servicestack/client';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import TimeSlider from '../../timeSlider/TimeSlider';
import useTimeSlider from '../../../hooks/useTimeSlider';
import { Epidemic, AnalysisResultTimeScale } from '../../../dtos/Spread.dtos';
import { EpidemicResultsContext } from '../../epidemic/EpidemicResultsContext';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IEpidemicTimeControlProps {
    analysisTimeScale: AnalysisResultTimeScale; // refactor
    analysisStartDate?: Date; // refactor
    analysisEndDate?: Date; // refactor 
    epidemic?: Epidemic;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2, 1)
        }
    })
);

const EpidemicTimeControl: React.FunctionComponent<IEpidemicTimeControlProps> = ({ analysisTimeScale, analysisStartDate, analysisEndDate, epidemic }) => {
    const classes = useStyles();

    const epidemicResultContext = useContext(EpidemicResultsContext);

    const epStartDate = useMemo(() => {
        return epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate ? analysisStartDate ? analysisStartDate : addDays(toDate(epidemic.epidemicDateSet.earliestStartOfInfectiousPeriodDate), -1) : undefined
    }, [analysisStartDate, epidemic]);

    const epEndDate = useMemo(
        () => epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate
            ? analysisEndDate ? analysisEndDate : addDays(toDate(epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate), 1)
            : undefined,
        [analysisEndDate, epidemic]);
        
    useEffect(() => {
        epidemicResultContext.setCurrentDates(epStartDate, epEndDate);
        }, [epidemicResultContext, epStartDate, epEndDate]
    )
        
    const [speed, setSpeed] = useState(1000);

    useEffect(() => {
        if(analysisTimeScale === AnalysisResultTimeScale.Day){
            setSpeed(1000)
        } else {
            setSpeed(250)
        }
    }, [analysisTimeScale])
    
    const [
        currentInterval,
        currentTime,
        setOffsetValue,
        sliderMarks,
        togglePlayingFn,
        stopPlayingFn,
        skipToStartFn,
        skipToEndFn,
        sliderPlaying
    ] = useTimeSlider(analysisTimeScale ? analysisTimeScale : AnalysisResultTimeScale.Day, speed, epStartDate, epEndDate);

    const setSpeedCallback = useMemo(() => (val: number) => {
        setSpeed(val);
    }, [setSpeed]);

    useEffect(() => {
        epidemicResultContext.setCurrentTime(currentTime);
        }, [epidemicResultContext, currentTime]
    )

    return epStartDate && epEndDate ? <TimeSlider
        startDate={epStartDate}
        endDate={epEndDate}
        interval={analysisTimeScale ? analysisTimeScale : AnalysisResultTimeScale.Day}
        setSpeedCallBack={setSpeedCallback}
        speed={speed}
        currentInterval={currentInterval}
        currentTime={currentTime}
        setOffsetValue={setOffsetValue}
        sliderMarks={sliderMarks}
        togglePlayingFn={togglePlayingFn}
        stopPlayingFn={stopPlayingFn}
        skipToStartFn={skipToStartFn}
        skipToEndFn={skipToEndFn}
        sliderPlaying={sliderPlaying}
    /> : null
}

export default EpidemicTimeControl;