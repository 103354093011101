/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toDateFmt } from '@servicestack/client';
import classNames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { areaDateTypeSearchHooks } from '../../store/reducers/areaDateTypes/areaDateTypeSearch';
import { parcelSearchActions, parcelSearchHooks } from '../../store/reducers/parcels/parcelSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';
import { AnalysisDate } from '../areaDate/AnalysisDate';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        parcelRow: {
        }
    }),
);

interface IParcelListProps {
    className?: string;
    clusterId?: number;
    epidemicId?: number;
    infectedPremiseId?: number;
    url: string;
}

export const ParcelList: React.FunctionComponent<IParcelListProps> = ({ className, clusterId, epidemicId, infectedPremiseId, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({ clusterId: clusterId, epidemicId: epidemicId, infectedPremiseId: infectedPremiseId }), [clusterId, epidemicId, infectedPremiseId]);
    const pagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 200}), []);
    const dateTypeSpecOps = useMemo(() => ({ epidemicId: epidemicId, isAnalysisDate: true }), [epidemicId]);
    const dateTypePagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 200}), []);
    const [data, paginateOptions, , requestState] = parcelSearchHooks.useSearch(specOps, pagOps);
    const [dateTypeData, , ,] = areaDateTypeSearchHooks.useSearch(dateTypeSpecOps, dateTypePagOps);

    const startDateType = dateTypeData && dateTypeData.results.find(t => t.isStartOfInfectiousPeriodDate);
    const endDateType = dateTypeData && dateTypeData.results.find(t => t.isEndOfInfectiousPeriodDate);

    const headerColumns = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'analysisStartDate', numeric: false, disablePadding: false, label: startDateType ? startDateType.name : 'Start of Infectious Period Date' },
        { id: 'analysisEndDate', numeric: false, disablePadding: false, label: endDateType ? endDateType.name : 'End of Infectious Period Date' },
    ];

    return <SortableTable
        className={classNames(className, classes.table)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={parcelSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.parcelRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${url}/parcel/${item.id}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <AnalysisDate dateSet={item.parcelDateSet} dateType='startinfectious' />
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <AnalysisDate dateSet={item.parcelDateSet} dateType='endinfectious' />
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}