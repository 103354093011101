/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IRequestState } from "../types/IRequestState";
import { useSelector } from "./useTypedSelector";
import { analysisTapmActions } from "../store/reducers/analysis/analysisTapm";

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useReprocessTapmResults(analysisJobTapmId?: number): [
    () => void,
    IRequestState
] {
    const dispatch = useDispatch();
    const requestState = useSelector(state => state.analysisTapm.reprocessResultsState);

    const reprocessFn = () => {
        if (analysisJobTapmId) {
            dispatch(analysisTapmActions.reprocessResults(analysisJobTapmId))
        }
    };

    return [
        reprocessFn,
        requestState
    ];
}
