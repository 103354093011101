/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import 'es6-shim';
import * as React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import spreadStore from './store/store';
import { AuthBaseline } from './components/routes/AuthBaseline';
import { SpreadApplicationRoutes } from './components/routes/SpreadApplicationRoutes';
import { useSelector } from './hooks/useTypedSelector';
import { HomePage } from './pages/content/HomePage';
import { RegisterPage } from './pages/application/RegisterPage';
import LoginPage from './pages/application/LoginPage';


/*
 * ---------------------------------------------------------------------------------
 * Initialise Charts
 * ---------------------------------------------------------------------------------
 */

import ReactChartkick from 'react-chartkick';
import * as Chart from 'chart.js';
import { useTooltip } from './hooks/useTooltip';
ReactChartkick.addAdapter(Chart);

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const history = createBrowserHistory();
export const store = spreadStore(history)

const App = () => {

    return (<Provider store={store}>
        <AuthBaseline />
        <ThemedApp />
    </Provider>);
}

const ThemedApp = () => {
    const themeState = useSelector(state => state.theme);

    // ensure tooltips are loaded
    useTooltip('');

    return <MuiThemeProvider theme={themeState}>
        <CssBaseline />
        <ConnectedRouter history={history}>
            <Switch>
                {
                    // Home & Content sections
                }
                <Route exact path={'/'} component={HomePage} />
                <Route exact path={'/signup'} component={RegisterPage} />
                <Route exact path={'/login'} component={LoginPage} />

                {
                    // SPREAD Application
                }
                <Route component={SpreadApplicationRoutes} />
            </Switch>
        </ConnectedRouter>
    </MuiThemeProvider>;

}

export default App;
