/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { Field } from '../../dtos/Spread.dtos';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewFieldProps {
    field?: Field;
    loading: boolean;
    match: match<any>;
}

export const ViewField: React.FunctionComponent<IViewFieldProps> = ({ field, loading, match }) => {
    const classes = useStyles();

    return <>
        <Paper className={classes.root}>
            <Typography variant="h4" component="h4">{!loading ? field && field.name : <Skeleton />}</Typography>
            <Typography variant="h5" component="h5">
                {
                    //!loading ? field &&
                    //    `Cluster: ${field.cluster.name}` :
                    //    <Skeleton />
                }
            </Typography>
            <Typography variant="h5" component="h5">
                {
                    //!loading ? field &&
                    //    `Infected Premise: ${field.infectedPremise.name}` :
                    //    <Skeleton />
                }
            </Typography>
            <Typography variant="body1" component="p">{!loading ? field?.centreLat && `Latitude : ${field.centreLat}` : <Skeleton />}</Typography>
            <Typography variant="body1" component="p">{!loading ? field?.centreLng && `Longitude: ${field.centreLng}` : <Skeleton />}</Typography>
            <Typography variant="body1" component="p">{!loading ? field?.area && <> Area: {field.area} m<sup>2</sup></> : <Skeleton />}</Typography>
            <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                <HasPermission permission="UpdateArea" objectId={field?.epidemicId}>
                    <ButtonLink
                        aria-label="Edit Area"
                        color="primary"
                        variant="contained"
                        to={`${match.url}/edit`}
                    >
                        <EditIcon className={classes.buttonSubmitIcon} />
                        Edit
                    </ButtonLink>
                </HasPermission>
            </Box>
        </Paper>

    </>
}