/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateInfectedPremise } from '../../components/infectedPremise/CreateInfectedPremise';
import { ViewInfectedPremises } from '../../components/infectedPremise/ViewInfectedPremises';
import { InfectedPremiseRouter } from '../../routers/InfectedPremiseRouter';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IInfectedPremisesPageProps {
    match: match;
}

export const InfectedPremisesPage: React.FunctionComponent<IInfectedPremisesPageProps> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/create`} component={CreateInfectedPremise} />
        <Route exact path={`${match.path}`} component={ViewInfectedPremises} />
        <Route path={`${match.path}/:infectedPremiseId`} component={InfectedPremiseRouter} />
    </Switch>;
}
