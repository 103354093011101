/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import classNames from 'classnames'
import * as React from 'react';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateGroupMemberInviteForm } from './CreateGroupMemberInviteForm';
import { useSelector } from '../../hooks/useTypedSelector';
import { groupInviteActions } from '../../store/reducers/groups/groupInvite';
import { RequestState } from '../../types/RequestState';
import { ErrorMessage } from '../common/ErrorMessage';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            //padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        buttonInviteMore: {
        },
        errorMessage: {
            padding: theme.spacing(2, 1),
        }
    }),
);

interface ICreateGroupInviteProps {
    className?: string;
    groupId: number;
}

export const CreateGroupMemberInvite: React.FunctionComponent<ICreateGroupInviteProps> = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const createState = useSelector(state => state.groupInvite.createMemberInviteState);

    const handleClearInvite = () => {
        dispatch(groupInviteActions.clear());
    }

    useEffect(() => {
        return function cleanup() {
            dispatch(groupInviteActions.clearCreateInvite());
            dispatch(groupInviteActions.loadInvites(props.groupId))
        }
    }, [dispatch]);

    const tryAgainButton = <Button
        variant="contained"
        size="small"
        type="button"
        color="secondary"
        onClick={handleClearInvite}
    >
        Try again
    </Button>;

    const inviteMoreButton = <Button
        variant="contained"
        size="small"
        className={classes.buttonInviteMore}
        type="button"
        color="secondary"
        onClick={handleClearInvite}
    >
        Invite more
    </Button>;

    return <div className={classNames(classes.root, props.className)}>
    {//<Paper className={classNames(classes.root, props.className)}>
    }
        <Typography variant="h5" component="h5">Invite user to group</Typography>
        {createState.state === RequestState.None && <CreateGroupMemberInviteForm groupId={props.groupId} />}
        <ErrorMessage className={classes.errorMessage} errorSelector={state => state.groupInvite.createMemberInviteState.responseStatus} />

        {createState.state === RequestState.Success && <Box display="flex" justifyContent="space-between"><Typography>Invite successfully sent</Typography>{inviteMoreButton}</Box>}

        {createState.state === RequestState.Failure && <Box display="flex" alignItems="center" justifyContent="flex-end">
            {tryAgainButton}
        </Box>
        }

        {createState.state === RequestState.Pending && <Box display="flex" alignItems="center" justifyContent="flex-end"><CircularProgress /></Box>}
    </div>
}