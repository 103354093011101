/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import MenuItem from '@material-ui/core/MenuItem';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import { Field, FieldProps } from 'react-final-form';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../../constants/Dates';
import excretionSearchModule from '../../../store/reducers/excretion/excretionSearch';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAnalysisTapmExcretionSelectProps extends FieldProps<any, any> {
    epidemicId?: number;
}

const AnalysisTapmExcretionSelect: React.FunctionComponent<IAnalysisTapmExcretionSelectProps> = ({
    epidemicId,
    ...fieldProps
}) => {
    const excretionPageOps = useMemo(() => ({ take: 1000, skip: 0, orderByDesc: 'RequestedDate' }), []);

    const excretionSpecOps = useMemo(() => ({ epidemicId: epidemicId, status: 'ResultsProcessed' }), [epidemicId]);

    const [tapmExcretions, , ,] = excretionSearchModule.hooks.useSearch(excretionSpecOps, excretionPageOps);

    const tapmExcretionsOptions = tapmExcretions && tapmExcretions.results && tapmExcretions.results.length > 0 ?
        tapmExcretions.results.map((e =>
            <MenuItem key={e.id} value={e.id}>
                {e.name} - {e.model} - {format(toDate(e.requestedDate), DateTimeFormat)}
            </MenuItem>
        )) :
        [];

    return <Field {...fieldProps} component={SelectWrapper}>
        {tapmExcretionsOptions}
    </Field>;
}

export default AnalysisTapmExcretionSelect;