/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import mockupCoverView from '../../../assets/images/mockup_coverview.png';
import { MockSelect } from './MockSelect';
import { MockFormContext } from './MockFormContext';

import mockupFastQc from '../../../assets/images/mockup_fastqc.png';
import mockupPileUp from '../../../assets/images/mockup_pileup.png';
import { MockRadioButtons } from './MockRadioButtons';
import { MockMultiLineText } from './MockText';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        assemblyButtons: {
            margin: theme.spacing(1)
        },
        imageWrapper: {
            textAlign: 'center'
        },
        image: {
            width: '50%',
        }
    }),
);

interface IMockSequenceQualityFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

function createData(name: string, count: string, length: string, bases: string) {
    return { name, count, length, bases };
}

const mappingStatsRows = [
    createData('Total reads', '436,142', '234.81', '102,408,580'),
    createData('Mapped', '423,844', '234.82', '99,528,100'),
    createData('Unmapped', '12,298', '234.22', '2,880,480'),
];

export const MockSequenceQualityForm: React.FunctionComponent<IMockSequenceQualityFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const mockForm = React.useContext(MockFormContext);
    const dataSet = mockForm.fields['MockDataSetYear'];

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqqual_genomeId'}
                title={'Genome ID'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: 'AA001100'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqqual_epidemic'}
                title={'Epidemic'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: dataSet === 1982 ? '1982/83 - GDR / Denmark' : 'UK_FMD_2007'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'seqqual_cluster'}
                title={'Cluster'}
                defaultKey={'1'}
                items={[
                    {
                        key: '1',
                        label: dataSet === 1982 ? 'Eastern Funen - DNK' : 'Surrey'
                    }
                ]}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={2} xs={12}>
            <MockRadioButtons
                className={classes.formControl}
                fieldName={'seqqual_assessment'}
                title={'Quality Assessment'}
                items={[
                    {
                        key: '1',
                        label: 'Pass'
                    },
                    {
                        key: '2',
                        label: 'Fail'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={10} xs={12}>
            <MockMultiLineText
                className={classes.formControl}
                fieldName={'seqqual_comments'}
                title='Comment'
                multiline
            />
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h5'}>Report</Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Quality Control Fastqc Output</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
            <div className={classes.imageWrapper}>
                <img
                    className={classes.image}
                    src={mockupFastQc}
                    alt="Fastqc Output"
                />
            </div>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Sequencing Data
            </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Count</TableCell>
                        <TableCell align="right">Average Length</TableCell>
                        <TableCell align="right">Total Bases</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mappingStatsRows.map(row => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.count}</TableCell>
                            <TableCell align="right">{row.length}</TableCell>
                            <TableCell align="right">{row.bases}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>Coverage and Variant Analysis</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
            <div className={classes.imageWrapper}>
                <img
                    className={classes.image}
                    src={mockupPileUp}
                    alt="PileUp.js Output"
                />
            </div>
        </Grid>
        <Grid item xs={12}>
            <Typography variant={'h6'}>CoverView</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
            <div className={classes.imageWrapper}>
                <img
                    className={classes.image}
                    src={mockupCoverView}
                    alt="CoverView Output"
                />
            </div>
        </Grid>
        <Grid item xs={12} />
    </Grid>
}