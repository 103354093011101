/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useEffect, useState } from "react";
import L from 'leaflet';
import { Feature, FeatureCollection, Geometry } from 'geojson';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

// We aren't using React-Leaflet (yet?), but here's a fix: https://github.com/PaulLeCam/react-leaflet/issues/453
// This is also imported wrong.
import '../../../node_modules/leaflet/dist/leaflet.css';
import { InfectedPremise } from '../../dtos/Spread.dtos';
import { DateTimeFormat } from '../../constants/Dates';

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../../node_modules/leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('../../../node_modules/leaflet/dist/images/marker-shadow.png')
});

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface ILeafletMapProps {
    defaultCentre: [number, number];
    infectedPremises?: InfectedPremise[];
    timeStep: Date;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            margin: theme.spacing(3, 0)
        },
        map: {
            height: '600px'
        },
    })
);

let map: any = undefined;
const layerGroupActive = L.layerGroup()
const layerGroupInactive = L.layerGroup()

const geojsonMarkerOptions = {
    postInfected: {
        radius: 8,
        fillColor: "#777777",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 1
    },
    infected: {
        radius: 8,
        fillColor: "#ff302f",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 1
    },
    notInfected: {
        radius: 8,
        fillColor: "#ffa818",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 1
    }
};

const PrototypeMap: React.FunctionComponent<ILeafletMapProps> = ({ defaultCentre, infectedPremises, timeStep }) => {
    const classes = useStyles();

    const [mapInit, setMapInit] = useState(false);

    useEffect(() => {
        if (!mapInit) {
            setMapInit(true);

            map = L.map('map', {
                center: defaultCentre, //[50.73, -4.01],
                zoom: 9,
                layers: [
                    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    }),
                ]
            });

            layerGroupActive.addTo(map);
            layerGroupInactive.addTo(map);
        }

        return function cleanup() {
            //map.remove();
        }
    }, [mapInit, setMapInit, defaultCentre])

    useEffect(() => {
        layerGroupActive.clearLayers();
        layerGroupInactive.clearLayers();

        if (infectedPremises) {
            const activeInfectedPremises = infectedPremises.filter((ip) => {
                return toDate(ip.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= timeStep && toDate(ip.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= timeStep;
            })
            const inactiveInfectedPremises = infectedPremises.filter((ip) => {
                return !(toDate(ip.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= timeStep && toDate(ip.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= timeStep);
            })

            var activeIpCollection: FeatureCollection = {
                type: 'FeatureCollection',
                features: activeInfectedPremises.map(i => MakeGeoJsonFeature(i))
            }

            L.geoJSON(activeIpCollection, {
                pointToLayer: (feature, latlng) => {
                    let styleToUse = geojsonMarkerOptions.infected;

                    return L.circleMarker(latlng, styleToUse);
                },
                onEachFeature: (feature: Feature<Geometry, IInfectedPremiseFeatureProperties>, layer) => {
                    if (feature) {
                        layer.bindPopup(
                            `Status: Infected
                                <br />Infected Premises: ${feature.properties.farmId}
                                <br />Farm ID: ${feature.properties.name}
                                <br />${feature.properties.start ? 'Presumed infection date:' + format(feature.properties.start as Date, DateTimeFormat) : ''}
                                <br />${feature.properties.end ? 'Disposal date:' + format(feature.properties.end as Date, DateTimeFormat) : ''}`
                        );
                    }
                }
            }
            ).addTo(layerGroupActive);


            var inactiveIpCollection: FeatureCollection = {
                type: 'FeatureCollection',
                features: inactiveInfectedPremises.map(i => MakeGeoJsonFeature(i))
            }

            L.geoJSON(inactiveIpCollection, {
                pointToLayer: (feature, latlng) => {
                    let styleToUse = geojsonMarkerOptions.notInfected;

                    if (timeStep > feature.properties.end) {
                        styleToUse = geojsonMarkerOptions.postInfected;
                    }

                    return L.circleMarker(latlng, styleToUse);
                },
                onEachFeature: (feature: Feature<Geometry, IInfectedPremiseFeatureProperties>, layer) => {
                    if (feature) {
                        const postInfected = feature.properties.end ? timeStep > feature.properties.end : false;

                        layer.bindPopup(
                            `Status: ${postInfected ? 'Culled' : 'At risk'}
                                <br />Infected Premises: ${feature.properties.farmId}
                                <br />Farm ID: ${feature.properties.name}
                                <br />${feature.properties.start ? 'Presumed infection date:' + format(feature.properties.start as Date, DateTimeFormat) : ''}
                                <br />${feature.properties.end ? 'Disposal date:' + format(feature.properties.end as Date, DateTimeFormat) : ''}`
                        );
                    }
                }
            }
            ).addTo(layerGroupActive);

            var doOnce = true;

            layerGroupActive.eachLayer(l => {
                if(doOnce){
                    (l as any).bringToFront();
                    doOnce = false;
                }
            })
        }
    }, [infectedPremises, timeStep])

    return <div className={classes.map} id="map"></div>;
}

interface IInfectedPremiseFeatureProperties {
    start?: Date;
    end?: Date;
    name: string;
    farmId: string;
}

const MakeGeoJsonFeature = (infectedPremise: InfectedPremise) => {

    const feature: Feature<Geometry, IInfectedPremiseFeatureProperties> = {
        type: "Feature",
        geometry: { type: "Point", coordinates: [infectedPremise.centreLat, infectedPremise.centreLng] },
        id: infectedPremise.id,
        properties: {
            start: infectedPremise.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate ? toDate(infectedPremise.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) : undefined,
            end: infectedPremise.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate ? toDate(infectedPremise.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) : undefined,
            name: infectedPremise.infectedPremiseId,
            farmId: infectedPremise.farmId
        }
    };

    return feature;
}

export default PrototypeMap;