/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple personRoles.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { ISearchState, initialSearchState, createAutoQueryModule } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialEpidemicPersonGroupAccessSearchState = ISearchState<SpreadDtos.QueryPersonGroupEpidemic>;

export interface IEpidemicPersonGroupAccessSearchState {
    epidemicPersonGroupAccessSearch: IInitialEpidemicPersonGroupAccessSearchState;
}

export const initialState: IEpidemicPersonGroupAccessSearchState = {
    epidemicPersonGroupAccessSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const epidemicPersonGroupAccessSearchModule = createAutoQueryModule(
    'epidemicPersonGroupAccessSearch',
    initialSearchState,
    '@@epidemicPersonGroupAccessSearch', 
    SpreadDtos.QueryPersonGroupEpidemics, 
    undefined,
    undefined,
    undefined,
    false,
    (options) =>({
        epidemicId: options.specialOptions.epidemicId
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export default epidemicPersonGroupAccessSearchModule;