/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import diseaseModule from '../../store/reducers/diseases/disease';
import { RequestFormState } from '../../types/RequestState';
import { DiseaseClassification } from '../../dtos/Spread.dtos';
import DiseaseClassificationEditForm from './DiseaseClassificationEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateDiseaseClassificationParams {
    diseaseId?: string;
}

interface ICreateDiseaseClassificationProps {
    diseaseId?: number;
    history: History;
    match: match<ICreateDiseaseClassificationParams>;
}

export const CreateDiseaseClassification: React.FunctionComponent<ICreateDiseaseClassificationProps> = (props) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const diseaseState = useSelector(state => state.disease);
    const diseaseId = props.diseaseId || ((props.match.params && props.match.params.diseaseId)? parseInt(props.match.params.diseaseId) : 0);
    useEffect(() => {
        if (diseaseModule.actions.load) {
            dispatch(diseaseModule.actions.load(diseaseId))
        }
    }, [dispatch, diseaseId]);

    const diseaseIsLoading = diseaseState.states.loadState.state === RequestFormState.Pending && !diseaseState.data;
    return diseaseIsLoading ?
        <CircularProgress /> : <Paper className={classes.root}>
            <DiseaseClassificationEditForm
                editDiseaseClassification={new DiseaseClassification({
                    diseaseId: diseaseState.data && diseaseState.data.id,
                })}
                disease={diseaseState.data}
                history={props.history}
                url={props.match.url}
            />
        </Paper>
}