/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useMemo } from 'react';
import * as classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink, Switch, Route, match, Redirect } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import SaveIcon from '@material-ui/icons/Save';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { MockSelect } from './MockSelect';
import { useSelector } from '../../../hooks/useTypedSelector';
import { clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import { infectedPremiseSearchHooks } from '../../../store/reducers/infectedPremises/infectedPremiseSearch';
import { livestocksHooks } from '../../../store/reducers/livestock/livestockSearch';
import { MockText } from './MockText';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        }
    }),
);

interface IMockSpecimenFormProps {
    className?: string;
    history: History;
    match: match<any>;
}

export const MockSpecimenForm: React.FunctionComponent<IMockSpecimenFormProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const epidemic = useSelector(state => state.epidemic.data);
    const epidemicId = epidemic ? epidemic.id : undefined

    const specOps = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])
    const [clusters, , ,] = clustersHooks.useSearch(specOps);

    const clusterItems = clusters && clusters.results
        ? clusters.results.map((c) => {
            return {
                key: c.id,
                label: c.name
            }
        })
        : [];

    const specOpsIp = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])
    const [infectedPremises, , ,] = infectedPremiseSearchHooks.useSearch(specOps);

    const ipItems = infectedPremises && infectedPremises.results
        ? infectedPremises.results.map((ip) => {
            return {
                key: ip.id,
                label: ip.infectedPremiseId ? ip.infectedPremiseId : ip.farmId
            }
        })
        : [];

    const specOpsLivestock = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])
    const [livestocks, , ,] = livestocksHooks.useSearch(specOps);

    const livestockItems = livestocks && livestocks.results
        ? livestocks.results.map((ls) => {
            return {
                key: ls.id,
                label: ls.speciesName
            }
        })
        : [];

    return <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={6}>
            <MockSelect
                defaultKey={'0'}
                disabled={true}
                fieldName={'spec_epidemic'}
                className={classes.formControl}
                title={''}
                items={[
                    {
                        key: '0',
                        label: epidemic ? epidemic.name : 'Unknown'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'spec_cluster'}
                title={'Cluster'}
                items={clusterItems}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'spec_ip'}
                title={'Infected Premise'}
                items={ipItems}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
            <MockSelect
                className={classes.formControl}
                fieldName={'spec_species'}
                title={'Host Species'}
                items={livestockItems}
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'spec_datecollection'}
                title='Date of Specimen Collection'
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'spec_specid'}
                title='Specimen ID'
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockSelect
                className={classes.formControl}
                fieldName={'spec_tissuespecimen'}
                title={'Tissue Specimen'}
                items={[
                    {
                        key: '0',
                        label: 'Tongue blister'
                    }, {
                        key: '1',
                        label: 'Epithelial tissue'
                    }
                ]}
            />
        </Grid>
        <Grid item lg={6} xs={12}>
            <MockText
                className={classes.formControl}
                fieldName={'spec_nlis'}
                title='NLIS RFID (or animal id)'
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
            <MockText
                className={classes.formControl}
                fieldName={'spec_dateisolate'}
                title='Date of Isolation (if undertaken)'
            />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={6}>
            <MockSelect
                className={classes.formControl}
                fieldName={'spec_materialsent'}
                title={'Material Sent For Sequencing'}
                items={[
                    {
                        key: '0',
                        label: 'Clinical material'
                    },
                    {
                        key: '1',
                        label: 'Cell culture virus isolation'
                    },
                    {
                        key: '2',
                        label: 'PCR product'
                    },
                    {
                        key: '3',
                        label: 'Synthetic virus (e.g. RG experiment)'
                    }
                ]}
            />
        </Grid>
    </Grid>
}