/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import React, { useState, useContext } from 'react';

import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, IconButton, Box, Slide } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { SmartMapContext, ISmartMapPosition } from './SmartMapContext';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISmartMapTab {
    featureIcon?: any;
    tabKey: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        controlRoot: {
            margin: '10px 0 0 -5px !important',
            minHeight: '40px'
        },
        toggleButton: {
            marginLeft: '8px',
            position: 'absolute',
            right: 0
        },
        openPaper: {
            marginBottom: theme.spacing(2)
        },
        openPaperInner: {
            padding: theme.spacing(0, 2, 1, 2),
        },
    })
);

const SmartMapTab: React.FunctionComponent<ISmartMapTab> = (props) => {
    const smartTabContext = useContext(SmartMapContext);
    const classes = useStyles(props);

    const { children, featureIcon, tabKey } = { ...props };
    const { openTabKey, position, toggleOpen } = { ...smartTabContext };
    const open = openTabKey === tabKey;

    const [hidden, setHidden] = useState(true);

    const handleSlideToggled = () => {
        setHidden(!hidden);
    }

    const slideDirection = position === 'left' ? 'right' : 'left';

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        toggleOpen(tabKey);
    }

    return <>
        {
            !open && <SmartMapToggleButton
                className={undefined}
                featureIcon={featureIcon}
                handleOpen={handleOpen}
                open={open}
                position={position} 
                wrap={true}
            />
        }

        <Slide in={open} direction={slideDirection} onExited={handleSlideToggled} onEnter={handleSlideToggled}>
            <Paper className={classes.openPaper} style={hidden ? { width: 0, height: 0 } : undefined}>
                <Box className={classes.openPaperInner}>
                    {open && <SmartMapToggleButton
                        className={classes.toggleButton}
                        featureIcon={featureIcon}
                        handleOpen={handleOpen}
                        open={open}
                        position={position}
                        wrap={false}
                    />}
                    {children}
                </Box>
            </Paper>
        </Slide>
    </>
}


const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: '40px',
            maxWidth: '64px',
            //marginBottom: theme.spacing(2),
            //position: 'absolute',
            right: (props: ISmartMapToggleButton): number | undefined => props.position === 'right' ? 0 : undefined,
        },
    })
);

interface ISmartMapToggleButton {
    className?: string;
    handleOpen: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    featureIcon: any;
    open: boolean;
    position: ISmartMapPosition;
    wrap: boolean;
}

const SmartMapToggleButton: React.FunctionComponent<ISmartMapToggleButton> = (props) => {
    const classes = useStylesButton(props);

    const { className,
        handleOpen,
        featureIcon,
        open,
        position,
        wrap } = { ...props };

    const invertIconDirection = position === 'left';

    const buttonElement = <IconButton className={className} onClick={handleOpen}>
        {
            (!open && invertIconDirection) || (!invertIconDirection && open) ? <>{featureIcon && featureIcon}<ChevronRightIcon /></>
                : <>{featureIcon && featureIcon}<ChevronLeftIcon /></>
        }
    </IconButton>;

    return wrap ? <Paper className={classes.paper}>
        {buttonElement}
    </Paper>
        : buttonElement


};

export default SmartMapTab;