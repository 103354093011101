/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { areaDateTypeActions, areaDateTypeSelectors } from '../../store/reducers/areaDateTypes/areaDateType';
import EditForm from '../forms/EditForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAreaDateTypeEditFormProps {
    editAreaDateType?: SpreadDtos.AreaDateType;
    epidemic?: SpreadDtos.Epidemic;
    history: History;
    loadingAreaDateType?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IAreaDateTypeFormSchema extends Partial<SpreadDtos.AreaDateType> {
}

const areaDateTypeSchema = Yup.object<IAreaDateTypeFormSchema>().shape({
    epidemicId: Yup.number().label('Epidemic')
        .required(),
    name: Yup.string().label('Name')
        .required(),
    offsetDays: Yup.number().label('Offset Days')
        .required(),
});

const validation = Yup.object().shape({
    object: areaDateTypeSchema.notRequired()
})


const AreaDateTypeEditForm: React.FunctionComponent<IAreaDateTypeEditFormProps> = ({
    editAreaDateType,
    epidemic,
    history,
    loadingAreaDateType,
    url,
}) => {
    const epidemicId = editAreaDateType ? editAreaDateType.epidemicId : epidemic && epidemic.id;

    const wrappedObject = {
        object: editAreaDateType
    };

    return <EditForm
        actions={areaDateTypeActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingAreaDateType}
        url={url}
        validate={validation}
        selectors={areaDateTypeSelectors}
        successRouteCallback={(d) => d ? `/epidemic/${epidemicId}/data/edit` : '/404'}
    >{() => <>
        <Grid item xs={12}>
            <Field
                required
                name="object.name"
                component={TextFieldWrapper}
                formControlProps={{
                    fullWidth: true,
                }}
                type="text"
                label="Name"
            />
        </Grid>

        <Field name="object.isStartOfInfectiousPeriodDate">
            {
                isStartOfInfectiousPeriodDate =>
                    <Field name="object.isEndOfInfectiousPeriodDate">
                        {
                            isEndOfInfectiousPeriodDate =>
                                <Grid item xs={12}>
                                    <Field
                                        name="object.isPresumedInfectionDate"
                                        component={CheckboxWrapper}
                                        disabled={isStartOfInfectiousPeriodDate && !!isStartOfInfectiousPeriodDate.input.value || isEndOfInfectiousPeriodDate && !!isEndOfInfectiousPeriodDate.input.value}
                                        label="Presumed Infection Date"
                                        type="checkbox"
                                    />
                                </Grid>
                        }
                    </Field>
            }
        </Field>

        <Field name="object.isEndOfInfectiousPeriodDate">
            {
                isEndOfInfectiousPeriodDate =>
                    <Grid item xs={12}>
                        <Field
                            name="object.isStartOfInfectiousPeriodDate"
                            component={CheckboxWrapper}
                            disabled={!!editAreaDateType || (isEndOfInfectiousPeriodDate && !!isEndOfInfectiousPeriodDate.input.value) || (epidemic && epidemic.epidemicDateSet && epidemic.epidemicDateSet.latestEndOfInfectiousPeriodDate)}
                            label="Start of Infectious Period Date"
                            type="checkbox"
                        />
                    </Grid>
            }
        </Field>

        <Field name="object.isPresumedInfectionDate">
            {
                isPresumedInfectionDate =>
                    <Field name="object.isStartOfInfectiousPeriodDate">
                        {
                            isStartOfInfectiousPeriodDate =>
                                <Grid item xs={12}>
                                    <Field
                                        name="object.isEndOfInfectiousPeriodDate"
                                        component={CheckboxWrapper}
                                        disabled={isStartOfInfectiousPeriodDate && !!isStartOfInfectiousPeriodDate.input.value || isPresumedInfectionDate && !!isPresumedInfectionDate.input.value}
                                        label="End of Infectious Period Date"
                                        type="checkbox"
                                    />
                                </Grid>
                        }
                    </Field>
            }
        </Field>
        <FormSpy subscription={{ values: true }}>
            {
                ({ values: { areaDateType } }) =>
                    <Grid item xs={12}>
                        <Field
                            required
                            disabled={areaDateType && areaDateType.isAnalysisDate}
                            name="object.offsetDays"
                            component={TextFieldWrapper}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            type="number"
                            label="Offset Days"
                            format={(value: number) => areaDateType && areaDateType.isStartOfInfectiousPeriodDate ? 0 : value}
                            parse={(value: number) => areaDateType && areaDateType.isStartOfInfectiousPeriodDate ? 0 : value}
                        />
                    </Grid>
            }
        </FormSpy>
    </>}
    </EditForm>
};

export default AreaDateTypeEditForm;