/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../constants/Dates';
import { DateSet } from '../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IAnalysisDateProps {
    dateSet: DateSet;
    dateType: 'presumedinfection' | 'startinfectious' | 'endinfectious';
}

export const AnalysisDate: React.FunctionComponent<IAnalysisDateProps> = ({ dateSet, dateType }) => {

    const emptyResult = <>{'Insufficient date data'}</>;

    if(!dateSet){
        return emptyResult;
    }

    const date = dateType === 'presumedinfection' ? dateSet.earliestPresumedInfectionDate : 
        dateType === 'startinfectious' ? dateSet.earliestStartOfInfectiousPeriodDate : 
        dateType === 'endinfectious' ? dateSet.latestEndOfInfectiousPeriodDate : undefined;

    const calculated = dateType === 'presumedinfection' ? dateSet.isEarliestPresumedInfectionDateCalculated : 
    dateType === 'startinfectious' ? dateSet.isEarliestStartOfInfectiousPeriodDateCalculated : 
    dateType === 'endinfectious' ? dateSet.isLatestEndOfInfectiousPeriodDateCalculated : undefined;

    return date ? <>
    {
        format(toDate(date), DateTimeFormat)
    }
    {
        calculated && '*'
    }
    </> : emptyResult
}