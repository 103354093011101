/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an livestock
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    Livestock,
    CreateLivestock,
    CreateLivestockResponse,
    GetLivestock,
    GetLivestockResponse,
    UpdateLivestock,
    UpdateLivestockResponse,
    DeleteLivestock,
    DeleteLivestockResponse
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ILivestockState {
    livestock: IBaseCrudState<Livestock>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const livestockModule = createCrudModule<Livestock, CreateLivestockResponse, GetLivestockResponse, UpdateLivestockResponse, DeleteLivestockResponse, undefined>(
    'livestock',
    CreateLivestock,
    GetLivestock,
    UpdateLivestock,
    DeleteLivestock,
    undefined,
)


export default livestockModule;