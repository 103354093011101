/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { Checkbox, ListItemText, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import CheckboxWrapper from '../forms/FinalFormControls/CheckboxWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import pathogenModule from '../../store/reducers/pathogens/pathogen';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IPathogenEditFormProps {
    editPathogen?: SpreadDtos.Pathogen;
    history: History;
    loadingPathogen?: boolean;
    url: string;
}

interface IPathogenFormSchema extends Partial<SpreadDtos.Pathogen> {
    name: string;
}

const pathogenSchema = Yup.object<IPathogenFormSchema>().shape({
    name: Yup.string().label('Name')
        .required(),
});

const validation = Yup.object().shape({
    object: pathogenSchema.notRequired()
})


const PathogenEditForm: React.FunctionComponent<IPathogenEditFormProps> = ({
    editPathogen,
    history,
    loadingPathogen,
    url,
}) => {
    const editMode = editPathogen !== undefined;
    const wrappedObject = editMode ? {
        object: editPathogen
    } : undefined;

    return <EditForm
        actions={pathogenModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingPathogen}
        selectors={pathogenModule.selectors}
        successRouteCallback={(d) => d ? `/admin/pathogen` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default PathogenEditForm;