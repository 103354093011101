/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { History } from 'history';

import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DeleteIcon from '@material-ui/icons/Delete';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { HasPermission } from '../common/HasPermission';
import { usePersonGroupInvitesLoad } from '../../hooks/useGroupInvite';
import { RequestState } from '../../types/RequestState';
import { groupInviteActions } from '../../store/reducers/groups/groupInvite';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IGroupMemberInviteListProps {
    history: History;
    groupId?: number;
}

export const GroupMemberInviteList: React.FunctionComponent<IGroupMemberInviteListProps> = ({
    history,
    groupId
}) => {
    const [data, requestState] = usePersonGroupInvitesLoad(groupId);
    const dispatch = useDispatch();

    const Row = (listItem: any) => {
        const memberItem = data && data[listItem.index];

        const handleDeleteMemberInvite = React.useCallback(
            () => memberItem && dispatch(groupInviteActions.deleteInvite(memberItem.id)), [memberItem, dispatch]
        );

        return data && data[listItem.index] && groupId
            ? <ListItem button key={listItem.index} ContainerComponent="div" ContainerProps={{ style: listItem.style }} style={listItem.style}>
                <ListItemAvatar>
                    <Avatar>
                        <PersonOutlineIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${data[listItem.index].email}`} secondary={`${data[listItem.index].email}`} />
                <HasPermission objectId={groupId} permission={'DeleteGroupMember'}>
                    <ListItemSecondaryAction >
                        <IconButton edge="end" aria-label="Delete" onClick={handleDeleteMemberInvite}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </HasPermission>
            </ListItem>
            : null
    };

    return requestState.state === RequestState.Pending 
        ? <CircularProgress />
        : <FixedSizeList
        height={400}
        width={360}
        itemSize={70}
        itemCount={data ? data.length : 0}
    >
        {Row}
    </FixedSizeList>
}