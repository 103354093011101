/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import ButtonLink from '../common/ButtonLink';
import { MockAlignmentForm } from './MockForms/MockAlignmentForm';
import { MockAssembleForm } from './MockForms/MockAssembleForm';
import { MockSequenceForm } from './MockForms/MockSequenceForm';
import { MockSpecimenForm } from './MockForms/MockSpecimenForm';
import { IMockFormState, MockFormContext } from './MockForms/MockFormContext';
import { MockUploadForm } from './MockForms/MockUploadForm';
import { MockViewNextGenForm } from './MockForms/MockViewNextGenForm';
import { MockSequenceQualityForm } from './MockForms/MockSequenceQualityForm';
import { MockSequenceAnnotationForm } from './MockForms/MockSequenceAnnotationForm';
import { ViewMSA } from './MSA/ViewMSA';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        formPaper: {
            padding: theme.spacing(3, 2)
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IGenomicUploadProps {
    className?: string;
    history: History;
    match: match<any>;
}

const initialState = {};

function reducer(state: IMockFormState, action: any) {
    switch (action.type) {
        case 'setField':
            const updatedState = { ...state }

            updatedState[action.payload.key] = action.payload.value;

            return update(
                state,
                {
                    [action.payload.key]: {
                        $set: action.payload.value
                    }
                }
            )
        default:
            throw new Error();
    }
}

export const GenomicUpload: React.FunctionComponent<IGenomicUploadProps> = ({ className, history, match }) => {
    const classes = useStyles();

    const epidemic = useSelector(state => state.epidemic);

    const [mockFormState, dispatch] = React.useReducer(reducer, initialState);

    const handleSetField = (key: string, value: any) => {
        if (mockFormState) {
            const updatedState = { ...mockFormState }

            updatedState[key] = value;

            dispatch({ type: 'setField', payload: { key: key, value: value } })
        }
    }

    React.useEffect(() => {
        if (epidemic.data) {

            var year = 0;
            if (epidemic.data.year == 1966 || epidemic.data.year == 1967 || epidemic.data.year == 2001 || epidemic.data.year == 1982) {
                year = epidemic.data.year;
            }

            dispatch({
                type: 'setField',
                payload: {
                    key: 'MockDataSetYear',
                    value: year
                }
            })
        }
    }, [epidemic])

    const homeUrl = match.url.replace('/upload', '');

    return <MockFormContext.Provider value={{
        fields: mockFormState,
        setField: (k, v) => handleSetField(k, v)
    }}>
        <Box className={classes.root} component="div">
            <Box className={classes.layoutItem}>
                <Paper className={classes.formPaper}>
                    <Switch>
                        <Route exact path={`${match.path}/specimen`}>
                            <GenomicFormWrapper
                                buttonUrl={`${match.url}/sequence`}
                                buttonText={'Next'}
                                title={'Upload new Genomic Data'}
                                subtitle={'Step 1 - Isolate and specimen details'}
                            >
                                <MockSpecimenForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/sequence`}>
                            <GenomicFormWrapper
                                buttonUrl={`${match.url}/genomic`}
                                buttonText={'Next'}
                                title={'Upload new Genomic Data'}
                                subtitle={'Step 2 - Sequencing'}
                            >
                                <MockSequenceForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/genomic`}>
                            <GenomicFormWrapper
                                buttonUrl={`${match.url}/assemble`}
                                buttonText={'Upload data'}
                                title={'Upload new Genomic Data'}
                                subtitle={'Step 3 - Upload sequences'}
                            >
                                <MockUploadForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/assemble`}>
                            <GenomicFormWrapper
                                buttonUrl={`${match.url}/alignment`}
                                buttonText={'Run assembly'}
                                title={'Assemble NextGen sequence data'}
                                subtitle={'Step 4 - Assemble'}
                            >
                                <MockAssembleForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/alignment`}>
                            <GenomicFormWrapper
                                buttonUrl={`${match.url}/alignment/-1/review`}
                                buttonText={'Create MSA'}
                                title={'Create Multi-Sequence Alignment'}
                                subtitle={'Select MSA'}
                            >
                                <MockAlignmentForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/alignment/:msaId/review`}>
                            <GenomicFormWrapper
                                buttonUrl={homeUrl}
                                buttonText={'Update MSA Assessment'}
                                title={'Multi-Sequence Alignment QA'}
                                subtitle={'Review MSA'}
                            >
                                <ViewMSA
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/:nextGenId/view`}>
                            {
                                routeProps => <GenomicFormWrapper
                                    title={'NextGen Sequence Data'}
                                    subtitle={''}
                                >
                                    <MockViewNextGenForm
                                        history={history}
                                        match={match}
                                        nextGenId={routeProps.match && routeProps.match.params.nextGenId}
                                    />
                                </GenomicFormWrapper>
                            }
                        </Route>
                        <Route exact path={`${match.path}/:nextGenId/quality`}>
                            <GenomicFormWrapper
                                buttonUrl={homeUrl}
                                buttonText={'Save'}
                                title={'NGS Assembly QA Report'}
                                subtitle={''}
                            >
                                <MockSequenceQualityForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Route exact path={`${match.path}/:nextGenId/annotation`}>
                            <GenomicFormWrapper
                                buttonUrl={homeUrl}
                                buttonText={'Save'}
                                title={'Annotation QA of Consensus Sequence'}
                                subtitle={''}
                            >
                                <MockSequenceAnnotationForm
                                    history={history}
                                    match={match}
                                />
                            </GenomicFormWrapper>
                        </Route>
                        <Redirect to={`${match.path}/specimen`} />
                    </Switch>
                </Paper>
            </Box>
        </Box>
    </MockFormContext.Provider>
}

const useFormWrapperStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            marginBottom: theme.spacing(2),
        }
    }),
);

interface IGenomicFormWrapper {
    buttonUrl?: string;
    buttonText?: string;
    title: string;
    subtitle: string;
}

export const GenomicFormWrapper: React.FunctionComponent<IGenomicFormWrapper> = ({ children, buttonUrl, buttonText, title, subtitle }) => {

    const classes = useFormWrapperStyles();

    return <>
        <Typography variant={'h5'}>{title}</Typography>
        <Typography className={classes.subtitle} variant={'subtitle1'}>{subtitle}</Typography>
        {children}
        {
            buttonText || buttonUrl ?
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <ButtonLink
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={false}
                        to={buttonUrl ? buttonUrl : ''}
                    >
                        {buttonText}
                    </ButtonLink>
                </Box>
                : null
        }
    </>

}