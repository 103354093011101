/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an pathogen
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import update from 'immutability-helper';
import { Action } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { ApiJobGenomicNetwork, ApiJobMSA, CreateJobGenomicNetworkMSA, CreateJobGenomicNetworkMSAResponse, CreateJobMSA, CreateJobMSAResponse, DeleteJobMSA, DeleteJobMSAResponse, GetMSA, GetMSAResponse, MSA } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createDeleteRequest, createPostRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { ActionType, baseCrudInitialState, createCrudModule, IBaseCrudActions, IBaseCrudApi, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IMSASubState extends IBaseCrudState<MSA> {
    job?: ApiJobMSA;
    extraStates: {
        createGenomicNetworkJobState: IFormRequestState;
        createJobState: IFormRequestState;
        deleteJobState: IFormRequestState;
    }
}

export interface IMSAState {
    msa: IMSASubState;
}

interface IMSAActions extends IBaseCrudActions<MSA> {
    createGenomicNetworkJob: ActionType<(epidemicId: number, msaId: number, startDateTypeId: number) => { epidemicId: number, msaId: number, startDateTypeId: number }>;
    createGenomicNetworkJobFormResponse: ActionType<(response: CreateJobGenomicNetworkMSAResponse, state: RequestFormState) => { response: CreateJobGenomicNetworkMSAResponse, state: RequestFormState }>;
    createJob: ActionType<(infectedPremiseIds: number[]) => { infectedPremiseIds: number[] }>;
    createJobFormResponse: ActionType<(response: CreateJobMSAResponse, state: RequestFormState) => { response: CreateJobMSAResponse, state: RequestFormState }>;
    deleteJob: ActionType<(msaJobId: number) => { msaJobId: number }>;
    deleteJobFormResponse: ActionType<(response: DeleteJobMSAResponse, state: RequestFormState) => { response: DeleteJobMSAResponse, state: RequestFormState }>;
}

interface IMSAApi extends IBaseCrudApi {
    createJob: ReturnType<typeof createPostRequest>;
    createJobGenomicNetworkMSA: ReturnType<typeof createPostRequest>;
    deleteJob: ReturnType<typeof createDeleteRequest>;
}

interface IMSASelectors extends IBaseCrudSelectors<MSA, IMSASubState> {
    job: (state: any) => IMSASubState["job"];
    createJobState: (state: IMSAState) => IMSASubState["extraStates"]["createJobState"];
    createGenomicNetworkJobState: (state: any) => IMSASubState["extraStates"]["createGenomicNetworkJobState"];
    deleteJobState: (state: any) => IMSASubState["extraStates"]["deleteJobState"];
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const initialMSAState: IMSASubState = {
    job: undefined,
    extraStates: {
        createGenomicNetworkJobState: {
            state: RequestFormState.None
        },
        createJobState: {
            state: RequestFormState.None
        },
        deleteJobState: {
            state: RequestFormState.None
        },
    },
    ...baseCrudInitialState<MSA>()
};

const msaModule = createCrudModule<MSA, undefined, GetMSAResponse, undefined, undefined, undefined, IMSASubState, IMSAActions, IMSAApi, IMSASelectors>(
    'msa',
    undefined,
    GetMSA,
    undefined,
    undefined,
    undefined,
    initialMSAState,
);

// Create Job action

msaModule.actions.createJob = createAction(`@@msa/CREATE_JOB`,
    (infectedPremiseIds: number[]) => ({ infectedPremiseIds })
);

msaModule.actions.createJobFormResponse = createAction(`@@msa/CREATE_JOB_FORM_RESPONSE`,
    (response: CreateJobMSAResponse, state: RequestFormState) => ({ response, state })
);

const createJobReducer = (state: IMSASubState) => update(
    state,
    {
        extraStates: {
            createJobState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const createJobFormResponseReducer = (state: IMSASubState, payload: any, data: ApiJobMSA) => update(
    state,
    {
        job: {
            $set: data
        },
        extraStates: {
            createJobState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

msaModule.reducer.on(msaModule.actions.createJob, (state: IMSASubState) => (
    createJobReducer(state)
));

msaModule.reducer.on(msaModule.actions.createJobFormResponse, (state: IMSASubState, payload) => (
    createJobFormResponseReducer(state, payload, payload.response.msaJob)
));

msaModule.api.createJob = createPostRequest(
    CreateJobMSA,
    (infectedPremiseIds: number[]) => ({
        infectedPremiseIds
    })
);

const createJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(msaModule.actions.createJob.getType()),
        mergeMap(action => {
            return msaModule.api.createJob(action.payload.infectedPremiseIds)
                .pipe(
                    mergeMap(response => of(msaModule.actions.createJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(msaModule.actions.createJobFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

// Create Genomic Network Job action

msaModule.actions.createGenomicNetworkJob = createAction(`@@msa/CREATE_GENOMIC_NETWORK_JOB`,
    (epidemicId: number, msaId: number, startDateTypeId: number) => ({ epidemicId, msaId, startDateTypeId })
);

msaModule.actions.createGenomicNetworkJobFormResponse = createAction(`@@msa/CREATE_GENOMIC_NETWORK_JOB_FORM_RESPONSE`,
    (response: CreateJobGenomicNetworkMSAResponse, state: RequestFormState) => ({ response, state })
);

const createGenomicNetworkJobReducer = (state: IMSASubState) => update(
    state,
    {
        extraStates: {
            createGenomicNetworkJobState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const createGenomicNetworkJobFormResponseReducer = (state: IMSASubState, payload: any, data: ApiJobGenomicNetwork) => update(
    state,
    {
        extraStates: {
            createGenomicNetworkJobState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

msaModule.reducer.on(msaModule.actions.createGenomicNetworkJob, (state: IMSASubState) => (
    createGenomicNetworkJobReducer(state)
));

msaModule.reducer.on(msaModule.actions.createGenomicNetworkJobFormResponse, (state: IMSASubState, payload) => (
    createGenomicNetworkJobFormResponseReducer(state, payload, payload.response.genomicNetworkJob)
));

msaModule.api.createJobGenomicNetworkMSA = createPostRequest(
    CreateJobGenomicNetworkMSA,
    (epidemicId: number, msaId: number, startDateTypeId: number) => ({
        epidemicId,
        msaId,
        startDateTypeId,
    })
);

const createGenomicNetworkJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(msaModule.actions.createGenomicNetworkJob.getType()),
        mergeMap((action: Action<{ epidemicId: number; msaId: number; startDateTypeId: number }, {}>) => {
            return msaModule.api.createJobGenomicNetworkMSA(action.payload.epidemicId, action.payload.msaId, action.payload.startDateTypeId)
                .pipe(
                    mergeMap(response => of(msaModule.actions.createGenomicNetworkJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(msaModule.actions.createGenomicNetworkJobFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

// Delete Job action

msaModule.actions.deleteJob = createAction(`@@msa/DELETE_JOB`,
    (msaJobId: number) => ({ msaJobId })
);

msaModule.actions.deleteJobFormResponse = createAction(`@@msa/DELETE_JOB_FORM_RESPONSE`,
    (response: DeleteJobMSAResponse, state: RequestFormState) => ({ response, state })
);

const deleteJobReducer = (state: IMSASubState) => update(
    state,
    {
        extraStates: {
            deleteJobState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const deleteJobFormResponseReducer = (state: IMSASubState, payload: any) => update(
    state,
    {
        extraStates: {
            deleteJobState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

msaModule.reducer.on(msaModule.actions.deleteJob, (state: IMSASubState) => (
    deleteJobReducer(state)
));

msaModule.reducer.on(msaModule.actions.deleteJobFormResponse, (state: IMSASubState, payload) => (
    deleteJobFormResponseReducer(state, payload)
));

msaModule.api.deleteJob = createDeleteRequest(
    DeleteJobMSA,
    (msaJobId: number) => ({
        msaJobId
    })
);

const deleteJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(msaModule.actions.deleteJob.getType()),
        mergeMap(action =>
            msaModule.api.deleteJob(action.payload.msaJobId)
                .pipe(
                    mergeMap(response => of(msaModule.actions.deleteJobFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(msaModule.actions.deleteJobFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

/* Add additional epics to module */

msaModule.epics = combineEpics(msaModule.epics, createJobEpic, createGenomicNetworkJobEpic, deleteJobEpic);

/* Additional Selectors */

msaModule.selectors.job = (state: IMSAState) => state.msa.job;
msaModule.selectors.createJobState = (state: IMSAState) => state.msa.extraStates.createJobState;
msaModule.selectors.createGenomicNetworkJobState = (state: IMSAState) => state.msa.extraStates.createGenomicNetworkJobState;
msaModule.selectors.deleteJobState = (state: IMSAState) => state.msa.extraStates.deleteJobState;

export default msaModule;