/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving a sequence retrieval job
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { ComplexActionCreator } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ApiJobSequenceRetrieval, CreateJobSequenceRetrieval, CreateJobSequenceRetrievalResponse, GetJobSequenceRetrieval, GetJobSequenceRetrievalResponse } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest } from '../../../helpers/createRequest';
import { ReplaceReturnType } from '../../../types/HelperTypes';
import { RequestFormState } from '../../../types/RequestState';
import { createCrudModuleWithNamedRequestObject, dummyAction, dummyObservable, IBaseCrudActions, IBaseCrudApi, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ISequenceRetrievalState {
    sequenceRetrieval: IBaseCrudState<ApiJobSequenceRetrieval>;
}

interface ISequenceRetrievalActions extends IBaseCrudActions<ApiJobSequenceRetrieval> {
    createJob?: ReplaceReturnType<(timestamp?: string) => { timestamp?: string }, {
        type: string;
        payload: any;
        meta: {};
    }> & ComplexActionCreator<ReturnType<(timestamp?: string) => { timestamp?: string }>, {}>;
    createJobFormResponse?: ReplaceReturnType<(response: CreateJobSequenceRetrievalResponse, state: RequestFormState) => { response: CreateJobSequenceRetrievalResponse, state: RequestFormState }, {
        type: string;
        payload: any;
        meta: {};
    }> & ComplexActionCreator<ReturnType<(response: CreateJobSequenceRetrievalResponse, state: RequestFormState) => { response: CreateJobSequenceRetrievalResponse, state: RequestFormState }>, {}>;
}

interface ISequenceRetrievalApi extends IBaseCrudApi {
    createJob: ReturnType<typeof createPostRequest>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

let sequenceRetrievalModule = createCrudModuleWithNamedRequestObject<ApiJobSequenceRetrieval, undefined, GetJobSequenceRetrievalResponse, undefined, undefined, undefined, IBaseCrudState<ApiJobSequenceRetrieval>, ISequenceRetrievalActions, ISequenceRetrievalApi>(
    'sequenceRetrieval',
    'retrievalJob',
    undefined,
    GetJobSequenceRetrieval,
    undefined,
    undefined,
    undefined,
)

// Create Job action

sequenceRetrievalModule.actions.createJob = createAction(`sequenceRetrieval/CREATE_JOB`,
    (timestamp?: string) => ({ timestamp })
);

sequenceRetrievalModule.actions.createJobFormResponse = createAction(`sequenceRetrieval/CREATE_JOB_FORM_RESPONSE`,
    (response: CreateJobSequenceRetrievalResponse, state: RequestFormState) => ({ response, state })
);

const createJobFormReducer = (state: IBaseCrudState<ApiJobSequenceRetrieval>) => update(
    state,
    {
        states: {
            createState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
)

const createJobFormResponseReducer = (state: IBaseCrudState<ApiJobSequenceRetrieval>, payload: any, data: ApiJobSequenceRetrieval) => update(
    state,
    {
        data: {
            $set: data
        },
        states: {
            createState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

sequenceRetrievalModule.api.createJob = createPostRequest(
    CreateJobSequenceRetrieval,
    (timestamp: string) => ({
        timestamp
    })
);

const createJobEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) => {
    if (sequenceRetrievalModule.actions.createJob) {
        return action$.pipe(
            ofType(sequenceRetrievalModule.actions.createJob.getType()),
            mergeMap(action => {
                return sequenceRetrievalModule.api.createJob(action.payload.timestamp)
                    .pipe(
                        mergeMap(response => {
                            if (sequenceRetrievalModule.actions.createJobFormResponse) {
                                return of(sequenceRetrievalModule.actions.createJobFormResponse(response, RequestFormState.SubmitSuccess));
                            }
                            // #region dummy code
                            // This should never be reached
                            return of(dummyObservable());
                            //#endregion
                        }),
                        catchError(e => {
                            if (sequenceRetrievalModule.actions.createJobFormResponse) {
                                return of(sequenceRetrievalModule.actions.createJobFormResponse(e, RequestFormState.ServerError));
                            }
                            // #region dummy code
                            // This should never be reached
                            return of(dummyObservable());
                            //#endregion
                        })
                    );
            })
        )
    }
    // #region dummy code
    // This should never be reached
    return action$.pipe(
        ofType(dummyAction.getType()),
        mergeMap(() =>
            dummyObservable()
                .pipe(
                    mergeMap(() => of(dummyObservable())),
                    catchError(() => of(dummyObservable()))
                )
        ))
    //#endregion

}

/* Add additional reducers and epics to module */

sequenceRetrievalModule.reducer.on(sequenceRetrievalModule.actions.createJob, (state: IBaseCrudState<ApiJobSequenceRetrieval>) => (
    createJobFormReducer(state)
));

sequenceRetrievalModule.reducer.on(sequenceRetrievalModule.actions.createJobFormResponse, (state: IBaseCrudState<ApiJobSequenceRetrieval>, payload) => (
    createJobFormResponseReducer(state, payload, payload.response.retrievalJob)
));

sequenceRetrievalModule.epics = combineEpics(sequenceRetrievalModule.epics, createJobEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default sequenceRetrievalModule;