
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import materialModule from '../../store/reducers/materials/material';
import { RequestFormState } from '../../types/RequestState';
import MaterialEditForm from './MaterialEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditMaterialParams {
    materialId: string;
}

interface IEditMaterialProps {
    materialId?: number;
    history: History;
    match: match<IEditMaterialParams>;
}

export const EditMaterial: React.FunctionComponent<IEditMaterialProps> = (props) => {
    const materialState = useSelector(state => state.material);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const materialId = props.materialId || (props.match.params ? parseInt(props.match.params.materialId) : undefined);

    useEffect(() => {
        if (materialModule.actions.load) {
            dispatch(materialModule.actions.load(materialId))
        }
    }, [dispatch, materialId]);

    return <>
        <Paper className={classes.root}>
            <Typography variant="h4" component="h4">Edit Material</Typography>
            <MaterialEditForm
                editMaterial={materialState.data}
                history={props.history}
                loadingMaterial={materialState.states.loadState.state === RequestFormState.Pending && !materialState.data}
                url={props.match.url}
            />
        </Paper>
    </>
}
