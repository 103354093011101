/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMutators from 'final-form-arrays';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import * as Yup from 'yup';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as SpreadDtos from '../../dtos/Spread.dtos';
import livestockModule from '../../store/reducers/livestock/livestock';
import { livestocksHooks } from '../../store/reducers/livestock/livestockSearch';
import { speciesSearchHooks } from '../../store/reducers/species/speciesSearch';
import { RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import NumericFieldWrapper from '../forms/FinalFormControls/NumericFieldWrapper';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        headingPadding: {
            height: "100%", paddingTop: 40,
        },
    }),
);

interface ILivestockEditFormProps {
    editLivestock?: SpreadDtos.Livestock;
    epidemicId?: number;
    history: History;
    loadingLivestock?: boolean;
    url: string;
}

interface ILivestockFormSchema extends Partial<SpreadDtos.Livestock> {
    epidemicId: number;
    speciesId: number;
    transmissionParameter: string;
    latentPeriod?: number;
    infectiousPeriod1?: number;
    infectiousPeriod2?: number;
    relativeExcretionInL1: string;
    relativeExcretionInL2: string;
    windDispersionThreshold: string;
}

const livestockSchema = Yup.object<ILivestockFormSchema>({
    epidemicId: Yup.number().label('Epidemic').required(),
    speciesId: Yup.number().label('Species').required(),
    transmissionParameter: Yup.string().label('Transmission Parameter'),
    latentPeriod: Yup.number().label('Latent Period'),
    infectiousPeriod1: Yup.number().label('Infectious Period 1'),
    infectiousPeriod2: Yup.number().label('Infectious Period 2'),
    relativeExcretionInL1: Yup.string().label('Relative Excretion 1'),
    relativeExcretionInL2: Yup.string().label('Relative Excretion 2'),
    windDispersionThreshold: Yup.string().label('Wind Dispersion Threshold'),
});

const validation = Yup.object().shape({
    object: livestockSchema.notRequired(),
})

const LivestockEditForm: React.FunctionComponent<ILivestockEditFormProps> = ({
    editLivestock,
    epidemicId,
    history,
    loadingLivestock,
    url,
}) => {
    const classes = useStyles();
    const [speciesData, , , speciesRequestState] = speciesSearchHooks.useSearch();

    const speciesOptions = speciesData && speciesData.results && speciesData.results.length > 0 ?
        speciesData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];

    const specOpsLivestock = useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])
    const [targetLivestockData, , , targetLivestockRequestState] = livestocksHooks.useSearch(specOpsLivestock);

    const targetLivestockOptions = targetLivestockData && targetLivestockData.results && targetLivestockData.results.length > 0 ?
        targetLivestockData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.speciesName}
            </MenuItem>
        )) :
        [];

    const epidemicIdParsed = editLivestock ? editLivestock.epidemicId : epidemicId && epidemicId;

    const editMode = editLivestock !== undefined;

    const wrappedObject = editMode ? {
        object: editLivestock,
    } : undefined;

    return <EditForm
        actions={livestockModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear, ...arrayMutators }}
        loading={!!loadingLivestock}
        selectors={livestockModule.selectors}
        successRouteCallback={(d) => d ? `/epidemic/${epidemicIdParsed}/data/edit` : '/404'}
        url={url}
        validate={validation}
    >{() => (
        <>
            <Field
                required
                disabled
                component={'input'}
                name="object.epidemicId"
                defaultValue={epidemicIdParsed}
                type={'hidden'}
            />
            <Grid item xs={12}>
                {
                    (speciesRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            required
                            disabled={false}
                            name="object.speciesId"
                            component={SelectWrapper}
                            formControlProps={{
                                fullWidth: true,
                                required: true
                            }}
                            label="Species"
                        >
                            {speciesOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.speciesId"
                            component={TextFieldWrapper}
                            type="text"
                            label="Species"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.transmissionParameter"
                    component={TextFieldWrapper}
                    type="text"
                    label="Transmission Parameter"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.latentPeriod"
                    component={TextFieldWrapper}
                    type="number"
                    label="Latent Period"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.infectiousPeriod1"
                    component={TextFieldWrapper}
                    type="number"
                    label="Infectious Period 1"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.infectiousPeriod2"
                    component={TextFieldWrapper}
                    type="number"
                    label="Infectious Period 2"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.relativeExcretionInL1"
                    component={TextFieldWrapper}
                    type="text"
                    label="Relative Excretion In L1"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.relativeExcretionInL2"
                    component={TextFieldWrapper}
                    type="text"
                    label="Relative Excretion In L2"
                />
            </Grid>
            <Grid item xs={12}>
                <Field
                    fullWidth
                    name="object.windDispersionThreshold"
                    component={TextFieldWrapper}
                    type="text"
                    label="Wind Dispersion Threshold"
                />
            </Grid>
            <Grid xs={12}>
                <Typography variant="h5" component="h5" className={classes.headingPadding}>Transmission coefficient</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FieldArray name="object.beta">
                    {({ fields }) => (
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Target Livestock</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((name, index) => (
                                        <TableRow key={name}>
                                            <TableCell>
                                                {
                                                    (targetLivestockRequestState.state !== RequestState.Pending) ?
                                                        <Field
                                                            fullWidth
                                                            required
                                                            disabled={false}
                                                            name={`${name}.targetLivestockId`}
                                                            component={SelectWrapper}
                                                            formControlProps={{
                                                                fullWidth: true,
                                                                required: true
                                                            }}
                                                        >
                                                            {targetLivestockOptions}
                                                        </Field> :
                                                        <Field
                                                            fullWidth
                                                            disabled
                                                            name={`${name}.value`}
                                                            component={NumericFieldWrapper}
                                                            type="text"
                                                            defaultValue="Loading..."
                                                        />
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    fullWidth
                                                    name={`${name}.value`}
                                                    component={NumericFieldWrapper}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <button type="button" onClick={() => fields.remove(index)}>
                                                    <DeleteIcon className={classes.buttonSubmitIcon} />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <button type="button" onClick={() => fields.push({})} >
                                <AddBoxIcon className={classes.buttonSubmitIcon} />
                                Add
                            </button>
                        </div>
                    )}
                </FieldArray>
            </Grid>
            <Grid xs={12}>
                <Typography variant="h5" component="h5" className={classes.headingPadding}>Excretion Log<sub>10</sub> TCID<sub>50</sub> virus /24 hr</Typography>
            </Grid>
            <Grid item lg={6} xs={12}>
                <FieldArray name="object.log10Tcid50">
                    {({ fields }) => (
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Day</TableCell>
                                        <TableCell align="right">Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fields.map((name, index) => (
                                        <TableRow key={name}>
                                            <TableCell>
                                                <Field
                                                    fullWidth
                                                    name={`${name}.day`}
                                                    component={NumericFieldWrapper}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    fullWidth
                                                    name={`${name}.value`}
                                                    component={NumericFieldWrapper}
                                                    type="text"
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <button type="button" onClick={() => fields.remove(index)}>
                                                    <DeleteIcon className={classes.buttonSubmitIcon} />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <button type="button" onClick={() => fields.push({})} >
                                <AddBoxIcon className={classes.buttonSubmitIcon} />
                                Add
                            </button>
                        </div>
                    )}
                </FieldArray>
            </Grid>
        </>
    )}
    </EditForm>
};

export default LivestockEditForm;