/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { CircularProgress, MenuItem } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import { match, Redirect } from 'react-router-dom';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import genomicNetworkModule from '../../../store/reducers/genomic/genomicNetwork';
import msaModule from '../../../store/reducers/msa/msa';
import { RequestFormState } from '../../../types/RequestState';
import { HasPermission } from '../../common/HasPermission';
import CommonForm from '../../forms/CommonForm';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import { SpreadMSAViewer } from './MSAViewer';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginRight: theme.spacing(1),
        },
        buttonBox: {},
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        imageWrapper: {
            textAlign: 'center'
        },
        image: {
            width: '75%',
        },
        root: {
            margin: theme.spacing(3, 2),
            padding: theme.spacing(3, 2),
        },
        formItem: {
            margin: theme.spacing(2, 3),
        },
        loadingLabel: {
            marginLeft: theme.spacing(1)
        },
    }),
);

interface IViewMSAParams {
    epidemicId?: string;
    msaId?: string;
}

interface IViewMSAProps {
    className?: string;
    epidemicId?: number;
    msaId?: number;
    history: History;
    match: match<IViewMSAParams>;
}

function createData(attribute: string, value: number) {
    return { attribute, value };
}

export const ViewMSA: React.FunctionComponent<IViewMSAProps> = ({ className, epidemicId: epidemicIdProp, msaId: msaIdProp, history, match }) => {
    const classes = useStyles();
    const epidemicId = React.useMemo(() => epidemicIdProp || (match.params.epidemicId ? parseInt(match.params.epidemicId) : -1), [epidemicIdProp, match.params.epidemicId]);
    const msaId = React.useMemo(() => msaIdProp || (match.params.msaId ? parseInt(match.params.msaId) : -1), [msaIdProp, match.params.msaId]);

    const [data, loadState] = msaModule.hooks.useLoad(msaId);
    const createGenomicNetworkJobState = useSelector(state => msaModule.selectors.createGenomicNetworkJobState(state));

    const sequences = loadState.state !== RequestFormState.Pending && data ?
        data.sequences :
        [];

    const rows = [
        createData('Number of sequences in alignment', sequences.length),
        //createData('Number of sequences with insertions/deletions', 1),
    ];

    const [width, setWidth] = React.useState(1000);
    const [scheme, setScheme] = React.useState(0);
    const schemes: any = ['zappo', 'nucleotide', 'clustal2', 'mae', 'taylor'];

    const redirectComponent = createGenomicNetworkJobState.state === RequestFormState.SubmitSuccess ?
        <Redirect push to={routeBackToPath(match.url, 4, "genomicNetwork")} /> :
        undefined;

    const createState = useSelector(genomicNetworkModule.selectors.createState);

    const epidemic = useSelector(state => state.epidemic.data);

    const submitSuccess = createState.state === RequestFormState.SubmitSuccess;
    const pending = createState.state === RequestFormState.Pending;

    const startAction = msaModule.actions.createGenomicNetworkJob;
    const resolveAction = msaModule.actions.createGenomicNetworkJobFormResponse;
    const rejectAction = msaModule.actions.createGenomicNetworkJobFormResponse;

    // Quick check to make sure relevant actions are defined
    if (!startAction || !resolveAction || !rejectAction) {
        return <div>ERROR: Server actions not defined</div>;
    }

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        const toRoute: string = data ? `${routeBackToPath(match.url)}` : '/404';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    return <Paper className={classes.root}>
        {redirectComponent}
        <Typography variant="h4" component="h4">MSA {loadState.state !== RequestFormState.Pending ? data && data.id : <Skeleton />}</Typography>
        <Grid container alignItems="flex-start" spacing={2}>
            <Grid item lg={12} xs={12}>
                <div className={classes.imageWrapper}>
                    {/* <div>
                        Width: {width}
                        <button onClick={() => setWidth(width + 50)}>More</button>
                        <button onClick={() => setWidth(width - 50)}>Less</button>
                    </div>
                    <div>
                        Scheme: {schemes[scheme]}
                        <button onClick={() => { scheme === schemes.length - 1 ? setScheme(0) : setScheme(scheme + 1) }}>Next</button>
                        <button onClick={() => { scheme === 0 ? setScheme(schemes.length - 1) : setScheme(scheme - 1) }}>Last</button>
                    </div>
                    */}
                    <SpreadMSAViewer
                        sequences={sequences}
                        colorScheme={schemes[scheme]}
                        height={30 + sequences.length * 20}
                        labelStyle={{
                            marginRight: '10px',
                            textAlign: 'right',
                        }}
                        sequenceOverflowX="scroll"
                        sequenceScrollBarPositionX="bottom"
                        width={width}
                    />
                </div>
            </Grid>

            <Grid item lg={4} xs={12}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Attribute</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow key={row.attribute}>
                                <TableCell component="th" scope="row">
                                    {row.attribute}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            <HasPermission permission="CreateGenomicNetworkJob" objectId={epidemicId}>
                <Grid item lg={12} xs={12}>
                    <Typography variant="h5" component="h5">Create Genomic Network</Typography>
                    {successRedirectComponent}
                    <CommonForm
                        start={startAction}
                        resolve={resolveAction}
                        reject={rejectAction}
                        history={history}
                        submitSuccess={submitSuccess}
                        labelSave="Queue"
                    >
                        {() => <>
                            <Grid item xs={12}>
                                <Box className={classes.formItem}>
                                    <Field
                                        required
                                        disabled
                                        component={'input'}
                                        name="epidemicId"
                                        defaultValue={epidemicId}
                                        type={'hidden'}
                                    />
                                    <Field
                                        required
                                        disabled
                                        component={'input'}
                                        name="msaId"
                                        defaultValue={msaId}
                                        type={'hidden'}
                                    />
                                    <Field
                                        name="startDateTypeId"
                                        component={SelectWrapper}
                                        defaultValue={''}
                                        formControlProps={{
                                            className: classes.formControl,
                                            fullWidth: true,
                                            required: false
                                        }}
                                        label="Start Dates To Use"
                                    >
                                        {
                                            epidemic && epidemic.dateTypes
                                                .filter(dateType => dateType.isPresumedInfectionDate || dateType.isStartOfInfectiousPeriodDate)
                                                .map(dateType => <MenuItem value={dateType.id}>{dateType.name}</MenuItem>)
                                        }

                                    </Field>
                                </Box>
                            </Grid>
                        </>}
                    </CommonForm>
                    {
                        pending && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                            <CircularProgress />
                            <Typography className={classes.loadingLabel} component="h6" variant="h6">Dispatching job</Typography>
                        </Box>
                    }
                </Grid>
            </HasPermission>
            {/*
            <Grid item xs={12}>
                <Typography variant={'h6'}>MSA Editing</Typography>
            </Grid>
            <Grid item lg={12} xs={12}>
                <Button
                    aria-label="Download MSA for editing"
                    color="primary"
                    variant="contained"
                    startIcon={<CloudDownloadIcon />}
                >
                    Download MSA for editing
                </Button>
            </Grid>
            <Grid item lg={12} xs={12}>
                <Button
                    aria-label="Upload edited MSA"
                    color="primary"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                >
                    Upload edited MSA
                </Button>
            </Grid>
            <Grid item xs={12} />
            <Grid item lg={2} xs={12}>
                <MockRadioButtons
                    className={classes.formControl}
                    fieldName={'align_assessment'}
                    title={'MSA Assessment'}
                    items={[
                        {
                            key: '1',
                            label: 'Pass'
                        },
                        {
                            key: '2',
                            label: 'Fail'
                        }
                    ]}
                />
            </Grid>
            <Grid item lg={10} xs={12}>
                <MockMultiLineText
                    className={classes.formControl}
                    fieldName={'align_comments'}
                    title='Comment'
                    multiline
                />
            </Grid>
            <Grid item xs={12} />
            */}
        </Grid>
    </Paper>
}