
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useSelector } from '../../hooks/useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IHasPermissionProps {
    permission: string;
    objectId?: number;
}

/**
 * This helper checks whether the current person passes a given permission check.
 * 
 * @returns The component if it passes the permission check
 */
export const HasPermission: React.FunctionComponent<IHasPermissionProps> = (props) => {
    const personState = useSelector(state => state.authenticatedPerson.data);
    const person = personState ? personState : undefined;

    let allowed: boolean = false;

    if (person) {
        // check if they have the permission for all objects or is it a global permission
        allowed = person.permissions.find(p => p === props.permission) !== undefined;

        // otherwise, check they have the individual permission
        if (!allowed && props.objectId) {
            allowed = person.epidemicPermissions[props.objectId] && person.epidemicPermissions[props.objectId].find(p => p === props.permission) !== undefined;
        }

        if (!allowed && props.objectId) {
            allowed = person.groupPermissions[props.objectId] && person.groupPermissions[props.objectId].find(p => p === props.permission) !== undefined;
        }
    }

    if (allowed && props.children) {
        return <>{props.children}</>
    }

    return null;
}