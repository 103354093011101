/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { InfectedPremise } from '../../dtos/Spread.dtos';
import InfectedPremiseEditForm from './InfectedPremiseEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateInfectedPremiseParams {
    clusterId: string;
    epidemicId: string;
    subClusterId: string;
}

interface ICreateInfectedPremiseProps {
    clusterId?: number;
    epidemicId?: number;
    history: History;
    match: match<ICreateInfectedPremiseParams>;
    subClusterId?: number;
}

export const CreateInfectedPremise: React.FunctionComponent<ICreateInfectedPremiseProps> = (props) => {
    const classes = useStyles();

    const epidemicId = props.epidemicId || (props.match.params ? parseInt(props.match.params.epidemicId) : undefined)
    const clusterId = props.clusterId || (props.match.params ? parseInt(props.match.params.clusterId) : undefined);
    const subClusterId = props.subClusterId || (props.match.params ? parseInt(props.match.params.subClusterId) : undefined);

    return <Paper className={classes.root}>
        <InfectedPremiseEditForm
            editInfectedPremise={new InfectedPremise({
                clusterId,
                epidemicId,
                subClusterId,
            })}
            history={props.history}
            url={props.match.url}
        />
    </Paper>
}