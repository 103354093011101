/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import { useDispatch } from 'react-redux';
import { match, Redirect } from 'react-router-dom';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import { clustersHooks } from '../../../store/reducers/clusters/clusterSearch';
import genomicNetworkModule from '../../../store/reducers/genomic/genomicNetwork';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';
import InfectedPremiseCheckboxSelect from '../../forms/InfectedPremiseCheckboxSelect/InfectedPremiseCheckboxSelect';
import { changeValue, clear } from '../../forms/mutators';
import SubClusterSelect from '../../forms/SubClusterSelect/SubClusterSelect';
import { IGenomicNetworkFormSchema } from './types';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        formItem: {
            margin: theme.spacing(2, 3),
        },
        loadingLabel: {
            marginLeft: theme.spacing(1)
        },
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

const validation = Yup.object<IGenomicNetworkFormSchema>({
    clusterId: Yup.number().label("Cluster")
        .notRequired(),
    subClusterId: Yup.number().label("Sub-Cluster")
        .notRequired(),
    infectedPremiseIds: Yup.mixed().label("Infected Premises")
        .notRequired(),
    allInfectedPremisesOnEpidemicId: Yup.number().label("Epidemic")
        .notRequired(),
    startDateTypeId: Yup.number().label('Emission start date type'),
});

interface ICreateGenomicNetworkFormProps {
    className?: string;
    epidemicId?: number;
    history: History;
    match: match<any>;
}

export const CreateGenomicNetworkForm: React.FunctionComponent<ICreateGenomicNetworkFormProps> = ({ epidemicId: epidemicIdProp, history, match }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const createState = useSelector(genomicNetworkModule.selectors.createState);
    const data = useSelector(genomicNetworkModule.selectors.data);

    const epidemicId = epidemicIdProp || (match.params ? parseInt(match.params.epidemicId) : undefined);

    const epidemic = useSelector(state => state.epidemic.data);

    const submitSuccess = createState.state === RequestFormState.SubmitSuccess;
    const pending = createState.state === RequestFormState.Pending;

    const clusterSpecOps = React.useMemo(() => ({ epidemicId: epidemicId }), [epidemicId]);
    const clusterPaginateOps = React.useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [clusterData, , ,] = clustersHooks.useSearch(clusterSpecOps, clusterPaginateOps);

    const clusterOptions = clusterData && clusterData.results && clusterData.results.length > 0 ?
        clusterData.results.map((c =>
            <MenuItem
                key={c.id}
                value={c.id}
            >
                {c.name}: {
                    c.clusterDateSet && c.clusterDateSet.earliestStartOfInfectiousPeriodDate && c.clusterDateSet.latestEndOfInfectiousPeriodDate
                        ? `${format(toDate(c.clusterDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.clusterDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)}`
                        : '(Insufficient date data)'
                }
            </MenuItem>
        )) :
        [];

    React.useEffect(() => {
        return function cleanup() {
            dispatch(genomicNetworkModule.actions.clearFormState());
        }
    }, [genomicNetworkModule.actions, dispatch]);

    const startAction = genomicNetworkModule.actions.createJob;
    const resolveAction = genomicNetworkModule.actions.createJobFormResponse;
    const rejectAction = genomicNetworkModule.actions.createJobFormResponse;

    // Quick check to make sure relevant actions are defined
    if (!startAction || !resolveAction || !rejectAction) {
        return <div>ERROR: Server actions not defined</div>;
    }

    let successRedirectComponent: JSX.Element | null = null;

    if (submitSuccess) {
        const toRoute: string = data ? `${routeBackToPath(match.url)}` : '/404';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    let initialValues = {
        clusterId: -1 as number | undefined,
        subClusterId: undefined as number | undefined,
        infectedPremiseIds: [] as number[],
    };

    return <Paper className={classes.root}>
        {successRedirectComponent}
        <Typography variant="h4" component="h4">Create Genomic Network</Typography>
        <CommonForm
            start={startAction}
            resolve={resolveAction}
            reject={rejectAction}
            history={history}
            mutators={{
                clear: clear,
                changeValue: changeValue
            }}
            submitSuccess={submitSuccess}
            initialValues={initialValues}
            validate={validation}
            labelSave="Queue"
        >
            {() => <>
                <Grid item xs={12}>
                    <FormSpy>
                        {props => <>
                            <OnChange name={"clusterId"}>
                                {(value: any) => {
                                    props.form.mutators.clear('subClusterId');
                                    props.form.mutators.clear('infectedPremiseIds');

                                    if (value != 0) {
                                        props.form.mutators.clear('allInfectedPremisesOnEpidemicId');
                                    } else {
                                        props.form.change('allInfectedPremisesOnEpidemicId', epidemicId)
                                    }
                                }}
                            </OnChange>
                            <OnChange name={"subClusterId"}>
                                {(value: any) => {
                                    props.form.mutators.clear('infectedPremiseIds');
                                }}
                            </OnChange>
                        </>}
                    </FormSpy>
                    <Box className={classes.formItem}>
                        <Field
                            name="clusterId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Cluster"
                            format={(value) => value === -1 ? undefined : (value || 0)}
                            parse={(value) => value || undefined}
                        >
                            <MenuItem value={-1}></MenuItem>
                            <MenuItem value={0}>All</MenuItem>
                            {clusterOptions}
                        </Field>
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="clusterId" render={
                            (clusterProps) => (
                                <Field name="subClusterId" render={
                                    subClusterProps => (
                                        <SubClusterSelect
                                            clusterId={Number(clusterProps.input.value) || undefined}
                                            epidemicId={epidemicId}
                                            formControlProps={{
                                                className: classes.formControl,
                                                fullWidth: true,
                                                required: false
                                            }}
                                            disabled={Number(clusterProps.input.value) == -1}
                                            input={subClusterProps.input}
                                            label="Sub-Cluster"
                                            meta={subClusterProps.meta}
                                        />
                                    )
                                } />
                            )
                        } />
                    </Box>
                    <Box className={classes.formItem}>
                        <Field name="clusterId" render={
                            (clusterProps) => (
                                <Field name="subClusterId" render={
                                    (subClusterProps) => (
                                        (clusterProps.input.value && clusterProps.input.value !== '')
                                            || (subClusterProps.input.value && subClusterProps.input.value !== '') ?
                                            <InfectedPremiseCheckboxSelect
                                                clusterId={clusterProps.input.value === '' ? undefined : clusterProps.input.value}
                                                epidemicId={epidemicId}
                                                subClusterId={subClusterProps.input.value === '' ? undefined : subClusterProps.input.value}
                                                name={"infectedPremiseIds"}
                                                filter={(ip) => ip.hasConsensusSequence}
                                                noResultsMessage={'No eligible Infected Premises found - try a different cluster. To be eligible an Infected Premises must be attached to a consensus sequence.'}
                                            />
                                            : null
                                    )
                                } />
                            )
                        } />
                    </Box>
                    {/*}
                    <Box hidden className={classes.formItem}>
                        <MockSelect
                            className={classes.formControl}
                            fieldName={'sequenceFilter'}
                            title={'Sequence Filter'}
                            defaultKey={'-1'}
                            items={[
                                {
                                    key: '1',
                                    label: 'Perfect data'
                                },
                                {
                                    key: '2',
                                    label: 'Allow up to 10% missing in constant regions'
                                },
                                {
                                    key: '3',
                                    label: 'Allow up to 10% missing anywhere'
                                },
                                {
                                    key: '4',
                                    label: 'Allow any data'
                                }
                            ]}
                        />
                    </Box>
                    <Box hidden className={classes.formItem}>
                        <MockSelect
                            className={classes.formControl}
                            fieldName={'metadataFilter'}
                            title={'Metadata Filter'}
                            defaultKey={'-1'}
                            items={[
                                {
                                    key: '11',
                                    label: 'Perfect data'
                                },
                                {
                                    key: '12',
                                    label: 'Allow missing dates'
                                },
                                {
                                    key: '13',
                                    label: 'Allow missing species'
                                },
                                {
                                    key: '14',
                                    label: 'Allow any data'
                                }
                            ]}
                        />
                    </Box>
                    */}
                    <Box className={classes.formItem}>
                        <FormLabel>Transmission Model</FormLabel>
                        <FormGroup className={classes.formGroup}>
                            <FormControlLabel
                                disabled
                                key="23"
                                control={<Checkbox
                                    checked
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />}
                                label="SCOTTI"
                            />
                            <FormControlLabel
                                disabled
                                key="21"
                                control={<Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />}
                                label="TCS"
                            />
                            <FormControlLabel
                                disabled
                                key="22"
                                control={<Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                />}
                                label="Outbreaker"
                            />
                        </FormGroup>
                    </Box>
                    <Box className={classes.formItem}>
                        <Field
                            name="startDateTypeId"
                            component={SelectWrapper}
                            defaultValue={''}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: false
                            }}
                            label="Start Dates To Use"
                        >
                            {
                                epidemic && epidemic.dateTypes
                                    .filter(dateType => dateType.isPresumedInfectionDate || dateType.isStartOfInfectiousPeriodDate)
                                    .map(dateType => <MenuItem value={dateType.id}>{dateType.name}</MenuItem>)
                            }

                        </Field>
                    </Box>
                </Grid>
            </>}
        </CommonForm>
        {
            pending && <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Typography className={classes.loadingLabel} component="h6" variant="h6">Dispatching job</Typography>
            </Box>
        }
    </Paper>
}
