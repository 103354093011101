/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import InstrumentModule from '../../store/reducers/instruments/instrument';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IInstrumentEditFormProps {
    editInstrument?: SpreadDtos.Instrument;
    history: History;
    loadingInstrument?: boolean;
    url: string;
}

interface IInstrumentFormSchema extends Partial<SpreadDtos.Instrument> {
    name: string;
}

const InstrumentSchema = Yup.object<IInstrumentFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: InstrumentSchema.notRequired()
})


const InstrumentEditForm: React.FunctionComponent<IInstrumentEditFormProps> = ({
    editInstrument,
    history,
    loadingInstrument,
    url,
}) => {
    const editMode = editInstrument !== undefined;

    const wrappedObject = editMode ? {
        object: editInstrument
    } : undefined;

    return <EditForm
        actions={InstrumentModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingInstrument}
        selectors={InstrumentModule.selectors}
        successRouteCallback={(d) => d ? `/admin/instrument` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default InstrumentEditForm;