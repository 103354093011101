/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Checkbox, ListItemText, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import { isNumber } from 'util';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import { RequestState } from '../../types/RequestState';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import diseaseClassificationModule from '../../store/reducers/diseaseClassifications/diseaseClassification';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import { classificationSearchHooks } from '../../store/reducers/classifications/classificationSearch';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IDiseaseClassificationEditFormProps {
    editDiseaseClassification?: SpreadDtos.DiseaseClassification;
    disease?: SpreadDtos.Disease;
    history: History;
    loadingDiseaseClassification?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IDiseaseClassificationFormSchema extends Partial<SpreadDtos.DiseaseClassification> {
    value: string;
    classificationId: number;
}

const diseaseClassificationSchema = Yup.object<IDiseaseClassificationFormSchema>().shape({
    diseaseId: Yup.number().label('Disease').required(),
    value: Yup.string().label('Value').required(),
    classificationId: Yup.number().label('Classification').required(),
});

const validation = Yup.object().shape({
    object: diseaseClassificationSchema.notRequired()
})


const DiseaseClassificationEditForm: React.FunctionComponent<IDiseaseClassificationEditFormProps> = ({
    editDiseaseClassification,
    disease,
    history,
    loadingDiseaseClassification,
    url,
}) => {
    const diseaseId = editDiseaseClassification ? editDiseaseClassification.diseaseId : disease && disease.id;
    const editMode = editDiseaseClassification !== undefined;

    const wrappedObject = editMode ? {
        object: editDiseaseClassification
    } : undefined;
    const classes = useStyles({});

    // setup classifications settings
    const [classificationId, setClassificationId] = React.useState(undefined as number | undefined);

    if (!classificationId && editDiseaseClassification && editDiseaseClassification.classificationId) {
        setClassificationId(editDiseaseClassification.classificationId);
    }

    const updateClassificationId = (classificationId: number | undefined) => {
        setClassificationId(classificationId);
    }

    const [classificationData, , , classificationRequestState] = classificationSearchHooks.useSearch();

    const classificationOptions = classificationData && classificationData.results && classificationData.results.length > 0 ?
        classificationData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];
    // ~ classifations

    return <EditForm
        actions={diseaseClassificationModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingDiseaseClassification}
        selectors={diseaseClassificationModule.selectors}
        successRouteCallback={(d) => d ? `/admin/disease/${diseaseId}` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <TextField
                label={'Disease'}
                disabled={true}
                fullWidth
                name={"object.disease"}
                value={disease ? disease.name : ""}
            />
        </Grid>
        <Grid item xs={12}>
            {
                (classificationRequestState.state !== RequestState.Pending) ?
                    <Field
                        fullWidth
                        required
                        disabled={false}
                        name="object.classificationId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Classification"
                    >
                        {classificationOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.classificationIdLoading"
                        component={TextFieldWrapper}
                        type="text"
                        label="Classification"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <FormSpy>
            {props => <Field
                name="object.classificationId"
                subscription={{ value: true }}
                render={field => {
                    const value = field.input.value;
                    if (isNumber(value) && value > 0 && value !== classificationId) {
                        updateClassificationId(value);
                    }
                    return null;
                }}
            />}
        </FormSpy>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.value"
                component={TextFieldWrapper}
                type="text"
                label="Value"
            />
        </Grid>
    </>}
    </EditForm>
};


export default DiseaseClassificationEditForm;