/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for registering a new person.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';

import { Epic, ofType, combineEpics } from 'redux-observable';

import { of, from } from 'rxjs';

import { mergeMap, catchError } from 'rxjs/operators';

import { JsonServiceClient } from '@servicestack/client';

import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { RequestState } from '../../../types/RequestState';
import { IRequestState } from '../../../types/IRequestState';
import { authenticatedPersonActions } from './authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialRegisterPersonState {
    registerState: IRequestState;
}

export interface IRegisterPersonState {
    registerPerson: IInitialRegisterPersonState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@register_person';

export const registerPersonTypes = {
    REGISTER: `${typeNamespace}/REGISTER`,
    REGISTER_SUCCESS: `${typeNamespace}/REGISTER_SUCCESS`,
    REGISTER_FAILURE: `${typeNamespace}/REGISTER_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialRegisterPersonState = {
    registerState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const registerPersonReducer = createReducer<IInitialRegisterPersonState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const registerPersonActions = {
    /**
     * This action begins the request to register a new person
     */
    register: createAction(registerPersonTypes.REGISTER,
        (token: string, email: string, password: string, displayName: string) => ({
            token,
            email,
            password,
            displayName
        })
    ),
    /**
     * This action fires after a successful person register request
     */
    registerSuccess: createAction(registerPersonTypes.REGISTER_SUCCESS,
        (response: SpreadDtos.RegisterResponse) => ({
            response
        })
    ),
    /**
     * This action stores the error in the register person state after a 
     * failed register request
     */
    registerFailure: createAction(registerPersonTypes.REGISTER_FAILURE,
        (response: SpreadDtos.RegisterResponse) => ({
            response
        }),
    ),
    /**
     * This action clears the entire register person state.
     */
    clear: createAction(registerPersonTypes.CLEAR, () => ({})),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Register Reducers
registerPersonReducer.on(registerPersonActions.register, (state) => (
    update(
        state,
        {
            registerState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

registerPersonReducer.on(registerPersonActions.registerSuccess, (state) => (
    update(
        state,
        {
            registerState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

registerPersonReducer.on(registerPersonActions.registerFailure, (state, payload) => (
    update(
        state,
        {
            registerState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
registerPersonReducer.on(registerPersonActions.clear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const registerPersonsApi = {
    register: (token: string, email: string, password: string, displayName: string) => {
        const client = new JsonServiceClient('');
        let request = new SpreadDtos.Register();

        request.meta = {
            token: token
        };

        request.autoLogin = true;
        request.email = email;
        request.password = password;

        request.displayName = displayName;

        const promise = client.post(request);
        return from(promise);
    },
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const registerEpic: Epic<ReturnType<typeof registerPersonActions.register>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(registerPersonActions.register.getType()),
    mergeMap(action =>
        registerPersonsApi.register(
            action.payload.token,
            action.payload.email,
            action.payload.password,
            action.payload.displayName
        )
            .pipe(
                mergeMap(response =>
                    of(
                        registerPersonActions.registerSuccess(response),
                        authenticatedPersonActions.auth()
                    )
                ),
                catchError(e => of(registerPersonActions.registerFailure(e)))
            )
    ));

export const registerPersonEpics = combineEpics(registerEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default registerPersonReducer;