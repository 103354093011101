/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { Checkbox, ListItemText, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Field, FormSpy } from 'react-final-form';
import { isNumber } from 'util';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import { RequestState } from '../../types/RequestState';
import diseaseModule from '../../store/reducers/diseases/disease';
import { RequestFormState } from '../../types/RequestState';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import { pathogenSearchHooks } from '../../store/reducers/pathogens/pathogenSearch';


/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IDiseaseEditFormProps {
    editDisease?: SpreadDtos.Disease;
    history: History;
    loadingDisease?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IDiseaseFormSchema extends Partial<SpreadDtos.Disease> {
    name: string;
    pathogenId: number;
}

const diseaseSchema = Yup.object<IDiseaseFormSchema>().shape({
    name: Yup.string().label('Name')
        .required(),
    pathogenId: Yup.number().label('Pathogen')
        .required(),
});

const validation = Yup.object().shape({
    object: diseaseSchema.notRequired()
})


const DiseaseEditForm: React.FunctionComponent<IDiseaseEditFormProps> = ({
    editDisease,
    history,
    loadingDisease,
    url,
}) => {
    const editMode = editDisease !== undefined;
    const wrappedObject = editMode ? {
        object: editDisease
    } : undefined;
    
    const classes = useStyles({});
    const dispatch = useDispatch();

    // Setup the pathogen options
    const [pathogenId, setPathogenId] = React.useState(undefined as number | undefined);
    
    if (!pathogenId && editDisease && editDisease.pathogenId) {
        setPathogenId(editDisease.pathogenId);
    }

    const updatePathogenId = (pathogenId: number | undefined) => {
        setPathogenId(pathogenId);
    }

    const [pathogenData, , , pathogenRequestState] = pathogenSearchHooks.useSearch();
    
    const pathogenOptions = pathogenData && pathogenData.results && pathogenData.results.length > 0 ?
        pathogenData.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.name}
            </MenuItem>
        )) :
        [];
    // ~ Setup pathogen options

    return <EditForm
        actions={diseaseModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingDisease}
        selectors={diseaseModule.selectors}
        successRouteCallback={(d) => d ? `/admin/disease` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
        <Grid item xs={12}>
                {
                    (pathogenRequestState.state !== RequestState.Pending) ?
                        <Field
                            fullWidth
                            required
                            disabled={false}
                            name="object.pathogenId"
                            component={SelectWrapper}
                            formControlProps={{
                                className: classes.formControl,
                                fullWidth: true,
                                required: true
                            }}
                            label="Pathogen"
                        >
                            {pathogenOptions}
                        </Field> :
                        <Field
                            fullWidth
                            disabled
                            name="object.pathogenIdLoading"
                            component={TextFieldWrapper}
                            type="text"
                            label="Pathogen"
                            defaultValue="Loading..."
                        />
                }
            </Grid>
            <FormSpy>
                {props => <Field
                    name="object.pathogenId"
                    subscription={{ value: true }}
                    render={field => {
                        const value = field.input.value;
                        if (isNumber(value) && value > 0 && value !== pathogenId) {
                            updatePathogenId(value);
                        }
                        return null;
                    }}
                />}
            </FormSpy>
    </>}
    </EditForm>
};


export default DiseaseEditForm;