/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import EmailIcon from '@material-ui/icons/Email';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { match, Redirect } from 'react-router';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from "../../dtos/Spread.dtos";
import { routeBackToPath } from '../../helpers/routeHelpers';
import { useSelector } from '../../hooks/useTypedSelector';
import { passwordResetActions, passwordResetSelectors } from '../../store/reducers/person/passwordReset';
import { RequestFormState } from '../../types/RequestState';
import AsyncForm from '../forms/AsyncForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IResetPasswordRequestSchema extends Partial<SpreadDtos.RequestPasswordReset> { }

const validation = Yup.object<IResetPasswordRequestSchema>().shape({
    email: Yup.string().email('must be a valid email').required(),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bottomBox: {},
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IPasswordResetRequestProps {
    history: History;
    match: match;
}

const PasswordResetRequest: React.FunctionComponent<IPasswordResetRequestProps> = ({ history, match }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const requestState = useSelector(s => passwordResetSelectors.requestState(s)).state;

    React.useEffect(() => {
        return function cleanup() {
            dispatch(passwordResetActions.clear());
        }
    }, [passwordResetActions, dispatch]);

    const handleCancelClick = () => {
        history.goBack();
    };

    var successRedirectComponent = null;

    if (requestState === RequestFormState.SubmitSuccess) {
        successRedirectComponent = <Redirect push to={routeBackToPath(match.url, 1, "resetPasswordRequestConfirm")} />
    }

    return <>
        {successRedirectComponent}
        <Typography variant="h4" component="h4">Password Reset</Typography>
        <AsyncForm
            start={passwordResetActions.request}
            resolve={passwordResetActions.requestResponse}
            reject={passwordResetActions.requestResponse}
            formSubscription={{ pristine: true, submitting: true }}
            validate={validation}
        >
            {({ pristine, submitting }) =>
                <Grid container alignItems="flex-start" spacing={2} >
                    <Grid item xs={12}>
                        <Field
                            name="email"
                            component={TextFieldWrapper}
                            type="text"
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.bottomBox} display="flex" alignItems="center" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.buttonCancel}
                                disabled={submitting}
                                onClick={() => { handleCancelClick() }}
                            >
                                <CancelIcon className={classes.buttonCancelIcon} />
                                Cancel
                             </Button>
                            <Button
                                className={classes.buttonSubmit}
                                color="primary"
                                disabled={pristine || submitting}
                                type="submit"
                                variant="contained"
                            >
                                <EmailIcon className={classes.buttonSubmitIcon} />
                                Send
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            }
        </AsyncForm>
    </>;
}

export default PasswordResetRequest;