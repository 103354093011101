/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { SequenceList } from './SequenceList';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewSequencesParams {
    specimenId: string;
    epidemicId: string;
}

interface IViewSequencesProps {
    match: match<IViewSequencesParams>;
    showCreate: boolean;
}

export const ViewSequences: React.FunctionComponent<IViewSequencesProps> = ({
    match,
    showCreate,
}) => {
    const classes = useStyles();

    const specimenId = parseInt(match.params.specimenId);

    const url = match.url + (!match.url.endsWith('/sequence') ? '/sequence' : '');

    return <Paper className={classes.root}>
        <SequenceList
            showTitle
            className={classes.list}
            specimenId={specimenId}
            url={url}
        />
        {/*showCreate ?
            <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                <HasPermission permission="CreateSequence" objectId{epidemicId}>
                    <ButtonLink
                        aria-label="Create Sequence"
                        color="primary"
                        variant="contained"
                        to={`${url}/create`}
                    >
                        <AddBoxIcon className={classes.buttonSubmitIcon} />
                        Create
                    </ButtonLink>
                </HasPermission>
            </Box> :
            undefined
        */}
    </Paper>
}