/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ResponseStatus } from '../../../dtos/Spread.dtos';
import { useSelector } from '../../../hooks/useTypedSelector';
import sequenceModule from '../../../store/reducers/sequences/sequence';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        wrapper: {
            display: 'block'
        },
        textContainer: {
            wordWrap: "break-word",
            whiteSpace: "pre-wrap"
        }
    }),
);

interface IViewGenbankProps {
    sequenceId?: number;
}

export const ViewGenbank: React.FunctionComponent<IViewGenbankProps> = ({ sequenceId: sequenceIdProp }) => {
    const classes = useStyles();

    const sequence = useSelector(state => state.sequence.data);
    const sequenceId = sequenceIdProp || (sequence ? sequence.id : undefined);

    const [content, setContent] = React.useState("");

    const [blob, loadState] = sequenceModule.hooks.useLoadGBKFile(sequenceId || -1);

    // Just here to trick webpack into acknowledging text as a property of Blob
    const customBlob = blob as Blob & { text(): Promise<string>; responseStatus: ResponseStatus };
    if (customBlob) {
        if (customBlob.responseStatus && content != customBlob.responseStatus.message) {
            setContent(customBlob.responseStatus.message);
        }
        else if (!customBlob.responseStatus) {
            customBlob.text().then(value => setContent(value));
        }
    }

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">NextGen Sequence Data</Typography>
        <div className={classes.wrapper}>
            <pre className={classes.textContainer}>{content}</pre>
        </div>
    </Paper>
}