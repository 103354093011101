/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ViewAnalyses } from './ViewAnalyses';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IAnalysisHomeParams {
    epidemicId: string;
}

interface IAnalysisHomeProps {
    match: match<IAnalysisHomeParams>;
}

export const AnalysisHome: React.FunctionComponent<IAnalysisHomeProps> = ({ match }) => {
    const classes = useStyles();

    const epidemicId = match.params && parseInt(match.params.epidemicId);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewAnalyses
                epidemicId={epidemicId}
                match={match}
            />
        </Box>
    </Box>
}
