/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { InfectedPremise } from '../dtos/Spread.dtos';
import { infectedPremiseActions } from '../store/reducers/infectedPremises/infectedPremise';
import { IFormRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useInfectedPremiseLoad(infectedPremiseId?: number): [InfectedPremise | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.infectedPremise.data);
    const requestState = useSelector(state => state.infectedPremise.states.loadState);

    useEffect(() => {
        if (infectedPremiseId && infectedPremiseActions.load) {
            dispatch(infectedPremiseActions.load(infectedPremiseId))
        }

        return function cleanup() {
            dispatch(infectedPremiseActions.clear())
        }
    }, [dispatch, infectedPremiseId]);

    return [
        data,
        requestState
    ];
}
