/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../dtos/Spread.dtos';
import { useSelector } from './useTypedSelector';
import { authenticatedPersonActions } from '../store/reducers/person/authenticatedPerson';
import { PersonGroupMemberInvite } from '../dtos/Spread.dtos';
import { personActions } from '../store/reducers/person/person';
import { IRequestState } from '../types/IRequestState';
import { RequestState } from '../types/RequestState';
import { useAcceptPersonGroupInvite, useDeletePersonGroupInvite } from './useGroupInvite';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function usePersonLoad(personId: number): [SpreadDtos.Person | undefined, IRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.person.data);
    const requestState = useSelector(state => state.person.loadState);

    useEffect(() => {
        dispatch(personActions.load(personId))

        return function cleanup() {
            dispatch(personActions.clear())
        }
    }, [dispatch, personId]);

    return [
        data,
        requestState
    ];
}

export function useAuthenticatedPersonGroupInvites(): [(inviteId: number) => void, (inviteId: number) => void, boolean, PersonGroupMemberInvite[] | undefined, IRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.authenticatedPerson.groupInvites.data);
    const loadRequestState = useSelector(state => state.authenticatedPerson.groupInvites.loadState);

    const [actionPending, setActionPending] = useState(false)

    const [acceptDispatch, acceptRequestState] = useAcceptPersonGroupInvite();
    const [declineDispatch, declineRequestState] = useDeletePersonGroupInvite();

    useEffect(() => {
        // if we toggled from pending to not pending, reload the invites as they have probably changed
        if (!actionPending) {
            dispatch(
                authenticatedPersonActions.loadGroupInvites()
            )
        }
    }, [dispatch, actionPending]);

    useEffect(() => {
        // when all actions have finished and we were waiting for one to finish, set it to not pending so we know to reload the group invites
        if (acceptRequestState.state !== RequestState.Pending && declineRequestState.state !== RequestState.Pending && actionPending) {
            setActionPending(false);
        }
    }, [actionPending, acceptRequestState, declineRequestState]);

    const interceptDispatch = (action: any) => {
        // block more than one action happening at once (accept/decline)
        if (!actionPending) {
            setActionPending(true);

            return action;
        }

        return;
    }

    return [
        (inviteId: number) => interceptDispatch(acceptDispatch(inviteId)),
        //acceptRequestState,
        (inviteId: number) => interceptDispatch(declineDispatch(inviteId)),
        //declineRequestState,
        actionPending,
        data,
        loadRequestState
    ];
}

