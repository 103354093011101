/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Config } from 'final-form';
import { History } from 'history';
import * as React from 'react';
import { FormRenderProps, FormSpy } from 'react-final-form';
import * as Yup from 'yup';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import createAction from '../../helpers/createAction';
import NavigationBlockerDialog from '../routes/NavigationBlockerDialog';
import AsyncForm from './AsyncForm';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ICommonFormProps<ValidatorType = Yup.Schema<any>, FormType = Yup.InferType<ValidatorType>> {
    children: (props: FormRenderProps<FormType>) => React.ReactNode;
    initialValues?: FormType;
    history: History;
    submitSuccess: boolean;

    mutators?: Config<any>['mutators'];
    /**
     * The actions that are dispatched when submitting the form.
     */
    // the type of action to dispatch when this function is called
    start: ReturnType<typeof createAction>;
    // the type of action that will resolve the promise
    resolve: ReturnType<typeof createAction>;
    // the type of action that will reject the promise
    reject: ReturnType<typeof createAction>;

    iconSave?: (props: SvgIconProps) => JSX.Element;
    iconCancel?: (props: SvgIconProps) => JSX.Element;

    labelSave?: string;
    labelCancel?: string;

    validate?: ValidatorType;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

function CommonForm<FormType>({ children, initialValues, history, mutators, submitSuccess, validate, start, reject, resolve, iconSave, iconCancel, labelSave, labelCancel }: ICommonFormProps<FormType>) {
    const classes = useStyles();

    const handleCancelClick = () => {
        history.goBack();
    };

    // They need to be capitalized for some reason
    const IconCancel = iconCancel;
    const IconSave = iconSave;

    return <AsyncForm
        initialValues={initialValues}
        mutators={mutators}
        validate={validate}
        start={start}
        resolve={reject}
        reject={resolve}
    >{({
        active,
        dirty,
        dirtyFields,
        dirtyFieldsSinceLastSubmit,
        dirtySinceLastSubmit,
        error,
        errors,
        form,
        handleSubmit,
        hasSubmitErrors,
        hasValidationErrors,
        initialValues,
        invalid,
        pristine,
        submitError,
        submitErrors,
        submitFailed,
        submitSucceeded,
        submitting,
        valid,
        validating,
        values,
    }) => <>
            <Grid container alignItems="flex-start" spacing={2} >
                {children({
                    active,
                    dirty,
                    dirtyFields,
                    dirtyFieldsSinceLastSubmit,
                    dirtySinceLastSubmit,
                    error,
                    errors,
                    form,
                    handleSubmit,
                    hasSubmitErrors,
                    hasValidationErrors,
                    initialValues,
                    invalid,
                    pristine,
                    submitError,
                    submitErrors,
                    submitFailed,
                    submitSucceeded,
                    submitting,
                    valid,
                    validating,
                    values,
                })}
                <Grid item xs={12}>
                    <Box className={classes.bottomBox} display="flex" alignItems="center" justifyContent="flex-end">
                        <FormSpy subscription={{ submitError: true, submitErrors: true }}>
                            {
                                formErrorProps => (
                                    formErrorProps.submitError ? <Typography color={'error'} variant="body1" component="p">{formErrorProps.submitError}</Typography> :
                                        formErrorProps.submitErrors && Object.values(formErrorProps.submitErrors).length ? <Typography color={'error'} variant="body1" component="p">{Object.values(formErrorProps.submitErrors)[0]}</Typography> : null
                                )
                            }
                        </FormSpy>
                        <FormSpy subscription={{ pristine: true, submitting: true, values: true }}>
                            {formButtonProps => (
                                <>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={classes.buttonCancel}
                                        disabled={formButtonProps.submitting}
                                        onClick={() => { handleCancelClick() }}
                                    >
                                        {
                                            IconCancel ?
                                                <IconCancel className={classes.buttonCancelIcon} /> :
                                                <CancelIcon className={classes.buttonCancelIcon} />
                                        }
                                        {labelCancel ? labelCancel : 'Cancel'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className={classes.buttonSubmit}
                                        type="submit"
                                        color="primary"
                                        disabled={formButtonProps.pristine || formButtonProps.submitting/* || (submitError !== undefined && !dirtySinceLastSubmit)*/}
                                    >
                                        {
                                            IconSave ?
                                                <IconSave className={classes.buttonSubmitIcon} /> :
                                                <SaveIcon className={classes.buttonSubmitIcon} />
                                        }
                                        {labelSave ? labelSave : 'Save'}
                                    </Button>
                                </>
                            )}
                        </FormSpy>
                    </Box>
                </Grid>
            </Grid>
            <FormSpy subscription={{ dirtySinceLastSubmit: true, pristine: true }}>
                {spyProps => (
                    <NavigationBlockerDialog block={!submitSuccess && (!spyProps.pristine || spyProps.dirtySinceLastSubmit)} />
                )}
            </FormSpy>
        </>}
    </AsyncForm>
};

export default CommonForm;