/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AreaLivestockCountList } from './AreaLivestockCountList';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewAreaLivestockCountsParams {
    areaId: string;
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
}

interface IViewAreaLivestockCountsProps {
    areaId?: number;
    history: History;
    match: match<IViewAreaLivestockCountsParams>;
}

export const ViewAreaLivestockCounts: React.FunctionComponent<IViewAreaLivestockCountsProps> = ({
    areaId,
    history,
    match,
}) => {
    const classes = useStyles();

    const url = match.url + (!match.url.endsWith('/livestock') ? '/livestock' : '');
    areaId = areaId || (match.params ? parseInt(match.params.areaId) : undefined);

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Livestock</Typography>
        {areaId ? <AreaLivestockCountList className={classes.list} areaId={areaId} history={history} url={url} /> : null}
    </Paper>
}