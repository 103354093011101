/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple areaLivestockCount.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialAreaLivestockCountSearchState = ISearchState<SpreadDtos.AreaLivestockCount>;

export interface IAreaLivestockCountSearchState {
    areaLivestockCountSearch: IInitialAreaLivestockCountSearchState;
}

export const initialState: IAreaLivestockCountSearchState = {
    areaLivestockCountSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const areaLivestockCountsModule = createAutoQueryModule(
    'areaLivestockCountSearch',
    initialSearchState,
    '@@areaLivestockCountSearch',
    SpreadDtos.QueryAreaLivestockCounts,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        areaId: options.specialOptions.areaId
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const areaLivestockCountSearchActions = areaLivestockCountsModule.actions;
export const areaLivestockCountSearchEpics = areaLivestockCountsModule.epics;
export const areaLivestockCountsApi = areaLivestockCountsModule.api;
export const areaLivestockCountsSelectors = areaLivestockCountsModule.selectors;
export const areaLivestockCountsHooks = areaLivestockCountsModule.hooks;
export default areaLivestockCountsModule.reducer;