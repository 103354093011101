/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useCallback } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { TapmDataSetList } from './TapmDataSetList';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useRefreshTapmDataSets } from '../../../hooks/analysisTapm';
import { HasPermission } from '../../common/HasPermission';
import { RequestState } from '../../../types/RequestState';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IRefreshTapmDataSetsProps {
    className?: string;
    history: History;
    url: string;
}

export const RefreshTapmDataSets: React.FunctionComponent<IRefreshTapmDataSetsProps> = ({ className, history, url }) => {
    const classes = useStyles();

    const [refreshFn, refreshState] = useRefreshTapmDataSets();

    const handleReprocess = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => refreshFn(),
        [refreshFn],
    );

    return <>
        <Paper className={classNames(className, classes.root)}>
            <Typography variant="h4" component="h4">TAPM Datasets</Typography>
            {
                refreshState.state === RequestState.None && <HasPermission permission="RefreshTapmDataSets">
                    <Button
                        aria-label="Sync any new or changed TAPM DataSets into the SPREAD database"
                        color="primary"
                        variant="contained"
                        onClick={handleReprocess}
                    >
                        Check TAPM for new datasets
                </Button>
                </HasPermission>
            }
            {
                refreshState.state === RequestState.Pending && <div>
                    <CircularProgress />
                    <Typography variant="body1" component="p">Contacting the TAPM API and updating datasets - please be patient this can take a few minutes to complete.</Typography>
                </div>
            }

            {
                refreshState.state === RequestState.Success && <Typography variant="body1" component="p">Sync completed with no errors. You may need to refresh the page to see new datasets in the table below.</Typography>
            }

            {refreshState.responseStatus && <Typography variant="body1" component="p">{refreshState.responseStatus.message}</Typography>}
        </Paper>
        <Paper className={classNames(className, classes.root)}>
            <Typography variant="h4" component="h4">TAPM Datasets In Spread</Typography>
            <TapmDataSetList
                url={url}
            />
        </Paper>
    </>
}