/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

export interface ITestPageProps {
  classes?: any;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(3, 2)
    },
    progress: {
      flexGrow: 1,
      margin: theme.spacing(4, 1)
    }
  }),
);

export default function LoadingPage() {
  const classes = useStyles();

  //const sessionState = useSelector(state => state.authenticatedPerson.authState);

  return (<Paper className={classes.root}>
    <Grid container justify="center" direction="column">
      <Typography align="center" variant="h3" component="h3">Loading SPREAD</Typography>
      <LinearProgress className={classes.progress} />
    </Grid>
  </Paper>
  );
}
