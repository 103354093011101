/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../../hooks/useTypedSelector';
import livestockDefaultModule from '../../../store/reducers/livestockDefaults/livestockDefault';
import { RequestFormState } from '../../../types/RequestState';
import LivestockDefaultEditForm from './LivestockDefaultEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditLivestockDefaultParams {
    livestockDefaultId: string;
}

interface IEditLivestockDefaultProps {
    livestockDefaultId?: number;
    history: History;
    match: match<IEditLivestockDefaultParams>;
}

export const EditLivestockDefault: React.FunctionComponent<IEditLivestockDefaultProps> = (props) => {
    const livestockDefaultState = useSelector(state => state.livestockDefault);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const livestockDefaultId = props.livestockDefaultId || (props.match.params ? parseInt(props.match.params.livestockDefaultId) : undefined);

    useEffect(() => {
        if (livestockDefaultModule.actions.load) {
            dispatch(livestockDefaultModule.actions.load(livestockDefaultId))
        }
    }, [dispatch, livestockDefaultId]);

    return <Paper className={classes.root}>
        <LivestockDefaultEditForm
            editLivestockDefault={livestockDefaultState.data}
            history={props.history}
            loadingLivestockDefault={livestockDefaultState.states.loadState.state === RequestFormState.Pending && !livestockDefaultState.data}
            url={props.match.url}
        />
    </Paper>
}
