/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import countryModule from '../../store/reducers/countries/country';
import pathogenModule from '../../store/reducers/pathogens/pathogen';
import { RequestFormState } from '../../types/RequestState';
import { Pathogen } from '../../dtos/Spread.dtos';
import PathogenEditForm from './PathogenEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditPathogenParams {
    pathogenId: string;
}

interface IEditPathogenProps {
    pathogenId?: number;
    history: History;
    match: match<IEditPathogenParams>;
}

export const EditPathogen: React.FunctionComponent<IEditPathogenProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles({});
    
    const pathogenState = useSelector(state => state.pathogen);
    const pathogenId = props.pathogenId || ((props.match.params && props.match.params.pathogenId)? parseInt(props.match.params.pathogenId) : 0);
    useEffect(() => {
        if (pathogenModule.actions.load) {
            dispatch(pathogenModule.actions.load(pathogenId))
        }
    }, [dispatch, pathogenId]);

    return <Paper className={classes.root}>
        <PathogenEditForm
            editPathogen={pathogenState.data}
            history={props.history}
            loadingPathogen={pathogenState.states.loadState.state === RequestFormState.Pending && !pathogenState.data}
            url={props.match.url}
        />
    </Paper>
}