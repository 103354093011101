/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Field } from 'react-final-form'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';

import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import AsyncForm from '../forms/AsyncForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { groupInviteActions } from '../../store/reducers/groups/groupInvite';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        }
    }),
);

interface ICreateGroupInviteFormProps {
    className?: string;
    groupId: number;
}

interface IGroupMemberInviteFormSchema {
    email: string;
    groupId: number;
}

const validation = Yup.object().shape<IGroupMemberInviteFormSchema>({
    email: Yup.string().email().label('Email')
        .required(),
    groupId: Yup.number().required()
});

export const CreateGroupMemberInviteForm: React.FunctionComponent<ICreateGroupInviteFormProps> = (props) => {
    const classes = useStyles();

    const startAction = groupInviteActions.createMemberInvite;
    const resolveAction = groupInviteActions.createMemberInviteSuccess;
    const rejectAction = groupInviteActions.createMemberInviteFailure;

    return <>
        <AsyncForm
            initialValues={{ groupId: props.groupId, email: '' }}
            validate={validation}
            start={startAction}
            resolve={resolveAction}
            reject={rejectAction}
            formSubscription={{ submitting: true, pristine: true, submitError: true, dirtySinceLastSubmit: true, hasValidationErrors: true }}
        >{({ dirtySinceLastSubmit, hasValidationErrors, pristine, submitError, submitting, values }) => (
            <Grid container alignItems="flex-start" spacing={2} >
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        required
                        name="email"
                        component={TextFieldWrapper}
                        type="text"
                        label="Email"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        {submitError && <Typography color={'error'} variant="body1" component="p">{submitError}</Typography>}
                        <Button
                            variant="contained"
                            size="small"
                            className={classes.buttonSubmit}
                            type="submit"
                            color="primary"
                            disabled={pristine || hasValidationErrors || submitting || (submitError !== undefined && !dirtySinceLastSubmit)}
                        >
                            <SendIcon className={classes.buttonSubmitIcon} />
                            Send Invite
                        </Button>
                    </Box>
                </Grid>
            </Grid >
        )}
        </AsyncForm>
    </>
}