/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { PersonHome } from '../../components/person/PersonHome';
import { ViewPersons } from '../../components/person/ViewPersons';
import { CreatePersonRole } from '../../components/roles/CreatePersonRole';
import { ViewPersonRoles } from '../../components/roles/ViewPersonRoles';

export interface IPersonsPageProps {
    classes?: any;
    match: match;
}

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export const PersonsPage: React.FunctionComponent<IPersonsPageProps> = (props) => {
    return (<Switch>
        <Route exact path={`${props.match.path}/:personId`} component={PersonHome} />
        <Route exact path={`${props.match.path}/:personId/role/create`} component={CreatePersonRole} />
        <Route exact path={`${props.match.path}/role`} component={ViewPersonRoles} />
        <Route path={`${props.match.path}`} component={ViewPersons} />
    </Switch>
    );
}