/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import subclassificationModule from '../../store/reducers/subClassifications/subClassification';
import { RequestFormState } from '../../types/RequestState';
import SubClassificationEditForm from './SubClassificationEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSubClassificationParams {
    subclassificationId: string;
}

interface IEditSubClassificationProps {
    subclassificationId?: number;
    history: History;
    match: match<IEditSubClassificationParams>;
}

export const EditSubClassification: React.FunctionComponent<IEditSubClassificationProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles({});
    
    const subclassificationState = useSelector(state => state.subClassification);
    const subclassificationId = props.subclassificationId || ((props.match.params && props.match.params.subclassificationId)? parseInt(props.match.params.subclassificationId) : 0);
    useEffect(() => {
        if (subclassificationModule.actions.load) {
            dispatch(subclassificationModule.actions.load(subclassificationId))
        }
    }, [dispatch, subclassificationId]);

    return <Paper className={classes.root}>
        <SubClassificationEditForm
            editSubClassification={subclassificationState.data}
            history={props.history}
            loadingSubClassification={subclassificationState.states.loadState.state === RequestFormState.Pending && !subclassificationState.data}
            url={props.match.url}
        />
    </Paper>
}