/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { History } from 'history';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import LoginPage from '../../pages/application/LoginPage';
import { AdminPage } from '../../pages/application/AdminPage';
import LoadingPage from '../../pages/application/LoadingPage';
import { RegisterPage } from '../../pages/application/RegisterPage';
import { EpidemicsPage } from '../../pages/application/EpidemicsPage';
import { GroupsPage } from '../../pages/application/GroupsPage';
import { PersonProfilePage } from '../../pages/application/PersonProfilePage';
import { PersonsPage } from '../../pages/application/PersonsPage';
import { TasksPage } from '../../pages/application/TasksPage';
import TestPage from '../../pages/application/TestPage';
import { AuthState } from '../../types/AuthState';
import Page404 from '../../pages/Page404';
import { Layout } from '../layout/Layout';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISpreadApplicationRoutesProps {
    history: History;
}

export const SpreadApplicationRoutes: React.FunctionComponent<ISpreadApplicationRoutesProps> = (props) => {
    const sessionState = useSelector(state => state.authenticatedPerson.authState);

    return <Layout showNav={sessionState === AuthState.Authenticated} title={'SPREAD'} history={props.history}>

        {sessionState === AuthState.Unknown || sessionState === AuthState.Pending
            ? <LoadingPage />
            : sessionState === AuthState.Authenticated
                ?
                <Switch>
                    <Route path={'/task'} component={TasksPage} />
                    <Route path={'/admin'} component={AdminPage} />
                    <Route path={'/epidemic'} component={EpidemicsPage} />
                    <Route path={'/group'} component={GroupsPage} />
                    <Route path={'/profile'} component={PersonProfilePage} />
                    <Route path={'/person'} component={PersonsPage} />
                    <Route path={'/map'} component={TestPage} />
                    <Route exact path={'/404'} component={Page404} />
                    <Redirect to="/404" />
                </Switch>

                :
                <Switch>
                    <Route exact path={'/signup'} component={RegisterPage} />
                    <Route path={'/login'} component={LoginPage} />
                    <Redirect to="/login" />
                </Switch>}
    </Layout>;
}