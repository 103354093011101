/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple fields.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';
import { Field, QueryFields } from '../../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialFieldSearchState = ISearchState<Field>;

export interface IFieldSearchState {
    fieldSearch: IInitialFieldSearchState;
}

export const initialState: IFieldSearchState = {
    fieldSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const fieldSearchModule = createAutoQueryModule(
    'fieldSearch',
    initialSearchState,
    '@@fieldSearch', 
    QueryFields, 
    { },
    (type) => ({ }),
    (reducer, actions) => { },
    false,
    (options) =>({
        clusterId: options.specialOptions.clusterId,
        epidemicId: options.specialOptions.epidemicId,
        infectedPremiseId: options.specialOptions.infectedPremiseId,
        parcelId: options.specialOptions.parcelId
    })
);


/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const fieldSearchActions = fieldSearchModule.actions;
export const fieldSearchEpics = fieldSearchModule.epics;
export const fieldSearchApi = fieldSearchModule.api;
export const fieldSearchSelectors = fieldSearchModule.selectors;
export const fieldSearchHooks = fieldSearchModule.hooks;
export default fieldSearchModule.reducer;