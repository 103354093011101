/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse, ParsedQuery, stringify } from 'query-string';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { OnBlur, OnChange } from "react-final-form-listeners";

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { countriesHooks } from '../../store/reducers/countries/countrySearch';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISequenceViewFilterProps {
    filterSet: "view" | "link";
    history: History;
    params: ParsedQuery<string>;
    sequencesLoading: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

export const SequenceViewFilter: React.FunctionComponent<ISequenceViewFilterProps> = ({
    filterSet,
    history,
    params,
    sequencesLoading,
    url,
}) => {
    const classes = useStyles();

    const [searchText, setSearchText] = React.useState(params.searchText || undefined);
    const [countryData, , , countryRequestState] = countriesHooks.useSearch();
    const [country, setCountry] = React.useState(String(params.country));
    const [year, setYear] = React.useState(isNaN(parseInt(String(params.year))) ? undefined : parseInt(String(params.year)));

    const dontDoAnything = () => {
        // Don't do anything
    };

    const restParams = (exclude: string) => {
        let params = parse(history.location.search);
        params[exclude] = undefined;
        return stringify(params);
    }

    const countryOptions = countryData && countryData.results && countryData.results.length > 0 ?
        countryData.results.map(((d, i) =>
            <MenuItem
                key={`country-${i}`}
                value={d.name}
            >
                {d.name}
            </MenuItem>
        )) :
        [];

    return <Form
        initialValues={{
            searchText: params.searchText,
            origin: params.origin,
            sequencingStatus: params.sequencingStatus,
            country: params.country,
            year: params.year,
        }}
        onSubmit={dontDoAnything}
    >
        {
            ({ }) => <>
                <OnChange name={"searchText"}>
                    {(value: string) => {
                        if ((!value || value.length == 0) && searchText) {
                            setSearchText(undefined);
                        }
                        if (searchText !== value) {
                            setSearchText(value);
                        }
                    }}
                </OnChange>
                <OnBlur name={"searchText"}>
                    {() => {
                        const prefix = searchText ? `?searchText=${searchText}` : "";
                        const otherParams = restParams('searchText');
                        const middle = otherParams.length == 0 ? "" : prefix.length == 0 ? "?" : "&";
                        history.replace(`${url}${prefix}${middle}${otherParams.length > 0 ? otherParams : ''}`)
                    }}
                </OnBlur>
                <Grid item lg={6} xs={12}>
                    <Field
                        name="searchText"
                        component={TextFieldWrapper}
                        disabled={sequencesLoading}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                        }}
                        label="Search"
                    />
                </Grid>
                {filterSet == "view" ?
                    <>
                        <OnChange name={"origin"}>
                            {(value: any) => {
                                const prefix = value == "All" ? "" : `?origin=${value}`;
                                const otherParams = restParams('origin');
                                const middle = otherParams.length == 0 ? "" : prefix.length == 0 ? "?" : "&";
                                history.replace(`${url}${prefix}${middle}${otherParams.length > 0 ? otherParams : ''}`)
                            }}
                        </OnChange>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="origin"
                                component={SelectWrapper}
                                disabled={sequencesLoading}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label="Origin"
                            >
                                <MenuItem
                                    key={0}
                                    value="All"
                                >
                                    All
                        </MenuItem>
                                <MenuItem
                                    key={1}
                                    value="SPREAD"
                                >
                                    SPREAD
                        </MenuItem>
                                <MenuItem
                                    key={2}
                                    value="GenBank"
                                >
                                    GenBank
                        </MenuItem>
                            </Field>
                        </Grid>
                        <OnChange name={"sequencingStatus"}>
                            {(value: any) => {
                                const prefix = value == "All" ? "" : `?sequencingStatus=${value}`;
                                const otherParams = restParams('sequencingStatus');
                                const middle = otherParams.length == 0 ? "" : prefix.length == 0 ? "?" : "&";
                                history.replace(`${url}${prefix}${middle}${otherParams.length > 0 ? otherParams : ''}`)
                            }}
                        </OnChange>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="sequencingStatus"
                                component={SelectWrapper}
                                disabled={sequencesLoading}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label="Type"
                            >
                                <MenuItem
                                    key={0}
                                    value="All"
                                >
                                    All
                        </MenuItem>
                                <MenuItem
                                    key={1}
                                    value="No Sequence"
                                >
                                    Specimen
                        </MenuItem>
                                <MenuItem
                                    key={2}
                                    value="Unassembled"
                                >
                                    SRA
                        </MenuItem>
                                <MenuItem
                                    key={3}
                                    value="Assembled"
                                >
                                    Consensus
                        </MenuItem>
                            </Field>
                        </Grid>
                    </> :
                    <>
                        <OnChange name={"country"}>
                            {(value: string) => {
                                if (country !== value) {
                                    const prefix = value == "All" ? "" : `?country=${value}`;
                                    const otherParams = restParams('country');
                                    const middle = otherParams.length == 0 ? "" : prefix.length == 0 ? "?" : "&";
                                    setCountry(value);
                                    history.replace(`${url}${prefix}${middle}${otherParams.length > 0 ? otherParams : ''}`)
                                }
                            }}
                        </OnChange>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="country"
                                component={SelectWrapper}
                                disabled={sequencesLoading}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label="Country"
                            >
                                <MenuItem
                                    value={"All"}
                                >
                                    All
                            </MenuItem>
                                {countryOptions}
                            </Field>
                        </Grid>
                        <OnChange name={"year"}>
                            {(value: string) => {
                                if ((!value || value.length == 0) && year) {
                                    setYear(undefined);
                                }
                                if (!isNaN(parseInt(value)) && year !== parseInt(value)) {
                                    setYear(parseInt(value));
                                }
                            }}
                        </OnChange>
                        <OnBlur name={"year"}>
                            {() => {
                                const prefix = year ? `?year=${year}` : "";
                                const otherParams = restParams('year');
                                const middle = otherParams.length == 0 ? "" : prefix.length == 0 ? "?" : "&";
                                history.replace(`${url}${prefix}${middle}${otherParams.length > 0 ? otherParams : ''}`)
                            }}
                        </OnBlur>
                        <Grid item lg={6} xs={12}>
                            <Field
                                name="year"
                                component={TextFieldWrapper}
                                disabled={sequencesLoading}
                                formControlProps={{
                                    className: classes.formControl,
                                    fullWidth: true,
                                }}
                                label="Year"
                            />
                        </Grid>
                    </>
                }
            </>
        }
    </Form >
};
