/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for executing an epidemic spatial import
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import createAction from '../../../helpers/createAction';
import { createGetRequest } from '../../../helpers/createRequest';
import { RequestState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';
import { PreparseImportFileResponse, ImportType, PreparseImportFile, FileExtensionType } from '../../../dtos/Spread.dtos';
import { IRequestState } from '../../../types/IRequestState';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialPreParseState {
    data?: PreparseImportFileResponse;
    loadState: IRequestState
}

export interface IImportPreParseState {
    importPreParse: IInitialPreParseState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@importPreParse';

export const ImportPreParseTypes = {
    PREPARSE: `${typeNamespace}/PREPARSE`,
    PREPARSE_SUCCESS: `${typeNamespace}/PREPARSE_SUCCESS`,
    PREPARSE_FAILURE: `${typeNamespace}/PREPARSE_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialPreParseState = {
    data: undefined,
    loadState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const importPreParseReducer = createReducer<IInitialPreParseState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const importPreParseActions = {

    /**
     * Begins the request to load a given log
     */
    preParse: createAction(ImportPreParseTypes.PREPARSE,
        (importType: ImportType, epidemicId: number, temporaryFileToken: string, temporaryFileExtensionType: FileExtensionType) => ({ 
            importType, 
            epidemicId,
            temporaryFileToken, 
            temporaryFileExtensionType 
        })
    ),
    /**
     * Stores the log after a successful load request
     */
    preParseSuccess: createAction(ImportPreParseTypes.PREPARSE_SUCCESS,
        (response: PreparseImportFileResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    preParseFailure: createAction(ImportPreParseTypes.PREPARSE_FAILURE,
        (response: PreparseImportFileResponse) => ({
            response
        })
    ),
    /**
    * Clears the importPreParse state.
    */
    clear: createAction(ImportPreParseTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Load Reducers
importPreParseReducer.on(importPreParseActions.preParse, (state) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

importPreParseReducer.on(importPreParseActions.preParseSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

importPreParseReducer.on(importPreParseActions.preParseFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));


// Clear Reducer
importPreParseReducer.on(importPreParseActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
importPreParseReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const importPreParseApi = {
    preParse: createGetRequest(
        PreparseImportFile,
        (importType: ImportType, epidemicId: number, temporaryFileToken: string, temporaryFileExtensionType: FileExtensionType) => ({
            importType: importType,
            epidemicId: epidemicId,
            temporaryFileToken: temporaryFileToken,
            temporaryFileExtensionType: temporaryFileExtensionType,
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const preParseEpic: Epic<ReturnType<typeof importPreParseActions.preParse>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(importPreParseActions.preParse.getType()),
    mergeMap(action =>
        importPreParseApi.preParse(action.payload.importType, action.payload.epidemicId, action.payload.temporaryFileToken, action.payload.temporaryFileExtensionType)
            .pipe(
                mergeMap(response =>
                    of(
                        importPreParseActions.preParseSuccess(response)
                    )
                ),
                catchError(e => of(importPreParseActions.preParseFailure(e)))
            )
    ));

export const importPreParseEpics = combineEpics(preParseEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default importPreParseReducer;