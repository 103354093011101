/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple libraryStrategy.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialLibraryStrategySearchState = ISearchState<SpreadDtos.LibraryStrategy>;

export interface ILibraryStrategySearchState {
    libraryStrategySearch: IInitialLibraryStrategySearchState;
}

export const initialState: ILibraryStrategySearchState = {
    libraryStrategySearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const libraryStrategysModule = createAutoQueryModule(
    'libraryStrategySearch',
    initialSearchState,
    '@@libraryStrategySearch',
    SpreadDtos.QueryLibraryStrategys,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const libraryStrategySearchActions = libraryStrategysModule.actions;
export const libraryStrategySearchEpics = libraryStrategysModule.epics;
export const libraryStrategySearchApi = libraryStrategysModule.api;
export const libraryStrategySearchSelectors = libraryStrategysModule.selectors;
export const libraryStrategySearchHooks = libraryStrategysModule.hooks;
export default libraryStrategysModule.reducer;