/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ReplaceAll } from '../../types/HelperTypes';
import { TablePaginationActions } from './TablePaginationActions';
import { ITableHeadSortableColumn, TableHeadSortable } from './TableHeadSortable';
import { createAutoQueryModule, IPaginateQueryOptions } from '../../store/reducers/common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface ISortableTableColumnDef {
    format?: string;
    header: ITableHeadSortableColumn;
    order: number;
    render?: (value: any) => React.ReactNode;
}

interface ISortableTableProps<ObjectType = any, PropertiesType extends ReplaceAll<Partial<ObjectType>, ISortableTableColumnDef> = ReplaceAll<Partial<ObjectType>, ISortableTableColumnDef>> {
    className?: string;
    data?: ObjectType[];
    headerColumns?: ITableHeadSortableColumn[];
    include?: PropertiesType;
    paginateOptions: IPaginateQueryOptions;
    rowCount: number;
    rowsPerPageOptions?: number[];
    updatePaginateOptions: ReturnType<typeof createAutoQueryModule>['actions']['updatePaginateOptions'];
}

export const SortableTable = <ObjectType extends object = any>({ children, className, data, headerColumns, include, paginateOptions, rowCount, rowsPerPageOptions, updatePaginateOptions }: ISortableTableProps<ObjectType> & { children?: React.ReactNode }) => {
    const dispatch = useDispatch();

    const headers = headerColumns || [];

    const handleChangePage = useCallback(
        (event: React.MouseEvent | null, newPage: number) =>
            dispatch(updatePaginateOptions(
                {
                    skip: newPage * paginateOptions.take
                }
            )),
        [dispatch, updatePaginateOptions, paginateOptions],
    );

    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            dispatch(updatePaginateOptions(
                {
                    take: parseInt(event.target.value, 10)
                }
            )),
        [dispatch, updatePaginateOptions],
    );

    const handleRequestSort = useCallback(
        (event: React.MouseEvent, property: string) => {
            var orderByDesc: undefined | string = undefined;
            var orderBy: undefined | string = property;
            if (paginateOptions.orderBy === property) {
                orderByDesc = property;
                orderBy = undefined;
            }

            dispatch(updatePaginateOptions(
                {
                    orderBy: orderBy,
                    orderByDesc: orderByDesc
                }))
        },
        [dispatch, updatePaginateOptions, paginateOptions],
    );

    return <Table className={className}>
        <TableHeadSortable
            columns={headers}
            orderBy={paginateOptions.orderBy}
            orderByDesc={paginateOptions.orderByDesc}
            onRequestSort={handleRequestSort}
        />
        <TableBody>
            {data && include ?
                <></> :
                children}
        </TableBody>
        <TableFooter>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions ? rowsPerPageOptions : [10, 25, 100, 200]}
                    colSpan={headers.length}
                    count={rowCount}
                    rowsPerPage={paginateOptions.take ? paginateOptions.take : 10}
                    page={paginateOptions.skip && paginateOptions.take ? paginateOptions.skip / paginateOptions.take : 0}
                    SelectProps={{
                        inputProps: { 'aria-label': 'Rows per page' },
                        native: false,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </TableRow>
        </TableFooter>
    </Table>
}