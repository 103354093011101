/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an epidemic person
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPutRequest } from '../../../helpers/createRequest';
import { IRequestState } from '../../../types/IRequestState';
import { RequestState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialPersonState {
    data?: SpreadDtos.Person;
    createState: IRequestState;
    loadState: IRequestState;
    updateState: IRequestState;
}

export interface IPersonState {
    person: IInitialPersonState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@person';

export const PersonTypes = {
    CREATE: `${typeNamespace}/CREATE`,
    CREATE_SUCCESS: `${typeNamespace}/CREATE_SUCCESS`,
    CREATE_FAILURE: `${typeNamespace}/CREATE_FAILURE`,
    LOAD: `${typeNamespace}/LOAD`,
    LOAD_SUCCESS: `${typeNamespace}/LOAD_SUCCESS`,
    LOAD_FAILURE: `${typeNamespace}/LOAD_FAILURE`,
    UPDATE: `${typeNamespace}/UPDATE`,
    UPDATE_SUCCESS: `${typeNamespace}/UPDATE_SUCCESS`,
    UPDATE_FAILURE: `${typeNamespace}/UPDATE_FAILURE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialPersonState = {
    data: undefined,
    loadState: {
        state: RequestState.None
    },
    createState: {
        state: RequestState.None
    },
    updateState: {
        state: RequestState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const personReducer = createReducer<IInitialPersonState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const personActions = {
    /**
     * Begins the request to load a given person.
     */
    load: createAction(PersonTypes.LOAD,
        (personId: number) => ({ personId })
    ),
    /**
     * Stores the person after a successful load request
     */
    loadSuccess: createAction(PersonTypes.LOAD_SUCCESS,
        (response: SpreadDtos.GetPersonResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed load request
     */
    loadFailure: createAction(PersonTypes.LOAD_FAILURE,
        (response: SpreadDtos.GetPersonResponse) => ({
            response
        })
    ),
    /**
     * Begins the request to update an person.
     * 
     * This cannot create a new person.
     */
    update: createAction(PersonTypes.UPDATE,
        (person: SpreadDtos.Person) => ({ person })
    ),
    /**
     * Stores the person after a successful update request
     */
    updateSuccess: createAction(PersonTypes.UPDATE_SUCCESS,
        (response: SpreadDtos.UpdatePersonResponse) => ({ response })
    ),
    /**
     * Stores the error after a failed update
     */
    updateFailure: createAction(PersonTypes.UPDATE_FAILURE,
        (response: SpreadDtos.UpdatePersonResponse) => ({
            response
        })
    ),
    /**
    * Clears the person state.
    */
    clear: createAction(PersonTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Load Reducers
personReducer.on(personActions.load, (state) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

personReducer.on(personActions.loadSuccess, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: payload.response.person
            },
            loadState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

personReducer.on(personActions.loadFailure, (state, payload) => (
    update(
        state,
        {
            data: {
                $set: undefined
            },
            loadState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Update existing Reducers
personReducer.on(personActions.update, (state, payload) => (
    update(
        state,
        {
            updateState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
));

personReducer.on(personActions.updateSuccess, (state, payload) => (
    update(
        state,
        {
            //data: {
            //    $set: payload.response.person
            //},
            updateState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
));

personReducer.on(personActions.updateFailure, (state, payload: any) => (
    update(
        state,
        {
            updateState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
personReducer.on(personActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
personReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const personsApi = {
    load: createGetRequest(
        SpreadDtos.GetPerson,
        (personId: number) => ({
            id: personId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdatePerson,
        (person: SpreadDtos.Person) => ({
            person: person
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const loadEpic: Epic<ReturnType<typeof personActions.load>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(personActions.load.getType()),
    mergeMap(action =>
        personsApi.load(action.payload.personId)
            .pipe(
                mergeMap(response =>
                    of(
                        personActions.loadSuccess(response)
                    )
                ),
                catchError(e => of(personActions.loadFailure(e)))
            )
    ));

export const updateEpic: Epic<ReturnType<typeof personActions.update>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(personActions.update.getType()),
    mergeMap(action =>
        personsApi.update(action.payload.person)
            .pipe(
                mergeMap(response =>
                    of(
                        personActions.updateSuccess(response)
                    )
                ),
                catchError(e => of(personActions.updateSuccess(e)))
            )
    ));

export const personEpics = combineEpics(loadEpic, updateEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default personReducer;