/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an sub nationals
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateSubNational,
    CreateSubNationalResponse,
    SubNational,
    DeleteSubNational,
    DeleteSubNationalResponse,
    GetSubNational,
    GetSubNationalResponse,
    UpdateSubNational,
    UpdateSubNationalResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface ISubNationalState {
    subNational: IBaseCrudState<SubNational>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const subNationalModule = createCrudModule<SubNational, CreateSubNationalResponse, GetSubNationalResponse, UpdateSubNationalResponse, DeleteSubNationalResponse, undefined>(
    'subNational',
    CreateSubNational,
    GetSubNational,
    UpdateSubNational,
    DeleteSubNational,
    undefined,
)


export default subNationalModule;