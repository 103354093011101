/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { toDate } from '@servicestack/client';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { AnalysisJobWindDispersionResult, AnalysisResultTimeScale, GetAnalysisJobTapmResultResponse, LatLngBounds, ResultGridCell, WindDispersionResultType } from '../dtos/Spread.dtos';
import { analysisTapmActions } from '../store/reducers/analysis/analysisTapm';
import { IRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useAnalysisWindDispersionResultsLoad(analysisMode: WindDispersionResultType, analysisTapmJobId?: number):
    [AnalysisJobWindDispersionResult[] | undefined,
        number,
        number,
        ResultGridCell[] | undefined,
        Date | undefined,
        Date | undefined,
        AnalysisResultTimeScale,
        LatLngBounds,
        IRequestState
    ] {
    const dispatch = useDispatch();
    const response = useSelector(state => state.analysisTapm.results[analysisTapmJobId ? analysisTapmJobId : -1]);
    const data: GetAnalysisJobTapmResultResponse = response && response[analysisMode];
    const requestState = useSelector(state => state.analysisTapm.loadResultsState);

    useEffect(() => {
        if (analysisTapmJobId && data === undefined) {
            dispatch(analysisTapmActions.loadResults(analysisTapmJobId, analysisMode))
        }
    }, [dispatch, analysisTapmJobId, analysisMode]);

    // cleanup
    useEffect(() => {
        return function cleanup() {
            dispatch(analysisTapmActions.clear())
        }
    }, [])

    const analysisStartDate = useMemo(() => {
        return data && toDate(data.analysisStartDate)
    }, [data]);

    const analysisEndDate = useMemo(() => {
        return data && toDate(data.analysisEndDate)
    }, [data]);

    return [
        data && data.results,
        data && data.resultGlobalMax,
        data && data.minimumCutOff,
        data && data.resultGrid,
        analysisStartDate,
        analysisEndDate,
        data && data.analysisResultTimeScale,
        data && data.latLngBounds,
        requestState
    ];
}
