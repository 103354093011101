import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface RadioWrapperProps extends FieldRenderProps<any, any> {
    formControlProps?: FormControlProps;
    items: {
        label: string,
        value: any,
    }[];
    label: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioGroup: {
            margin: theme.spacing(1)
        }
    }),
);

const RadioWrapper: React.FunctionComponent<RadioWrapperProps> = ({
    input: { value, name, onChange, ...restInput },
    meta,
    formControlProps,
    items,
    label,
    ...rest
}) => {
    const classes = useStyles();

    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.value);
    };

    const radios = items.map((item, i) =>
        <FormControlLabel
            value={item.value}
            control={<Radio
                {...rest}
                checked={item.value === value}
                key={`radio-${label}-${i}`}
                inputProps={restInput}

                value={item.value}
            />}
            key={`radioform-${label}-${i}`}
            label={item.label}
        />
    );

    return <FormControl {...formControlProps} error={showError}>
        {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <RadioGroup
            className={classes.radioGroup}
            name={name}
            onChange={onRadioChange}
        >
            {radios}
        </RadioGroup>
    </FormControl>;
}

export default RadioWrapper;