/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import EnrichmentMethodModule from '../../store/reducers/enrichmentMethods/enrichmentMethod';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IEnrichmentMethodEditFormProps {
    editEnrichmentMethod?: SpreadDtos.EnrichmentMethod;
    history: History;
    loadingEnrichmentMethod?: boolean;
    url: string;
}

interface IEnrichmentMethodFormSchema extends Partial<SpreadDtos.EnrichmentMethod> {
    name: string;
}

const EnrichmentMethodSchema = Yup.object<IEnrichmentMethodFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: EnrichmentMethodSchema.notRequired()
})


const EnrichmentMethodEditForm: React.FunctionComponent<IEnrichmentMethodEditFormProps> = ({
    editEnrichmentMethod,
    history,
    loadingEnrichmentMethod,
    url,
}) => {
    const editMode = editEnrichmentMethod !== undefined;

    const wrappedObject = editMode ? {
        object: editEnrichmentMethod
    } : undefined;

    return <EditForm
        actions={EnrichmentMethodModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingEnrichmentMethod}
        selectors={EnrichmentMethodModule.selectors}
        successRouteCallback={(d) => d ? `/admin/enrichmentMethod` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default EnrichmentMethodEditForm;