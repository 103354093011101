/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { fieldActions } from '../../store/reducers/fields/field';
import { RequestFormState } from '../../types/RequestState';
import FieldEditForm from '../field/FieldEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditFieldParams {
    fieldId: string;
}

interface IEditFieldProps {
    fieldId?: number;
    history: History;
    match: match<IEditFieldParams>;
}

export const EditField: React.FunctionComponent<IEditFieldProps> = ({ history, match, fieldId }) => {
    const fieldState = useSelector(state => state.field);
    const dispatch = useDispatch();
    const classes = useStyles();

    const calcFieldId = fieldId || (match.params ? parseInt(match.params.fieldId) : undefined);

    useEffect(() => {
        if(fieldActions.load){
            dispatch(fieldActions.load(calcFieldId))
        }

        return function cleanup() {
            dispatch(fieldActions.clear())
        }
    }, [calcFieldId, dispatch]);

    return <Paper className={classes.root}>
        {
            fieldState.data && <FieldEditForm
                editField={fieldState.data}
                history={history}
                loadingField={fieldState.states.loadState.state === RequestFormState.Pending && !fieldState.data}
                url={match.url}
            />
        }
    </Paper>
}