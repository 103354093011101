/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toDate } from '@servicestack/client';
import classNames from 'classnames';
import { format } from 'date-fns';
import { History } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { DateTimeFormat } from '../../constants/Dates';
import { areaDateSearchActions, areaDatesHooks } from '../../store/reducers/areaDates/areaDateSearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        areaDateListRow: {
        }
    }),
);

const headerColumns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Date Event' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Value' },
    { id: 'createdDate', numeric: false, disablePadding: false, label: 'Date Created' }
];

interface IAreaDateListProps {
    className?: string;
    clusterId?: number;
    epidemicId?: number;
    history: History;
    infectedPremiseId?: number;
    areaId?: number;
    url: string;
}

export const AreaDateList: React.FunctionComponent<IAreaDateListProps> = (props) => {
    const classes = useStyles();

    const specOps = React.useMemo(() => ({ areaId: props.areaId, clusterId: props.clusterId, epidemicId: props.epidemicId, infectedPremise: props.infectedPremiseId, }), [props.areaId, props.clusterId, props.epidemicId, props.infectedPremiseId]);
    const pagOps = React.useMemo(() => ({ orderBy: "date", skip: 0, take: 200 }), []);

    const [data, paginateOptions, , requestState] = areaDatesHooks.useSearch(specOps, pagOps);

    return <SortableTable
        className={classNames(classes.table, props.className)}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={areaDateSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        className={classes.areaDateListRow}
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${props.url}/${item.id}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.date && format(toDate(item.date), DateTimeFormat)}
                            {item.date && item.calculatedRecord ? '*' : ''}
                        </TableCell>
                        <TableCell align="right">
                            {item.createdDate ? format(toDate(item.createdDate), DateTimeFormat) : 'Unknown'}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There\'s nothing here!'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}