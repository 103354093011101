/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import * as React from 'react';
import { useMemo } from 'react';

import { toDate } from '@servicestack/client';
import { format } from 'date-fns';
import { CircleMarker, Popup, Polygon, GeoJSON } from "react-leaflet";

import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { InfectedPremise, AnalysisInfectedPremiseStatus } from '../../../../dtos/Spread.dtos';
import { DateTimeFormat } from '../../../../constants/Dates';
import ButtonLink from '../../../common/ButtonLink';
import { INetworkEdgeThatWillEventuallyBeADTO, networkEdges } from '../../../../helpers/tempData';
import { GenomicNetworkGraph } from './GenomicNetworkGraph';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface GenomicNetworkDisplayProps {
    networkEdges?: INetworkEdgeThatWillEventuallyBeADTO[];
    showProbabilities?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(2, 1),
            padding: theme.spacing(1, 2),
            width: '160px'
        },
        legendLine: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        legendColours: {
            backgroundImage: 'linear-gradient(to bottom, #84ACFF,#90E47C,#EAE667,#FF7686)',
            margin: '2px',
            padding: '100px 20px',
        },
        legendLabels: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column'
        },
        legendTitle: {
            paddingTop: theme.spacing(1)
        }
    })
);

export const GenomicNetworkDisplay: React.FunctionComponent<GenomicNetworkDisplayProps> = ({ networkEdges, showProbabilities }) => {
    const classes = useStyles();

    return <>
        {networkEdges && <GenomicNetworkGraph
            networkEdges={networkEdges}
            showProbabilities={showProbabilities}
        />}
    </>
}