

import * as React from 'react';
import { useContext } from 'react';
import { ColumnChart, LineChart } from 'react-chartkick';
import { eachDayOfInterval, format} from 'date-fns';
import { Typography } from '@material-ui/core';
import { toDate } from '@servicestack/client';
import * as d3 from 'd3';

import { InfectedPremise } from '../../dtos/Spread.dtos';
import { DateTimeFormat } from '../../constants/Dates';
import "../../assets/styleSheets/StyleSheet.css";
import { EpidemicResultsContext } from '../epidemic/EpidemicResultsContext';
import { addDays } from 'date-fns/esm';


interface IInfectedPremiseChartProps {
    data?: InfectedPremise[];
    simpleCount?: boolean;
    dateInterval?: number;
}


export const InfectedPremiseChart: React.FunctionComponent<IInfectedPremiseChartProps> = ({ data, simpleCount, dateInterval }) => {

    const epidemicResultContext = useContext(EpidemicResultsContext);

    const startDate = epidemicResultContext.currentStartDate;
    const endDate = epidemicResultContext.currentEndDate;

    const dashboard = (id: string, fData: ChartMultiType[]) => {

        var barColor = 'steelblue';
        function segColor(c: any) { return { total: "steelblue", atRisk: "#807dba", infected: "#f5ac56", postInfected: "#41ab5d" }[c]; }
        function hoverColor(c: any) { return { total: "steelblue", atRisk: "#5853b8", infected: "#ff8a00", postInfected: "#00c434" }[c]; }

        d3.selectAll('#dashboard svg').remove();
        d3.selectAll('#dashboard table').remove();
        // function to handle histogram.
        function histoGram(fD: ChartMultiType[]) {
            if (fD !== undefined) {
                var hg = {};
                var margin = { top: 20, right: 20, bottom: 30, left: 40 },
                    width = 1350 - margin.left - margin.right,
                    height = 500 - margin.top - margin.bottom;

                //create svg for histogram.
                var x = d3.scaleBand()
                    .range([0, width])
                    .padding(0.1);
                var y = d3.scaleLinear()
                    .range([height, 0]);
                var z = d3.scaleOrdinal(d3.schemeCategory10);

                const tooltipId = 'toolTip';

                var tooltip = d3.select(`#${tooltipId}`);

                if(tooltip.size() === 0){
                    const newTooltip = d3.select(id).append("div").attr("class", "toolTip").attr("id", tooltipId).html("<br />").style("padding", "10px").style("text-align", "center");

                    tooltip = d3.select(`#${tooltipId}`)
                }

                var svg = d3.select(id).append("svg")
                    .attr("class", "svg-body")
                    .attr("width", "100%")
                    .attr("height", height + margin.top + margin.bottom + 100)
                    .append("g")
                    .attr("transform",
                        "translate(" + margin.left + "," + margin.top + ")");

                var keys = ['atRisk', 'infected', 'postInfected'];

                x.domain(fD.map(function (d) { return new Date(d.date).toISOString().split('T')[0]; }));
                y.domain([0, Math.max.apply(Math, fD.map(function (d) { return d.count; }))]);
                z.domain(keys);




                z.domain(keys)

                var stack = d3.stack().keys(keys);
                var stackData = stack(fD);


                //create the rectangles.

                svg.selectAll(".bar")
                    .data(stackData)
                    .enter().append("g")
                    .attr("class", "bar")
                    .attr("fill", function (d) { return segColor(d.key); })
                    .selectAll("rect")
                    .data(function (d, i) { return d })
                    .enter().append("rect")
                    .attr("x", function (d) { return x(new Date(d.data["date"]).toISOString().split('T')[0]) || 0 })
                    .attr("width", x.bandwidth())
                    .attr("y", function (d) { return y(d[1]); })
                    .attr("height", function (d) { return y(d[0]) - y(d[1]); })
                    .on("mouseover", function (d) {
                        tooltip.style("width", "fit-content")
                        tooltip.html("<span>" + getKeyDisplayName(findKey(d)) + ": " + (d[1] - d[0]).toString() + "</span>")
                        let xPosition = Math.round(parseInt(d3.select(this).attr("x")) + x.bandwidth() / 2 + x.bandwidth() / 10).toString() + "px"
                        let yPosition = (0).toString() + "px"
                        d3.select(this).attr("fill", hoverColor(findKey(d)))
                        tooltip.style("left", xPosition)
                        tooltip.style("top", yPosition)
                        tooltip.style("position", "relative")
                        tooltip.style("border", "solid")
                        tooltip.style("border-radius", "6px")
                        tooltip.style("border-color", "#fff")
                        tooltip.style("color", "#fff")
                        tooltip.style("box-shadow", "0 4px 8px 0 rgba(0, 0, 0, 0.2)")
                        tooltip.style("background", "rgba(129, 161, 161, 0.8)")
                    })// mouseover is defined below.
                    .on("mouseout", function (d, i) {
                        //tooltip.html("<br />")
                        //tooltip.style("box-shadow", "0 4px 8px 0 rgba(0, 0, 0, 0.0)")
                        //tooltip.style("border-color", "transparent")
                        //tooltip.style("background", "transparent")
                        //tooltip.style("color", "transparent")
                        d3.select(this).attr("fill", segColor(findKey(d)));
                    });// mouseout is defined below.;



                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .style("font", "12px sans-serif")
                    .call(d3.axisBottom(x));

                // add the y Axis
                svg.append("g")
                    .style("font", "12px sans-serif")
                    .call(d3.axisLeft(y));

                var legend = svg.selectAll(".legend")
                    .data(keys.reverse())
                    .enter().append("g")
                    .attr("class", "legend")
                    .attr("transform", function (d, i) { return "translate(0, " + i * 20 + ")" });

                legend.append("rect")
                    .style("fill", function (d) { return segColor(d); })
                    .attr("x", margin.left + 18)
                    .attr("y", margin.top + margin.bottom + height)
                    .attr("width", 18)
                    .attr("height", 18);

                legend.append("text")
                    .style("font", "14px sans-serif")
                    .attr("x", margin.left + 44)
                    .attr("y", margin.top + margin.bottom + height + 10)
                    .attr("dy", ".35em")
                    .attr("text-anchor", "start")
                    .text(function (d) { return d.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(' '); });

                return svg;
            }
            return null;

        }

        function getKeyDisplayName(key: string) {
            let name = key.split(/(?=[A-Z])/);
            let displayName = "";
            name.forEach(function (word) {
                displayName = displayName + word.charAt(0).toUpperCase() + word.slice(1) + " ";
            })
            return displayName.trim()
        }

        function findKey(data: any) {
            if (data[1] - data[0] === data.data.atRisk)
                return "atRisk";
            else if (data[1] - data[0] === data.data.infected)
                return "infected";
            else if (data[1] - data[0] === data.data.postInfected)
                return "postInfected";
            else return "total";
        }

        //function pieChart( pD: any ) {
        //    var margin = { top: 20, right: 20, bottom: 30, left: 40 },
        //        width = 400 - margin.left - margin.right,
        //        height = 400 - margin.top - margin.bottom,
        //        radius = Math.min(width, height) / 2;

        //    var piesvg = d3.select(pieId).append("svg")
        //        .attr("width", width).attr("height", height).append("g")
        //        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")"),
        //        g = piesvg.append("g");

        //    // create function to draw the arcs of the pie slices.
        //    var arc = d3.arc().outerRadius(radius - 40).innerRadius(radius - 40);

        //    var arr = pD.map(function (d: any) { return d.total });

        //    var pie = d3.pie()(arr);

        //    var background = g.selectAll("path")
        //        .data(pie)
        //        .enter()
        //        .append("path")
        //        .style("fill", function (d: any) {
        //            return segColor(d.data.type);
        //        })
        //        .attr("d", arc)

        //}


        histoGram(fData);
        //pieChart(tf);

    }

    let initialChartSteps = startDate && endDate ? eachDayOfInterval({ start: startDate, end: endDate }, { step: dateInterval }) : undefined

    if(endDate && initialChartSteps && initialChartSteps[initialChartSteps.length - 1] < endDate){
        const finalStep = addDays(initialChartSteps[initialChartSteps.length - 1], dateInterval ? dateInterval : 1);
        initialChartSteps.push(finalStep)
    }

    const chartSteps = initialChartSteps;

    const totalCount: ChartMultiType[] = chartSteps ? chartSteps.map((step, index) => {
        return {
            index: index,
            date: step.getTime(),
            count: data ? data.length : 0,
            atRisk: data ? data.filter(d => d.infectedPremiseDateSet && toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) > step).length : 0,
            infected: data ? data.filter(d => d.infectedPremiseDateSet && (toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= step && toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= step)).length : 0,
            postInfected: data ? data.filter(d => d.infectedPremiseDateSet && toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) < step).length : 0
        }
    }) : [];

    const simpleIpCount: ChartDataType[] = chartSteps ? chartSteps.map((step) => {
        return {
            date: step.toDateString(),
            count: data ? data.filter(d => d.infectedPremiseDateSet && (toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= step && toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= step)).length : 0
        }
    }) : [];

    const atRisk: ChartDataType[] = chartSteps ? chartSteps.map((step) => {
        return {
            date: step.toDateString(),
            count: data ? data.filter(d => d.infectedPremiseDateSet &&  toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) > step).length : 0
        }
    }) : [];

    const infected: ChartDataType[] = chartSteps ? chartSteps.map((step) => {
        return {
            date: step.toDateString(),
            count: data ? data.filter(d => d.infectedPremiseDateSet &&  (toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= step && toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= step)).length : 0
        }
    }) : [];

    const postInfected: ChartDataType[] = chartSteps ? chartSteps.map((step) => {
        return {
            date: step.toDateString(),
            count: data ? data.filter(d => d.infectedPremiseDateSet &&  toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) < step).length : 0
        }
    }) : [];

    const mystyles = {
        width: '100%',
        height: '100%'
    } as React.CSSProperties;

    const complexLabelled = [
        {
            name: "At risk",
            data: atRisk
        },
        {
            name: "Infected",
            data: infected
        },
        {
            name: "Culled",
            data: postInfected
        }
    ]

    if (simpleIpCount.length > 0 && Math.max.apply(Math, simpleIpCount.map(function (d) { return d.count })) > 0 ){
        dashboard('#dashboard', totalCount);
    }

    const lineChartSteps = startDate && endDate ? eachDayOfInterval({ start: startDate, end: endDate }, { step: 1 }) : undefined;

    const IpCount: Types[] = lineChartSteps ? lineChartSteps.map((step) => {
        return [
            format(step, DateTimeFormat),
            data ? data.filter(d => d.infectedPremiseDateSet &&  (toDate(d.infectedPremiseDateSet.earliestStartOfInfectiousPeriodDate) <= step && toDate(d.infectedPremiseDateSet.latestEndOfInfectiousPeriodDate) >= step)).length : 0
        ]
    }) : [];

    return <div id="charts">
        <div id='dashboard'>

        </div>
        <Typography variant="h5">Number of Infected Premises</Typography>
        <LineChart data={IpCount} colors={["#ff302f"]} curve={false} />
    </div>;

}

type Types = (string | number)[];

type ChartDataType = { date: string, count: number };

type ChartMultiType = { index: number, date: number, count: number, atRisk: number, infected: number, postInfected: number };

type Counts = { atRisk: number, infected: number, postInfected: number }

interface ITestChartProps {
    colours: any;
    data?: any;// ChartDataType[];
}


const TestChart: React.FunctionComponent<ITestChartProps> = (props) => {

    const testData = [
        [
            1, 50
        ],
        [
            2, 50
        ],
        [
            3, 50
        ],
        [
            4, 50
        ]
    ]

    const testDataSets = [
        {
            name: "At risk",
            data: [["2010", 10], ["2020", 16], ["2030", 28]]
        },
        {
            name: "Infected",
            data: [["2010", 24], ["2020", 22], ["2030", 19]]
        },
        {
            name: "Post-Infected",
            data: [["2010", 20], ["2020", 23], ["2030", 29]]
        }
    ]

    return <ColumnChart
        colors={props.colours}
        //data={testDataSets}
        data={props.data}
        height={500}
        stacked={true}
    />
}