/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { match, Route } from 'react-router';
import { History } from 'history';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { ViewPersonRoles } from '../roles/ViewPersonRoles';
import { CreateGroupPersonRole } from '../roles/CreateGroupPersonRole';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        inviteButton: {
            marginRight: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        modal: {
            /*top: '50%',
            left: '50%',
            position: 'absolute',
            transform: `translate(-50%, -50%)`,*/
            width: 350
        }
    }),
);


interface IGroupManageRoleHomeProps {
    history: History;
    match: match<any>;
}

export const GroupManageRoleHome: React.FunctionComponent<IGroupManageRoleHomeProps> = ({
    history,
    match
}) => {
    const group = useSelector(state => state.group.data);
    const classes = useStyles();

    return <Box className={classes.root} component="div">
        <Route exact path={`${match.path}`}>
            <Box className={classes.layoutItem}>
                <ViewPersonRoles
                    personGroupId={group && group.id}
                    match={match}
                />
            </Box>
        </Route>
        <Route exact path={`${match.path}/create`}>
            <Box className={classes.layoutItem}>
                {
                    group && <CreateGroupPersonRole
                        group={group}
                        history={history}
                        match={match}
                    />
                }
            </Box>
        </Route>
    </Box>
}