/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { connectRouter, routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import createReduxPromiseListener from 'redux-promise-listener';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { combinedEpics, combinedReducers } from './reducers';
import { IAnalysisSearchState } from './reducers/analysis/analysisSearch';
import { IAnalysisTapmState } from './reducers/analysis/analysisTapm';
import { IAnalysisTapmDataSetSearchState } from './reducers/analysis/analysisTapmDataSetSearch';
import { IAreaDateState } from './reducers/areaDates/areaDate';
import { IAreaDateSearchState } from './reducers/areaDates/areaDateSearch';
import { IAreaDateTypeState } from './reducers/areaDateTypes/areaDateType';
import { IAreaDateTypeSearchState } from './reducers/areaDateTypes/areaDateTypeSearch';
import { IAreaLivestockCountState } from './reducers/areaLivestockCounts/areaLivestockCount';
import { IAreaLivestockCountSearchState } from './reducers/areaLivestockCounts/areaLivestockCountSearch';
import { IAreaSearchState } from './reducers/areas/areaSearch';
import { IImportDatesState } from './reducers/bulkUpload/importDates';
import { IImportEpidemicState } from './reducers/bulkUpload/importEpidemic';
import { IImportHierarchyState } from './reducers/bulkUpload/importHierarchy';
import { IImportLivestockState } from './reducers/bulkUpload/importLivestock';
import { IImportLogState } from './reducers/bulkUpload/importLog';
import { IImportLogSearchState } from './reducers/bulkUpload/importLogSearch';
import { IImportPreParseState } from './reducers/bulkUpload/importPreParse';
import { IImportSpatialState } from './reducers/bulkUpload/importSpatial';
import { IClassificationState } from './reducers/classifications/classification';
import { IClassificationSearchState } from './reducers/classifications/classificationSearch';
import { IClusterState } from './reducers/clusters/cluster';
import { IClusterSearchState } from './reducers/clusters/clusterSearch';
import { ICountryState } from './reducers/countries/country';
import { ICountrySearchState } from './reducers/countries/countrySearch';
import { IDiseaseClassificationState } from './reducers/diseaseClassifications/diseaseClassification';
import { IDiseaseClassificationSearchState } from './reducers/diseaseClassifications/diseaseClassificationSearch';
import { IDiseaseState } from './reducers/diseases/disease';
import { IDiseaseSearchState } from './reducers/diseases/diseaseSearch';
import { IDiseaseSubClassificationState } from './reducers/diseaseSubClassifications/diseaseSubClassification';
import { IDiseaseSubClassificationSearchState } from './reducers/diseaseSubClassifications/diseaseSubClassificationSearch';
import { IEnrichmentMethodState } from './reducers/enrichmentMethods/enrichmentMethod';
import { IEnrichmentMethodSearchState } from './reducers/enrichmentMethods/enrichmentMethodSearch';
import { IEpidemicState } from './reducers/epidemics/epidemic';
import { IEpidemicPersonGroupAccessSearchState } from './reducers/epidemics/epidemicPersonGroupAccessSearch';
import { IEpidemicSearchState } from './reducers/epidemics/epidemicSearch';
import { IExcretionState } from './reducers/excretion/excretion';
import { IExcretionSearchState } from './reducers/excretion/excretionSearch';
import { IFieldState } from './reducers/fields/field';
import { IFieldSearchState } from './reducers/fields/fieldSearch';
import { IGenomicNetworkState } from './reducers/genomic/genomicNetwork';
import { IGenomicNetworkSearchState } from './reducers/genomic/genomicNetworkSearch';
import { IGroupEpidemicState } from './reducers/groupEpidemics/groupEpidemic';
import { IGroupEpidemicSearchState } from './reducers/groupEpidemics/groupEpidemicSearch';
import { IGroupState } from './reducers/groups/group';
import { IGroupInviteState } from './reducers/groups/groupInvite';
import { IGroupMemberState } from './reducers/groups/groupMember';
import { IGroupSearchState } from './reducers/groups/groupSearch';
import { IInfectedPremiseState } from './reducers/infectedPremises/infectedPremise';
import { IInfectedPremiseSearchState } from './reducers/infectedPremises/infectedPremiseSearch';
import { IInstrumentState } from './reducers/instruments/instrument';
import { IInstrumentSearchState } from './reducers/instruments/instrumentSearch';
import { ILibraryStrategyState } from './reducers/libraryStrategys/libraryStrategy';
import { ILibraryStrategySearchState } from './reducers/libraryStrategys/libraryStrategySearch';
import { ILivestockState } from './reducers/livestock/livestock';
import { ILivestockSearchState } from './reducers/livestock/livestockSearch';
import { ILivestockDefaultState } from './reducers/livestockDefaults/livestockDefault';
import { ILivestockDefaultSearchState } from './reducers/livestockDefaults/livestockDefaultSearch';
import { ILivestockLocationState } from './reducers/livestockLocations/livestockLocation';
import { ILivestockLocationSearchState } from './reducers/livestockLocations/livestockLocationSearch';
import { IMaterialState } from './reducers/materials/material';
import { IMaterialSearchState } from './reducers/materials/materialSearch';
import { IMSAState } from './reducers/msa/msa';
import { IMSASearchState } from './reducers/msa/msaSearch';
import { IParcelState } from './reducers/parcels/parcel';
import { IParcelSearchState } from './reducers/parcels/parcelSearch';
import { IPathogenState } from './reducers/pathogens/pathogen';
import { IPathogenSearchState } from './reducers/pathogens/pathogenSearch';
import { IAuthenticatedPersonState } from './reducers/person/authenticatedPerson';
import { IPasswordResetState } from './reducers/person/passwordReset';
import { IPersonState } from './reducers/person/person';
import { IPersonSearchState } from './reducers/person/personSearch';
import { IRegisterPersonState } from './reducers/person/registerPerson';
import { IPlatformState } from './reducers/platforms/platform';
import { IPlatformSearchState } from './reducers/platforms/platformSearch';
import { IPersonRoleState } from './reducers/roles/personRole';
import { IPersonRoleSearchState } from './reducers/roles/personRoleSearch';
import { IRoleSearchState } from './reducers/roles/roleSearch';
import { IConsensusSequenceState } from './reducers/sequences/consensusSequence';
import { ISequenceState } from './reducers/sequences/sequence';
import { ISequenceRetrievalState } from './reducers/sequences/sequenceRetrieval';
import { ISequenceSearchState } from './reducers/sequences/sequenceSearch';
import { ISequenceViewSearchState } from './reducers/sequences/sequenceViewSearch';
import { ISpeciesState } from './reducers/species/species';
import { ISpeciesSearchState } from './reducers/species/speciesSearch';
import { ISpecimenState } from './reducers/specimens/specimen';
import { ISpecimenSearchState } from './reducers/specimens/specimenSearch';
import { ISubClassificationState } from './reducers/subClassifications/subClassification';
import { ISubClassificationSearchState } from './reducers/subClassifications/subClassificationSearch';
import { ISubClusterState } from './reducers/subClusters/subCluster';
import { ISubClusterSearchState } from './reducers/subClusters/subClusterSearch';
import { ISubNationalState } from './reducers/subNationals/subNational';
import { ISubNationalSearchState } from './reducers/subNationals/subNationalSearch';
import { IThemeState } from './reducers/theme/theme';
import { ITissueState } from './reducers/tissues/tissue';
import { ITissueSearchState } from './reducers/tissues/tissueSearch';
import { ITooltipsState } from './reducers/tooltips/tooltips';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

// Create promise middleware to connect redux actions to promises as final-form expects promises 
const reduxPromiseListener = createReduxPromiseListener()

export interface IStoreState extends
    IAnalysisTapmState,
    IAnalysisSearchState,
    IAnalysisTapmDataSetSearchState,
    IAreaSearchState,
    IAreaDateState,
    IAreaDateSearchState,
    IAreaDateTypeState,
    IAreaDateTypeSearchState,
    IAreaLivestockCountState,
    IAreaLivestockCountSearchState,
    IAuthenticatedPersonState,
    IClusterState,
    IClusterSearchState,
    IConsensusSequenceState,
    IDiseaseState,
    IDiseaseSearchState,
    IDiseaseClassificationState,
    IDiseaseClassificationSearchState,
    IDiseaseSubClassificationState,
    IDiseaseSubClassificationSearchState,
    IEpidemicState,
    IEpidemicSearchState,
    IEpidemicPersonGroupAccessSearchState,
    IExcretionState,
    IExcretionSearchState,
    IFieldState,
    IFieldSearchState,
    IGenomicNetworkState,
    IGenomicNetworkSearchState,
    IGroupState,
    IGroupInviteState,
    IGroupMemberState,
    IGroupSearchState,
    IGroupEpidemicState,
    IGroupEpidemicSearchState,
    IImportEpidemicState,
    IImportDatesState,
    IImportHierarchyState,
    IImportLivestockState,
    IImportLogState,
    IImportLogSearchState,
    IImportPreParseState,
    IImportSpatialState,
    IInfectedPremiseState,
    IInfectedPremiseSearchState,
    ILivestockState,
    ILivestockSearchState,
    ILivestockDefaultState,
    ILivestockDefaultSearchState,
    ILivestockLocationState,
    ILivestockLocationSearchState,
    IMSAState,
    IMSASearchState,
    IParcelState,
    IParcelSearchState,
    IPasswordResetState,
    IPersonRoleState,
    IPersonRoleSearchState,
    IPersonState,
    IPersonSearchState,
    IRegisterPersonState,
    IRoleSearchState,
    ISequenceState,
    ISequenceRetrievalState,
    ISequenceSearchState,
    ISequenceViewSearchState,
    ISpeciesState,
    ISpeciesSearchState,
    ISpecimenSearchState,
    ISpecimenState,
    ISpecimenSearchState,
    ISubClusterState,
    ISubClusterSearchState,
    ICountryState,
    ICountrySearchState,
    ISubNationalState,
    ISubNationalSearchState,
    IPathogenState,
    IPathogenSearchState,
    IClassificationState,
    IClassificationSearchState,
    ISubClassificationState,
    ISubClassificationSearchState,
    ITissueState,
    ITissueSearchState,
    IMaterialState,
    IMaterialSearchState,
    IEnrichmentMethodState,
    IEnrichmentMethodSearchState,
    ILibraryStrategyState,
    ILibraryStrategySearchState,
    IInstrumentState,
    IInstrumentSearchState,
    IPlatformState,
    IPlatformSearchState,
    ITooltipsState,
    IThemeState { };

/**
 * This function initialises the redux store and its associated middleware.
 * @param history The history to be used with the router middleware.
 * @returns the store.
 */
const spreadStore = (
    history: History
): Store<IStoreState> => {
    // Create router middleware with the provided history.
    const routerMiddleware = createRouterMiddleware(history);

    // Create logic middleware with the currently registered logic.
    const epicMiddleware = createEpicMiddleware();

    // Create middleware
    let middleware = null;

    if (process.env.NODE_ENV === 'production') {
        middleware = applyMiddleware(routerMiddleware, epicMiddleware, reduxPromiseListener.middleware);
    }
    else {
        const logger = require('redux-logger').default;

        middleware = applyMiddleware(routerMiddleware, epicMiddleware, reduxPromiseListener.middleware, logger);
    }

    const composeEnhancers = process.env.NODE_TARGET !== 'server' &&
        process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 500 }) :
        compose;

    const reducer = combineReducers(
        {
            router: connectRouter(history),
            ...combinedReducers
        });

    const store = createStore(reducer, undefined, composeEnhancers(middleware));

    epicMiddleware.run(combinedEpics);

    return store;
};

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const promiseListener = reduxPromiseListener;

export default spreadStore;