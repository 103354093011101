/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file defines functions for evaluating descriptions for enums.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AreaLevel, SpreadWorkflowJobStatus } from '../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */


export function getEnumKeyByEnumValue(tEnum: any, enumValue: any) {
    let keys = Object.keys(tEnum).filter(x => tEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
}

export function areaLevelDescription(areaLevel: AreaLevel | undefined) {
    switch (areaLevel) {
        case undefined:
        case null:
            return "Area";
        case AreaLevel.InfectedPremise:
            return "Infected Premise";
        case AreaLevel.Parcel:
        case AreaLevel.Field:
        default:
            return areaLevel.toString();
    }
}

export function areaLevelChildDescription(areaLevel: AreaLevel | undefined) {
    switch (areaLevel) {
        case AreaLevel.InfectedPremise:
            return "Parcel";
        case AreaLevel.Parcel:
            return "Field";
        case undefined:
        case null:
        case AreaLevel.Field:
        default:
            return "Area";
    }
}

export function spreadWorkflowJobStatusDescription(spreadWorkflowJobStatus: SpreadWorkflowJobStatus | undefined) {
    switch (spreadWorkflowJobStatus) {
        case undefined:
        case null:
            return "Status";
        case SpreadWorkflowJobStatus.CreateError:
            return "Creation Error";
        case SpreadWorkflowJobStatus.InitError:
            return "Initialization Error";
        case SpreadWorkflowJobStatus.Inited:
            return "Initialized";
        case SpreadWorkflowJobStatus.Initing:
            return "Initializing";
        case SpreadWorkflowJobStatus.ResultsDownloadError:
            return "Results Download Error";
        case SpreadWorkflowJobStatus.ResultsDownloaded:
            return "Results Downloaded";
        case SpreadWorkflowJobStatus.ResultsDownloading:
            return "Results Downloading";
        case SpreadWorkflowJobStatus.ResultsProcessError:
            return "Results Process Error";
        case SpreadWorkflowJobStatus.ResultsProcessed:
            return "Results Processed";
        case SpreadWorkflowJobStatus.ResultsProcessing:
            return "Results Processing";
        default:
            return spreadWorkflowJobStatus.toString();
    }
}

/*
export function countryDescription(country: CountryName) {
    switch (country) {
        case Country.Aus:
            return "Australia";
        case Country.UK:
        case Country.Fra:
        case Country.Ger:
        case Country.Den:
        case Country.Unspecified:
        default:
            return country.toString();
    }
}
*/