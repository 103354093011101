/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
import { History } from 'history';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { CreateGroupMemberInvite } from './CreateGroupMemberInvite';
import { GroupMemberInviteList } from './GroupMemberInviteList';
import { HasPermission } from '../common/HasPermission';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        inviteButton: {
            marginRight: theme.spacing(1),
        },
        modal: {
            /*top: '50%',
            left: '50%',
            position: 'absolute',
            transform: `translate(-50%, -50%)`,*/
            width: 350
        }
    }),
);


interface IGroupMemberInviteHomeProps {
    history: History;
    match: match<any>;
}

export const GroupMemberInviteHome: React.FunctionComponent<IGroupMemberInviteHomeProps> = ({
    history,
}) => {
    const group = useSelector(state => state.group.data);
    const dispatch = useDispatch();
    const classes = useStyles();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleOpenInvite = () => {
        setModalOpen(true);
    };

    const handleCloseInvite = () => {
        setModalOpen(false);
    };

    return <Paper className={classes.root}>
        {
            group ?
                <Dialog open={modalOpen} onClose={handleCloseInvite}>
                    <CreateGroupMemberInvite className={classes.modal} groupId={group.id} />
                </Dialog> :
                null
        }
        <Typography variant="h4" component="h4">Pending Invites</Typography>
        {
            group ? <GroupMemberInviteList
                history={history}
                groupId={group && group.id}
            /> :
                undefined
        }
        {
            group && <Box display="flex" justifyContent="flex-end">
                <HasPermission permission="CreateGroupMemberInvite" objectId={group.id}>
                    <Button
                        aria-label="Edit Group"
                        className={classes.inviteButton}
                        color="primary"
                        variant="contained"
                        onClick={handleOpenInvite}
                    >
                        <PersonAddIcon className={classes.buttonSubmitIcon} />
                        Invite
                    </Button>
                </HasPermission>
            </Box>
        }
    </Paper>
}