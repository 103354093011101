/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ContentLogo } from './ContentLogo';
import { ContentMenu } from './ContentMenu';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backing: {
            bottom: '-2px',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: '#0E122F'
        },
        footerContainer: {
            position: 'relative',
            color: 'white'
        },
        wrapper: {
            top: '100%',
            position: 'relative',
            width: '100%'
        },
        content: {
            height: '100px'
        }
    }),
);

interface IContentFooterProps {
}

export const ContentFooter: React.FunctionComponent<IContentFooterProps> = (props) => {
    const classes = useStyles(props);
    const matchSmall = useMediaQuery(theme => (theme as Theme).breakpoints.down('sm'));
    const matchLarge = useMediaQuery(theme => (theme as Theme).breakpoints.up('lg'));

    /*const textClass = classNames(classes.text, {
        [classes.textSmall]: matchSmall,
        [classes.textLarge]: matchLarge,
    })*/

    return <Box className={classes.wrapper}>
        <div className={classes.backing}></div>
        <Container fixed className={classes.footerContainer}>
            <Box className={classes.content} display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <ContentLogo
                    color={'white'}
                />
                <ContentMenu />
            </Box>
        </Container>
    </Box>
}
