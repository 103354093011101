/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an field
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActions, createCrudActionTypes, createCrudEpics, createCrudSelectors, IBaseCrudState, IBaseCrudTypes } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialFieldState extends IBaseCrudState {
    data?: SpreadDtos.Field;
}

export interface IFieldState {
    field: IInitialFieldState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IFieldActionTypes {
    CLEAR: string;
}

const fieldTypes: IBaseCrudTypes & IFieldActionTypes = {
    ...createCrudActionTypes('field'),
    CLEAR: '@@field/CLEAR',
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialFieldState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    },
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const fieldReducer = createReducer<IInitialFieldState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const fieldActions = {
    ...createCrudActions<SpreadDtos.Field, SpreadDtos.CreateFieldResponse, SpreadDtos.GetFieldResponse, SpreadDtos.UpdateFieldResponse, undefined, undefined>(fieldTypes),
    clear: createAction(fieldTypes.CLEAR,
        () => ({})
    ),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(fieldReducer, fieldActions, 'field', initialState);

// Clear Reducer
fieldReducer.on(fieldActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
fieldReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const fieldsApi = {
    create: createPostRequest(
        SpreadDtos.CreateField,
        (field: SpreadDtos.Field) => ({
            field: field
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetField,
        (fieldId: number) => ({
            id: fieldId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateField,
        (field: SpreadDtos.Field) => ({
            field: field
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(fieldActions, fieldsApi);

export const fieldEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const fieldSelectors = createCrudSelectors('field');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default fieldReducer;