/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple importLog jobs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { ISearchState, initialSearchState, createAutoQueryModule } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialImportLogSearchState = ISearchState<SpreadDtos.ImportLog>;

export interface IImportLogSearchState {
    importLogSearch: IInitialImportLogSearchState;
}

export const initialState: IImportLogSearchState = {
    importLogSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const importLogSearchModule = createAutoQueryModule(
    'importLogSearch',
    initialSearchState,
    '@@importLogSearch', 
    SpreadDtos.QueryImportLog, 
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) =>({
        epidemicId: options.specialOptions.epidemicId
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export default importLogSearchModule;