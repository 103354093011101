/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

export function routeBackToPath(url: string, iterations?: number, to?: string | number) {
    if (!iterations) {
        iterations = 1;
    }

    let baseUrl = url;

    for (let i = 0; i < iterations; i++) {
        baseUrl = baseUrl.substr(0, baseUrl.lastIndexOf('/'));
    }

    if (to !== undefined) {
        return baseUrl + `/${to}`;
    }

    return baseUrl;
}