/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useState } from 'react';

import { Theme } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ContentFooter } from './ContentFooter';
import { ContentLogo } from './ContentLogo';
import { ContentMenu } from './ContentMenu';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IContentLayoutProps {
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerWrapper: {
            height: '150px',
            backgroundColor: 'white'
        },
        header: {
            height: '150px',
        },
        container: {
            paddingLeft: 0,
            paddingRight: 0
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        pageWrapper: {
            minHeight: '100vh'
        }
    })
);

export const ContentLayout: React.FunctionComponent<IContentLayoutProps> = (props) => {

    const classes = useStyles(props);

    const [drawerOpen, drawerOpenUpdate] = useState(false);

    function toggleDrawerOpen() {
        drawerOpenUpdate(!drawerOpen);
    }

    return (<div className={classes.pageWrapper}>
        <Box className={classes.headerWrapper}>
            <Container fixed className={classes.header}>
                <Box className={classes.header} display="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
                    <ContentLogo
                        color={'primary'}
                    />
                    <ContentMenu />
                </Box>
            </Container>
        </Box>
        {props.children}
        <ContentFooter />
    </div>);
}
