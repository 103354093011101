/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { match } from 'react-router';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewPersonParams {
    personId: string;
}

interface IViewPersonProps {
    person?: SpreadDtos.Person;
    loading: boolean;
}

export const ViewPerson: React.FunctionComponent<IViewPersonProps> = ({
    person,
    loading,
}) => {
    const classes = useStyles();

    return <Paper className={classes.root}><Typography variant="h4" component="h4">{!loading ? person && person.displayName : <Skeleton />}</Typography>
        <Typography variant="h5" component="h5">{!loading ? person && person.email : <Skeleton />}</Typography>
        {/*
            <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
                <HasPermission permission="UpdatePerson">
                    <Button
                        aria-label="Edit Person"
                        color="primary"
                        variant="contained"
                        onClick={handleEdit}
                    >
                        <EditIcon className={classes.buttonSubmitIcon} />
                        Edit
                    </Button>
                </HasPermission>
            </Box>
        */}
    </Paper>
}