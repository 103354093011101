/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useState } from 'react';

import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import MenuIcon from '@material-ui/icons/Menu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ContentLogo } from './ContentLogo';
import { ListItemNavLink } from '../common/ListItemLink';
import ButtonLink from '../common/ButtonLink';
import { useSelector } from '../../hooks/useTypedSelector';
import { AuthState } from '../../types/AuthState';
import { ContentNav } from './ContentNav';
import { IContentNavItem } from './content';
import ButtonExternalLink from '../common/ButtonExternalLink';
import { ListItemExternalLink } from '../common/ListItemExternalLink';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerItem: {
            "&:hover": {
                background: lighten(theme.palette.primary.dark, 0.15)
            },
            paddingLeft: theme.spacing(3)
        },
        drawerItemActive: {
            color: lighten(theme.palette.primary.main, 0.4)
        },
        drawerList: {
            paddingTop: 0,
        },
        wrapper: {},
        menuButton: {
            fontSize: '3rem',
            marginRight: theme.spacing(2)
        },
        menuButtonIcon: {
            fontSize: '3rem'
        }

    }),
);

interface IContentMenuProps {
}


const items: IContentNavItem[] = [
    {
        label: 'Home',
        icon: <DashboardIcon />,
        to: '/'
    },
    {
        externalLink: true,
        label: 'Wiki',
        icon: <DashboardIcon />,
        to: 'https://confluence.csiro.au/display/SPREADwiki/SPREAD-Wiki'
    },
    {
        externalLink: true,
        label: 'Contact Us',
        icon: <DashboardIcon />,
        to: 'https://confluence.csiro.au/display/SPREADwiki/Contact+Us'
    },
    {
        externalLink: true,
        label: 'About Us',
        icon: <DashboardIcon />,
        to: 'https://confluence.csiro.au/display/SPREADwiki/About+Us'
    },
    {
        authProtected: true,
        authFailLabel: 'Login',
        authFailTo: '/login',
        label: 'Go to application',
        icon: <DashboardIcon />,
        to: '/task'
    }
];

export const ContentMenu: React.FunctionComponent<IContentMenuProps> = (props) => {
    const classes = useStyles(props);

    const [drawerOpen, setDrawerOpen] = useState(false);

    function toggleDrawerOpen() {
        setDrawerOpen(!drawerOpen);
    }

    const userAuthenticatedState = useSelector(state => state.authenticatedPerson.authState);

    const itemsState = items.map((menuItem, index) => {
        const menuItemTo = menuItem.authProtected && userAuthenticatedState !== AuthState.Authenticated ? menuItem.authFailTo : menuItem.to;
        const menuItemLabel = menuItem.authProtected && userAuthenticatedState !== AuthState.Authenticated ? menuItem.authFailLabel : menuItem.label;

        return {
            externalLink: menuItem.externalLink,
            to: menuItemTo ? menuItemTo : '',
            loading: menuItem.authProtected && userAuthenticatedState === AuthState.Pending,
            label: menuItemLabel,
            lastItem: index === items.length - 1 && '|',
            icon: menuItem.icon
        };
    });

    const drawer = (
        <div>
            <Divider />
            <List className={classes.drawerList}>
                {
                    itemsState.map((menuItem) => {

                        const linkInternal = <>
                            {
                                menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
                            <ListItemText primary={
                                !menuItem.loading
                                    ? menuItem.label
                                    : <Skeleton width={'100px'} />
                            } />
                        </>

                        return <React.Fragment key={menuItem.label + menuItem.to}>
                            {
                                menuItem.externalLink
                                    ? <ListItemExternalLink className={classes.drawerItem} href={menuItem.to}>
                                        {linkInternal}
                                    </ListItemExternalLink>
                                    : <ListItemNavLink activeClassName={classes.drawerItemActive} className={classes.drawerItem} to={menuItem.to}>
                                        {linkInternal}
                                    </ListItemNavLink>
                            }

                            {
                                !menuItem.lastItem && <Divider />
                            }
                        </React.Fragment>
                    }
                    )
                }
            </List>
        </div>);

    return <Box className={classes.wrapper} display='flex' flexDirection='row' alignItems='center'>

        <Hidden mdUp implementation="css">
            <ContentNav
                drawerOpen={drawerOpen}
                drawer={drawer}
                toggleDrawerOpen={toggleDrawerOpen}
                title={'SPREAD'}
            />

            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawerOpen}>
                <MenuIcon className={classes.menuButtonIcon} />
            </IconButton>
        </Hidden>

        <Hidden smDown implementation="css">
            {
                itemsState.map((menuItem, index) => {

                    const linkInternal = !menuItem.loading
                        ? menuItem.label
                        : <Skeleton width={'100px'} />;

                    return <React.Fragment key={menuItem.label + menuItem.to}>
                        {
                            menuItem.externalLink
                                ? <ButtonExternalLink color="inherit" href={menuItem.to}>
                                    {
                                        linkInternal
                                    }
                                </ButtonExternalLink>
                                : <ButtonLink color="inherit" to={menuItem.to}>
                                    {
                                        linkInternal
                                    }
                                </ButtonLink>
                        }
                        {
                            !menuItem.lastItem && '|'
                        }
                    </React.Fragment>
                })
            }
        </Hidden>
    </Box>
}
