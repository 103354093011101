/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { formatDate, parseDate } from '../../helpers/dateHelpers';
import { routeBackToPath } from '../../helpers/routeHelpers';
import { areaDateActions, areaDateSelectors } from '../../store/reducers/areaDates/areaDate';
import { areaDateTypeSearchHooks } from '../../store/reducers/areaDateTypes/areaDateTypeSearch';
import { RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import DatePickerWrapper from '../forms/FinalFormControls/DatePickerWrapper';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAreaDateEditFormProps {
    editAreaDate?: SpreadDtos.AreaDate;
    history: History;
    loadingAreaDate?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IAreaFormSchema extends Partial<SpreadDtos.AreaDate> {
}

const areaDateSchema = Yup.object<IAreaFormSchema>().shape({
    date: Yup.string().label('Date').required(),
    areaDateTypeId: Yup.number().typeError('You must specify a type').label('Date Type').required(),
});

const validation = Yup.object().shape({
    object: areaDateSchema.notRequired()
})

const AreaDateEditForm: React.FunctionComponent<IAreaDateEditFormProps> = ({
    editAreaDate,
    history,
    loadingAreaDate,
    url,
}) => {
    const classes = useStyles();

    const epidemicId = editAreaDate ? editAreaDate.epidemicId : undefined;

    const adtSpecOps = React.useMemo(() => ({ epidemicId: epidemicId }), [epidemicId])

    const [areaDateTypeData, , , areaDateTypeRequestState] = areaDateTypeSearchHooks.useSearch(adtSpecOps);

    const areaDateTypeOptions = areaDateTypeData && areaDateTypeData.results && areaDateTypeData.results.length > 0 ?
        areaDateTypeData.results.map((t =>
            <MenuItem
                key={t.id}
                value={t.id}
            >
                {t.name}
            </MenuItem>
        )) :
        [];

    const editMode = editAreaDate !== undefined && editAreaDate.id !== undefined;

    const wrappedObject = {
        object: editAreaDate
    };

    return <EditForm
        actions={areaDateActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingAreaDate}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, editMode ? 3 : 2)}` : '/404'}
        url={url}
        selectors={areaDateSelectors}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            {
                areaDateTypeRequestState.state !== RequestState.Pending ?
                    <Field
                        disabled={editMode}
                        name="object.areaDateTypeId"
                        component={SelectWrapper}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Type"
                        defaultValue={editAreaDate ? editAreaDate.areaDateTypeId : undefined}
                    >
                        <MenuItem value={0}></MenuItem>
                        {areaDateTypeOptions}
                    </Field> :
                    <Field
                        fullWidth
                        disabled
                        name="object.areaDateTypeIdLoading"
                        component={TextFieldWrapper}
                        type="text"
                        label="Type"
                        defaultValue="Loading..."
                    />
            }
        </Grid>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.date"
                component={DatePickerWrapper}
                label="Date"
                parse={parseDate}
                format={formatDate}
            />
        </Grid>
    </>}
    </EditForm>
};

export default AreaDateEditForm;