/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import diseaseModule from '../../store/reducers/diseases/disease';
import diseaseClassificationModule from '../../store/reducers/diseaseClassifications/diseaseClassification';
import diseaseSubClassificationModule from '../../store/reducers/diseaseSubClassifications/diseaseSubClassification';
import { RequestFormState } from '../../types/RequestState';
import { DiseaseSubClassification } from '../../dtos/Spread.dtos';
import DiseaseSubClassificationEditForm from './DiseaseSubClassificationEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditDiseaseSubClassificationParams {
    diseaseSubClassificationId: string;
    diseaseClassificationId?: string;
    diseaseId?: string;
}

interface IEditDiseaseSubClassificationProps {
    diseaseSubClassificationId?: number;
    diseaseClassificationId?: number;
    diseaseId?: number;
    history: History;
    match: match<IEditDiseaseSubClassificationParams>;
}

export const EditDiseaseSubClassification: React.FunctionComponent<IEditDiseaseSubClassificationProps> = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles({});

    // disease
    const diseaseState = useSelector(state => state.disease);
    const diseaseId = props.diseaseId || ((props.match.params && props.match.params.diseaseId)? parseInt(props.match.params.diseaseId) : 0);
    useEffect(() => {
        if (diseaseModule.actions.load) {
            dispatch(diseaseModule.actions.load(diseaseId))
        }
    }, [dispatch, diseaseId]);

    const diseaseIsLoading = diseaseState.states.loadState.state === RequestFormState.Pending && !diseaseState.data;
    //  ~ disease

    // Disease classification
    const diseaseClassificationState = useSelector(state => state.diseaseClassification);
    const diseaseClassificationId = props.diseaseClassificationId || ((props.match.params && props.match.params.diseaseClassificationId)? parseInt(props.match.params.diseaseClassificationId) : 0);
    useEffect(() => {
        if (diseaseClassificationModule.actions.load) {
            dispatch(diseaseClassificationModule.actions.load(diseaseClassificationId))
        }
    }, [dispatch, diseaseClassificationId]);

    const diseaseClassificationIsLoading = diseaseClassificationState.states.loadState.state === RequestFormState.Pending && !diseaseClassificationState.data;
    // ~ Disease Classification

    const diseaseSubClassificationState = useSelector(state => state.diseaseSubClassification);
    const diseaseSubClassificationId = props.diseaseSubClassificationId || ((props.match.params && props.match.params.diseaseSubClassificationId)? parseInt(props.match.params.diseaseSubClassificationId) : 0);
    useEffect(() => {
        if (diseaseSubClassificationModule.actions.load) {
            dispatch(diseaseSubClassificationModule.actions.load(diseaseSubClassificationId))
        }
    }, [dispatch, diseaseSubClassificationId]);

    return (diseaseIsLoading || diseaseClassificationIsLoading) ?
        <CircularProgress /> : <Paper className={classes.root}>
        <DiseaseSubClassificationEditForm
            editDiseaseSubClassification={diseaseSubClassificationState.data}
            disease={diseaseState.data}
            diseaseClassification={diseaseClassificationState.data}
            history={props.history}
            loadingDiseaseSubClassification={diseaseSubClassificationState.states.loadState.state === RequestFormState.Pending && !diseaseSubClassificationState.data}
            url={props.match.url}
        />
    </Paper>
}