/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { BASE_IMAGES_PATH } from '../../constants/Paths';
import ButtonLink from '../common/ButtonLink';
import DocumentIcon from '../common/DocumentIcon';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export interface IThumbnailProps {
    previewPath: string;
    displayPath: string;
    isImage: boolean;
    text: string;
    title?: string;
    isLoading?: boolean;
    isAbsolulePath?: boolean;
    isBlocked?: boolean;
    onPreview?: Function;
    onDownload?: Function;
    onRemove?: Function;
    style?: any;
}

class Thumbnail extends React.PureComponent<IThumbnailProps, any> {
    constructor(props: IThumbnailProps) {
        super(props)
        this.onPreview = this.onPreview.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onPreview(path: string) {
        if (this.props.onPreview) {
            this.props.onPreview(path)
        }
    }

    onDownload(path: string) {
        if (this.props.onDownload) {
            this.props.onDownload(path)
        }
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove()
        }
    }

    render() {
        let base = BASE_IMAGES_PATH;
        if (base === '/') {
            base = "";
        }
        let display = base + this.props.displayPath;
        let view = base + this.props.previewPath;
        if (this.props.isAbsolulePath) {
            display = this.props.displayPath;
            view = this.props.previewPath;
        }

        let preview;
        if (this.props.isBlocked) {
            preview = (
                <BlockIcon
                    style={{
                        width: '46px',
                        height: '46px',
                        margin: '15px 17px 22px'
                    }}
                />
            );
        } else if (this.props.isImage) {
            preview = (
                <img src={view}
                    alt="photo"
                    onClick={() => this.onPreview(display)} />
            );
        } else {
            preview = (
                <div onClick={() => this.onDownload(display)}>
                    <DocumentIcon style={{
                        width: '50px',
                        height: '50px',
                    }}
                    />
                </div>
            );
        }

        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box p={1} flexGrow={1}>
                    <div style={{
                        position: 'relative',
                        textAlign: 'center',
                        ...this.props.style
                    }}>
                        {preview}
                        {this.props.title && (
                            <p style={{ padding: '0', fontSize: '8px', top: '2px', background: 'black', opacity: 0.7, bottom: 'auto' }}>{this.props.title}</p>
                        )}
                        {!this.props.isImage && (
                            <p style={{ margin: '0' }}>{this.props.text}</p>
                        )}
                        {this.props.isLoading && (
                            <Skeleton /*style={{ position: 'absolute', bottom: '-8px', left: '2px', right: '2px' }}*/ />
                        )}
                    </div>
                </Box>
                <Box p={0} style={{ textAlign: 'center' }}>
                    {this.props.onRemove && (
                        <ButtonLink to="#" onClick={this.onRemove} style={{ padding: '0' }} >
                            <DeleteIcon style={{
                                borderRadius: '12px',
                                width: '24px',
                                height: '24px'
                            }} />
                        </ButtonLink>
                    )}
                </Box>
            </Box>
        );
    }
}

export default Thumbnail;