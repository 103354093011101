/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This watches for and displays ServiceStack ResponseStatus messages from the 
 * specified redux state.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Typography } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { IStoreState } from '../../store/store';
import { ResponseStatus } from '../../dtos/Spread.dtos';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

 
interface IErrorMessageProps {
    className?: string;
    errorSelector: (state: IStoreState) => ResponseStatus | undefined;
}

export const ErrorMessage: React.FunctionComponent<IErrorMessageProps> = (props) => {
    const errorState = useSelector(props.errorSelector);
    
    return errorState ? <Typography className={props.className}>{errorState.message}</Typography> : null
}