/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

 
/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISkeletonCheckboxOptionsProps {
    className?: string;
    count?: number
}

const SkeletonCheckboxOptions: React.FunctionComponent<ISkeletonCheckboxOptionsProps> = ({
    className,
    count
}) => {

    var skeletonElements = [];

    if (count) {
        for (var n = 0; n < count; n++) {
            skeletonElements.push(
                <div className={className} key={`loading-${n}`}>
                    <Checkbox
                        edge="start"
                        checked={true}
                        tabIndex={-1}
                        disableRipple
                        disabled
                    />
                    <Skeleton width={'50%'} />
                </div>
            );
        }
    }

    return <>{skeletonElements}</>;
}

export default SkeletonCheckboxOptions;