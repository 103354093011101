/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import L, { LatLngBoundsLiteral } from 'leaflet';
// https://github.com/PaulLeCam/react-leaflet/issues/453
import 'leaflet/dist/leaflet.css';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { MapContainer, Pane, TileLayer, useMap } from "react-leaflet";
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { AnalysisJobInfectedPremiseStatus, InfectedPremise, LatLngBounds } from '../../dtos/Spread.dtos';
import { ITileMap } from '../../types/MapTypes';
import InfectedPremiseMarker from './visualisations/InfectedPremiseMarker';
import TestInfectionLines from './TestInfectionLines';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

interface ILeafletMapProps {
    defaultCentre?: [number, number];
    defaultBounds?: LatLngBounds;
    height?: number;
    width?: number;
    infectedPremises?: InfectedPremise[];
    infectedPremisesStatus?: AnalysisJobInfectedPremiseStatus[];
    infectedPremiseIdStartOpen?: number;
    tileMap: ITileMap;
    timeStep?: Date;

    showPolygons: boolean;
    showPoints: boolean;
    showLabels: boolean;
    showLabelMode: string;
    isMapHidden: boolean;
}



const LeafletMap: React.FunctionComponent<ILeafletMapProps> = (
    
    {
        children,
        defaultCentre,
        defaultBounds,
        height,
        width,
        infectedPremises,
        infectedPremisesStatus,
        infectedPremiseIdStartOpen,
        tileMap,
        timeStep,
        showPolygons,
        showPoints,
        showLabels,
        showLabelMode,
        isMapHidden
    }) => {
    const mapStyles = useMemo(() => { return { height: height ? height : '100%', width: width ? width : '100%' } }, [height, width]);

    const mapBounds: LatLngBoundsLiteral | undefined = defaultBounds && [
        [defaultBounds.latitudeCornerA, defaultBounds.longitudeCornerA],
        [defaultBounds.latitudeCornerB, defaultBounds.longitudeCornerB]
    ];

    return (
        <>
            <MapContainer
                bounds={mapBounds}
                center={defaultCentre}
                zoom={8}
                style={mapStyles}
                maxZoom={14}
                minZoom={2}
            >
                <RefreshSizeWatcher isMapHidden={isMapHidden} />
                <TileLayer
                    attribution={tileMap.attribution}
                    url={tileMap.url}
                />
                {
                    // needs to be wrapped in a pane so it is ordered above other things drawn on the map
                    infectedPremises
                        ? <Pane name="infectedPremises">
                            {infectedPremises.map((ip) => {
                                const status = infectedPremisesStatus && infectedPremisesStatus.find(s => s.infectedPremiseId === ip.id);

                                return <InfectedPremiseMarker
                                    key={ip.id}
                                    infectedPremise={ip}
                                    analysisStatus={status && status.infectedPremiseStatus}
                                    timeStep={timeStep}
                                    startOpen={infectedPremiseIdStartOpen && ip.id === infectedPremiseIdStartOpen ? true : false}

                                    showPolygons={showPolygons}
                                    showPoints={showPoints}
                                    showLabels={showLabels}
                                    showLabelMode={showLabelMode}
                                />
                            }
                            )}
                        </Pane> : null
                }
                {/*This is the implementation of the infection edges component */}
                {
                    //(infectedPremises && infectedPremises[infectedPremises.length - 1]) ? <TestInfectionLines infectedPremises={infectedPremises} bounds={mapBounds} /> : null
                }

                {children}
            </MapContainer>
        </>
    )
};

export default LeafletMap;

/**
 * If map mounted when hidden the size will be wrong so invalidateSize must be called to
 * correct.
 */

interface IRefreshSizeWatcherProps {
    isMapHidden: boolean;
}

const RefreshSizeWatcher: React.FunctionComponent<IRefreshSizeWatcherProps> = (props) => {
    const map = useMap();
    const isMapHidden = props.isMapHidden;

    useEffect(() => { map.invalidateSize() }, [isMapHidden])

    return null;
}