import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';

interface TextFieldWrapperProps extends FieldRenderProps<any, any> {
    label?: string;
    formControlProps?: FormControlProps;
    disabled?: boolean;
    inputProps?: {
        autocomplete?: string;
        autofocus?: boolean;
        dirname?: string;
        max?: number;
        maxlength?: number;
        min?: number;
        minlength?: number;
        pattern?: string;
    }
}

const TextFieldWrapper: React.FunctionComponent<TextFieldWrapperProps> = ({
    input: { ...restInput },
    disabled,
    meta,
    label,
    formControlProps,
    inputProps,
    ...rest
}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    const allInputProps = {...inputProps, ...restInput}

    return <FormControl {...formControlProps} error={showError}>
        <TextField
            {...rest}
            disabled={disabled}
            inputProps={allInputProps}
            label={label}
        />

        {showError &&
            <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
        }
    </FormControl>;
};
//error={showError}
//helperText={showError ? meta.error || meta.submitError : undefined}

export default TextFieldWrapper;