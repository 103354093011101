/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for the app theme.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';

import update from 'immutability-helper';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import createAction from '../../../helpers/createAction';
import { Theme, createMuiTheme } from '@material-ui/core';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IThemeState {
    theme: Theme;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@theme';

export const themeTypes = {
    SET_THEME: `${typeNamespace}/SET_THEME`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

let spreadTheme = createMuiTheme({
    typography: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: 14,
        fontWeightLight: 300, // Work Sans
        fontWeightRegular: 400, // Work Sans
        fontWeightMedium: 700, // Roboto Condensed
        //fontFamilySecondary: "'Roboto Condensed', sans-serif",
    },
    palette: {
        primary: {
            light: '#00AFDB',
            main: '#0065A4',
            dark: '#001B33'
        },
        secondary: {
            light: '#EC519E',
            main: '#EC519E',
            dark: '#EC519E'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)'
        }
    }
});

spreadTheme = {
    ...spreadTheme,
    overrides: {
       // MuiDrawer: {
       //     paper: {
       //         backgroundColor: spreadTheme.palette.primary.dark //'#18202c',
       //     }
       // },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: spreadTheme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: spreadTheme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [spreadTheme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: spreadTheme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#767678',
            },
        },
        //MuiListItemText: {
        //    primary: {
        //        fontWeight: spreadTheme.typography.fontWeightMedium,
        //    },
        //},
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
                minWidth: 40
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
    //props: {
    //    MuiTab: {
    //        disableRipple: true,
    //    },
    //},
    mixins: {
        ...spreadTheme.mixins,
        //toolbar: {
        //    minHeight: 48,
        //},
    },
};

export const initialState: Theme = spreadTheme //createMuiTheme({

//palette: {
//    type: 'light'
//}
//});

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const themeReducer = createReducer<any>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const themeActions = {
    /**
     * This action sets the theme of the app
     */
    setThemeType: createAction(themeTypes.SET_THEME, (type: 'light' | 'dark') => ({ type })),
    /**
     * This action clears the theme state.
     */
    clear: createAction(themeTypes.CLEAR, () => ({})),
};

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Auth Reducers
themeReducer.on(themeActions.setThemeType, (state, payload) => {
    const newTheme = createMuiTheme({
        ...spreadTheme,
        palette: {
            type: payload.type
        }
    });

    return update(
        state,
        {
            $set: newTheme
        }
    )
});

// Clear Reducer
themeReducer.on(themeActions.clear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const themeApi = {};

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

//export const themeEpics = combineEpics();

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default themeReducer;