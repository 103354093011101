/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { History } from 'history';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import { HasPermission } from '../common/HasPermission';
import { groupMemberActions } from '../../store/reducers/groups/groupMember';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IGroupMemberListProps {
    history: History;
    items: SpreadDtos.Person[];
    groupId?: number;
}

export const GroupMemberList: React.FunctionComponent<IGroupMemberListProps> = (props) => {
    const Row = (abc: any) => {
        const dispatch = useDispatch();

        const memberItem = props.items[abc.index];
        const handleDeleteMember = useCallback(
            () => dispatch(groupMemberActions.deleteMember(props.groupId, memberItem.id)), [memberItem, dispatch]
        );

        return props.items && props.items[abc.index] && props.groupId
            ? <ListItem button key={abc.index} ContainerComponent="div" ContainerProps={{ style: abc.style }} style={abc.style}>
                <ListItemAvatar>
                    <Avatar>
                        <PersonIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`${props.items[abc.index].displayName}`} secondary={`${props.items[abc.index].email}`} onClick={() => props.history.push(`/person/${props.items[abc.index].id}`)}/>
                <HasPermission objectId={props.groupId} permission={'DeleteGroupMember'}>
                    <ListItemSecondaryAction >
                        <IconButton edge="end" aria-label="Delete" onClick={handleDeleteMember}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </HasPermission>
            </ListItem>
            : null
    };

    return <FixedSizeList
        height={400}
        width={360}
        itemSize={70}
        itemCount={props.items ? props.items.length : 0}
    >
        {Row}
    </FixedSizeList>
}