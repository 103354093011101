/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import groupModule from '../../store/reducers/groups/group';
import { routeBackToPath } from '../../helpers/routeHelpers';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IGroupEditFormProps {
    editGroup?: SpreadDtos.PersonGroup;
    history: History;
    loadingGroup?: boolean;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IGroupFormSchema extends Partial<SpreadDtos.PersonGroup> { }

const groupSchema = Yup.object<IGroupFormSchema>().shape({
    createdDate: Yup.mixed().notRequired(),
    displayName: Yup.string().label('Group name')
        .required(),
    groupMembers: Yup.mixed().notRequired(),
    id: Yup.mixed().notRequired()
});

const validation = Yup.object().shape({
    object: groupSchema.notRequired()
});

const GroupEditForm: React.FunctionComponent<IGroupEditFormProps> = ({
    editGroup,
    history,
    loadingGroup,
    url,
}) => {
    const classes = useStyles();

    const editMode = editGroup !== undefined;

    const wrappedObject = editMode ? {
        object: editGroup
    } : undefined;

    return <EditForm
        actions={groupModule.actions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingGroup}
        url={url}
        successRouteCallback={(d) => d ? `${routeBackToPath(url, 1, !editMode ? d.id : undefined)}` : '/404'}
        validate={validation}
        selectors={groupModule.selectors}
    >{() => (
        <Grid container alignItems="flex-start" spacing={2} >
            <Grid item xs={12}>
                <Field
                    fullWidth
                    required
                    name="object.displayName"
                    component={TextFieldWrapper}
                    formControlProps={{
                        className: classes.formControl,
                        fullWidth: true,
                        required: true
                    }}
                    type="text"
                    label="Group Name"
                />
            </Grid>
        </Grid >
    )}
    </EditForm>
};

export default GroupEditForm;