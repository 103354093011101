/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useInfectedPremiseLoad } from '../../hooks/infectedPremise';
import { RequestFormState } from '../../types/RequestState';
import { ViewAreaDates } from '../areaDate/ViewAreaDates';
import { ViewAreaLivestockCounts } from '../areaLivestockCount/ViewAreaLivestockCounts';
import { ViewParcels } from '../parcel/ViewParcels';
import { ViewInfectedPremise } from './ViewInfectedPremise';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IInfectedPremiseHomeParams {
    areaId: string;
    clusterId: string;
    epidemicId: string;
    infectedPremiseId: string;
    subClusterId: string;
}

interface IInfectedPremiseHomeProps {
    infectedPremiseId?: number;
    history: History;
    match: match<IInfectedPremiseHomeParams>;
}

export const InfectedPremiseHome: React.FunctionComponent<IInfectedPremiseHomeProps> = ({ infectedPremiseId, history, match }) => {
    const classes = useStyles();

    let loadedInfectedPremiseId = infectedPremiseId || (match.params ? parseInt(match.params.infectedPremiseId) : undefined);
    loadedInfectedPremiseId = React.useMemo(() => loadedInfectedPremiseId, [loadedInfectedPremiseId]);

    if (!loadedInfectedPremiseId) {
        throw Error("Invalid ID specified");
    }

    const [infectedPremiseData, infectedPremiseState] = useInfectedPremiseLoad(loadedInfectedPremiseId);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewInfectedPremise
                infectedPremise={infectedPremiseData}
                loading={infectedPremiseState.state === RequestFormState.Pending}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewParcels
                history={history}
                infectedPremiseId={loadedInfectedPremiseId}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaLivestockCounts
                areaId={infectedPremiseData && infectedPremiseData.areaId}
                history={history}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaDates
                areaId={infectedPremiseData && infectedPremiseData.areaId}
                history={history}
                match={match}
            />
        </Box>
    </Box>
}
