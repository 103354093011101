/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import * as React from 'react';
import { match } from 'react-router';
import Skeleton from 'react-loading-skeleton';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { GroupEpidemicList } from './GroupEpidemicList';
import { useSelector } from '../../hooks/useTypedSelector';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2),
        },
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewGroupEpidemicsParams {
    groupId?: string;
    epidemicId?: string;
}

interface IViewGroupEpidemicsProps {
    match: match<IViewGroupEpidemicsParams>;
}

export const ViewGroupEpidemics: React.FunctionComponent<IViewGroupEpidemicsProps> = ({
    match
}) => {
    const classes = useStyles();

    const group = useSelector(state => state.group.data);
    const epidemic = useSelector(state => state.epidemic.data);
    const url = match.url + (!match.url.endsWith('/groupEpidemic') ? '/groupEpidemic' : '');

    const groupId = match.params.groupId ? parseInt(match.params.groupId) : 0;
    const epidemicId = match.params.epidemicId ? parseInt(match.params.epidemicId) : 0;

    return <Box className={classes.root} component="div">
        <Paper className={classes.paper}>
            <Typography variant="h4" component="h4">{
                groupId && epidemicId ?
                    'ERROR: This page has been implemented inappropriately. Please contact your system adminstrator.' :
                    groupId ?
                        group ?
                            `Epidemics assigned to ${group.displayName}` :
                            <Skeleton /> :
                        epidemicId ?
                            epidemic ?
                                `Group Access` :
                                <Skeleton /> :
                            'ERROR: This page has been implemented inappropriately. Please contact your system adminstrator.'
            }</Typography>
            {
                group ?
                    <GroupEpidemicList
                        className={classes.list}
                        groupId={group.id}
                    /> :
                    null
            }
            <Box display="flex" justifyContent="flex-end">
                <HasPermission permission="CreateGroupEpidemic">
                    <ButtonLink
                        aria-label="Add Epidemic"
                        color="primary"
                        variant="contained"
                        to={`${url}/create`}
                    >
                        <AddBoxIcon className={classes.buttonSubmitIcon} />
                        Add
                </ButtonLink>
                </HasPermission>
            </Box>
        </Paper>
    </Box>
}