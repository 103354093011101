import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useEffect } from 'react';
import { MockFormContext } from './MockFormContext';

interface IMockRadioItem {
    key: any;
    label: string;
}

interface IMockRadioButtonsProps {
    className?: string;
    defaultKey?: any;
    disabled?: boolean;
    fieldName: string;
    items: IMockRadioItem[];
    title: string;
}


const useMockFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioGroup: {
            margin: theme.spacing(1)
        }
    }),
);

export const MockRadioButtons: React.FunctionComponent<IMockRadioButtonsProps> = ({ className, disabled, defaultKey, items, fieldName, title }) => {
    const classes = useMockFormStyles();

    const mockForm = React.useContext(MockFormContext);

    const handleKeyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        mockForm.setField(fieldName, event.target.value as any);
    };

    const radioItems = items && items.map((radioItem) => <FormControlLabel value={radioItem.key} control={<Radio />} label={radioItem.label} />);

    return <FormControl className={className} disabled={disabled}>
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup 
            className={classes.radioGroup}
            value={mockForm.fields[fieldName]} 
            onChange={handleKeyChange}
        >
            {radioItems}
        </RadioGroup>
    </FormControl>
}