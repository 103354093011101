/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Config } from 'final-form';
import { History } from 'history';
import * as React from 'react';
import { FormProps, FormRenderProps, FormSpy } from 'react-final-form';
import { Redirect } from 'react-router';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import useEditForm from '../../hooks/useEditForm';
import { createCrudSelectors, IBaseCrudActions } from '../../store/reducers/common/crud';
import { RequestFormState } from '../../types/RequestState';
import NavigationBlockerDialog from '../routes/NavigationBlockerDialog';
import AsyncForm from './AsyncForm';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IEditFormProps<ValidatorType = Yup.Schema<any>, FormType = Yup.InferType<ValidatorType>> {
    actions: IBaseCrudActions;
    children: (props: FormRenderProps<FormType>) => React.ReactNode;
    edit?: FormType;
    extraButtons?: React.ReactNode[];
    history: History;
    loading: boolean;
    mutators?: Config['mutators'];
    successRouteCallback?: (data: any) => string;
    url: string;

    /**
     * Configuration for when the underlying final-form should rerender.
     */
    formSubscription?: FormProps<FormType>['subscription'];

    saveDisabled?: boolean;

    selectors: ReturnType<typeof createCrudSelectors>;

    validate?: ValidatorType;
    validateContext?: object;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        buttonCancel: {
            margin: theme.spacing(1),
        },
        buttonCancelIcon: {
            marginRight: theme.spacing(1),
        },
        buttonSubmit: {
            margin: theme.spacing(1),
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

function EditForm<FormType>({ actions, children, edit, extraButtons, formSubscription, history, loading, mutators, saveDisabled, selectors, successRouteCallback, url, validate, validateContext }: IEditFormProps<FormType>) {
    const classes = useStyles();

    const editMode: boolean = edit && edit[Object.keys(edit)[0]] && edit[Object.keys(edit)[0]]['id'];

    const startAction = editMode ? actions.update : actions.create;
    const resolveAction = editMode ? actions.updateFormResponse : actions.createFormResponse;
    const rejectAction = editMode ? actions.updateFormResponse : actions.createFormResponse;

    const handleCancelClick = () => {
        history.goBack();
    };

    const [data, requestState] = useEditForm(actions, selectors, editMode);

    var successRedirectComponent = null;

    if (requestState === RequestFormState.SubmitSuccess) {
        const toRoute: string = successRouteCallback ? successRouteCallback(data) : '/';

        successRedirectComponent = <Redirect push to={toRoute} />
    }

    // Quick check to make sure relevant actions are defined
    if (!startAction || !resolveAction || !rejectAction) {
        return <div>ERROR: Server actions not defined</div>;
    }

    return loading ?
        <CircularProgress /> :
        <>
            {successRedirectComponent}
            <AsyncForm
                initialValues={edit}
                validate={validate}
                validateContext={validateContext}
                start={startAction}
                resolve={resolveAction}
                reject={rejectAction}
                mutators={mutators}
            >{({
                active,
                dirty,
                dirtyFields,
                dirtyFieldsSinceLastSubmit,
                dirtySinceLastSubmit,
                error,
                errors,
                form,
                handleSubmit,
                hasSubmitErrors,
                hasValidationErrors,
                initialValues,
                invalid,
                pristine,
                submitError,
                submitErrors,
                submitFailed,
                submitSucceeded,
                submitting,
                valid,
                validating,
                values,
            }) => <>
                    <Grid container alignItems="flex-start" spacing={2} >
                        {children({
                            active,
                            dirty,
                            dirtyFields,
                            dirtyFieldsSinceLastSubmit,
                            dirtySinceLastSubmit,
                            error,
                            errors,
                            form,
                            handleSubmit,
                            hasSubmitErrors,
                            hasValidationErrors,
                            initialValues,
                            invalid,
                            pristine,
                            submitError,
                            submitErrors,
                            submitFailed,
                            submitSucceeded,
                            submitting,
                            valid,
                            validating,
                            values,
                        })}
                        <Grid item xs={12}>
                            <Box className={classes.bottomBox} display="flex" alignItems="center" justifyContent="flex-end">
                                <FormSpy subscription={{ submitError: true }}>
                                    {
                                        formErrorProps => (
                                            formErrorProps.submitError ? <Typography color={'error'} variant="body1" component="p">{formErrorProps.submitError}</Typography> : null
                                        )
                                    }
                                </FormSpy>
                                <FormSpy subscription={{ pristine: true, submitting: true, values: true }}>
                                    {formButtonProps => (
                                        <>
                                            {extraButtons}
                                            <Button
                                                variant="contained"
                                                size="small"
                                                className={classes.buttonCancel}
                                                disabled={formButtonProps.submitting}
                                                onClick={() => { handleCancelClick() }}
                                            >
                                                <CancelIcon className={classes.buttonCancelIcon} />
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                className={classes.buttonSubmit}
                                                type="submit"
                                                color="primary"
                                                disabled={formButtonProps.pristine || formButtonProps.submitting || saveDisabled/* || (submitError !== undefined && !dirtySinceLastSubmit)*/}
                                            >
                                                <SaveIcon className={classes.buttonSubmitIcon} />
                                                Save
                                            </Button>
                                        </>
                                    )}
                                </FormSpy>
                            </Box>
                        </Grid>
                    </Grid>
                    <FormSpy subscription={{ dirtySinceLastSubmit: true, pristine: true }}>
                        {spyProps => (
                            <NavigationBlockerDialog block={requestState !== RequestFormState.SubmitSuccess && (!spyProps.pristine || spyProps.dirtySinceLastSubmit)} />
                        )}
                    </FormSpy>
                </>}
            </AsyncForm>
        </>
};

export default EditForm;