/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useFieldLoad } from '../../hooks/field';
import { RequestFormState } from '../../types/RequestState';
import { ViewAreaDates } from '../areaDate/ViewAreaDates';
import { ViewAreaLivestockCounts } from '../areaLivestockCount/ViewAreaLivestockCounts';
import { ViewField } from './ViewField';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(1),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
    }),
);

interface IFieldHomeParams {
    areaId: string;
    clusterId: string;
    epidemicId: string;
    fieldId: string;
    infectedPremiseId: string;
    parcelId: string;
    subClusterId: string;
}

interface IFieldHomeProps {
    fieldId?: number;
    history: History;
    match: match<IFieldHomeParams>;
}

export const FieldHome: React.FunctionComponent<IFieldHomeProps> = ({ fieldId, history, match }) => {
    const classes = useStyles();

    let loadedFieldId = fieldId || (match.params ? parseInt(match.params.fieldId) : undefined);
    loadedFieldId = React.useMemo(() => loadedFieldId, [loadedFieldId]);

    if (!loadedFieldId) {
        throw Error("Invalid ID specified");
    }

    const [fieldData, fieldState] = useFieldLoad(loadedFieldId);

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <ViewField
                field={fieldData}
                loading={fieldState.state === RequestFormState.Pending}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaLivestockCounts
                areaId={loadedFieldId}
                history={history}
                match={match}
            />
        </Box>
        <Box className={classes.layoutItem}>
            <ViewAreaDates
                areaId={loadedFieldId}
                history={history}
                match={match as any}
            />
        </Box>
    </Box>
}
