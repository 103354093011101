/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { useMemo } from 'react';

import { FieldProps, Field } from 'react-final-form';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import analysisTapmDataSetModule from '../../../store/reducers/analysis/analysisTapmDataSetSearch';
import SelectWrapper from '../../forms/FinalFormControls/SelectWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IAnalysisTapmDataSetSelectProps extends FieldProps<any, any> {
    filterDateStart?: string;
    filterDateEnd?: string;
}

const AnalysisTapmDataSetSelect: React.FunctionComponent<IAnalysisTapmDataSetSelectProps> = ({
    filterDateStart,
    filterDateEnd,
    ...fieldProps
}) => {
    const datasetPageOps = useMemo(() => ({ take: 1000, skip: 0, orderBy: 'dataSetId' }), []);

    const datasetSpecOps = useMemo(() => (DetermineSpecOps(filterDateStart, filterDateEnd)), [filterDateStart, filterDateEnd]);

    const [tapmDataSets, , ,] = analysisTapmDataSetModule.hooks.useSearch(datasetSpecOps, datasetPageOps);

    const tapmDataSetsOptions = tapmDataSets && tapmDataSets.results && tapmDataSets.results.length > 0 ?
        tapmDataSets.results.map((d =>
            <MenuItem
                key={d.id}
                value={d.id}
            >
                {d.dataSetName}
            </MenuItem>
        )) :
        [];

    return <Field
        {...fieldProps}
        component={SelectWrapper}
    >
        {tapmDataSetsOptions}
    </Field>;
}

const DetermineSpecOps = (filterDateStart?: any, filterDateEnd?: any) => {

    if (filterDateStart && filterDateEnd) {
        return {
            onOrBeforeMinDate: filterDateStart,
            onOrAfterMaxDate: filterDateEnd
        }
    }
    else if (filterDateEnd) {
        return {
            onOrAfterMaxDate: filterDateEnd
        }
    } 
    else if (filterDateStart) {
        return {
            onOrBeforeMinDate: filterDateStart
        }
    }

    return {};
}

export default AnalysisTapmDataSetSelect;