/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { toDate } from '@servicestack/client';
import classNames from 'classnames';
import { format } from 'date-fns';
import { History, Location } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../constants/Dates';
import { Epidemic } from '../../dtos/Spread.dtos';
import { useSelector } from '../../hooks/useTypedSelector';
import { epidemicSearchActions, epidemicsHooks } from '../../store/reducers/epidemics/epidemicSearch';
import sequenceRetrievalModule from '../../store/reducers/sequences/sequenceRetrieval';
import { RequestFormState, RequestState } from '../../types/RequestState';
import IconButtonLink from '../common/IconLink';
import { SortableTable } from '../table/SortableTable';
import { AnalysisDate } from '../areaDate/AnalysisDate';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            marginTop: theme.spacing(0),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        paper: {
            padding: theme.spacing(2, 3),
            marginTop: theme.spacing(2),
        }
    }),
);

interface IAdminTasksProps {
    history: History;
    location: Location;
    match: match<any>;
}

export const AdminTasks: React.FunctionComponent<IAdminTasksProps> = ({ history, location, match }) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const callback = React.useCallback(() => sequenceRetrievalModule.actions.createJob ? dispatch(sequenceRetrievalModule.actions.createJob()) : undefined, []);

    const retrievalJobCreateState = useSelector(sequenceRetrievalModule.selectors.createState);

    return <Container maxWidth="xl">
        {
            <Grid container className={classes.gridContainer} spacing={2} >
                <ButtonRow
                    buttons={[
                        {
                            text: 'View workflow jobs',
                            tooltip: 'View all the jobs in the SPREAD system',
                            url: `${match.url}/workflow/job`,
                        },
                        {
                            text: 'Trigger TAPM Dataset Sync',
                            tooltip: 'The system will check with the TAPM API for any new or changed datasets and automatically store them in the SPREAD Database',
                            url: `${match.url}/workflow/tapm/dataset`,
                        },
                        {
                            disabled: retrievalJobCreateState.state == RequestFormState.Pending,
                            text: 'Trigger GenBank Sequence Retrieval',
                            tooltip: 'The system will check for any new sequences from GenBank, download them, and automatically store them in the SPREAD Database',
                            onClick: callback,
                        },
                    ]}
                    title="SPREAD Workflow API Management"
                    theme={'dropDown'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'Edit Countries',
                            tooltip: 'Edit the countries that appear in drop-down lists',
                            url: `${match.url}/country`,
                        },
                        {
                            text: 'Edit Species',
                            tooltip: 'Edit the species that can be used to create Livestock definitions',
                            url: `${match.url}/species`,
                        },
                        {
                            text: 'Edit Livestock Defaults',
                            tooltip: 'Edit the livestock that epidemics are created with by default.',
                            url: `${match.url}/livestock`,
                        }
                    ]}
                    title="Drop-down List Management"
                    theme={'dropDown'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'Edit Pathogens',
                            tooltip: 'Edit the Pathogens for diseases',
                            url: `${match.url}/pathogen`,
                        },
                        {
                            text: 'Edit Classifications',
                            tooltip: 'Edit the Classifications for diseases',
                            url: `${match.url}/classification`,
                        },
                        {
                            text: 'Edit Sub Classifications',
                            tooltip: 'Edit the Sub Classifications for diseases',
                            url: `${match.url}/subclassification`,
                        },
                    ]}
                    title="Pathogens and Classifications"
                    theme={'dropDown'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'Edit Diseases',
                            tooltip: 'Edit the Pathogens for diseases',
                            url: `${match.url}/disease`,
                        }
                    ]}
                    title="Diseases"
                    theme={'dropDown'}
                />

                <ButtonRow
                    buttons={[
                        {
                            text: 'Edit Tissues',
                            tooltip: 'Edit the tissues for Sequencing',
                            url: `${match.url}/tissue`,
                        },
                        {
                            text: 'Edit Material',
                            tooltip: 'Edit the materials for Sequencing',
                            url: `${match.url}/material`,
                        },
                        {
                            text: 'Edit Enrichment Method',
                            tooltip: 'Edit the enrichment method for Sequencing',
                            url: `${match.url}/enrichmentMethod`,
                        },
                        {
                            text: 'Edit Library Strategy',
                            tooltip: 'Edit the library strategy for Sequencing',
                            url: `${match.url}/libraryStrategy`,
                        },
                        {
                            text: 'Edit Instrument',
                            tooltip: 'Edit the instrument for Sequencing',
                            url: `${match.url}/instrument`,
                        },
                        {
                            text: 'Edit Platform',
                            tooltip: 'Edit the platform for Sequencing',
                            url: `${match.url}/platform`,
                        }
                    ]}
                    title="Sequencing"
                    theme={'dropDown'}
                />
            </Grid>
        }
    </Container>
}

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            textAlign: 'center',
            '&:hover': {
                backgroundColor: 'inherit',
            },
            padding: 0
        },
        row: {
            padding: theme.spacing(0),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2.5),
        },
        rowItems: {
            paddingLeft: theme.spacing(2)
        },
        buttonDropDown: {
            color: '#DBC994',
            '&:hover': {
                color: '#b8a97d',
            }
        },
        chevron: {
            height: '100px',
            width: '250px',
            color: 'inherit'
        },
        chevronText: {
            position: 'absolute',
            left: '92px',
            textAlign: 'center',
            color: 'white',
            fontSize: '1rem',
            width: '100px'
        },
        chevronTextFirst: {
            position: 'absolute',
            left: '45px',
            textAlign: 'center',
            color: 'white',
            fontSize: '1rem',
            width: '135px'
        },
        buttonDisabled: {
            color: 'rgba(0,0,0,0.2)'
        }
    }),
);

interface IButton {
    text: string;
    url?: string;
    disabled?: boolean;
    hidden?: boolean;
    tooltip?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface IButtonRowProps {
    buttons: IButton[];
    title: string;
    theme: 'dropDown';
}

const ButtonRow: React.FunctionComponent<IButtonRowProps> = ({ title, buttons, theme }) => {
    const classes = useRowStyles({});

    const buttonClassName = theme === 'dropDown' ? classes.buttonDropDown : undefined;

    const activeButtons = [...buttons.filter(button => !button.hidden)]

    return <Box className={classes.row}>
        <Typography variant={'h5'}>
            {title}
        </Typography>
        <Box component='div' display="flex" flexDirection="row" className={classes.rowItems}>
            {
                activeButtons.map((button, idx) => {

                    const buttonElement = button.url ?
                        <IconButtonLink
                            key={`button-${button.text}-${button.url}`}
                            className={classNames(
                                classes.button,
                                buttonClassName
                            )}
                            disabled={button.disabled}
                            to={button.url}
                        >
                            {
                                idx === 0 ? <LabelIcon className={classNames(
                                    classes.chevron,
                                    {
                                        [classes.buttonDisabled]: button.disabled
                                    }
                                )}
                                    preserveAspectRatio={'none'}
                                    viewBox="2 5 21 14"
                                />
                                    :
                                    <LabelImportantIcon className={classNames(
                                        classes.chevron,
                                        {
                                            [classes.buttonDisabled]: button.disabled
                                        }
                                    )}
                                        preserveAspectRatio={'none'}
                                        viewBox="2 5 19 14"
                                    />
                            }

                            <div className={classNames(
                                {
                                    [classes.chevronTextFirst]: idx === 0,
                                    [classes.chevronText]: idx !== 0
                                }
                            )}>
                                {button.text}
                            </div>
                        </IconButtonLink> :
                        <IconButton
                            key={`button-${button.text}-${button.url}`}
                            className={classNames(
                                classes.button,
                                buttonClassName
                            )}
                            disabled={button.disabled}
                            onClick={button.onClick}
                        >
                            {
                                idx === 0 ? <LabelIcon className={classNames(
                                    classes.chevron,
                                    {
                                        [classes.buttonDisabled]: button.disabled
                                    }
                                )}
                                    preserveAspectRatio={'none'}
                                    viewBox="2 5 21 14"
                                />
                                    :
                                    <LabelImportantIcon className={classNames(
                                        classes.chevron,
                                        {
                                            [classes.buttonDisabled]: button.disabled
                                        }
                                    )}
                                        preserveAspectRatio={'none'}
                                        viewBox="2 5 19 14"
                                    />
                            }

                            <div className={classNames(
                                {
                                    [classes.chevronTextFirst]: idx === 0,
                                    [classes.chevronText]: idx !== 0
                                }
                            )}>
                                {button.text}
                            </div>
                        </IconButton>

                    return button.tooltip ? <Tooltip title={button.tooltip}>
                        <span>
                            {buttonElement}
                        </span>
                    </Tooltip>
                        : buttonElement
                })
            }

        </Box>
    </Box>
}



const useEpidemicListStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            minWidth: 275,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            //marginBottom: theme.spacing(1),
        },
        table: {

        }
    }),
);

const headerColumns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'subNational', numeric: false, disablePadding: false, label: 'Sub-National' },
    { id: 'year', numeric: false, disablePadding: false, label: 'Year' },
    { id: 'disease', numeric: false, disablePadding: false, label: 'Disease' },
    { id: 'infectedPremiseCount', numeric: false, disablePadding: false, label: 'No. of IPs' },
];

interface IChooseEpidemicListProps {
    location: Location;
}

const ChooseEpidemicList: React.FunctionComponent<IChooseEpidemicListProps> = ({ location }) => {
    const classes = useEpidemicListStyles();

    const [data, paginateOptions, , requestState] = epidemicsHooks.useSearch();

    return <SortableTable
        className={classes.table}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={epidemicSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${location.pathname.replace('/task/select', '/epidemic/' + item.id.toString())}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.countryName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.subNationalName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.year}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.diseaseName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.infectedPremiseCount}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There are no Epidemics to choose from. Create one to use this feature.'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}


interface IEpidemicModuleSummaryProps {
    epidemic: Epidemic;
}

const useModuleStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2, 3),
            marginTop: theme.spacing(2),
        }
    }),
);

const EpidemicModuleSummary: React.FunctionComponent<IEpidemicModuleSummaryProps> = ({
    epidemic
}) => {
    const classes = useModuleStyles();

    return <Paper className={classes.paper}>
        <Typography variant="h4" component="h4">{epidemic.name}</Typography>
        <Typography variant="subtitle1" component="p">Start: <AnalysisDate dateSet={epidemic.epidemicDateSet} dateType={'startinfectious'} /></Typography>
        <Typography variant="subtitle1" component="p">End: <AnalysisDate dateSet={epidemic.epidemicDateSet} dateType={'endinfectious'} /></Typography>
    </Paper>;
}