/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple roles.
 * --------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { createAutoQueryModule, initialSearchState, ISearchState } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialRoleSearchState = ISearchState<SpreadDtos.QueryRole>;

export interface IRoleSearchState {
    roleSearch: IInitialRoleSearchState;
}

export const initialState: IRoleSearchState = {
    roleSearch: initialSearchState,
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const rolesModule = createAutoQueryModule(
    'roleSearch',
    initialSearchState,
    '@@roleSearch',
    SpreadDtos.QueryRoles,
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) => ({
        assignRoleObjectTypeId: options.specialOptions.assignRoleObjectTypeId,
        canAssignOnly: options.specialOptions.canAssignOnly
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export const roleSearchActions = rolesModule.actions;
export const roleSearchEpics = rolesModule.epics;
export const rolesApi = rolesModule.api;
export const rolesSelectors = rolesModule.selectors;
export const rolesHooks = rolesModule.hooks;
export default rolesModule.reducer;