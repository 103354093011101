/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { SubCluster } from '../../dtos/Spread.dtos';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);


interface IViewSubClusterProps {
    loading: boolean;
    match: match<any>;
    subCluster?: SubCluster;
}

export const ViewSubCluster: React.FunctionComponent<IViewSubClusterProps> = ({
    loading,
    match,
    subCluster
}) => {
    const classes = useStyles();

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">{!loading ? subCluster && subCluster.name : <Skeleton />}</Typography>
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="UpdateCluster" objectId={subCluster?.cluster.epidemicId}>
                <ButtonLink
                    aria-label="Edit Cluster"
                    color="primary"
                    variant="contained"
                    to={`${match.url}/edit`}
                >
                    <EditIcon className={classes.buttonSubmitIcon} />
                    Edit
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}