/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for executing an epidemic spatial import
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { createReducer } from 'redux-act';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IImportSpatialFormSchema } from '../../../components/bulkUpload/ImportSpatialForm';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createPostRequest } from '../../../helpers/createRequest';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialImportSpatialState {
    importState: IFormRequestState;
    importResponse?: SpreadDtos.ImportHierarchyResponse;
}

export interface IImportSpatialState {
    importSpatial: IInitialImportSpatialState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@importSpatial';

export const ImportSpatialTypes = {
    IMPORT: `${typeNamespace}/IMPORT`,
    IMPORT_FORM_RESPONSE: `${typeNamespace}/IMPORT_FORM_RESPONSE`,
    CLEAR: `${typeNamespace}/CLEAR`
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialImportSpatialState = {
    importResponse: undefined,
    importState: {
        state: RequestFormState.None
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const importSpatialReducer = createReducer<IInitialImportSpatialState>({}, initialState);

/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const importSpatialActions = {
    /**
     * Begins the request to execute a new livestock import.
     */
    import: createAction(ImportSpatialTypes.IMPORT,
        (importSpatial: IImportSpatialFormSchema) => ({ importSpatial })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    importFormResponse: createAction(ImportSpatialTypes.IMPORT_FORM_RESPONSE,
        (response: SpreadDtos.ImportSpatialResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the importSpatial state.
    */
    clear: createAction(ImportSpatialTypes.CLEAR, () => ({ initialState })),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// Create new Reducers
importSpatialReducer.on(importSpatialActions.import, (state) => (
    update(
        state,
        {
            importState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    )
));

importSpatialReducer.on(importSpatialActions.importFormResponse, (state, payload) => (
    update(
        state,
        {
            importResponse: {
                $set: payload.response
            },
            importState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    )
));

// Clear Reducer
importSpatialReducer.on(importSpatialActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
importSpatialReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const importSpatialsApi = {
    import: createPostRequest(
        SpreadDtos.ImportSpatial,
        (importSpatial: IImportSpatialFormSchema) => ({
            epidemicId: importSpatial.epidemicId,
            temporaryFileToken: importSpatial.files[0].tempFileGuid,
            temporaryFileExtension: importSpatial.files[0].fileExtensionType,
            mappedHeadersDictionary: importSpatial.fieldMapping,
            importIpKey: SpreadDtos.ImportIpKey.FarmId
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

export const importEpic: Epic<ReturnType<typeof importSpatialActions.import>, any, any, any> = (action$, state$) => action$.pipe(
    ofType(importSpatialActions.import.getType()),
    mergeMap(action => {
        return importSpatialsApi.import(action.payload.importSpatial).pipe(
            mergeMap(response =>
                of(
                    importSpatialActions.importFormResponse(response, RequestFormState.SubmitSuccess)
                )
            ),
            catchError(e => of(importSpatialActions.importFormResponse(e, RequestFormState.ServerError)))
        )
    })
);

export const importSpatialEpics = combineEpics(importEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default importSpatialReducer;