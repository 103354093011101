/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Sequence } from '../../../dtos/Spread.dtos';
import { GenomicFormWrapper } from '../GenomicDataUpload';
import SequenceEditForm from './SequenceEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface ICreateSequenceParams {
    epidemicId: string;
    specimenId: string;
}

interface ICreateSequenceProps {
    epidemicId?: number;
    history: History;
    match: match<ICreateSequenceParams>;
    specimenId?: number;
    uploadType?: "fastQ" | "fastA";
}

export const CreateSequence: React.FunctionComponent<ICreateSequenceProps> = ({
    epidemicId: epidemicIdProp,
    history,
    match,
    specimenId,
    uploadType: uploadTypeProp,
}) => {
    const classes = useStyles();

    const params = parseQueryString(history.location.search);

    const epidemicId = epidemicIdProp || (match.params.epidemicId ? parseInt(match.params.epidemicId) : undefined);
    specimenId = specimenId || (match.params.specimenId ? parseInt(match.params.specimenId) : (params ? Number(params.specimenId) : undefined));

    const uploadType = uploadTypeProp || (params.uploadType == "fastQ" || params.uploadType == "fastA" ? params.uploadType : undefined);

    return <Paper className={classes.root}>
        <GenomicFormWrapper
            title={`Upload new ${uploadType ? `${uploadType.toUpperCase()} ` : ''}Genomic Data`}
            subtitle={'Step 2 - Sequencing Metadata'}
        >
            <SequenceEditForm
                editSequence={new Sequence({
                    epidemicId,
                    specimenId,
                    originId: 1,
                })}
                history={history}
                uploadType={uploadType}
                url={match.url}
            />
        </GenomicFormWrapper>
    </Paper>
}