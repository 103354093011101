/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Cluster } from '../dtos/Spread.dtos';
import { clusterActions } from '../store/reducers/clusters/cluster';
import { IFormRequestState } from '../types/IRequestState';
import { useSelector } from './useTypedSelector';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

export function useClusterLoad(clusterId?: number): [Cluster | undefined, IFormRequestState] {
    const dispatch = useDispatch();
    const data = useSelector(state => state.cluster.data);
    const requestState = useSelector(state => state.cluster.states.loadState);

    useEffect(() => {
        if(clusterId) {
            dispatch(clusterActions.load(clusterId))
        }

        return function cleanup() {
            dispatch(clusterActions.clear())
        }
    }, [dispatch, clusterId]);

    return [
        data,
        requestState
    ];
}
