/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSubClusterLoad } from '../../hooks/subCluster';
import { RequestFormState } from '../../types/RequestState';
import SubClusterEditForm from './SubClusterEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSubClusterParams {
    clusterId: string;
    epidemicId: string;
    subClusterId: string;
}

interface IEditSubClusterProps {
    history: History;
    match: match<IEditSubClusterParams>;
    subClusterId?: number;
}

export const EditSubCluster: React.FunctionComponent<IEditSubClusterProps> = ({
    history,
    match,
    subClusterId,
}) => {
    const classes = useStyles();

    subClusterId = subClusterId || (match.params ? parseInt(match.params.subClusterId) : undefined);
    subClusterId = React.useMemo(() => subClusterId, [subClusterId]);

    if (!subClusterId) {
        throw Error("Invalid ID specified");
    }

    const [subClusterData, subClusterState] = useSubClusterLoad(subClusterId);

    return <Paper className={classes.root}>
        <SubClusterEditForm
            editSubCluster={subClusterData}
            history={history}
            loadingSubCluster={subClusterState.state === RequestFormState.Pending && !subClusterData}
            url={match.url}
        />
    </Paper>
}