/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an area date
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createReducer } from 'redux-act';
import { combineEpics } from 'redux-observable';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPostRequest, createPutRequest } from '../../../helpers/createRequest';
import { RequestFormState } from '../../../types/RequestState';
import { applyCrudReducers, createCrudActionTypes, createCrudSelectors, createCrudEpics, IBaseCrudState } from '../common/crud';
import { authenticatedPersonActions } from '../person/authenticatedPerson';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IInitialAreaDateState extends IBaseCrudState {
    data?: SpreadDtos.AreaDate;
}

export interface IAreaDateState {
    areaDate: IInitialAreaDateState;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

const typeNamespace = '@@areaDate';

export const areaDateTypes = {
    ...createCrudActionTypes(typeNamespace),
    CLEAR: `${typeNamespace}/CLEAR`,
};

/*
 * ---------------------------------------------------------------------------------
 * Initial State
 * ---------------------------------------------------------------------------------
 */

export const initialState: IInitialAreaDateState = {
    data: undefined,
    states: {
        createState: {
            state: RequestFormState.None
        },
        loadState: {
            state: RequestFormState.None
        },
        updateState: {
            state: RequestFormState.None
        },
        deleteState: {
            state: RequestFormState.None
        },
        archiveState: {
            state: RequestFormState.None
        },
    }
};

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const areaDateReducer = createReducer<IInitialAreaDateState>({}, initialState);


/*
 * ---------------------------------------------------------------------------------
 * Actions
 * ---------------------------------------------------------------------------------
 */

export const areaDateActions = {
    /**
     * Begins the request to create a new area date.
     * 
     * This cannot update an existing area date. Use update for that.
     */
    create: createAction(areaDateTypes.CREATE,
        (areaDate: SpreadDtos.AreaDate) => ({ object: areaDate })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    createFormResponse: createAction(areaDateTypes.CREATE_FORM_RESPONSE,
        (response: SpreadDtos.CreateAreaDateResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to load a given area date.
     */
    load: createAction(areaDateTypes.LOAD,
        (areaDateId: number) => ({ id: areaDateId })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    loadFormResponse: createAction(areaDateTypes.LOAD_FORM_RESPONSE,
        (response: SpreadDtos.GetAreaDateResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
     * Begins the request to update an area date.
     * 
     * This cannot create a new area date. Use save for that.
     */
    update: createAction(areaDateTypes.UPDATE,
        (areaDate: SpreadDtos.AreaDate) => ({ object: areaDate })
    ),
    /**
     * Stores the response from the form submission,
     * which may be a success or failure
     */
    updateFormResponse: createAction(areaDateTypes.UPDATE_FORM_RESPONSE,
        (response: SpreadDtos.UpdateAreaDateResponse, state: RequestFormState) => ({ response, state })
    ),
    /**
    * Clears the form request state.
    */
    clearFormState: createAction(areaDateTypes.CLEAR_FORM, () => ({})),
    /**
    * Clears the areaDate state.
    */
    clear: createAction(areaDateTypes.CLEAR, () => ({})),
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

// CRUD Reducers
applyCrudReducers(areaDateReducer, areaDateActions, 'areaDate', initialState);

// Clear Reducer
areaDateReducer.on(areaDateActions.clear, (state) => ({ ...initialState }));

// LogoutClear Reducer
areaDateReducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));

/*
 * ---------------------------------------------------------------------------------
 * API Calls
 * ---------------------------------------------------------------------------------
 */

export const areaDatesApi = {
    create: createPostRequest(
        SpreadDtos.CreateAreaDate,
        (areaDate: SpreadDtos.AreaDate) => ({
            areaDate: areaDate
        })
    ),
    load: createGetRequest(
        SpreadDtos.GetAreaDate,
        (areaDateId: number) => ({
            id: areaDateId
        })
    ),
    update: createPutRequest(
        SpreadDtos.UpdateAreaDate,
        (areaDate: SpreadDtos.AreaDate) => ({
            areaDate: areaDate
        })
    ),
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

const formEpics = createCrudEpics(areaDateActions, areaDatesApi);

export const areaDateEpics = combineEpics(formEpics);

/*
 * ---------------------------------------------------------------------------------
 * Selectors
 * ---------------------------------------------------------------------------------
 */

export const areaDateSelectors = createCrudSelectors<SpreadDtos.AreaDate, IBaseCrudState<SpreadDtos.AreaDate>>('areaDate');

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default areaDateReducer;