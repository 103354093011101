/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';

import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { EpidemicPersonGroupAccessList } from './EpidemicPersonGroupAccessList';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface IViewEpidemicPersonGroupAccessProps {
    epidemicId: number;
    url: string;
}

export const ViewEpidemicPersonGroupAccess: React.FunctionComponent<IViewEpidemicPersonGroupAccessProps> = ({ epidemicId, url }) => {
    const classes = useStyles();

    url = url + (!url.endsWith('/groupEpidemic') ? '/groupEpidemic' : '');

    return <Paper className={classes.root}>
        <Typography variant="h4" component="h4">Group Access</Typography>
        <EpidemicPersonGroupAccessList className={classes.list} epidemicId={epidemicId} />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="AssignRole">
                <ButtonLink
                    aria-label="Grant access to epidemic for a group"
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Grant group access
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}

