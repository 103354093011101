import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';
interface CheckboxWrapperProps extends FieldRenderProps<any, any> {
    label?: string;
    formControlProps?: FormControlProps;
    disabled?: boolean;
}

const CheckboxWrapper: React.FunctionComponent<CheckboxWrapperProps> = ({
    disabled,
    formControlProps,
    input: { checked, name, onChange, value, ...restInput },
    label,
    meta,
    ...rest
}) => {
    const showError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && !meta.submitting;

    return (
        <FormControl {...formControlProps} error={showError}>
            <label>
                <Checkbox
                    {...rest}
                    disabled={disabled}
                    name={name}
                    inputProps={restInput}
                    onChange={onChange}
                    checked={checked}
                />
                {label}
            </label>

            {showError &&
                <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
            }
        </FormControl>
    );
}

export default CheckboxWrapper;