/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { toDate } from '@servicestack/client';
import * as classNames from 'classnames';
import { format } from 'date-fns';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { specimenSearchActions, specimensHooks } from '../../../store/reducers/specimens/specimenSearch';
import { RequestState } from '../../../types/RequestState';
import { SortableTable } from '../../table/SortableTable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            //cursor: 'pointer',
        }
    }),
);

const headerColumns = [
    { id: 'specimenId', numeric: false, disablePadding: false, label: 'Specimen ID' },
    { id: 'livestockId', numeric: false, disablePadding: false, label: 'Species' },
    { id: 'isolateName', numeric: false, disablePadding: false, label: 'Isolate' },
    { id: 'collectionDate', numeric: false, disablePadding: false, label: 'Collection Date' },
];

interface ISpecimenListProps {
    className?: string;
    showTitle?: boolean;
    url: string;
}

export const SpecimenList: React.FunctionComponent<ISpecimenListProps> = ({ className, showTitle, url }) => {
    const classes = useStyles();

    const specOps = useMemo(() => ({}), []);
    const pagOps = useMemo(() => ({ orderBy: "specimenId", skip: 0, take: 200}), []);

    const [data, paginateOptions, , requestState] = specimensHooks.useSearch(specOps, pagOps);

    return <>
        {
            showTitle && data && data.results && data.results.length > 0 && requestState.state !== RequestState.Pending ?
                <Typography variant="h4" component="h4">Specimens</Typography> :
                null
        }
        {data && data.results && data.results.length > 0 ?
            <SortableTable
                className={classNames(className, classes.table)}
                headerColumns={headerColumns}
                paginateOptions={paginateOptions}
                rowCount={data ? data.total : 0}
                updatePaginateOptions={specimenSearchActions.updatePaginateOptions}
            >
                {
                    data.results.map((item =>
                        <TableRow
                            className={classes.clickableRow}
                            key={item.id}
                        >
                            <TableCell component="th" scope="row">
                                <Link component={RouterLink} to={`${url}/${item.id}`}>
                                    {item.specimenId}
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.livestockName || 'No Data'}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.isolateName}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {item.collectionDate ? format(toDate(item.collectionDate), DateTimeFormat) : 'Not provided'}
                            </TableCell>
                        </TableRow>
                    ))
                }
            </SortableTable> :
            requestState.state === RequestState.Pending ?
                <Skeleton /> :
                <Typography variant="h6" component="h6">No Specimen</Typography>
        }
    </>
}