/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import { toDate } from '@servicestack/client';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { RequestState } from '../../types/RequestState';
import { useAuthenticatedPersonGroupInvites } from '../../hooks/person';
import { DateTimeFormat } from '../../constants/Dates';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IGroupInviteListProps {
    className?: string;
}

export const GroupInviteList: React.FunctionComponent<IGroupInviteListProps> = (props) => {
    const [accept, decline, actionPending, data, loadRequestState] = useAuthenticatedPersonGroupInvites();

    return <List>
        {loadRequestState.state === RequestState.Pending ? <Skeleton /> : null}
        {loadRequestState.state !== RequestState.Pending && data ? data.map(invite =>
            <ListItem key={invite.id}>
                <ListItemText
                    primary={invite.groupName}
                    secondary={format(toDate(invite.createdDate), DateTimeFormat)}
                />
                {
                    //actionPending
                        //? <CircularProgress />
                        //: 
                        <ListItemSecondaryAction>
                            <IconButton disabled={actionPending} edge="end" aria-label="accept" onClick={() => accept(invite.id)}>
                                <CheckIcon />
                            </IconButton>
                            <IconButton disabled={actionPending} edge="end" aria-label="decline" onClick={() => decline(invite.id)}>
                                <CloseIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                }
            </ListItem>,
        ) : null}
    </List>
}