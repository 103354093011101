/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { Theme, lighten } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';

import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';

import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import TabIcon from '@material-ui/icons/Tab';
import SendIcon from '@material-ui/icons/Send';
import BuildIcon from '@material-ui/icons/Build';
import PublicIcon from '@material-ui/icons/Public';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import LeakAddIcon from '@material-ui/icons/LeakAdd';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import ButtonLink from '../common/ButtonLink';
import { ListItemLink, ListItemNavLink } from '../common/ListItemLink';
import { useSelector } from '../../hooks/useTypedSelector';
import { DrawerWidthPx, HeaderHeightPx } from '../../constants/Layout';
import { ContentLogo } from './ContentLogo';
import { IContentNavItem } from './content';
import logo from '../../assets/images/spread-logo.png';
import { AuthState } from '../../types/AuthState';
import { NavLinkProps } from 'react-router-dom';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: DrawerWidthPx,
                flexShrink: 0,
            }
        },
        drawerButton: {
        },
        drawerButtonIcon: {
            fontSize: '2.5rem'
        },
        drawerHeader: {
            margin: '0.5rem 1rem 0.5rem 1rem'
        },
        drawerLogo: {
            height: '50px'
        },
        drawerPaper: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            //backgroundColor: theme.palette.primary.main,
            width: DrawerWidthPx,
        }
    })
);

interface IContentNavProps {
    drawerOpen: boolean;
    title?: string;
    toggleDrawerOpen: any;
    drawer: any;
}

export const ContentNav: React.FunctionComponent<IContentNavProps> = (props) => {
    const classes = useStyles(props);

    return <nav className={classes.drawer} aria-label="SPREAD Navigation Menu">
        <Hidden mdUp implementation="css">
            <SwipeableDrawer
                variant="temporary"
                anchor={'left'}
                open={props.drawerOpen}
                onClose={props.toggleDrawerOpen}
                onOpen={props.toggleDrawerOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <Box className={classes.drawerHeader} display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    
                    <img className={classes.drawerLogo} src={logo} alt="Spread Logo" />
                    <Typography variant='h6'>
                        {props.title}
                    </Typography>
                    <IconButton className={classes.drawerButton} color={'inherit'} onClick={props.toggleDrawerOpen}>
                        {<MenuIcon className={classes.drawerButtonIcon} />}
                    </IconButton>
                </Box>
                {props.drawer}
            </SwipeableDrawer>
        </Hidden>
    </nav>;

}