// Retrieved from https://github.com/LiveBy/react-leaflet-control/issues/44

import classNames from 'classnames';
import * as React from 'react';

/**
 * @see https://react-leaflet.js.org/docs/example-react-control
 */

// Classes used by Leaflet to position controls.
const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
} as const;

const ReactLeafletControl = (props: ReactLeafletControlProps): JSX.Element => {
    const { position, containerProps, children, className } = props;
    return (
        <div className={POSITION_CLASSES[position]}>
            <div className={classNames('leaflet-control', className)} {...containerProps}>
                {children}
            </div>
        </div>
    );
};

export type ReactLeafletControlProps = {
    position: keyof typeof POSITION_CLASSES;
    containerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    children: React.ReactNode;
    className?: string;
};

ReactLeafletControl.defaultProps = {
    position: 'topleft' as ReactLeafletControlProps['position'],
    containerProps: {},
    children: null,
};

export default ReactLeafletControl;