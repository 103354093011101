/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an classification
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import {
    CreateClassification,
    CreateClassificationResponse,
    Classification,
    DeleteClassification,
    DeleteClassificationResponse,
    GetClassification,
    GetClassificationResponse,
    UpdateClassification,
    UpdateClassificationResponse,
} from '../../../dtos/Spread.dtos';
import { createCrudModule, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IClassificationState {
    classification: IBaseCrudState<Classification>;
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const classificationModule = createCrudModule<Classification, CreateClassificationResponse, GetClassificationResponse, UpdateClassificationResponse, DeleteClassificationResponse, undefined>(
    'classification',
    CreateClassification,
    GetClassification,
    UpdateClassification,
    DeleteClassification,
    undefined,
)


export default classificationModule;