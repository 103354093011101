/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';
import { Redirect } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { registerPersonActions } from '../../store/reducers/person/registerPerson';
import { RequestState } from '../../types/RequestState';
import AsyncForm from '../forms/AsyncForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IRegisterFormProps {
    email: string;
    token: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            //marginTop: theme.spacing(1),
        },
        input: {
            marginTop: theme.spacing(0),
        },
        progress: {
            margin: theme.spacing(0, 1, 0, 0)
        },
        title: {
            margin: theme.spacing(0, 0, 0, 0)
        }
    }),
);

interface IPersonRegisterFormSchema {
    token: string;
    displayName: string;
    email: string;
    password: string;
}

const validation = Yup.object().shape<IPersonRegisterFormSchema>({
    token: Yup.string(),
    displayName: Yup.string().label('Display name')
        .required(),
    email: Yup.string().label('Email')
        .required().email(),
    password: Yup.string().label('Password')
        .required()
});

const RegisterForm: React.FunctionComponent<IRegisterFormProps> = (props) => {
    const classes = useStyles();
    const registerState = useSelector(state => state.registerPerson.registerState);

    const startAction = registerPersonActions.register;
    const resolveAction = registerPersonActions.registerSuccess;
    const rejectAction = registerPersonActions.registerFailure;

    var successRedirectComponent = null;

    if (registerState.state === RequestState.Success) {
        successRedirectComponent = <Redirect push to={'/task'} />
    }

    return <>
        {successRedirectComponent}
        <div style={{ backgroundColor: 'blue' }}>{
            registerState.state === RequestState.Failure && registerState.responseStatus
                ? registerState.responseStatus.message
                : null
        }</div>
        <AsyncForm
            validate={validation}
            start={startAction}
            resolve={resolveAction}
            reject={rejectAction}
            formSubscription={{ submitting: true, pristine: true, submitError: true, dirtySinceLastSubmit: true, hasValidationErrors: true }}
        >{({ dirtySinceLastSubmit, hasValidationErrors, pristine, submitError, submitting }) => (
            <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                    <Typography variant="h4" component="h4">Sign Up</Typography>
                </Grid>
                <Grid item hidden xs={12}>
                    <Field
                        disabled
                        name="token"
                        component={TextFieldWrapper}
                        label="Token"
                        defaultValue={props.token}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        required
                        component={TextFieldWrapper}
                        disabled={submitting}
                        id="displayName"
                        label="Display Name"
                        name="displayName"
                        type="text"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        required
                        component={TextFieldWrapper}
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        autocomplete="username"
                        defaultValue={props.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        fullWidth
                        required
                        component={TextFieldWrapper}
                        disabled={submitting}
                        id="password"
                        label="Password"
                        name="password"
                        type="password"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-end">
                        {
                            submitting ?
                                <CircularProgress className={classes.progress} /> :
                                <>
                                    {submitError && <Typography color={'error'} variant="body1" component="p">{submitError}</Typography>}
                                    <Button
                                        className={classes.button}
                                        color="primary"
                                        disabled={pristine || hasValidationErrors || submitting || (submitError !== undefined && !dirtySinceLastSubmit)}
                                        type="submit"
                                        variant="contained"
                                    >
                                        Register
                                    </Button>
                                </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )}
        </AsyncForm>
    </>;
}

export default RegisterForm;