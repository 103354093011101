/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { match } from 'react-router-dom';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { AssemblyMethod } from '../../../dtos/Spread.dtos';
import { useSelector } from '../../../hooks/useTypedSelector';
import sequenceModule from '../../../store/reducers/sequences/sequence';
import { RequestFormState } from '../../../types/RequestState';
import CommonForm from '../../forms/CommonForm';
import RadioWrapper from '../../forms/FinalFormControls/RadioWrapper';
import TextFieldWrapper from '../../forms/FinalFormControls/TextFieldWrapper';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: '100%',
            width: '100%'
        },
        hr: {
            width: '100%'
        },
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IAssemblyFormSchema {
    sequenceId: number;
    assemblyMethod?: AssemblyMethod;
    genotype?: string;
}

const validation = Yup.object<IAssemblyFormSchema>().shape({
    sequenceId: Yup.mixed().label('Sequence')
        .required(),
    assemblyMethod: Yup.mixed().label('Assembly Method')
        .required(),
    gemotype: Yup.number().label('Genotype')
        .notRequired(),
});

interface IEditSequenceParams {
    epidemicId: string;
    sequenceId: string;
}

interface IAssembleSequenceFormProps {
    epidemicId?: number;
    className?: string;
    history: History;
    match: match<IEditSequenceParams>;
}

export const AssembleSequenceForm: React.FunctionComponent<IAssembleSequenceFormProps> = ({
    epidemicId: epidemicIdProp,
    className,
    history,
    match
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const sequenceState = useSelector(state => state.sequence);

    const epidemicId = epidemicIdProp || (match.params.epidemicId ? parseInt(match.params.epidemicId) : undefined);

    let sequence = sequenceState.data;

    if (sequence && epidemicId)
        sequence.epidemicId = epidemicId;

    const [assemblyMethod, setAssemblyMethod] = React.useState(undefined as AssemblyMethod | undefined);

    const handleAssembleClick = () => {
        if (sequence && sequence.id > 0)
            dispatch(sequenceModule.actions.createAssemblyJob(sequence.id));
    };

    const subForm2 = <Grid item lg={6} xs={12}>
        <Field
            name='genotype'
            component={TextFieldWrapper}
            formControlProps={{
                className: classes.formControl,
                fullWidth: true,
            }}
            label={assemblyMethod == AssemblyMethod.ReferenceGenotype ? 'Genotype' : 'Isolate'}
        />
    </Grid>;

    return <Paper className={classes.root}>
        <Typography variant='h5'>Assemble NextGen sequence data</Typography>
        {
            sequenceState.extraStates.createAssemblyJobState.state == RequestFormState.Pending ?
                <Skeleton /> :
                sequence ?
                    <CommonForm
                        history={history}
                        submitSuccess={sequenceState.extraStates.createAssemblyJobState.state == RequestFormState.SubmitSuccess}
                        initialValues={{
                            sequenceId: sequence.id,
                            assemblyMethod: undefined,
                            genotype: undefined,
                        }}
                        start={sequenceModule.actions.createAssemblyJob}
                        resolve={sequenceModule.actions.createAssemblyJobFormResponse}
                        reject={sequenceModule.actions.createAssemblyJobFormResponse}
                        validate={validation}
                        labelSave="Queue Job"
                    >
                        {() =>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <OnChange name="assemblyMethod">
                                    {(value: AssemblyMethod) => {
                                        setAssemblyMethod(value);
                                    }}
                                </OnChange>
                                <Grid item lg={6} xs={12}>
                                    <Field
                                        name="assemblyMethod"
                                        component={RadioWrapper}
                                        formControlProps={{
                                            fullWidth: true,
                                            className: classes.formControl,
                                        }}
                                        title={'Assembly method'}
                                        items={[
                                            {
                                                label: 'Reference Isolate',
                                                value: `${AssemblyMethod.ReferenceIsolate}`,
                                            },
                                            {
                                                label: 'Reference Genotype',
                                                value: `${AssemblyMethod.ReferenceGenotype}`,
                                            },
                                            {
                                                label: 'VP1 - Denovo + Reference Genotype',
                                                value: `${AssemblyMethod.VP1}`,
                                            },
                                            {
                                                label: 'Denovo',
                                                value: `${AssemblyMethod.Denovo}`,
                                            },
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} />
                                {
                                    assemblyMethod == AssemblyMethod.ReferenceGenotype || assemblyMethod == AssemblyMethod.ReferenceIsolate ?
                                        subForm2 :
                                        null
                                }
                                <Grid item xs={12} />
                            </Grid>
                        }
                    </CommonForm> :
                    null
        }
    </Paper>
}