/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import Skeleton from 'react-loading-skeleton';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import epidemicModule from '../../store/reducers/epidemics/epidemic';
import { epidemicsHooks } from '../../store/reducers/epidemics/epidemicSearch';
import { groupEpidemicActions, groupEpidemicSelectors } from '../../store/reducers/groupEpidemics/groupEpidemic';
import { groupsHooks } from '../../store/reducers/groups/groupSearch';
import { RequestFormState, RequestState } from '../../types/RequestState';
import EditForm from '../forms/EditForm';
import SelectWrapper from '../forms/FinalFormControls/SelectWrapper';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import groupModule from '../../store/reducers/groups/group';
import { useMemo } from 'react';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface IGroupEpidemicEditFormProps {
    editGroupEpidemic?: SpreadDtos.PersonGroupEpidemic;
    epidemicId?: number;
    epidemicData?: SpreadDtos.Epidemic;
    groupId?: number;
    groupData?: SpreadDtos.PersonGroup;
    history: History;
    loadingGroupEpidemic?: boolean;
    successRouteCallback?: (data: any) => string;
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
    }),
);

interface IGroupEpidemicFormSchema extends Partial<SpreadDtos.PersonGroupEpidemic> { }

const groupEpidemicSchema = Yup.object<IGroupEpidemicFormSchema>().shape({
    epidemicId: Yup.number().label('Epidemic'),
    personGroupId: Yup.number().label('Group'),
});

const validation = Yup.object().shape({
    object: groupEpidemicSchema.notRequired()
});

const GroupEpidemicEditForm: React.FunctionComponent<IGroupEpidemicEditFormProps> = ({
    editGroupEpidemic,
    epidemicId,
    epidemicData,
    groupId,
    groupData,
    history,
    loadingGroupEpidemic,
    successRouteCallback,
    url,
}) => {
    const classes = useStyles();

    const epPageOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [epidemicsData, , , epidemicsRequestState] = epidemicsHooks.useSearch(undefined, epPageOps);

    const epidemicOptions = epidemicsData && epidemicsData.results && epidemicsData.results.length > 0 ?
        epidemicsData.results.map((e =>
            <MenuItem
                key={e.id}
                value={e.id}
            >
                {e.name}
            </MenuItem>
        )) :
        [];

    const groupPageOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [groupsData, , , groupsRequestState] = groupsHooks.useSearch(undefined, groupPageOps);

    const groupOptions = groupsData && groupsData.results && groupsData.results.length > 0 ?
        groupsData.results.map((g =>
            <MenuItem
                key={g.id}
                value={g.id}
            >
                {g.displayName}
            </MenuItem>
        )) :
        [];

    const epidemicIdLoaded = React.useMemo(() => epidemicId, [epidemicId]);
    const groupIdLoaded = React.useMemo(() => groupId, [groupId]);

    const editMode = editGroupEpidemic !== undefined;

    const wrappedObject = editMode ? {
        object: editGroupEpidemic
    } : undefined;

    return <EditForm
        actions={groupEpidemicActions}
        edit={wrappedObject}
        history={history}
        loading={!!loadingGroupEpidemic}
        url={url}
        successRouteCallback={successRouteCallback}
        validate={validation}
        selectors={groupEpidemicSelectors}
    >{() => <>
        <Typography variant="h4" component="h4">{
            epidemicIdLoaded && groupIdLoaded ?
                'ERROR: this page is not implemented appropriately. Please contact your system administrator.' :
                groupIdLoaded ?
                    groupData ? groupData.displayName 
                    : <Skeleton /> :
                    epidemicIdLoaded ?
                        epidemicData ? epidemicData.name :
                        <Skeleton /> :
                        'Assign Group/Epidemic'
        }</Typography>
        <Grid item xs={12}>
            {
                epidemicsRequestState.state !== RequestState.Pending ?
                    <Field
                        disabled={epidemicId}
                        name="object.epidemicId"
                        component={SelectWrapper}
                        defaultValue={epidemicId}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Epidemic"
                    >
                        <MenuItem value={0}></MenuItem>
                        {epidemicOptions}
                    </Field> :
                    <Skeleton />
            }
        </Grid>
        <Grid item xs={12}>
            {
                groupsRequestState.state !== RequestState.Pending ?
                    <Field
                        disabled={groupId}
                        name="object.personGroupId"
                        component={SelectWrapper}
                        defaultValue={groupId}
                        formControlProps={{
                            className: classes.formControl,
                            fullWidth: true,
                            required: true
                        }}
                        label="Group"
                        
                    >
                        <MenuItem value={0}></MenuItem>
                        {groupOptions}
                    </Field> :
                    <Skeleton />
            }
        </Grid>
    </>}
    </EditForm>
};

export default GroupEpidemicEditForm;