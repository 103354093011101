/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import timeline from '../../assets/images/FMD_outbreak_timelines_no title.png';
import ButtonLink from '../common/ButtonLink';
import { HasPermission } from '../common/HasPermission';
import { AreaDateTypeList } from './AreaDateTypeList';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2)
        },
        bottomBox: {},
        list: {
            margin: theme.spacing(0, 0, 4, 0)
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
        timelineImageWrapper: {
            textAlign: 'center'
        },
        timelineImage: {
            width: '50%',
        }
    }),
);

interface IViewAreaDateTypesParams {
    epidemicId?: string;
}

interface IViewAreaDateTypesProps {
    className?: string;
    editMode?: boolean;
    epidemicId?: number;
    history: History;
    match: match<IViewAreaDateTypesParams>;
}

export const ViewAreaDateTypes: React.FunctionComponent<IViewAreaDateTypesProps> = (props) => {
    const classes = useStyles();

    let url = props.match.url;
    url = (url.endsWith('/edit') ? url.substr(0, url.lastIndexOf('/edit')) : url) + (!url.endsWith('/areaDateType') ? '/areaDateType' : '');
    const epidemicId = props.epidemicId || (props.match.params && props.match.params.epidemicId ? parseInt(props.match.params.epidemicId) : undefined);

    return <Paper className={classNames(classes.root, props.className)}>
        <Typography variant="h4" component="h4">Date Definitions</Typography>
        <div className={classes.timelineImageWrapper}>
            <img
                className={classes.timelineImage}
                src={timeline}
                alt="Epidemic Timeline"
            />
        </div>
        <AreaDateTypeList
            className={classes.list}
            editMode={props.editMode}
            epidemicId={epidemicId}
            history={props.history}
            url={url}
        />
        <Box className={classes.bottomBox} display="flex" justifyContent="flex-end">
            <HasPermission permission="CreateAreaDateType" objectId={epidemicId}>
                <ButtonLink
                    aria-label="Create Date"
                    color="primary"
                    variant="contained"
                    to={`${url}/create`}
                >
                    <AddBoxIcon className={classes.buttonSubmitIcon} />
                    Create
                </ButtonLink>
            </HasPermission>
        </Box>
    </Paper>
}