/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { LinkProps } from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { Box } from '@material-ui/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { History } from 'history';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import { AuthState } from '../../types/AuthState';
import { authenticatedPersonActions } from '../../store/reducers/person/authenticatedPerson';
import { IStoreState } from '../../store/store';

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    avatarLink: {
        marginLeft: theme.spacing(1),
        textDecoration: 'none',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export interface IHeaderProps {
    classNames?: string;
    onMenuClick: () => void;
    history: History;
}

export function Header(props: IHeaderProps) {
    const userAuthenticated = useSelector(state => state.authenticatedPerson.authState) === AuthState.Authenticated;
    const userAuthenticatedData = useSelector(state => state.authenticatedPerson.data);
    const userSession = useSelector(state => state.authenticatedPerson.session);

    const dispatch = useDispatch();
    const onLogout = React.useCallback(() => {
        dispatch(authenticatedPersonActions.logout());
    }, [dispatch])

    const classes = useStyles();

    type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
    const CollisionLink = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'innerRef' | 'to'>>(
        (props: any, ref: any) => <Link innerRef={ref as any} to={'/login'} {...props} />,
    );

    const notificationCount = userAuthenticatedData ? userAuthenticatedData.notificationCount : 0;

    return <AppBar position="fixed" className={props.classNames}>
        <Toolbar>
            <Hidden mdUp implementation="css">
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={props.onMenuClick}>
                    <MenuIcon />
                </IconButton>
            </Hidden>

            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={props.history.goBack}>
                <BackIcon />
            </IconButton>

            <Box className={classes.title} component="div">
                <Hidden xsDown implementation="css">
                    <PageTitles />
                </Hidden>
            </Box>

            {/*
            <FormControlLabel
                control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} checked={currentTheme.palette.type === 'dark'} />}
                label=""
                onClick={handleToggleTheme}
            />
*/}
            {userAuthenticated
                ? <>
                    <Button onClick={onLogout} color="inherit">Logout</Button>
                    <Link className={classes.avatarLink} to={'/profile'}>
                        <Tooltip title={`You have ${notificationCount} notification${notificationCount != 1 ? 's' : ''}`}>
                            <Badge badgeContent={notificationCount} color="secondary">
                                <Avatar>{userSession && userSession.displayName ? userSession.displayName[0] : null}</Avatar>
                            </Badge>
                        </Tooltip>
                    </Link>
                </>
                : <Button component={CollisionLink} color="inherit">Login</Button>
            }
        </Toolbar>
    </AppBar>;
};

interface IPageTitleProps {
    className?: string;
    displayName: (data: any) => string;
    selector: (state: IStoreState) => any;
}

/*const test: IPageTitleProps<Epidemic | undefined> = {
    className: '',
    displayName: (s => s ? s.name : 'nothing'),
    selector: (s => s.epidemic.data)
}*/

const PageTitle: React.FunctionComponent<IPageTitleProps> = (props) => {
    const selected = useSelector(props.selector);

    return selected ? <Typography className={props.className} variant="h6">
        {props.displayName(selected)}
    </Typography>
        : <div className={props.className}><Skeleton width={200} /></div>
}

const PageTitles: React.FunctionComponent<any> = (props) => {
    return <>
        <Switch>
            <Route path={`/profile`}
                render={() => <Typography variant="h6">
                    My Profile
                </Typography>}
            />

            <Route exact path={`/task`}
                render={() => <Typography variant="h6">
                    Tasks
                </Typography>}
            />

            <Route path={`/task/select`}
                render={() => <Typography variant="h6">
                    Select Epidemic
                </Typography>}
            />

            <Route exact path={`/epidemic/create`}
                render={() => <Typography variant="h6">
                    Create Epidemic
                </Typography>}
            />

            <Route exact path={`/epidemic`}
                render={() => <Typography variant="h6">
                    Epidemics
                </Typography>}
            />

            <Route path={`/epidemic/:epidemicId`}
                render={routeProps => routeProps.match
                    && <PageTitle
                        displayName={s => s ? s.name : 'Resource not found'}
                        selector={s => s.epidemic.data}
                        {...routeProps}
                    />}
            />

            <Route exact path={`/group/create`}
                render={() => <Typography variant="h6">
                    Create Group
                </Typography>}
            />

            <Route path={`/group`}
                render={() => <Typography variant="h6">
                    Groups
                </Typography>}
            />

            <Route path={`/group/:groupId`}
                render={routeProps => routeProps.match
                    && <PageTitle
                        displayName={s => s ? s.displayName : 'Resource not found'}
                        selector={s => s.group.data}
                        {...routeProps}
                    />}
            />

            <Route path={`/`}
                render={() => <Typography variant="h6">
                    SPREAD
                </Typography>}
            />
        </Switch>
    </>
}