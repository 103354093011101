
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Paper, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { History } from 'history';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import platformModule from '../../store/reducers/platforms/platform';
import { RequestFormState } from '../../types/RequestState';
import PlatformEditForm from './PlatformEditForm';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditPlatformParams {
    platformId: string;
}

interface IEditPlatformProps {
    platformId?: number;
    history: History;
    match: match<IEditPlatformParams>;
}

export const EditPlatform: React.FunctionComponent<IEditPlatformProps> = (props) => {
    const platformState = useSelector(state => state.platform);
    const dispatch = useDispatch();
    const classes = useStyles({});

    const platformId = props.platformId || (props.match.params ? parseInt(props.match.params.platformId) : undefined);

    useEffect(() => {
        if (platformModule.actions.load) {
            dispatch(platformModule.actions.load(platformId))
        }
    }, [dispatch, platformId]);

    return <>
        <Paper className={classes.root}>
            <Typography variant="h4" component="h4">Edit Platform</Typography>
            <PlatformEditForm
                editPlatform={platformState.data}
                history={props.history}
                loadingPlatform={platformState.states.loadState.state === RequestFormState.Pending && !platformState.data}
                url={props.match.url}
            />
        </Paper>
    </>
}
