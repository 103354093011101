/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Grid from '@material-ui/core/Grid';
import { History } from 'history';
import * as React from 'react';
import { Field } from 'react-final-form';
import * as Yup from 'yup';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../dtos/Spread.dtos';
import EditForm from '../forms/EditForm';
import TextFieldWrapper from '../forms/FinalFormControls/TextFieldWrapper';
import { clear } from '../forms/mutators';
import LibraryStrategyModule from '../../store/reducers/libraryStrategys/libraryStrategy';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ILibraryStrategyEditFormProps {
    editLibraryStrategy?: SpreadDtos.LibraryStrategy;
    history: History;
    loadingLibraryStrategy?: boolean;
    url: string;
}

interface ILibraryStrategyFormSchema extends Partial<SpreadDtos.LibraryStrategy> {
    name: string;
}

const LibraryStrategySchema = Yup.object<ILibraryStrategyFormSchema>().shape({
    name: Yup.string().label('Name').required()
});

const validation = Yup.object().shape({
    object: LibraryStrategySchema.notRequired()
})


const LibraryStrategyEditForm: React.FunctionComponent<ILibraryStrategyEditFormProps> = ({
    editLibraryStrategy,
    history,
    loadingLibraryStrategy,
    url,
}) => {
    const editMode = editLibraryStrategy !== undefined;

    const wrappedObject = editMode ? {
        object: editLibraryStrategy
    } : undefined;

    return <EditForm
        actions={LibraryStrategyModule.actions}
        edit={wrappedObject}
        history={history}
        mutators={{ clear: clear }}
        loading={!!loadingLibraryStrategy}
        selectors={LibraryStrategyModule.selectors}
        successRouteCallback={(d) => d ? `/admin/libraryStrategy` : '/404'}
        url={url}
        validate={validation}
    >{() => <>
        <Grid item xs={12}>
            <Field
                fullWidth
                required
                name="object.name"
                component={TextFieldWrapper}
                type="text"
                label="Name"
            />
        </Grid>
    </>}
    </EditForm>
};


export default LibraryStrategyEditForm;