/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for loading, editing and saving an sequence
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ConsensusSequence, GetAnnotationData, GetAnnotationDataResponse, GetAssemblyData, GetAssemblyDataResponse, GetConsensusSequence, GetConsensusSequenceResponse, UpdateAnnotationQA, UpdateAnnotationQAResponse, UpdateAssemblyQA, UpdateAssemblyQAResponse } from '../../../dtos/Spread.dtos';
import createAction from '../../../helpers/createAction';
import { createGetRequest, createPutRequest } from '../../../helpers/createRequest';
import { useSelector } from '../../../hooks/useTypedSelector';
import { IFormRequestState } from '../../../types/IRequestState';
import { RequestFormState } from '../../../types/RequestState';
import { ActionType, baseCrudInitialState, createCrudModule, IBaseCrudActions, IBaseCrudApi, IBaseCrudHooks, IBaseCrudSelectors, IBaseCrudState } from '../common/crud';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IAnnotationQAParams {
    consensusSequenceId: number;
    passed?: boolean;
    comment?: string;
}

interface IAssemblyQAParams {
    consensusSequenceId: number;
    passed?: boolean;
    comment?: string;
}

interface ILoadAnnotationDataParams {
    consensusSequenceId: number;
}

interface ILoadAssemblyDataParams {
    consensusSequenceId: number;
}

interface IConsensusSequenceSubState extends IBaseCrudState<ConsensusSequence> {
    annotationData: Object | undefined;
    assemblyData: Object | undefined;
    extraStates: {
        annotationQAState: IFormRequestState;
        assemblyQAState: IFormRequestState;
        loadAnnotationData: IFormRequestState;
        loadAssemblyData: IFormRequestState;
    }
}

export interface IConsensusSequenceState {
    consensusSequence: IConsensusSequenceSubState;
}

interface IConsensusSequenceActions extends IBaseCrudActions<ConsensusSequence> {
    annotationQA: ActionType<(consensusSequenceId: number, passed?: boolean, comment?: string) => IAnnotationQAParams>;
    annotationQAFormResponse: ActionType<(response: UpdateAnnotationQAResponse, state: RequestFormState) => { response: UpdateAnnotationQAResponse, state: RequestFormState }>;
    assemblyQA: ActionType<(consensusSequenceId: number, passed?: boolean, comment?: string) => IAssemblyQAParams>;
    assemblyQAFormResponse: ActionType<(response: UpdateAssemblyQAResponse, state: RequestFormState) => { response: UpdateAssemblyQAResponse, state: RequestFormState }>;
    loadAnnotationData: ActionType<(consensusSequenceId: number) => ILoadAssemblyDataParams>;
    loadAnnotationDataFormResponse: ActionType<(response: GetAnnotationDataResponse, state: RequestFormState) => { response: GetAnnotationDataResponse, state: RequestFormState }>;
    loadAssemblyData: ActionType<(consensusSequenceId: number) => ILoadAssemblyDataParams>;
    loadAssemblyDataFormResponse: ActionType<(response: GetAssemblyDataResponse, state: RequestFormState) => { response: GetAssemblyDataResponse, state: RequestFormState }>;
}

interface IConsensusSequenceApi extends IBaseCrudApi {
    updateAnnotationQA: ReturnType<typeof createPutRequest>;
    updateAssemblyQA: ReturnType<typeof createPutRequest>;
    getAnnotationData: ReturnType<typeof createGetRequest>;
    getAssemblyData: ReturnType<typeof createGetRequest>;
}

interface IConsensusSequenceSelectors extends IBaseCrudSelectors<ConsensusSequence, IConsensusSequenceSubState> {
    annotationData: (state: IConsensusSequenceState) => IConsensusSequenceSubState["annotationData"];
    annotationQAState: (state: IConsensusSequenceState) => IConsensusSequenceSubState["extraStates"]["annotationQAState"];
    assemblyData: (state: IConsensusSequenceState) => IConsensusSequenceSubState["assemblyData"];
    assemblyQAState: (state: IConsensusSequenceState) => IConsensusSequenceSubState["extraStates"]["assemblyQAState"];
    loadAnnotationDataState: (state: IConsensusSequenceState) => IConsensusSequenceSubState["extraStates"]["loadAnnotationData"];
    loadAssemblyDataState: (state: IConsensusSequenceState) => IConsensusSequenceSubState["extraStates"]["loadAssemblyData"];
}

interface IConsensusSequenceHooks extends IBaseCrudHooks<ConsensusSequence> {
    useLoadAnnotationData: (consensusSequenceId: number) => [Object | undefined, IFormRequestState];
    useLoadAssemblyData: (consensusSequenceId: number) => [Object | undefined, IFormRequestState];
}

/*
 * ---------------------------------------------------------------------------------
 * Reducer Module
 * ---------------------------------------------------------------------------------
 */

const initialConsensusSequenceState: IConsensusSequenceSubState = {
    annotationData: undefined,
    assemblyData: undefined,
    extraStates: {
        annotationQAState: {
            state: RequestFormState.None
        },
        assemblyQAState: {
            state: RequestFormState.None
        },
        loadAnnotationData: {
            state: RequestFormState.None
        },
        loadAssemblyData: {
            state: RequestFormState.None
        },
    },
    ...baseCrudInitialState<ConsensusSequence>()
};

const consensusSequenceModule = createCrudModule<
    ConsensusSequence,
    undefined,
    GetConsensusSequenceResponse,
    undefined,
    undefined,
    undefined,
    IConsensusSequenceSubState,
    IConsensusSequenceActions,
    IConsensusSequenceApi,
    IConsensusSequenceSelectors,
    IConsensusSequenceHooks>(
        'consensusSequence',
        undefined,
        GetConsensusSequence,
        undefined,
        undefined,
        undefined,
        initialConsensusSequenceState,
    );

// Annotation QA action

consensusSequenceModule.actions.annotationQA = createAction(`@@consensusSequence/ANNOTATION_QA`,
    (consensusSequenceId: number, passed?: boolean, comment?: string) => ({ consensusSequenceId, passed, comment })
);

consensusSequenceModule.actions.annotationQAFormResponse = createAction(`@@consensusSequence/ANNOTATION_QA_FORM_RESPONSE`,
    (response: UpdateAnnotationQAResponse, state: RequestFormState) => ({ response, state })
);

const annotationQAReducer = (state: IConsensusSequenceSubState) => update(
    state,
    {
        extraStates: {
            annotationQAState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const annotationQAFormResponseReducer = (state: IConsensusSequenceSubState, payload: any) => update(
    state,
    {
        extraStates: {
            annotationQAState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.annotationQA, (state: IConsensusSequenceSubState) => (
    annotationQAReducer(state)
));

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.annotationQAFormResponse, (state: IConsensusSequenceSubState, payload) => (
    annotationQAFormResponseReducer(state, payload)
));

consensusSequenceModule.api.updateAnnotationQA = createPutRequest(
    UpdateAnnotationQA,
    (consensusSequenceId: number, passed?: boolean, comment?: string) => ({
        consensusSequenceId,
        passed,
        comment,
    }),
);

const annotationQAEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(consensusSequenceModule.actions.annotationQA.getType()),
        mergeMap((action: Action<IAnnotationQAParams, {}>) =>
            consensusSequenceModule.api.updateAnnotationQA(action.payload.consensusSequenceId, action.payload.passed, action.payload.comment)
                .pipe(
                    mergeMap(response => of(consensusSequenceModule.actions.annotationQAFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(consensusSequenceModule.actions.annotationQAFormResponse(e, RequestFormState.ServerError)))
                )
        )
    );

consensusSequenceModule.selectors.annotationQAState = (state: IConsensusSequenceState) => state['consensusSequence'].extraStates.annotationQAState;

// Assembly QA action

consensusSequenceModule.actions.assemblyQA = createAction(`@@consensusSequence/ASSEMBLY_QA`,
    (consensusSequenceId: number, passed?: boolean, comment?: string) => ({ consensusSequenceId, passed, comment })
);

consensusSequenceModule.actions.assemblyQAFormResponse = createAction(`@@consensusSequence/ASSEMBLY_QA_FORM_RESPONSE`,
    (response: UpdateAssemblyQAResponse, state: RequestFormState) => ({ response, state })
);

const assemblyQAReducer = (state: IConsensusSequenceSubState) => update(
    state,
    {
        extraStates: {
            assemblyQAState: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const assemblyQAFormResponseReducer = (state: IConsensusSequenceSubState, payload: any) => update(
    state,
    {
        extraStates: {
            assemblyQAState: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus
                }
            }
        }
    }
);

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.assemblyQA, (state: IConsensusSequenceSubState) => (
    assemblyQAReducer(state)
));

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.assemblyQAFormResponse, (state: IConsensusSequenceSubState, payload) => (
    assemblyQAFormResponseReducer(state, payload)
));

consensusSequenceModule.api.updateAssemblyQA = createPutRequest(
    UpdateAssemblyQA,
    (consensusSequenceId: number, passed?: boolean, comment?: string) => ({
        consensusSequenceId,
        passed,
        comment,
    }),
);

const assemblyQAEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(consensusSequenceModule.actions.assemblyQA.getType()),
        mergeMap((action: Action<IAssemblyQAParams, {}>) => {
            return consensusSequenceModule.api.updateAssemblyQA(action.payload.consensusSequenceId, action.payload.passed, action.payload.comment)
                .pipe(
                    mergeMap(response => of(consensusSequenceModule.actions.assemblyQAFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(consensusSequenceModule.actions.assemblyQAFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

consensusSequenceModule.selectors.assemblyQAState = (state: IConsensusSequenceState) => state['consensusSequence'].extraStates.assemblyQAState;

// Load Annotation Data action

consensusSequenceModule.actions.loadAnnotationData = createAction(`@@consensusSequence/LOAD_ANNOTATION_DATA`,
    (consensusSequenceId: number) => ({ consensusSequenceId })
);

consensusSequenceModule.actions.loadAnnotationDataFormResponse = createAction(`@@consensusSequence/LOAD_ANNOTATION_DATA_FORM_RESPONSE`,
    (response: GetAnnotationDataResponse, state: RequestFormState) => ({ response, state })
);

const loadAnnotationDataReducer = (state: IConsensusSequenceSubState) => update(
    state,
    {
        extraStates: {
            loadAnnotationData: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const loadAnnotationDataFormResponseReducer = (state: IConsensusSequenceSubState, payload: any, data: Object) => update(
    state,
    {
        annotationData: {
            $set: data
        },
        extraStates: {
            loadAnnotationData: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus,
                }
            }
        }
    }
);

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.loadAnnotationData, (state: IConsensusSequenceSubState) => (
    loadAnnotationDataReducer(state)
));

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.loadAnnotationDataFormResponse, (state: IConsensusSequenceSubState, payload) => (
    loadAnnotationDataFormResponseReducer(state, payload, payload.response.annotationData)
));

consensusSequenceModule.api.getAnnotationData = createGetRequest(
    GetAnnotationData,
    (consensusSequenceId: number) => ({
        consensusSequenceId,
    }),
);

const loadAnnotationDataEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(consensusSequenceModule.actions.loadAnnotationData.getType()),
        mergeMap((action: Action<ILoadAssemblyDataParams, {}>) => {
            return consensusSequenceModule.api.getAnnotationData(action.payload.consensusSequenceId)
                .pipe(
                    mergeMap(response => of(consensusSequenceModule.actions.loadAnnotationDataFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(consensusSequenceModule.actions.loadAnnotationDataFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

consensusSequenceModule.selectors.annotationData = (state: IConsensusSequenceState) => state.consensusSequence.annotationData;
consensusSequenceModule.selectors.loadAnnotationDataState = (state: IConsensusSequenceState) => state.consensusSequence.extraStates.loadAnnotationData;

const useLoadAnnotationData = (consensusSequenceId: number): [Object | undefined, IFormRequestState] => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.consensusSequence.annotationData);
    const requestState = useSelector(state => state.consensusSequence.extraStates.loadAnnotationData);

    useEffect(() => {
        if (consensusSequenceId && consensusSequenceId > 0) {
            dispatch(consensusSequenceModule.actions.loadAnnotationData(consensusSequenceId));
        }

        return function cleanup() {
            dispatch(consensusSequenceModule.actions.loadAnnotationDataFormResponse({ annotationData: undefined }, RequestFormState.None));
        }
    }, [dispatch, consensusSequenceId]);

    return [
        data,
        requestState
    ];
};

consensusSequenceModule.hooks.useLoadAnnotationData = useLoadAnnotationData;

// Load Assembly Data action

consensusSequenceModule.actions.loadAssemblyData = createAction(`@@consensusSequence/LOAD_ASSEMBLY_DATA`,
    (consensusSequenceId: number) => ({ consensusSequenceId })
);

consensusSequenceModule.actions.loadAssemblyDataFormResponse = createAction(`@@consensusSequence/LOAD_ASSEMBLY_DATA_FORM_RESPONSE`,
    (response: GetAssemblyDataResponse, state: RequestFormState) => ({ response, state })
);

const loadAssemblyDataReducer = (state: IConsensusSequenceSubState) => update(
    state,
    {
        extraStates: {
            loadAssemblyData: {
                $set: {
                    state: RequestFormState.Pending
                }
            }
        }
    }
);

const loadAssemblyDataFormResponseReducer = (state: IConsensusSequenceSubState, payload: any, data: Object) => update(
    state,
    {
        assemblyData: {
            $set: data
        },
        extraStates: {
            loadAssemblyData: {
                $set: {
                    state: payload.state,
                    responseStatus: payload.response.responseStatus,
                }
            }
        }
    }
);

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.loadAssemblyData, (state: IConsensusSequenceSubState) => (
    loadAssemblyDataReducer(state)
));

consensusSequenceModule.reducer.on(consensusSequenceModule.actions.loadAssemblyDataFormResponse, (state: IConsensusSequenceSubState, payload) => (
    loadAssemblyDataFormResponseReducer(state, payload, payload.response.assemblyData)
));

consensusSequenceModule.api.getAssemblyData = createGetRequest(
    GetAssemblyData,
    (consensusSequenceId: number) => ({
        consensusSequenceId,
    }),
);

const loadAssemblyDataEpic: Epic<ReturnType<ReturnType<typeof createAction>>, any, any, any> = (action$, state$: StateObservable<any>) =>
    action$.pipe(
        ofType(consensusSequenceModule.actions.loadAssemblyData.getType()),
        mergeMap((action: Action<ILoadAssemblyDataParams, {}>) => {
            return consensusSequenceModule.api.getAssemblyData(action.payload.consensusSequenceId)
                .pipe(
                    mergeMap(response => of(consensusSequenceModule.actions.loadAssemblyDataFormResponse(response, RequestFormState.SubmitSuccess))),
                    catchError(e => of(consensusSequenceModule.actions.loadAssemblyDataFormResponse(e, RequestFormState.ServerError)))
                );
        })
    );

consensusSequenceModule.selectors.assemblyData = (state: IConsensusSequenceState) => state.consensusSequence.assemblyData;
consensusSequenceModule.selectors.loadAssemblyDataState = (state: IConsensusSequenceState) => state.consensusSequence.extraStates.loadAssemblyData;

const useLoadAssemblyData = (consensusSequenceId: number): [Object | undefined, IFormRequestState] => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.consensusSequence.assemblyData);
    const requestState = useSelector(state => state.consensusSequence.extraStates.loadAssemblyData);

    useEffect(() => {
        if (consensusSequenceId && consensusSequenceId > 0) {
            dispatch(consensusSequenceModule.actions.loadAssemblyData(consensusSequenceId));
        }

        return function cleanup() {
            dispatch(consensusSequenceModule.actions.loadAssemblyDataFormResponse({ assemblyData: undefined }, RequestFormState.None));
        }
    }, [dispatch, consensusSequenceId]);

    return [
        data,
        requestState
    ];
};

consensusSequenceModule.hooks.useLoadAssemblyData = useLoadAssemblyData;

/* Add additional epics to module */

consensusSequenceModule.epics = combineEpics(consensusSequenceModule.epics, annotationQAEpic, assemblyQAEpic, loadAnnotationDataEpic, loadAssemblyDataEpic);

/*
 * ---------------------------------------------------------------------------------
 * Export Reducers
 * ---------------------------------------------------------------------------------
 */

export default consensusSequenceModule;