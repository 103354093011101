/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import * as React from 'react';
import { match } from 'react-router';
import { History } from 'history';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { ContentSection } from '../../components/content/ContentSection';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        featuresBacking: {
            //background: 'linear-gradient(0, #64C2CA, #0963A1)'
            background: '#0963A1'
        },
        featuresContainer: {
            paddingTop: '2rem',
            paddingBottom: '3rem',
        },
        featureWrapper: {
            width:'100%',
        },
        feature: {
            border: '2px white solid',
            borderRadius: '6px',
            color: 'white',
            padding: '1.5rem',
            height: '100%',
        },
        featuresGridContainer: {
            width: '100%',
            [theme.breakpoints.up('md')]: {
              width: '80%',
            },
            [theme.breakpoints.up('lg')]: {
              width: '70%',
            },
        },
        featureIcon: {
            fontSize: '6rem',
            marginRight: '1rem'
        },
        featureTitle: {
            fontWeight: 'bold'
        },
        featureDescription: {

        }
    }),
);

interface IContentFeature {
    icon: any;
    title: string;
    description?: string;
}

interface IContentFeaturesProps {
    features: IContentFeature[];
}

export const ContentFeatures: React.FunctionComponent<IContentFeaturesProps> = (props) => {
    const classes = useStyles(props);

    return <ContentSection
        backingClassName={classes.featuresBacking}
        className={classes.featuresContainer}
    >
        <Box display="flex" flexDirection="row" justifyContent="center">
            <Grid className={classes.featuresGridContainer} container spacing={2}>
                {
                    props.features.map((feature) => {
                        const Component = feature.icon;

                        return <Grid className={classes.featureWrapper} item sm={12} md={6} key={feature.title}>
                            <Box className={classes.feature} display="flex" flexDirection="row" alignItems="center">
                                <Component className={classes.featureIcon} />
                                <Typography className={classes.featureTitle} variant={'h5'}>
                                    {feature.title}
                                </Typography>
                                <Typography className={classes.featureTitle} variant={'body1'}>
                                    {feature.description}
                                </Typography>
                            </Box>

                        </Grid>
                    })
                }
            </Grid>
        </Box>
    </ContentSection>
}
