/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { useSelector } from '../../hooks/useTypedSelector';
import libraryStrategyModule from '../../store/reducers/libraryStrategys/libraryStrategy';
import { libraryStrategySearchActions, libraryStrategySearchHooks } from '../../store/reducers/libraryStrategys/libraryStrategySearch';
import { RequestState } from '../../types/RequestState';
import { SortableTable } from '../table/SortableTable';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        table: {
            //minWidth: 500,
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        clickableRow: {
            cursor: 'pointer',
        },
        buttonSubmitIcon: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface ILibraryStrategyListProps {
    className?: string;
    editMode?: boolean;
    history: History;
    url: string;
}

export const LibraryStrategyList: React.FunctionComponent<ILibraryStrategyListProps> = ({ className, editMode, history, url }) => {
    const classes = useStyles({});
    const dispatch = useDispatch();

    const deleteRequestState = useSelector(state => state.libraryStrategy.states.deleteState);

    const specOps = useMemo(() => ({ }), [deleteRequestState]);
    const pagOps = useMemo(() => ({ orderBy: "name", skip: 0, take: 100 }), []);
    const [data, paginateOptions, , requestState] = libraryStrategySearchHooks.useSearch(specOps, pagOps);

    const headerColumns = [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: '', numeric: false, disablePadding: false, label: 'Delete', disableSorting: true },
    ];

    // Delete modals
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [idToDelete, setIdToDelete] = React.useState(undefined as number | undefined);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIdToDelete(parseInt(event.currentTarget.value));
            setDeleteConfirmOpen(true);
        },
        [isDeleteConfirmOpen, setIdToDelete],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen],
    );

    const actions = libraryStrategyModule.actions;
    const handleDeleteConfirm = React.useCallback(
        () => {
            if (actions && actions.delete) {
                dispatch(actions.delete(idToDelete));
                setIdToDelete(undefined);
                setDeleteConfirmOpen(false);
            }
        },
        [idToDelete],
    );

    const libraryStrategyToDelete = idToDelete && data && data.results ? data.results.find(t => t.id == idToDelete) : undefined;

    return <>
        <SortableTable
            className={classNames(className, classes.table)}
            headerColumns={headerColumns}
            paginateOptions={paginateOptions}
            rowCount={data ? data.total : 0}
            updatePaginateOptions={libraryStrategySearchActions.updatePaginateOptions}
        >
            {
                data && data.results && data.results.length > 0
                    ? data.results.map((item =>
                        <TableRow
                            className={classes.clickableRow}
                            key={item.id}>
                            <TableCell component="th" scope="row">
                                {
                                    editMode ?
                                        <Link component={RouterLink} to={`${url}/${item.id}`}>
                                            {item.name}
                                        </Link> :
                                        item.name
                                }
                            </TableCell>
                            <TableCell>
                                <Button
                                    aria-label="Delete Library Strategy"
                                    color="primary"
                                    value={item.id}
                                    onClick={handleDeleteClick}
                                >
                                    <DeleteIcon className={classes.buttonSubmitIcon} />
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))
                    : <TableRow>
                        <TableCell component="th" colSpan={2}>
                            {requestState.state === RequestState.Pending
                                ? <Skeleton />
                                : 'There\'s nothing here!'}
                        </TableCell>
                    </TableRow>
            }
        </SortableTable>
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete {`"${libraryStrategyToDelete ? libraryStrategyToDelete.name : '[ERROR]'}"`}? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}