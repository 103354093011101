/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import { match, Redirect, Route, Switch } from 'react-router';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { SequenceAnnotationQAForm } from '../components/genomic/sequence/SequenceAnnotationQAForm';
import { SequenceAssemblyQAForm } from '../components/genomic/sequence/SequenceAssemblyQAForm';
import { ViewGenbank } from '../components/genomic/sequence/ViewGenbank';
import consensusSequenceModule from '../store/reducers/sequences/consensusSequence';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

interface IConsensusSequenceRouterParams {
    consensusSequenceId?: string;
    sequenceId?: string;
    specimenId?: string;
}

interface IConsensusSequenceRouterProps {
    consensusSequenceId?: number;
    match: match<IConsensusSequenceRouterParams>;
}

export const ConsensusSequenceRouter: React.FunctionComponent<IConsensusSequenceRouterProps> = ({ consensusSequenceId: consensusSequenceIdProp, match }) => {
    const consensusSequenceId = consensusSequenceIdProp || (match.params.consensusSequenceId ? parseInt(match.params.consensusSequenceId) : -1);

    // Load in consensus sequence so child routes can use
    const consensusSequenceIdMemo = React.useMemo(() => consensusSequenceId, [consensusSequenceId])
    const [, response] = consensusSequenceModule.hooks.useLoad(consensusSequenceIdMemo);

    return response.responseStatus && response.responseStatus.errorCode && response.responseStatus.errorCode == "ArgumentException" ?
        <Redirect to="/404" /> :
        <Switch>
            <Route exact path={`${match.path}/gbk`} component={ViewGenbank} />
            <Route exact path={`${match.path}/qa`} component={SequenceAssemblyQAForm} />
            <Route exact path={`${match.path}/annotationqa`} component={SequenceAnnotationQAForm} />
        </Switch>
}