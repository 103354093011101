/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains common types, actions and reducers for working with AutoQuery
 * --------------------------------------------------------------------------------
 */

import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { ComplexActionCreator, createReducer, Reducer } from 'redux-act';
import { combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { QueryResponse } from '../../../dtos/Spread.dtos';
import createAction, { IAnyFunction } from '../../../helpers/createAction';
import { createGetRequest, DtoReturnType } from '../../../helpers/createRequest';
import { ReplaceReturnType } from '../../../types/HelperTypes';
import { IRequestState } from '../../../types/IRequestState';
import { RequestState } from '../../../types/RequestState';
import { authenticatedPersonActions } from '../person/authenticatedPerson';



/*
 * ---------------------------------------------------------------------------------
 * Query Option Types
 * ---------------------------------------------------------------------------------
 */

export interface IPaginateQueryOptions {
    skip: number;
    take: number;
    orderBy?: string;
    orderByDesc?: string;
    include?: string;
    fields?: string;
    meta?: { [index: string]: string; };
}

export type MakeQueryOptions<T> = Partial<Omit<T, "createResponse" | "getTypeName" | "skip" | "take" | "orderBy" | "orderByDesc" | "include" | "fields" | "meta">>;

/*
 * ---------------------------------------------------------------------------------
 * Search State
 * ---------------------------------------------------------------------------------
 */

export interface IQueryOptions<SpecialQueryOptions> {
    paginateOptions: IPaginateQueryOptions;
    specialOptions: MakeQueryOptions<SpecialQueryOptions>;
}

export interface ISearchState<QueryReturnedDTO> extends IInternalSearchState<QueryReturnedDTO, AutoQueryRequestDto<QueryReturnedDTO>> { };

interface IInternalSearchState<QueryReturnedDTO, QueryRequestDTO extends AutoQueryRequestDto<QueryReturnedDTO>> {
    data?: QueryResponse<QueryReturnedDTO>;
    searchState: IRequestState;
    queryOptions: IQueryOptions<QueryRequestDTO>;
}

export const initialSearchState: ISearchState<any> = {
    data: undefined,
    searchState: {
        state: RequestState.None
    },
    queryOptions: {
        paginateOptions: {
            take: 200,
            skip: 0
        },
        specialOptions: {}
    }
}


/*
 * ---------------------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------------------
 */

interface IBaseAutoQueryTypes {
    CLEAR: string;
    UPDATE_PAGINATE_OPTIONS: string;
    UPDATE_SPECIAL_OPTIONS: string;
    SEARCH: string;
    SEARCH_SUCCESS: string;
    SEARCH_FAILURE: string;
}

function createAutoQueryActionTypes<OtherActionTypes extends Record<string, string> = {}>(namespace: string, otherActionTypes?: OtherActionTypes): IBaseAutoQueryTypes & OtherActionTypes {
    const actionTypes: Record<string, string> = {
        CLEAR: `${namespace}/CLEAR`,
        UPDATE_PAGINATE_OPTIONS: `${namespace}/UPDATE_PAGINATE_OPTIONS`,
        UPDATE_SPECIAL_OPTIONS: `${namespace}/UPDATE_SPECIAL_OPTIONS`,
        SEARCH: `${namespace}/SEARCH`,
        SEARCH_SUCCESS: `${namespace}/SEARCH_SUCCESS`,
        SEARCH_FAILURE: `${namespace}/SEARCH_FAILURE`
    }

    if (otherActionTypes) {
        for (let key in otherActionTypes) {
            actionTypes[key] = `${namespace}/${otherActionTypes[key]}`;
        }
    }

    return actionTypes as OtherActionTypes & IBaseAutoQueryTypes;
}

/*
 * ---------------------------------------------------------------------------------
 * Action Creators
 * ---------------------------------------------------------------------------------
 */

interface IUpdatePaginateOptionsFn {
    (options: Partial<IPaginateQueryOptions>): Partial<IPaginateQueryOptions>;
}

interface IClearFn {
    (): {};
}

interface ISearchFn {
    (): {};
}

interface ISearchSuccessFn<ResponseDto> {
    (response: ResponseDto): ResponseDto;
}

interface ISearchFailureFn<ResponseDto> {
    (response: ResponseDto | null): ResponseDto | null;
}

interface IUpdateSpecialOptionsFn<RequestDto> {
    (options: Partial<MakeQueryOptions<RequestDto>>): Partial<MakeQueryOptions<RequestDto>>;
}

type AutoQueryBaseActionCreator<Fn extends IAnyFunction> = ReplaceReturnType<
    Fn,
    {
        type: string,
        payload: ReturnType<IUpdatePaginateOptionsFn>,
        meta: {}
    }
> & ComplexActionCreator<ReturnType<Fn>, {}>

interface IBaseActionCreators<RequestDto> {
    clear: AutoQueryBaseActionCreator<IClearFn>;
    updatePaginateOptions: AutoQueryBaseActionCreator<IUpdatePaginateOptionsFn>;
    updateSpecialOptions: AutoQueryBaseActionCreator<IUpdateSpecialOptionsFn<RequestDto>>;
    search: AutoQueryBaseActionCreator<ISearchFn>;
    searchSuccess: AutoQueryBaseActionCreator<ISearchSuccessFn<DtoReturnType<RequestDto>>>;
    searchFailure: AutoQueryBaseActionCreator<ISearchFailureFn<DtoReturnType<RequestDto>>>;
}

function createAutoQueryActions<QueryRequest extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<any>>, OtherActionsType>(actionTypes: ReturnType<typeof createAutoQueryActionTypes>, otherActions: OtherActionsType): IBaseActionCreators<QueryRequest> & OtherActionsType {
    return {
        clear: createAction(actionTypes.CLEAR, () => ({})),
        updatePaginateOptions: createAction(actionTypes.UPDATE_PAGINATE_OPTIONS,
            (options) => options
        ),
        updateSpecialOptions: createAction(actionTypes.UPDATE_SPECIAL_OPTIONS,
            (specialOptions) => specialOptions
        ),
        search: createAction(actionTypes.SEARCH, () => ({})),
        searchSuccess: createAction(actionTypes.SEARCH_SUCCESS,
            (response) => (response)
        ),
        searchFailure: createAction(actionTypes.SEARCH_FAILURE,
            (response) => (response)
        ),
        ...otherActions
    }
}

/*
 * ---------------------------------------------------------------------------------
 * Reducers
 * ---------------------------------------------------------------------------------
 */

function createAutoQueryReducer<InitialState, RequestDto, ExtraActionCreators>(name: string, initialState: InitialState, actions: IBaseActionCreators<RequestDto> & ExtraActionCreators) {

    const autoQueryReducer = createReducer<InitialState>({}, initialState);

    applyAutoQueryReducers<RequestDto, ExtraActionCreators>(name, autoQueryReducer, actions, initialState);

    return autoQueryReducer;
}

function applyAutoQueryReducers<RequestDto, ExtraActionCreators>(name: string, myReducer: Reducer<any, AnyAction>, myActions: IBaseActionCreators<RequestDto> & ExtraActionCreators, initialState: any) {
    // The base query options that all AutoQuery requests will share
    // mostly handles pagination/sort
    myReducer.on(myActions.updatePaginateOptions, (state, payload) => (
        updatePaginationReducer(name, state, payload)
    ));
    myReducer.on(myActions.updateSpecialOptions, (state, payload) => (
        updateSpecialQueryReducer(name, state, payload)
    ));
    // Begins the request to query the API with the current options
    myReducer.on(myActions.search, (state, payload) => (
        updateSearchReducer(name, state, payload)
    ));
    // A successful search
    myReducer.on(myActions.searchSuccess, (state, payload) => (
        updateSearchSuccessReducer(name, state, payload)
    ));
    // Failure to search. ResponseStatus will be stored.
    myReducer.on(myActions.searchFailure, (state, payload) => (
        updateSearchFailureReducer(name, state, payload)
    ));
    // Clear and reset state
    myReducer.on(myActions.clear, (state, payload) => (
        ({ ...initialState })
    ));
};

// QueryOptions - Pagination
const updatePaginationReducer: any = (statePart: string, state: any, payload: any) => {
    const paginateOptions = { ...state.queryOptions.paginateOptions };

    for (let key in payload) {
        paginateOptions[key] = payload[key];
    }

    return update(
        state,
        {
            queryOptions: {
                paginateOptions: {
                    $set: paginateOptions
                }
            }
        }
    )
}

// QueryOptions - Special
const updateSpecialQueryReducer: any = (statePart: string, state: any, payload: any) => {
    const specialOptions = { ...state.queryOptions.specialOptions };

    for (let key in payload) {
        specialOptions[key] = payload[key];
    }

    return update(
        state,
        {
            queryOptions: {
                specialOptions: {
                    $set: specialOptions
                }
            }
        }
    )
}

// Search
const updateSearchReducer: any = (statePart: string, state: any, payload: any) => {
    return update(
        state,
        {
            searchState: {
                $set: {
                    state: RequestState.Pending
                }
            }
        }
    )
}

// Search Success
const updateSearchSuccessReducer: any = (statePart: string, state: any, payload: any) => {
    return update(
        state,
        {
            data: {
                $set: payload
            },
            searchState: {
                $set: {
                    state: RequestState.Success
                }
            }
        }
    )
}

// Search Failure
const updateSearchFailureReducer: any = (statePart: string, state: any, payload: any) => {
    return update(
        state,
        {
            data: {
                $set: undefined
            },
            searchState: {
                $set: {
                    state: RequestState.Failure,
                    responseStatus: payload.responseStatus
                }
            }
        }
    )
}

/*
 * ---------------------------------------------------------------------------------
 * API
 * ---------------------------------------------------------------------------------
 */

interface ISearchRequestExtraMappingFn<QueryOptionType, ResponseType, RequestType extends SpreadDtos.IReturn<ResponseType>> {
    (queryOptions: QueryOptionType): MakeQueryOptions<RequestType>
}

interface IBaseApi {
    search: ReturnType<typeof createAutoQuerySearchRequest>;
}

function createAutoQuerySearchRequest<QueryOptions extends IQueryOptions<any>, RequestType extends SpreadDtos.IReturn<any>, ResponseType extends Partial<DtoReturnType<RequestType>>>(type: new () => RequestType, requestExtraMappingFunctionWithALongName?: ISearchRequestExtraMappingFn<QueryOptions, ResponseType, RequestType>) {
    return createGetRequest(
        type,
        (options: QueryOptions) => {
            const baseRequestParams = {
                take: options.paginateOptions.take,
                skip: options.paginateOptions.skip,
                orderBy: options.paginateOptions.orderBy,
                orderByDesc: options.paginateOptions.orderByDesc
            }

            if (requestExtraMappingFunctionWithALongName) {
                return {
                    ...baseRequestParams,
                    ...requestExtraMappingFunctionWithALongName(options)
                } as unknown as RequestType
            }

            return baseRequestParams as unknown as RequestType
        }
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Epics
 * ---------------------------------------------------------------------------------
 */

function createAutoQueryEpics<QueryRequest extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<any>>, OtherEpicsType>(stateName: string, actions: IBaseActionCreators<QueryRequest>, api: IBaseApi, otherEpics: OtherEpicsType) {

    const searchEpic: Epic<ReturnType<typeof actions.search>, any, any, any> = (action$, state$: StateObservable<any>) => action$.pipe(
        ofType(actions.search.getType()),
        withLatestFrom(state$),
        switchMap(action =>
            api.search(state$.value[stateName].queryOptions)
                .pipe(
                    switchMap(response =>
                        of(
                            actions.searchSuccess(response)
                        )
                    ),
                    catchError(e => of(actions.searchFailure(e)))
                )
        ));

    return combineEpics(
        searchEpic
        //, otherEpics
    )
}


/*
 * ---------------------------------------------------------------------------------
 * Selectors & Hooks
 * ---------------------------------------------------------------------------------
 */

export interface IBaseAutoQuerySelectors<QueryReturnedDTO, AutoQueryReducerState extends ISearchState<QueryReturnedDTO>> {
    data: (state: any) => AutoQueryReducerState["data"];
    paginateOptions: (state: any) => AutoQueryReducerState["queryOptions"]["paginateOptions"];
    specialOptions: (state: any) => AutoQueryReducerState["queryOptions"]["specialOptions"];
    searchState: (state: any) => AutoQueryReducerState["searchState"];
}

function createAutoQuerySelectors<QueryReturnedDTO>(reducerPropertyName: string) {

    const selectors: IBaseAutoQuerySelectors<QueryReturnedDTO, ISearchState<QueryReturnedDTO>> = {
        data: (state) => state[reducerPropertyName].data,
        paginateOptions: (state) => state[reducerPropertyName].queryOptions.paginateOptions,
        specialOptions: (state) => state[reducerPropertyName].queryOptions.specialOptions,
        searchState: (state) => state[reducerPropertyName].searchState,
    }

    return selectors;
}

/*
 * ---------------------------------------------------------------------------------
 * Module Export
 * ---------------------------------------------------------------------------------
 */

export type AutoQueryRequestDto<SearchQueryReturnDto> = SpreadDtos.IReturn<SpreadDtos.QueryResponse<SearchQueryReturnDto>>;

export type AutoQueryReturnDto<RequestDto> = RequestDto extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<infer S>> ? S : never;

export const createAutoQueryModule = <
    SearchRequestType extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<any>>,
    ExtraActionTypes extends Record<string, string>,
    ExtraActions extends Record<string, Function>,
    ExtraApi extends Record<string, Function>,
    ExtraEpics
>(
    reducerPropertyName: string,
    initialState: ISearchState<AutoQueryReturnDto<SearchRequestType>>,
    namespace: string,
    searchRequestType: new () => SearchRequestType,
    extraActionTypes?: ExtraActionTypes,
    extraActions?: (types: IBaseAutoQueryTypes & ExtraActionTypes) => ExtraActions,
    extraReducer?: (reducer: Reducer<ISearchState<AutoQueryReturnDto<SearchRequestType>>, AnyAction>, actions: IBaseActionCreators<SearchRequestType> & ExtraActions) => void,
    persistOnLogout?: boolean,
    searchRequestMappingFn?: ISearchRequestExtraMappingFn<IQueryOptions<SearchRequestType>, SpreadDtos.QueryResponse<AutoQueryReturnDto<SearchRequestType>>, SearchRequestType>,
    extraApi?: ExtraApi,
    extraEpics?: (actions: IBaseActionCreators<SearchRequestType>, api: ExtraApi & IBaseApi) => ExtraEpics
) => {

    const types = createAutoQueryActionTypes(namespace, extraActionTypes);

    const actions = createAutoQueryActions<SearchRequestType, ExtraActions>(types, extraActions ? extraActions(types) : {} as ExtraActions);

    const reducer = createAutoQueryReducer<ISearchState<AutoQueryReturnDto<SearchRequestType>>, SearchRequestType, ExtraActions>(reducerPropertyName, initialState, actions);

    const api = {
        search: createAutoQuerySearchRequest(searchRequestType, searchRequestMappingFn),
        ...(extraApi ? extraApi : {} as ExtraApi)
    };

    const epics = createAutoQueryEpics(reducerPropertyName, actions, api, extraEpics);

    const selectors = createAutoQuerySelectors<AutoQueryReturnDto<SearchRequestType>>(reducerPropertyName);

    if (extraReducer) {
        extraReducer(reducer, actions);
    }

    if (!persistOnLogout) {
        // LogoutClear Reducer
        reducer.on(authenticatedPersonActions.logoutClear, () => ({ ...initialState }));
    }

    return {
        types,
        reducer,
        actions,
        api,
        epics,
        selectors,
        hooks: {
            useSearch: useAutoQuerySearch.bind(
                null,
                actions,
                selectors
            ) as (initialSpecialOptions?: IQueryOptions<SearchRequestType>["specialOptions"], initialPaginationOptions?: IQueryOptions<SearchRequestType>["paginateOptions"]) => AutoQuerySearchHookReturn<SearchRequestType>
        }
    }
}

type AutoQuerySearchHookReturn<QueryRequestDto extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<any>>> = [
    QueryResponse<AutoQueryReturnDto<QueryRequestDto>> | undefined,
    IQueryOptions<QueryRequestDto>["paginateOptions"],
    IQueryOptions<QueryRequestDto>["specialOptions"],
    IRequestState
];

function useAutoQuerySearch<QueryRequestDto extends SpreadDtos.IReturn<SpreadDtos.QueryResponse<any>>, ActionsType extends IBaseActionCreators<QueryRequestDto>>(
    actions: ActionsType,
    sel: IBaseAutoQuerySelectors<AutoQueryReturnDto<QueryRequestDto>, ISearchState<AutoQueryReturnDto<QueryRequestDto>>>,
    initialSpecialOptions?: IQueryOptions<QueryRequestDto>["specialOptions"],
    initialPaginationOptions?: IQueryOptions<QueryRequestDto>["paginateOptions"]
): AutoQuerySearchHookReturn<QueryRequestDto> {

    const dispatch = useDispatch();
    const data = useSelector(sel.data);
    const paginateOptions = useSelector(sel.paginateOptions);
    const specialOptions = useSelector(sel.specialOptions);
    const requestState = useSelector(sel.searchState);

    const [initialPaginationOptionsLoaded, setInitialPaginationOptionsLoaded] = useState(() => initialPaginationOptions ? false : true);
    const [initialSpecialOptionsLoaded, setInitialSpecialOptionsLoaded] = useState(() => initialSpecialOptions ? false : true);

    useEffect(() => {
        return () => {
            dispatch(actions.clear());
        }
    }, [actions, dispatch]);

    useEffect(() => {
        if (initialPaginationOptions) {
            setInitialPaginationOptionsLoaded(false);
        }
    }, [initialPaginationOptions, dispatch, setInitialPaginationOptionsLoaded]);

    useEffect(() => {
        if (initialSpecialOptions) {
            setInitialSpecialOptionsLoaded(false);
        }
    }, [initialSpecialOptions, dispatch, setInitialSpecialOptionsLoaded]);

    useEffect(() => {
        if (!initialPaginationOptionsLoaded) {
            dispatch(actions.updatePaginateOptions(initialPaginationOptions));
            setInitialPaginationOptionsLoaded(true);
        }
    }, [actions, initialPaginationOptions, initialPaginationOptionsLoaded, dispatch, setInitialPaginationOptionsLoaded]);

    useEffect(() => {
        if (!initialSpecialOptionsLoaded) {
            dispatch(actions.updateSpecialOptions(initialSpecialOptions));
            setInitialSpecialOptionsLoaded(true);
        }
    }, [actions, initialSpecialOptions, initialSpecialOptionsLoaded, dispatch, setInitialSpecialOptionsLoaded]);

    useEffect(() => {
        if (initialPaginationOptionsLoaded && initialSpecialOptionsLoaded)
            dispatch(
                actions.search()
            )
    }, [actions, initialSpecialOptionsLoaded, initialPaginationOptionsLoaded, paginateOptions, specialOptions, dispatch]);

    return [
        data,
        paginateOptions,
        specialOptions,
        requestState
    ];
}