/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { History } from 'history';
import * as React from 'react';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import ImportDatesForm from './ImportDatesForm';
import ImportEpidemicForm from './ImportEpidemicForm';
import ImportHierarchyForm from './ImportHierarchyForm';
import ImportLivestockForm from './ImportLivestockForm';
import ImportSpatialForm from './ImportSpatialForm';



/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        root: {
            marginTop: theme.spacing(1),
        },
        paper: {
            padding: theme.spacing(3, 2)
        }
    }),
);

interface IBulkUploadHomeParams {
    epidemicId: string;
}

interface IBulkUploadHomeProps {
    history: History;
    loading: boolean;
    match: match<IBulkUploadHomeParams>;
}

export const BulkUploadHome: React.FunctionComponent<IBulkUploadHomeProps> = ({ history, match }) => {
    const classes = useStyles();

    const [uploadTypeId, setUploadTypeId] = React.useState(0);

    const handleUploadTypeChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setUploadTypeId(event.target.value as number);
    }

    const epidemicId = match && match.params ? parseInt(match.params.epidemicId) : undefined;

    return <Box className={classes.root} component="div">
        <Box className={classes.layoutItem}>
            <Paper className={classes.paper}>
                <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h4">Import Epidemic Data</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>Upload Type</FormLabel>
                        <Select
                            fullWidth
                            className={classes.formControl}
                            onChange={handleUploadTypeChange}
                            value={uploadTypeId}
                        >
                            <MenuItem value={0}></MenuItem>
                            <MenuItem value={1}>
                                Hierarchy
                            </MenuItem>
                            <MenuItem value={2}>
                                Spatial
                            </MenuItem>
                            <MenuItem value={3}>
                                Livestock
                            </MenuItem>
                            <MenuItem value={4}>
                                Dates
                            </MenuItem>
                            <MenuItem value={5}>
                                Epidemic
                            </MenuItem>
                        </Select>
                    </Grid>
                    {
                        uploadTypeId == 1 &&
                        <ImportHierarchyForm
                            epidemicId={epidemicId}
                            history={history}
                            url={match.url}
                            successRouteCallback={(response) => response ? `${match.url}/log/${response.importLogId}` : '/404'}
                        />
                    }
                    {
                        uploadTypeId == 2 &&
                        <ImportSpatialForm
                            epidemicId={epidemicId}
                            history={history}
                            url={match.url}
                            successRouteCallback={(response) => response ? `${match.url}/log/${response.importLogId}` : '/404'}
                        />
                    }
                    {
                        uploadTypeId == 3 &&
                        <ImportLivestockForm
                            epidemicId={epidemicId}
                            history={history}
                            url={match.url}
                            successRouteCallback={(response) => response ? `${match.url}/log/${response.importLogId}` : '/404'}
                        />
                    }
                    {
                        uploadTypeId == 4 &&
                        <ImportDatesForm
                            epidemicId={epidemicId}
                            history={history}
                            url={match.url}
                            successRouteCallback={(response) => response ? `${match.url}/log/${response.importLogId}` : '/404'}
                        />
                    }
                    {
                        uploadTypeId == 5 &&
                        <ImportEpidemicForm
                            epidemicId={epidemicId}
                            history={history}
                            url={match.url}
                            successRouteCallback={(response) => response ? `${match.url}/log/${response.importLogId}` : '/404'}
                        />
                    }
                </Grid>
            </Paper>
        </Box>
    </Box>
}
