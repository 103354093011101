/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import { FormControlProps } from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { toDate } from '@servicestack/client';
import * as React from 'react';
import { useMemo } from 'react';

import { FieldRenderProps } from 'react-final-form';

import { differenceInDays, format } from 'date-fns';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { DateTimeFormat } from '../../../constants/Dates';
import { subClustersHooks } from '../../../store/reducers/subClusters/subClusterSearch';
import SelectWrapper from '../FinalFormControls/SelectWrapper';

/*
* ---------------------------------------------------------------------------------
* Implementation
* ---------------------------------------------------------------------------------
*/

interface ISubClusterSelectProps extends FieldRenderProps<any, any> {
    clusterId?: number;
    epidemicId?: number;
    unselectLabel?: string;
    label?: string;
    disabled?: boolean;
    formControlProps?: FormControlProps;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formGroup: {
            margin: theme.spacing(1, 0, 0, 3)
        },
        formSkeleton: {
            height: 42,
            marginLeft: -11,
        }
    }),
);

const SubClusterSelect: React.FunctionComponent<ISubClusterSelectProps> = ({
    clusterId,
    disabled,
    epidemicId,
    unselectLabel,
    input,
    meta,
    label,
    formControlProps,
    ...rest
}) => {
    const classes = useStyles();

    const subClusterSpecOps = useMemo(() => ({ clusterId: clusterId, epidemicId: epidemicId, }), [clusterId, epidemicId]);
    const subClusterPaginateOps = useMemo(() => ({ skip: 0, take: 1000 }), []);
    const [subClusterData, , , subClusterRequestState] = subClustersHooks.useSearch(subClusterSpecOps, subClusterPaginateOps);

    const subClusterOptions = subClusterData && subClusterData.results && subClusterData.results.length > 0 ?
        subClusterData.results.map((c => {

            const dayDifference = c.subClusterDateSet && c.subClusterDateSet.earliestStartOfInfectiousPeriodDate && c.subClusterDateSet.latestEndOfInfectiousPeriodDate 
                ? differenceInDays(toDate(c.subClusterDateSet.latestEndOfInfectiousPeriodDate), toDate(c.subClusterDateSet.earliestStartOfInfectiousPeriodDate)) : null;

            const dayDifferenceMessage = dayDifference && dayDifference + ` day${dayDifference !== 1 && 's'}`;

            return <MenuItem
                key={c.id}
                value={c.id}
            >
                {c.name}: {
                    c.subClusterDateSet && c.subClusterDateSet.earliestStartOfInfectiousPeriodDate && c.subClusterDateSet.latestEndOfInfectiousPeriodDate 
                        ? `${format(toDate(c.subClusterDateSet.earliestStartOfInfectiousPeriodDate), DateTimeFormat)} to ${format(toDate(c.subClusterDateSet.latestEndOfInfectiousPeriodDate), DateTimeFormat)} ${dayDifferenceMessage && dayDifferenceMessage}`
                        : '(Insufficient date data)'
                }
            </MenuItem>
        }
        )) :
        undefined;

    return <SelectWrapper
        formControlProps={formControlProps}
        disabled={disabled || !subClusterOptions}
        input={input}
        label={label}
        meta={meta}
    >
        <MenuItem value={undefined}>{unselectLabel ? unselectLabel : 'All'}</MenuItem>
        {subClusterOptions}
    </SelectWrapper>;
}

export default SubClusterSelect;