/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import classNames from 'classnames';
import { History, Location } from 'history';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { match } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { Epidemic } from '../../dtos/Spread.dtos';
import { useSelector } from '../../hooks/useTypedSelector';
import { epidemicSearchActions, epidemicsHooks } from '../../store/reducers/epidemics/epidemicSearch';
import { RequestState } from '../../types/RequestState';
import { AnalysisDate } from '../areaDate/AnalysisDate';
import IconButtonLink from '../common/IconLink';
import { SortableTable } from '../table/SortableTable';

/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridContainer: {
            marginTop: theme.spacing(0),
        },
        layoutItem: {
            margin: theme.spacing(3, 2),
        },
        paper: {
            padding: theme.spacing(2, 3),
            marginTop: theme.spacing(2),
        }
    }),
);

interface IEpidemicModuleSelectorProps {
    history: History;
    location: Location;
    match: match<any>;
}

export const EpidemicModuleSelector: React.FunctionComponent<IEpidemicModuleSelectorProps> = ({ history, location, match }) => {
    const classes = useStyles();

    const epidemic = useSelector(state => state.epidemic.data);

    const preUrl = !epidemic ? 'select/' : '';

    const selectPage = match.path.includes('select')

    return <Container maxWidth="xl">
        {
            selectPage && <Paper className={classes.paper}>
                <Typography variant="h4" component="h4">Select Epidemic</Typography>
                <ChooseEpidemicList
                    location={location}
                />
            </Paper>
        }
        {
            epidemic && <EpidemicModuleSummary
                epidemic={epidemic}
            />
        }
        {
            !selectPage && <Grid className={classes.gridContainer} container spacing={2} >
                <ButtonRow
                    buttons={[
                        {
                            text: 'Register an  Epidemic',
                            url: `/epidemic/create`,
                            hidden: epidemic !== undefined
                        },
                        {
                            text: 'Upload Epidemic Data',
                            url: `${match.url}/${preUrl}data/import`
                        },
                        {
                            text: 'View Epidemic',
                            url: `${match.url}/${preUrl}data`
                        },
                        /*{
                            text: 'View Epidemic',
                            url: `/epidemic`,
                            hidden: epidemic !== undefined
                        },*/
                    ]}
                    title={'Epidemiology Tasks'}
                    theme={'epidemic'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'View/Search All NGS Sequences',
                            url: `${match.url}/${preUrl}bioinformatics`
                        },
                        {
                            text: 'Upload NGS (FASTQ)',
                            url: `${match.url}/${preUrl}bioinformatics/specimen/create?uploadType=fastQ`,
                        },
                        {
                            text: 'Retrieve Genbank NGS',
                            url: `${match.url}`,
                            disabled: true,
                            tooltip: 'Under development'
                        },
                        {
                            text: 'Assemble / Annotate NGS',
                            url: `${match.url}/${preUrl}bioinformatics/upload/assemble`
                        },
                        {
                            text: 'Re-Assemble / Re-Annotate NGS',
                            url: `${match.url}`,
                            disabled: true,
                            tooltip: 'Under development'
                        },
                    ]}
                    title={'NGS Assembly/Annotation Tasks'}
                    theme={'ngs'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'View Linked Sequences',
                            url: `${match.url}/${preUrl}bioinformatics?sequencingStatus=Assembled`
                        },
                        {
                            text: 'Upload FASTA/Genbank',
                            url: `${match.url}/${preUrl}bioinformatics/specimen/create?uploadType=fastA`
                        },
                        {
                            text: 'Link Sequences to Epidemic/IP',
                            url: `${match.url}/${preUrl}bioinformatics?link=1${epidemic && epidemic.countryId ? `&country=${epidemic.country}` : ''}${epidemic && epidemic.year ? `&year=${epidemic.year}` : ''}`
                        },
                        {
                            text: 'Create/View Sequence Alignment',
                            url: `${match.url}/${preUrl}bioinformatics/msa`,
                        },
                        {
                            text: 'Create Genomic Network',
                            url: `${match.url}/${preUrl}genomicNetwork/create`,
                        },
                    ]}
                    title={'Genomic Network Tasks'}
                    theme={'bio'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'View Excretion',
                            url: `${match.url}/${preUrl}excretion`
                        },
                        {
                            text: 'Estimate Excretion',
                            url: `${match.url}/${preUrl}excretion/create`
                        },
                    ]}
                    title={'Farm Level Viral Excretion Tasks'}
                    theme={'wind'}
                />
                <ButtonRow
                    buttons={[
                        {
                            text: 'View Wind Dispersion Runs',
                            url: `${match.url}/${preUrl}analysis`
                        },
                        {
                            text: 'View/Edit Date Definitions',
                            url: `${match.url}/${preUrl}data/edit`
                        },
                        {
                            text: 'Undertake Wind Dispersion Run',
                            url: `${match.url}/${preUrl}analysis/create`
                        },
                        {
                            disabled: true,
                            text: 'Create Wind Dispersion Network',
                            url: `${match.url}`,
                            tooltip: 'Under development'
                        }
                    ]}
                    title={'Wind Dispersion Tasks'}
                    theme={'wind'}
                />
                <ButtonRow
                    buttons={[
                        {
                            disabled: true,
                            text: 'View Animal Movements',
                            url: ``,
                            tooltip: 'Future proposed work'
                        },
                        {
                            disabled: true,
                            text: 'View Vehicle Movements',
                            url: ``,
                            tooltip: 'Future proposed work'
                        },
                        {
                            disabled: true,
                            text: 'View Personnel Movements',
                            url: ``,
                            tooltip: 'Future proposed work'
                        }
                    ]}
                    title={'Movements'}
                    theme={'animal'}
                />
            </Grid>
        }
    </Container>
}

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
            textAlign: 'center',
            '&:hover': {
                backgroundColor: 'inherit',
            },
            padding: 0
        },
        row: {
            padding: theme.spacing(0),
            width: '100%',
            display: 'block',
            marginTop: theme.spacing(2.5),
        },
        rowItems: {
            paddingLeft: theme.spacing(2)
        },
        buttonEpidemic: {
            color: '#DBC994',
            '&:hover': {
                color: '#b8a97d',
            }
        },
        buttonNgs: {
            color: '#E99C47',
            '&:hover': {
                color: '#cf8a3e',
            }
        },
        buttonBio: {
            color: '#926B89',
            '&:hover': {
                color: '#73546c',
            }
        },
        buttonWind: {
            color: '#B0C764',
            '&:hover': {
                color: '#90a352',
            }
        },
        buttonAnimal: {
            color: '#e8e82a',
            '&:hover': {
                color: '#c2c223',
            }
        },
        chevron: {
            height: '100px',
            width: '250px',
            color: 'inherit'
        },
        chevronText: {
            position: 'absolute',
            left: '92px',
            textAlign: 'center',
            color: 'white',
            fontSize: '1rem',
            width: '100px'
        },
        chevronTextFirst: {
            position: 'absolute',
            left: '45px',
            textAlign: 'center',
            color: 'white',
            fontSize: '1rem',
            width: '135px'
        },
        buttonDisabled: {
            color: 'rgba(0,0,0,0.2)'
        }
    }),
);

interface IButton {
    text: string;
    url: string;
    disabled?: boolean;
    hidden?: boolean;
    tooltip?: string;
}

interface IButtonRowProps {
    buttons: IButton[];
    title: string;
    theme: 'epidemic' | 'ngs' | 'bio' | 'wind' | 'animal';
}

const ButtonRow: React.FunctionComponent<IButtonRowProps> = ({ title, buttons, theme }) => {
    const classes = useRowStyles();

    const buttonClassName = theme === 'epidemic' ? classes.buttonEpidemic :
        theme === 'bio' ? classes.buttonBio :
            theme === 'ngs' ? classes.buttonNgs :
                theme === 'wind' ? classes.buttonWind :
                    theme === 'animal' ? classes.buttonAnimal : undefined

    const activeButtons = [...buttons.filter(button => !button.hidden)]

    return <Box className={classes.row}>
        <Typography variant={'h5'}>
            {title}
        </Typography>
        <Box component='div' display="flex" flexDirection="row" className={classes.rowItems}>
            {
                activeButtons.map((button, idx) => {

                    const buttonElement = <IconButtonLink
                        key={`button-${button.text}-${button.url}`}
                        className={classNames(
                            classes.button,
                            buttonClassName
                        )}
                        disabled={button.disabled}
                        to={button.url}
                    >
                        {
                            idx === 0 ? <LabelIcon className={classNames(
                                classes.chevron,
                                {
                                    [classes.buttonDisabled]: button.disabled
                                }
                            )}
                                key={`label-${button.text}-${button.url}`}
                                preserveAspectRatio={'none'}
                                viewBox="2 5 21 14"
                            />
                                :
                                <LabelImportantIcon className={classNames(
                                    classes.chevron,
                                    {
                                        [classes.buttonDisabled]: button.disabled
                                    }
                                )}
                                    key={`label-${button.text}-${button.url}`}
                                    preserveAspectRatio={'none'}
                                    viewBox="2 5 19 14"
                                />
                        }

                        <div className={classNames(
                            {
                                [classes.chevronTextFirst]: idx === 0,
                                [classes.chevronText]: idx !== 0
                            }
                        )}>
                            {button.text}
                        </div>
                    </IconButtonLink>

                    return button.tooltip ? <Tooltip title={button.tooltip} key={`tooltip-${button.text}-${button.url}`}>
                        <span>
                            {buttonElement}
                        </span>
                    </Tooltip>
                        : buttonElement
                })
            }

        </Box>
    </Box>
}


const useEpidemicListStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            minWidth: 275,
        },
        title: {
            fontSize: 14,
        },
        pos: {
            //marginBottom: theme.spacing(1),
        },
        table: {

        }
    }),
);

const headerColumns = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'country', numeric: false, disablePadding: false, label: 'Country' },
    { id: 'subNational', numeric: false, disablePadding: false, label: 'Sub-National' },
    { id: 'year', numeric: false, disablePadding: false, label: 'Year' },
    { id: 'disease', numeric: false, disablePadding: false, label: 'Disease' },
    { id: 'infectedPremiseCount', numeric: false, disablePadding: false, label: 'No. of IPs' },
];

interface IChooseEpidemicListProps {
    location: Location;
}

const ChooseEpidemicList: React.FunctionComponent<IChooseEpidemicListProps> = ({ location }) => {
    const classes = useEpidemicListStyles();

    const [data, paginateOptions, , requestState] = epidemicsHooks.useSearch();

    return <SortableTable
        className={classes.table}
        headerColumns={headerColumns}
        paginateOptions={paginateOptions}
        rowCount={data ? data.total : 0}
        updatePaginateOptions={epidemicSearchActions.updatePaginateOptions}
    >
        {
            data && data.results && data.results.length > 0
                ? data.results.map((item =>
                    <TableRow
                        key={item.id}>
                        <TableCell component="th" scope="row">
                            <Link component={RouterLink} to={`${location.pathname.replace('/task/select', '/epidemic/' + item.id.toString())}${location.search}`}>
                                {item.name}
                            </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.countryName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.subNationalName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.year}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.diseaseName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                            {item.infectedPremiseCount}
                        </TableCell>
                    </TableRow>
                ))
                : <TableRow>
                    <TableCell component="th" colSpan={2}>
                        {requestState.state === RequestState.Pending
                            ? <Skeleton />
                            : 'There are no Epidemics to choose from. Create one to use this feature.'}
                    </TableCell>
                </TableRow>
        }
    </SortableTable>
}


interface IEpidemicModuleSummaryProps {
    epidemic: Epidemic;
}

const useModuleStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2, 3),
            marginTop: theme.spacing(2),
        }
    }),
);

const EpidemicModuleSummary: React.FunctionComponent<IEpidemicModuleSummaryProps> = ({
    epidemic
}) => {
    const classes = useModuleStyles();

    return <Paper className={classes.paper}>
        <Typography variant="h4" component="h4">{epidemic.name}</Typography>
        <Typography variant="subtitle1" component="p">Start: <AnalysisDate dateSet={epidemic.epidemicDateSet} dateType='startinfectious' /></Typography>
        <Typography variant="subtitle1" component="p">End: <AnalysisDate dateSet={epidemic.epidemicDateSet} dateType='endinfectious' /></Typography>
    </Paper>;
}