import * as React from 'react';

interface IEpidemicResultsContext {
    currentStartDate?: Date;
    currentEndDate?: Date;
    setCurrentDates: (startDate: Date | undefined, endDate: Date | undefined) => void;

    currentTime?: any;
    setCurrentTime: (value: any) => void;
}

export const EpidemicResultsContext = React.createContext<IEpidemicResultsContext>({
    setCurrentTime: (value: any) => {},
    setCurrentDates: (startDate: Date | undefined, endDate: Date | undefined) => {}
});
