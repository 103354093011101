import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MockFormContext } from './MockFormContext';

interface IMockMenuItem {
    key: any;
    label: string;
}

interface IMockSelectProps {
    className?: string;
    defaultKey?: any;
    disabled?: boolean;
    fieldName: string;
    items: IMockMenuItem[];
    title: string;
}


const useMockFormStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

export const MockSelect: React.FunctionComponent<IMockSelectProps> = ({ className, disabled, defaultKey, fieldName, items, title }) => {
    const classes = useMockFormStyles();

    const mockForm = React.useContext(MockFormContext);

    const handleKeyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        mockForm.setField(fieldName, event.target.value as any);
    };

    React.useEffect(() => {
        mockForm.setField(fieldName, defaultKey);
    }, [defaultKey])

    const menuItems = [<MenuItem value={'-1'}></MenuItem>];

    if (items) {
        const mappedMenuItems = items.map((menuItem) => <MenuItem value={menuItem.key}>{menuItem.label}</MenuItem>);

        menuItems.push(...mappedMenuItems);
    }

    return <FormControl className={className}>
        <InputLabel>{title}</InputLabel>
        <Select
            disabled={disabled}
            defaultValue={defaultKey}
            value={mockForm.fields[fieldName]}
            onChange={handleKeyChange}
            fullWidth={true}
        >
            {menuItems}
        </Select>
    </FormControl>
}