/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file sets up the logic for searching for and retrieving multiple analysis jobs.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as SpreadDtos from '../../../dtos/Spread.dtos';
import { ISearchState, initialSearchState, createAutoQueryModule } from '../common/autoQuery';

/*
 * ---------------------------------------------------------------------------------
 * State
 * ---------------------------------------------------------------------------------
 */

export type IInitialAnalysisSearchState = ISearchState<SpreadDtos.AnalysisJobTapm>;

export interface IAnalysisSearchState {
    analysisSearch: IInitialAnalysisSearchState;
}

export const initialState: IAnalysisSearchState = {
    analysisSearch: initialSearchState
};

/*
 * ---------------------------------------------------------------------------------
 * Auto Query Module
 * ---------------------------------------------------------------------------------
 */

const analysissModule = createAutoQueryModule(
    'analysisSearch',
    initialSearchState,
    '@@analysisSearch', 
    SpreadDtos.QueryAnalysis, 
    {
    },
    (type) => ({
    }),
    (reducer, actions) => {
    },
    false,
    (options) =>({
        subClusterId: options.specialOptions.subClusterId,
        epidemicId: options.specialOptions.epidemicId,
        nameContains: options.specialOptions.nameContains
    })
);

/*
 * ---------------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------------
 */

export default analysissModule;