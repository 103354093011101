/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { History } from 'history';
import { parse as parseQueryString } from 'query-string';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { match } from 'react-router';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import { routeBackToPath } from '../../../helpers/routeHelpers';
import { useSelector } from '../../../hooks/useTypedSelector';
import specimenModule from '../../../store/reducers/specimens/specimen';
import { RequestFormState } from '../../../types/RequestState';
import { HasPermission } from '../../common/HasPermission';
import { ViewSequences } from '../sequence/ViewSequences';
import SpecimenEditForm from './SpecimenEditForm';


/*
 * ---------------------------------------------------------------------------------
 * Implementation
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3, 2),
            margin: theme.spacing(3, 2)
        },
    }),
);

interface IEditSpecimenParams {
    epidemicId: string;
    specimenId: string;
}

interface IEditSpecimenProps {
    history: History;
    match: match<IEditSpecimenParams>;
    uploadType?: "fastQ" | "fastA";
}

export const EditSpecimen: React.FunctionComponent<IEditSpecimenProps> = ({
    history,
    match,
    uploadType: uploadTypeProp,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const params = parseQueryString(history.location.search);

    const specimenState = useSelector(state => state.specimen);
    const specimenData = specimenState.data;

    const uploadType = uploadTypeProp || (params.uploadType == "fastQ" || params.uploadType == "fastA" ? params.uploadType : undefined);

    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);

    const handleDeleteClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setDeleteConfirmOpen(true);
        },
        [isDeleteConfirmOpen],
    );

    const handleDeleteConfirmClose = React.useCallback(
        () => {
            setDeleteConfirmOpen(false);
        },
        [setDeleteConfirmOpen],
    );

    const handleDeleteConfirm = React.useCallback(
        () => {
            if (specimenData && specimenModule.actions.delete) {
                setDeleteConfirmOpen(false);

                return dispatch(specimenModule.actions.delete(specimenData.id));
            }
        }, [specimenData, dispatch]
    );

    if (specimenData === undefined && specimenState.states.loadState.state !== RequestFormState.Pending && specimenState.states.loadState.state !== RequestFormState.None) {
        history.replace('/404');
    }

    if (specimenState.states.deleteState.state === RequestFormState.SubmitSuccess) {
        history.replace(`${routeBackToPath(match.url, 2, undefined)}`);
    }

    // can only delete if there are no attached consensus sequences
    const hasConsensusSequence = specimenData && specimenData.sequences && specimenData.sequences.find(s => s.hasConsensusSequence) !== undefined;

    return <>
        <Paper className={classes.root}>
            {
                !hasConsensusSequence && <HasPermission permission={'DeleteSpecimen'} objectId={specimenData?.epidemicId}>
                    <Box display="flex" flexDirection="row-reverse">
                        <IconButton edge="end" aria-label="Delete" onClick={handleDeleteClick}>
                            <DeleteIcon />
                        </IconButton>
                        {
                            specimenState.states.deleteState.responseStatus && specimenState.states.deleteState.responseStatus.message
                        }
                    </Box>
                </HasPermission>
            }
            <SpecimenEditForm
                editSpecimen={specimenData}
                uploadType={uploadType}
                history={history}
                loadingSpecimen={specimenState.states.loadState.state === RequestFormState.Pending && !specimenData}
                url={match.url}
            />
        </Paper>
        <ViewSequences
            match={match}
            showCreate={!specimenData || !specimenData.sequences || specimenData.sequences.length < 1}
        />
        <Dialog
            open={isDeleteConfirmOpen}
            onClose={handleDeleteConfirmClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="simple-dialog-title">Delete Confirmation</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this specimen and ALL associated sequence(s)? <strong>This operation cannot be undone.</strong>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDeleteConfirmClose}>
                    Cancel
                </Button>
                <Button onClick={handleDeleteConfirm} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>
}